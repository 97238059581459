import { styled } from '@mui/material';
import { MAX_WIDTH, MD_WIDTH, SM_WIDTH } from 'styles/constants';

export const NavBarStyled = styled('nav')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  width: '100%',
  height: '90px',
  position: 'sticky',
  top: 0,
  zIndex: 5000,
  display: 'flex',
  justifyContent: 'center',
  borderBottom: `1px solid ${theme.palette.common.white}`,
}));

export const NavBarWrapperStyled = styled('div')(({ theme }) => ({
  width: MD_WIDTH,
  maxWidth: MAX_WIDTH,
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    width: SM_WIDTH,
    padding: '0',
    margin: '0 auto',
  },
}));
