import { Box, styled } from '@mui/material';

export const StyledBriefSummaryWrapper = styled(Box)(() => ({
  width: '100%',
}));

export const StyledLoadingOrErrorWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  width: '100%',
}));
