import { useNavigate } from 'react-router';

import { Box } from '@mui/material';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { ContainerStyled } from './NewTermsThanks.styles';
import { routes } from 'options/routes';

const NewTermsThanks = () => {
  const navigate = useNavigate();
  return (
    <ContainerStyled>
      <CustomTitle variant="h3" text="Thank you for your terms" />
      <Box mt={2}>
        <BodyText text="Your terms has been successfully created." variant="body2" />
      </Box>
      <Box display="flex" mt={2}>
        <Box mr={2}>
          <CustomButton text="View all terms" onClick={() => navigate(routes.termsDashboard)} colour="secondary" />
        </Box>
        <Box>
          <CustomButton text="Create new template" onClick={() => navigate(routes.termsAdd)} />
        </Box>
      </Box>
    </ContainerStyled>
  );
};

export default NewTermsThanks;
