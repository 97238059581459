import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import PillTag from 'components/PillTag/PillTag';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import StatusPill from 'components/StatusPill/StatusPill';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';

import { PILL_STATUS_ICON, PILL_STATUS_MAP, BUYER_PRODUCT_PILL_STATUS_TEXT } from 'options/pillStatusOptions';
import { PROJECT_STATUS, PROJECT_STATUS_FILTER } from 'options/projectStatus';
import { buyerProductsListHeadings } from 'options/tableOptions';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { routes } from 'options/routes';
import { CURRENCY_MAP } from 'options/currency';

import { getUniqueList } from 'utils/getUniqueList';
import { addFilters } from 'utils/addFilters';

import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';
import { ProductDetailsWrapper } from 'components/DesignerProductList/DesignerProductList.styles';
import { TableStyled } from 'components/Table/Table.styles';

import { useTableFilter } from 'hooks/useTableFilter';

import { getBuyersProductsList, getChildOptions } from 'api';

const BuyerProductsList = ({ isStory, mockData, mockTotal }) => {
  const navigate = useNavigate();

  const { order, orderBy, filter, setFilter, p, l, parsedFilter, onPageChange, onRequestSort, onRowsPerPageChange } =
    useTableFilter({ initialOrder: 'desc', initialOrderBy: 'created' });

  const hasFilter = Object.keys(filter).length;

  const { data, isLoading } = useQuery(
    ['buyersProductsList', parsedFilter],
    () => getBuyersProductsList(parsedFilter),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const productsList = useMemo(() => (!isStory ? data?.products || [] : mockData), [data?.products, isStory, mockData]);
  const totalCount = useMemo(() => (!isStory ? data?.total : mockTotal), [data?.total, isStory, mockTotal]);
  const loading = useMemo(() => (!isStory ? isLoading : false), [isLoading, isStory]);

  const {
    data: { options: categories },
  } = useQuery(['options', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const filtersList = useMemo(
    () => ({
      main_categories: categories,
      statuses: [...Object.values(PROJECT_STATUS_FILTER)],
    }),
    [categories]
  );

  const headingsOnClick = useMemo(
    () =>
      buyerProductsListHeadings.map((item) =>
        !item.sortable && !item.readOnly
          ? {
              ...item,
              menuOptions: addFilters(item, filtersList, setFilter, filter, BUYER_PRODUCT_PILL_STATUS_TEXT),
            }
          : item
      ),
    [filter, filtersList, setFilter]
  );
  const notFound = productsList?.length === 0;
  const showNotFoundMessage = notFound && !hasFilter;

  return (
    <>
      {loading && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}
      {!loading && (
        <CustomTableWrapper
          noResultsTitle={showNotFoundMessage ? 'No products found' : ''}
          noResultsDescription={showNotFoundMessage ? "You haven't added any products yet. Let's get started!" : ''}
          btnText={showNotFoundMessage ? 'Find Products' : ''}
          onBtnClick={showNotFoundMessage ? () => navigate(routes.products) : () => {}}
        >
          <TableStyled>
            <CustomTableHeader
              columnHeadings={headingsOnClick}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <CustomTableBody>
              {!productsList.length && <NoFilteringMatch item="products" cols={buyerProductsListHeadings.length} />}
              {productsList?.map((product, index) => {
                const {
                  image,
                  name,
                  categories,
                  date_sold,
                  price,
                  application_fee,
                  product_uuid,
                  status,
                  currency = 'gbp',
                } = product;
                const currencyIcon = CURRENCY_MAP[currency] || '£';
                const isRejected = status === PROJECT_STATUS.REJECTED;
                return (
                  <CustomTableRow
                    onClick={!isRejected && (() => navigate(`${routes.productsDashboard}/${product_uuid}/product`))}
                    key={product_uuid}
                  >
                    <CustomTableBodyCell>
                      <ProductDetailsWrapper>{<ItemInfo avatar={image || {}} label={name} />}</ProductDetailsWrapper>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <PillsWrapperStyled>
                        {getUniqueList(categories, 'slug')?.map((category, i) => {
                          const { name, slug } = category;
                          return (
                            <Box key={`${category}_${i}`}>
                              <PillTag option={{ label: name, value: slug }} />
                            </Box>
                          );
                        })}
                      </PillsWrapperStyled>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <TableBodyText text={date_sold ? date_sold : '--'} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <TableBodyText text={price ? `${currencyIcon}${parseFloat(price).toFixed(2)}` : '0'} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <TableBodyText
                        text={application_fee ? `${currencyIcon}${parseFloat(application_fee).toFixed(2)}` : '--'}
                      />
                    </CustomTableBodyCell>
                    {status && (
                      <CustomTableBodyCell>
                        <StatusPill
                          label={BUYER_PRODUCT_PILL_STATUS_TEXT[status]}
                          status={PILL_STATUS_MAP[status]}
                          icon={PILL_STATUS_ICON[status]}
                        />
                      </CustomTableBodyCell>
                    )}
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </TableStyled>
          {!notFound && (
            <CustomTablePagination
              count={totalCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPage={l}
              rowsPerPageOptions={[5, 10, 15]}
              page={p}
            />
          )}
        </CustomTableWrapper>
      )}
    </>
  );
};

BuyerProductsList.propTypes = {};

export default BuyerProductsList;
