import { Box, styled } from '@mui/material';

export const ItemInfoContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
}));

export const ItemInfoTextWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  flex: 1,
  maxWidth: '200px',
  marginRight: 'auto',
}));
