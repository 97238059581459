import React from 'react';
import PropTypes from 'prop-types';
import { StarRatingStyled } from './StarRating.styles';

const StarRating = ({ value, onChange }) => {
  return <StarRatingStyled onChange={(e, newVal) => onChange(e, newVal)} value={value} />;
};

StarRating.propTypes = {
  value: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  onChange: PropTypes.func.isRequired,
};

export default StarRating;
