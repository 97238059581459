import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import MoodboardProductList from 'components/MoodboardProductList/MoodboardProductList';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';

const MoodboardSingle = ({
  name,
  description,
  moodboardProducts,
  handleOnHiddenClick,
  moodboard_uuid,
  isOwner,
  refetch,
  setMessage,
}) => {
  return (
    <Box>
      <Box my={4}>
        <CustomTitle text={name} variant="h3" />
        <Box mt={2}>
          <BodyText text={description} />
        </Box>
      </Box>
      <MoodboardProductList
        moodboardProducts={moodboardProducts}
        handleOnHiddenClick={handleOnHiddenClick}
        moodboard_uuid={moodboard_uuid}
        refetch={refetch}
        setMessage={setMessage}
        isOwner={isOwner}
      />
    </Box>
  );
};

MoodboardSingle.propTypes = {};

export default MoodboardSingle;
