import { Box, Modal, styled } from '@mui/material';

export const CustomModalStyled = styled(Modal)(({ theme }) => ({}));

export const PdfModalContentContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '25px 50px',
  zIndex: '5000',
  display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: 5,
  minHeight: '300px',
  minWidth: '350px',
  maxHeight: '85vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  '.react-pdf__Page > canvas ': {
    width: '100%',
    maxWidth: '100vw',
    // maxHeight: '100vh',
  },
  [theme.breakpoints.down('md')]: {
    maxWidth: '100vw',
    maxHeight: '100vh',
    padding: '0px',
  },
  '& h4': {
    marginBottom: theme.spacing(2),
  },
}));

export const LoadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 'auto',
}));

export const PdfModalCloseWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(3),
  right: theme.spacing(2),
  zIndex: '5001',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': {
    transform: 'scale(1.4)',
  },
}));

export const PdfContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
}));

export const PDFNextBtnWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  right: 0,
  transform: 'translateY(-50%)',
  '& > *': {
    transform: 'scale(1.4)',
  },
  zIndex: '5001',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const PDFBackBtnWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
  '& > *': {
    transform: 'scale(1.4)',
  },
  zIndex: '5001',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const PDFPageNumWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  zIndex: '5001',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 50,
}));
