export const getImageSrc = (image) => {
  const fileSrc = image?.fileSrc || '';
  const fileType = image?.fileType || 'image';
  const fileName = image?.name || '';
  const src = fileType === 'url' && fileSrc ? atob(fileSrc) : fileSrc;
  return {
    fileSrc: src,
    name: fileName,
    fileType,
    file_uuid: image?.file_uuid || '',
  };
};
