import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

import { accountTypes } from '../../options/accountTypes';

export const PricingOptionsTableData = [
  {
    title: 'Solo',
    accountType: accountTypes.solo,
    priceTitle: '£0',
    description: 'One single account — An individual account gives access for one user to BuyDesign',
    listItems: [
      { label: 'Browse Products', icon: <CheckIcon /> },
      { label: 'Search By Designer', icon: <CheckIcon /> },
      { label: 'Brief individual designers', icon: <CheckIcon /> },
      { label: 'Brief multiple designers', icon: <CloseIcon /> },
      { label: 'Number of Users', icon: '1' },
      { label: 'Moodboards', icon: '1' },
    ],
    buttonText: 'Solo',
    buttonColour: 'secondary',
    showDivider: true,
  },
  {
    title: 'Business',
    accountType: accountTypes.business,
    priceTitle: '£0',
    description: 'A Business Account allows you to manage five profiles on behalf of your business.',
    listItems: [
      { label: 'Browse Products', icon: <CheckIcon /> },
      { label: 'Search By Designer', icon: <CheckIcon /> },
      { label: 'Brief individual designers', icon: <CheckIcon /> },
      { label: 'Brief multiple designers', icon: <CloseIcon /> },
      { label: 'Number of Users', icon: '2' },
      { label: 'Moodboards', icon: <AllInclusiveIcon /> },
    ],
    buttonText: 'Business',
    buttonColour: 'primary',
    showDivider: true,
  },
  {
    title: 'Enterprise',
    accountType: accountTypes.enterprise,
    priceTitle: '£0',
    description: 'A Enterprise Account allows you to manage six plus profiles on behalf of your business.',
    listItems: [
      { label: 'Browse Products', icon: <CheckIcon /> },
      { label: 'Search By Designer', icon: <CheckIcon /> },
      { label: 'Brief individual designers', icon: <CheckIcon /> },
      { label: 'Brief multiple designers', icon: <CloseIcon /> },
      { label: 'Number of Users', icon: '5+' },
      { label: 'Moodboards', icon: <AllInclusiveIcon /> },
    ],
    buttonText: 'Enterprise',
    buttonColour: 'secondary',
    showDivider: true,
  },
];
