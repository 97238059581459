import React from 'react';

import { Box } from '@mui/material';
import ControlledSelectField from 'components/Inputs/CustomSelectField/ControlledSelectField/ControlledSelectField';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import ControlledDatePicker from 'components/Inputs/CustomDatePicker/ControlledDatePicker/ControlledDatePicker';
import ControlledAutoCompleteField from 'components/Inputs/CustomAutoCompleteField/ControlledAutoCompleteField.jsx/ControlledAutoCompleteField';
import ControlledCustomFiledUpload from 'components/CustomFileUpload/ControlledCustomFileUpload/ControlledCustomFileUpload';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';

import { parseRemoteOptions } from 'utils/parseRemoteOptions';

import { DESIGNER_COUNTRIES } from 'options/countries';
import { wpRoutes } from 'options/routes';

const DesignerSignUpProfile = ({ options }) => {
  const parsedProducts = parseRemoteOptions(options);
  return (
    <div>
      <Box>
        <CustomFieldSet
          label="Profile Picture"
          description={
            <>
              Follow our guides to upload your portrait found{' '}
              <CustomStandardLink
                linkType="primary"
                text="here"
                href={wpRoutes.resourcesImageGuidelines}
                target="_blank"
              />
              , you may change this at any time after completing your account.
            </>
          }
        >
          <ControlledCustomFiledUpload variant="single" fileTypes="images" numberOfTiles={1} name="portrait" />
        </CustomFieldSet>
      </Box>
      <Box mt={2}>
        <CustomFieldSet>
          <ControlledTextField name="studio_name" label="Studio Name" showSuccess />
          <ControlledTextField name="website" label="Website" type="url" showSuccess />
          <ControlledAutoCompleteField name="studio_country" label="Country" options={DESIGNER_COUNTRIES} showSuccess />
        </CustomFieldSet>
      </Box>
      <Box mt={6}>
        <CustomFieldSet
          label="When did you start your career?"
          description="When you finished studies and started working professionally."
        >
          <ControlledDatePicker
            name="designer_since"
            label="Designer Since"
            views={['year', 'month']}
            inputFormat="MM/yyyy"
            showSuccess
            disableFuture
          />
        </CustomFieldSet>
      </Box>
      <Box mt={6}>
        <CustomFieldSet
          label="What type of products do you create?"
          description="We’ll use this information to recommend relevant content, useful resources and more – just for you."
        >
          <ControlledSelectField name="product_types" label="Select All That Apply" options={parsedProducts} />
        </CustomFieldSet>
      </Box>
    </div>
  );
};

export default DesignerSignUpProfile;
