import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';

import { StyledEmptyListCreateNewWrapper } from './EmptyListCreateNew.styles';

const EmptyListCreateNew = ({ title, description, btnText, onClick, icon, secondBtnText, secondBtnOnClick }) => {
  return (
    <StyledEmptyListCreateNewWrapper pt={2}>
      {title && <CustomTitle text={title} variant={'h4'} />}
      {description && <BodyText text={description} />}
      <Box display="flex" gap={'4px'} justifyContent="center">
        {btnText && onClick ? (
          icon ? (
            <CustomIconButton colour={'black'} icon={icon} text={btnText} onClick={onClick} />
          ) : (
            <CustomButton text={btnText} onClick={onClick} />
          )
        ) : null}
        {secondBtnText && secondBtnOnClick ? <CustomButton text={secondBtnText} onClick={secondBtnOnClick} /> : null}
      </Box>
    </StyledEmptyListCreateNewWrapper>
  );
};

EmptyListCreateNew.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
};

export default EmptyListCreateNew;
