import { useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';

import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import ProductBuyerList from 'components/ProductBuyerList/ProductBuyerList';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

import { routes } from 'options/routes';
import { MOCK_PRODUCT_PROPOSAL_LIST } from 'mockData/mockProduct';

import { getDesignerProductNegotiations } from 'api';

export const DesignerProductBuyerList = () => {
  const { product_uuid } = useParams();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery(['productBuyerList'], () => getDesignerProductNegotiations(product_uuid), {
    enabled: true,
    staleTime: 2 * 60 * 1000,
  });

  const productBuyersList = data?.product_buyers;
  const productName = data?.product_name;

  return (
    <DesignerDashboardWrapper
      activeTab={routes.productsDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Products', onClick: () => navigate(`${routes.productsDashboard}`) }]}
          currentPage={productName}
        />
      }
    >
      <HeaderAndMenu title={productName} />
      {isLoading ? (
        <Box my={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <ProductBuyerList productBuyersList={productBuyersList} product_uuid={product_uuid} />
      )}
    </DesignerDashboardWrapper>
  );
};

export default DesignerProductBuyerList;
