import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import Spinner from 'components/Spinner/Spinner';
import { StripeRefreshPageWrapper } from './StripeRefresh.styles';

import { routes } from 'options/routes';
import { userTypes } from 'options/userTypes';

import { getStripeAccountUrl } from 'api';

const StripeRefresh = ({ userType }) => {
  const navigate = useNavigate();
  const redirect = async () => {
    const res = await getStripeAccountUrl();
    if (res.success && res.url) {
      window.location.href = res.url;
    } else {
      navigate(routes.account);
    }
  };

  useEffect(() => {
    if (userType) {
      if (userType !== userTypes.designer) navigate(routes.home);
      else redirect();
    }
  }, [userType]);

  return (
    <StripeRefreshPageWrapper>
      <Spinner align={'center'} />
    </StripeRefreshPageWrapper>
  );
};

StripeRefresh.propTypes = {};

export default StripeRefresh;
