import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PitchProposalSuccess from 'components/PitchProposalSuccess/PitchProposalSuccess';

import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';

const DesignerPitchNewThanks = ({ userType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from;

  useEffect(() => {
    if (userType && userType !== userTypes.designer) navigate(routes.home);
  }, [userType, navigate]);

  return (
    <DesignerDashboardWrapper
      activeTab={routes.pitchesDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[
            { label: 'Pitches', onClick: () => navigate(routes.pitchesDashboard) },
            {
              label: from === 'conversion' ? 'New Pitch Conversion' : 'Add New Pitch',
              onClick: () => navigate(from === 'conversion' ? routes.pitchesConversionAdd : routes.pitchesAdd),
            },
          ]}
          currentPage={'Thanks'}
        />
      }
    >
      <PitchProposalSuccess />
    </DesignerDashboardWrapper>
  );
};

export default DesignerPitchNewThanks;
