import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { BRIEF_STATUS_BUYER } from 'options/briefStatus';
import { PROJECT_STATUS } from 'options/projectStatus';
import { PROPOSAL_STATUS } from 'options/proposalStatus';

export const getIsProposalTabDisabled = (project_status, proposal_status, brief_status) => {
  if (proposal_status === PROPOSAL_STATUS.REJECTED) return true;
  if (brief_status === BRIEF_STATUS_BUYER.CANCELLED) return true;
  return project_status !== PROJECT_STATUS.ACCEPTED && project_status !== PROJECT_STATUS.COMPLETED;
};

export const getIsAgreementTabDisabled = (project_status, proposal_status, brief_status) => {
  if (brief_status === BRIEF_STATUS_BUYER.CANCELLED) return true;
  return project_status === PROJECT_STATUS.REJECTED || proposal_status === PROPOSAL_STATUS.REJECTED;
};

export const getIsFilesTabDisabled = (agreement_status, brief_status) => {
  if (brief_status === BRIEF_STATUS_BUYER.CANCELLED) return true;
  return !(agreement_status === AGREEMENT_STATUS.PAID || agreement_status === AGREEMENT_STATUS.UPFRONT_PAID);
};

export const getShowCompleteProject = (agreement_status, project_status) => {
  return agreement_status === AGREEMENT_STATUS.PAID && project_status !== PROJECT_STATUS.COMPLETED;
};

export const getShowCheckoutButton = (agreement_status, project_status) => {
  return (
    (agreement_status === AGREEMENT_STATUS.AGREED || agreement_status === AGREEMENT_STATUS.UPFRONT_PAID) &&
    project_status !== PROJECT_STATUS.COMPLETED
  );
};
