import { styled, Box } from '@mui/material';
import { MAX_WIDTH, MD_WIDTH, SM_WIDTH } from 'styles/constants';

export const ContentWrapper = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  margin: '78px auto',
  width: MD_WIDTH,
  maxWidth: MAX_WIDTH,
  [theme.breakpoints.down('md')]: {
    width: SM_WIDTH,
    margin: `${theme.spacing(2)} auto`,
  },
}));
