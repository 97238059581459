import { useState, useEffect, useMemo } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import AccountFormContainer from '../AccountFormContainer/AccountFormContainer';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import TwoFormFieldsRow from 'components/TwoFormFieldsRow/TwoFormFieldsRow';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ControlledSelectField from 'components/Inputs/CustomSelectField/ControlledSelectField/ControlledSelectField';
import ControlledAutoCompleteField from 'components/Inputs/CustomAutoCompleteField/ControlledAutoCompleteField.jsx/ControlledAutoCompleteField';
import ControlledDatePicker from 'components/Inputs/CustomDatePicker/ControlledDatePicker/ControlledDatePicker';
import ControlledCustomFiledUpload from 'components/CustomFileUpload/ControlledCustomFileUpload/ControlledCustomFileUpload';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import FormError from '../FormError/FormError';

import { parseRemoteOptions } from 'utils/parseRemoteOptions';
import { parseDateToYear } from '../../../utils/parseDates';
import { CURRENCY_OPTIONS } from 'options/currency';
import { COUNTRIES } from 'options/countries';
import { ANNUAL_PRODUCTS_LAUNCH, NUMBER_OF_EMPLOYESS } from 'options/selectOptions';
import { accountTypes } from '../../../options/accountTypes';
import { wpRoutes } from 'options/routes';

import { parseChoicesToArray } from '../../../utils/parseChoicesToArray';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { validationSchema } from './BuyerCompanyDetailsForm.data';

import { useAuthContext } from 'context/AuthContext';

import { updateAccountCompany } from '../../../api';

const BuyerCompanyDetailsForm = ({
  productsOptions,
  companyOptions,
  spacesOptions,
  formData,
  accountType,
  setSuccess,
  isStory,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [selectOptions] = useState({
    productsOptions: parseRemoteOptions(productsOptions),
    companyOptions: parseRemoteOptions(companyOptions),
    spacesOptions: parseRemoteOptions(spacesOptions),
  });
  const { getUserProfile } = useAuthContext();

  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      logo: [],

      jurisdiction: '',
      company_number: '',
      name: '',

      address_1: '',
      address_2: '',
      address_3: '',
      city: '',
      county: '',
      country: null,
      post_code: '',

      website: '',
      about: '',

      established_since: null,
      employee_count: [],
      annual_product_launches: [],
      currency: [],

      product_interests: [],
      buyer_spaces: [],
      buyer_categories: [],

      twitter: '',
      instagram: '',
      linkedin: '',
      pinterest: '',
    },
    resolver,
  });

  const { reset } = methods;

  useEffect(() => {
    reset({ ...formData });
  }, [reset, formData]);

  const numberOfEmployees = useMemo(() => parseRemoteOptions(NUMBER_OF_EMPLOYESS), []);
  const annualProductLaunches = useMemo(() => parseRemoteOptions(ANNUAL_PRODUCTS_LAUNCH), []);

  const onSubmit = async (data) => {
    setLoading(true);

    // Parse form data
    const submitData = {
      name: data.name,
      country: data.country,
      website: data.website,
      established_since: data.established_since ? parseDateToYear(new Date(data.established_since)) : '',
      product_interests: parseChoicesToArray(data.product_interests),
      buyer_categories: parseChoicesToArray(data.buyer_categories),
      buyer_spaces: parseChoicesToArray(data.buyer_spaces),
      account_type: accountType,
    };
    if (data.logo.length) submitData.logo = data.logo[0] instanceof Object ? data.logo[0].fileSrc : data.logo[0];
    if (data.jurisdiction) submitData.jurisdiction = data.jurisdiction;
    if (data.company_number) submitData.company_number = data.company_number;
    if (data.address_1) submitData.address_1 = data.address_1;
    if (data.address_2) submitData.address_2 = data.address_2;
    if (data.address_3) submitData.address_3 = data.address_3;
    if (data.city) submitData.city = data.city;
    if (data.county) submitData.county = data.county;
    if (data.post_code) submitData.post_code = data.post_code;
    if (data.about) submitData.about = data.about;
    if (data.employee_count.length) submitData.employee_count = data.employee_count[0]?.value;
    if (data.annual_product_launches.length)
      submitData.annual_product_launches = data.annual_product_launches[0]?.value;
    if (data.currency.length) submitData.currency = data.currency[0]?.value;
    if (data.twitter) submitData.twitter = data.twitter;
    if (data.instagram) submitData.instagram = data.instagram;
    if (data.linkedin) submitData.linkedin = data.linkedin;
    if (data.pinterest) submitData.pinterest = data.pinterest;

    // Do not make API call if using component in storybook
    if (isStory) {
      console.log(submitData);
      setLoading(false);
      return;
    }

    // Post to API
    const response = await updateAccountCompany(submitData);
    if (!response.success) {
      // Set error and reset form data to original values
      setFormError(response.data.error_message);
      reset({ ...formData });
      setLoading(false);
      return;
    }

    // Set success message in parent component
    setSuccess('Company data has been successfully updated.');
    getUserProfile();
    setLoading(false);
  };

  return (
    <AccountFormContainer
      titleText="Company Details"
      tooltipText={`Please enter here your company details.`}
      tooltipParagrahs={[
        'Your company details will be visible to all designers.',
        'This information is essentially the introduction to you, explaining who you are and what you do.',
        'You can copy and paste from your website, who you are, your mission and your vision.',
        "Please view 'Profile' in the menu to view what a designer will see. If you have any issues at support@buydesign.com.",
        'Please save each section separately.',
      ]}
      descriptionText={
        <>
          Manage your company profile and settings here. If need any help managing your account please send us a message{' '}
          <CustomStandardLink
            linkType="primary"
            text="here"
            href="/help"
            onClick={() => {
              navigate('/help');
            }}
            fontSize="large"
          />
          , or email{' '}
          <CustomStandardLink
            linkType="primary"
            text="support@buydesign.com."
            href={'mailto:support@buydesign.com'}
            fontSize="large"
          />
        </>
      }
      onClick={methods.handleSubmit(onSubmit)}
      buttonText="Save this section"
      loading={loading}
    >
      {formError && <FormError errors={[formError]} />}
      <FormProvider {...methods}>
        <form>
          <CustomFieldSet
            label="Company Logo"
            tooltipText="Please upload your company logo here."
            tooltipParagrahs={[
              'If your company logo is not formatted correctly, BuyDesign will contact you or adjust the formatting to the correct size.',
              'We can assist you in uploading your logo after you have completed your account.',
              'If you have any issues at support@buydesign.com. Please save each section separately.',
            ]}
            description={
              <>
                Follow our guides found in the resources page{' '}
                <CustomStandardLink
                  linkType="primary"
                  text="here"
                  href={wpRoutes.resourcesImageGuidelines}
                  target={'_blank'}
                  fontSize="large"
                />{' '}
                to upload your company logo.
              </>
            }
          >
            <ControlledCustomFiledUpload variant="single" fileTypes="images" numberOfTiles={1} name="logo" />
          </CustomFieldSet>

          <Box>
            <CustomFieldSet>
              <TwoFormFieldsRow>
                <ControlledTextField name="jurisdiction" label="Jurisdiction" />
                <ControlledTextField name="company_number" label="Company Number" />
              </TwoFormFieldsRow>
              <ControlledTextField name="name" label="Company Name" />
              <ControlledTextField name="address_1" label="Company Address 1" />
              <ControlledTextField name="address_2" label="Company Address 2" />
              <ControlledTextField name="address_3" label="Company Address 3" />
              <ControlledTextField name="city" label="City" />
              <TwoFormFieldsRow>
                <ControlledTextField name="county" label="County" />
                <ControlledAutoCompleteField name="country" label="Country" options={COUNTRIES} />
              </TwoFormFieldsRow>
              <ControlledTextField name="post_code" label="Post Code" />
              <ControlledTextField name="website" label="Website" />
              <ControlledTextField name="about" label="About" multiline minRows={2} />
            </CustomFieldSet>
          </Box>

          <Box mt={6}>
            <TwoFormFieldsRow>
              <CustomFieldSet label="Established since">
                <ControlledDatePicker
                  name="established_since"
                  label="Established Since"
                  views={['year']}
                  inputFormat="yyyy"
                  showError={false}
                />
              </CustomFieldSet>
              <CustomFieldSet label="Number Of Employees">
                <ControlledSelectField
                  name="employee_count"
                  label="Number Of Employees"
                  options={numberOfEmployees}
                  isSingleSelect
                />
              </CustomFieldSet>
            </TwoFormFieldsRow>
          </Box>

          <Box>
            <TwoFormFieldsRow>
              <CustomFieldSet label="How many products do you launch each year?">
                <ControlledSelectField
                  name="annual_product_launches"
                  label="Products launched per year"
                  options={annualProductLaunches}
                  isSingleSelect
                />
              </CustomFieldSet>
              <CustomFieldSet label="Select Currency">
                <ControlledSelectField name="currency" label="Currency" options={CURRENCY_OPTIONS} isSingleSelect />
              </CustomFieldSet>
            </TwoFormFieldsRow>
          </Box>
          <Box mt={6} />
          <CustomFieldSet
            label="What type of products are you interested in?"
            description={
              'Select one or more categories. We’ll use this information to recommend relevant content and ' +
              'resources around Buyers, resources, and more – just for you.'
            }
            tooltipText={'What type of products are you interested in?'}
            tooltipParagrahs={[
              'Select one or more categories. We’ll use this information to recommend relevant content and resources – just for you.',
            ]}
          >
            <ControlledSelectField
              name="product_interests"
              label="Select all that apply"
              options={selectOptions.productsOptions}
            />
          </CustomFieldSet>
          <Box mt={6} />
          <CustomFieldSet
            label="What type of space are you interested in?"
            description={
              'Select one or more categories. We’ll use this information to recommend relevant content ' +
              'and resources around Buyers, resources, and more – just for you.'
            }
          >
            <ControlledSelectField
              name="buyer_spaces"
              label="Select all that apply"
              options={selectOptions.spacesOptions}
            />
          </CustomFieldSet>
          <Box mt={6} />
          <CustomFieldSet
            label="How would you describe your company?"
            description="Select one or more categories. We’ll use this information to recommend relevant content and resources around Buyers, resources, and more – just for you."
          >
            <ControlledSelectField
              name="buyer_categories"
              label="Select All That Apply"
              options={selectOptions.companyOptions}
            />
          </CustomFieldSet>
          <Box mt={6}>
            <CustomFieldSet
              label="Social Media (Company)"
              description="Please provide the full url of the account starting: https://www..."
            >
              <TwoFormFieldsRow>
                <ControlledTextField name="twitter" label="Twitter" />
                <ControlledTextField name="instagram" label="Instagram" />
              </TwoFormFieldsRow>
              <TwoFormFieldsRow>
                <ControlledTextField name="linkedin" label="Linkedin" />
                <ControlledTextField name="pinterest" label="Pinterest" />
              </TwoFormFieldsRow>
            </CustomFieldSet>
          </Box>
        </form>
      </FormProvider>
    </AccountFormContainer>
  );
};

BuyerCompanyDetailsForm.propTypes = {
  productsOptions: PropTypes.array,
  companyOptions: PropTypes.array,
  spacesOptions: PropTypes.array,
  formData: PropTypes.object,
  accountType: PropTypes.oneOf(['', accountTypes.solo, accountTypes.business, accountTypes.enterprise]),
  setSuccess: PropTypes.func,
  isStory: PropTypes.bool,
};

export default BuyerCompanyDetailsForm;
