export const parseFormData = ({ main_image, ...rest }, isLastStep) => {
  return {
    main_image: main_image[0],
    ...rest,
    ...(isLastStep ? { status: 'pending_approval' } : {}),
  };
};

export const parseRemoteData = ({ main_image, file_formats, id, width, height, depth, ...rest }) => {
  return {
    main_image: main_image && Object.keys(main_image).length ? [main_image] : [],

    width: width ? String(width) : '',
    height: height ? String(height) : '',
    depth: depth ? String(depth) : '',

    ...rest,
  };
};
