import React, { useCallback, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import ChatBox from 'components/ChatBox/ChatBox';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Spinner from 'components/Spinner/Spinner';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import DesignerTabSectionsWrapper from 'components/Wrappers/TabSectionsWrapper/DesignerTabSectionsWrapper/DesignerTabSectionsWrapper';
import BodyText from 'components/Typography/BodyText/BodyText';
import TopBarTitleStatus from 'components/TopBarTitleStatus/TopBarTitleStatus';
import ScrollableContainer from 'components/ScrollableContainer/ScrollableContainer';

import { routes } from 'options/routes';
import { PROJECT_STATUS } from 'options/projectStatus';

import { capitalize } from 'utils/capitalize';
import { parseRemoteProductProposalData } from 'utils/parseProductProposal';
import { getIsAgreementTabDisabled, getIsFilesTabDisabled } from '../DesignerSingleProduct.utils';

import { options } from './DesignerSingleProductLanding.data';

import { useMessageContext } from 'context/MessageContext';

import {
  deleteDesignerProductFiles,
  downloadDesignerProductFiles,
  getDesignerProductTab,
  getProductChatMessages,
  postProductChatMessage,
  uploadDesignerProductFiles,
} from 'api';

const DesignerSingleProductLanding = ({ userType }) => {
  const { product_uuid, buyer_product_uuid } = useParams();
  const [setMessage] = useMessageContext();
  const navigate = useNavigate();

  const [showMessages, setShowMessages] = useState(true);

  const location = useLocation();
  const pathnameArr = location?.pathname.split('/');
  const pathName = pathnameArr[pathnameArr.length - 1];

  const { data, isLoading } = useQuery(
    ['designerSingleProduct', product_uuid, buyer_product_uuid],
    () => getDesignerProductTab(product_uuid, buyer_product_uuid),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const product = (data && data.product && parseRemoteProductProposalData(data?.product)) || {};
  const { name, agreement_status, chat_uuid, project_status } = product;

  const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status);
  const isAgreementTabDisabled = getIsAgreementTabDisabled(agreement_status);
  const isProjectCompleted = project_status === PROJECT_STATUS.COMPLETED;

  const handleSectionClick = useCallback(
    (path) => {
      navigate(`${routes.productsDashboard}/${product_uuid}/${buyer_product_uuid}${path}`);
    },
    [navigate, buyer_product_uuid, product_uuid]
  );

  const toggleMessages = useCallback(() => {
    setShowMessages((prev) => !prev);
  }, []);

  return (
    <DesignerTabSectionsWrapper
      title={name}
      headerMenuOptions={[
        {
          buttonTitle: showMessages ? 'Hide Messages' : 'Show Messages',
          icon: showMessages ? <SpeakerNotesOffIcon fontSize="small" /> : <ChatIcon fontSize="small" />,
          onClick: toggleMessages,
        },
        {
          buttonTitle: 'Back',
          icon: <ArrowBackIcon fontSize="small" />,
          onClick: () => navigate(`${routes.productsDashboard}`),
          animate: true,
        },
      ]}
      activeTab={routes.productsDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[
            { label: 'Products', onClick: () => navigate(routes.productsDashboard) },
            { label: name, onClick: () => navigate(`${routes.productsDashboard}/${product_uuid}`) },
          ]}
          currentPage={capitalize(pathName)}
        />
      }
      handleSectionClick={handleSectionClick}
      collapse={showMessages}
      options={options.map((option) => {
        if (option.path === '/agreement') {
          return {
            ...option,
            disabled: isAgreementTabDisabled,
          };
        }
        if (option.path === '/files') {
          return {
            ...option,
            disabled: isFilesTabDisabled,
          };
        }
        return option;
      })}
      chatbox={
        <ChatBox
          chat_uuid={chat_uuid}
          getChatMessages={() => getProductChatMessages(chat_uuid)}
          postChatMessage={(chat_uuid, data) => postProductChatMessage(chat_uuid, data)}
          downloadFiles={(data) => downloadDesignerProductFiles(product_uuid, buyer_product_uuid, data, true)}
          uploadFiles={(data) => uploadDesignerProductFiles(product_uuid, buyer_product_uuid, data)}
          deleteFiles={(file_uuid) => deleteDesignerProductFiles(product_uuid, buyer_product_uuid, file_uuid)}
          userType={userType}
          expand={showMessages}
          setExpand={toggleMessages}
        />
      }
    >
      {isLoading && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}

      {!isLoading && (
        <>
          <TopBarTitleStatus name={product?.company?.name} project_status={project_status} />
          {!isLoading && isProjectCompleted && (
            <Box mt={2}>
              <BodyText text="This project has been completed." />
            </Box>
          )}
          <ScrollableContainer collapse={showMessages}>
            <Outlet context={[product, setMessage]} />
          </ScrollableContainer>
        </>
      )}
    </DesignerTabSectionsWrapper>
  );
};

DesignerSingleProductLanding.propTypes = {};

export default DesignerSingleProductLanding;
