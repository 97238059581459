import React from 'react';
import PropTypes from 'prop-types';
import { TableSortLabel, useTheme } from '@mui/material';
import CustomTableHeadCell from '../CustomTableHeadCell/CustomTableHeadCell';
import {
  CustomTableHeadCellContentWrapper,
  StyledTableHead,
  StyledTableHeadText,
  StyledTableRow,
} from './CustomTableHeader.styles';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import CustomTableCheckbox from 'components/Inputs/CustomTableCheckbox/CustomTableCheckbox';
import { Box } from '@mui/system';

const CustomTableHeader = ({
  order,
  orderBy,
  onRequestSort,
  columnHeadings,
  checkboxClick,
  checked,
  indeterminate,
}) => {
  const createSortHandler = (e, id) => {
    if (onRequestSort) {
      let newOrder;
      if (order === 'asc') newOrder = 'desc';
      if (order === 'desc' || !order) newOrder = 'asc';
      onRequestSort(e, id, newOrder);
    }
  };

  const theme = useTheme();

  return (
    <StyledTableHead>
      <StyledTableRow>
        {columnHeadings &&
          columnHeadings.map(({ id, label, sortable, menuOptions, flexEnd }) => {
            return (
              <CustomTableHeadCell key={id}>
                <CustomTableHeadCellContentWrapper flexEnd={flexEnd}>
                  {id !== 'checkbox' ? (
                    <>
                      <StyledTableHeadText bold={id === orderBy}>{label}</StyledTableHeadText>

                      {sortable && (
                        <TableSortLabel
                          direction={orderBy === id ? order : 'asc'}
                          onClick={(e) => createSortHandler(e, id)}
                          sx={{
                            padding: '6px 4px',
                            borderRadius: '50px',
                            '&:hover': {
                              backgroundColor: theme.palette.common.peach,
                              opacity: 1,
                              '& .MuiTableSortLabel-icon': {
                                opacity: 1,
                              },
                            },
                          }}
                        />
                      )}
                      {menuOptions && menuOptions.length > 0 && (
                        <Box ml={1}>
                          <ThreeDotMenu hoverPeachBg={true} variant="arrow" actions={menuOptions} />
                        </Box>
                      )}
                    </>
                  ) : (
                    <CustomTableCheckbox onClick={checkboxClick} checked={checked} indeterminate={indeterminate} />
                  )}
                </CustomTableHeadCellContentWrapper>
              </CustomTableHeadCell>
            );
          })}
      </StyledTableRow>
    </StyledTableHead>
  );
};

CustomTableHeader.propTypes = {
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func,
  columnHeadings: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CustomTableHeader;
