import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FormFieldSetAndInputWrapper from 'components/Wrappers/FormFieldSetAndInputWrapper/FormFieldSetAndInputWrapper';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import SingleInputResponsiveWrapper from 'components/Wrappers/SingleInputResponsiveWrapper/SingleInputResponsiveWrapper';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ControlledCustomRadioGroupWithTextField from 'components/Inputs/ControlledCustomRadioGroupWithTextField/ControlledCustomRadioGroupWithTextField';
import ControlledCustomRadioGroup from 'components/Inputs/CustomRadioStandardGroup/ControlledCustomRadioGroup/ControlledCustomRadioGroup';

import { LICENSE_OPTIONS } from 'options/agreementFormOptions';
import { LICENSE_TYPES } from 'options/briefOptions';

import { ROYALTY_REVIEW_PERIODS } from '../NewBriefRoyaltyLicenseForm/NewBriefRoyaltyLicenseForm.data';
import { NEW_BRIEF_BUDGET_OPTIONS } from '../NewBriefOneTImeFeeForm/NewBriefOneTimeFeeForm.data';

const BuyerAgreementForm = ({ variant = 'briefs', agreement_type, currency = '£' }) => {
  const isBriefsVariant = variant === 'briefs';
  return (
    <Box>
      <Box>
        <FormFieldSetAndInputWrapper mb={2}>
          <CustomFieldSet
            label="What type of agreement do you need?"
            description="Add your company's general Terms and Conditions in the next step"
            marginTop={2}
          />
          <ControlledCustomRadioGroup
            name="negotiated_agreement_type"
            direction="row"
            options={LICENSE_OPTIONS.map((option) => {
              if (option.value !== agreement_type) {
                return { ...option, disabled: true };
              }
              return option;
            })}
            removeError
          />
        </FormFieldSetAndInputWrapper>
        {agreement_type === LICENSE_TYPES.royalty ? (
          <>
            <FormFieldSetAndInputWrapper mb={2}>
              <CustomFieldSet
                label="Royalty license percentage"
                description="Please add the percentage of your standard royalty payment, referencing your terms and conditions."
                tooltipText="Please enter the % you offer the designer. Please stipulate in your T&Cs if this % is based on wholesale or Retail sales."
                marginTop={2}
              />
              <SingleInputResponsiveWrapper isModal>
                <ControlledTextField
                  placeholder="0.00"
                  startAdornment={'%'}
                  name="negotiated_royalty_percentage"
                  defaultValue=""
                />
              </SingleInputResponsiveWrapper>
            </FormFieldSetAndInputWrapper>
            <FormFieldSetAndInputWrapper mb={2}>
              <CustomFieldSet
                label="Royalty review period"
                description="How frequently do you review sales and pay out royalties?"
                marginTop={2}
              />
              <ControlledCustomRadioGroupWithTextField
                radioOptions={ROYALTY_REVIEW_PERIODS}
                name="negotiated_royalty_review_period"
                inputPlaceholder={'Specify other review period'}
                isModal
              />
            </FormFieldSetAndInputWrapper>
          </>
        ) : (
          <FormFieldSetAndInputWrapper mb={2}>
            <CustomFieldSet
              label={isBriefsVariant ? 'Budget' : 'Price'}
              description={
                isBriefsVariant
                  ? 'If you wish to pay the designer a one-time fee, please indicate the amount this can be negotiated later. As per T&C, a one-time fee is subject to an additional BuyDesign 15% commission.'
                  : 'Please indicate a price for your product'
              }
              marginTop={2}
            />
            <ControlledCustomRadioGroupWithTextField
              radioOptions={
                isBriefsVariant
                  ? NEW_BRIEF_BUDGET_OPTIONS.map(({ label, value }) => ({ label: `${currency}${label}`, value }))
                  : []
              }
              name="negotiated_one_off_budget"
              inputPlaceholder={isBriefsVariant ? 'Specify other budget' : 'Specify your price'}
              inputStartAdornment={currency}
              isModal
            />
          </FormFieldSetAndInputWrapper>
        )}
        <FormFieldSetAndInputWrapper mb={2}>
          <CustomFieldSet
            label="Additional Information"
            description="Please supply any additional information"
            marginTop={2}
          />
          <ControlledTextField
            multiline={true}
            minRows={4}
            placeholder={'Any additional information'}
            name="negotiated_payment_terms"
          />
        </FormFieldSetAndInputWrapper>
      </Box>
    </Box>
  );
};

BuyerAgreementForm.propTypes = {
  variant: PropTypes.oneOf(['proposal', 'briefs']),
};

export default BuyerAgreementForm;
