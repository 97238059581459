import { styled, Box } from '@mui/material';
import { pageContentWrapperStyles } from 'components/PageContentWrapper/PageContentWrapper.styles';

export const StyledContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'collapse',
})(({ theme, collapse }) => ({
  padding: '78px 0px 6px 0',
  ...pageContentWrapperStyles(theme, { padding: '16px 0px' }, null, collapse),
}));

export const BreadcrumbsContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 30,
  left: 0,
  [theme.breakpoints.down('md')]: {
    position: 'relative',
    top: 'unset',
    left: 'unset',
    margin: 10,
  },
}));
