import React from 'react';
import CustomDivider from '../../Divider/CustomDivider';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import FormParagraph from 'components/Typography/FormParagraph/FormParagraph';
import { SignUpUserOptionContainer, DividerContainer } from './SignUpUserOption.styles';

const SignUpUserOption = ({ title, header, descriptionText, buttonText, onClick, subText, colour }) => {
  return (
    <SignUpUserOptionContainer colour={colour}>
      <DividerContainer>
        <CustomDivider orientation={'horizontal'} variant={colour === 'white' ? 'secondary' : 'primary'} />
      </DividerContainer>
      <CustomTitle text={title} variant="h6" />
      <CustomTitle text={header} variant="h1" />
      <BodyText text={descriptionText} colour={colour} />
      <CustomButton text={buttonText} onClick={onClick} variant="outlined" colour={colour} />
      <FormParagraph text={subText} variant="secondary" />
    </SignUpUserOptionContainer>
  );
};

export default SignUpUserOption;
