import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import queryString from 'query-string';
import { debounce } from 'debounce';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import PillTag from 'components/PillTag/PillTag';
import ModalListItemCheckbox from 'components/ModalListItemCheckbox/ModalListItemCheckbox';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';
import Spinner from 'components/Spinner/Spinner';
import BodyText from 'components/Typography/BodyText/BodyText';

import { COUNTRIES } from 'options/countries';
import { BUYER_CATEGORIES_OPTIONS, EXPERIENCE_OPTIONS } from 'options/findDesignerOptions';

import { addFilters } from 'utils/addFilters';

import { ListStyled, PillWrapperStyled } from 'components/ModalFindDesigner/ModalFindDesigner.styles';

import { getBuyersList, getDesignerPitchBuyerRecipients } from 'api';

const FIND_BUYER_MENU = [
  {
    label: 'Categories',
    id: 'categories',
    actions: [],
  },
  {
    label: 'Location',
    id: 'location',
    actions: [],
  },
  {
    label: 'Experience',
    id: 'experience',
    actions: [],
  },
];

const BUYER_VALUE = 'buyers';

const ModalFindBuyer = ({
  open,
  handleClose,
  isStory,
  mockData,
  onHandleAccept,
  variant,
  product_uuid,
  fetchDisabledBuyers = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [buyersList, setBuyersList] = useState(isStory ? mockData : []);
  const [buyersDisabledList, setBuyersDisabledList] = useState([]);

  const [buyersModalSelected, setBuyersModalSelected] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const [filter, setFilter] = useState({});

  const { setValue, clearErrors, getValues } = useFormContext();

  const parsedFilter = useMemo(
    () => queryString.stringify({ ...filter, ...(searchTerm && searchTerm.length > 2 ? { searchTerm } : {}) }),
    [filter, searchTerm]
  );

  const fetchBuyers = async (query) => {
    setIsLoading(true);
    const res = await getBuyersList(query);
    setIsLoading(false);
    if (!res || !res.success) {
      return;
    }
    const formSelectedBuyers = getValues(BUYER_VALUE);

    setBuyersList(res.buyers);
    setBuyersModalSelected(formSelectedBuyers || []);
  };

  const debouncedFetch = debounce((query) => fetchBuyers(query), 200);

  useEffect(() => {
    debouncedFetch(parsedFilter);
  }, [parsedFilter]);

  useEffect(() => {
    const setDisabledBuyers = async () => {
      const res = await getDesignerPitchBuyerRecipients(product_uuid);

      if (!res.success) {
        return;
      }
      setBuyersDisabledList(res?.companies_pitched);
    };
    if (variant === 'pitch' && fetchDisabledBuyers) {
      setDisabledBuyers();
    }
  }, [fetchDisabledBuyers, product_uuid, variant]);

  const selectOption = useCallback((option) => {
    setBuyersModalSelected([option]);
  }, []);

  const unSelectOption = useCallback((option) => {
    setBuyersModalSelected([]);
  }, []);

  const handleClick = useCallback(
    (option) => {
      const isSelected = !!buyersModalSelected.find((v) => v.company_uuid === option?.company_uuid);
      if (!isSelected) {
        selectOption(option);
      } else {
        unSelectOption(option);
      }
    },
    [buyersModalSelected, selectOption, unSelectOption]
  );

  const handleAccept = useCallback(async () => {
    clearErrors(BUYER_VALUE);
    setValue(BUYER_VALUE, buyersModalSelected);
    if (onHandleAccept) {
      setIsLoading(true);
      await onHandleAccept();
      setIsLoading(false);
    }
    handleClose();
  }, [onHandleAccept, clearErrors, setValue, buyersModalSelected, handleClose]);

  const handleCancel = useCallback(() => {
    setValue(BUYER_VALUE, []);
    handleClose();
  }, [handleClose, setValue]);

  const filtersList = useMemo(
    () => ({
      categories: [...BUYER_CATEGORIES_OPTIONS],
      location: [...COUNTRIES],
      experience: [...EXPERIENCE_OPTIONS],
    }),
    []
  );

  const menuFilterOptions = useMemo(() => {
    return FIND_BUYER_MENU.map((menuItem) => {
      return {
        ...menuItem,
        actions: addFilters(menuItem, filtersList, setFilter, filter),
      };
    });
  }, [filter, filtersList]);

  return (
    <CustomModal title="Find Buyer" open={open} handleClose={handleClose}>
      <Box>
        <Box>
          <SearchAndFilterOptions
            placeholder="Search for buyers"
            menuOptions={menuFilterOptions}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            isModal
          />
        </Box>
        <PillWrapperStyled>
          {buyersModalSelected?.map((buyer) => (
            <Box mb={0.5} key={buyer.company_uuid}>
              <PillTag option={{ label: buyer.name, ...buyer }} removeSelection={handleClick} />
            </Box>
          ))}
        </PillWrapperStyled>
        <Box width="100%">
          {isLoading && (
            <Box my={2}>
              <Spinner align="center" flex={0} />
            </Box>
          )}{' '}
          {!isLoading && !buyersList?.length && <BodyText text="No results found" />}
          {!isLoading && (
            <ListStyled>
              {buyersList?.map((buyer) => {
                const isSelected = !!buyersModalSelected?.find((v) => v?.company_uuid === buyer?.company_uuid);
                const isDisabled = variant === 'pitch' && buyersDisabledList?.includes(buyer?.company_uuid);
                return (
                  <ModalListItemCheckbox
                    onClick={handleClick}
                    option={{
                      label: buyer.name,
                      ...buyer,
                    }}
                    checked={isSelected}
                    key={buyer.company_uuid}
                    isDisabled={isDisabled}
                  />
                );
              })}
            </ListStyled>
          )}
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box width="100%" mr={0.5}>
          <CustomButton text="Cancel" variant="outlined" onClick={handleCancel} fullWidth />
        </Box>
        <Box width="100%" ml={0.5}>
          <CustomButton text="Accept" colour="primary" onClick={handleAccept} fullWidth />
        </Box>
      </Box>
    </CustomModal>
  );
};

ModalFindBuyer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isStory: PropTypes.bool,
  mockData: PropTypes.array,
};

export default ModalFindBuyer;
