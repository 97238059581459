import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { PROJECT_STATUS } from 'options/projectStatus';
import { PROPOSAL_STATUS } from 'options/proposalStatus';
import { getRandomFromList } from 'utils/getRandomFromList';
import {
  mockProductImageObject,
  mockProductImageObject_2,
  mockProductImageUrl_1,
  mockProductList,
} from './mockProductImage';

const STATUS_LIST = [PROPOSAL_STATUS.APPROVED, PROPOSAL_STATUS.PENDING_APPROVAL];

// TODO: Change to actual pitch mock data, this is using a copy of products
export const MOCK_PITCH_LIST_ITEM_DESIGNER = {
  image: { fileSrc: mockProductImageUrl_1, fileType: 'image' },
  name: 'Hop Chair',
  categories: [
    { name: 'Chair', slug: 'chair' },
    { name: 'Wood', slug: 'wood' },
    { name: 'Dining Room', slug: 'dining-room' },
  ],
  date_created: '03/05/2020',
  views: '10',
  status: 'pending_approval',
  pitch_uuid: '123',
  buyer_pitch_uuid: '4456',
  edit_product_url: '/some-edit-url',
};

export const MOCK_PITCH_LIST_DESIGNER = Array(10)
  .fill()
  .map((_item) => ({
    ...MOCK_PITCH_LIST_ITEM_DESIGNER,
    image: getRandomFromList(mockProductList),
    pitch_uuid: String(Math.random()),
    buyer_pitch_uuid: String(Math.random()),
    status: getRandomFromList(STATUS_LIST),
  }));

export const MOCK_PITCH = {
  main_image: [mockProductImageObject],
  additional_images: [mockProductImageObject, mockProductImageObject_2, mockProductImageObject],
  name: 'Coast Chair',
  designer_name: 'Harry Batchelor',
  concept:
    'An outdoor chair with a robust galvanised steel frame and inviting soft wooden slats. Durable, stackable, and designed from materials that will gain a natural patina over time the Coast chair with its warm wooden seat and back provides durability and comfort beyond most contemporary painted steel outdoor furniture. This is part of a family including tables and lower lounge chairs.',
  price: '10.00',
  construction:
    'Dynamic Wood Product, flat packed self-assembled. Self assembly of 5 elements with dowel and fixing construction.',
  colour_material_finish: 'Some colour material finish description',
  width: '532',
  height: '200',
  depth: '100',
  material_tag: [
    { label: 'Wood', value: 'wood' },
    { label: 'Plastic', value: 'plastic' },
    { label: 'Metal', value: 'metal' },
  ],
  colour_tag: [
    { label: 'Red', value: 'red' },
    { label: 'Orange', value: 'orange' },
    { label: 'Blue', value: 'blue' },
  ],
  production_files: [
    { fileType: '.ai', name: 'ai' },
    { fileType: '.ai', name: 'ai' },
    { fileType: '.ai', name: 'ai' },
    { fileType: '.ind', name: 'ind' },
    { fileType: '.psd', name: 'psd' },
    { fileType: '.tiff', name: 'tiff' },
    { fileType: '.pdf', name: 'pdf' },
    { fileType: '.pdf', name: 'pdf' },
    { fileType: '.pdf', name: 'pdf' },
    { fileType: '.pdf', name: 'pdf' },
  ],
  agreement_type: 'one_off',
  registered: 'yes',
  registered_info:
    'You are buying the rights, to manufacture and sell this design. As part of the purchase you will have download access to all the Technical Data Sheets, Drawings, 3D models & Visual Assets required to manufacture this design.',
  terms_accepted: true,
  product_uuid: '123',
  project_status: PROJECT_STATUS.PENDING,
  agreement_status: AGREEMENT_STATUS.AWAITING_BUYER_SIGN,
};
