import React from 'react';
import PropTypes from 'prop-types';
import {
  CustomModalContentContainer,
  CustomModalStyled,
  CustomModalTitleAndCloseBtnWrapper,
} from './CustomModal.styles';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';
import CloseIcon from '@mui/icons-material/Close';

const CustomModal = ({ children, open, handleClose, title, variant, wide }) => {
  return (
    <CustomModalStyled open={open} onClose={handleClose}>
      <CustomModalContentContainer variant={variant} wide={wide}>
        <CustomModalTitleAndCloseBtnWrapper>
          <CustomTitle variant="h3" text={title} /> <CustomIconButton onClick={handleClose} icon={<CloseIcon />} />
        </CustomModalTitleAndCloseBtnWrapper>
        {children}
      </CustomModalContentContainer>
    </CustomModalStyled>
  );
};

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default CustomModal;
