import { Box, styled } from '@mui/material';

export const ContainerStyled = styled(Box)(() => ({
  width: '100%',
}));

export const MessageContainerStyled = styled(Box)(({ theme }) => ({
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
