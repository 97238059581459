import { useCallback, useState, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import PillTag from 'components/PillTag/PillTag';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import ModalFindBuyer from 'components/ModalFindBuyer/ModalFindBuyer';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';

import { BUYERS_LIST_HEADINGS } from 'options/tableOptions';

import { filterCategories, sortByObjProp } from 'utils/sortHelpers';
import { addFilters } from 'utils/addFilters';
import { getUniqueList } from 'utils/getUniqueList';

import { TableStyled } from 'components/Table/Table.styles';
import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';
import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';

import { useTableFilter } from 'hooks/useTableFilter';

const BUYERS_KEY = 'buyers';

const NewPitchBuyersList = ({ isStory, mockData, mockTotal, pitch_uuid }) => {
  const { order, orderBy, filter, setFilter, onRequestSort, } =
    useTableFilter({ initialOrder: 'desc', initialOrderBy: 'experience' });

  const [openFindBuyers, setOpenFindBuyers] = useState(false);

  const { setValue, getValues, formState, watch } = useFormContext();

  const buyersList = watch(BUYERS_KEY);

  const onRemove = useCallback(
    (company_uuid) => {
      // remove
      const buyersValue = getValues(BUYERS_KEY);
      setValue(
        BUYERS_KEY,
        buyersValue.filter((d) => d.company_uuid !== company_uuid)
      );
    },
    [getValues, setValue]
  );

  const addMoreBuyers = useCallback(() => {
    setOpenFindBuyers(true);
  }, []);

  const allCategories = useMemo(
    () =>
      getUniqueList(
        buyersList
          ?.map(({ categories }) => categories)
          ?.flat()
          .filter(Boolean),
        'slug'
      ),
    [buyersList]
  );

  const itemList = useMemo(
    () => ({
      categories: allCategories,
    }),
    [allCategories]
  );

  const headingsOnClick = useMemo(() => {
    return BUYERS_LIST_HEADINGS.map((item) =>
      !item.sortable
        ? {
            ...item,
            menuOptions: addFilters(item, itemList, setFilter, filter),
          }
        : item
    );
  }, [filter, itemList]);

  return (
    <Box>
      <Box>
        <CustomTitle text="Add buyer" variant="h3" />
      </Box>
      <Box mt={2}>
        <CustomFieldSet label="You can select only one buyer at a time for your Pitch. If your Pitch is rejected, you will be able to choose another Buyer or upload it to your profile." />
      </Box>
      <CustomTableWrapper
        noResultsTitle={buyersList.length === 0 ? 'No Buyers found' : ''}
        noResultsDescription={buyersList.length === 0 ? "You haven't added any Buyers yet." : ''}
      >
        <TableStyled minHeight="unset">
          <CustomTableHeader
            columnHeadings={headingsOnClick}
            onRequestSort={onRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <CustomTableBody>
            {buyersList
              ?.sort((a, b) => sortByObjProp(a, b, order, orderBy))
              .filter(({ categories }) => filterCategories(categories, filter?.categories))
              .map((buyer, index) => {
                const { name, company_uuid, buyer_categories } = buyer;
                const threeDotMenuActions = [{ title: 'Remove', onClick: () => onRemove(company_uuid) }];
                return (
                  <CustomTableRow key={`table_${name}_${index}`}>
                    <CustomTableBodyCell>
                      <ItemInfo avatar={{}} label={name} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <PillsWrapperStyled>
                        {!!buyer_categories?.length
                          ? buyer_categories.map((category, i) => {
                              const { name, slug } = category;
                              return (
                                <Box key={`${category}_${i}`}>
                                  <PillTag option={{ label: name, value: slug }} />
                                </Box>
                              );
                            })
                          : '--'}
                      </PillsWrapperStyled>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <ThreeDotMenu actions={threeDotMenuActions} />
                      </Box>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
          </CustomTableBody>
        </TableStyled>
      </CustomTableWrapper>
      <Box mt={2}>
        <CustomButton text="Add buyer" onClick={addMoreBuyers} variant="outlined" />
      </Box>
      {openFindBuyers && (
        <ModalFindBuyer
          open={openFindBuyers}
          handleClose={() => setOpenFindBuyers(false)}
          variant="pitch"
          product_uuid={pitch_uuid}
          fetchDisabledBuyers={false}
        />
      )}
      <Box height="20px">
        {formState?.errors?.designers && <ErrorMessage>{formState?.errors?.designers?.message}</ErrorMessage>}
      </Box>
    </Box>
  );
};

NewPitchBuyersList.propTypes = {
  isStory: PropTypes.bool,
};

export default NewPitchBuyersList;
