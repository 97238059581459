import { Box } from '@mui/material';
import ControlledCustomFiledUpload from 'components/CustomFileUpload/ControlledCustomFileUpload/ControlledCustomFileUpload';
import ControlledCustomFiledUploadDND from 'components/CustomFileUpload/ControlledCustomFIleUploadDND/ControlledCustomFileUploadDND';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import BodyText from 'components/Typography/BodyText/BodyText';

import { ADD_PRODUCT_DIMENSIONS } from 'components/Forms/ProductProposalDetailsForm/ProductProposalDetailsForm.data';

import {
  StyledDimensionContainer,
  StyledDimensionWrapper,
} from 'components/Forms/ProductProposalDetailsForm/ProductProposalDetailsForm.styles';

const ProposalForm = () => {
  return (
    <Box>
      <Box mb={6}>
        <CustomFieldSet label="Product name" />
        <ControlledTextField name="name" placeholder="Give this new proposal a title" />
      </Box>
      <Box mb={6}>
        <CustomFieldSet
          label="Cover Image"
          description="This image is what designers will see first."
          tooltipTitle="Images"
          tooltipText="Cover Image. This image is what designers will see first. Upload an inspirational or reference image that supports your design brief."
        />
        <ControlledCustomFiledUpload numberOfTiles={1} variant={'single'} name={'main_image'} fileTypes="images" />
      </Box>
      <Box mb={6}>
        <CustomFieldSet label="Product images" description="Upload a maximum of 10 images" />
        <ControlledCustomFiledUploadDND
          numberOfTiles={10}
          variant={'multi'}
          name={'additional_images'}
          fileTypes="images"
        />
      </Box>
      <Box mb={6}>
        <CustomFieldSet label="Product Concept" />
        <ControlledTextField
          multiline={true}
          minRows={5}
          name="concept"
          placeholder={
            'Tell us more about your product, include any key information you feel is relevant to this design —  the more detail the better.'
          }
        />
      </Box>
      <Box mb={6}>
        <CustomFieldSet label="Construction" />
        <ControlledTextField
          multiline={true}
          minRows={1}
          name="construction"
          placeholder={'Describe any key construction, manufacturing and assembly details.'}
        />
      </Box>
      <Box mb={6}>
        <CustomFieldSet label="Colour, material and finish" />
        <ControlledTextField
          multiline={true}
          minRows={1}
          name="colour_material_finish"
          placeholder={'Describe any further details about the products colour, material and finish (CMF).'}
        />
      </Box>
      <Box mb={6}>
        <CustomFieldSet
          label="Dimensions"
          description="All measurements in millimetres using the following format Width x Height x Depth"
        />
        <StyledDimensionContainer>
          {ADD_PRODUCT_DIMENSIONS.map((d, index) => (
            <StyledDimensionWrapper key={d.placeholder}>
              <ControlledTextField
                label=""
                placeholder={d.placeholder}
                name={d.name}
                key={`${index}_field_${d.placeholder}`}
              />

              {index !== ADD_PRODUCT_DIMENSIONS.length - 1 && (
                <BodyText text="x" key={`${index}_text_${d.placeholder}`} />
              )}
            </StyledDimensionWrapper>
          ))}
        </StyledDimensionContainer>
      </Box>
    </Box>
  );
};

export default ProposalForm;
