import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';

import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import PillTag from 'components/PillTag/PillTag';
import Spinner from 'components/Spinner/Spinner';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';
import CustomTableCheckbox from 'components/Inputs/CustomTableCheckbox/CustomTableCheckbox';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';

import { PRODUCTS_LIST_PREVIEW_HEADINGS } from 'options/tableOptions';
import { CATEGORIES_OPTIONS } from 'options/findDesignerOptions';
import { routes } from 'options/routes';

import { getUniqueList } from 'utils/getUniqueList';
import { addFilters } from 'utils/addFilters';

import { ProductDetailsWrapper } from 'components/DesignerProductList/DesignerProductList.styles';
import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';
import { TableStyled, VisibilityIconWrapper } from 'components/Table/Table.styles';

const ProductListPreview = ({
  productsList,
  totalCount,
  tableMethods,
  loading,
  itemsSelected,
  handleCheckboxClick,
  onPreviewClick,
}) => {
  const navigate = useNavigate();

  const { order, orderBy, filter, setFilter, hasFilter, p, l, onPageChange, onRequestSort, onRowsPerPageChange } =
    tableMethods;
  const notFound = productsList?.length === 0;
  const showNotFoundMessage = notFound && !hasFilter;

  const filtersList = useMemo(
    () => ({
      categories: [...CATEGORIES_OPTIONS],
    }),
    []
  );

  const columnHeadings = useMemo(
    () =>
      PRODUCTS_LIST_PREVIEW_HEADINGS.map((item) =>
        !item.sortable
          ? {
              ...item,
              menuOptions: addFilters(item, filtersList, setFilter, filter),
            }
          : item
      ),
    [filtersList, setFilter, filter]
  );

  return (
    <>
      {loading && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}
      {!loading && (
        <CustomTableWrapper
          noResultsTitle={showNotFoundMessage ? 'No products found' : ''}
          noResultsDescription={showNotFoundMessage ? "You haven't added any products yet. Let's get started!" : ''}
          btnText={showNotFoundMessage ? 'Add Product' : ''}
          onBtnClick={showNotFoundMessage ? () => navigate(routes.productsAdd) : () => {}}
        >
          <TableStyled>
            <CustomTableHeader
              columnHeadings={columnHeadings}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <CustomTableBody>
              {!productsList.length && (
                <NoFilteringMatch item="products" cols={PRODUCTS_LIST_PREVIEW_HEADINGS.length} />
              )}
              {productsList?.map((product, index) => {
                const { image, name, categories, date_created, product_uuid } = product;

                const isSelected = !!itemsSelected.find((selected) => selected === product_uuid);
                return (
                  <CustomTableRow
                    onClick={(e) => {
                      e.stopPropagation();
                      handleCheckboxClick(product, isSelected);
                    }}
                    key={product_uuid}
                  >
                    <CustomTableBodyCell>
                      <CustomTableCheckbox checked={isSelected} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <ProductDetailsWrapper>{<ItemInfo avatar={image || {}} label={name} />}</ProductDetailsWrapper>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <PillsWrapperStyled>
                        {getUniqueList(categories, 'slug')?.map((category, i) => {
                          const { name, slug } = category;
                          return (
                            <Box key={`${category}_${i}`}>
                              <PillTag option={{ label: name, value: slug }} />
                            </Box>
                          );
                        })}
                      </PillsWrapperStyled>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <TableBodyText text={date_created ? date_created : '--'} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <VisibilityIconWrapper>
                        <VisibilityIcon onClick={() => onPreviewClick(product_uuid)} />
                      </VisibilityIconWrapper>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </TableStyled>
          {!notFound && (
            <CustomTablePagination
              count={totalCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPage={l}
              rowsPerPageOptions={[5, 10, 15]}
              page={p}
            />
          )}
        </CustomTableWrapper>
      )}
    </>
  );
};

ProductListPreview.propTypes = {};

export default ProductListPreview;
