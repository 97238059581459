import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CustomAccordion from 'components/Accordion/CustomAccordion/CustomAccordion';
import CustomAccordionHead from 'components/Accordion/CustomAccordionHead/CustomAccordionHead';
import CustomAccordionBody from 'components/Accordion/CustomAccordionBody/CustomAccordionBody';
import FileUploadIconThumbnail from '../FileUploadIconThumbnail/FileUploadIconThumbnail';

import BodyText from 'components/Typography/BodyText/BodyText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import {
  ButtonTextWrapperStyled,
  DescriptionTextWrapper,
  ReferenceFileSectionWrapper,
  SectionTitle,
  SectionDescription,
  ThumbnailContainer,
} from './OpenBriefsAccordion.styles';
import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';

import { routes } from 'options/routes';
import { userTypes } from 'options/userTypes';
import { useGalleryColumns } from 'hooks/useGalleryColumns';
import { BriefImageGroup } from 'components/BriefImageGroup/BriefImageGroup';

// const labels = {
//   categories: 'Category:',
//   materials: 'Material:',
//   spaces: 'Space:',
//   styles: 'Style:',
//   colours: 'Colour:',
// };

const OpenBriefsAccordion = ({
  id,
  expanded,
  index,
  briefData,
  handleExpanded,
  handleOpenProposalModal,
  handleOpenPreviewModal,
  startNegotationError,
  role,
}) => {
  const { columns } = useGalleryColumns();

  const {
    title,
    // categories,
    // materials,
    // spaces,
    // styles,
    // colours,
    main_image,
    additional_images,
    is_negotiating,
    design_requirements,
    brief_uuid,
    deadline,
    reference_file,
  } = briefData;

  const images = useMemo(
    () => [main_image ? main_image : {}, ...(additional_images ? additional_images : [])],
    [main_image, additional_images]
  );

  const navigate = useNavigate();

  // const productProperties = { categories, materials, spaces, styles, colours };

  const actionBtnText = is_negotiating ? 'View proposal details' : 'View Open Call';

  const actionBtnOnClick = is_negotiating
    ? () => {
        window.scroll(0, 0);
        navigate(`${routes.openCallsDashboard}/${brief_uuid}/open-call`);
      }
    : () => handleOpenProposalModal(title, brief_uuid);

  const ActionBtn = () => (
    <CustomButton
      variant="outlined"
      styleOverrides={{
        '&:hover .MuiTypography-root': {
          color: 'white!important',
        },
        '&:hover svg': {
          color: 'white!important',
        },
      }}
      text={
        <ButtonTextWrapperStyled>
          <BodyText text={actionBtnText} /> <ArrowForwardIcon />
        </ButtonTextWrapperStyled>
      }
      onClick={actionBtnOnClick}
    />
  );

  return (
    <CustomAccordion id={id} index={index} onChange={handleExpanded} expanded={expanded}>
      <CustomAccordionHead text={title} expanded={expanded} />
      <CustomAccordionBody>
        <Box mb={2}>
          <Box mt={2}>
            <DescriptionTextWrapper mb={4} mt={2}>
              <BodyText text={design_requirements} />
              <Box mt={2} display={'flex'}>
                <BodyText text={`Deadline: `} bold />
                <BodyText text={deadline} />
              </Box>
            </DescriptionTextWrapper>
            {/* {Object.entries(productProperties)
              ?.filter(([_key, value]) => value?.length)
              .map(([key, value]) => {
                return (
                  <Box display="flex" key={key}>
                    <Box mr={1}>
                      <BodyText text={labels[key]} bold />
                    </Box>
                    <BodyText text={value?.map(({ name }) => name).join(', ')} />
                  </Box>
                );
              })} */}
          </Box>
          <BriefImageGroup images={images} />
          {reference_file && reference_file.fileSrc && (
            <ReferenceFileSectionWrapper>
              <SectionTitle>Reference File</SectionTitle>
              <SectionDescription>
                Reference material provided by the company. Click the thumbnail to preview it
              </SectionDescription>
              <ThumbnailContainer style={{ cursor: 'pointer' }} onClick={() => handleOpenPreviewModal(reference_file)}>
                <FileUploadIconThumbnail fileType=".pdf" />
              </ThumbnailContainer>
            </ReferenceFileSectionWrapper>
          )}
        </Box>
        {role === userTypes.designer && (
          <>
            <Box mb={4}>
              <ActionBtn />
            </Box>
            {startNegotationError && (
              <Box mb={2}>
                <ErrorMessage>{startNegotationError}</ErrorMessage>
              </Box>
            )}
          </>
        )}
      </CustomAccordionBody>
    </CustomAccordion>
  );
};

OpenBriefsAccordion.propTypes = {
  id: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  handleExpanded: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  briefData: PropTypes.object.isRequired,
};

export default React.memo(OpenBriefsAccordion);
