import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';

import { Box } from '@mui/material';
import Close from '@mui/icons-material/Close';
import ModalCancelItem from 'components/ModalCancelItem/ModalCancelItem';
import Spinner from 'components/Spinner/Spinner';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import BriefProposalsList from 'components/BriefProposalsList/BriefProposalsList';
import EmptyListCreateNew from 'components/EmptyListCreateNew/EmptyListCreateNew';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

import { routes } from 'options/routes';

import { getErrorMessage } from 'utils/getErrorMessage';

import { useMessageContext } from 'context/MessageContext';

import { cancelBuyerBrief, getBuyersProposalList } from 'api';

const BuyerBriefProposalList = () => {
  const { brief_uuid } = useParams();
  const [openCancel, setOpenCancel] = useState(false);
  const navigate = useNavigate();
  const [setMessage] = useMessageContext();
  const queryClient = useQueryClient();

  const {
    data = {},
    isLoading,
    refetch,
  } = useQuery(['buyerProposalList', brief_uuid], () => getBuyersProposalList(brief_uuid), {
    enabled: true,
    staleTime: 2 * 60 * 1000,
    refetchOnMount: 'always',
  });

  const handleCancelBrief = useCallback(async () => {
    const res = await cancelBuyerBrief(brief_uuid);

    if (!res.success) {
      return setMessage(getErrorMessage(res));
    }
    setMessage('You have successfully cancelled this brief.');
    queryClient.invalidateQueries({ queryKey: ['buyerBriefList'] });
    navigate(`${routes.briefsDashboard}`);
  }, [brief_uuid, navigate, queryClient, setMessage]);

  useEffect(() => {
    if (!isLoading && !data?.success) {
      navigate(`${routes.briefsDashboard}`);
    }
  }, [data?.success, navigate]);

  const { brief_designers: proposals, brief_name, can_cancel } = data;

  return (
    <BuyerDashboardWrapper
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Briefs', onClick: () => navigate(routes.briefsDashboard) }]}
          currentPage={brief_name}
        />
      }
      activeTab={routes.briefsDashboard}
    >
      <HeaderAndMenu
        title={brief_name}
        menuOptions={[
          ...(can_cancel
            ? [
                {
                  buttonTitle: 'Cancel Brief',
                  onClick: () => setOpenCancel(true),
                  icon: <Close fontSize="small" />,
                },
              ]
            : []),
        ]}
      />
      {!isLoading && !proposals?.length && (
        <EmptyListCreateNew
          title="There are no proposals for this brief yet."
          btnText="View Briefs"
          onClick={() => navigate(routes.briefsDashboard)}
        />
      )}
      {isLoading ? (
        <Box my={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <BriefProposalsList
          proposals={proposals}
          brief_uuid={brief_uuid}
          brief_name={brief_name}
          setMessage={setMessage}
          refetch={refetch}
        />
      )}
      {openCancel && (
        <ModalCancelItem
          open={openCancel}
          handleClose={() => setOpenCancel(false)}
          handleSubmit={handleCancelBrief}
          title="Cancel Brief"
          description="Cancelling this Brief will cancel it for all designers who haven’t agreed terms yet. This action cannot be undone."
        />
      )}
    </BuyerDashboardWrapper>
  );
};

BuyerBriefProposalList.propTypes = {};

export default BuyerBriefProposalList;
