import ControlledBriefSummary from 'components/BriefSummary/ControlledBriefSummary/ControlledBriefSummary';
import NewBriefDetailsForm from 'components/Forms/NewBriefDetailsForm/NewBriefDetailsForm';
import NewBriefSetUp from 'components/NewBriefSetUpForm/NewBriefSetUpForm';
import NewBriefSuggestedProducts from 'components/NewBriefSuggestedProducts/NewBriefSuggestedProducts';
import ProductProposalPropertiesForm from 'components/ProductProposalPropertiesForm/ProductProposalPropertiesForm';
import { newBriefSuggestedProductsMock } from 'components/NewBriefSuggestedProducts/NewBriefSuggestedProducts.stories';
import NewBriefAgreementForm from 'components/Forms/NewBriefAgreementForm/NewBriefAgreementForm';
import Spinner from 'components/Spinner/Spinner';

import { mockData } from 'components/NewBriefDesignersList/NewBriefDesignersList.data';

import { existingTermsHeading } from 'options/tableOptions';

import { formDefaultValues } from './BuyerBriefNew.data';

const mockTableData = {
  data: [
    {
      company_name: 'Made.com 1',
      type_of_terms: 'One time fee',
      created: '01/04/2022',
    },
    {
      company_name: 'Made.com 2',
      type_of_terms: 'Royalty license',
      created: '02/03/2021',
    },
    {
      company_name: 'Made.com 3',
      type_of_terms: 'One time fee',
      created: '10/05/2018',
    },
    {
      company_name: 'Made.com 4',
      type_of_terms: 'Royalty license',
      created: '30/05/2017',
    },
    {
      company_name: 'Made.com 5',
      type_of_terms: 'Royalty license',
      created: '22/05/2021',
    },
  ],
  headings: existingTermsHeading,
  paginationOptions: {
    count: 200,
    page: 1,
    onPageChange: () => console.log('page-change'),
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15],
    onRowsPerPageChange: () => console.log('on-rows-per-page-change'),
  },
};

export const BuyerBriefNewSteps = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0: {
      return (
        <NewBriefSetUp isStory={true} defaultValues={formDefaultValues} setIsLoading={stepsOptions.setIsFetching} />
      );
    }
    case 1: {
      return stepsOptions.isFetching ? <Spinner align="center" /> : <ProductProposalPropertiesForm />;
    }
    case 2: {
      return <NewBriefSuggestedProducts products={newBriefSuggestedProductsMock} />;
    }
    case 3: {
      return <NewBriefDetailsForm />;
    }
    case 4: {
      return <NewBriefAgreementForm />;
    }
    case 5: {
      return <ControlledBriefSummary userType={userType} />;
    }
    default: {
      return <></>;
    }
  }
};

export const BuyerBriefNewStepsEditting = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0: {
      return stepsOptions.isFetching ? <Spinner align="center" /> : <ProductProposalPropertiesForm />;
    }
    case 1: {
      return <NewBriefSuggestedProducts products={newBriefSuggestedProductsMock} />;
    }
    case 2: {
      return <NewBriefDetailsForm />;
    }
    case 3: {
      return (
        <NewBriefAgreementForm
          isStory={false}
          designerListTotal={14}
          designerListData={mockData}
          existingTermsTableData={mockTableData}
        />
      );
    }
    case 4: {
      return <ControlledBriefSummary userType={userType} />;
    }
    default: {
      return <></>;
    }
  }
};

export const BriefsEditingActiveSteps = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0: {
      return stepsOptions.isFetching ? <Spinner align="center" /> : <ProductProposalPropertiesForm />;
    }
    case 1: {
      return <NewBriefDetailsForm />;
    }
    case 2: {
      return <ControlledBriefSummary userType={userType} disableTerms={true} isActive={true} />;
    }
    default: {
      return <></>;
    }
  }
};
