import { Box, styled } from '@mui/material';

export const TitleWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.black}`,
  borderBottom: `1px solid ${theme.palette.common.black}`,
}));

export const PaginationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: theme.spacing(3),
}));
