import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import { BuyerProfileIncompleteContainer, StyledDescriptionText } from './IncompleteProfile.styles';

import { routes } from 'options/routes';
import { userTypes } from 'options/userTypes';

const IncompleteProfile = ({ userType }) => {
  const navigate = useNavigate();

  return (
    <PageWrapper userType={userType} showSecondaryFooter={true}>
      <BuyerProfileIncompleteContainer>
        <CustomTitle variant="h3" text="Complete Your Profile" />
        <Box my={2} display={'flex'} flexDirection="column">
          <StyledDescriptionText variant={'body1'}>
            {[userTypes.buyerAdmin, userTypes.designer].includes(userType)
              ? 'Please complete your profile and connect your Stripe account to start using the site.'
              : 'Please contact your Company admin to complete your profile information.'}
          </StyledDescriptionText>
          {[userTypes.buyerAdmin, userTypes.designer].includes(userType) && (
            <Box mt={2} display={'flex'} justifyContent={'center'}>
              <CustomButton text="My Account" onClick={() => navigate(routes.account)} />
            </Box>
          )}
        </Box>
      </BuyerProfileIncompleteContainer>
    </PageWrapper>
  );
};

export default IncompleteProfile;
