import { Box, styled } from '@mui/material';
export const StyledSingleInputResponsiveWrapper = styled(Box)(({ theme, modal }) => ({
  width: modal ? '100%' : '30%',
  [theme.breakpoints.down(1000)]: {
    width: '50%',
  },
  [theme.breakpoints.down(768)]: {
    width: '100%',
  },
}));
