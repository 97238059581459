import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import FormParagraph from '../../Typography/FormParagraph/FormParagraph';
import { FormContainer, Container } from './ResetPassword.styles';

import { routes } from '../../../options/routes';
import { Box } from '@mui/material';

const ResetPasswordSuccess = ({ variant }) => {
  const navigate = useNavigate();
  const titleText = variant === 'primary' ? 'changed your password' : 'accepted your invitation';

  const text = <>You have successfully {titleText}.</>;

  return (
    <FormContainer>
      <CustomTitle variant="h2" text="Thank You" />
      <Container>
        <FormParagraph text={text} variant="primary" />
      </Container>
      <Box display="flex" justifyContent="flex-start">
        <CustomButton text="Continue to Log in" variant="outlined" onClick={() => navigate(routes.login)} />
      </Box>
    </FormContainer>
  );
};

ResetPasswordSuccess.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default ResetPasswordSuccess;
