import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import theme from '../../theme/theme';

import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import PillTag from 'components/PillTag/PillTag';
import Spinner from 'components/Spinner/Spinner';
import StatusPill from 'components/StatusPill/StatusPill';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';

import { designerBriefsListHeadings } from 'options/tableOptions';
import { routes } from 'options/routes';
import { BRIEF_STATUS_DESIGNER } from 'options/briefStatus';
import { PILL_STATUS_ICON, PILL_STATUS_MAP, DESIGNER_BRIEF_PILL_STATUS_TEXT } from 'options/pillStatusOptions';

import { getUniqueList } from 'utils/getUniqueList';
import { addFilters } from 'utils/addFilters';

import { TableStyled } from 'components/Table/Table.styles';
import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';

import { getChildOptions, getDesignerBriefsList } from 'api';
import { OPTIONS_TYPE } from 'options/selectOptions';

import { useTableFilter } from 'hooks/useTableFilter';

const DesignerBriefsList = ({ isStory, mockData, mockTotal }) => {
  const { order, orderBy, filter, setFilter, p, l, parsedFilter, onPageChange, onRequestSort, onRowsPerPageChange } =
    useTableFilter({ initialOrder: 'desc', initialOrderBy: 'date_sent' });

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['designerBriefsList', parsedFilter],
    () => getDesignerBriefsList(parsedFilter),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const hasFilter = Object.keys(filter).length;

  const briefList = useMemo(() => (isStory ? mockData : data?.briefs || []), [data?.briefs, isStory, mockData]);
  const totalCount = useMemo(() => (isStory ? mockTotal : data?.total || []), [data?.total, isStory, mockTotal]);

  const {
    data: { options: categories },
  } = useQuery(['options', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const filtersList = useMemo(
    () => ({
      categories: categories,
      statuses: [...Object.values(BRIEF_STATUS_DESIGNER)],
    }),
    [categories]
  );

  const columnHeadings = useMemo(
    () =>
      designerBriefsListHeadings.map((menuItem) =>
        !menuItem.sortable
          ? {
              ...menuItem,
              menuOptions: addFilters(menuItem, filtersList, setFilter, filter, DESIGNER_BRIEF_PILL_STATUS_TEXT),
            }
          : menuItem
      ),
    [filter, filtersList]
  );

  const handleNavigateToBrief = useCallback(
    (brief_uuid) => {
      if (isStory) {
        console.log(`navigate to brief => ${brief_uuid}`);
        return;
      } else {
        navigate(`${routes.briefsDashboard}/${brief_uuid}/brief`);
      }
    },
    [isStory, navigate]
  );

  const noBrief = !briefList || briefList.length === 0;
  const showNoBriefsYetMessage = !hasFilter && noBrief;

  return (
    <>
      {isLoading && !isStory ? (
        <Box mt={theme.spacing(2)}>
          <Spinner align="center" />
        </Box>
      ) : (
        <CustomTableWrapper
          noResultsTitle={showNoBriefsYetMessage ? 'No briefs found' : ''}
          noResultsDescription={showNoBriefsYetMessage ? "You haven't got any briefs yet" : ''}
        >
          <TableStyled>
            <CustomTableHeader
              columnHeadings={columnHeadings}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <CustomTableBody>
              {!briefList.length && <NoFilteringMatch item="briefs" cols={designerBriefsListHeadings.length} />}
              {briefList?.map((brief) => {
                const {
                  company_name,
                  company_logo,
                  date_sent,
                  deadline,
                  status,
                  title,
                  brief_uuid,
                  categories,
                  company_uuid,
                  views,
                } = brief;

                const menuActions = [
                  { title: 'View Brief', onClick: () => handleNavigateToBrief(brief_uuid) },
                  {
                    title: 'View Company',
                    onClick: company_uuid
                      ? () => {
                          navigate(`${routes.buyersIndex}/${company_uuid}`);
                        }
                      : null,
                  },
                ];
                return (
                  <CustomTableRow key={`${title}-uuid_${brief_uuid}`} onClick={() => handleNavigateToBrief(brief_uuid)}>
                    {/* Name cell */}
                    <CustomTableBodyCell>
                      <Box cursor="pointer">
                        <ItemInfo label={title} avatar={{}} />
                      </Box>
                    </CustomTableBodyCell>
                    {/* Company cell */}
                    <CustomTableBodyCell>
                      <Box
                        onClick={
                          company_uuid
                            ? (e) => {
                                e.stopPropagation();
                                navigate(`${routes.buyersIndex}/${company_uuid}`);
                              }
                            : null
                        }
                      >
                        <ItemInfo label={company_name} avatar={company_logo} />
                      </Box>
                    </CustomTableBodyCell>
                    {/* Category cell */}
                    <CustomTableBodyCell>
                      <PillsWrapperStyled>
                        {getUniqueList(categories, 'slug')?.map((category, i) => {
                          const { name, slug } = category;
                          return (
                            <Box key={`pill_${name}_${i}`}>
                              <PillTag option={{ label: name, value: slug }} />
                            </Box>
                          );
                        })}
                      </PillsWrapperStyled>
                    </CustomTableBodyCell>
                    {/* Sent cell */}
                    <CustomTableBodyCell>
                      <TableBodyText text={date_sent} />
                    </CustomTableBodyCell>
                    {/* Deadline cell */}
                    <CustomTableBodyCell>
                      <TableBodyText text={deadline} />
                    </CustomTableBodyCell>
                    {/* Views cell */}
                    <CustomTableBodyCell>
                      <TableBodyText text={views ? views : '0'} />
                    </CustomTableBodyCell>
                    {/* Status cell */}
                    <CustomTableBodyCell>
                      <StatusPill
                        label={DESIGNER_BRIEF_PILL_STATUS_TEXT[status]}
                        status={PILL_STATUS_MAP[status]}
                        icon={PILL_STATUS_ICON[status]}
                      />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <ThreeDotMenu actions={menuActions} variant="vertical" />
                      </Box>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </TableStyled>
          {!noBrief && (
            <CustomTablePagination
              count={totalCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPage={l}
              rowsPerPageOptions={[5, 10, 15]}
              page={p}
            />
          )}
        </CustomTableWrapper>
      )}
    </>
  );
};

DesignerBriefsList.propTypes = {
  isStory: PropTypes.bool,
};

export default DesignerBriefsList;
