import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import { routes } from 'options/routes';
import { useNavigate } from 'react-router';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import { DESIGNER_PRODUCT_SALES_HEADINGS } from 'options/tableOptions';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import BodyText from 'components/Typography/BodyText/BodyText';
import { TableStyled } from 'components/Table/Table.styles';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import { CURRENCY_MAP } from 'options/currency';

const DesignerDashboardProductSalesTable = ({ data, total, isLoading }) => {
  const navigate = useNavigate();

    const onView = useCallback(
    (product_uuid) => {
      navigate(`${routes.product}/${product_uuid}`, {
        state: { from: routes.productsDashboard, fromTitle: 'Products' },
      });
    },
    [navigate]
  );

  return (
    <Box>
      <CustomTableWrapper
        noResultsTitle={!isLoading && data.length === 0 ? 'No products found' : ''}
        noResultsDescription={
          !isLoading && data.length === 0 ? "You haven't sold any products yet. Let's get started!" : ''
        }
        btnText={!isLoading && data.length === 0 ? 'Create a product' : ''}
        onBtnClick={() => navigate(routes.productsAdd)}
      >
        <TableStyled minWidth={'none'}>
          <CustomTableHeader columnHeadings={DESIGNER_PRODUCT_SALES_HEADINGS} />
          <CustomTableBody>
            {data?.map((item) => {
              const { name, main_image, price, currency, product_uuid } = item;
              const currencyIcon = currency ? CURRENCY_MAP[currency] : '£';
              return (
                <CustomTableRow
                  key={`table_row_${product_uuid}`}
                  onClick={() => onView(product_uuid)}
                >
                  <CustomTableBodyCell>{<ItemInfo avatar={main_image || {}} label={name} />}</CustomTableBodyCell>
                  <CustomTableBodyCell>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <BodyText text={price ? currencyIcon + price : '%'} />
                    </Box>
                  </CustomTableBodyCell>
                </CustomTableRow>
              );
            })}
          </CustomTableBody>
        </TableStyled>
      </CustomTableWrapper>
    </Box>
  );
};

DesignerDashboardProductSalesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

export default DesignerDashboardProductSalesTable;
