import { Scale } from '@mui/icons-material';
import { styled, Chip } from '@mui/material';

export const StatusPillStyled = styled(Chip)(({ theme, label }) => ({
  padding: '3px 2px',
  lineHeight: '1.2',
  display: 'inline-flex',
  fontWeight: '600',
  borderWidth: '1.25px',
  alignItems: 'center',
  minWidth: label?.length ? '136px' : 'unset',
  width: !label.length ? '32px' : '',
  '& .MuiChip-label': {
    display: !label?.length ? 'none' : '',
  },
  '&:hover': {
    cursor: 'inherit',
    backgroundColor: 'white',
  },
  '& a': {
    color: 'inherit',
    textUnderlineOffset: '2px',
  },

  '&.MuiChip-outlined.MuiChip-colorSuccess': {
    borderColor: '#A5D8A8',
  },
  '&.MuiChip-outlined.MuiChip-colorWarning': {
    borderColor: '#F6B580',
  },
  '&.MuiChip-outlined.MuiChip-colorError': {
    borderColor: '#F6A9A4',
  },
  '& .MuiSvgIcon-root': {
    marginRight: label?.length ? '' : '0px',
    marginLeft: label?.length ? '' : '0px',
  },
}));
