import { useCallback } from 'react';
import PropTypes from 'prop-types';

import MinimizeOutlined from '@mui/icons-material/MinimizeOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import { CustomAccordionHeadStyled } from './CustomAccordionHead.styles';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';

const CustomAccordionHead = ({ expanded, text, handleOnEdit }) => {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      handleOnEdit();
    },
    [handleOnEdit]
  );

  return (
    <CustomAccordionHeadStyled expandIcon={!expanded ? <AddOutlinedIcon /> : <MinimizeOutlined />}>
      <CustomTitle text={text} variant={'h3'} />
      {handleOnEdit && <CustomStandardLink text="Edit" onClick={handleClick} linkType="primary" />}
    </CustomAccordionHeadStyled>
  );
};

CustomAccordionHead.propTypes = {
  expanded: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default CustomAccordionHead;
