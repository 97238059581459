import React from 'react';
import PropTypes from 'prop-types';
import { SectionTabStyled } from './SectionTab.styles';

const SectionTab = ({ complete, label, onClick, active, disabled }) => {
  return <SectionTabStyled complete={complete} label={label} onClick={onClick} active={active} disabled={disabled} />;
};

SectionTab.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  complete: PropTypes.bool.isRequired,
};

export default SectionTab;
