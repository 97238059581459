import { useCallback, useMemo, useState, useEffect } from 'react';
import queryString from 'query-string';

import { PITCHES_PER_PAGE } from '../options/pagination';

export const useTableFilter = ({ initialOrder = 'desc', initialOrderBy, isTablePagination = true }) => {
  const [order, setOrder] = useState(initialOrder);
  const [orderBy, setOrderBy] = useState(initialOrderBy);
  const [filter, setFilter] = useState({});
  const [pagination, setPaginationOptions] = useState({
    p: isTablePagination ? 0 : 1,
    l: isTablePagination ? 10 : PITCHES_PER_PAGE,
  });
  const { p, l } = pagination;

  useEffect(() => {
    // Set Pagination page to 0 on filter/orderBy change
    setPaginationOptions((prevState) => ({ ...prevState, p: isTablePagination ? 0 : 1 }));
  }, [isTablePagination, filter, orderBy]);

  const hasFilter = !!Object.keys(filter).length;

  const parsedFilter = useMemo(
    () => queryString.stringify({ ...filter, l, p: isTablePagination ? p + 1 : p, order_by: orderBy, order: order }),
    [isTablePagination, filter, l, order, orderBy, p]
  );

  const onRequestSort = useCallback((_e, id, order) => {
    setOrderBy(id);
    setOrder(order);
  }, []);

  const onPageChange = useCallback((_e, newPage) => {
    setPaginationOptions((prevState) => ({ ...prevState, p: newPage }));
  }, []);

  const onRowsPerPageChange = useCallback((e) => {
    setPaginationOptions((prevState) => ({ ...prevState, l: e.target.value }));
  }, []);

  const resetFilter = useCallback(() => {
    setFilter({});
  }, []);

  return {
    order,
    orderBy,
    filter,
    p,
    l,
    parsedFilter,
    onRequestSort,
    onPageChange,
    onRowsPerPageChange,
    setFilter,
    hasFilter,
    resetFilter,
  };
};
