import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import queryString from 'query-string';

import { Box } from '@mui/system';
import ProfileDetails from 'components/ProfileDetails/ProfileDetails';
import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import ProductList from 'components/ProductList/ProductList';
import Spinner from 'components/Spinner/Spinner';
import BodyText from 'components/Typography/BodyText/BodyText';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';

import { LoadingContainer } from 'components/ProductsMegaMenu/ProductsMegaMenu.styles';

import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';
import { PRODUCTS_MENU } from 'options/menuOptions';
import { FILTER_OPTIONS } from 'options/filterOptions';

import { addProductFilters } from 'utils/addFilters';

import { addFavouriteDesigner, getDesignerProfile, getDesignersList, getProducts, removeFavouritedDesigner } from 'api';
import { PaginationWrapper } from 'components/ProductsAll/ProductsAll.styles';
import { Pagination } from '@mui/material';
import { PRODUCTS_PER_PAGE } from 'options/pagination';
import { useProductOptionsQueries } from 'hooks/useProductOptionsQueries';

const BuyerDesignerProfile = () => {
  const [designer, setDesigner] = useState(null);
  const { designer_uuid } = useParams();
  const [filter, setFilter] = useState({});
  const [pagination, setPaginationOptions] = useState({ p: 1, l: PRODUCTS_PER_PAGE });
  const { p, l } = pagination;

  const parsedFilter = useMemo(() => queryString.stringify({ ...filter, p: p, l: l }), [filter, p, l]);
  const navigate = useNavigate();
  const resetFilter = useCallback(() => {
    setFilter({});
  }, []);
  const fetchDesigner = useCallback(async () => {
    try {
      if (designer_uuid) {
        const res = await getDesignerProfile(designer_uuid);
        setDesigner(res.designer_profile);
      }
    } catch (error) {
      console.error(error);
    }
  }, [designer_uuid]);

  useEffect(() => {
    fetchDesigner();
    window.scroll(0, 0);
  }, [fetchDesigner]);

  const { data, isLoading, isError, refetch } = useQuery(
    ['myFavouritedDesigners'],
    () => getDesignersList('is_favourite=true'),
    {
      // enabled: !isStory,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const fetchProducts = useQuery(
    ['allDesignersProducts', parsedFilter, designer_uuid],
    () => getProducts(`designer_uuid=${designer_uuid}&${parsedFilter}`),
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const designersProducts = useMemo(() => fetchProducts?.data?.products ?? null, [fetchProducts?.data?.products]);

  const favouritedDesignersList = useMemo(() => data?.designers ?? [], [data?.designers]);

  useEffect(() => {
    setPaginationOptions({ p: 1, l: PRODUCTS_PER_PAGE });
  }, [filter]);

  const can_create = data?.can_create;

  const isFavourited = useMemo(
    () =>
      favouritedDesignersList?.length && designer
        ? !!favouritedDesignersList.find((favourited) => favourited.uuid === designer.uuid)
        : null,
    [favouritedDesignersList, designer]
  );

  const designerProfileInfo = useMemo(() => {
    if (!designer) return null;
    return {
      uuid: designer.user_uuid,
      first: designer.first_name,
      last: designer.last_name,
      bio: designer.bio,
      portrait: designer.portrait && designer.portrait.fileSrc ? designer.portrait.fileSrc : null,
      city: designer.studio_city,
      country: designer.studio_country,
      products: designer.product_types,
      joined: designer.date_joined,
    };
  }, [designer]);

  const favouriteDesigner = async () => {
    try {
      await addFavouriteDesigner(designer.uuid);
      refetch();
    } catch (error) {}
  };
  const unfavouriteDesigner = async () => {
    try {
      await removeFavouritedDesigner(designer.uuid);
      refetch();
    } catch (error) {}
  };

  const briefDesigner = useCallback(() => {
    navigate(routes.briefsAdd, {
      state: {
        designers: [designer],
      },
    });
  }, [designer, navigate]);

  const { space, categories, materials, colour } = useProductOptionsQueries();

  const itemsList = useMemo(
    () => ({
      [FILTER_OPTIONS.CATEGORY]: categories ?? [],
      [FILTER_OPTIONS.SPACE]: space ?? [],
      [FILTER_OPTIONS.MATERIAL]: materials ?? [],
      [FILTER_OPTIONS.COLOUR]: colour ?? [],
    }),
    [categories, colour, materials, space]
  );

  const menuOptions = useMemo(
    () =>
      PRODUCTS_MENU.filter((menuItem) => menuItem.id !== FILTER_OPTIONS.CATEGORY).map((menuItem) => {
        return {
          ...menuItem,
          actions: addProductFilters(menuItem, itemsList, setFilter, filter),
        };
      }),
    [filter, itemsList]
  );

  const totalProds = useMemo(() => {
    if (fetchProducts?.isLoading) {
      return '...';
    }
    return fetchProducts?.data?.total || '0';
  }, [fetchProducts?.data?.total, fetchProducts?.isLoading]);

  const pageCount = useMemo(
    () => (fetchProducts?.data?.total ? Math.ceil(fetchProducts?.data?.total / PRODUCTS_PER_PAGE) : null),
    [fetchProducts?.data?.total]
  );

  const onPageChange = useCallback((_e, newPage) => {
    setPaginationOptions((prevState) => ({ ...prevState, p: newPage }));
  }, []);

  const hasFilter = !!Object.keys(filter).length;

  return (
    <PageWrapper showSecondaryFooter={false} userType={userTypes.buyer}>
      <PageContentWrapper breadcrumbsMargin>
        {!designer && (
          <LoadingContainer>
            <Spinner align={'center'} />
          </LoadingContainer>
        )}
        {designer && designerProfileInfo && (
          <>
            <Box mb={4}>
              <Breadcrumbs
                currentPage={designerProfileInfo.first}
                links={[{ label: 'Designers', onClick: () => navigate(routes.designersIndex) }]}
              />
            </Box>

            <ProfileDetails
              isFavourited={isFavourited}
              favouriteDesigner={favouriteDesigner}
              unfavouriteDesigner={unfavouriteDesigner}
              briefDesigner={briefDesigner}
              viewType={userTypes.buyer}
              profileDetails={designerProfileInfo}
              canBriefDesigner={can_create}
            />
            {((!fetchProducts.isLoading && !!designersProducts?.length) || hasFilter) && (
              <SearchAndFilterOptions
                title={`Products (${totalProds})`}
                hasFilter={hasFilter}
                menuOptions={menuOptions}
                hasBorder
                resetFilter={resetFilter}
              />
            )}
            {fetchProducts.isLoading && (
              <LoadingContainer>
                <Spinner align={'center'} />
              </LoadingContainer>
            )}
            {!fetchProducts.isLoading && designersProducts?.length > 0 && (
              <Box mb={4}>
                <ProductList products={designersProducts} userType={userTypes.buyer} />
                {totalProds > PRODUCTS_PER_PAGE && (
                  <PaginationWrapper>
                    <Pagination showFirstButton showLastButton page={p} count={pageCount} onChange={onPageChange} />
                  </PaginationWrapper>
                )}
              </Box>
            )}
            {!fetchProducts.isLoading && hasFilter && (!designersProducts || designersProducts.length === 0) && (
              <Box style={{ minHeight: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <BodyText
                  text={`Sorry, none of ${designerProfileInfo.first}'s products match your search criteria...`}
                />
              </Box>
            )}
          </>
        )}
      </PageContentWrapper>
    </PageWrapper>
  );
};

BuyerDesignerProfile.propTypes = {};

export default BuyerDesignerProfile;
