import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useParams, Outlet } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';

import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import ChatIcon from '@mui/icons-material/Chat';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Spinner from 'components/Spinner/Spinner';
import BodyText from 'components/Typography/BodyText/BodyText';
import DesignerTabSectionsWrapper from 'components/Wrappers/TabSectionsWrapper/DesignerTabSectionsWrapper/DesignerTabSectionsWrapper';
import ChatBox from 'components/ChatBox/ChatBox';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';
import ModalRejectBrief from 'components/ModalRejectBrief/ModalRejectBrief';
import ModalCancelItem from 'components/ModalCancelItem/ModalCancelItem';
import TopBarTitleStatus from 'components/TopBarTitleStatus/TopBarTitleStatus';
import ScrollableContainer from 'components/ScrollableContainer/ScrollableContainer';

import { PROJECT_STATUS } from 'options/projectStatus';
import { routes } from 'options/routes';

import { getErrorMessage } from 'utils/getErrorMessage';
import { parseRemoteData } from 'pages/Dashboard/Buyer/Briefs/BuyerBriefNew/BuyerBriefNew.utils';
import {
  getIsAgreementTabDisabled,
  getIsFilesTabDisabled,
  getIsProposalTabDisabled,
} from '../DesignerSingleBrief.utils';

import { MOCK_BRIEF_DATA } from 'mockData/mockBrief';

import { options } from './DesignerSingleBriefLanding.data';

import { useMessageContext } from 'context/MessageContext';

import {
  cancelDesignerBriefProposal,
  deleteBriefDesignerFiles,
  downloadBriefDesignerFiles,
  getBriefChatMessages,
  getDesignerSingleBrief,
  postBriefChatMessage,
  setDesignerBriefResponse,
  uploadBriefDesignerFiles,
} from 'api';

export const DesignerSingleBriefLanding = ({ isStory, userType }) => {
  const { brief_uuid } = useParams();
  const [openCancel, setOpenCancel] = useState(false);
  const [setMessage] = useMessageContext();
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [showMessages, setShowMessages] = useState(true);

  const onAcceptBrief = useCallback(() => {
    setShowAcceptModal(true);
  }, []);

  const onDeclineBrief = useCallback(() => {
    setShowRejectModal(true);
  }, []);

  const onBriefResponseConfirm = async (accept, message) => {
    const res = await setDesignerBriefResponse(brief_uuid, {
      response: accept ? 'accepted' : 'rejected',
      ...(!accept ? { rejected_reason: message } : {}),
    });

    if (!res.success) {
      return setMessage('There was a problem responding to the brief');
    }

    queryClient.setQueryData(['designerSingleBrief', brief_uuid], (oldData) => {
      return {
        brief: {
          ...oldData.brief,
          project_status: accept ? 'accepted' : 'rejected',
        },
        can_cancel: accept,
      };
    });

    setMessage(
      accept
        ? 'Congratulations, you have successfully accepted the brief.'
        : 'You have successfully rejected the brief. The buyer will be notified of your response.'
    );

    return accept ? navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal`) : null;
  };

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['designerSingleBrief', brief_uuid],
    () => getDesignerSingleBrief(brief_uuid),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const briefData = useMemo(
    () => (isStory ? MOCK_BRIEF_DATA : data && data?.brief && parseRemoteData(data?.brief)) || {},
    [data, isStory]
  );
  const chat_uuid = briefData?.chat_uuid || '';

  const { proposal_status, project_status, agreement_status, status } = briefData;

  const isProposalTabDisabled = getIsProposalTabDisabled(project_status);
  const isAgreementTabDisabled = getIsAgreementTabDisabled(project_status, proposal_status, status);
  const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status, status);

  const handleSectionClick = useCallback(
    (path) => {
      navigate(`${routes.briefsDashboard}/${brief_uuid}${path}`);
    },
    [navigate, brief_uuid]
  );

  const handleGetChatMessages = useCallback(() => {
    return getBriefChatMessages(chat_uuid);
  }, [chat_uuid]);

  const handlePostChatMessages = useCallback((chat_uuid, data) => {
    return postBriefChatMessage(chat_uuid, data);
  }, []);

  const handleChatDownloadFiles = useCallback(
    (data) => {
      return downloadBriefDesignerFiles(brief_uuid, data, true);
    },
    [brief_uuid]
  );

  const handleChatUploadFiles = useCallback(
    (data) => {
      return uploadBriefDesignerFiles(brief_uuid, data);
    },
    [brief_uuid]
  );

  const handleChatDeleteFiles = useCallback(
    (file_uuid) => {
      return deleteBriefDesignerFiles(brief_uuid, file_uuid);
    },
    [brief_uuid]
  );

  const toggleMessages = useCallback(() => {
    setShowMessages((prev) => !prev);
  }, []);

  const handleCancelBriefProposal = useCallback(async () => {
    const res = await cancelDesignerBriefProposal(brief_uuid);

    if (!res.success) {
      return setMessage(getErrorMessage(res));
    }
    setMessage('You have successfully cancelled the proposal for this brief.');
    refetch();
  }, [brief_uuid, refetch, setMessage]);

  const briefName = briefData?.title;
  const isProjectCompleted = briefData?.project_status === PROJECT_STATUS.COMPLETED;
  const can_cancel = data?.can_cancel;

  const showAcceptDeclineButtons = project_status === PROJECT_STATUS.PENDING;
  const loading = isLoading || isRefetching;

  return (
    <>
      <DesignerTabSectionsWrapper
        breadcrumbs={
          <Breadcrumbs
            links={[{ label: 'Briefs', onClick: () => navigate(routes.briefsDashboard) }]}
            currentPage={briefName}
          />
        }
        title={briefName}
        headerMenuOptions={[
          ...(can_cancel
            ? [
                {
                  buttonTitle: 'Cancel Proposal',
                  onClick: () => setOpenCancel(true),
                  icon: <Close />,
                },
              ]
            : []),
          {
            buttonTitle: showMessages ? 'Hide Messages' : 'Show Messages',
            icon: showMessages ? <SpeakerNotesOffIcon fontSize="small" /> : <ChatIcon fontSize="small" />,
            onClick: toggleMessages,
          },
          {
            buttonTitle: 'Back',
            icon: <ArrowBackIcon fontSize="small" />,
            onClick: () => navigate(`${routes.briefsDashboard}`),
            animate: true,
          },
        ]}
        chatbox={
          <ChatBox
            chat_uuid={chat_uuid}
            setMessage={setMessage}
            getChatMessages={handleGetChatMessages}
            postChatMessage={handlePostChatMessages}
            downloadFiles={handleChatDownloadFiles}
            uploadFiles={handleChatUploadFiles}
            deleteFiles={handleChatDeleteFiles}
            userType={userType}
            expand={showMessages}
            setExpand={toggleMessages}
            onAcceptClick={showAcceptDeclineButtons ? onAcceptBrief : null}
            acceptBtnText={'Create Proposal'}
            rejectBtnText={'Decline Brief'}
            onRejectClick={showAcceptDeclineButtons ? onDeclineBrief : null}
          />
        }
        activeTab={routes.briefsDashboard}
        collapse={showMessages}
        handleSectionClick={handleSectionClick}
        options={options.map((option) => {
          if (option.path === '/files') {
            return {
              ...option,
              disabled: isFilesTabDisabled,
            };
          }
          if (option.path === '/proposal') {
            return {
              ...option,
              disabled: isProposalTabDisabled,
            };
          }
          if (option.path === '/agreement') {
            return {
              ...option,
              disabled: isAgreementTabDisabled,
            };
          }
          return option;
        })}
      >
        {loading && (
          <Box mt={2}>
            <Spinner align="center" />
          </Box>
        )}
        {!loading && (
          <>
            <TopBarTitleStatus name={briefData?.company?.name} project_status={project_status} />
            {!loading && isProjectCompleted && (
              <Box mt={2}>
                <BodyText text="This project has been completed." />
              </Box>
            )}
            <ScrollableContainer collapse={showMessages}>
              <Outlet context={[{ ...briefData }, setMessage, userType, onAcceptBrief, onDeclineBrief]} />
            </ScrollableContainer>
          </>
        )}
      </DesignerTabSectionsWrapper>
      <ModalConfirmAccept
        open={showAcceptModal}
        name="brief"
        titleOverride="Create proposal"
        textOverride="By creating proposal, you are accepting to proceed with the brief; this does not mean you accept the terms and conditions. "
        handleClose={() => setShowAcceptModal(false)}
        handleSubmit={onBriefResponseConfirm}
      />
      <ModalRejectBrief
        open={showRejectModal}
        handleClose={() => setShowRejectModal(false)}
        handleSubmit={onBriefResponseConfirm}
        variant="designer-brief"
      />
      {openCancel && (
        <ModalCancelItem
          open={openCancel}
          handleClose={() => setOpenCancel(false)}
          handleSubmit={handleCancelBriefProposal}
          title="Cancel Proposal"
          description="Cancelling this Proposal will automatically reject the Brief. This action cannot be undone."
        />
      )}
    </>
  );
};

export default DesignerSingleBriefLanding;
