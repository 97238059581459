export const PROPOSAL_STATUS = {
  ACCEPTED: 'accepted',
  APPROVED: 'approved',
  PENDING_APPROVAL: 'pending_approval',
  DRAFT: 'draft',
  REJECTED: 'rejected',
  DELETED: 'deleted',
  CANCELLED: 'cancelled',
  DESIGNER_CANCELLED: 'designer_cancelled',
  BUYER_CANCELLED: 'buyer_cancelled',
};
