import { useEffect, useState, useCallback } from 'react';
import { createBrowserHistory } from 'history';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider } from '@mui/material';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter as Router } from 'react-router-dom';

import Content from './parts/Content';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBounday';
import PageLoading from 'components/PageLoading/PageLoading';
import NotificationProvider from 'components/NotificationProvider/NotificationProvider';

import theme from './theme/theme';
import { routes, nonAuthenticatedRoutes } from './options/routes';

import { AuthContext } from 'context/AuthContext';

import { getProfile } from './api';

// Instantiate createBrowserHistory to enable redirecting if #! is in URL
const history = createBrowserHistory();
// Initialise QueryClient to allow all nested content to have access to React Query
const queryClient = new QueryClient();

const App = () => {
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [baseProfile, setBaseProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Remove #! in URL and redirect user to relevant path - S3 bucket redirect fix
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  const loginRedirect = useCallback((activeRoute) => {
    if (!nonAuthenticatedRoutes.some((v) => activeRoute.includes(v))) {
      localStorage.clear();
      window.location.href = routes.login;
    }
  }, []);

  const getUserProfile = useCallback(async () => {
    // Check if profile exists in local storage - redirect to home if trying to access unauthenticated route
    const activeRoute = window.location.pathname;
    const token = localStorage.getItem('token');

    // Do not complete any further checks if there is no token and user is on a non-authenticated page
    if (!token && nonAuthenticatedRoutes.some((v) => activeRoute.includes(v))) {
      setIsLoading(false);
      return;
    }

    // Redirect to home if there is a token and the user is trying to access unauthenticated route
    if (token && nonAuthenticatedRoutes.some((v) => activeRoute.includes(v))) {
      // Changing to routes.profile temp
      // window.location.href = routes.home;
      window.location.href = routes.profile;
      setIsLoading(false);
      return;
    }

    // Get profile
    const response = await getProfile();
    if (!response.success || !response.profile) {
      loginRedirect(activeRoute);
      setIsLoading(false);
      return;
    }
    const { profile } = response;

    // Set local storage and states
    localStorage.setItem('profile', JSON.stringify(profile));
    setUserAuthenticated(true);
    setBaseProfile(profile);
    setIsLoading(false);
  }, [loginRedirect]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <Router>
              <AuthContext.Provider value={{ ...baseProfile, getUserProfile }}>
                <NotificationProvider>
                  {isLoading ? (
                    <PageLoading />
                  ) : (
                    <Content userAuthenticated={userAuthenticated} baseProfile={baseProfile} />
                  )}
                </NotificationProvider>
              </AuthContext.Provider>
            </Router>
          </QueryClientProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  );
};

export default App;
