import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

import { CheckIconStyled, ErrorMessage, TextFieldStyled } from './CustomTextField.styles';

const CustomTextField = ({
  minRows,
  maxRows,
  label,
  type,
  onChange,
  onBlur,
  value,
  startAdornment,
  endAdornment,
  showSuccessAdornment = true,
  error,
  success,
  multiline,
  disabled,
  placeholder,
  fieldStyle = 'primary',
  autoComplete = '',
  removeError,
}) => {
  const endAdornmentSelected = success && showSuccessAdornment ? <CheckIconStyled /> : endAdornment;
  return (
    <div>
      <TextFieldStyled
        label={label}
        type={type}
        value={value}
        variant="outlined"
        margin="normal"
        onChange={onChange}
        onBlur={onBlur}
        error={!removeError && !!error}
        disabled={disabled}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        fieldStyle={fieldStyle}
        placeholder={placeholder}
        autoComplete={autoComplete}
        InputProps={{
          startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
          endAdornment: endAdornmentSelected && <InputAdornment position="end">{endAdornmentSelected}</InputAdornment>,
        }}
      />
      {!removeError && error && (
        <Box height="20px">
          {error && (
            <ErrorMessage variant="body1" colour="error.main">
              {error}
            </ErrorMessage>
          )}
        </Box>
      )}
    </div>
  );
};

CustomTextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'password']),
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startAdornment: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  endAdornment: PropTypes.element,
  showSuccessAdornment: PropTypes.bool,
  error: PropTypes.string,
  success: PropTypes.bool,
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  disabled: PropTypes.bool,
  fieldStyle: PropTypes.oneOf(['primary', 'secondary']),
  removeError: PropTypes.bool,
};

export default React.memo(CustomTextField);
