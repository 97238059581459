import { Link, styled } from '@mui/material';
import desktopLogo from 'assets/svgs/bd-logo-desktop.svg';
import mobileLogo from 'assets/svgs/bd-logo-mobile.svg';

export const NavBarLogoStyled = styled(Link)(({ theme }) => ({
  background: `url(${desktopLogo}) no-repeat center / contain`,
  width: '185px',
  height: '50px',
  cursor: 'pointer',
  [theme.breakpoints.down(1050)]: {
    backgroundImage: `url(${mobileLogo})`,
    height: 40,
    width: 40,
  },
}));
