import { CircularProgress } from '@mui/material';
import { SpinnerContainer } from './Spinner.styles';

const Spinner = ({ align, flex = 1, size }) => {
  return (
    <SpinnerContainer
      display="flex"
      justifyContent={align === 'center' ? 'center' : 'flex-end'}
      alignItems="center"
      flex={flex}
    >
      <CircularProgress size={size} />
    </SpinnerContainer>
  );
};

export default Spinner;
