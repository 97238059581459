import { Box, styled } from '@mui/material';

export const ErrorContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const TilesContainerStyled = styled(Box, {
  shouldForwardProp: (props) => props !== 'numberOfTiles',
})(({ theme, numberOfTiles }) => ({
  display: 'grid',
  gridTemplateColumns: numberOfTiles === 1 ? '150px' : 'repeat(auto-fit, 150px)',
  gap: theme.spacing(1),
  // [theme.breakpoints.down('sm')]: {
  //   gridTemplateColumns: numberOfTiles === 1 ? '1fr' : '1fr 1fr',
  // },
}));
