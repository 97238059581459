import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import CustomModal from 'components/CustomModal/CustomModal';
import Spinner from 'components/Spinner/Spinner';
import BriefSummary from 'components/BriefSummary/BriefSummary';
import BodyText from 'components/Typography/BodyText/BodyText';

import { StyledBriefSummaryWrapper, StyledLoadingOrErrorWrapper } from './PreviewBriefModal.styles';

import { parseRemoteData } from 'pages/Dashboard/Buyer/Briefs/BuyerBriefNew/BuyerBriefNew.utils';
import { parseRemoteData as parseRemoteTermsData } from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/utils/BuyerTermsNew.utils';

import { getBuyerOpenCallsSingle, getBuyersBrief, getSingleTerm } from 'api';

const PreviewBriefModal = ({
  open,
  handleClose,
  isStory,
  mockData,
  brief_uuid,
  isOpenCall,
  showTerms,
  wide,
  variant,
}) => {
  const [termsData, setTermsData] = useState({});
  const [termsLoading, setTermsLoading] = useState(true);
  const { data, isLoading, isError } = useQuery(
    ['buyersBrief', brief_uuid],
    () => (isOpenCall ? getBuyerOpenCallsSingle(brief_uuid) : getBuyersBrief(brief_uuid)),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const getTerms = useCallback(async () => {
    const { brief } = data;
    if (!brief || !brief.selected_terms_uuid) {
      setTermsLoading(false);
      return;
    }
    const termsResponse = await getSingleTerm(brief.selected_terms_uuid);
    const parsedTermsData = (termsResponse?.terms && parseRemoteTermsData(termsResponse.terms)) || {};
    setTermsData(parsedTermsData);
    setTermsLoading(false);
  }, [data]);

  useEffect(() => {
    if (!isLoading && showTerms) {
      getTerms();
    }
  }, [data, isLoading, showTerms, getTerms]);

  const briefSummary = useMemo(
    () => (isStory ? mockData?.data : data && parseRemoteData(isOpenCall ? data?.open_call : data?.brief)),
    [data, isOpenCall, isStory, mockData?.data]
  );
  const loading = useMemo(() => {
    if (isStory) {
      return mockData?.isLoading;
    }
    if (showTerms) {
      return termsLoading && isLoading;
    }
    return isLoading;
  }, [isLoading, isStory, mockData?.isLoading, showTerms, termsLoading]);
  const error = useMemo(() => (isStory ? mockData?.isError : isError), [isError, isStory, mockData?.isError]);
  return (
    <CustomModal open={open} handleClose={handleClose} title="Brief summary" wide={wide} variant={variant}>
      <StyledBriefSummaryWrapper error={error}>
        {loading && (
          <StyledLoadingOrErrorWrapper>
            <Spinner align="center" />
          </StyledLoadingOrErrorWrapper>
        )}
        {!loading && briefSummary && <BriefSummary briefDetails={{ ...briefSummary, ...termsData }} />}
        {error && (
          <StyledLoadingOrErrorWrapper>
            <BodyText text={error.message} />
          </StyledLoadingOrErrorWrapper>
        )}
      </StyledBriefSummaryWrapper>
    </CustomModal>
  );
};

PreviewBriefModal.propTypes = {
  brief_uuid: PropTypes.string,
  mockData: PropTypes.object,
  isStory: PropTypes.bool,
};

export default PreviewBriefModal;
