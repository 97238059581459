import LoginForm from '../../../components/Forms/LoginForm/LoginForm';
import TwoColumnBlackWhiteWrapper from 'components/Layout/TwoColumnBlackWhiteWrapper/TwoColumnBlackWhiteWrapper';

const Login = () => {
  return (
    <TwoColumnBlackWhiteWrapper>
      <LoginForm />
    </TwoColumnBlackWhiteWrapper>
  );
};

export default Login;
