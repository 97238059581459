import BuyerLoginDashboardComponent from 'components/BuyerLoginDashboardComponent/BuyerLoginDashboardComponent';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import { routes } from 'options/routes';

const BuyerDashboardLanding = () => {
  return (
    <BuyerDashboardWrapper activeTab={routes.dashboard}>
      <BuyerLoginDashboardComponent />
    </BuyerDashboardWrapper>
  );
};

export default BuyerDashboardLanding;
