import { useEffect, useState } from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';

import { Box } from '@mui/material';
import ProductSingle from 'components/ProductSingle/ProductSingle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';
import EmptyListCreateNew from 'components/EmptyListCreateNew/EmptyListCreateNew';
import BodyText from 'components/Typography/BodyText/BodyText';
import ModalUploadProductToBrief from 'components/ModalUploadProductToBrief/ModalUploadProductToBrief';

import { PROPOSAL_STATUS } from 'options/proposalStatus';
import { PROJECT_STATUS } from 'options/projectStatus';
import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';

import { parseRemoteData } from '../DesignerBriefProposalNew/utils/DesignerBriefProposalNew.utils';
import { getIsProposalTabDisabled } from '../DesignerSingleBrief.utils';

import { StyledProposalWrapper, StyledButtonWrapper } from './DesignerBriefProposalDetails.styles';

import { getDesignerSingleProposal } from 'api';

const getText = (proposal_status) => {
  switch (proposal_status) {
    case PROPOSAL_STATUS.PENDING_APPROVAL: {
      return 'Waiting for the buyer to respond';
    }
    case PROPOSAL_STATUS.ACCEPTED: {
      return 'The buyer has approved the proposal';
    }
    case PROPOSAL_STATUS.REJECTED: {
      return 'The buyer has rejected the proposal';
    }
    default: {
      return '';
    }
  }
};

export const DesignerBriefProposalDetails = () => {
  const { brief_uuid } = useParams();
  const [briefData] = useOutletContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [openUploadProductToBrief, setOpenUploadProductToBrief] = useState(false);

  const project_status = briefData?.project_status || '';
  const proposal_status = briefData?.proposal_status || '';

  useEffect(() => {
    const isProposalDisabled = getIsProposalTabDisabled(project_status);

    if (isProposalDisabled) {
      navigate(`${routes.briefsDashboard}/${brief_uuid}/brief`);
    }
  }, [brief_uuid, navigate, project_status, proposal_status]);

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['designerSingleProposal', brief_uuid],
    () => getDesignerSingleProposal(brief_uuid),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const proposalDetails = (data && data?.proposal && parseRemoteData(data?.proposal)) || {};
  const hasProposal = !!Object.keys(proposalDetails).length;
  const isProjectRejected = project_status === PROJECT_STATUS.REJECTED;

  const company_name = briefData?.company?.name;
  const company_uuid = briefData?.company?.uuid;

  const { status, converted } = proposalDetails;

  const text = !isProjectRejected && getText(status);

  const loading = isLoading || isRefetching;
  const showEditButton =
    ([PROPOSAL_STATUS.PENDING_APPROVAL, PROPOSAL_STATUS.DRAFT].includes(status) ||
      (project_status === PROJECT_STATUS.ACCEPTED && status === PROPOSAL_STATUS.REJECTED)) &&
    !isProjectRejected;

  return (
    <StyledProposalWrapper>
      {loading && (
        <Box mt={2}>
          <Spinner align="center" flex={0} />
        </Box>
      )}
      {!loading && !!Object.keys(proposalDetails).length && (
        <>
          <Box my={2}>
            <ProductSingle
              data={{ ...proposalDetails, company_name, company_uuid }}
              userType={userTypes.designer}
              variant="brief_proposal_view"
            />
          </Box>
          {text && (
            <Box>
              <BodyText text={text} />
            </Box>
          )}
          <StyledButtonWrapper>
            {(status === PROPOSAL_STATUS.REJECTED || isProjectRejected) && !converted && (
              <CustomButton
                text="Upload to marketplace"
                onClick={() => navigate(`${routes.productsAdd}`, { state: { proposal: proposalDetails } })}
              />
            )}
            {converted && (
              <Box>
                <BodyText text={'You have uploaded this product to the markeplace'} />
              </Box>
            )}
            {!converted && showEditButton && (
              <CustomButton
                text="Edit"
                onClick={() =>
                  navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal/add/${proposalDetails?.proposal_uuid}`)
                }
              />
            )}
          </StyledButtonWrapper>
        </>
      )}
      {!loading &&
        !Object.keys(proposalDetails).length &&
        !isProjectRejected &&
        ![PROJECT_STATUS.DESIGNER_CANCELLED, PROJECT_STATUS.BUYER_CANCELLED].includes(project_status) && (
          <EmptyListCreateNew
            title="You haven't made a proposal yet"
            description="Create a new proposal or select a product from your profile to use."
            btnText="Create Proposal"
            onClick={() => navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal/add`)}
            secondBtnText="Select product"
            secondBtnOnClick={() => setOpenUploadProductToBrief(true)}
          />
        )}
      {isProjectRejected && !hasProposal && (
        <Box>
          <BodyText text={'This brief has been rejected.'} />
        </Box>
      )}
      {!hasProposal && project_status === PROJECT_STATUS.BUYER_CANCELLED && (
        <Box>
          <BodyText text={'This brief has been cancelled.'} />
        </Box>
      )}
      {!hasProposal && project_status === PROJECT_STATUS.DESIGNER_CANCELLED && (
        <Box>
          <BodyText text={'This proposal has been cancelled.'} />
        </Box>
      )}
      {openUploadProductToBrief && (
        <ModalUploadProductToBrief
          brief_uuid={brief_uuid}
          open={openUploadProductToBrief}
          onClose={() => setOpenUploadProductToBrief(false)}
          onSuccess={() => {
            queryClient.setQueryData(['designerSingleBrief', brief_uuid], (oldData) => {
              if (oldData) {
                return {
                  ...oldData,
                  brief: {
                    ...oldData?.brief,
                    proposal_status: PROPOSAL_STATUS.PENDING_APPROVAL,
                  },
                };
              }
            });
            refetch();
            setOpenUploadProductToBrief(false);
          }}
        />
      )}
    </StyledProposalWrapper>
  );
};

export default DesignerBriefProposalDetails;
