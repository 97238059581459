import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import BuyerOpenCalls from './BuyerOpenCalls/BuyerOpenCalls';
import PageWrapper from '../../components/Wrappers/PageWrapper/PageWrapper';
import Spinner from '../../components/Spinner/Spinner';
import ProfileDetails from '../../components/ProfileDetails/ProfileDetails';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import DesignerProducts from './DesignerProducts/DesignerProducts';

import { userTypes } from '../../options/userTypes';

import { useMessageContext } from 'context/MessageContext';

import { getDesignerProfile, getBuyerProfile } from '../../api';

const Profile = ({ baseProfile }) => {
  const [userRole, setUserRole] = useState('');
  const [profileDetails, setProfileDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [setMessage] = useMessageContext();
  const [error, setError] = useState('');
  const [canCreate, setCanCreate] = useState(false);

  const setProfileInfo = useCallback((profile, role) => {
    // Parse profile data based on user type
    switch (role) {
      case userTypes.designer:
        return {
          uuid: profile.user_uuid,
          first: profile.first_name,
          last: profile.last_name,
          bio: profile.bio,
          portrait: profile.portrait && profile.portrait.fileSrc ? profile.portrait.fileSrc : null,
          city: profile.studio_city,
          country: profile.studio_country,
          website: profile.website,
          instagram: profile.instagram,
          twitter: profile.twitter,
          linkedin: profile.linkedin,
          pinterest: profile.pinterest,
          products: profile.product_types,
          joined: profile.date_joined,
          current_products: profile.current_products,
        };
      default:
        return {
          uuid: profile.user_uuid,
          first: profile.name,
          last: '',
          bio: profile.about,
          portrait: profile.logo && profile.logo.fileSrc ? profile.logo.fileSrc : null,
          city: profile.city,
          country: profile.country,
          website: profile.website,
          instagram: profile.instagram,
          twitter: profile.twitter,
          linkedin: profile.linkedin,
          pinterest: profile.pinterest,
          products: profile.product_interests,
          joined: profile.date_joined,
        };
    }
  }, []);

  const getProfileInfo = useCallback(async () => {
    if (Object.keys(baseProfile).length) {
      // Get role, uuid and company_uuid (if buyer) from baseProfile
      const { role } = baseProfile;
      const uuid = role === userTypes.designer ? baseProfile.uuid : baseProfile.company_uuid;
      setUserRole(role === userTypes.designer ? userTypes.designer : userTypes.buyer);

      // Make relevant API call based on user type
      const response = role === userTypes.designer ? await getDesignerProfile(uuid) : await getBuyerProfile(uuid);
      if (!response.success && !(role === userTypes.designer ? response.designer_profile : response.buyer_profile)) {
        setMessage(response.data.error_message);
        setLoading(false);
        return;
      }

      // Set profile details
      const profile = role === userTypes.designer ? response.designer_profile : response.buyer_profile;
      const details = setProfileInfo(profile, role);
      setProfileDetails(details);
      setLoading(false);
    }
  }, [baseProfile, setMessage, setProfileInfo]);

  useEffect(() => {
    getProfileInfo();
  }, [getProfileInfo]);

  return (
    <PageWrapper userType={userRole} showSecondaryFooter={false}>
      {loading && <Spinner align="center" />}
      {!loading && profileDetails && Object.keys(profileDetails).length && (
        <PageContentWrapper>
          <ProfileDetails profileDetails={profileDetails} viewType={userTypes.personal} canCreateOpenCall={canCreate} />
          {userRole && userRole === userTypes.designer ? (
            <DesignerProducts location="designer_profile" />
          ) : (
            <BuyerOpenCalls company_uuid={baseProfile.company_uuid || ''} setCanCreate={setCanCreate} />
          )}
        </PageContentWrapper>
      )}
    </PageWrapper>
  );
};

Profile.propTypes = {
  baseProfile: PropTypes.object.isRequired,
};

export default Profile;
