import format from 'date-fns/format';

// Parse Date object to YYYY-MM-DD string format
export const parseDateToFullDateString = (date) => format(date, 'yyyy-MM-dd');

// Parse Date object to YYYY-MM string format
export const parseDateToMonthYear = (date) => format(date, 'yyyy-MM');

// Parse Date object to YYYY string format
export const parseDateToYear = (date) => format(date, 'yyyy');

// Parse string to Date object
export const parseStringToDate = (dateString) => new Date(dateString);

// Parse string to Date object from DD-MM-YYYY
export const DateObjFromUKDateStr = (ukDateStr) => {
  if (!ukDateStr) throw new Error('DateObjFromString expects an argument in DD-MM-YYYY format');
  const arr = ukDateStr.split('/');
  if (!arr || arr.length < 3) throw new Error('DateObjFromString expects an argument in DD-MM-YYYY format');
  return new Date(`${arr[1]}/${arr[0]}/${arr[2]}`);
};
