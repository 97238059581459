import { useState, useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';

import { Box, Table } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import BodyText from 'components/Typography/BodyText/BodyText';
import PillTag from 'components/PillTag/PillTag';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import Spinner from 'components/Spinner/Spinner';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import PreviewBriefModal from 'components/PreviewBriefModal/PreviewBriefModal';

import { existingBriefsHeadings } from 'options/tableOptions';

import { getUniqueList } from 'utils/getUniqueList';
import { addFilters } from 'utils/addFilters';

import { useTableFilter } from 'hooks/useTableFilter';

import { CategoriesWrapperStyled } from './ExistingBriefsList.styles';
import { VisibilityIconWrapper } from 'components/Table/Table.styles';

import { getBuyerOpenCallsList, getBuyersBriefList } from 'api';

const ExistingBriefsList = ({ isStory, mockData, mockTotal, handleBriefSelection, isOpenCall }) => {
  const { order, orderBy, filter, setFilter, p, l, parsedFilter, onPageChange, onRequestSort, onRowsPerPageChange } =
    useTableFilter({ initialOrder: 'desc', initialOrderBy: 'date_sent' });

  const [selectedBriefUUID, setSelectedBriefUUID] = useState('');
  const [previewBriefUUID, setPreviewBriefUUID] = useState('');

  const { data, isLoading } = useQuery(
    ['buyerBriefsList', parsedFilter],
    () => (isOpenCall ? getBuyerOpenCallsList(parsedFilter) : getBuyersBriefList(parsedFilter)),
    {
      enabled: !isStory,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const list = isOpenCall ? data?.open_calls : data?.briefs;

  const briefsList = useMemo(() => (isStory ? mockData : list || []), [list, isStory, mockData]);
  const totalCount = useMemo(() => (isStory ? mockTotal : data?.total || []), [data?.total, isStory, mockTotal]);

  const selectBrief = useCallback(
    (brief_uuid) => {
      setSelectedBriefUUID(brief_uuid);
      handleBriefSelection(brief_uuid);
    },
    [handleBriefSelection]
  );

  const onPreviewClick = (e, brief_uuid) => {
    e.stopPropagation();
    setPreviewBriefUUID(brief_uuid);
  };

  const allCategories = useMemo(
    () => getUniqueList(briefsList.map(({ categories }) => categories)?.flat(), 'slug'),
    [briefsList]
  );

  const filtersList = useMemo(
    () => ({
      main_categories: allCategories,
    }),
    [allCategories]
  );

  const headingsOnClick = useMemo(() => {
    return existingBriefsHeadings.map((menuItem) =>
      !menuItem.sortable
        ? {
            ...menuItem,
            menuOptions: addFilters(menuItem, filtersList, setFilter, filter),
          }
        : menuItem
    );
  }, [filter, filtersList]);

  return (
    <Box>
      {isLoading && !isStory ? (
        <Spinner align="center" flex={0} />
      ) : (
        <CustomTableWrapper
          noResultsTitle={briefsList.length === 0 ? 'No briefs found' : ''}
          noResultsDescription={briefsList.length === 0 ? "You haven't added any briefs yet. Let's get started!" : ''}
        >
          <Table>
            <CustomTableHeader
              columnHeadings={headingsOnClick}
              order={order}
              orderBy={orderBy}
              onRequestSort={onRequestSort}
            />
            <CustomTableBody>
              {briefsList.map((item, index) => {
                const { title, categories, date_sent, brief_uuid } = item;
                const isSelected = selectedBriefUUID === brief_uuid;
                return (
                  <CustomTableRow
                    key={`table_row_${index}`}
                    onClick={() => selectBrief(brief_uuid)}
                    isSelected={isSelected}
                  >
                    <CustomTableBodyCell>
                      {isSelected ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <BodyText text={title} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <CategoriesWrapperStyled>
                        {getUniqueList(categories, 'slug')?.map((category, i) => {
                          const { name, slug } = category;
                          return (
                            <Box mr={1} mb={1} key={`${category}_${i}`}>
                              <PillTag option={{ label: name, value: slug }} />
                            </Box>
                          );
                        })}
                      </CategoriesWrapperStyled>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <BodyText text={date_sent || '--'} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <VisibilityIconWrapper>
                        <VisibilityIcon onClick={(e) => onPreviewClick(e, brief_uuid)} />
                      </VisibilityIconWrapper>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </Table>
          <Box>
            <CustomTablePagination
              count={totalCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPage={l}
              rowsPerPageOptions={[5, 10, 15]}
              page={p}
            />
          </Box>
        </CustomTableWrapper>
      )}

      {!!previewBriefUUID && (
        <PreviewBriefModal
          open={!!previewBriefUUID}
          handleClose={() => setPreviewBriefUUID('')}
          brief_uuid={previewBriefUUID}
          isStory={false}
          isOpenCall={isOpenCall}
          variant="secondary"
        />
      )}
    </Box>
  );
};

export default ExistingBriefsList;
