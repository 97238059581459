import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import SearchIcon from '@mui/icons-material/Search';
import FormStepper from 'components/FormStepper/FormStepper';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import { DesignerPitchNewSteps } from './DesignerPitchNewSteps';

import { routes } from 'options/routes';

import { getErrorMessage } from 'utils/getErrorMessage';
import { parseProductProposalFormData, parseRemoteProductProposalData } from 'utils/parseProductProposal';

import { formDefaultValues, formValidation, steps } from './DesignerPitchNew.data';

import { createDesignerSinglePitch, getDesignerSinglePitch, updateDesignerSinglePitch } from 'api';
import { useAuthContext } from 'context/AuthContext';

const DesignerPitchNew = ({ isStory }) => {
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const buyers = location?.state?.buyers;
  const { currency } = useAuthContext();

  const [apiFormData, setApiFormData] = useState({});

  useEffect(() => {
    if (buyers?.length > 0) {
      const newData = { ...formDefaultValues, buyers };
      setApiFormData(newData);
    }
  }, [buyers]);

  useEffect(() => {
    const getInitialValues = async () => {
      setIsFetching(true);
      const res = await getDesignerSinglePitch(id);
      setIsFetching(false);
      if (res.success) {
        setApiFormData(parseRemoteProductProposalData(res.pitch));
      }
    };

    if (id) {
      getInitialValues();
    }
  }, [id]);

  const createOrUpdateDesignerPitch = useCallback(async (values, isLastStep) => {
    const parsedFormData = parseProductProposalFormData(values, isLastStep);

    const { product_uuid } = parsedFormData;

    return !product_uuid
      ? await createDesignerSinglePitch(parsedFormData)
      : await updateDesignerSinglePitch(product_uuid, parsedFormData);
  }, []);

  const nextStepAction = useCallback(
    async (values, step) => {
      if (step > 0) {
        const isLastStep = steps.length - 1 === step;
        const res = await createOrUpdateDesignerPitch(values, isLastStep);
        if (!res.success) {
          throw new Error(getErrorMessage(res));
        }
        return isLastStep
          ? navigate(routes.pitchesAddThanks)
          : setApiFormData(parseRemoteProductProposalData(res.pitch));
      }
    },
    [createOrUpdateDesignerPitch, navigate]
  );

  const saveAndExit = useCallback(
    async (values) => {
      const res = await createOrUpdateDesignerPitch(values);
      if (!res.success) {
        throw new Error(getErrorMessage(res));
      }
      return navigate(routes.pitchesDashboard);
    },
    [createOrUpdateDesignerPitch, navigate]
  );

  return (
    <DesignerDashboardWrapper
      activeTab={routes.pitchesDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Pitches', onClick: () => navigate(routes.pitchesDashboard) }]}
          currentPage={'New pitch proposal'}
        />
      }
    >
      <HeaderAndMenu
        title="New pitch proposal"
        menuOptions={[
          { buttonTitle: 'View all pitches', icon: <SearchIcon />, onClick: () => navigate(routes.pitchesDashboard) },
        ]}
      />
      <FormStepper
        steps={steps}
        stepsSwitcher={DesignerPitchNewSteps}
        formValidation={formValidation}
        defaultFormValues={formDefaultValues}
        apiFormData={apiFormData}
        nextStepAction={nextStepAction}
        onSaveAndExit={saveAndExit}
        saveAndExitSteps={[1, 2]}
        saveAndExitRequiredFields={[null, ['name'], null]}
        lastStepAction={nextStepAction}
        isStory={isStory}
        stepsOptions={{ isFetching, currency }}
      />
    </DesignerDashboardWrapper>
  );
};

export default DesignerPitchNew;
