import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import NotificationCounter from '../NotificationCounter/NotificationCounter';
import NotificationListItem from '../NotificationListItem/NotificationListItem';

import {
  NavBarIconStyled,
  NotificationMenuStyled,
  NotificationStyled,
  NotificationWrapper,
} from './NavBarIconButton.styles';

import { useNotification } from 'hooks/useNotification';

const NavBarNotificationButton = ({ isStory }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const { count, notificationsList, isNotificationsLoading, removeNotification } = useNotification(open, isStory);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <NavBarIconStyled onClick={handleClick}>
        <NotificationStyled />
        {count && (
          <NotificationWrapper>
            <NotificationCounter count={count} />
          </NotificationWrapper>
        )}
      </NavBarIconStyled>
      <NotificationMenuStyled
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: -50 }}
      >
        {isNotificationsLoading && !isStory && (
          <MenuItem my={2} width="40px" height="20px">
            <Spinner align="center" size="16px" />{' '}
          </MenuItem>
        )}
        {!isNotificationsLoading && !notificationsList?.length && (
          <NotificationListItem title="You have no notifications yet" read />
        )}
        {notificationsList?.map(({ notification_uuid, ...rest }, i) => {
          return <NotificationListItem {...rest} notification_uuid={notification_uuid} key={`${notification_uuid}_${i}`} removeNotification={removeNotification}/>;
        })}
      </NotificationMenuStyled>
    </div>
  );
};

NavBarNotificationButton.propTypes = {
  isStory: PropTypes.bool,
};

export default NavBarNotificationButton;
