import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/system';

export const TooltipContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  maxWidth: '350px',
}));

export const TooltipText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.white,
  fontSize: '0.75rem',
  whiteSpace: 'pre-wrap',
}));

export const InfoOutlinedIconStyled = styled(InfoOutlinedIcon)(({ theme }) => ({
  margin: `0px ${theme.spacing(1)} 0px 0px`,
  color: theme.palette.text.white,
}));
