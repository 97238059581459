import { Box, styled } from '@mui/material';

export const StyledNamesListContainer = styled(Box)(({ theme }) => ({}));

export const StyledNamesColumnWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  '& > *': {
    flexBasis: `calc(20% - ${theme.spacing(3)})`,
    flexGrow: 1,
    maxWidth: `calc(20% - ${theme.spacing(3)})`,
    marginBottom: 36,
    marginRight: 20,
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(2),
      flexBasis: `calc(33.3333% - ${theme.spacing(2)})`,
      maxWidth: `calc(33.3333% - ${theme.spacing(2)})`,
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: `calc(50% - ${theme.spacing(2)})`,
      maxWidth: `calc(50% - ${theme.spacing(2)})`,
    },
  },
}));
