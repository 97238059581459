import { useOutletContext } from 'react-router';

import { Box } from '@mui/material';
import ProductSingle from 'components/ProductSingle/ProductSingle';

import { userTypes } from 'options/userTypes';

export const DesignerSingleProductTab = () => {
  const [product] = useOutletContext();

  return (
    <Box mt={2}>
      <ProductSingle data={product} userType={userTypes.designer} variant="product_view" />
    </Box>
  );
};

export default DesignerSingleProductTab;
