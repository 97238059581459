import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { IconWrapper, SupportingDetailsContainer } from './SupportingDetails.styles';

const SupportingDetailsText = ({ text, icon }) => {
  return (
    <SupportingDetailsContainer>
      <IconWrapper>{icon}</IconWrapper>
      <Typography variant="body2">{text}</Typography>
    </SupportingDetailsContainer>
  );
};

SupportingDetailsText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.element,
};

export default SupportingDetailsText;
