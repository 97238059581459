import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import { Box, Step, StepButton, useMediaQuery } from '@mui/material';
import FormActionButton from 'components/Buttons/FormActionButton/FormActionButton';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import FormError from 'components/Forms/FormError/FormError';
import Spinner from 'components/Spinner/Spinner';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import {
  ContentContainer,
  FormContainer,
  FormPageWrapper,
  SpinnerWrapper,
  StepperContainer,
  StepperStyled,
  TitleContainer,
} from './FormStepper.styles';

const FormStepper = ({
  steps,
  stepsSwitcher,
  defaultFormValues,
  formValidation,
  nextStepAction,
  onSaveAndExit,
  saveAndExitRequiredFields,
  saveAndExitSteps,
  apiFormData,
  isStory,
  userType,
  stepsOptions,
  submitText = 'Submit',
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [activeStep, setActiveStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState([]);

  const resolver = useYupValidationResolver(formValidation[activeStep]);
  const methods = useForm({
    defaultValues: defaultFormValues,
    mode: 'onChange',
    resolver,
  });

  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (Object.keys(apiFormData).length > 0) {
      methods.reset(apiFormData);
    }
  }, [apiFormData, methods]);

  const scrollUp = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    scrollUp();
  }, [activeStep, scrollUp]);

  const currentHeader = useMemo(() => steps[activeStep], [steps, activeStep]);

  const handleNext = useCallback(async () => {
    const isValid = await methods.trigger();

    const values = methods.getValues();

    if (isValid) {
      // next step action
      try {
        setIsSubmitting(true);
        await nextStepAction(values, activeStep);
        setIsSubmitting(false);
        setActiveStep((prevState) => prevState + 1);
        if (apiError && apiError.length > 0) {
          setApiError([]);
        }
      } catch (err) {
        setIsSubmitting(false);
        setApiError([err.toString()]);
        scrollUp();
      }
    } else {
      // is not valid
      scrollUp();
    }
  }, [activeStep, apiError, methods, nextStepAction, scrollUp]);

  const handleBack = useCallback(() => {
    if (activeStep !== 0) {
      setActiveStep((prevState) => prevState - 1);
      if (apiError && apiError.length > 0) {
        setApiError([]);
      }
    }
  }, [activeStep, apiError]);

  const handleSaveAndExit = useCallback(async () => {
    // clear errors and validate only save and exit valid steps
    // this helps if there's any error such as duplicate images still present
    methods.clearErrors();
    const requiredFields = saveAndExitRequiredFields[activeStep];
    const isDraftValid = requiredFields ? await methods.trigger(requiredFields) : true;
    if (isDraftValid) {
      try {
        const values = methods.getValues();
        setIsSubmitting(true);
        await onSaveAndExit(values, activeStep);
        setIsSubmitting(false);
      } catch (err) {
        setIsSubmitting(false);
        setApiError([err.toString()]);
        scrollUp();
      }
    }
  }, [activeStep, methods, onSaveAndExit, saveAndExitRequiredFields, scrollUp]);

  const stepOrientation = isMobile ? 'horizontal' : 'vertical';
  const flexDirection = isMobile ? 'column' : 'row';

  const currentErrors = !!Object.keys(errors).length
    ? Object.entries(errors).map((entry) => entry[1].message)
    : apiError;

  return (
    <FormPageWrapper flexDirection={flexDirection} container>
      <StepperContainer md={2}>
        <StepperStyled activeStep={activeStep} orientation={stepOrientation} alternativeLabel={isMobile}>
          {steps.map((label, i) => (
            <Step key={`${label}_${i}`}>
              <StepButton>{label}</StepButton>
            </Step>
          ))}
        </StepperStyled>
      </StepperContainer>
      {isSubmitting ? (
        <SpinnerWrapper>
          <Spinner justifyContent="center" flex={0} />
        </SpinnerWrapper>
      ) : (
        <ContentContainer md={10}>
          <TitleContainer>
            <CustomTitle variant="h3" text={currentHeader} />
          </TitleContainer>
          <Box>
            <FormContainer>
              <Box my={2.625}>{!!currentErrors?.length && <FormError errors={currentErrors} />}</Box>
              <FormProvider {...methods}>
                <form>
                  {stepsSwitcher(activeStep, isStory, userType, stepsOptions)}
                  <Box display="flex" justifyContent="flex-start" mt="32px" mb="72px">
                    <Box mr={1}>
                      <FormActionButton onClick={handleBack} text="Back" variant="text" isBack disabled={!activeStep} />
                    </Box>
                    {!!saveAndExitSteps?.includes(activeStep) && (
                      <Box mr={1}>
                        <FormActionButton onClick={handleSaveAndExit} text="Save and Exit" variant="text" />
                      </Box>
                    )}
                    <FormActionButton
                      onClick={handleNext}
                      text={activeStep === steps.length - 1 ? submitText : 'Continue'}
                      variant="outlined"
                    />
                  </Box>
                </form>
              </FormProvider>
            </FormContainer>
          </Box>
        </ContentContainer>
      )}
    </FormPageWrapper>
  );
};

FormStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultFormValues: PropTypes.object.isRequired,
  formValidation: PropTypes.array,
  stepsSwitcher: PropTypes.func,
  nextStepAction: PropTypes.func,
  lastStepAction: PropTypes.func,
  onSaveAndExit: PropTypes.func,
  saveAndExitRequiredFields: PropTypes.array,
  apiFormData: PropTypes.object,
  apiError: PropTypes.array,
};

export default FormStepper;
