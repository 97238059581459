import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const AccordionContentStyled = styled(Box)(({ theme, borderBottom }) => ({
  padding: `0 0 ${theme.spacing(2)} 0`,
  '&:first-of-type': {
    paddingTop: theme.spacing(2),
  },
  borderBottom: borderBottom ? '1px solid' : '',
  [theme.breakpoints.down('md')]: {
    borderBottom: 'unset',
  },
}));

export const AccordionContentFlexRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(3),
  '& > *': { flex: 1 },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
