import { LICENSE_TYPES } from './briefOptions';

export const LICENSE_OPTIONS = [
  {
    label: 'Royalty license agreement',
    value: LICENSE_TYPES.royalty,
  },
  {
    label: 'One time fee agreement',
    value: LICENSE_TYPES.one_off,
  },
];

export const UPFRONT_FEE_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];
