import React, { useCallback, useMemo, useState } from 'react';

import { Box } from '@mui/system';
import Spinner from 'components/Spinner/Spinner';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import { routes } from 'options/routes';
import { useNavigate } from 'react-router';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import { BUYER_PRODUCT_HISTORY_HEADINGS } from 'options/tableOptions';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import BodyText from 'components/Typography/BodyText/BodyText';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import { TableStyled } from 'components/Table/Table.styles';
import { CURRENCY_MAP } from 'options/currency';

const BuyerDashboardProductHistoryTable = ({ data, total, isLoading }) => {
  const navigate = useNavigate();

  const onView = useCallback(
    (product_uuid) => {
      navigate(`${routes.productsDashboard}/${product_uuid}/product`, {
        state: { from: routes.dashboard, fromTitle: 'Dashboard' },
      });
    },
    [navigate]
  );

  return (
    <Box>
      {isLoading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <CustomTableWrapper
          noResultsTitle={!isLoading && data.length === 0 ? 'No products found' : ''}
          noResultsDescription={
            !isLoading && data.length === 0 ? "You haven't purchased any products yet. Let's get started!" : ''
          }
          btnText={!isLoading && data.length === 0 ? 'Find products' : ''}
          onBtnClick={() => navigate(routes.products)}
        >
          <TableStyled minWidth={'none'}>
            <CustomTableHeader columnHeadings={BUYER_PRODUCT_HISTORY_HEADINGS} />
            <CustomTableBody>
              {data?.map((item) => {
                const { name, main_image, price, currency, product_uuid, application_fee } = item;
                const currencyIcon = currency ? CURRENCY_MAP[currency] : '£';
                return (
                  <CustomTableRow key={`table_row_${product_uuid}`} onClick={() => onView(product_uuid)}>
                    <CustomTableBodyCell>{<ItemInfo avatar={main_image || {}} label={name} />}</CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box display={'flex'} justifyContent={'flex-end'}>
                        <BodyText text={price ? `${currencyIcon}${price}` : '%'} />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box display={'flex'} justifyContent={'flex-end'}>
                        <BodyText
                          text={application_fee ? `${currencyIcon}${parseFloat(application_fee).toFixed(2)}` : '--'}
                        />
                      </Box>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </TableStyled>
        </CustomTableWrapper>
      )}
    </Box>
  );
};

BuyerDashboardProductHistoryTable.propTypes = {};

export default BuyerDashboardProductHistoryTable;
