import React from 'react';
import PropTypes from 'prop-types';

import { BodyTextStyled } from './BodyText.styles';

const BodyText = ({
  text,
  colour,
  bold,
  fontSize = 'medium',
  variant = 'body1',
  truncateWord,
  handleOnClick,
  styleOverrides,
}) => {
  return (
    <BodyTextStyled
      style={{ color: colour ? colour : undefined }}
      variant={variant}
      bold={bold ? 'true' : ''}
      fontSize={fontSize}
      truncateWord={truncateWord}
      onClick={handleOnClick}
      styleOverrides={styleOverrides}
    >
      {text}
    </BodyTextStyled>
  );
};

BodyText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]), // sometimes the parsing of text becoming an array of elements
  colour: PropTypes.string,
  bold: PropTypes.bool,
};

export default BodyText;
