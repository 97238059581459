import { useEffect, useCallback } from 'react';

import { OPTIONS_TYPE } from '../../../options/selectOptions';
import { useQueries } from 'react-query';

import { getTopOptions } from '../../../api';

import { StyledSignUpWrapper } from './SignUpWrapper.styles';
// import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
// import useCustomMediaQueries from 'hooks/useCustomMediaQueries';

const SignUpWrapper = ({ getOptionsStates, children }) => {
  const optionsQueries = useQueries({
    queries: [
      {
        queryKey: ['topCompanyCategories'],
        queryFn: () => getTopOptions(OPTIONS_TYPE.company),
        staleTime: 5 * 60 * 1000,
        retryDelay: 500,
      },
      {
        queryKey: ['topProductCategories'],
        queryFn: () => getTopOptions(OPTIONS_TYPE.product),
        staleTime: 5 * 60 * 1000,
        retryDelay: 500,
      },
      {
        queryKey: ['topSpaceTags'],
        queryFn: () => getTopOptions(OPTIONS_TYPE.space),
        staleTime: 5 * 60 * 1000,
        retryDelay: 500,
      },
    ],
  });

  const isLoading = optionsQueries.some((query) => query.isLoading);

  const parseData = useCallback(
    (queries) => {
      let companyCategories = [],
        productCategories = [],
        spaceTags = [],
        error = '',
        loading = false;

      // Set error and data states
      if (queries.some((query) => !query.data.success)) error = 'There was an error when getting one or more options';
      if (queries[0].data.success && queries[0].data.options) companyCategories = queries[0].data.options;
      if (queries[1].data.success && queries[1].data.options) productCategories = queries[1].data.options;
      if (queries[2].data.success && queries[2].data.options) spaceTags = queries[2].data.options;
      getOptionsStates({ error, loading, companyCategories, productCategories, spaceTags });
    },
    [getOptionsStates]
  );

  useEffect(() => {
    if (!isLoading) {
      parseData(optionsQueries);
    }
  }, [isLoading, parseData, optionsQueries]);

  // const { isTablet } = useCustomMediaQueries();

  return (
    <StyledSignUpWrapper>
      {children}
    </StyledSignUpWrapper>
  );
};

export default SignUpWrapper;
