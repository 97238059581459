import { Box, styled } from '@mui/material';

export const OverlayStyled = styled(Box)(({ show, theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  transition: 'opacity 300ms ease-in-out',
  opacity: show ? 1 : 0,
  height: '100%',
  width: '100%',
  backgroundColor: `rgba(242, 242, 242, 0.8);`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

export const ContentWrapper = styled(Box)(({}) => ({
  position: 'relative',
  width: '100%',
  height: '100%',
}));

export const ImageButtonsStyled = styled(Box)(({}) => ({
  position: 'absolute',
  bottom: 20,
  left: 20,
}));

export const HiddenWrapper = styled(Box)(({ theme }) => ({
  // position: 'absolute',
  // top: theme.spacing(2),
  // right: theme.spacing(2),
  width: 24,
  height: 24,
  cursor: 'pointer',
  borderRadius: 24,
  '&:hover': {
    backgroundColor: theme.palette.background.grayHover,
  },
}));

export const DeleteWrapper = styled(Box)(({ theme }) => ({
  // position: 'absolute',
  // bottom: theme.spacing(2),
  // right: theme.spacing(2),
  width: 24,
  height: 24,
  cursor: 'pointer',
  borderRadius: 24,
  '&:hover': {
    backgroundColor: theme.palette.background.grayHover,
  }
}));
