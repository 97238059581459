import { styled } from '@mui/system';

export const NotificationContainer = styled('div')(({ variant }) => ({
  position: variant === 'fixed' ? 'fixed' : 'absolute',
  bottom: 25,
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  zIndex: 10000,
  left: '50%',
  transform: 'translate(-50%, 0)',
}));
