export const MOCK_NAV_NOTIFICATIONS = {
  link: '/some-link',
  title: 'New Brief Accepted',
  created: '12/03/2023',
  notification_uuid: '123',
  read: true,
};

export const MOCK_NAV_NOTIFICATIONS_LIST = Array(10)
  .fill()
  .map((_i, index) => ({ ...MOCK_NAV_NOTIFICATIONS, notification_uuid: String(new Date()), read: index !== 0 }));
