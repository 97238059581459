import React from 'react';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ControlledCustomFiledUpload from 'components/CustomFileUpload/ControlledCustomFileUpload/ControlledCustomFileUpload';
import ControlledSelectField from 'components/Inputs/CustomSelectField/ControlledSelectField/ControlledSelectField';
import SingleInputResponsiveWrapper from 'components/Wrappers/SingleInputResponsiveWrapper/SingleInputResponsiveWrapper';
import FormFieldSetAndInputWrapper from 'components/Wrappers/FormFieldSetAndInputWrapper/FormFieldSetAndInputWrapper';
import ControlledCustomFiledUploadDND from 'components/CustomFileUpload/ControlledCustomFIleUploadDND/ControlledCustomFileUploadDND';

import { CURRENCY_MAP } from 'options/currency';

import { useAuthContext } from 'context/AuthContext';

import { FILE_REQUIREMENTS, NEW_BRIEF_MARKETS, NEW_BRIEF_PROJECTED_VOLUME_OPTIONS } from './NewBriefDetailsForm.data';

const NewBriefDetailsForm = () => {
  const { currency } = useAuthContext();
  const currencyIcon = currency ? CURRENCY_MAP[currency] : '£';
  const showMoreOption = { label: 'Show More', value: 'show-more-options' };
  const fileRequirementsList = [...FILE_REQUIREMENTS, showMoreOption];

  return (
    <div>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Brief Title"
          tooltipText="The Title will be used across the project from start to finish and will be how you find and share the work"
        />

        <ControlledTextField name="title" placeholder="Give this brief a name" />
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Design Requirements"
          tooltipText="List what is needed or wanted; a compulsory or necessary condition. Design requirements are the base attributes that enable the designer to convert your requirements into ideas and design features. This could include form, ergonomics, modularity, style, colour, material and finish."
        />

        <ControlledTextField
          multiline={true}
          minRows={5}
          name="design_requirements"
          placeholder="Describe your design brief"
        />
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Customer Requirements"
          tooltipText="What is required by your customers? What motivates your consumers to purchase a product. This can be driven by a set of needs, including form, functionality, price, quality, and sustainability. Describe your market you wish to sell to."
        />

        <ControlledTextField
          multiline={true}
          minRows={5}
          name="customer_requirements"
          placeholder="Describe your customer needs, what do you want to solve?"
        />
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Commercial Requirements"
          tooltipText="What is required by you, your team and your business? These requirements typically include, size, functionality and specifically material choice, including CMF."
        />

        <ControlledTextField
          multiline={true}
          minRows={5}
          name="commercial_requirements"
          placeholder="Describe to us your commercial goals. What are you hoping to achieve commercially?"
        />
      </FormFieldSetAndInputWrapper>

      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Technical Considerations"
          tooltipText="Your manufacturing and technical specification needs and considerations. Material preference, production techniques, including details of your sustainable needs. Include here the technical requiremnets you need from the designer; detail drawings, files, renders and views etc.."
        />

        <ControlledTextField
          multiline={true}
          minRows={5}
          name="manufacturing_requirements"
          placeholder="Describe your manufactoring capabilities and any restrictions?"
        />
      </FormFieldSetAndInputWrapper>

      <FormFieldSetAndInputWrapper>
        <CustomFieldSet label="Markets" description="Where your product will be available" />

        <ControlledSelectField
          showBorder={true}
          direction="row"
          name="markets"
          label="Please select all that apply"
          options={NEW_BRIEF_MARKETS}
        />
      </FormFieldSetAndInputWrapper>

      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Projected Retail Price"
          tooltipText="Your estimate of your retail selling price. This helps the designer understand the perception of the product in the retail space."
        />
        <SingleInputResponsiveWrapper>
          <ControlledTextField
            startAdornment={currencyIcon}
            name="projected_retail_price"
            placeholder="Enter your projected retail price"
          />
        </SingleInputResponsiveWrapper>
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet label="Projected Cost Price" tooltipText="Your estimate of manufacturing  cost price." />
        <SingleInputResponsiveWrapper>
          <ControlledTextField
            startAdornment={currencyIcon}
            name="projected_cost_price"
            placeholder="Enter your projected cost price"
          />
        </SingleInputResponsiveWrapper>
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet label="Projected Volume" />
        <SingleInputResponsiveWrapper>
          <ControlledSelectField
            name="projected_volume"
            label="Projected volume"
            options={NEW_BRIEF_PROJECTED_VOLUME_OPTIONS}
            isSingleSelect
          />
        </SingleInputResponsiveWrapper>
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Cover Image"
          description="This image is what designers will see first."
          tooltipTitle="Images"
          tooltipText="Cover Image. This image is what designers will see first. Upload an inspirational or reference image that supports your design brief."
        />

        <ControlledCustomFiledUpload numberOfTiles={1} variant={'single'} name={'main_image'} fileTypes="images" />
      </FormFieldSetAndInputWrapper>

      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Reference File"
          description="Add a file with additional information to support your Brief."
          tooltipText="Please upload a file with additional information to support your brief. Include details, techniques, materials or style details. "
        />

        <ControlledCustomFiledUpload numberOfTiles={1} variant="single" name="reference_file" fileTypes="pdf" />
      </FormFieldSetAndInputWrapper>

      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Reference Images"
          description="Use up to ten images to show reference for your brief"
          tooltipText="Please upload any inspirational images to support your brief. Include details, techniques, materials or style images. "
        />

        <ControlledCustomFiledUploadDND
          numberOfTiles={10}
          variant={'multi'}
          name={'additional_images'}
          fileTypes="images"
        />
      </FormFieldSetAndInputWrapper>

      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Preferred File Requirements"
          description="Choose your preferred file requirements"
          tooltipText="Add a note to the Designer, introduction, have you worked with them before, whats your positions?"
        />

        <ControlledSelectField
          showBorder={true}
          direction="row"
          name="file_requirements"
          label="Please select all that apply"
          options={fileRequirementsList}
        />
      </FormFieldSetAndInputWrapper>
    </div>
  );
};

NewBriefDetailsForm.propTypes = {};

export default NewBriefDetailsForm;
