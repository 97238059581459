import { Box, styled } from '@mui/material';

export const TextFieldWrapper = styled(Box)(({ theme, inline }) => ({
  display: inline ? 'flex' : 'unset',
  marginBottom: inline ? 0 : 'unset',
  '& > div': {
    marginBottom: inline ? 0 : 'unset',
    '&:first-of-type': {
      marginRight: inline ? theme.spacing(1) : '0',
    },
    '&:last-of-type': {
      marginLeft: inline ? theme.spacing(1) : '0',
    },
  },

  '& .MuiFormControl-root': {
    marginTop: 0,
  },
}));
