import { useFormContext } from 'react-hook-form';
import ProductSingle from '../ProductSingle';

const ControlledProductSingle = ({ userType, variant, isStory }) => {
  const { watch } = useFormContext();
  const data = watch();

  return <ProductSingle data={data} userType={userType} variant={variant} isStory={isStory} />;
};

export default ControlledProductSingle;
