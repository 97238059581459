import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import { Box, ImageList } from '@mui/material';
import CustomImageListItem from 'components/CustomImageListItem/CustomImageListItem';
import ModalRetractPitch from 'components/ModalRetractPitch/ModalRetractPitch';
import StatusPill from 'components/StatusPill/StatusPill';

import { routes } from 'options/routes';
import { PILL_STATUS_ICON, PILL_STATUS_MAP, DESIGNER_PITCH_PILL_STATUS_TEXT } from 'options/pillStatusOptions';

import { getImageSrc } from 'utils/getImageSrc';
import { getErrorMessage } from 'utils/getErrorMessage';

import { useGalleryColumns } from 'hooks/useGalleryColumns';

import { useMessageContext } from 'context/MessageContext';

import { retractPitchNegotiation } from 'api';

const PitchBuyerList = ({ pitchBuyersList, pitch_uuid, refetch }) => {
  const navigate = useNavigate();
  const { columns } = useGalleryColumns();
  const [buyerPitchToRetract, setBuyerPitchToRetract] = useState('');
  const [setMessage] = useMessageContext();

  const handleOnRetractPitchNegotiation = useCallback(async () => {
    try {
      const res = await retractPitchNegotiation(pitch_uuid, buyerPitchToRetract);
      if (!res.success) {
        return setMessage(getErrorMessage(res));
      }
      setMessage('You have successfully retracted this negotiation');
    } catch (err) {
      setMessage('There was an error retracting pitch negotiation');
    }
  }, [buyerPitchToRetract, pitch_uuid, setMessage]);

  return (
    <Box>
      <ImageList cols={columns} variant="standard" gap={10}>
        {pitchBuyersList?.map((productBuyerDetails, i) => {
          const {
            company: { uuid, name, logo },
            buyer_product_uuid,
            project_status,
            can_retract,
          } = productBuyerDetails;

          const handleViewNavigation = () =>
            navigate(`${routes.pitchesDashboard}/${pitch_uuid}/${buyer_product_uuid}/pitch`);

          const overlayOptions = {
            title: name,
            description: '',
            menuOptions: [
              {
                title: 'View details',
                onClick: handleViewNavigation,
              },
              { title: 'View profile', onClick: () => navigate(`${routes.buyersIndex}/${uuid}`) },
              ...(can_retract
                ? [
                    {
                      title: 'Retract negotiation',
                      onClick: () => setBuyerPitchToRetract(buyer_product_uuid),
                    },
                  ]
                : []),
            ],
            statusData: [
              {
                label: 'Project Status',
                icon: (
                  <StatusPill
                    label={DESIGNER_PITCH_PILL_STATUS_TEXT[project_status]}
                    status={PILL_STATUS_MAP[project_status]}
                    icon={PILL_STATUS_ICON[project_status]}
                  />
                ),
              },
            ],
          };

          const { fileSrc, name: imageName } = getImageSrc(logo);

          return (
            <CustomImageListItem
              src={fileSrc}
              name={imageName}
              overlayOptions={overlayOptions}
              onClick={handleViewNavigation}
            />
          );
        })}
      </ImageList>
      {!!buyerPitchToRetract && (
        <ModalRetractPitch
          onSubmit={handleOnRetractPitchNegotiation}
          variant="negotiation"
          open={buyerPitchToRetract}
          handleClose={() => setBuyerPitchToRetract(false)}
          callback={() => refetch()}
        />
      )}
    </Box>
  );
};

PitchBuyerList.propTypes = {};

export default PitchBuyerList;
