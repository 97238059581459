import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import ProposalForm from 'components/ProposalForm/ProposalForm';
import ControlledProductSingle from 'components/ProductSingle/ControlledProductSingle/ControlledProductSingle';

import { userTypes } from 'options/userTypes';

export const proposalSteps = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0:
      return stepsOptions.isFetching ? (
        <Box my={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <ProposalForm />
      );
    case 1:
      return <ControlledProductSingle userType={userTypes.designer} variant="proposal_view" />;
    default:
      return <></>;
  }
};
