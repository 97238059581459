import { validation, yupObject } from 'utils/validation';

const buyerProfileValidationSchema = yupObject({
  ...validation.personalFields,
  position: validation.buyerPersonalFields.position,
});

const designerProfileValidationSchema = yupObject({
  portrait: validation.pictureFields.portrait,
  ...validation.personalFields,
});

const personalFields = {
  first_name: '',
  last_name: '',
  date_of_birth: null,
  phone_number: '',
  gender: '',
};

const buyerDefaultValues = {
  ...personalFields,
  position: '',
};

const designerDefaultValues = {
  ...personalFields,
};

export const rolesDefaultValues = {
  buyer_admin: buyerDefaultValues,
  buyer_user: buyerDefaultValues,
  designer: designerDefaultValues,
};

export const schemaValidation = {
  buyer_admin: buyerProfileValidationSchema,
  buyer_user: buyerProfileValidationSchema,
  designer: designerProfileValidationSchema,
};
