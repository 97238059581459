import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import ControlledCustomRadioGroup from 'components/Inputs/CustomRadioStandardGroup/ControlledCustomRadioGroup/ControlledCustomRadioGroup';
import { TERM_OPTIONS, TERM_TYPES } from 'options/briefOptions';
import NewTermsForm from '../NewTermsForm/NewTermsForm';
import { useFormContext } from 'react-hook-form';
import ExistingTermsList from 'components/ExistingTermsList/ExistingTermsList';
import { existingTermsHeading } from 'options/tableOptions';
import FormFieldSetAndInputWrapper from 'components/Wrappers/FormFieldSetAndInputWrapper/FormFieldSetAndInputWrapper';
import { EXISTING_TERMS_LIST_VARIANTS } from 'components/ExistingTermsList/ExistingTermsList.data';
import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';

const NewBriefTermsFormWithController = ({ isStory, variant, isOpenCall }) => {
  const { watch, setValue, formState, clearErrors } = useFormContext();
  const termType = watch('term_type', TERM_TYPES.new_terms);
  const selected_terms_uuid = watch('selected_terms_uuid');
  const isModal = variant === 'modal';

  const handleTermsTableSetSelectedTermsUuid = useCallback(
    (terms_uuid) => {
      setValue('selected_terms_uuid', terms_uuid);
      setValue('agreement_body', '');
      setValue('agreement_file', []);
      setValue('agreement_title', '');
      clearErrors('selected_terms_uuid');
    },
    [clearErrors, setValue]
  );

  const selectedTermsErrorMessage = useMemo(
    () => formState?.errors?.selected_terms_uuid?.message,
    [formState?.errors?.selected_terms_uuid?.message]
  );

  useEffect(() => {
    if (termType === TERM_TYPES.new_terms) {
      setValue('selected_terms_uuid', '');
    }
  }, [setValue, termType]);

  return (
    <Box>
      <FormFieldSetAndInputWrapper mb={0}>
        <CustomFieldSet
          label="Terms and Conditions"
          description={`Add your company's general Terms and Conditions here. Alternatively, save and exit and then create new Terms and Conditions in your Terms tab and then add to your ${isOpenCall ? 'brief' : 'brief'}.`}
          marginTop={isModal ? 2 : 4}
        />
        <ControlledCustomRadioGroup name="term_type" direction="row" options={TERM_OPTIONS} removeError />
      </FormFieldSetAndInputWrapper>
      {termType === TERM_TYPES.new_terms && <NewTermsForm isModal />}
      {termType === TERM_TYPES.existing_terms && (
        <Box mt={2}>
          <ExistingTermsList
            variant={EXISTING_TERMS_LIST_VARIANTS.FORM}
            formAction={(terms_uuid) => handleTermsTableSetSelectedTermsUuid(terms_uuid)}
            isStory={isStory}
            mockTotal={isStory ? 14 : null}
            mockData={isStory ? mockExistingTermsTableData : null}
            headings={existingTermsHeading}
            selected_terms_uuid={selected_terms_uuid}
          />
          {selectedTermsErrorMessage && (
            <ErrorMessage variant="body1" colour="error.main">
              {selectedTermsErrorMessage}
            </ErrorMessage>
          )}
        </Box>
      )}
    </Box>
  );
};

NewBriefTermsFormWithController.propTypes = {
  tableData: PropTypes.object,
};

export default NewBriefTermsFormWithController;

const mockExistingTermsTableData = [
  {
    title: 'Made.com 1',
    created: '01/04/2022',
    last_used: '01/01/2022',
    terms_uuid: '123',
    can_edit: false,
    can_delete: false,
  },
  {
    title: 'Made.com 2',
    created: '02/03/2021',
    last_used: '01/01/2022',
    terms_uuid: '1234',
    can_edit: false,
    can_delete: false,
  },
  {
    title: 'Made.com 3',
    created: '10/05/2018',
    last_used: '01/01/2022',
    terms_uuid: '12345',
  },
  {
    title: 'Made.com 4',
    created: '30/05/2017',
    last_used: '01/01/2022',
    terms_uuid: '123456',
    can_edit: false,
    can_delete: false,
  },
  {
    title: 'Made.com 5',
    created: '22/05/2021',
    last_used: '01/01/2022',
    terms_uuid: '1234567',
  },
];
