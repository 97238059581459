import React from 'react';

import { Box } from '@mui/material';
import TwoFormFieldsRow from 'components/TwoFormFieldsRow/TwoFormFieldsRow';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ControlledPasswordField from 'components/Inputs/TextFields/CustomPasswordField/ControlledPasswordField/ControlledPasswordField';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import CustomTermsLink from 'components/Typography/CustomTermsText/CustomTermsLink/CustomTermsLink';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';


const SignUpAccount = () => {
  return (
    <CustomFieldSet>
      <TwoFormFieldsRow>
        <ControlledTextField name="first_name" label="First Name" showSuccess />
        <ControlledTextField name="last_name" label="Last Name" showSuccess />
      </TwoFormFieldsRow>
      <Box>
        <ControlledTextField name="email" label="Email Address" showSuccess />
        <ControlledPasswordField name="password" label="Password" showSuccess />
        <ControlledPasswordField name="confirm_password" label="Confirm Password" showSuccess />
      </Box>
      <Box mt={2}>
        <BodyText
          text={<>
            Your personal information will be handled in accordance with our{' '}
            <CustomStandardLink
              target='_blank'
              linkType='primary'
              text='Privacy Policy'
              href='https://buydesign.com/privacy-policy'
            />
          </>}
        />
      </Box>
    </CustomFieldSet>
  );
};

export default SignUpAccount;
