import React from 'react';
import PropTypes from 'prop-types';
import { StyledTableBody } from './CustomTableBody.styles';

const CustomTableBody = ({ children }) => {
  return <StyledTableBody>{children}</StyledTableBody>;
};

CustomTableBody.propTypes = {};

export default CustomTableBody;
