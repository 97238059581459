import React from 'react';
import { useOutletContext } from 'react-router';

import BriefSummary from 'components/BriefSummary/BriefSummary';
import { userTypes } from 'options/userTypes';
import { PROJECT_STATUS } from 'options/projectStatus';

export const DesignerBriefDetails = () => {
  const [briefData, setMessage, userType, onAcceptBrief, onDeclineBrief] = useOutletContext();

  const { project_status } = briefData;
  const showAcceptDeclineButtons = project_status === PROJECT_STATUS.PENDING;

  return (
    <BriefSummary
      briefDetails={{ ...briefData }}
      onAcceptBrief={onAcceptBrief}
      onDeclineBrief={onDeclineBrief}
      acceptBtnText="Create Proposal"
      rejectBtnText="Decline Brief"
      userType={userTypes.designer}
      showButtons={showAcceptDeclineButtons}
      filePreviewEnabled={true}
    />
  );
};

export default DesignerBriefDetails;
