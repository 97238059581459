import { Box, IconButton, styled } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';

export const IconButtonStyled = styled(IconButton)(() => ({
  padding: 0,
}));

export const MenuIconStyled = styled(MenuIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const MenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
  },
  '& 	.MuiMenu-list': {
    backgroundColor: theme.palette.primary.main,
  },
}));

export const IconContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  marginTop: -1,
  '& .MuiSvgIcon-root': {
    fill: `white`,
    height: '18px',
    width: '18px',
    marginLeft: 1,
    transition: 'opacity 100ms ease',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));
