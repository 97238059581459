import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import PillTag from 'components/PillTag/PillTag';
import { TooltipChildWrapper, TooltipText } from './PillTagToolTip.styles';

const PillTagToolTip = ({ option, toolTipText = '', removeSelection }) => {
  return (
    <Tooltip
      placement="top"
      id={'custom-pill-tag-tooltip'}
      arrow
      title={toolTipText && <TooltipText fontSize="small">{toolTipText}</TooltipText>}
    >
      <TooltipChildWrapper>
        <PillTag option={option} removeSelection={removeSelection} colour="gray" />
      </TooltipChildWrapper>
    </Tooltip>
  );
};

PillTagToolTip.propTypes = {
  toolTipText: PropTypes.string,
  removeSelection: PropTypes.func,
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
};

export default PillTagToolTip;
