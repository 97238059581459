import { useEffect, useCallback } from 'react';
import { useQueries } from 'react-query';

import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';

import { OPTIONS_TYPE } from '../../../options/selectOptions';

import { getTopOptions } from '../../../api';

const AccountOptionsWrapper = ({ getOptionsStates, children }) => {
  const optionsQueries = useQueries({
    queries: [
      {
        queryKey: ['topCompanyCategories'],
        queryFn: () => getTopOptions(OPTIONS_TYPE.company),
        staleTime: 5 * 60 * 1000,
        retryDelay: 500,
      },
      {
        queryKey: ['topProductCategories'],
        queryFn: () => getTopOptions(OPTIONS_TYPE.product),
        staleTime: 5 * 60 * 1000,
        retryDelay: 500,
      },
      {
        queryKey: ['topSpaceTags'],
        queryFn: () => getTopOptions(OPTIONS_TYPE.space),
        staleTime: 5 * 60 * 1000,
        retryDelay: 500,
      },
    ],
  });

  const isLoading = optionsQueries.some((query) => query.isLoading);

  const parseData = useCallback(
    (queries) => {
      let companyCategories = [],
        productCategories = [],
        spaceTags = [],
        error = '',
        loading = false;

      // Set error and data states
      if (queries.some((query) => !query.data.success)) error = 'There was an error when getting one or more options';
      if (queries[0].data.success && queries[0].data.options) companyCategories = queries[0].data.options;
      if (queries[1].data.success && queries[1].data.options) productCategories = queries[1].data.options;
      if (queries[2].data.success && queries[2].data.options) spaceTags = queries[2].data.options;
      getOptionsStates({ error, loading, companyCategories, productCategories, spaceTags });
    },
    [getOptionsStates]
  );

  useEffect(() => {
    if (!isLoading) {
      parseData(optionsQueries);
    }
  }, [isLoading, parseData, optionsQueries]);

  return (
    <PageContentWrapper
      sx={{
        marginBottom: 2,
        '&  hr': {
          margin: 0,
        },
      }}
    >
      {children}
    </PageContentWrapper>
  );
};

export default AccountOptionsWrapper;
