export const LICENSE_TYPES = {
  royalty: 'royalty',
  one_off: 'one_off',
  both: 'both',
};

export const LICENSE_TYPES_NAMES = {
  royalty: 'Royalty',
  one_off: 'One off',
};

export const TERM_TYPES = {
  new_terms: 'new_terms',
  existing_terms: 'existing_terms',
};

export const TERM_OPTIONS = [
  {
    label: 'Create new terms',
    value: TERM_TYPES.new_terms,
  },
  {
    label: 'Select and edit ones from previous and save as new',
    value: TERM_TYPES.existing_terms,
  },
];
