import { styled, Box } from '@mui/material';
import { MAX_WIDTH, MD_WIDTH, SM_WIDTH } from 'styles/constants';

export const pageContentWrapperStyles = (theme, downMd, downSm, collapse, noTopMargin) => ({
  width: MD_WIDTH,
  maxWidth: MAX_WIDTH,
  alignSelf: 'center',
  transition: 'all 500ms ease-in-out',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    transition: 'height 500ms ease-in-out',
    margin: noTopMargin ? '0px auto' : '16px auto',
    width: SM_WIDTH,
    height: collapse ? '32vh' : '',
    overflowY: collapse ? 'scroll' : '',
    ...(downMd || {}),
  },
  [theme.breakpoints.down('sm')]: {
    ...(downSm || {}),
  },
});

export const PageContentWrapperStyled = styled(Box)(({ theme, sx, breadcrumbsMargin, noTopMargin }) => ({
  margin: breadcrumbsMargin ? '30px auto 72px' : noTopMargin ? '0px auto' : '78px auto 72px',
  ...pageContentWrapperStyles(theme, null, null, null, noTopMargin),
  ...(sx || {}),
}));
