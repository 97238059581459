export const signupPrefix = '/signup';
export const forgotPasswordPrefix = '/forgot-password';
export const resetPasswordPrefix = '/reset-password';
export const acceptInvitationPrefix = '/accept-invitation';
export const dashboardPrefix = '/dashboard';

export const routes = {
  home: '/',
  login: '/login/',
  signup: '/register/',
  signupDesigner: `${signupPrefix}/designer`,
  signupBuyer: `${signupPrefix}/buyer`,
  signupTokenVerify: `${signupPrefix}/verify`,
  signupSuccess: `${signupPrefix}/success/`,
  forgotPassword: `${forgotPasswordPrefix}/`,
  forgotPasswordSuccess: `${forgotPasswordPrefix}/success/`,
  resetPassword: `${resetPasswordPrefix}`,
  resetPasswordSuccess: `${resetPasswordPrefix}/success/`,
  acceptInvitation: `${acceptInvitationPrefix}`,
  acceptInvitationSuccess: `${acceptInvitationPrefix}/success/`,
  profile: '/profile/',
  account: '/account/',
  dashboard: `${dashboardPrefix}/`,
  productsDashboard: `${dashboardPrefix}/products`,
  productsAdd: `${dashboardPrefix}/products/add`,
  productsAddThanks: `${dashboardPrefix}/products/add/thanks`,
  briefsDashboard: `${dashboardPrefix}/briefs`,
  briefsAdd: `${dashboardPrefix}/briefs/add`,
  briefThanks: `${dashboardPrefix}/briefs/add/thanks`,
  proposalAdd: `${dashboardPrefix}/briefs/:brief_uuid/proposal/add`,
  proposalAddThanks: `${dashboardPrefix}/briefs/:brief_uuid/proposal/add/thanks`,
  logout: '/logout/',
  termsDashboard: `${dashboardPrefix}/terms`,
  termsAdd: `${dashboardPrefix}/terms/add`,
  termsThanks: `${dashboardPrefix}/terms/add/thanks`,
  products: '/products',
  product: '/product',
  productsCategorySearch: '/products/category-search',
  moodboardsDashboard: `${dashboardPrefix}/moodboards`,
  moodboard: '/moodboard',
  designersIndex: '/designers',
  buyerDesignersDashboard: `${dashboardPrefix}/designers`,
  productConversionThanks: `${dashboardPrefix}/products/add/conversion/thanks`,
  help: '/help',
  openCallsDashboard: `${dashboardPrefix}/open-calls`,
  openCallsDashboardAdd: `${dashboardPrefix}/open-calls/add`,
  openCallsDashboardAddThanks: `${dashboardPrefix}/open-calls/add/thanks`,
  openCallsProposalAdd: `${dashboardPrefix}/open-calls/:brief_uuid/proposal/add`,
  openCallsProposalAddThanks: `${dashboardPrefix}/open-calls/:brief_uuid/proposal/add/thanks`,
  pitchesDashboard: `${dashboardPrefix}/pitches`,
  pitchesAdd: `${dashboardPrefix}/pitches/add`,
  pitchesAddThanks: `${dashboardPrefix}/pitches/add/thanks`,
  pitchesConversionAdd: `${dashboardPrefix}/pitches/add/conversion`,
  pitchesConversionAddThanks: `${dashboardPrefix}/pitches/add/conversion/thanks`,
  stripeRefresh: '/stripe-refresh',
  buyersIndex: '/buyers',
};

export const wpBaseUrl = process.env.REACT_APP_WP_BASE_URL;

export const wpRoutes = {
  about: `${wpBaseUrl}about`,
  contact: `${wpBaseUrl}contact`,
  faqs: `${wpBaseUrl}faqs`,
  features: `${wpBaseUrl}features`,
  legal: `${wpBaseUrl}legal2`,
  // pricing: `${wpBaseUrl}pricing`,
  pricing: `${wpBaseUrl}pricing_updated_a`,
  stories: `${wpBaseUrl}stories`,
  team: `${wpBaseUrl}team`,
  termsAndConditions: `${wpBaseUrl}tcs`,
  termsAndConditionsBuyer: `${wpBaseUrl}buyer-terms-and-conditions`,
  termsAndConditionsDesigner: `${wpBaseUrl}designer-terms-and-conditions`,
  resources: `${wpBaseUrl}resources`,
  resourcesImageGuidelines: `${wpBaseUrl}resources-image-guidelines`,
  privacyPolicy: `${wpBaseUrl}privacy-policy`,
  discoverDesigners: `${wpBaseUrl}discover-designers`,
  discoverBuyers: `${wpBaseUrl}discover-buyers`,
  unsupportedPayments: `${wpBaseUrl}resources-payments`,
  businesses: `${wpBaseUrl}businesses`,
  designers: `${wpBaseUrl}designers`,
};

export const socialRoutes = {
  instagram: `https://www.instagram.com/buydesign.original`,
  linkedIn: `https://www.linkedin.com/company/connectwithbuydesign`,
};

export const authenticatedRoutes = [
  routes.home,
  routes.profile,
  routes.account,
  routes.dashboard,
  routes.productsDashboard,
  routes.productsAdd,
  routes.productsAddThanks,
  routes.briefsDashboard,
  routes.briefsAdd,
  routes.briefThanks,
  routes.proposalAdd,
  routes.proposalAddThanks,
  routes.termsDashboard,
  routes.termsAdd,
  routes.termsThanks,
  routes.logout,
  routes.products,
  routes.product,
  routes.moodboardsDashboard,
  routes.designersIndex,
  routes.buyerDesignersDashboard,
  routes.productConversionThanks,
  routes.help,
  routes.pitchesDashboard,
  routes.stripeRefresh,
  routes.buyersIndex,
];

export const nonAuthenticatedRoutes = [
  routes.login,
  routes.signup,
  routes.signupDesigner,
  routes.signupBuyer,
  routes.signupTokenVerify,
  routes.signupSuccess,
  routes.forgotPassword,
  routes.forgotPasswordSuccess,
  routes.resetPassword,
  routes.resetPasswordSuccess,
  routes.acceptInvitation,
  routes.acceptInvitationSuccess,
];
