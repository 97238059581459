import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

const CustomTitle = ({ text, variant, align, colour = 'primary', noWrap }) => {
  return (
    <Typography variant={variant} align={align} color={colour} noWrap={noWrap}>
      {text}
    </Typography>
  );
};

CustomTitle.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  align: PropTypes.oneOf(['center', 'justify', 'left', 'right']),
  colour: PropTypes.oneOf(['primary', 'secondary']),
};

export default CustomTitle;
