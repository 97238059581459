import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomSearchField from 'components/CustomSearchField/CustomSearchField';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';

import {
  MenuWrapper,
  OptionWrapper,
  SearchAndFilterWrapper,
  SortArrowIconButton,
  StyledFilterContainer,
  TextWrapper,
} from './SearchAndFilterOptions.styles';

const SearchAndFilterOptions = ({
  title,
  setSearchTerm,
  searchTerm,
  placeholder,
  menuOptions,
  hasBorder,
  onRequestSort,
  order,
  orderBy,
  resetFilter,
  hasFilter,
  isModal,
}) => {
  const createSortHandler = (e, id) => {
    if (onRequestSort) {
      let newOrder;
      if (order === 'asc') newOrder = 'desc';
      if (order === 'desc' || !order) newOrder = 'asc';
      onRequestSort(e, id, newOrder);
    }
  };

  const resetFilterButton = (
    <CustomIconButton onClick={resetFilter} icon={<RefreshIcon fontSize="small" />} text="Reset" />
  );

  const resetBtnFlexEndProps = setSearchTerm
    ? {
        justifyContent: 'flex-end',
        paddingRight: 2,
      }
    : {};

  return (
    <SearchAndFilterWrapper display="flex" flexWrap="wrap" border={hasBorder}>
      {setSearchTerm && (
        <CustomSearchField setSearchTerm={setSearchTerm} searchTerm={searchTerm} placeholder={placeholder} />
      )}
      {title ? (
        <Box flex={1} display="flex" alignItems="center">
          <Box mr={2}>
            <BodyText text={title} fontSize="medium" bold />
          </Box>
          {hasFilter && resetFilterButton}
        </Box>
      ) : hasFilter ? (
        <Box flex={1} display="flex" alignItems="center" {...resetBtnFlexEndProps}>
          {resetFilterButton}
        </Box>
      ) : (
        ''
      )}
      <StyledFilterContainer>
        {menuOptions?.map(({ actions, menuOptions, label, onClick, sortable, id, selected }, i) => {
          if (id === 'open_calls') {
            return (
              <Box sx={{ cursor: 'pointer' }} mr={2} onClick={onClick} key={label} display="flex" alignItems="center">
                <Box mr={2}>
                  <BodyText text={label} />
                </Box>
                {selected ? <CheckBoxIcon fontSize="small" /> : <CheckBoxOutlineBlankIcon fontSize="small" />}
              </Box>
            );
          }
          if (!sortable) {
            const threeDotActions = actions || menuOptions;
            return (
              <OptionWrapper display="flex" alignItems="center" key={label} onClick={onClick}>
                <TextWrapper click={!!onClick}>
                  <BodyText text={label} />
                </TextWrapper>
                <MenuWrapper display="flex" justifyContent="center" isModal={isModal}>
                  {!!(threeDotActions && threeDotActions.length) && !onClick && (
                    <ThreeDotMenu hoverPeachBg={true} variant="arrow" actions={threeDotActions} />
                  )}
                </MenuWrapper>
              </OptionWrapper>
            );
          } else
            return (
              <Box key={label} display="flex" alignItems="center">
                <BodyText text={label} />
                <SortArrowIconButton
                  ordered={orderBy === id}
                  direction={orderBy === id ? order : 'asc'}
                  onClick={(e) => createSortHandler(e, id)}
                >
                  <ArrowUpwardIcon />
                </SortArrowIconButton>
              </Box>
            );
        })}
      </StyledFilterContainer>
    </SearchAndFilterWrapper>
  );
};

SearchAndFilterOptions.propTypes = {
  title: PropTypes.string,
  getSearchTerm: PropTypes.func,
  placeholder: PropTypes.string,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string,
      actions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          onClick: PropTypes.func,
          selected: PropTypes.bool,
        })
      ),
    })
  ),
};

export default SearchAndFilterOptions;
