import React from 'react';
import PropTypes from 'prop-types';
import { StyledTableRow } from './CustomTableRow.styles';

const CustomTableRow = ({ styledOverride, noHover, children, isSelected, onClick, onHover }) => {
  return (
    <StyledTableRow
      styledOverride={styledOverride || {}}
      noHover={noHover}
      isSelected={isSelected}
      onClick={onClick}
      onMouseEnter={() => (onHover ? onHover(true) : undefined)}
      onMouseLeave={() => (onHover ? onHover(false) : undefined)}
    >
      {children}
    </StyledTableRow>
  );
};

CustomTableRow.propTypes = {
  isSelected: PropTypes.bool,
};

export default CustomTableRow;
