import { styled, Link, Box } from '@mui/material';

export const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecorationColor: theme.palette.common.white,
  cursor: 'pointer',
  fontSize: '14px',
  textUnderlineOffset: '5px'
}));

export const LinkContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
  },
}));

export const IconContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  cursor: 'pointer',
  '& .MuiSvgIcon-root': {
    fill: `white`,
    height: '18px',
    width: '18px',
    marginLeft: 2,
    transition: 'opacity 100ms ease',
    '&:hover': {
      opacity: 0.8,
    },
  },
}));
