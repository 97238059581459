import { Box, styled } from '@mui/material';

export const StyledHelpTabContainer = styled(Box)(({ theme, isSelected }) => ({
  backgroundColor: !isSelected ? '' : theme.palette.common.peach,
  color: theme.palette.text.primary,
  padding: theme.spacing(1),
  cursor: 'pointer',
  opacity: !isSelected ? 0.45 : 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: `0.5px solid ${theme.palette.common.black}`,
  borderBottom: `0.5px solid ${theme.palette.common.black}`,
  '&:last-of-type': {
    borderBottom: `1px solid ${theme.palette.common.black}`,
  },
}));

export const StyledHelpTabLabelDateBox = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
