import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import NewTermsThanks from 'components/NewTermsThanks/NewTermsThanks';

import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';

const BuyerTermsThanks = ({ userType }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (userType && userType !== userTypes.buyerUser && userType !== userTypes.buyerAdmin) navigate(routes.home);
  }, [userType, navigate]);

  return (
    <BuyerDashboardWrapper
      activeTab={routes.termsAdd}
      breadcrumbs={
        <Breadcrumbs
          links={[
            { label: 'Terms', onClick: () => navigate(routes.termsDashboard) },
            { label: 'New Template', onClick: () => navigate(routes.termsAdd) },
          ]}
          currentPage={'Thanks'}
        />
      }
    >
      <NewTermsThanks />
    </BuyerDashboardWrapper>
  );
};

export default BuyerTermsThanks;
