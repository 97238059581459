import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { yupObject, validation } from 'utils/validation';

import { deleteMoodboard } from 'api';

const validationSchema = yupObject({
  ...validation.deleteProduct,
});

const ModalDeleteMoodboard = ({ open, handleClose, moodboard_uuid, setMessage }) => {
  const [isLoading, setIsLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    defaultValues: {
      delete: '',
    },
    resolver,
  });

  useEffect(() => {
    if (!open) {
      methods.reset({ delete: '' });
    }
  }, [methods, open]);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    const res = await deleteMoodboard(moodboard_uuid);
    setIsLoading(false);
    if (!res.success) {
      setMessage('There was an error deleting moodboard.');
      return handleClose(false);
    }
    setMessage('Moodboard deleted with success.');
    handleClose(true);
  }, [handleClose, moodboard_uuid, setMessage]);

  return (

    <CustomModal title="Delete moodboard" open={open} handleClose={() => handleClose(false)}>
      <StyledModalContentWrapper>
        {isLoading ? (
          <Box mt={2}>
            <Spinner align="center" />
          </Box>
        ) : (
          <Box mt={1}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Box mb={2}>
                  <BodyText
                    text="Sure you want to delete this moodboard? Once confirmed, this action cannot be undone."
                    colour="gray"
                  />
                </Box>
                <Box>
                  <BodyText text="Type 'delete' in the field below to confirm:" />
                </Box>
                <ControlledTextField placeholder="delete" name="delete" />
                <StyledActionButtonsContainer>
                  <CustomButton text="Cancel" onClick={() => handleClose(false)} variant="outlined" fullWidth />
                  <CustomButton text="Remove" buttonType="submit" fullWidth />
                </StyledActionButtonsContainer>
              </form>
            </FormProvider>
          </Box>
        )}
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

ModalDeleteMoodboard.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  moodboard_uuid: PropTypes.string,
};

export default ModalDeleteMoodboard;
