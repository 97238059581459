import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import NavBarLogo from '../components/NavBarLogo/NavBarLogo';
import NavBarLinkList from '../components/NavBarLinkList/NavBarLinkList';

import { NavBarStyled, NavBarWrapperStyled } from '../NavBar.styles';
import SearchResults from 'components/SearchResults/SearchResults';

import { ProductsMegaMenuWrapper, SearchResultsSearchBarContainer } from './NavBarBuyer.styles';
import { mockData } from './NavBarBuyer.mockData';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ProductsMegaMenu from 'components/ProductsMegaMenu/ProductsMegaMenu';
import { useLocation, useNavigate } from 'react-router';

export const NavBarBuyerContext = React.createContext();

const NavBarBuyer = ({ isStory }) => {
  const [showSearch, setShowSearch] = useState(false);
  const [showMegaMenu, setShowMegaMenu] = useState(false);
  const wrapperRef = useRef(null);

  const navigate = useNavigate();
  const handleSearchClick = () => {
    if (showMegaMenu) setShowMegaMenu(false);
    setShowSearch((prev) => !prev);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setTimeout(() => {
          if (showSearch) setShowSearch(false);
        }, 100);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    if (!showSearch) document.removeEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, showSearch]);

  const handleMegaMenuClick = () => {
    if (showSearch) setShowSearch(false);
    setShowMegaMenu((prev) => !prev);
  };

  const navBarLinksIconsAndFns = [
    {
      icon: <ExpandMoreIcon />,
      linkText: 'Products',
      onClick: () => handleMegaMenuClick(),
    },
  ];

  const handleProductsMegaMenuNavigation = (titleSlug, optionSlug = null) => {
    if (!titleSlug && !optionSlug) console.error('no slugs passed');
    if (!optionSlug) {
      navigate(`/products/${titleSlug}`);
    } else {
      navigate(`/products/${titleSlug}/${optionSlug}`);
    }
    setShowMegaMenu(false);
    window.scroll(0, 0);
  };

  const NavBarBuyerContextValue = {
    showSearch,
    showMegaMenu,
    handleMegaMenuClick,
    handleSearchClick,
    navBarLinksIconsAndFns,
    handleProductsMegaMenuNavigation,
  };

  const location = useLocation();
  const isDashboard = useMemo(() => location.pathname.split('/').includes('dashboard'), [location.pathname]);

  return (
    <NavBarBuyerContext.Provider value={NavBarBuyerContextValue}>
      <NavBarStyled>
        <SearchResultsSearchBarContainer ref={wrapperRef} isDashboard={isDashboard} showSearch={showSearch}>
          <SearchResults mockData={mockData} />
        </SearchResultsSearchBarContainer>
        <ProductsMegaMenuWrapper isDashboard={isDashboard} showMegaMenu={showMegaMenu}>
          <ProductsMegaMenu showMegaMenu={showMegaMenu} />
        </ProductsMegaMenuWrapper>

        <NavBarWrapperStyled>
          <NavBarLogo />
          <NavBarLinkList
            handleSearchClick={handleSearchClick}
            type="buyer"
            handleMegaMenuClick={handleMegaMenuClick}
            isStory={isStory}
          />
        </NavBarWrapperStyled>
      </NavBarStyled>
    </NavBarBuyerContext.Provider>
  );
};

NavBarBuyer.propTypes = {
  count: PropTypes.number,
};

export default NavBarBuyer;
