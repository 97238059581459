import ThreeDMSVG from 'assets/svgs/fileTypes/file-type-3dm.svg';
import pdfSVG from 'assets/svgs/fileTypes/file-type-pdf.svg';
import indSVG from 'assets/svgs/fileTypes/file-type-ind.svg';
import aiSVG from 'assets/svgs/fileTypes/file-type-ai.svg';
import dxfSVG from 'assets/svgs/fileTypes/file-type-dxf.svg';
import psdSVG from 'assets/svgs/fileTypes/file-type-psd.svg';
import tiffSVG from 'assets/svgs/fileTypes/file-type-tiff.svg';
import dwgSVG from 'assets/svgs/fileTypes/file-type-dwg.svg';
import sldprtSVG from 'assets/svgs/fileTypes/file-type-sldprt.svg';
import skpSVG from 'assets/svgs/fileTypes/file-type-skp.svg';
import igesSVG from 'assets/svgs/fileTypes/file-type-iges.svg';
import ThreeDSSVG from 'assets/svgs/fileTypes/file-type-3DS.svg';
import acisSVG from 'assets/svgs/fileTypes/file-type-acis.svg';
import fbxSVG from 'assets/svgs/fileTypes/file-type-fbx.svg';
import objSVG from 'assets/svgs/fileTypes/file-type-obj.svg';
import prtSVG from 'assets/svgs/fileTypes/file-type-prt.svg';
import sldasmSVG from 'assets/svgs/fileTypes/file-type-sldasm.svg';
import stepSVG from 'assets/svgs/fileTypes/file-type-step.svg';
import stlSVG from 'assets/svgs/fileTypes/file-type-stl.svg';
import pptSVG from 'assets/svgs/fileTypes/file-type-ppt.svg';
import jpgSVG from 'assets/svgs/fileTypes/file-type-jpg.svg';

export const fileTypes = [
  '.ai',
  '.ind',
  '.psd',
  '.tiff',
  '.pdf',
  '.3dm',
  '.3DS',
  '.acis',
  '.dwg',
  '.dxf',
  '.FBX',
  '.iges',
  '.obj',
  '.prt',
  '.SKP',
  '.sldasm',
  '.sldprt',
  '.step',
  '.stl',
  '.ppt',
];

export const fileTypesThumbnails = {
  '.ai': aiSVG,
  '.ind': indSVG,
  '.psd': psdSVG,
  '.tiff': tiffSVG,
  '.pdf': pdfSVG,
  '.3dm': ThreeDMSVG,
  '.3ds': ThreeDSSVG,
  '.acis': acisSVG,
  '.dwg': dwgSVG,
  '.dxf': dxfSVG,
  '.FBX': fbxSVG,
  '.iges': igesSVG,
  '.obj': objSVG,
  '.prt': prtSVG,
  '.skp': skpSVG,
  '.sldasm': sldasmSVG,
  '.sldprt': sldprtSVG,
  '.step': stepSVG,
  '.stp': stepSVG,
  '.stl': stlSVG,
  '.ppt': pptSVG,
  '.jpg': jpgSVG,
  '.jpeg': jpgSVG,
};
