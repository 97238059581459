import React from 'react';
import PropTypes from 'prop-types';

import { TextFieldWrapper } from './ModalCreateMoodboard.styles';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';

const CreateModalFields = ({ shouldUnregister, inline }) => {
  return (
    <TextFieldWrapper mb={4} inline={inline}>
      <CustomFieldSet label={inline ? 'Name' : 'Moodboard name'}>
        <ControlledTextField name="name" placeholder="Name" shouldUnregister={shouldUnregister} defaultValue="" />
      </CustomFieldSet>
      <CustomFieldSet
        description={inline ? '' : 'Tell your team what this moodboard is about'}
        label={inline ? 'Description' : 'Moodboard Description'}
      >
        <ControlledTextField
          name="description"
          placeholder="Description"
          shouldUnregister={shouldUnregister}
          defaultValue=""
          multiline={true}
          minRows={5}
        />
      </CustomFieldSet>
    </TextFieldWrapper>
  );
};

CreateModalFields.propTypes = {};

export default CreateModalFields;
