import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FormFieldSetAndInputWrapper from 'components/Wrappers/FormFieldSetAndInputWrapper/FormFieldSetAndInputWrapper';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import SingleInputResponsiveWrapper from 'components/Wrappers/SingleInputResponsiveWrapper/SingleInputResponsiveWrapper';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ControlledCustomRadioGroupWithTextField from 'components/Inputs/ControlledCustomRadioGroupWithTextField/ControlledCustomRadioGroupWithTextField';
import { NEW_BRIEF_BUDGET_OPTIONS } from '../NewBriefOneTImeFeeForm/NewBriefOneTimeFeeForm.data';
import ControlledDatePicker from 'components/Inputs/CustomDatePicker/ControlledDatePicker/ControlledDatePicker';
import RadioWithTextFieldWrapper from 'components/Wrappers/RadioWithTextFieldWrapper/RadioWithTextFieldWrapper';
import ControlledCustomRadioGroup from 'components/Inputs/CustomRadioStandardGroup/ControlledCustomRadioGroup/ControlledCustomRadioGroup';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';

import { LICENSE_OPTIONS, UPFRONT_FEE_OPTIONS } from 'options/agreementFormOptions';
import { LICENSE_TYPES } from 'options/briefOptions';
import { CURRENCY_MAP } from 'options/currency';

import { validation, yupObject } from 'utils/validation';

import { ROYALTY_REVIEW_PERIODS } from '../NewBriefRoyaltyLicenseForm/NewBriefRoyaltyLicenseForm.data';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

const commonDefaultValues = {
  negotiated_agreement_type: LICENSE_TYPES.royalty,
  negotiated_royalty_percentage: '',
  negotiated_royalty_review_period: '',
  negotiated_payment_terms: '',
  negotiated_one_off_budget: 0,
};

const briefsDefaultValues = {
  ...commonDefaultValues,
  negotiated_deadline: null,
  negotiated_upfront_payment: '',
  negotiated_upfront_payment_amount: '',
};

const commonValidation = yupObject({
  ...validation.agreementReviewCommon,
});

const briefsValidation = yupObject({
  ...validation.agreementReviewCommon,
  ...validation.agreementReviewBriefs,
});

const AgreementReviewForm = ({
  variant,
  isModal = true,
  handleSubmit,
  handleClose,
  editValues,
  agreement_type,
  currency,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isBriefsVariant = variant === 'briefs';
  const defaultValues = isBriefsVariant ? briefsDefaultValues : commonDefaultValues;

  const validationSchema = isBriefsVariant ? briefsValidation : commonValidation;

  const {
    negotiated_one_off_budget,
    negotiated_royalty_percentage,
    negotiated_royalty_review_period,
    negotiated_upfront_payment,
    negotiated_deadline,
  } = editValues;

  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    defaultValues:
      editValues && !!Object.keys(editValues).length
        ? {
            ...editValues,
            ...(negotiated_one_off_budget
              ? { negotiated_one_off_budget: String(editValues.negotiated_one_off_budget) }
              : {}),
            ...(negotiated_deadline ? { negotiated_deadline: new Date(negotiated_deadline) } : {}),
            negotiated_upfront_payment: negotiated_upfront_payment ? 'yes' : 'no',
          }
        : defaultValues,
    resolver,
  });

  const { watch, unregister, register, clearErrors } = methods;

  const upfrontPayment = watch('negotiated_upfront_payment', 'yes');
  const agreementType = watch('negotiated_agreement_type');

  const currencyIcon = currency ? CURRENCY_MAP[currency] : '£';

  useEffect(() => {
    clearErrors();
    switch (agreementType) {
      case LICENSE_TYPES.royalty:
        unregister('negotiated_one_off_budget');
        register('negotiated_royalty_percentage', negotiated_royalty_percentage ? negotiated_royalty_percentage : '');
        register(
          'negotiated_royalty_review_period',
          negotiated_royalty_review_period ? negotiated_royalty_review_period : ''
        );
        break;
      case LICENSE_TYPES.one_off:
        register('negotiated_one_off_budget', negotiated_one_off_budget ? negotiated_one_off_budget : '');
        unregister(['negotiated_royalty_percentage', 'negotiated_royalty_review_period']);
        break;
      default:
        break;
    }
  }, [
    agreementType,
    clearErrors,
    negotiated_one_off_budget,
    negotiated_royalty_percentage,
    negotiated_royalty_review_period,
    register,
    unregister,
  ]);

  useEffect(() => {
    if (variant === 'briefs') {
      if (upfrontPayment === 'no') unregister('negotiated_upfront_payment_amount');
      if (upfrontPayment === 'yes') register('negotiated_upfront_payment_amount');
    }
  }, [register, unregister, upfrontPayment, variant]);

  const onHandleSubmit = async (data) => {
    setIsLoading(true);
    await handleSubmit(data);
    setIsLoading(false);
  };

  return (
    <Box>
      {isLoading ? (
        <Box my={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onHandleSubmit)}>
            <FormFieldSetAndInputWrapper mb={2}>
              <CustomFieldSet
                label="What type of agreement do you need?"
                description="Add your company's general Terms and Conditions in the next step"
                marginTop={2}
              />
              <ControlledCustomRadioGroup
                name="negotiated_agreement_type"
                direction="row"
                options={LICENSE_OPTIONS.map((option) => {
                  if (variant === 'negotiation' && option.value !== agreement_type) {
                    return { ...option, disabled: true };
                  }
                  return option;
                })}
                removeError
              />
            </FormFieldSetAndInputWrapper>
            {agreementType === LICENSE_TYPES.royalty ? (
              <>
                <FormFieldSetAndInputWrapper mb={2}>
                  <CustomFieldSet
                    label="Royalty license percentage"
                    description="A percentage of the net sales price of each licensed product sold and delivered"
                    tooltipText="Please enter the % you offer the designer. Please stipulate in your T&Cs if this % is based on wholesale or Retail sales."
                  />
                  <SingleInputResponsiveWrapper isModal>
                    <ControlledTextField
                      placeholder="0.00"
                      startAdornment={'%'}
                      name="negotiated_royalty_percentage"
                      defaultValue=""
                    />
                  </SingleInputResponsiveWrapper>
                </FormFieldSetAndInputWrapper>
                <FormFieldSetAndInputWrapper mb={2}>
                  <CustomFieldSet
                    label="Royalty review period"
                    description="How frequently do you review sales and pay out royalties?"
                  />
                  <ControlledCustomRadioGroupWithTextField
                    radioOptions={ROYALTY_REVIEW_PERIODS}
                    name="negotiated_royalty_review_period"
                    inputPlaceholder={'Specify other review period'}
                    isModal={isModal}
                  />
                </FormFieldSetAndInputWrapper>
              </>
            ) : (
              <FormFieldSetAndInputWrapper mb={2}>
                <CustomFieldSet
                  label={isBriefsVariant ? 'Budget' : 'Price'}
                  // tooltipText={'The final price you pay will include 15% application fee'}
                  description={
                    isBriefsVariant
                      ? 'If you wish to pay the designer a one-time fee, please indicate the amount this can be negotiated later. As per T&C, a one-time fee is subject to an additional BuyDesign 15% commission.'
                      : 'Please indicate a price for your product'
                  }
                />

                <ControlledCustomRadioGroupWithTextField
                  radioOptions={
                    isBriefsVariant
                      ? NEW_BRIEF_BUDGET_OPTIONS.map(({ label, value }) => ({
                          label: `${currencyIcon}${label}`,
                          value,
                        }))
                      : []
                  }
                  name="negotiated_one_off_budget"
                  inputPlaceholder={isBriefsVariant ? 'Specify other budget' : 'Specify your price'}
                  inputStartAdornment={currencyIcon}
                  isModal={isModal}
                />
              </FormFieldSetAndInputWrapper>
            )}
            <FormFieldSetAndInputWrapper mb={2}>
              <CustomFieldSet label="Additional Information" description="Please supply any additional information" />
              <ControlledTextField
                multiline={true}
                minRows={4}
                placeholder={'Any additional information'}
                name="negotiated_payment_terms"
              />
            </FormFieldSetAndInputWrapper>
            {variant === 'briefs' && (
              <Box>
                <FormFieldSetAndInputWrapper mb={2}>
                  <CustomFieldSet
                    label="Deadline"
                    description="Please choose a deadline date for the acceptance of the brief"
                  />
                  <SingleInputResponsiveWrapper isModal>
                    <ControlledDatePicker name="negotiated_deadline" />
                  </SingleInputResponsiveWrapper>
                </FormFieldSetAndInputWrapper>
                <FormFieldSetAndInputWrapper mb={2}>
                  <CustomFieldSet
                    label="Before work starts will you offer an up front payment?"
                    description="Some designers will request a deposit to start the work. The fee is deduced from their first Royalty Payment"
                    tooltipParagrahs={[
                      'The advanced payment to start the work for a one-off payment will be deducted from your final budget amount.',
                      'As per T&C, a one-time fee is subject to an additional BuyDesign 15% commission.',
                    ]}
                  />
                  <RadioWithTextFieldWrapper isModal>
                    <Box mr={2}>
                      <ControlledCustomRadioGroup
                        direction="row"
                        name="negotiated_upfront_payment"
                        options={UPFRONT_FEE_OPTIONS}
                      />
                    </Box>
                    {upfrontPayment === 'yes' && (
                      <SingleInputResponsiveWrapper isModal>
                        <ControlledTextField
                          placeholder="Enter your upfront fee"
                          startAdornment={currencyIcon}
                          name="negotiated_upfront_payment_amount"
                        />
                      </SingleInputResponsiveWrapper>
                    )}
                  </RadioWithTextFieldWrapper>
                </FormFieldSetAndInputWrapper>
              </Box>
            )}
            {isModal && (
              <Box display="flex" justifyContent="space-between" mt={4}>
                <Box width="48%">
                  <CustomButton text="Cancel" onClick={handleClose} variant="outlined" fullWidth />
                </Box>
                <Box width="48%">
                  <CustomButton text="Submit" buttonType="submit" fullWidth />
                </Box>
              </Box>
            )}
          </form>
        </FormProvider>
      )}
    </Box>
  );
};

AgreementReviewForm.propTypes = {
  variant: PropTypes.oneOf(['proposal', 'briefs', 'negotiation']),
};

export default AgreementReviewForm;
