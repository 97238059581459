import NewTermsForm from 'components/Forms/NewTermsForm/NewTermsForm';

export const termsSteps = (activeStep, isStory) => {
  switch (activeStep) {
    case 0: {
      return <NewTermsForm isStory={isStory} />;
    }
    default: {
      return <></>;
    }
  }
};
