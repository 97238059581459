import { styled, Table } from '@mui/material';

export const TableStyled = styled(Table)(() => ({
  minWidth: '400px',
  overflowX: 'scroll',
  '& thead tr th div': {
    display: 'flex',
    alignItems: 'center',
  },
  '& td div div': {
    flex: 'unset',
  },
}));
