export const accountTypes = {
  solo: 'solo',
  business: 'business',
  enterprise: 'enterprise',
};

export const subPaymentTypes = {
  annual: 'annual',
  monthly: 'monthly',
};
