import styled from '@emotion/styled';
import { FormControlLabel } from '@mui/material';

export const FormControlLabelStyled = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'showBorder',
})(({ theme, showBorder }) => ({
  border: showBorder ? `1px solid ${theme.palette.common.black}` : '',
  borderRadius: showBorder ? '100px' : '',
  marginRight: theme.spacing(2),
  marginBottom: theme.spacing(2),
  padding: showBorder ? '' : `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} 0`,
  '&:last-child': {
    marginRight: 0,
  },
  '& .MuiFormGroup-row': {
    margin: 0,
  },
  '&.Mui-disabled': {
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.secondary,
    },
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));
