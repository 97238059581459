import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import React from 'react';

import { TopBarContainer } from './TopBarTitleStatus.styles';

const TopBarTitleStatus = ({ name, project_status }) => {
  return (
    <TopBarContainer>
      <CustomTitle variant="h3" text={name || 'Company Title'} />
    </TopBarContainer>
  );
};

TopBarTitleStatus.propTypes = {};

export default TopBarTitleStatus;
