import React from 'react';
import PropTypes from 'prop-types';

import CustomModal from 'components/CustomModal/CustomModal';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';
import theme from 'theme/theme';

const ModalCongratulations = ({ title, text, open, handleClose, action }) => {
  const handleContinueClicked = () => {
    handleClose();
    action();
  };

  return (
    <CustomModal text={text} handleClose={handleClose} open={open} title={title}>
      <StyledModalContentWrapper>
        <CustomTitle text="Congratulations!" />
        <BodyText colour={theme.palette.grey[500]} text={text} />
        <StyledActionButtonsContainer>
          <CustomButton text="Continue" onClick={handleContinueClicked} />
        </StyledActionButtonsContainer>
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

ModalCongratulations.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

export default ModalCongratulations;
