import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomDivider from 'components/Divider/CustomDivider';
import FormActionButton from 'components/Buttons/FormActionButton/FormActionButton';
import FormParagraph from 'components/Typography/FormParagraph/FormParagraph';
import FieldLabel from 'components/Typography/FieldLabel/FieldLabel';
import Spinner from '../../Spinner/Spinner';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';

const AccountFormContainer = ({
  titleText,
  subtitleText,
  descriptionText,
  children,
  onClick,
  buttonText,
  showDivider,
  loading,
  tooltipTitle,
  tooltipText,
  tooltipParagrahs,
}) => {
  return (
    <Box flex={1}>
      <Box mb={2}>
        <CustomFieldSet
          label={titleText}
          titleFontSize={24}
          bold={false}
          tooltipTitle={tooltipTitle}
          tooltipText={tooltipText}
          tooltipParagrahs={tooltipParagrahs}
        />
        {subtitleText && (
          <Box mt={2}>
            <FieldLabel text={subtitleText} />
          </Box>
        )}
        {descriptionText && (
          <Box my={1}>
            <FormParagraph text={descriptionText} />
          </Box>
        )}
        {showDivider && <CustomDivider orientation="horizontal" />}
      </Box>
      {children}
      {buttonText && onClick && (
        <Box display="flex" justifyContent="flex-end" mt={1}>
          {loading && <Spinner />}
          {!loading && <FormActionButton text={buttonText} onClick={onClick} type="submit" variant="outlined" />}
        </Box>
      )}
    </Box>
  );
};

AccountFormContainer.propTypes = {
  titleText: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  descriptionText: PropTypes.string,
  loading: PropTypes.bool,
};

export default AccountFormContainer;
