import { Box, IconButton, styled } from '@mui/material';

export const SearchAndFilterWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasBorder',
})(({ theme, border }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  borderTop: border ? `1px solid ${theme.palette.common.black}` : '',
  borderBottom: border ? `1px solid ${theme.palette.common.black}` : '',
  padding: `${theme.spacing(0.5)} 0`,
  minHeight: '48px',
  boxSizing: 'border-box',
  '& .MuiFormControl-root': {
    marginTop: 0,
  },
  [theme.breakpoints.down(800)]: {
    flexDirection: 'column',
    margin: 0,
  },
}));

export const StyledFilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.down(800)]: {
    borderTop: `1px solid ${theme.palette.common.black}`,
    marginTop: theme.spacing(1),
  },
}));

export const TextWrapper = styled(Box)(({ theme, click }) => ({
  marginRight: theme.spacing(1),
  cursor: click ? 'pointer' : '',
}));

export const SortArrowIconButton = styled(IconButton, {
  shouldForwardProp: (props) => props !== 'ordered',
})(({ theme, direction, ordered }) => ({
  borderRadius: '50px',
  padding: '4px 4px',
  marginLeft: '8px',
  marginRight: '8px',
  '&:hover': {
    backgroundColor: theme.palette.common.peach,
  },
  transform: direction === 'desc' ? 'rotate(180deg)' : '',
  '& svg': {
    transform: 'scale(0.8)',
    opacity: ordered ? 1 : 0.4,
  },
}));

export const OptionWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(2),
  '&:last-of-type': {
    marginRight: 0,
  },
}));

export const MenuWrapper = styled(Box)(({ theme, isModal }) => ({
  width: isModal ? '20px' : '40px',
  [theme.breakpoints.down('md')]: {
    width: '20px',
  },
}));
