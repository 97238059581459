import { styled } from '@mui/system';

export const SignUpSuccessContainer = styled('div')(() => ({
  flex: 1,
  textAlign: 'left',
}));

export const Container = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const PillContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
