import { useState, useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

import { ImageListItem, useTheme } from '@mui/material';
import ImageOverlay from 'components/ImageOverlay/ImageOverlay';
import EmptyImage from 'components/EmptyImage/EmptyImage';
import { getProductImgSrc } from 'api';
import './empty-image.css';

const CustomImageListItem = ({
  src,
  name,
  overlayOptions,
  onClick,
  styleOverride = {},
  wrapStatus = true,
  file_uuid,
  location,
  isProposal,
}) => {
  const [localSrc, setLocalSrc] = useState(src || null);
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const getImgSrc = useCallback(async () => {
    try {
      const data = await getProductImgSrc({ file_uuids: [file_uuid], ...(location ? { location } : {}) });
      const newSrc = data?.images[0]?.fileSrc;
      const decodedSrc = data?.images[0]?.fileType === 'url' ? atob(newSrc) : newSrc;
      setLocalSrc(decodedSrc);
    } catch (err) {
      console.log('🚀 err:', err);
    }
  }, [file_uuid, location]);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0.01,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView && !localSrc) {
      // get source
      getImgSrc();
    }
  }, [getImgSrc, inView, localSrc, file_uuid]);

  return localSrc ? (
    <ImageListItem key={name} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={onClick}>
      <img
        src={localSrc}
        alt="product"
        loading="lazy"
        style={{ borderRadius: theme.shape.borderRadius, maxHeight: '350px', aspectRatio: '1 / 1', ...styleOverride }}
      />
      {overlayOptions && !!Object.keys(overlayOptions).length && (
        <ImageOverlay wrapStatus={wrapStatus} {...overlayOptions} show={isHovering} />
      )}
    </ImageListItem>
  ) : (
    <div ref={ref} className={`${isProposal ? '' : 'animated-background'}`}>
      <EmptyImage
        handleMouseOver={handleMouseOver}
        handleMouseOut={handleMouseOut}
        overlayOptions={overlayOptions}
        isHovering={isProposal ? true : isHovering && inView}
        onClick={onClick}
      />
    </div>
  );
};

export default CustomImageListItem;
