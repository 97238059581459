import { Box } from '@mui/material';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import ControlledCheckBoxStandard from 'components/Inputs/CustomCheckboxStandard/ControlledCheckBoxStandard/ControlledCheckBoxStandard';

import BodyText from 'components/Typography/BodyText/BodyText';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';

import { termsList } from './NewBriefTermsAcceptForm.data';

const NewBriefTermsAcceptForm = () => {
  return (
    <Box>
      <Box mb={4}>
        <CustomTitle variant="h3" text="Next Steps" />
      </Box>
      <Box mb={4}>
        <BodyText text="Save and exit below and submit the brief later. Continue to view a summary and then submit the brief to the designer. The designer will receive an email and accept or decline the brief." />
      </Box>
      <Box>
        {termsList?.map((term) => {
          const {
            label: customFieldSetLabel,
            description,
            tooltipText,
            checkbox: { label, name },
          } = term;
          return (
            <Box mb={4} key={term.label}>
              <CustomFieldSet label={customFieldSetLabel} description={description} tooltipText={tooltipText}>
                <ControlledCheckBoxStandard label={label} name={name} showError={true} />
              </CustomFieldSet>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default NewBriefTermsAcceptForm;
