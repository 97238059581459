import { Box, styled, TableRow } from '@mui/material';

export const StyledTableRow = styled(TableRow, {
  shouldForwardProp: (props) => props !== 'isSelected' && props !== 'styledOverride' && props !== 'noHover',
})(({ theme, isSelected, noHover, styledOverride }) => ({
  backgroundColor: isSelected ? theme.palette.background.pink : '',
  cursor: noHover ? '' : 'pointer',
  height: 50,
  '&:hover': noHover
    ? {}
    : {
        backgroundColor: theme.palette.background.pink,
      },
  ...styledOverride,
}));

export const PillsWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
}));
