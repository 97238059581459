import React, { useEffect, useState, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import ModalListItemCheckbox from 'components/ModalListItemCheckbox/ModalListItemCheckbox';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import { mockMoodboards } from './ModalAddMoodboardProduct.data';
import CreateModalFields from 'components/ModalCreateMoodboard/CreateModalFields';
import Spinner from 'components/Spinner/Spinner';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { validation, yupObject } from 'utils/validation';

import { routes } from 'options/routes';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

import { addProductToMoodboard, addProductToMoodboards, createMoodboard, getMoodboardsList } from 'api';

const ModalAddMoodboardProduct = ({ open, handleClose, product_uuid, isStory, setMessage }) => {
  const [isLoading, setisLoading] = useState(false);
  const [moodboards, setMoodboards] = useState([]);
  const [productMoodboards, setProductMoodboards] = useState([]);
  const [moodboardSelected, setMoodBoardSelected] = useState([]);

  const [createNewMoodboard, setCreateNewMoodboard] = useState(false);

  const resolver = useYupValidationResolver(
    yupObject({
      ...validation.createMoodboard,
    })
  );
  const methods = useForm({
    mode: 'onChange',
    resolver,
  });

  const values = methods.watch();
  const { name, description } = values;

  const fetchMoodboards = useCallback(async () => {
    setisLoading(true);
    const res = await getMoodboardsList(product_uuid);
    if (res.success) {
      if (res.product_moodboards) {
        setProductMoodboards(res.product_moodboards.map(({ moodboard_uuid }) => ({ value: moodboard_uuid })));
      }
      setMoodboards(
        res.moodboards.map(({ moodboard_uuid, name, description = '' }) => ({ moodboard_uuid, name, description }))
      );
      return setisLoading(false);
    }

    setMessage({ text: res?.error_message || 'There was an error fetching moodboards' });
    setisLoading(false);
  }, [setMessage]);

  const resetMoodboard = () => {
    let moodboardList = [];
    if (localStorage.getItem('moodboard')) {
      let moodboardUuid = localStorage.getItem('moodboard');
      if (
        moodboardUuid &&
        !productMoodboards.filter((obj) => {
          return obj.value == moodboardUuid;
        }).length
      ) {
        moodboardList.push({ value: moodboardUuid });
      }
    }
    setMoodBoardSelected(moodboardList);
  };

  useEffect(() => {
    if (isStory) {
      return setMoodboards(mockMoodboards);
    }
    fetchMoodboards();
  }, [fetchMoodboards, isStory]);

  useEffect(() => {
    if (productMoodboards) {
      return resetMoodboard();
    }
  }, [productMoodboards]);

  useEffect(() => {
    if ((name || description) && moodboardSelected.length) {
      resetMoodboard();
    }
  }, [name, description, moodboardSelected.length]);

  useEffect(() => {
    if (!open) {
      resetMoodboard();

      methods.reset({ name: '' });
      setCreateNewMoodboard(false);
    }
  }, [methods, open]);

  const handleOnClick = (option, isSelected, isDisabled) => {
    if (isDisabled) {
      return;
    }
    if (isSelected) {
      return setMoodBoardSelected((prevState) => prevState.filter((v) => v.value !== option.value));
    }

    return setMoodBoardSelected([...moodboardSelected, option]);
  };

  const handleCreateMoodboard = () => {
    setCreateNewMoodboard((prevState) => !prevState);
  };

  const addProduct = async (moodboard_uuid) => {
    setisLoading(true);
    const res = await addProductToMoodboard(moodboard_uuid, product_uuid);
    setisLoading(false);
    if (res.success) {
      setMessage({
        text: `Saved to "${res.moodboard_name}".`,
        link: `${routes.moodboardsDashboard}/${moodboard_uuid}`,
        linkText: 'View moodboard',
      });
      return handleClose();
    }
    setisLoading(false);
    setMessage({ text: res?.data?.error_message || 'There was an error adding product to moodboard' });
    return handleClose();
  };

  const addProductMultiple = async (moodboard_uuids) => {
    setisLoading(true);
    const res = await addProductToMoodboards(moodboard_uuids, product_uuid);
    setisLoading(false);
    if (res.success) {
      setMessage({
        text: `Saved to multiple boards.`,
      });
      return handleClose();
    }
    setisLoading(false);
    setMessage({ text: res?.data?.error_message || 'There was an error adding product to moodboard' });
    return handleClose();
  };

  const handleAddProduct = async () => {
    // Accept and close
    if (createNewMoodboard) {
      const isValid = await methods.trigger();
      if (isValid) {
        // API call to create moodboard name, description
        setisLoading(true);
        const res = await createMoodboard({ name, description });
        setisLoading(false);
        if (!res.success) {
          setMessage({
            text: res.data?.error_message || 'An error has occurred creating moodboard. Please try again.',
          });
          return handleClose();
        }
        if (res.success) {
          const { moodboard_uuid } = res;
          return addProduct(moodboard_uuid);
        }
      }
      return;
    }

    // add product_uuid to selected moodboard
    if (moodboardSelected.length) {
      if (moodboardSelected.length === 1) {
        const { value } = moodboardSelected[0];
        // send POST request
        addProduct(value);
        return;
      }
      addProductMultiple(
        moodboardSelected.map((moodboard) => {
          return moodboard.value;
        })
      );
    }
  };

  return (
    <Box mt={2}>
      <CustomModal title="Add Product to Moodboard" open={open} handleClose={handleClose} variant="secondary">
        <StyledModalContentWrapper>
          <Box>
            <BodyText text="Select a Moodboard" colour="gray" />
          </Box>
          <Box>
            {isLoading && (
              <Box>
                <Spinner align="center" />
              </Box>
            )}
            {!isLoading &&
              moodboards?.map((moodboard) => {
                const { name, moodboard_uuid } = moodboard;
                const isSelected = moodboardSelected.find((v) => v.value === moodboard_uuid);
                const isPreviouslySaved = productMoodboards.find((v) => v.value === moodboard_uuid);
                return (
                  <ModalListItemCheckbox
                    option={{ label: name, value: moodboard_uuid }}
                    onClick={handleOnClick}
                    checked={isSelected}
                    isDisabled={isPreviouslySaved}
                    key={moodboard_uuid}
                  />
                );
              })}
          </Box>
          {!isLoading && (
            <Box my={2}>
              <CustomStandardLink text="Create new board" linkType="primary" onClick={handleCreateMoodboard} />
              <FormProvider {...methods}>{createNewMoodboard && <CreateModalFields shouldUnregister />}</FormProvider>
            </Box>
          )}
          <StyledActionButtonsContainer>
            <CustomButton text="Cancel" onClick={handleClose} variant="outlined" fullWidth />
            <CustomButton text="Accept" fullWidth onClick={handleAddProduct} />
          </StyledActionButtonsContainer>
        </StyledModalContentWrapper>
      </CustomModal>
    </Box>
  );
};

ModalAddMoodboardProduct.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  product_uuid: PropTypes.string.isRequired,
  isStory: PropTypes.bool,
  setMessage: PropTypes.func,
};

export default ModalAddMoodboardProduct;
