import React from 'react';
import PropTypes from 'prop-types';
import { CustomTableHeadCellStyled } from './CustomTableHeadCell.styles';

const CustomTableHeadCell = ({ children }) => {
  return <CustomTableHeadCellStyled>{children}</CustomTableHeadCellStyled>;
};

CustomTableHeadCell.propTypes = {};

export default CustomTableHeadCell;
