import { useMediaQuery } from '@mui/material';

export const useGalleryColumns = () => {
  const columns1 = useMediaQuery((theme) => theme.breakpoints.down(500));
  const columns2 = useMediaQuery((theme) => theme.breakpoints.down(1050));
  const columns3 = useMediaQuery((theme) => theme.breakpoints.down(1400));
  const columns = columns1 ? 1 : columns2 ? 2 : columns3 ? 3 : 4;

  return {
    columns,
  };
};
