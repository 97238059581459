import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import FormParagraph from '../../Typography/FormParagraph/FormParagraph';
import { FormContainer, Container } from './ForgotPassword.styles';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import { routes } from '../../../options/routes';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

const ForgotPasswordSuccess = () => {
  const navigate = useNavigate();

  return (
    <FormContainer>
      <CustomTitle variant="h2" text="Thanks, check your email" />
      <Container mt={2}>
        <FormParagraph
          text="If you haven't received an email in 5 minutes, check your spam or we can send you a new code."
          variant="primary"
        />
      </Container>
      <Container></Container>
      <Box display="flex" justifyContent="flex-end">
        <CustomButton
          text="Resend password request"
          variant="outlined"
          onClick={() => navigate(routes.forgotPassword)}
        />
      </Box>
    </FormContainer>
  );
};

export default ForgotPasswordSuccess;
