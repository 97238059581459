import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';
import NewBriefThanks from 'components/NewBriefThanks/NewBriefThanks';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';

const BuyerBriefThanks = ({ userType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEditing = location?.state?.isEditing;

  useEffect(() => {
    if (userType && userType === userTypes.designer) navigate(routes.home);
  }, [userType, navigate]);

  return (
    <BuyerDashboardWrapper
      activeTab={routes.briefThanks}
      breadcrumbs={
        <Breadcrumbs
          links={[
            { label: 'Briefs', onClick: () => navigate(routes.briefsDashboard) },
            { label: 'New Brief', onClick: () => navigate(routes.briefsAdd) },
          ]}
          currentPage={'Thanks'}
        />
      }
    >
      <NewBriefThanks isEditing={isEditing} />
    </BuyerDashboardWrapper>
  );
};

export default BuyerBriefThanks;
