import { Typography, styled } from '@mui/material';

export const BodyTextStyled = styled(Typography, {
  shouldForwardProp: (props) => props !== 'fontSize' && props !== 'truncateWord' && props !== 'styleOverrides',
})(({ bold, fontSize, truncateWord, styleOverrides = {} }) => ({
  fontWeight: bold ? 600 : 400,
  fontSize: fontSize === 'small' ? '0.750rem' : 'unset',
  textOverflow: truncateWord ? 'ellipsis' : 'unset',
  overflow: truncateWord ? 'hidden' : 'unset',
  whiteSpace: truncateWord ? 'nowrap' : 'pre-wrap',
  wordBreak: 'break-word',
  ...styleOverrides,
}));
