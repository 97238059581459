import PropTypes from 'prop-types';

import Spinner from 'components/Spinner/Spinner';
import CustomModal from 'components/CustomModal/CustomModal';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import CustomTextField from 'components/Inputs/TextFields/CustomTextField/CustomTextField';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { ModalLoading } from './ModalHelpConversation.styles';
import { StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

const ModalHelpConversation = ({
  open,
  handleClose,
  chat_uuid,
  chat_title,
  handleTitleInput,
  handleCreateNewConvo,
  handleEditExistingConvo,
  helpModalLoading,
}) => {
  const handleCloseModal = () => {
    handleClose();
  };
  return (
    <CustomModal title={chat_uuid ? 'Edit convo' : 'New convo'} handleClose={handleCloseModal} open={open}>
      {!helpModalLoading ? (
        <>
          <CustomFieldSet label="Title" />
          <CustomTextField placeholder={'Enquiry'} value={chat_title} onChange={handleTitleInput} />
          <StyledActionButtonsContainer>
            <CustomButton variant="outlined" text="Cancel" onClick={handleCloseModal} />
            <CustomButton
              text="Submit"
              onClick={() => (chat_uuid !== '' ? handleEditExistingConvo() : handleCreateNewConvo())}
            />
          </StyledActionButtonsContainer>
        </>
      ) : (
        <ModalLoading>
          <Spinner align={'center'} />
        </ModalLoading>
      )}
    </CustomModal>
  );
};

ModalHelpConversation.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  chat_uuid: PropTypes.string,
  chat_title: PropTypes.string,
};

export default ModalHelpConversation;
