import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import { mockMembers } from './ModalShareMoodboard.data';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import BodyText from 'components/Typography/BodyText/BodyText';
import ModalListItemCheckbox from 'components/ModalListItemCheckbox/ModalListItemCheckbox';
import CustomDivider from 'components/Divider/CustomDivider';
import Spinner from 'components/Spinner/Spinner';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

import { getMoodboardUsers, shareMoodboard, unshareMoodboard } from 'api';

const ModalShareMoodboard = ({ isStory, open, handleClose, moodboard_uuid, setMessage }) => {
  const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);
  const [membersSelected, setMembersSelected] = useState([]);
  const [membersToDelete, setMembersToDelete] = useState([]);

  const fetchTeamMembers = useCallback(async () => {
    setIsLoadingTeamMembers(true);
    const res = await getMoodboardUsers(moodboard_uuid);
    if (res.success) {
      setTeamMembers(res?.team);
      const membersWithAccess = res?.team?.filter((member) => member.has_moodboard_access);
      if (membersWithAccess?.length) {
        setMembersSelected(membersWithAccess);
      }
    }
    setIsLoadingTeamMembers(false);
  }, [moodboard_uuid]);

  useEffect(() => {
    // set team members...
    if (isStory) {
      setTeamMembers(mockMembers);
      const membersWithAccess = mockMembers.filter((member) => member.has_moodboard_access);
      if (membersWithAccess?.length) {
        setMembersSelected(membersWithAccess);
      }
      return;
    }

    fetchTeamMembers();
  }, [fetchTeamMembers, isStory]);

  const handleOnClick = useCallback(
    (option, isSelected) => {
      if (option.uuid === 'all') {
        setMembersSelected(() => (!isSelected ? [...teamMembers] : []));
        return setMembersToDelete(() => {
          if (isSelected) {
            //if all is Selected and now unselecting, delete members that had access
            const membersWithAccess = teamMembers.filter((member) => member.has_moodboard_access);
            if (membersWithAccess?.length) {
              return membersWithAccess;
            }
          }
          return [];
        });
      }

      if (option.has_moodboard_access) {
        setMembersToDelete((prevState) =>
          isSelected ? [...prevState, option] : prevState.filter((prev) => prev.uuid !== option.uuid)
        );
      }
      setMembersSelected((prevState) =>
        !isSelected ? [...prevState, option] : prevState.filter((selected) => selected.uuid !== option.uuid)
      );
    },
    [teamMembers]
  );

  const handleShareMoodboard = useCallback(async () => {
    const membersSelectedWithNoAccess = membersSelected.filter((member) => !member.has_moodboard_access);
    if (membersToDelete?.length) {
      setIsLoadingTeamMembers(true);
      const promises = membersToDelete.map(({ uuid }) => unshareMoodboard(moodboard_uuid, uuid));
      Promise.all(promises)
        .then((_values) => {
          setIsLoadingTeamMembers(false);
          setMessage('Succesfully unshared moodboard');
          if (!membersSelectedWithNoAccess?.length) {
            handleClose(false);
          }
        })
        .catch(() => {
          setMessage('There was a problem unsharing moodboard');
        });
    }

    if (membersSelectedWithNoAccess?.length) {
      setIsLoadingTeamMembers(true);
      const promises = membersSelectedWithNoAccess.map(({ uuid }) => shareMoodboard(moodboard_uuid, uuid));
      Promise.all(promises)
        .then((_values) => {
          setIsLoadingTeamMembers(false);
          handleClose(false);
          setMessage('Successfully shared moodboard to members.');
        })
        .catch(() => {
          handleClose(false);
          setMessage('There was a problem sharing moodboard');
        });
    }
  }, [handleClose, membersSelected, membersToDelete, moodboard_uuid, setMessage]);

  return (
    <CustomModal title="Share Moodboard" open={open} handleClose={handleClose}>
      <StyledModalContentWrapper>
        <BodyText text="Select a team member. Manage your team in your account." colour="gray" />
        <CustomDivider orientation="horizontal" />
        <Box>
          <ModalListItemCheckbox
            option={{ label: 'Select All', uuid: 'all' }}
            onClick={handleOnClick}
            checked={membersSelected.length === teamMembers.length}
            key="all"
          />
          {isLoadingTeamMembers && (
            <Box mt={2}>
              <Spinner align="center" />
            </Box>
          )}
          {!isLoadingTeamMembers &&
            teamMembers?.map((member) => {
              const isSelected = !!membersSelected.find((v) => v.uuid === member?.uuid);
              return (
                <ModalListItemCheckbox
                  option={{
                    label: `${member.first_name} ${member.last_name}`,
                    uuid: member.uuid,
                    has_moodboard_access: member.has_moodboard_access,
                  }}
                  onClick={handleOnClick}
                  checked={isSelected}
                  key={member?.uuid}
                />
              );
            })}
        </Box>
        <StyledActionButtonsContainer>
          <CustomButton variant="outlined" text="Cancel" onClick={handleClose} fullWidth />
          <CustomButton text="Share Moodboard" onClick={handleShareMoodboard} fullWidth />
        </StyledActionButtonsContainer>
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

ModalShareMoodboard.propTypes = {};

export default ModalShareMoodboard;
