import ForgotPasswordSuccess from '../../../components/Forms/ForgotPasswordForm/ForgotPasswordSuccess';
import TwoColumnBlackWhiteWrapper from 'components/Layout/TwoColumnBlackWhiteWrapper/TwoColumnBlackWhiteWrapper';

const ForgotPassword = () => {
  return (
    <TwoColumnBlackWhiteWrapper>
      <ForgotPasswordSuccess />
    </TwoColumnBlackWhiteWrapper>
  );
};

export default ForgotPassword;
