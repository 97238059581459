import React, { useCallback, useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import FileUploads from 'components/FileUploads/FileUploads';
import Spinner from 'components/Spinner/Spinner';

import { routes } from 'options/routes';

import { getIsFilesTabDisabled, getIsProjectCompleted } from '../DesignerOpenCalls.utils';

import {
  deleteDesignerOpenCallsFiles,
  downloadDesignerOpenCallsFiles,
  getDesignerOpenCallsFiles,
  postDesignerOpenCallsFiles,
} from 'api';

const DesignerOpenCallsFilesTab = () => {
  const navigate = useNavigate();
  const { brief_uuid } = useParams();
  const [briefData, setMessage, userType] = useOutletContext();

  const project_status = briefData?.project_status || '';
  const { agreement_status } = briefData;

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['designerOpenCallFiles'],
    () => getDesignerOpenCallsFiles(brief_uuid),
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  useEffect(() => {
    const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status);

    if (isFilesTabDisabled) {
      navigate(`${routes.openCallsDashboard}/${brief_uuid}/open-call`);
    }
  }, [agreement_status, brief_uuid, navigate]);

  const onUploadFile = async (filesData) => {
    const data = {
      files: filesData,
      is_chat: false,
    };

    const res = await postDesignerOpenCallsFiles(brief_uuid, data);
    if (!res.success) {
      return setMessage('There was an error uploading the files. Please try again.');
    }
    setMessage('You have successfully uploaded files');
    refetch();
  };

  const design_files = data?.design_files || {};
  const isProjectCompleted = getIsProjectCompleted(project_status);

  const handleDownloadFiles = useCallback(
    (data) => downloadDesignerOpenCallsFiles(brief_uuid, data, true),
    [brief_uuid]
  );

  const handleDeleteFiles = useCallback(
    (file_uuid) => deleteDesignerOpenCallsFiles(brief_uuid, file_uuid),
    [brief_uuid]
  );

  const loading = isLoading || isRefetching;

  return (
    <Box>
      <Box mt={2}>
        {loading ? (
          <Box>
            <Spinner align="center" />
          </Box>
        ) : (
          <FileUploads
            files={design_files}
            uploadFile={onUploadFile}
            withUploader={!isProjectCompleted}
            setMessage={setMessage}
            refetch={refetch}
            userType={userType}
            downloadFiles={handleDownloadFiles}
            deleteFiles={!isProjectCompleted && handleDeleteFiles}
          />
        )}
      </Box>
    </Box>
  );
};

DesignerOpenCallsFilesTab.propTypes = {};

export default DesignerOpenCallsFilesTab;
