import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import FixedCustomNotification from 'components/FixedCustomNotification/FixedCustomNotification';

import { MessageContext } from 'context/MessageContext';

const NotificationProvider = ({ children }) => {
  const [message, setStateMessage] = useState('');
  const navigate = useNavigate();
  const text = message?.text || message;
  const linkText = message?.linkText;
  const link = message?.link;

  const closeMessage = () => {
    setMessage('');
  };

  const setMessage = (message) => {
    setStateMessage(message);
    // uncomment to use close message after 6000ms
    // setTimeout(() => {
    //   closeMessage();
    // }, 6000);
  };

  const contextValues = [setMessage];

  return (
    <MessageContext.Provider value={contextValues}>
      {children}
      {text && (
        <FixedCustomNotification
          variant="fixed"
          text={text}
          linkText={linkText}
          onLinkClick={
            link &&
            (() => {
              navigate(link);
              closeMessage();
            })
          }
          onClose={() => setMessage('')}
        />
      )}
    </MessageContext.Provider>
  );
};

NotificationProvider.propTypes = {};

export default NotificationProvider;
