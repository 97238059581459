import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { CustomDatePickerStyled } from './CustomDatePicker.styles';
import { ErrorMessage } from '../TextFields/CustomTextField/CustomTextField.styles';

const CustomDatePicker = ({
  label,
  value,
  onChange,
  error,
  success,
  views,
  inputFormat,
  fieldStyle = 'primary',
  showError = true,
  disableFuture = false,
}) => {
  return (
    <DesktopDatePicker
      label={label}
      inputFormat={inputFormat ? inputFormat : 'dd/MM/yyyy'}
      views={views}
      value={value}
      onChange={onChange}
      disableFuture={disableFuture}
      renderInput={(params) => {
        return (
          <Box>
            <CustomDatePickerStyled
              variant="outlined"
              label={label}
              {...params}
              error={!!error}
              color={success ? 'success' : 'primary'}
              fieldStyle={fieldStyle}
            />
            {showError && <Box height="20px">{error && <ErrorMessage>{error}</ErrorMessage>}</Box>}
          </Box>
        );
      }}
    />
  );
};

CustomDatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.instanceOf(Date || String),
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  success: PropTypes.bool,
  views: PropTypes.arrayOf((propValue, key, componentName) => {
    if (!/year|month|date/.test(propValue[key])) {
      return new Error(
        `Invalid prop ${propValue[key]} supplied to ${componentName} views array, expected 'year', 'month' or 'date'`
      );
    }
  }),
};

export default CustomDatePicker;
