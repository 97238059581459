import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@mui/material';
import { FormControlLabelStyled } from './CustomRadioFieldStandard.styles';

const CustomRadioFieldStandard = ({
  label,
  value,
  labelPlacement = 'end',
  showBorder,
  disabled = false,
  checked,
  onChange,
}) => {
  return (
    <FormControlLabelStyled
      label={label}
      value={value}
      control={<Radio size="small" />}
      labelPlacement={labelPlacement}
      showBorder={showBorder}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
    />
  );
};

CustomRadioFieldStandard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

export default CustomRadioFieldStandard;
