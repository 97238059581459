import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { Box } from '@mui/system';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import ControlledCustomFiledUpload from 'components/CustomFileUpload/ControlledCustomFileUpload/ControlledCustomFileUpload';
import ControlledCustomRadioGroup from 'components/Inputs/CustomRadioStandardGroup/ControlledCustomRadioGroup/ControlledCustomRadioGroup';
// import ControlledCheckBoxTerms from 'components/Inputs/CustomCheckBoxTerms/ControlledCheckBoxTerms/ControlledCheckBoxTerms';
import SingleInputResponsiveWrapper from 'components/Wrappers/SingleInputResponsiveWrapper/SingleInputResponsiveWrapper';
import BodyText from 'components/Typography/BodyText/BodyText';
import ControlledCustomFiledUploadDND from 'components/CustomFileUpload/ControlledCustomFIleUploadDND/ControlledCustomFileUploadDND';
import NewPitchBuyersList from 'components/NewPitchBuyersList/NewPitchBuyersList';

import { wpRoutes } from 'options/routes';

import {
  ADD_PRODUCT_AGREEMENT_TYPES,
  ADD_PRODUCT_DIMENSIONS,
  ADD_PRODUCT_REGISTERED_OPTIONS,
} from './ProductProposalDetailsForm.data';

import { StyledDimensionContainer, StyledDimensionWrapper } from './ProductProposalDetailsForm.styles';
import { LICENSE_TYPES } from 'options/briefOptions';
import { CURRENCY_MAP } from 'options/currency';

const ProductProposalDetailsForm = ({ currency, hasBuyers }) => {
  const { watch, register, unregister, clearErrors } = useFormContext();
  const registered = watch('registered');
  const agreementType = watch('agreement_type');

  const selectedCurrency = CURRENCY_MAP[currency] || '£';

  useEffect(() => {
    if (registered === 'yes') {
      register('registered_info');
    } else {
      unregister('registered_info');
    }
  }, [register, registered, unregister]);

  useEffect(() => {
    clearErrors();
    if (agreementType === LICENSE_TYPES.royalty) {
      unregister('price');
    } else {
      register('price', '');
    }
  }, [agreementType, clearErrors, register, unregister]);

  return (
    <div>
      <Box mb={6}>
        <CustomFieldSet label="Product title" />
        <ControlledTextField name="name" label="" placeholder="Give your product a suitable title" />
      </Box>
      <Box mb={6}>
        <CustomFieldSet label="Product Concept" />
        <ControlledTextField
          multiline={true}
          minRows={4}
          name="concept"
          label=""
          placeholder={
            'Tell us more about your product, include any key information you feel is relevant to this design —  the more detail the better.'
          }
        />
      </Box>
      <Box mb={6}>
        <CustomFieldSet
          label="Cover Image"
          description="For coordination and consistency cover images of products must use the standard BuyDesign image template. Creating a coordinated presentation helps us present your work clearly and consistently. Follow our guides found in the resources page "
          tooltipTitle="Images"
          tooltipText="This image is what buyers will see first."
          linkText='here'
          linkPath={wpRoutes.resourcesImageGuidelines}
          linkInline
          linkIsNewTab
        >
        <ControlledCustomFiledUpload numberOfTiles={1} variant={'single'} name={'main_image'} fileTypes="images" />
        </CustomFieldSet>
      </Box>
      <Box mb={6}>
        <CustomFieldSet label="Product images" description="Upload a maximum of 10 images" />
        <ControlledCustomFiledUploadDND
          numberOfTiles={10}
          variant={'multi'}
          name={'additional_images'}
          fileTypes="images"
        />
      </Box>
      <Box mb={6}>
        <CustomFieldSet label="Construction" />
        <ControlledTextField
          multiline={true}
          minRows={1}
          name="construction"
          label=""
          placeholder={'Describe any key construction, manufacturing and assembly details.'}
        />
      </Box>
      <Box mb={6}>
        <CustomFieldSet label="Colour, material and finish" />
        <ControlledTextField
          multiline={true}
          minRows={1}
          name="colour_material_finish"
          label=""
          placeholder={'Describe any further details about the products colour, material and finish (CMF).'}
        />
      </Box>
      <Box mb={6}>
        <CustomFieldSet
          label="Dimensions"
          description="All measurements in millimetres using the following format Width x Height x Depth"
        />
        <StyledDimensionContainer>
          {ADD_PRODUCT_DIMENSIONS.map((d, index) => (
            <StyledDimensionWrapper key={d.placeholder}>
              <ControlledTextField
                label=""
                placeholder={d.placeholder}
                name={d.name}
                key={`${index}_field_${d.placeholder}`}
              />
              {index !== ADD_PRODUCT_DIMENSIONS.length - 1 && (
                <BodyText text="x" key={`${index}_text_${d.placeholder}`} />
              )}
            </StyledDimensionWrapper>
          ))}
        </StyledDimensionContainer>
      </Box>
      <Box mb={6}>
        <CustomFieldSet
          label="Production files"
          description="Upload any files associated with the product including 3D, technical drawings or other design files"
          tooltipText="Your files will only be available for a buyer once an agreement has been made. "
        />
        <ControlledCustomFiledUpload numberOfTiles={10} variant={'multi'} name={'production_files'} fileTypes="files" />
      </Box>
      {hasBuyers && (
        <Box mb={6}>
          <NewPitchBuyersList />
        </Box>
      )}
      <Box mb={6}>
        <CustomFieldSet
          label="How do you want the product to be available?"
          description="This is to show a company how your product can be purchased. Either for a One off fee, a Royalty License or you may be open to both. If a company is interested in the product you will negotiate the terms together."
          tooltipText="One off design fee is where a buyer pays you a single fee for the rights to the design. Royalty license is where you receive a % of sales based on the agreement that’s made between you and the buyer. "
        />
        <ControlledCustomRadioGroup direction="row" name="agreement_type" options={ADD_PRODUCT_AGREEMENT_TYPES} />
        <Box>
          {(agreementType === LICENSE_TYPES.one_off || agreementType === LICENSE_TYPES.both) && (
            <SingleInputResponsiveWrapper>
              <ControlledTextField
                label=""
                placeholder={'Enter a price'}
                startAdornment={selectedCurrency}
                name="price"
              />
            </SingleInputResponsiveWrapper>
          )}
        </Box>
      </Box>
      <Box mb={4}>
        <CustomFieldSet
          label="Is your product registered?"
          tooltipText="Further information regarding registering your product can be found in our Resources page."
        />
        <ControlledCustomRadioGroup direction="row" name="registered" options={ADD_PRODUCT_REGISTERED_OPTIONS} />
        {registered === 'yes' && (
          <Box>
            <ControlledTextField
              multiline={true}
              minRows={2}
              name="registered_info"
              label=""
              defaultValue=""
              placeholder={
                'Have you patented, trademarked or registered the design in any way before submitting it to BuyDesign?'
              }
            />
          </Box>
        )}
      </Box>
      {/* <Box mb={4}>
        <CustomFieldSet label="Terms and conditions" description="Agree to the BuyDesigns terms and conditions" />
        <ControlledCheckBoxTerms name="terms_accepted" />
      </Box> */}
    </div>
  );
};

ProductProposalDetailsForm.propTypes = {};

export default ProductProposalDetailsForm;
