import React from 'react';

import NavBarLogo from '../components/NavBarLogo/NavBarLogo';
import { NavBarStyled, NavBarWrapperStyled } from '../NavBar.styles';
import NavBarLinkList from '../components/NavBarLinkList/NavBarLinkList';

const NavBar = () => {
  return (
    <NavBarStyled>
      <NavBarWrapperStyled>
        <NavBarLogo />
        <NavBarLinkList type="standard" />
      </NavBarWrapperStyled>
    </NavBarStyled>
  );
};

export default NavBar;
