import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import { StyledNamesColumnWrapper } from './NamesColumn.styles';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import BodyText from 'components/Typography/BodyText/BodyText';

const NamesColumn = ({ title, options }) => {
  return (
    <StyledNamesColumnWrapper>
      <Box pt={2} mb={2} borderTop={'1.4px solid'}>
        <CustomTitle variant="h4" text={title} />
      </Box>
      {options?.length > 0 &&
        options.map((option, index) => {
          return (
            <Box key={`names-option${index}+${option.label}`}>
              <CustomStandardLink onClick={() => option.onClick()} linkType="primary" text={option.label} />
            </Box>
          );
        })}
      {!options || (options.length === 0 && <BodyText text="-" />)}
    </StyledNamesColumnWrapper>
  );
};

NamesColumn.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    }).isRequired
  ),
};

export default NamesColumn;
