/*
 * insertOrRemoveCategory
 *  
 * it inserts nested options to nested object in given category,
 * it removes nested options if it finds a category that is not currently selected and still has nested array
 * 
 * For example, returns
 * 
 [
    {
      name: "Furniture",
      slug: "furniture",
      has_nested: true,
      nested: [
      {
        name: "Soft Seating",
        slug: "soft-seating",
        has_nested: true,
      },
      {
        name: "Seating",
        slug: "seating",
        has_nested: true,
      }
    ] 
  }
];
 */

export const insertOrRemoveCategory = (categoryList, categoryName, nestedOptions, categoriesSelected) => {
  if (categoryName === 'top') {
    return nestedOptions;
  }

  const categoriesSelectedValues = categoriesSelected.map((c) => c.value);

  const findAndInsert = (list) => {
    return list.map((item) => {
      if (item.value === categoryName) {

        // Only add the item as an option if not present already
        if (nestedOptions.find((option) => option.label === item.label)) {
          return {
            ...item,
            nested: [...nestedOptions],
          };
        }
        return {
          ...item,
          nested: [{ label: item.label, value: item.value }, ...nestedOptions],
        };
      }
      // if this category is no longer selected, unselect it
      if (!categoriesSelectedValues.includes(item.value) && item.nested?.length) {
        return {
          ...item,
          nested: [],
        };
      }

      const hasNested = item.nested && item.nested.length;
      if (hasNested) {
        const nestedItems = findAndInsert(item.nested);
        return {
          ...item,
          nested: nestedItems,
        };
      }
      return item;
    });
  };
  return findAndInsert(categoryList);
};

const checkAndTransformArray = (arr, index, item) => {
  if (Array.isArray(arr[index])) {
    arr[index].push(item);
  } else {
    arr.push([item]);
  }
};

/*
 * transform
 * takes nested options and appends it to an array
 * takes a function to modify item, used for example to select option
 * For example:
 * [[{name, label}], [{name, label}], [{name, label}],[{name, label}]]
 * Each one of these is a column of options
 */

export const transform = (arr, selectItem) => {
  if (!arr) {
    return arr;
  }
  const transformed = [];
  const findNested = (arrItem, levelIndex = 0) => {
    return arrItem.forEach((item) => {
      const selectedItem = selectItem(item);
      checkAndTransformArray(transformed, levelIndex, selectedItem);

      const hasNested = selectedItem.nested && selectedItem.nested.length;

      if (hasNested) {
        return findNested(selectedItem.nested, levelIndex + 1);
      }
    });
  };

  findNested(arr);

  return transformed;
};

export const isOptionInList = (optionsList, value) =>
  !!optionsList?.find((categorySelected) => categorySelected.value === value);

export const selectItem = (categoriesSelected, currentSelectedOptions, item) => {
  const value = item.value || '';

  return {
    ...item,
    isCategorySelected: isOptionInList(categoriesSelected, value) && item.has_nested,
    isOptionSelected: isOptionInList(currentSelectedOptions, value) && !item.has_nested,
  };
};

export const getHeaderVariant = (headerOptions, allValues, tabValue) => {
  return headerOptions.map((option) => {
    const currentOptionSelection = allValues[option.value];
    if (!!currentOptionSelection?.length) {
      return {
        ...option,
        variant: 'completed',
      };
    }
    if (option.value === tabValue) {
      return {
        ...option,
        variant: 'active',
      };
    }

    return option;
  });
};
