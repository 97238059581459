import { Menu, styled } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SearchIcon from '@mui/icons-material/Search';

export const NavBarIconStyled = styled('button')(({ theme }) => ({
  position: 'relative',
  padding: 0,
  margin: 0,
  marginRight: theme.spacing(2),
  backgroundColor: 'transparent',
  border: 0,
  fontSize: '12px',
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    marginRight: theme.spacing(1),
  },
}));

export const NotificationWrapper = styled('div')(() => ({
  position: 'absolute',
  top: '-3px',
  right: '-4px',
}));

export const NotificationStyled = styled(NotificationsNoneIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const SearchStyled = styled(SearchIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const NotificationMenuStyled = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    minHeight: 0,
    minWidth: 0,
    borderRadius: 0,
    maxHeight: 300,
    maxWidth: 200,
    overflowY: 'scroll',
    '& ul': {
      padding: 0,
    },
  },
}));
