import PropTypes from 'prop-types';

import ResetPasswordForm from '../../../components/Forms/ResetPasswordForm/ResetPasswordForm';
import TwoColumnBlackWhiteWrapper from 'components/Layout/TwoColumnBlackWhiteWrapper/TwoColumnBlackWhiteWrapper';

const ResetPassword = ({ variant }) => {
  return (
    <TwoColumnBlackWhiteWrapper>
      <ResetPasswordForm variant={variant} />
    </TwoColumnBlackWhiteWrapper>
  );
};

ResetPassword.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default ResetPassword;
