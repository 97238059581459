import { Box, styled } from '@mui/material';

export const StyledMegaMenuListContainer = styled(Box)(() => ({
  // flex: 1,
}));

export const StyledMegaMenuListTitleContainer = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  borderBottom: '2px solid',
  cursor: 'pointer',
  marginBottom: 24,
  '& a': {
    fontWeight: 600,
    minHeight: 48,
    display: 'flex',
    alignItems: 'center',
  },
}));

export const StyledMegaMenuSubCatContainer = styled(Box)(({ theme, last }) => ({
  // marginBottom: theme.spacing(1),
  cursor: 'pointer',
  padding: theme.spacing(1),
  borderBottom: !last ? `2px solid ${theme.palette.grey[300]}` : '',
  '&:hover': {
    backgroundColor: theme.palette.common.peach,
  },
}));
