import React from 'react';
import PropTypes from 'prop-types';
import { PageLoadingWrapper } from './PageLoading.styles';
import Spinner from 'components/Spinner/Spinner';

const PageLoading = () => {
  return (
    <PageLoadingWrapper>
      <Spinner align={'center'} />
    </PageLoadingWrapper>
  );
};

PageLoading.propTypes = {};

export default PageLoading;
