import axios from 'axios';

import { routes } from '../options/routes';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const defaultGetParams = '&0=';

const authWebService = async (url, method, data) => {
  // Generic API call function for unauthenticated endpoints
  try {
    const response = await axios({
      method,
      baseURL: BASE_URL,
      url: url,
      params: method === 'GET' ? data || {} : {},
      data: method !== 'GET' ? data || {} : {},
      headers: {
        'Content-Type': 'application/json',
      },
      timeout: 90000,
    });

    return response.data;
  } catch (error) {
    console.log(`Authentication error from ${url}`);
    console.error(error);
    console.log(error.message);

    return error.response;
  }
};

/* ------------------------------------------- Unauthenticated endpoints ------------------------------------------- */
export const login = async (data) => await authWebService('login', 'POST', data);

export const forgotPassword = async (data) => await authWebService(`forgot-password`, 'POST', data);

export const verifyResetToken = async (data) => await authWebService(`token/verify/reset`, 'POST', data);

export const resetPassword = async (data) => await authWebService('reset-password', 'POST', data);

export const verifyInviteToken = async (data) => await authWebService('token/verify/invite', 'POST', data);

export const acceptInvite = async (data) => await authWebService('accept-invitation', 'POST', data);

export const buyerValidateCompany = async (data) => await authWebService('validate-company', 'POST', data);

export const designerSignup = async (data) => await authWebService('designer-registration', 'POST', data);

export const buyerSignup = async (data) => await authWebService('buyer-registration', 'POST', data);

export const verifySignupToken = async (data) => await authWebService('token/verify/registration', 'POST', data);

export const refreshSignupToken = async (data) => await authWebService('token/refresh/registration', 'POST', data);

// Endpoints for getting options - all, top level only or children with parent slug
export const getAllOptions = async (type) => await authWebService(`options/${type}`, 'GET');

export const getTopOptions = async (type) => await authWebService(`options/${type}?parent=top`, 'GET');

export const getChildOptions = async (type, parent) => await authWebService(`options/${type}?parent=${parent}`, 'GET');

export const invokeWebService = async (url, method, data, isFile, isBlob) => {
  // Generic API call function for authenticated endpoints
  try {
    // account object stored as string in localStorage - need to parse it and get the accessToken
    const token = localStorage.getItem('token');
    if (!token) {
      localStorage.clear();
      window.location.href = routes.login;
      return;
    }
    // Set contentType to 'multipart/form-data' if passing files in the data
    const contentType = isFile ? 'multipart/form-data' : 'application/json';

    const response = await axios({
      method,
      baseURL: BASE_URL,
      url: url,
      // Pass data as params for GET request, or as data for all other requests
      params: method === 'GET' ? data || {} : {},
      data: method !== 'GET' ? data || {} : {},
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': contentType,
      },
      timeout: 90000,
      responseType: isBlob ? 'blob' : 'json',
    });

    return response.data;
  } catch (error) {
    console.log(`Request error from ${url}`);
    console.error(error);
    console.log(error.message);

    if (error?.response?.data?.code === 'token_not_valid') {
      localStorage.clear();
      window.location.href = routes.login;
      return;
    }
    // throw new Error(error.response.data.error_message);
    return error.response;
  }
};

/* --------------------------------------- User profile management endpoints --------------------------------------- */
export const getProfile = async () => await invokeWebService('profile', 'GET');

export const getDesignerProfile = async (id) => await invokeWebService(`designers/${id}`, 'GET');

export const getBuyerProfile = async (id) => await invokeWebService(`buyers/${id}`, 'GET');

export const getAccountProfile = async () => await invokeWebService('account/profile', 'GET');

export const getDesignersProfileProducts = async (queryString) =>
  await invokeWebService(`products/designer-profile?${queryString}`, 'GET');

// Add the form_type=profile querystring to the call for designer profile
export const updateAccountProfile = async (data, isDesignerProfile) =>
  await invokeWebService(`account/profile${isDesignerProfile ? '?form_type=profile' : ''}`, 'PUT', data);

export const getAccountCompany = async () => await invokeWebService('account/company', 'GET');

export const updateAccountCompany = async (data) => await invokeWebService('account/company', 'PUT', data);

export const getAccountTeam = async () => await invokeWebService('account/team', 'GET');

export const addTeamMembers = async (data) => await invokeWebService('account/team', 'POST', data);

export const deleteTeamMember = async (id) => await invokeWebService(`account/team/${id}`, 'DELETE');

export const getAccountType = async () => await invokeWebService('account/type', 'GET');

export const updateAccountType = async (data) => await invokeWebService('account/type', 'PUT', data);

export const getAccountStripe = async (accountType) => {
  const url = `account/stripe${accountType ? `?account_type=${accountType}` : ''}`;
  return await invokeWebService(url, 'GET');
};

export const changePassword = async (data) => await invokeWebService('change-password', 'POST', data);

export const getDesignerProductsList = async (queryString) => {
  const url = `profile/designer-products?${queryString}`;
  return await invokeWebService(url, 'GET', defaultGetParams);
};

export const deleteDesignerProduct = async (uuid) =>
  await invokeWebService(`profile/designer-products/${uuid}`, 'DELETE');

export const getDesignerProduct = async (uuid) => await invokeWebService(`profile/designer-products/${uuid}`, 'GET');

export const createDesignerProduct = async (data) => await invokeWebService(`profile/designer-products`, 'POST', data);

export const updateDesignerProduct = async (uuid, data) =>
  await invokeWebService(`profile/designer-products/${uuid}`, 'PUT', data);

export const getBuyersBriefList = async (queryString) =>
  await invokeWebService(`profile/buyer-briefs${queryString ? `?${queryString}` : ''}`, 'GET');

export const deleteBuyersBrief = async (uuid) => await invokeWebService(`profile/buyer-briefs/${uuid}`, 'DELETE');

export const cancelBuyerBrief = async (brief_uuid) =>
  await invokeWebService(`profile/buyer-briefs/${brief_uuid}/cancel`, 'POST');

export const getBuyersBrief = async (uuid) => await invokeWebService(`profile/buyer-briefs/${uuid}`, 'GET');

export const createBuyersBrief = async (data) => await invokeWebService(`profile/buyer-briefs`, 'POST', data);

export const updateBuyersBrief = async (uuid, data) =>
  await invokeWebService(`profile/buyer-briefs/${uuid}`, 'PUT', data);

export const downloadReferenceFile = async (file_uuid) =>
  await invokeWebService(`profile/reference_file/${file_uuid}/download`, 'GET', null, null, true);

export const getBuyersProductsList = async (queryString) => {
  const url = `profile/buyer-products?${queryString}`;
  return await invokeWebService(url, 'GET', defaultGetParams);
};

export const getProducts = async (queryString) => {
  const url = `products${queryString ? `?${queryString}` : ''}`;
  return await invokeWebService(url, 'GET', defaultGetParams);
};

export const getProduct = async (product_uuid) => await invokeWebService(`products/${product_uuid}`, 'GET');

export const getTermsList = async (queryString) => {
  const url = `profile/terms?${queryString}`;
  return await invokeWebService(url, 'GET');
};

export const getSingleTerm = async (terms_uuid) => await invokeWebService(`profile/terms/${terms_uuid}`, 'GET');

export const createTerms = async (data) => await invokeWebService('profile/terms', 'POST', data);

export const updateTerms = async (terms_uuid, data) =>
  await invokeWebService(`profile/terms/${terms_uuid}`, 'PUT', data);

export const deleteSingleTerm = async (terms_uuid) => await invokeWebService(`profile/terms/${terms_uuid}`, 'DELETE');

export const downloadTerms = async (terms_uuid) =>
  await invokeWebService(`profile/terms/${terms_uuid}/download`, 'PUT', null, null, true);

export const getMoodboardsList = async (product_uuid) =>
  await invokeWebService(`profile/moodboards${product_uuid ? `?product_uuid=${product_uuid}` : ''}`, 'GET');

export const getSingleMoodboard = async (moodboard_uuid) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}`, 'GET');

export const createMoodboard = async (data) => await invokeWebService('profile/moodboards', 'POST', data);

export const editMoodboard = async (moodboard_uuid, data) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}`, 'PUT', data);

export const deleteMoodboard = async (moodboard_uuid) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}`, 'DELETE');

export const getMoodboardUsers = async (moodboard_uuid) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}/users`, 'GET');

export const shareMoodboard = async (moodboard_uuid, profile_uuid) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}/share/${profile_uuid}`, 'POST');

export const unshareMoodboard = async (moodboard_uuid, profile_uuid) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}/share/${profile_uuid}`, 'DELETE');

export const addProductToMoodboard = async (moodboard_uuid, product_uuid) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}/product/${product_uuid}`, 'POST');

export const addProductToMoodboards = async (moodboard_uuids, product_uuid) =>
  await invokeWebService(`profile/products/${product_uuid}/moodboards`, 'POST', { moodboards: moodboard_uuids });

export const removeProductFromMoodboard = async (moodboard_uuid, product_uuid) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}/product/${product_uuid}`, 'DELETE');

export const hideOrUnhideFromMoodboard = async (moodboard_uuid, product_uuid, data) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}/product/${product_uuid}`, 'PUT', data);

export const getSuggestedProducts = async (queryString) => {
  const url = `profile/buyer-briefs/suggested?${queryString}`;
  return await invokeWebService(url, 'GET', defaultGetParams);
};

export const getDesignersList = async (queryString) => {
  const url = `designers?${queryString}`;
  return await invokeWebService(url, 'GET');
};

export const removeFavouritedDesigner = async (designer_uuid) => {
  const url = `designers/${designer_uuid}/favourite`;
  return await invokeWebService(url, 'DELETE');
};

export const addFavouriteDesigner = async (designer_uuid) => {
  const url = `designers/${designer_uuid}/favourite`;
  return await invokeWebService(url, 'POST');
};

export const getBriefChatMessages = async (chat_uuid) =>
  await invokeWebService(`profile/briefs/chat/${chat_uuid}/messages`, 'GET');

export const postBriefChatMessage = async (chat_uuid, data) =>
  await invokeWebService(`profile/briefs/chat/${chat_uuid}/messages`, 'POST', data);

export const postFileMessage = async (brief_uuid, data) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/files`, 'POST', data);

export const getDesignerBriefsList = async (queryString) => {
  const url = `profile/designer-briefs?${queryString}`;
  return await invokeWebService(url, 'GET');
};

// DESIGNER BRIEF TAB

export const getDesignerSingleBrief = async (brief_uuid) => {
  const url = `profile/designer-briefs/${brief_uuid}`;
  return await invokeWebService(url, 'GET');
};

export const setDesignerBriefResponse = async (brief_uuid, data) => {
  const url = `profile/designer-briefs/${brief_uuid}/brief/response`;
  return await invokeWebService(url, 'POST', data);
};

// DESIGNER PROPOSAL TAB

export const getDesignerSingleProposal = async (brief_uuid) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/proposal`, 'GET');

export const createDesignerBriefProposal = async (brief_uuid, data) => {
  const url = `profile/designer-briefs/${brief_uuid}/proposal`;
  return await invokeWebService(url, 'POST', data);
};

export const updateDesignerBriefProposal = async (brief_uuid, data) => {
  const url = `profile/designer-briefs/${brief_uuid}/proposal`;
  return await invokeWebService(url, 'PUT', data);
};

export const deleteDesignerBriefProposal = async (brief_uuid) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/proposal`, 'DELETE');

export const convertProposalToProduct = async (proposal_uuid, data) =>
  await invokeWebService(`profile/convert-proposal/${proposal_uuid}`, 'POST', data);

export const cancelDesignerBriefProposal = async (brief_uuid) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/proposal/cancel`, 'POST');

// DESIGNER AGREEMENT TAB

export const getDesignerAgreement = async (brief_uuid) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/agreement`, 'GET');

export const acceptDesignerAgreement = async (brief_uuid, agreement_uuid, data) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/agreement/${agreement_uuid}/accept`, 'POST', data);

export const getDesignerSignUrl = async (brief_uuid) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/agreement/sign-request`, 'GET');

// DESIGNER FILES TAB

export const getBriefDesignerFiles = async (brief_uuid) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/files`, 'GET');

export const uploadBriefDesignerFiles = async (brief_uuid, data) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/files`, 'POST', data);

export const downloadBriefDesignerFiles = async (brief_uuid, data, isBlob) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/files`, 'PUT', data, null, isBlob);

export const deleteBriefDesignerFiles = async (brief_uuid, file_uuid) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/files/${file_uuid}`, 'DELETE');

//  BUYER PROPOSALS PAGE

export const getBuyersProposalList = async (brief_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}`, 'GET');

export const sendDesignerReminder = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/project/message`, 'POST', data); // data { message <string>}

// BUYER PROPOSAL DETAILS

export const getBuyerBriefProposalDetails = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}`, 'GET');

export const postBuyerBriefProposalFile = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/file`, 'POST', data);

// BUYER PROPOSAL TAB

export const getBuyerProposalDetails = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/proposal`, 'GET');

export const postBuyerProposalResponse = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(
    `profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/proposal/response`,
    'POST',
    data
  ); //data = {response <accepted | rejected>, rejected_reason?<string> }

// BUYER AGREEMENT TAB

export const getBuyerAgreementDetails = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/agreement`, 'GET');

export const acceptBuyerAgreement = async (brief_uuid, brief_designer_uuid, agreement_uuid) =>
  await invokeWebService(
    `profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/agreement/${agreement_uuid}/accept`,
    'POST'
  );

export const editBuyerAgreement = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/agreement`, 'PUT', data);

export const getBuyerSignUrl = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/agreement/sign-request`, 'GET');

// BUYER DESIGN FILES TAB

export const getBuyerDesignFiles = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/files`, 'GET');

export const downloadBuyerDesignFiles = async (brief_uuid, brief_designer_uuid, data, isBlob) =>
  await invokeWebService(
    `profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/files`,
    'PUT',
    data,
    null,
    isBlob
  ); // data = Array<file_uuid>

export const uploadBuyerDesignFiles = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/files`, 'POST', data); // data = Array<file_uuid>

export const deleteBuyerFiles = async (brief_uuid, brief_designer_uuid, file_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/files/${file_uuid}`, 'DELETE'); // data = Array<file_uuid>

// BUYER COMPLETE PROJECT

export const setBuyerCompleteProject = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/project/complete`, 'POST');

//profile/buyer-proposals/<uuid:product_uuid>/<uuid:brief_designer_uuid>/checkout

export const getCheckoutBrief = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/checkout`, 'POST');

export const getCheckoutSuccessBrief = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/checkout/success`, 'POST');

// BUYER REVIEW DESIGNER

export const postBuyerDesignerReview = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/project/review`, 'POST', data);

export const getProductSearchBarResults = async (queryString) => {
  const url = `products/search/nav?${queryString}`;
  return await invokeWebService(url, 'GET');
};

export const getProductSearchSite = async (queryString) => {
  const url = `products/search/site?${queryString}`;
  return await invokeWebService(url, 'GET');
};

export const getHelpConversations = async () => {
  const url = `profile/help/chat`;
  return await invokeWebService(url, 'GET');
};

export const createHelpConversation = async (data) => {
  const url = `profile/help/chat`;
  return await invokeWebService(url, 'POST', data);
};
export const deleteHelpConversation = async (chat_uuid) => {
  const url = `profile/help/chat/${chat_uuid}`;
  return await invokeWebService(url, 'DELETE');
};

export const editHelpConversation = async (chat_uuid, data) => {
  const url = `profile/help/chat/${chat_uuid}`;
  return await invokeWebService(url, 'PUT', data);
};

export const getHelpConversationMessages = async (chat_uuid) => {
  const url = `profile/help/chat/${chat_uuid}/messages`;
  return await invokeWebService(url, 'GET');
};

export const sendHelpConversationMessage = async (chat_uuid, data) => {
  const url = `profile/help/chat/${chat_uuid}/messages`;
  return await invokeWebService(url, 'POST', data);
};

export const sendHelpConversationAttachedFile = async (chat_uuid, data) => {
  const url = `profile/help/chat/${chat_uuid}/messages/files`;
  return await invokeWebService(url, 'POST', data);
};

export const deleteHelpConversationAttachedFile = async (chat_uuid, data) => {
  const url = `profile/help/chat/${chat_uuid}/messages/files`;
  return await invokeWebService(url, 'DELETE', data);
};

export const downloadHelpConversationAttachedFile = async (chat_uuid, data) => {
  const url = `profile/help/chat/${chat_uuid}/messages/files`;
  return await invokeWebService(url, 'PUT', data, null, true);
};

export const getStripeAccountUrl = async () => {
  const url = `account/stripe`;
  return await invokeWebService(url, 'GET');
};
export const getBuyersList = async (queryString) => {
  const url = `buyers?${queryString}`;
  return await invokeWebService(url, 'GET');
};
// PRODUCT FLOW
// BUYER

export const purchaseProduct = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/purchase`, 'POST', data);

export const getBuyersSingleProduct = async (product_uuid) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}`, 'GET');
// ------------- NEW BUYERS AGREEMENT ------------- //
export const createBuyersProductAgreement = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/agreement`, 'POST', data);

export const updateBuyersProductAgreement = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/agreement`, 'PUT', data);

export const getBuyerProductSignUrl = async (product_uuid) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/agreement/sign-request`, 'GET');
// ------------- END NEW ------------- //
export const getBuyersProductAgreement = async (product_uuid) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/agreement`, 'GET');

export const postBuyersProductAgreementAccept = async (product_uuid) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/agreement/accept`, 'POST');

export const getBuyersProductFiles = async (product_uuid) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/files`, 'GET');

export const postBuyersProductFiles = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/files`, 'POST', data);

export const downloadBuyersProductFiles = async (product_uuid, data, isBlob) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/files`, 'PUT', data, null, isBlob); // data = [file_uuid]

export const deleteBuyersProductFiles = async (product_uuid, file_uuid) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/files/${file_uuid}`, 'DELETE');

export const completePurchase = async (product_uuid) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/purchase/complete`, 'POST');

export const reviewProductPurchaseDesigner = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/purchase/review`, 'POST', data); // data = {rating: String(same as brief), details?: String}

export const getCheckoutProduct = async (product_uuid) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/checkout`, 'POST');

// PRODUCT CHECKOUT SUCCESS
export const getCheckoutSuccessProduct = async (product_uuid) =>
  await invokeWebService(`profile/buyer-products/${product_uuid}/checkout/success`, 'POST');

// PRODUCT FLOW
// DESIGNER
export const getDesignerProductNegotiations = async (product_uuid) =>
  await invokeWebService(`profile/designer-product-negotiations/${product_uuid}`, 'GET');

export const getDesignerProductTab = async (product_uuid, buyer_product_uuid) =>
  await invokeWebService(`profile/designer-product-negotiations/${product_uuid}/${buyer_product_uuid}`, 'GET');

export const getDesignerProductAgreement = async (product_uuid, buyer_product_uuid) =>
  await invokeWebService(
    `profile/designer-product-negotiations/${product_uuid}/${buyer_product_uuid}/agreement`,
    'GET'
  );

export const acceptDesignerProductAgreement = async (product_uuid, buyer_product_uuid) =>
  await invokeWebService(
    `profile/designer-product-negotiations/${product_uuid}/${buyer_product_uuid}/agreement`,
    'POST'
  );

export const getDesignerProductSignUrl = async (product_uuid, buyer_product_uuid) =>
  await invokeWebService(
    `profile/designer-product-negotiations/${product_uuid}/${buyer_product_uuid}/agreement/sign-request`,
    'GET'
  );

export const getDesignerProductFiles = async (product_uuid, buyer_product_uuid) =>
  await invokeWebService(`profile/designer-product-negotiations/${product_uuid}/${buyer_product_uuid}/files`, 'GET');

// either via chat or file uploader
export const uploadDesignerProductFiles = async (product_uuid, buyer_product_uuid, data) =>
  await invokeWebService(
    `profile/designer-product-negotiations/${product_uuid}/${buyer_product_uuid}/files`,
    'POST',
    data
  );

export const downloadDesignerProductFiles = async (product_uuid, buyer_product_uuid, data, isBlob) =>
  await invokeWebService(
    `profile/designer-product-negotiations/${product_uuid}/${buyer_product_uuid}/files`,
    'PUT',
    data,
    null,
    isBlob
  ); // data = [file_uuid]

export const deleteDesignerProductFiles = async (product_uuid, buyer_product_uuid, file_uuid) =>
  await invokeWebService(
    `profile/designer-product-negotiations/${product_uuid}/${buyer_product_uuid}/files/${file_uuid}`,
    'DELETE'
  );

// CHAT FOR PRODUCT
// FOR BOTH DESIGNER AND BUYER
export const getProductChatMessages = async (chat_uuid) =>
  await invokeWebService(`profile/products/chat/${chat_uuid}/messages`, 'GET');

export const postProductChatMessage = async (chat_uuid, data) =>
  await invokeWebService(`profile/products/chat/${chat_uuid}/messages`, 'POST', data);

// LOGIN DASHBOARDS

export const getBuyerLoginDashboardData = async () => await invokeWebService('buyer-dashboard', 'GET');

export const getDesignerLoginDashboardData = async () => await invokeWebService('designer-dashboard', 'GET');
// OPEN CALLS
// DESIGNER
export const getDesignerOpenCallsList = async (queryString) => {
  const url = `profile/designer-open-calls?${queryString}`;
  return await invokeWebService(url, 'GET');
};

export const getDesignerOpenCallsSingle = async (brief_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}`, 'GET');

export const getDesignerOpenCallsProposal = async (brief_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/proposal`, 'GET');

export const createDesignerOpenCallsProposal = async (brief_uuid, data) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/proposal`, 'POST', data);

export const updateDesignerOpenCallsProposal = async (brief_uuid, data) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/proposal`, 'PUT', data);

export const deleteDesignerOpenCallsProposal = async (brief_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/proposal`, 'DELETE');

export const cancelDesignerOpenCallsProposal = async (brief_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/proposal/cancel`, 'POST');

export const getDesignerOpenCallsAgreement = async (brief_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/agreement`, 'GET');

export const acceptDesignerOpenCallsAgreement = async (brief_uuid, agreement_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/agreement/${agreement_uuid}/accept`, 'POST');

export const getDesignerOpenCallsSignUrl = async (brief_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/agreement/sign-request`, 'GET');

export const getDesignerOpenCallsFiles = async (brief_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/files`, 'GET');

export const postDesignerOpenCallsFiles = async (brief_uuid, data) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/files`, 'POST', data);

export const downloadDesignerOpenCallsFiles = async (brief_uuid, data) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/files`, 'PUT', data, null, true);

export const deleteDesignerOpenCallsFiles = async (brief_uuid, file_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/files/${file_uuid}`, 'DELETE');

// OPEN CALLS
// BUYER
export const getBuyerOpenCallsList = async (queryString) => {
  const url = `profile/buyer-open-calls?${queryString}`;
  return await invokeWebService(url, 'GET');
};

export const createBuyerOpenCall = async (data) => await invokeWebService('profile/buyer-open-calls', 'POST', data);

export const updateBuyerOpenCall = async (brief_uuid, data) =>
  await invokeWebService(`profile/buyer-open-calls/${brief_uuid}`, 'PUT', data);

export const deleteBuyerOpenCall = async (brief_uuid, data) =>
  await invokeWebService(`profile/buyer-open-calls/${brief_uuid}`, 'DELETE');

export const cancelBuyerOpenCall = async (brief_uuid) =>
  await invokeWebService(`profile/buyer-open-calls/${brief_uuid}/cancel`, 'POST');

export const getBuyerOpenCallsProposalsList = async (brief_uuid) =>
  await invokeWebService(`profile/buyer-open-call-proposals/${brief_uuid}`, 'GET');

export const getBuyerOpenCallsSingle = async (brief_uuid) =>
  await invokeWebService(`profile/buyer-open-calls/${brief_uuid}`, 'GET');

export const getBuyerOpenCallsSingleDetails = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}`, 'GET');

export const getBuyerOpenCallsSingleProposal = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/proposal`, 'GET');

export const postBuyerOpenCallsSingleResponse = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/proposal/response`,
    'POST',
    data
  );

export const getBuyerOpenCallsSingleAgreement = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/agreement`, 'GET');

export const editBuyerOpenCallsSingleAgreement = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/agreement`,
    'PUT',
    data
  );

export const acceptBuyerOpenCallsSingleAgreement = async (brief_uuid, brief_designer_uuid, agreement_uuid) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/agreement/${agreement_uuid}/accept`,
    'POST'
  );

export const getBuyerOpenCallsSignUrl = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/agreement/sign-request`,
    'GET'
  );

export const getBuyerOpenCallsSingleFiles = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/files`, 'GET');

export const uploadBuyerOpenCallsSingleFiles = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(`profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/files`, 'POST', data);

export const downloadBuyerOpenCallsSingleFiles = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/files`,
    'PUT',
    data,
    null,
    true
  );

export const deleteBuyerOpenCallsSingleFiles = async (brief_uuid, brief_designer_uuid, file_uuid) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/files/${file_uuid}`,
    'DELETE'
  );

export const postBuyerOpenCallsSingleCheckout = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/checkout`, 'POST');

export const postBuyerOpenCallsSingleCheckoutSuccess = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/checkout/success`,
    'POST'
  );

export const postBuyerOpenCallsSingleComplete = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/project/complete`,
    'POST'
  );

export const postBuyerOpenCallsSingleReview = async (brief_uuid, brief_designer_uuid, data) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/project/review`,
    'POST',
    data
  );

export const postBuyerOpenCallsSingleMessage = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(
    `profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/project/message`,
    'POST'
  );

export const getBuyersOpenCalls = async (company_uuid, queryString) =>
  await invokeWebService(`profile/company-open-calls/${company_uuid}?${queryString}`, 'GET');

export const startNegotationsOpenCall = async (brief_uuid) =>
  await invokeWebService(`profile/designer-open-calls/${brief_uuid}/negotiate`, 'POST');

// PITCHES

// --- DESIGNERS MANAGE PITCH ---
export const getDesignerPitchesList = async (queryString) => {
  const url = `profile/designer-pitches?${queryString}`;
  return await invokeWebService(url, 'GET', defaultGetParams);
};

export const getDesignerSinglePitch = async (product_uuid) => {
  return await invokeWebService(`profile/designer-pitches/${product_uuid}`, 'GET');
};

export const createDesignerSinglePitch = async (data) => {
  return await invokeWebService(`profile/designer-pitches`, 'POST', data);
};

export const updateDesignerSinglePitch = async (product_uuid, data) => {
  return await invokeWebService(`profile/designer-pitches/${product_uuid}`, 'PUT', data);
};

export const deleteDesignerPitch = async (uuid) => await invokeWebService(`profile/designer-pitches/${uuid}`, 'DELETE');

// --- DESIGNERS SEND PITCH TO ANOTHER COMPANY ---

export const resendDesignerSinglePitch = async (product_uuid, data) => {
  return await invokeWebService(`profile/designer-pitches/${product_uuid}/resend`, 'POST', data); // data = {company_uuid}
};

// --- DESIGNERS PITCH BUYER NEGOTIATIONS ---

export const getDesignerPitchBuyersList = async (product_uuid) => {
  return await invokeWebService(`profile/designer-pitch-negotiations/${product_uuid}`, 'GET');
};

export const getDesignerPitchBuyerSingleDetails = async (product_uuid, buyer_product_uuid) => {
  return await invokeWebService(`profile/designer-pitch-negotiations/${product_uuid}/${buyer_product_uuid}`, 'GET');
};

export const getDesignerPitchBuyerSingleAgreement = async (product_uuid, buyer_product_uuid) => {
  return await invokeWebService(
    `profile/designer-pitch-negotiations/${product_uuid}/${buyer_product_uuid}/agreement`,
    'GET'
  );
};

export const acceptDesignerPitchBuyerSingleAgreement = async (product_uuid, buyer_product_uuid) => {
  return await invokeWebService(
    `profile/designer-pitch-negotiations/${product_uuid}/${buyer_product_uuid}/agreement`,
    'POST'
  );
};

export const getDesignerPitchSignUrl = async (product_uuid, buyer_product_uuid) =>
  await invokeWebService(
    `profile/designer-pitch-negotiations/${product_uuid}/${buyer_product_uuid}/agreement/sign-request`,
    'GET'
  );

export const getDesignerPitchBuyerSingleFiles = async (product_uuid, buyer_product_uuid) => {
  return await invokeWebService(
    `profile/designer-pitch-negotiations/${product_uuid}/${buyer_product_uuid}/files`,
    'GET'
  );
};

export const uploadDesignerPitchBuyerSingleFiles = async (product_uuid, buyer_product_uuid, data) => {
  return await invokeWebService(
    `profile/designer-pitch-negotiations/${product_uuid}/${buyer_product_uuid}/files`,
    'POST',
    data
  );
};

export const downloadDesignerPitchBuyerSingleFiles = async (product_uuid, buyer_product_uuid, data) => {
  return await invokeWebService(
    `profile/designer-pitch-negotiations/${product_uuid}/${buyer_product_uuid}/files`,
    'PUT',
    data,
    null,
    true
  ); // data: file_uuid[]
};

export const deleteDesignerPitchBuyerSingleFiles = async (product_uuid, buyer_product_uuid, file_uuid) => {
  return await invokeWebService(
    `profile/designer-pitch-negotiations/${product_uuid}/${buyer_product_uuid}/files/${file_uuid}`,
    'DELETE'
  ); // data: file_uuid[]
};

export const getDesignerPitchBuyerRecipients = async (product_uuid) => {
  return await invokeWebService(`profile/designer-pitches/${product_uuid}/recipients`, 'POST');
};

// BUYER PITCHES
export const getBuyersPitchesList = async (queryString) => {
  const url = `profile/buyer-pitches?${queryString}`;
  return await invokeWebService(url, 'GET', defaultGetParams);
};

export const getBuyersSinglePitch = async (product_uuid) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}`, 'GET');

export const postBuyersSinglePitch = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}`, 'POST', data);

// ------------- NEW BUYERS PITCH AGREEMET ------------- //
export const createBuyersPitchAgreement = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/agreement`, 'POST', data);

export const updateBuyersPitchAgreement = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/agreement`, 'PUT', data);

export const getBuyerPitchSignUrl = async (product_uuid) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/agreement/sign-request`, 'GET');
// ------------- END NEW ------------- //

export const getBuyersPitchAgreement = async (product_uuid) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/agreement`, 'GET');

export const postBuyersPitchAgreementAccept = async (product_uuid) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/agreement/accept`, 'POST');

export const getBuyersPitchFiles = async (product_uuid) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/files`, 'GET');

export const postBuyersPitchFiles = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/files`, 'POST', data);

export const deleteBuyersPitchFiles = async (product_uuid, file_uuid) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/files/${file_uuid}`, 'DELETE');

export const downloadBuyersPitchFiles = async (product_uuid, data, isBlob) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/files`, 'PUT', data, null, isBlob); // data = [file_uuid]

export const getCheckoutPitch = async (product_uuid) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/checkout`, 'POST');

export const getCheckoutSuccessPitch = async (product_uuid) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/checkout/success`, 'POST');

export const completePitchPurchase = async (product_uuid) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/purchase/complete`, 'POST');

export const reviewPitchPurchase = async (product_uuid, data) =>
  await invokeWebService(`profile/buyer-pitches/${product_uuid}/purchase/review`, 'POST', data); // data = {rating: String(same as brief), details?: String}

export const getRecommendedProducts = async (product_uuid) =>
  await invokeWebService(`products/suggested/${product_uuid}`, 'GET');

// LANDING PAGE CALLS
export const getFeaturedBuyers = async () => await invokeWebService('featured-buyers', 'GET');

export const getFeaturedDesigners = async () => await invokeWebService('featured-designers', 'GET');

export const getFavouriteProducts = async () => await invokeWebService('favourite-products', 'GET');

export const getFeaturedProduct = async () => await invokeWebService('featured-product', 'GET');

// Invite Designer

export const inviteDesigner = async (data) => await invokeWebService('buyer-invite-designer', 'POST', data);

//  Open Calls Visbility

export const hideOpenCall = async (uuid) =>
  await invokeWebService(`profile/buyer-open-calls/${uuid}/profile-view`, 'DELETE');

export const showOpenCall = async (uuid) =>
  await invokeWebService(`profile/buyer-open-calls/${uuid}/profile-view`, 'POST');

// Products Visibility

export const hideOrShowProduct = async (uuid, visible) =>
  await invokeWebService(`profile/designer-products/${uuid}/marketplace-view`, visible ? 'DELETE' : 'POST');

// Product Conversion

// Get all marketplace products to be converted
export const getDesignerMarketPlaceProducts = async (parsedFilter) =>
  await invokeWebService(`profile/designer-marketplace-products?${parsedFilter}`);

// Product to Pitch Conversion
export const createPitchConversion = async (product_uuid, data) =>
  await invokeWebService(`profile/designer-products/${product_uuid}/convert-to-pitch`, 'PUT', data); // data={company_uuid=company_uuid}

// Product to Brief Proposal Conversion
export const createBriefProposalConversion = async (brief_uuid, data) =>
  await invokeWebService(`profile/designer-briefs/${brief_uuid}/convert-to-proposal`, 'PUT', data); // data={product_uuid=product_uuid}

// Nav Bar Notifications

export const getNavBarNotificationsCount = async () => await invokeWebService('profile/notifications/count');

export const getNavBarNotificationsList = async () => await invokeWebService('profile/notifications');

export const markAsReadNavBarNotifications = async () => await invokeWebService('profile/notifications', 'POST');

export const deleteNotification = async (notification_uuid) =>
  await invokeWebService(`profile/notifications/${notification_uuid}`, 'DELETE');

//  Pitch Retract

export const retractSinglePitch = async (pitch_uuid) =>
  await invokeWebService(`profile/designer-retract-pitch/${pitch_uuid}`, 'POST');

export const retractPitchNegotiation = async (pitch_uuid, buyer_pitch_uuid) =>
  await invokeWebService(`profile/designer-retract-pitch/${pitch_uuid}/${buyer_pitch_uuid}`, 'POST');

// Moodboard external share

export const getShareMoodboardExternalLink = async (moodboard_uuid, user_uuid) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}/share-external/${user_uuid}`);

export const postShareMoodboardEmail = async (moodboard_uuid, user_uuid, data) =>
  await invokeWebService(`profile/moodboards/${moodboard_uuid}/share-external/${user_uuid}`, 'POST', data);

export const getMoodboardShared = async (moodboard_uuid, shared_uuid) =>
  await invokeWebService(`external-moodboards/${moodboard_uuid}/${shared_uuid}`);

export const postSaveMoodboardShared = async (moodboard_uuid, shared_uuid, data) =>
  await invokeWebService(`external-moodboards/${moodboard_uuid}/${shared_uuid}`, 'POST', data);

export const getProductImgSrc = async (data) => await invokeWebService(`products/images`, 'POST', data); // {file_uuids : [file_uuid]}

// Edit Active Briefs / OCs

export const putEditActiveBrief = async (brief_uuid, data) =>
  await invokeWebService(`profile/buyer-briefs/${brief_uuid}/edit-active`, 'PUT', data);

export const putEditActiveOpenCall = async (brief_uuid, data) =>
  await invokeWebService(`profile/buyer-open-calls/${brief_uuid}/edit-active`, 'PUT', data);

// Cancel Briefs

export const postCancelBriefNegotiation = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-proposals/${brief_uuid}/${brief_designer_uuid}/cancel`, 'POST');

export const postCancelOpenCallsNegotiation = async (brief_uuid, brief_designer_uuid) =>
  await invokeWebService(`profile/buyer-open-call-proposals/${brief_uuid}/${brief_designer_uuid}/cancel`, 'POST');
