import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import CustomRadioStandardGroup from '../CustomRadioStandardGroup';

/*
 * Controlled Input
 * Needs to be wrapped in a <FormProvider {...methods}> that receives all methods from useForm (react-hook-form)
 * name needs to match field used in useForm defaultValues
 */

const ControlledCustomRadioGroup = ({
  removeError,
  showBorder,
  name,
  options,
  labelPlacement,
  direction,
  defaultValue,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, value, ...rest }, fieldState }) => {
        return (
          <CustomRadioStandardGroup
            value={value}
            showBorder={showBorder}
            options={options}
            labelPlacement={labelPlacement}
            direction={direction}
            error={fieldState.error?.message}
            removeError={removeError}
            {...rest}
          />
        );
      }}
    />
  );
};

ControlledCustomRadioGroup.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    })
  ),
  labelPlacement: PropTypes.oneOf(['start', 'end']),
  direction: PropTypes.oneOf(['row', 'column']),
};

export default ControlledCustomRadioGroup;
