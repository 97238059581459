import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import BodyText from 'components/Typography/BodyText/BodyText';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';

import { CloseIconStyled, CustomNotificationStyled } from './CustomNotification.styles';

const CustomNotification = ({ text, linkText, onLinkClick, onClose }) => {
  return (
    <CustomNotificationStyled>
      <BodyText text={text} colour="white" />
      <Box display="flex" gap="10px">
        {linkText && (
          <Box mr={2} ml={4}>
            <CustomStandardLink text={linkText} onClick={onLinkClick} linkType="primary" colour="white" />
          </Box>
        )}
        <CloseIconStyled onClick={onClose} />
      </Box>
    </CustomNotificationStyled>
  );
};

CustomNotification.propTypes = {
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  onLinkClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
};

export default CustomNotification;
