import { Box, styled } from '@mui/material';

export const StyledSplideWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    width: '99%',
  },
  '& .splide__arrow': {
    backgroundColor: 'transparent',
  },
  '& .splide__arrow svg': {
    fill: theme.palette.grey[400],
    transition: 'all 150ms ease',
    [theme.breakpoints.down('md')]: {
      fill: theme.palette.grey[800],
    },
  },
  '& .splide__arrow:hover svg': {
    fill: theme.palette.grey[800],
  },

  '& .splide__arrow--next': {
    right: '-3rem',
    [theme.breakpoints.down('md')]: {
      right: '0.5rem',
    },
  },
  '& .splide__arrow--prev': {
    left: '-3rem',
    [theme.breakpoints.down('md')]: {
      left: '0.5rem',
    },
  },
}));
