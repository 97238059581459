import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import CustomAutoCompleteField from '../CustomAutoCompleteField';

/*
 * Controlled Input
 * Needs to be wrapped in a <FormProvider {...methods}> that receives all methods from useForm (react-hook-form)
 * name needs to match field used in useForm defaultValues
 */

const ControlledAutoCompleteField = ({ name, label, options, showSuccess }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState }) => {
        const isSuccess = showSuccess && !!(fieldState.isTouched && !fieldState.error && value?.length);
        return (
          <CustomAutoCompleteField
            options={options}
            label={label}
            onChange={(_e, newValue) => {
              onChange(newValue);
            }}
            onBlur={onBlur}
            value={value}
            success={isSuccess}
            error={fieldState.error?.message}
          />
        );
      }}
    />
  );
};

ControlledAutoCompleteField.propTypes = {
  name: PropTypes.string,
  showSuccess: PropTypes.bool,
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
};

export default ControlledAutoCompleteField;
