import { styled, Grid } from '@mui/material';

export const GridContainerStyled = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ImageContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    margin: `0 auto ${theme.spacing(2)}`,
  },
}));

export const AboutContainer = styled(Grid)(({ theme }) => ({
  padding: `0 ${theme.spacing(4)}`,
  [theme.breakpoints.down('sm')]: {
    padding: `0 ${theme.spacing(1)}`,
  },
}));

export const DetailsContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));
