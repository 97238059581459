import React, { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';

import { routes } from 'options/routes';

import { getCheckoutSuccessProduct } from 'api';

const BuyerSingleProductSuccess = (props) => {
  const navigate = useNavigate();
  const { product_uuid } = useParams();
  const [product, setMessage] = useOutletContext();

  useEffect(() => {
    const getSuccess = async () => {
      const res = await getCheckoutSuccessProduct(product_uuid);
      if (!res.success) {
        setMessage(res?.data?.error_message || 'There was an error while checking out.');
        return navigate(`${routes.productsDashboard}/${product_uuid}/product`);
      }
      setMessage('Thanks for your payment.');
      navigate(`${routes.productsDashboard}/${product_uuid}/product`);
    };
    getSuccess();
  }, [navigate, product_uuid, setMessage]);
  return (
    <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
      <Spinner align="center" />
    </Box>
  );
};

BuyerSingleProductSuccess.propTypes = {};

export default BuyerSingleProductSuccess;
