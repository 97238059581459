import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

import { useTheme } from '@mui/material';

import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';

import './rich-editor.css';

const CustomRichTextEditor = ({ onEditorChange, error, value }) => {
  const theme = useTheme();

  return (
    <div class={`editor-container ${error ? 'editor-container-error' : ''}`}>
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY}
        onEditorChange={onEditorChange}
        value={value}
        init={{
          height: 500,
          menubar: false,
          font_css: '/src/index.css',
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'link',
            'charmap',
            'anchor',
            'searchreplace',
            'visualblocks',
            'wordcount',
          ],
          toolbar:
            'undo redo | blocks fontsize | bold italic underline strikethrough | bullist numlist outdent indent | copy cut paste pastetext removeformat ',
          content_style: `@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap'); html { height: 100%; } body { font-family:Inter; font-weight: 400; background-color: ${theme.palette.background.pink}; height: 86%; opacity: 0.75; } body::before { color: ${theme.palette.text.secondary} !important;} `,
          resize: true,
          icons: 'material',
          skin: 'snow',
          content_css: 'material-classic',
          placeholder: 'Copy in your legal documents here, or attach a pdf using the button below',
        }}
      />
      <ErrorMessage variant="body1" colour="error.main">
        {error}
      </ErrorMessage>
    </div>
  );
};

CustomRichTextEditor.propTypes = {};

export default CustomRichTextEditor;
