export const ROYALTY_REVIEW_PERIODS = [
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Quarterly',
    value: 'Quarterly',
  },
  {
    label: '6 Monthly',
    value: '6 Monthly',
  },
];

export const BRIEF_TIMELINE_OPTIONS = [
  {
    label: '1 Week',
    value: '1 Week',
  },
  {
    label: '2 Weeks',
    value: '2 Weeks',
  },
  {
    label: '1 Month',
    value: '1 Month',
  },
  {
    label: '6 Weeks',
    value: '6 Weeks',
  },
  {
    label: '2 Months',
    value: '2 Months',
  },
];

export const BRIEF_UPFRONT_FEE_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];
