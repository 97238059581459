import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

const TwoFormFieldsRow = ({ children }) => {
  return (
    <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))" columnGap={2}>
      {children}
    </Box>
  );
};

TwoFormFieldsRow.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

export default TwoFormFieldsRow;
