import { useNavigate } from 'react-router';

import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import ExistingTermsList from 'components/ExistingTermsList/ExistingTermsList';
import AddIcon from '@mui/icons-material/Add';

import { EXISTING_TERMS_LIST_VARIANTS } from 'components/ExistingTermsList/ExistingTermsList.data';

import { existingTermsHeadingPage } from 'options/tableOptions';
import { routes } from 'options/routes';

export const BuyerTermsDashboard = () => {
  const navigate = useNavigate();

  return (
    <BuyerDashboardWrapper activeTab={routes.termsDashboard}>
      <HeaderAndMenu
        title="Terms"
        menuOptions={[{ buttonTitle: 'New Template', icon: <AddIcon />, onClick: () => navigate(routes.termsAdd) }]}
      />
      <ExistingTermsList variant={EXISTING_TERMS_LIST_VARIANTS.PAGE} headings={existingTermsHeadingPage} />
    </BuyerDashboardWrapper>
  );
};

export default BuyerTermsDashboard;
