import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { Box, Stack } from '@mui/material';
import CustomImage from 'components/CustomImage/CustomImage';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomAccordion from 'components/Accordion/CustomAccordion/CustomAccordion';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import PillTag from 'components/PillTag/PillTag';
import CustomAccordionHead from 'components/Accordion/CustomAccordionHead/CustomAccordionHead';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import FileUploadIconThumbnail from 'components/FileUploadIconThumbnail/FileUploadIconThumbnail';
import ModalConfirmPurchase from 'components/ModalConfirmPurchase/ModalConfirmPurchase';
import CustomRadioFieldStandard from 'components/Inputs/CustomRadioFieldStandard/CustomRadioFieldStandard';
import ProductsCarousel from 'components/ProductsCarousel/ProductsCarousel';
import Spinner from 'components/Spinner/Spinner';

import { routes } from 'options/routes';
import { userTypes } from 'options/userTypes';
import { LICENSE_TYPES } from 'options/briefOptions';
import { CURRENCY_MAP } from 'options/currency';

import { getUniqueList } from 'utils/getUniqueList';
import { CUSTOM_PROP_TYPES } from 'options/customPropTypes';
import { isAnyBuyer } from 'utils/isAnyBuyer';

import {
  AgreementTextRow,
  ContentContainer,
  ImagesContainer,
  ProductInfoContainer,
  ProductSingleContainer,
  RecommendedProductsWrapper,
} from './ProductSingle.styles';

import { getRecommendedProducts, purchaseProduct } from 'api';

const ProductSingle = ({
  data,
  userType,
  variant,
  onAccept,
  onReject,
  acceptText = 'Accept',
  rejectText = 'Reject',
  showButtons,
  showPurchase = true,
  setMessage,
  showRecommendedProducts,
  refetch,
  agreementTypeSelected,
  setAgreementTypeSelected
}) => {
  const [expand, setExpand] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
  });

  const navigate = useNavigate();

  const [openConfirmPurchaseModal, setOpenConfirmPurchaseModal] = useState(false);

  const {
    name,
    concept,
    main_image,
    additional_images,
    construction,
    colour_material_finish,
    width,
    height,
    depth,
    production_files,
    agreement_type,
    price,
    currency = 'gbp',
    registered,
    registered_info,
    designer_profile,

    company_name = '',
    company_uuid = '',

    // product properties
    product_category,
    material_tag,
    colour_tag,
    style_tag,
    space_tag,
    sustainability_tag,

    designer_name = '',

    product_uuid,
    is_negotiating,
    selected_agreement_type,
  } = data;

  const { data: recommendedProductsData, isLoading: isRecommendedProdsLoading } = useQuery(
    ['recommendedProducts', product_uuid],
    () => getRecommendedProducts(product_uuid),
    {
      enabled: !!showRecommendedProducts,
    }
  );

  const recommendedProducts = useMemo(
    () =>
      recommendedProductsData?.suggested?.map(({ image, product_uuid, name }) => ({
        label: name,
        imageUrl: image?.fileSrc ? image?.fileSrc : '',
        fileType: image?.fileType ? image?.fileType : 'url',
        onClick: () => navigate(`${routes.product}/${product_uuid}`),
      })) || [],
    [navigate, recommendedProductsData?.suggested]
  );

  const isBuyerProposalView = isAnyBuyer(userType) && variant === 'proposal_view';
  const isDesignerProposalView = userType === userTypes.designer && variant === 'proposal_view';
  const isDesignerProductView = userType === userTypes.designer && variant === 'product_view';

  const isProductView = variant === 'product_view' || variant === 'pitch_view';
  const isProposalView = variant === 'proposal_view';


  const handleAgreementOnChange = useCallback((event) => {
    setAgreementTypeSelected(event.target.value);
  }, []);

  const onChange = useCallback((id) => {
    setExpand((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const onMakePurchase = useCallback(async () => {
    setOpenConfirmPurchaseModal(true);
  }, []);

  const handlePurchaseConfirmation = useCallback(async () => {
    try {
      const res = await purchaseProduct(product_uuid, { agreement_type: agreementTypeSelected }); // DATA TO BE SENT TO BE
      if (!res.success) {
        throw new Error(res?.data?.error_message || 'There was a problem purchasing the product.');
      }
      if (refetch) {
        refetch();
      }
      navigate(`${routes.productsDashboard}/${product_uuid}/product`);
    } catch (err) {
      setOpenConfirmPurchaseModal(false);
      setMessage({ text: err.toString() });
    }
  }, [agreementTypeSelected, navigate, product_uuid, setMessage, refetch]);

  const onDesignerClick = useCallback(() => {
    let route =
      userType === userTypes.designer
        ? routes.profile
        : `${routes.designersIndex}/${designer_profile.split('/').at(-1)}`;

    if (variant === 'brief_proposal_view') {
      route = `${routes.buyersIndex}/${company_uuid}`;
    }

    navigate(route);
  }, [userType, designer_profile, navigate]);

  const productImages = useMemo(
    () => [...(main_image ? main_image : []), ...(additional_images ? additional_images : [])],
    [main_image, additional_images]
  );

  const productFilesUnique = useMemo(() => getUniqueList(production_files, 'fileType'), [production_files]);
  const selectedCurrency = CURRENCY_MAP[currency] || '£';

  const productPropertiesAccordions = useMemo(() => {
    return [
      { title: 'Category', tooltipText: '', text: '', value: product_category },
      { title: 'Materials', tooltipText: '', text: colour_material_finish, value: material_tag },
      { title: 'Space', tooltipText: '', text: '', value: space_tag },
      { title: 'Style', tooltipText: '', text: '', value: style_tag },
      { title: 'Colour', tooltipText: '', text: '', value: colour_tag },
      { title: 'Sustainability', tooltipText: '', text: '', value: sustainability_tag },
    ].filter((item) => item.value?.length);
  }, [colour_material_finish, colour_tag, material_tag, product_category, space_tag, style_tag, sustainability_tag]);

  return (
    <Stack>
      <ProductSingleContainer>
        <ImagesContainer>
          {productImages?.map(({ fileSrc, fileType }, index) => (
            <CustomImage imgSrc={fileSrc} fileType={fileType} key={`image_${index}`} />
          ))}
        </ImagesContainer>
        <ContentContainer>
          <Box>
            <Box display="flex" alignItems="self-end" flexWrap="wrap" columnGap="20px" rowGap="8px">
              <CustomTitle variant="h2" text={name} />
              <Box pb={1.5}>
                <CustomStandardLink onClick={onDesignerClick} text={designer_name || company_name} linkType="primary" />
              </Box>
            </Box>
            <Box my={2}>
              <BodyText text={concept} />
            </Box>
            {!isBuyerProposalView && !isDesignerProposalView && (
              <Box>
                <BodyText text="THIS PRODUCT IS AVAILABLE" />
                <Box my={2}>
                  <AgreementTextRow>
                    <Box style={{ display: 'flex', flex: 1 }}>
                      <CustomRadioFieldStandard
                        label={`${selectedCurrency}${price ? price : '--'}`}
                        value={LICENSE_TYPES.one_off}
                        labelPlacement="end"
                        disabled={
                          agreement_type === LICENSE_TYPES.royalty ||
                          userType === userTypes.designer ||
                          selected_agreement_type === LICENSE_TYPES.royalty ||
                          is_negotiating
                        }
                        checked={agreementTypeSelected === LICENSE_TYPES.one_off}
                        onChange={handleAgreementOnChange}
                      />
                    </Box>
                    <Box style={{ display: 'flex', flex: 2 }} mt={1}>
                      <BodyText text={`Purchase IP (exc. commission)`} />
                    </Box>
                  </AgreementTextRow>
                  <AgreementTextRow>
                    <Box style={{ display: 'flex', flex: 1 }}>
                      <CustomRadioFieldStandard
                        label={`%`}
                        value={LICENSE_TYPES.royalty}
                        labelPlacement="end"
                        disabled={
                          agreement_type === LICENSE_TYPES.one_off ||
                          userType === userTypes.designer ||
                          selected_agreement_type === LICENSE_TYPES.one_off ||
                          is_negotiating
                        }
                        checked={agreementTypeSelected === LICENSE_TYPES.royalty}
                        onChange={handleAgreementOnChange}
                      />
                    </Box>
                    <Box style={{ display: 'flex', flex: 2 }} mt={1}>
                      <BodyText text="Licence IP" />
                    </Box>
                  </AgreementTextRow>
                </Box>
                {showPurchase && !is_negotiating && (
                  <Box my={2}>
                    <CustomButton
                      text="Connect with designer"
                      fullWidth
                      onClick={onMakePurchase}
                      disabled={isDesignerProductView || isDesignerProposalView || !agreementTypeSelected}
                    />
                  </Box>
                )}
                {is_negotiating && (
                  <Box my={2}>
                    <CustomButton
                      text="View details"
                      fullWidth
                      onClick={() => navigate(`${routes.productsDashboard}/${product_uuid}/product`)}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Box>
          <Box>
            <CustomAccordion id="panel1" expanded={expand.panel1} onChange={onChange} index={0}>
              <CustomAccordionHead text="Product Information" expanded={expand.panel1} />
              <Box my={1}>
                <CustomFieldSet label="Construction" tooltipText="Construction Info" bold={false} titleFontSize="large">
                  <ProductInfoContainer
                    last={
                      (!width || !height || !depth) &&
                      (!productPropertiesAccordions || !productPropertiesAccordions.length)
                    }
                  >
                    <Box>
                      <BodyText text={construction} />
                    </Box>
                  </ProductInfoContainer>
                </CustomFieldSet>
                {width && height && depth && (
                  <CustomFieldSet label="Dimensions" tooltipText="Dimensions Info" bold={false} titleFontSize="large">
                    <ProductInfoContainer last={!productPropertiesAccordions || !productPropertiesAccordions.length}>
                      <Box>
                        <BodyText text={`${width} x ${height} x ${depth} (mm)`} />
                      </Box>
                    </ProductInfoContainer>
                  </CustomFieldSet>
                )}
                {productPropertiesAccordions?.map(({ title, tooltipText, text, value }, i) => {
                  const isLast = i === productPropertiesAccordions.length - 1;
                  return (
                    <CustomFieldSet
                      label={title}
                      tooltipText={tooltipText}
                      bold={false}
                      titleFontSize="large"
                      key={title}
                    >
                      <ProductInfoContainer last={isLast}>
                        {text && (
                          <Box>
                            <BodyText text={text} />
                          </Box>
                        )}
                        <Box>
                          {value?.map(({ label, value }, i) => (
                            <Box mr={1} display="inline-flex" mt={2} key={`pill_${value}_${i}`}>
                              <PillTag option={{ label: label, value: value }} />
                            </Box>
                          ))}
                        </Box>
                      </ProductInfoContainer>
                    </CustomFieldSet>
                  );
                })}
              </Box>
            </CustomAccordion>
            {!isBuyerProposalView && !isDesignerProposalView && (
              <>
                <CustomAccordion id="panel2" expanded={expand.panel2} onChange={onChange}>
                  <CustomAccordionHead text="What are you buying?" expanded={expand.panel2} />
                  <Box my={1}>
                    {/* {(agreement_type === LICENSE_TYPES.one_off || agreement_type === LICENSE_TYPES.both) && */}
                    {agreement_type === LICENSE_TYPES.one_off && !!productFilesUnique?.length ? (
                      <CustomFieldSet label="£- Purchase IP: One-Off fee." bold={false} titleFontSize="large">
                        <ProductInfoContainer last={registered !== 'yes'}>
                          <BodyText text="The Designer has chosen a One-Off payment to purchase the IP of this design." />
                          <BodyText text="The Buyer can continue to upload terms and conditions and negotiate and sign the agreement with the designer, on the platform." />
                          <BodyText text="You receive the downloadable files provided by the designer in the following file formats:" />
                          <Box display="flex" flexWrap="wrap" mt={1} gap="8px">
                            {productFilesUnique?.map(({ name, fileType }, i) => (
                              <FileUploadIconThumbnail fileType={fileType} key={`${name}_${i}`} />
                            ))}
                          </Box>
                        </ProductInfoContainer>
                      </CustomFieldSet>
                    ) : (
                      <></>
                    )}
                    {agreement_type === LICENSE_TYPES.royalty ? (
                      <CustomFieldSet label="%- License IP: Royalty." bold={false} titleFontSize="large">
                        <ProductInfoContainer last={registered !== 'yes'}>
                          <BodyText text="The Designer has chosen a License Royalty payment to purchase the IP of this design." />
                          <BodyText text="The Buyer can continue to upload terms and conditions and negotiate and sign the agreement with the designer, on the platform." />
                        </ProductInfoContainer>
                      </CustomFieldSet>
                    ) : (
                      <></>
                    )}
                    {agreement_type === LICENSE_TYPES.both ? (
                      <CustomFieldSet label="£- %." bold={false} titleFontSize="large">
                        <ProductInfoContainer last={registered !== 'yes'}>
                          <BodyText text="The Designer is open to either a One-Off payment or a License Royalty agreement for the rights to purchase or license their design." />
                          <BodyText text="The Buyer can choose an option and then continue to upload terms and conditions, negotiate and sign the agreement with the designer, on the platform." />
                        </ProductInfoContainer>
                      </CustomFieldSet>
                    ) : (
                      <></>
                    )}
                    {registered === 'yes' ? (
                      <CustomFieldSet label="This design is registered" bold={false}>
                        <BodyText text={`The Designer has indicated this product as registered - ${registered_info}`} />
                      </CustomFieldSet>
                    ) : (
                      <></>
                    )}
                  </Box>
                </CustomAccordion>
                <CustomAccordion id="panel3" expanded={expand.panel3} onChange={onChange}>
                  <CustomAccordionHead text="Agreements & Payments" expanded={expand.panel3} />
                  <Box my={1}>
                    <ProductInfoContainer last={true}>
                      <BodyText text="All agreements are between the Buyer and the Designer." />
                      <BodyText text="Agreements can be negotiated, agreed and signed on the platform." />
                      <BodyText text="Payments for any design purchases or licenses through the platform shall continue to be made through BuyDesign for the duration of your agreement." />
                    </ProductInfoContainer>
                  </Box>
                </CustomAccordion>
              </>
            )}
          </Box>
          {showButtons && (
            <Box mt={2} display="flex">
              <Box mr={1} width="100%">
                <CustomButton
                  text={acceptText}
                  // agreementTypeSelected is used by accept pitch call
                  onClick={() => onAccept({ agreementTypeSelected })}
                  colour="success"
                  fullWidth
                  disabled={isDesignerProposalView || (variant === 'pitch_view' && !agreementTypeSelected)}
                />
              </Box>
              <Box width="100%">
                <CustomButton
                  text={rejectText}
                  onClick={onReject}
                  colour="error"
                  fullWidth
                  disabled={isDesignerProposalView}
                />
              </Box>
            </Box>
          )}
        </ContentContainer>
        {openConfirmPurchaseModal && (
          <ModalConfirmPurchase
            open={openConfirmPurchaseModal}
            handleClose={() => setOpenConfirmPurchaseModal(false)}
            handlePurchaseConfirmation={handlePurchaseConfirmation}
            product_uuid={product_uuid}
          />
        )}
      </ProductSingleContainer>

      {showRecommendedProducts && !isRecommendedProdsLoading && recommendedProducts?.length ? (
        <RecommendedProductsWrapper mt={2} mb={3} pt={1}>
          {isRecommendedProdsLoading ? (
            <Box my={2}>
              <Spinner align="center" />
            </Box>
          ) : (
            <ProductsCarousel title="Recommended Products" data={recommendedProducts} variant={4} />
          )}
        </RecommendedProductsWrapper>
      ) : (
        <></>
      )}
    </Stack>
  );
};

ProductSingle.propTypes = {
  data: PropTypes.shape({
    main_image: PropTypes.arrayOf(CUSTOM_PROP_TYPES.IMAGE),
    additional_images: PropTypes.arrayOf(CUSTOM_PROP_TYPES.IMAGE),
    name: PropTypes.string,
    designer_name: PropTypes.string,
    concept: PropTypes.string,
    availability: PropTypes.bool,
    price: PropTypes.string,
    construction: PropTypes.string,
    colour_material_finish: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    depth: PropTypes.string,
    materials: PropTypes.arrayOf(PropTypes.string),
    colours: PropTypes.arrayOf(PropTypes.string),
    production_files: PropTypes.arrayOf(PropTypes.object),
    agreement_type: PropTypes.string,
    registered: PropTypes.string,
    registered_info: PropTypes.string,
    terms_accepted: PropTypes.bool,
  }),
  userType: CUSTOM_PROP_TYPES.USER_TYPE,
  variant: PropTypes.oneOf(['proposal_view', 'product_view', 'pitch_view, brief_proposal_view']),
};

export default React.memo(ProductSingle);
