import { createTheme } from '@mui/material';

// TODO: replace with correct colours
let theme = createTheme({
  palette: {
    primary: {
      main: '#1A1A1A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E6E6E6',
      contrastText: '#1A1A1A',
    },
    error: {
      main: '#FF2D00',
      contrastText: '#fff',
      alertText: ' #631B16',
    },
    warning: {
      main: '#FF7D0F',
      contrastText: '#fff',
    },
    success: {
      main: '#00B464',
      contrastText: '#fff',
    },
    common: {
      black: '#1A1A1A',
      peach: '#FDF4EC',
      border: '#F2DFCE',
    },
    white: {
      main: '#fff',
      contrastText: '#1A1A1A',
    },
    text: {
      primary: '#1A1A1A',
      secondary: '#999999',
      white: '#fff',
    },
    background: {
      lightgray: '#E6E6E6',
      gray: '#F2F2F2',
      darkgray: '#D9D9D9',
      pink: '#FDF4EC',
      lightblue: '#f4fafa',
      pinkOpaque: 'rgba(253, 244, 236, 0.95)',
      yellow: '#e9da9c',
      grayHover: 'rgba(0, 0, 0, 0.04)',
      error: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336;',
      staticMessage: '#fff',
    },
    notification: {
      main: '#FF2D00',
    },
  },
  shape: {
    borderRadius: 5,
  },
});

theme = createTheme(theme, {
  typography: {
    margin: 0,
    letterSpacing: 0,
    h1: {
      fontFamily: 'InterSemiBold',
      fontSize: 48,
    },
    h2: {
      fontFamily: 'InterSemiBold',
      fontSize: 33,
    },
    h3: {
      fontFamily: 'InterSemiBold',
      fontSize: 24,
    },
    h4: {
      fontFamily: 'InterRegular',
      fontSize: 24,
    },
    h5: {
      fontFamily: 'InterRegular',
      fontSize: 18,
    },
    h6: {
      fontFamily: 'InterSemiBold',
      fontSize: 16,
      fontWeight: 700,
    },
    body1: {
      fontFamily: 'InterRegular',
      fontSize: 16,
      color: theme.palette.text.primary,
    },
    body2: {
      fontFamily: 'InterRegular',
      fontSize: 16,
      color: theme.palette.text.secondary,
    },
    bodyWhite: {
      fontFamily: 'InterRegular',
      fontSize: 16,
      color: theme.palette.text.white,
    },
    bodyWhiteSmall: {
      fontFamily: 'InterRegular',
      fontSize: 14,
      color: theme.palette.text.white,
    },
    bodyWhiteBold: {
      fontFamily: 'InterSemiBold',
      fontSize: 16,
      color: theme.palette.text.white,
    },
    button: {
      textTransform: 'none',
    },
    input: {
      '&:hover': {
        border: '1px solid #F2DFCE',
      },
      '&:active': {
        border: '1px solid #F2DFCE',
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '25px',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            '&:hover': {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.background.gray,
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            '&:hover': {
              backgroundColor: theme.palette.background.gray,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            textDecoration: 'underline',
            textUnderlineOffset: '5px',
            color: theme.palette.text.primary,
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            border: `1px solid ${theme.palette.common.black}`,
            '&:hover': {
              border: `1px solid ${theme.palette.common.black}`,
              background: theme.palette.common.black,
              color: theme.palette.common.white,
            },
          },
        },
        {
          props: { variant: 'outlined', color: 'white' },
          style: {
            border: `1px solid ${theme.palette.common.white}`,
            color: theme.palette.common.white,
            '&:hover': {
              border: `1px solid ${theme.palette.common.white}`,
              background: theme.palette.common.white,
              color: theme.palette.common.black,
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.common.black,
          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(2),
        },
        arrow: {
          color: theme.palette.common.black,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 10,
          marginBottom: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} 12px`,
          margin: 0,
          '& .MuiRadio-root, .MuiCheckbox-root': {
            padding: 0,
            margin: `0 ${theme.spacing(1)} 0 0`,
            color: theme.palette.common.black,
          },
        },
        labelPlacementStart: {
          padding: `${theme.spacing(1)} 12px ${theme.spacing(1)} ${theme.spacing(2)}`,
          color: theme.palette.common.black,
          '& .MuiRadio-root, & .MuiCheckbox-root': {
            margin: `0 0 0 ${theme.spacing(1)}`,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
          borderRadius: '20px',
        },
      },
    },
    MuiTableHeadCell: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          padding: '14px 10px',
          borderBottom: 'none',
          '& .MuiSvgIcon-root': {
            height: '20px',
            width: '20px',
          },
        },
      },
    },
    MuiTableBodyCell: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          padding: '5px 10px',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: theme.palette.common.black,
          height: '36.5px',
          width: '36.5px',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          zIndex: 6000,
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          zIndex: '7000 !important',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: 1.3,
        },
      },
    },
  },
});

export default theme;
