import React from 'react';
import PropTypes from 'prop-types';

import { FormErrorContainer, FormErrorStyled, FormErrorTextStyled, InfoOutlinedIconStyled } from './FormError.styles';

const FormError = ({ errors, showTitle = true }) => {
  return (
    <FormErrorContainer>
      <div>
        <InfoOutlinedIconStyled />
      </div>
      <div>
        {showTitle && <FormErrorStyled variant="body1">There was a problem.</FormErrorStyled>}
        {errors.map((error, i) => (
          <FormErrorTextStyled key={`${error}_${i}`} variant="body1">
            {error}
          </FormErrorTextStyled>
        ))}
      </div>
    </FormErrorContainer>
  );
};

FormError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
};

export default FormError;
