import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const LoginFormContainer = styled('div')(() => ({
  flex: 1,
}));

export const ErrorContainer = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
}));

export const HelperContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between('md', 1250)]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down(500)]: {
    flexDirection: 'column',
  },
}));
