import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';
import queryString from 'query-string';

import ProductsAll from 'components/ProductsAll/ProductsAll';
import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import ProductsNotFound from 'components/ProductsNotFound/ProductsNotFound';

import { FILTER_OPTIONS } from 'options/filterOptions';
import { PRODUCTS_MENU_SPACES } from 'options/menuOptions';
import { PRODUCTS_PER_PAGE } from 'options/pagination';
import { routes } from 'options/routes';

import { addProductFilters } from 'utils/addFilters';
import { capitalize } from 'utils/capitalize';

import { mockCategories } from 'components/ProductsAll/ProductsAll.data';

import { ContentWrapper } from '../Products.styles';

import { useProductOptionsQueries } from 'hooks/useProductOptionsQueries';

import { getProducts } from 'api';

const ProductsSpaces = ({ userType, isStory }) => {
  const { sub_category } = useParams();

  const [filter, setFilter] = useState({});

  const [pagination, setPaginationOptions] = useState({ p: 1, l: PRODUCTS_PER_PAGE });
  const { p, l } = pagination;
  const navigate = useNavigate();

  const parsedFilter = useMemo(
    () => queryString.stringify({ ...filter, p: p, l, space: sub_category }),
    [filter, p, l, sub_category]
  );

  const { data = {}, isLoading: isProductsLoading } = useQuery(
    ['products', parsedFilter],
    async () => getProducts(parsedFilter),
    {
      enabled: !isStory,
      staleTime: 5 * 60 * 1000,
    }
  );

  const total = useMemo(() => data.total || null, [data.total]);
  const pageCount = useMemo(() => (data.total ? Math.ceil(data.total / PRODUCTS_PER_PAGE) : null), [data.total]);

  const onPageChange = useCallback((_e, newPage) => {
    setPaginationOptions((prevState) => ({ ...prevState, p: newPage }));
  }, []);

  const clearPagination = useCallback(() => {
    setPaginationOptions({ p: 1, l: PRODUCTS_PER_PAGE });
  }, []);

  useEffect(() => {
    clearPagination();
  }, [clearPagination, filter]);

  const { categories, materials, colour } = useProductOptionsQueries();

  const itemsList = useMemo(
    () => ({
      [FILTER_OPTIONS.CATEGORY]: isStory ? mockCategories : categories,
      [FILTER_OPTIONS.MATERIAL]: materials || [],
      [FILTER_OPTIONS.COLOUR]: colour || [],
    }),
    [categories, colour, isStory, materials]
  );

  const menuOptions = useMemo(() => {
    return PRODUCTS_MENU_SPACES.map((menuItem) => {
      return {
        ...menuItem,
        actions: addProductFilters(menuItem, itemsList, setFilter, filter),
      };
    });
  }, [filter, itemsList]);

  const title = capitalize(sub_category);

  const products = data?.products || [];

  let notFound;

  if (!isProductsLoading && !products?.length) {
    notFound = <ProductsNotFound message="No products have been added to the platform yet." />;

    if (!products?.length || !!Object.keys(filter).length) {
      notFound = (
        <ProductsNotFound
          message="No products match your filter criteria."
          btnText={'View All Products'}
          onClick={() => navigate(routes.products, { state: { clearFilter: true } })}
        />
      );
    }
  }

  const resetFilter = useCallback(() => {
    setFilter({});
  }, []);

  const hasFilter = !!queryString.stringify(filter);

  return (
    <PageWrapper userType={userType} showSecondaryFooter={false}>
      <ContentWrapper>
        <ProductsAll
          title={title}
          userType={userType}
          menuOptions={menuOptions}
          products={products}
          isLoading={isProductsLoading}
          notFound={notFound}
          pageCount={pageCount}
          total={total}
          page={p}
          onPageChange={onPageChange}
          resetFilter={resetFilter}
          hasFilter={hasFilter}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};

ProductsSpaces.propTypes = {};

export default ProductsSpaces;
