import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import SearchIcon from '@mui/icons-material/Search';
import FormStepper from 'components/FormStepper/FormStepper';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import { productProposalSteps } from './ProductProposalSteps';

import { routes } from 'options/routes';

import { getErrorMessage } from 'utils/getErrorMessage';
import { parseProductProposalFormData, parseRemoteProductProposalData } from 'utils/parseProductProposal';

import { formDefaultValues, formValidation, steps } from './ProductProposal.data';

import { convertProposalToProduct, createDesignerProduct, getDesignerProduct, updateDesignerProduct } from 'api';

const ProductProposal = ({ isStory, currency }) => {
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { product_uuid } = useParams();
  const location = useLocation();
  const proposal = location.state?.proposal;

  const [apiFormData, setApiFormData] = useState({});

  useEffect(() => {
    if (proposal) {
      setApiFormData({ ...formDefaultValues, ...proposal });
    }
  }, [proposal]);

  useEffect(() => {
    const getInitialValues = async () => {
      setIsFetching(true);
      const res = await getDesignerProduct(product_uuid);
      setIsFetching(false);
      if (res.success) {
        setApiFormData(parseRemoteProductProposalData(res.product));
      }
    };

    if (product_uuid) {
      getInitialValues();
    }
  }, [product_uuid]);

  const isProposalConversion = !!Object.keys(proposal || {}).length;

  const createOrUpdateDesignerProduct = useCallback(
    async (values, isLastStep) => {
      const parsedFormData = parseProductProposalFormData(values, isLastStep);

      const { product_uuid, proposal_uuid } = parsedFormData;

      return !product_uuid
        ? isProposalConversion
          ? await convertProposalToProduct(proposal_uuid, parsedFormData)
          : await createDesignerProduct(parsedFormData)
        : await updateDesignerProduct(product_uuid, parsedFormData);
    },
    [isProposalConversion]
  );

  const nextStepAction = useCallback(
    async (values, step) => {
      if (step > 0) {
        const isLastStep = steps.length - 1 === step;
        const res = await createOrUpdateDesignerProduct(values, isLastStep);
        if (!res.success) {
          throw new Error(getErrorMessage(res));
        }
        const { converted } = values;
        return isLastStep
          ? converted
            ? navigate(routes.productConversionThanks)
            : navigate(routes.productsAddThanks)
          : setApiFormData(parseRemoteProductProposalData(res.product));
      }
    },
    [createOrUpdateDesignerProduct, navigate]
  );

  const saveAndExit = useCallback(
    async (values) => {
      const res = await createOrUpdateDesignerProduct(values);

      if (!res.success) {
        throw new Error(getErrorMessage(res));
      }

      return navigate(routes.productsDashboard);
    },
    [createOrUpdateDesignerProduct, navigate]
  );

  return (
    <DesignerDashboardWrapper
      activeTab={routes.productsDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Products', onClick: () => navigate(routes.productsDashboard) }]}
          currentPage={'Add Product'}
        />
      }
    >
      <HeaderAndMenu
        title="New product proposal"
        menuOptions={[
          { buttonTitle: 'View all products', icon: <SearchIcon />, onClick: () => navigate(routes.productsDashboard) },
        ]}
      />
      <FormStepper
        steps={steps}
        stepsSwitcher={productProposalSteps}
        formValidation={formValidation}
        defaultFormValues={formDefaultValues}
        apiFormData={apiFormData}
        nextStepAction={nextStepAction}
        onSaveAndExit={saveAndExit}
        saveAndExitSteps={[1, 2]}
        saveAndExitRequiredFields={[null, ['name'], null]}
        lastStepAction={nextStepAction}
        isStory={isStory}
        stepsOptions={{ currency, isFetching }}
      />
    </DesignerDashboardWrapper>
  );
};

export default ProductProposal;
