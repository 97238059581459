import React from 'react';
import PropTypes from 'prop-types';

import { ArrowBackStyled, FormActionButtonStyled } from './FormActionButton.styles';

const FormActionButton = ({ text, onClick, variant, type = 'button', disabled, isBack }) => {
  return (
    <FormActionButtonStyled onClick={onClick} variant={variant} type={type} disabled={disabled}>
      {isBack && <ArrowBackStyled fontSize="small" disabled={disabled} />}
      {text}
    </FormActionButtonStyled>
  );
};

FormActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['text', 'outlined']).isRequired,
  type: PropTypes.oneOf(['submit', 'button']),
  disabled: PropTypes.bool,
};

export default FormActionButton;
