import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Box, Breadcrumbs } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import Spinner from 'components/Spinner/Spinner';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';
import EmptyListCreateNew from 'components/EmptyListCreateNew/EmptyListCreateNew';
import ModalCreateMoodboard from 'components/ModalCreateMoodboard/ModalCreateMoodboard';
import ProductList from 'components/ProductList/ProductList';

import { useAuthContext } from 'context/AuthContext';
import { useMessageContext } from 'context/MessageContext';

import { routes } from 'options/routes';

import { getMoodboardShared } from 'api';

const BuyerMoodboardShared = () => {
  const navigate = useNavigate();
  const [setMessage] = useMessageContext('');
  const { role } = useAuthContext();
  const { moodboard_uuid, share_uuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [moodboardDetails, setMoodboardDetails] = useState({});

  const [openCreateMoodboard, setOpenCreateMoodboard] = useState(false);

  const fetchMoodboard = useCallback(async () => {
    setIsLoading(true);
    const res = await getMoodboardShared(moodboard_uuid, share_uuid);
    if (res.success) {
      setMoodboardDetails(res?.moodboard);
    }
    setIsLoading(false);
  }, [moodboard_uuid, share_uuid]);

  useEffect(() => {
    fetchMoodboard();
  }, [fetchMoodboard]);

  const handleAddProductsClick = useCallback(() => {
    navigate(routes.products);
  }, [navigate]);

  return (
    <BuyerDashboardWrapper
      activeTab={routes.moodboardsDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Moodboards', onClick: () => navigate(routes.moodboardsDashboard) }]}
          currentPage={moodboardDetails?.name || ''}
        />
      }
    >
      <HeaderAndMenu
        title="Shared Moodboard"
        menuOptions={[
          {
            buttonTitle: 'Save Moodboard',
            icon: <SaveAltIcon fontSize="small" />,
            onClick: () => setOpenCreateMoodboard(true),
          },
        ]}
      />
      {isLoading && !moodboardDetails?.products?.length && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}
      {!isLoading && !moodboardDetails?.products?.length && (
        <>
          <Box my={4}>
            <CustomTitle text={moodboardDetails.name} variant="h3" />
            <Box mt={2}>
              <BodyText text={moodboardDetails.description} />
            </Box>
          </Box>
          <Box mt={2}>
            <EmptyListCreateNew
              title="No products"
              description="Add products to your moodboard"
              btnText="Add Products"
              onClick={handleAddProductsClick}
            />
          </Box>
        </>
      )}
      <>
        {!!moodboardDetails?.products?.length && (
          <>
            <Box my={4}>
              <CustomTitle text={moodboardDetails?.name} variant="h3" />
              <Box mt={2}>
                <BodyText text={moodboardDetails?.description} />
              </Box>
            </Box>
            <ProductList products={moodboardDetails?.products} userType={role} />{' '}
          </>
        )}
      </>
      {openCreateMoodboard && (
        <ModalCreateMoodboard
          open={openCreateMoodboard}
          handleClose={() => setOpenCreateMoodboard(false)}
          variant="save"
          moodboardDetails={moodboardDetails}
          moodboard_uuid={moodboardDetails?.moodboard_uuid}
          share_uuid={share_uuid}
          setMessage={setMessage}
        />
      )}
    </BuyerDashboardWrapper>
  );
};

export default BuyerMoodboardShared;
