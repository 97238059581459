import { styled } from '@mui/system';
import { Box, List } from '@mui/material';

export const PillWrapperStyled = styled(Box)(({ theme }) => ({
  maxWidth: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: theme.spacing(1),
}));

export const ListStyled = styled(List)(({ theme }) => ({
  maxHeight: '250px',
  overflowY: 'scroll',
  paddingRight: theme.spacing(1),
}));
