import { Box, styled } from '@mui/material';

export const DesignerLandingSectionWrapper = styled(Box)(({ theme }) => ({
  borderTop: '1px solid',
  marginTop: theme.spacing(4),
  padding: `${theme.spacing(4)} ${theme.spacing(0)}`,
}));

export const FeaturedBuyersContentContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));
