import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import CustomRadioStandardGroup from 'components/Inputs/CustomRadioStandardGroup/CustomRadioStandardGroup';
import ExistingBriefsList from 'components/ExistingBriefsList/ExistingBriefsList';

import { parseRemoteData } from 'pages/Dashboard/Buyer/Briefs/BuyerBriefNew/BuyerBriefNew.utils';

import { getBuyersBrief, getBuyerOpenCallsSingle } from 'api';

const NewBriefSetUp = ({ isStory, defaultValues, setIsLoading, isOpenCall }) => {
  const [optionSelected, setOptionSelected] = useState('new-brief');
  const location = useLocation();
  const designers = location?.state?.designers || [];

  const { reset } = useFormContext();

  const handleOptionChange = useCallback((_e, newValue) => {
    setOptionSelected(newValue);
  }, []);

  useEffect(() => {
    if (optionSelected === 'new-brief') {
      reset(defaultValues);
    }
  }, [defaultValues, optionSelected, reset]);

  const handleBriefSelection = async (brief_uuid) => {
    // api call with brief_uuid
    setIsLoading(true);
    const res = isOpenCall ? await getBuyerOpenCallsSingle(brief_uuid) : await getBuyersBrief(brief_uuid);
    setIsLoading(false);
    if (!res.success) {
      return;
    }
    reset(parseRemoteData(isOpenCall ? { ...res.open_call, designers } : { ...res.brief, designers }, true));
  };

  return (
    <Box>
      <Box>
        <CustomFieldSet
          label="How would you like to get started?"
          description={`Do you want to start a new ${isOpenCall ? 'Open Call' : 'brief'}, or use one you've created?`}
        >
          <CustomRadioStandardGroup
            options={[
              { label: isOpenCall ? 'New Open Call' : 'New Brief', value: 'new-brief' },
              { label: "Start from one you've used before", value: 'existing-brief' },
            ]}
            value={optionSelected}
            onChange={handleOptionChange}
            labelPlacement="end"
            direction="row"
          />
        </CustomFieldSet>
      </Box>
      <Box>
        {optionSelected === 'existing-brief' && (
          <ExistingBriefsList handleBriefSelection={handleBriefSelection} isOpenCall={isOpenCall} />
        )}
      </Box>
    </Box>
  );
};

NewBriefSetUp.propTypes = {
  isStory: PropTypes.bool,
};

export default NewBriefSetUp;
