import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import BuyerProductsList from 'components/BuyerProductsList/BuyerProductsList';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';

import { routes } from 'options/routes';
import { MOCK_PRODUCT_LIST_BUYER } from 'mockData/mockProduct';

export const BuyerProductsListDashboard = () => {
  return (
    <BuyerDashboardWrapper activeTab={routes.productsDashboard}>
      <HeaderAndMenu title="Products" />
      <BuyerProductsList
        isStory={false}
        mockData={MOCK_PRODUCT_LIST_BUYER}
        mockTotal={MOCK_PRODUCT_LIST_BUYER.length}
      />
    </BuyerDashboardWrapper>
  );
};

export default BuyerProductsListDashboard;
