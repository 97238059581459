import React, { useMemo } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { Box, Stack } from '@mui/material';
import ModeIcon from '@mui/icons-material/Mode';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import AddIcon from '@mui/icons-material/Add';

import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import CustomImage from 'components/CustomImage/CustomImage';
import BodyText from 'components/Typography/BodyText/BodyText';
import PillTag from 'components/PillTag/PillTag';
import SupportingDetailsText from '../Typography/SupportingDetailsText/SupportingDetailsText';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';

import { userTypes } from '../../options/userTypes';
import { parseRemoteOptions } from 'utils/parseRemoteOptions';

import { AboutContainer, DetailsContainer, GridContainerStyled, ImageContainer } from './ProfileDetails.styles';
import { routes } from '../../options/routes';
import { useAuthContext } from 'context/AuthContext';

const ProfileDetails = ({
  profileDetails,
  viewType,
  isFavourited,
  favouriteDesigner,
  unfavouriteDesigner,
  briefDesigner,
  proposeBuyer,
  canBriefDesigner,
  canCreateOpenCall,
}) => {
  const {
    first,
    last,
    bio,
    portrait,
    products,
    city,
    country,
    website,
    instagram,
    joined,
    linkedin,
    twitter,
    pinterest,
  } = profileDetails;

  const navigate = useNavigate();

  const { role } = useAuthContext();

  const menuOptions = useMemo(() => {
    const personalAddActionBtn =
      role === userTypes.designer
        ? { buttonTitle: 'Add Product', icon: <AddIcon />, onClick: () => navigate(routes.productsAdd) }
        : canCreateOpenCall
        ? { buttonTitle: 'Add Open Call', icon: <AddIcon />, onClick: () => navigate(routes.openCallsDashboardAdd) }
        : null;
    switch (viewType) {
      case userTypes.designer: {
        return [{ buttonTitle: 'Propose', onClick: () => proposeBuyer(), icon: <ModeIcon /> }];
      }
      case userTypes.buyer: {
        return [
          ...(canBriefDesigner
            ? [{ buttonTitle: `Brief ${first}`, onClick: () => briefDesigner(), icon: <ModeIcon /> }]
            : []),
          {
            buttonTitle: isFavourited ? 'Remove' : 'Favourite',
            onClick: () => (isFavourited ? unfavouriteDesigner() : favouriteDesigner()),
            icon: !isFavourited ? <FavoriteBorderIcon /> : <FavoriteIcon />,
          },
        ];
      }
      case userTypes.personal: {
        return [
          { buttonTitle: 'Edit Profile', onClick: () => navigate('/account'), icon: <ManageAccountsIcon /> },
          personalAddActionBtn,
        ].filter((i) => i);
      }
      default:
        return [];
    }
  }, [
    viewType,
    proposeBuyer,
    first,
    isFavourited,
    briefDesigner,
    unfavouriteDesigner,
    favouriteDesigner,
    navigate,
    canBriefDesigner,
    role,
    canCreateOpenCall,
  ]);

  const parsedProducts = parseRemoteOptions(products);

  return (
    <Box>
      <Box>
        <HeaderAndMenu title={`${first}${last ? ` ${last}` : ''}`} menuOptions={menuOptions} />
      </Box>
      <GridContainerStyled container paddingTop={5} paddingBottom={9}>
        <ImageContainer item md={2} lg={2}>
          <CustomImage imgSrc={portrait} isCircle aspect1to1 />
        </ImageContainer>
        <AboutContainer item paddingX={4} md={7} lg={7}>
          <BodyText text="About" bold />
          <Box mb={2}>
            <BodyText text={bio ? bio : '...'} />
          </Box>
          {parsedProducts && parsedProducts.length > 0 ? (
            <Box>
              <BodyText text="Products" bold />
              <Box mb={2}>
                {parsedProducts.map((product) => (
                  <PillTag option={product} styleOverrides={{ marginRight: 8, marginBottom: 8 }} key={product?.label} />
                ))}
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </AboutContainer>
        <DetailsContainer item md={3} lg={3}>
          <SupportingDetailsText text={`${city ? `${city}, ` : ''}${country}`} icon={<LocationOnIcon />} />
          <SupportingDetailsText text={joined} icon={<CalendarMonthIcon />} />
          {website && (
            <SupportingDetailsText
              text={
                <CustomStandardLink
                  text={website?.toLowerCase()}
                  onClick={() => window.open(website.includes('https://') ? website : `https://${website}`, '_blank')}
                  rel="noopener"
                  linkType="primary"
                  colour="gray"
                />
              }
              icon={<LanguageIcon />}
            />
          )}
          <Stack>
            {instagram && (
              <SupportingDetailsText
                text={
                  <CustomStandardLink
                    text={instagram?.toLowerCase()}
                    onClick={() => {
                      window.open(instagram?.includes('https://') ? instagram : `https://${instagram}`, '_blank');
                    }}
                    href={instagram}
                    rel="noopener"
                    linkType="primary"
                    colour="gray"
                  />
                }
                icon={<InstagramIcon />}
              />
            )}
            {linkedin && (
              <SupportingDetailsText
                text={
                  <CustomStandardLink
                    text={linkedin?.toLowerCase()}
                    onClick={() => {
                      window.open(linkedin?.includes('https://') ? linkedin : `https://${linkedin}`, '_blank');
                    }}
                    href={linkedin}
                    rel="noopener"
                    linkType="primary"
                    colour="gray"
                  />
                }
                icon={<LinkedInIcon />}
              />
            )}
            {twitter && (
              <SupportingDetailsText
                text={
                  <CustomStandardLink
                    text={twitter?.toLowerCase()}
                    onClick={() => {
                      window.open(twitter?.includes('https://') ? twitter : `https://${twitter}`, '_blank');
                    }}
                    href={twitter}
                    rel="noopener"
                    linkType="primary"
                    colour="gray"
                  />
                }
                icon={<TwitterIcon />}
              />
            )}
            {pinterest && (
              <SupportingDetailsText
                text={
                  <CustomStandardLink
                    text={pinterest?.toLowerCase()}
                    onClick={() => {
                      window.open(pinterest?.includes('https://') ? pinterest : `https://${pinterest}`, '_blank');
                    }}
                    href={pinterest}
                    rel="noopener"
                    linkType="primary"
                    colour="gray"
                  />
                }
                icon={<PinterestIcon />}
              />
            )}
          </Stack>
        </DetailsContainer>
      </GridContainerStyled>
    </Box>
  );
};

ProfileDetails.propTypes = {
  profileDetails: PropTypes.shape({
    uuid: PropTypes.string,
    first: PropTypes.string,
    last: PropTypes.string,
    bio: PropTypes.string,
    portrait: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        slug: PropTypes.string,
      })
    ),
    city: PropTypes.string,
    country: PropTypes.string,
    website: PropTypes.string,
    instagram: PropTypes.string,
    joined: PropTypes.string,
  }),
  viewType: PropTypes.oneOf([userTypes.personal, userTypes.buyer, userTypes.designer]),
  isFavourited: PropTypes.bool,
  unfavouriteDesigner: PropTypes.func,
  favouriteDesigner: PropTypes.func,
  briefDesigner: PropTypes.func,
};

export default ProfileDetails;
