export const BRIEF_STATUS_BUYER = {
  DRAFT: 'draft',
  DELETED: 'deleted',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  ARCHIVED: 'archived',
  CANCELLED: 'cancelled',
};

export const BRIEF_STATUS_BUYER_OPTIONS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
};

export const BRIEF_STATUS_DESIGNER = {
  REJECTED: 'rejected',
  PENDING: 'pending',
  COMPLETED: 'completed',
  ACCEPTED: 'accepted',
  BUYER_CANCELLED: 'buyer_cancelled',
  DESIGNER_CANCELLED: 'designer_cancelled',
};

export const OPEN_CALL_STATUS_DESIGNER = {
  REJECTED: 'rejected',
  // PENDING: 'pending',
  COMPLETED: 'completed',
  ACCEPTED: 'accepted',
  BUYER_CANCELLED: 'buyer_cancelled',
  DESIGNER_CANCELLED: 'designer_cancelled',
};

export const BRIEF_STATUS_DESIGNER_OPTIONS = {
  REJECTED: 'rejected',
  PENDING: 'pending',
  COMPLETED: 'completed',
  ACCEPTED: 'accepted',
};
