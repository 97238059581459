import { Box } from '@mui/system';
import ProductProposalPropertiesForm from 'components/ProductProposalPropertiesForm/ProductProposalPropertiesForm';
import ProductProposalDetailsForm from 'components/Forms/ProductProposalDetailsForm/ProductProposalDetailsForm';
import ControlledProductSingle from 'components/ProductSingle/ControlledProductSingle/ControlledProductSingle';
import Spinner from 'components/Spinner/Spinner';

import { userTypes } from 'options/userTypes';

export const productProposalSteps = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0: {
      return stepsOptions.isFetching ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <ProductProposalPropertiesForm isStory={isStory} />
      );
    }
    case 1: {
      return <ProductProposalDetailsForm currency={stepsOptions?.currency} />;
    }
    case 2: {
      return <ControlledProductSingle userType={userTypes.designer} variant="product_view" />;
    }
    default: {
      return <></>;
    }
  }
};
