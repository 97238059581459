import { styled } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CustomNotificationStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  alignItems: 'center',
  minWidth: '300px',
  maxWidth: '500px',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadius,
}));

export const CloseIconStyled = styled(CloseIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  cursor: 'pointer',
}));
