import React from 'react';
import PropTypes from 'prop-types';
import { DividerStyled } from './CustomDivider.styles';

const CustomDivider = ({ orientation, flexItem, variant, overrideStyles = {} }) => (
  <DividerStyled orientation={orientation} flexItem={flexItem} variant={variant} style={overrideStyles}></DividerStyled>
);

CustomDivider.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal']).isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  flexItem: PropTypes.bool,
};

export default CustomDivider;
