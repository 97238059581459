import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import CustomTextField from '../CustomTextField';

/*
 * Controlled Input
 * Needs to be wrapped in a <FormProvider {...methods}> that receives all methods from useForm (react-hook-form)
 * name needs to match field used in useForm defaultValues
 */

const ControlledTextField = ({
  minRows,
  maxRows,
  name,
  label,
  showSuccessAdornment,
  showSuccess,
  multiline,
  disabled,
  startAdornment,
  placeholder,
  fullWidth,
  conditionallyShowValue,
  removeError,
  shouldUnregister = false,
  type = 'text',
  defaultValue,
  autoComplete,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur, value }, fieldState }) => {
        const isSuccess = showSuccess && !!(fieldState.isTouched && !fieldState.error && value?.length);
        return (
          <CustomTextField
            label={label}
            type={type}
            onChange={onChange}
            onBlur={onBlur}
            value={conditionallyShowValue ? conditionallyShowValue(value) : value}
            error={fieldState.error?.message}
            startAdornment={startAdornment}
            showSuccessAdornment={showSuccessAdornment && isSuccess}
            success={isSuccess}
            multiline={multiline}
            minRows={minRows}
            maxRows={maxRows}
            disabled={disabled}
            placeholder={placeholder}
            fullWidth={fullWidth}
            removeError={removeError}
            autoComplete={autoComplete}
          />
        );
      }}
    />
  );
};

ControlledTextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  showSuccessAdornment: PropTypes.bool,
  showSuccess: PropTypes.bool,
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  conditionallyShowValue: PropTypes.func,
  removeError: PropTypes.bool,
};

export default ControlledTextField;
