import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, MenuItem } from '@mui/material';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import CheckIcon from '@mui/icons-material/Check';

import { ActionTitleStyled, MenuStyled, MenuContainer } from './ThreeDotMenu.styles';

const ThreeDotMenu = ({ actions, variant, onlyShowOnHover, hover, hoverPeachBg }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  if (!actions || !actions.length) {
    return null;
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getIcon = (variant) => {
    switch (variant) {
      case 'vertical':
        return <MoreVertIcon />;
      case 'arrow':
        return <KeyboardArrowDownIcon />;
      default:
        return <MoreHorizIcon />;
    }
  };

  return (
    <MenuContainer>
      <CustomIconButton
        hoverPeachBg={hoverPeachBg}
        icon={getIcon(variant)}
        onClick={(e) => {
          e.stopPropagation();
          handleClick(e);
        }}
      />

      <MenuStyled
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => {
          e.stopPropagation();
          handleClose();
        }}
        elevation={1}
      >
        {actions?.map((action) => {
          return (
            <MenuItem
              key={action.title}
              onClick={(e) => {
                e.stopPropagation();
                action.onClick();
              }}
            >
              {action.to ? (
                <CustomStandardLink text={action.title} onClick={() => navigate(action.to)} linkType="secondary" />
              ) : (
                <Box display="flex">
                  <ActionTitleStyled>{action.title}</ActionTitleStyled>
                  {action.selected && (
                    <Box ml={2}>
                      <CheckIcon />
                    </Box>
                  )}
                </Box>
              )}
            </MenuItem>
          );
        })}
      </MenuStyled>
    </MenuContainer>
  );
};

ThreeDotMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      to: PropTypes.string,
    })
  ).isRequired,
  variant: PropTypes.oneOf(['horizontal', 'vertical', 'arrow']).isRequired,
  optionSelected: PropTypes.string,
};

export default ThreeDotMenu;
