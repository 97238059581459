import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import PageWrapper from '../../components/Wrappers/PageWrapper/PageWrapper';
import Spinner from '../../components/Spinner/Spinner';
import AccountOptionsWrapper from '../../components/Wrappers/OptionsWrapper/AccountOptionsWrapper';
import CustomTitle from '../../components/Typography/CustomTitle/CustomTitle';
import CustomDivider from '../../components/Divider/CustomDivider';
import ProfileDetailsForm from '../../components/Forms/ProfileDetailsForm/ProfileDetailsForm';
import ChangePasswordForm from '../../components/Forms/ChangePasswordForm/ChangePasswordForm';
import BuyerCompanyDetailsForm from '../../components/Forms/BuyerCompanyDetailsForm/BuyerCompanyDetailsForm';
import EnterpriseBenefitsList from '../../components/EnterpriseBenefitsList/EnterpriseBenefitsList';
import BuyerAccountTypeForm from '../../components/Forms/BuyerAccountTypeForm/BuyerAccountTypeForm';
import BuyerManageStripe from 'components/BuyerManageStripe/BuyerManageStripe';
import BuyerAdminManageTeam from '../../components/BuyerAdminManageTeam/BuyerAdminManageTeam';
import { Wrapper, ColumnContainer, SectionContainer } from './Account.styles';

import { userTypes } from '../../options/userTypes';
import { accountTypes } from '../../options/accountTypes';
import { parseStringToDate } from '../../utils/parseDates';
import { parseRemoteOptions } from '../../utils/parseRemoteOptions';
import { CURRENCY_OPTIONS } from '../../options/currency';
import { ANNUAL_PRODUCTS_LAUNCH, NUMBER_OF_EMPLOYESS } from '../../options/selectOptions';

import { STRIPE_STATUS_BUYER } from 'options/stripeStatus';

import { useMessageContext } from 'context/MessageContext';

import { getAccountProfile, getAccountCompany, getAccountType, getAccountTeam, getAccountStripe } from '../../api';

const AccountBuyer = ({ userType, stripeStatus }) => {
  const [setMessage] = useMessageContext();
  const [apiLoading, setApiLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [companyData, setCompanyData] = useState({});
  const [accountType, setAccountType] = useState('');
  const [stripeAccountUrl, setStripeAccountUrl] = useState('');
  const [teamData, setTeamData] = useState([]);
  const [companyCategoriesData, setCompanyCategoriesData] = useState([]);
  const [productCategoriesData, setProductCategoriesData] = useState([]);
  const [spaceTagsData, setSpaceTagsData] = useState([]);
  const stripeActive = stripeStatus === STRIPE_STATUS_BUYER.ACTIVE;
  const stripeInactive = stripeStatus === STRIPE_STATUS_BUYER.INACTIVE;

  const getOptionsStates = (states) => {
    const { error, loading, companyCategories, productCategories, spaceTags } = states;
    if (error) setMessage(error);
    setCompanyCategoriesData(companyCategories);
    setProductCategoriesData(productCategories);
    setSpaceTagsData(spaceTags);
    setApiLoading(loading);
  };

  const parseAccountData = (profile, company) => {
    let profileInfo = {},
      email = '',
      companyInfo = {};

    if (profile) {
      profileInfo = {
        first_name: profile.first_name ? profile.first_name : '',
        last_name: profile.last_name ? profile.last_name : '',
        date_of_birth: profile.date_of_birth ? parseStringToDate(profile.date_of_birth) : null,
        phone_number: profile.phone_number ? profile.phone_number : '',
        gender: profile.gender ? profile.gender : '',
        position: profile.position ? profile.position : '',
        stripeStatus: profile.stripe_status,
      };

      email = profile.email ? profile.email : '';
    }

    if (company) {
      companyInfo = {
        logo: company.logo ? [company.logo] : [],
        jurisdiction: company.jurisdiction ? company.jurisdiction : '',
        company_number: company.company_number ? company.company_number : '',
        name: company.name ? company.name : '',
        address_1: company.address_1 ? company.address_1 : '',
        address_2: company.address_2 ? company.address_2 : '',
        address_3: company.address_3 ? company.address_3 : '',
        city: company.city ? company.city : '',
        county: company.county ? company.county : '',
        post_code: company.post_code ? company.post_code : '',
        country: company.country ? company.country : null,
        website: company.website ? company.website : '',
        about: company.about ? company.about : '',
        established_since: company.established_since ? parseStringToDate(company.established_since.toString()) : null,
        employee_count: company.employee_count
          ? [
              parseRemoteOptions(NUMBER_OF_EMPLOYESS)[
                NUMBER_OF_EMPLOYESS.findIndex((option) => option.slug === company.employee_count)
              ],
            ]
          : [],
        annual_product_launches: company.annual_product_launches
          ? [
            ANNUAL_PRODUCTS_LAUNCH.findIndex((option) => option.slug === company.annual_product_launches) !== -1 ? 
              parseRemoteOptions(ANNUAL_PRODUCTS_LAUNCH)[
                ANNUAL_PRODUCTS_LAUNCH.findIndex((option) => option.slug === company.annual_product_launches)
              ] : {value: company.annual_product_launches, label: company.annual_product_launches},
            ]
          : [],
        currency: company.currency
          ? [CURRENCY_OPTIONS[CURRENCY_OPTIONS.findIndex((option) => option.value === company.currency)]]
          : [],
        product_interests: company.product_interests ? parseRemoteOptions(company.product_interests) : [],
        buyer_spaces: company.buyer_spaces ? parseRemoteOptions(company.buyer_spaces) : [],
        buyer_categories: company.buyer_categories ? parseRemoteOptions(company.buyer_categories) : [],
        twitter: company.twitter ? company.twitter : '',
        instagram: company.instagram ? company.instagram : '',
        linkedin: company.linkedin ? company.linkedin : '',
        pinterest: company.pinterest ? company.pinterest : '',
      };
    }

    return { profileInfo, email, companyInfo };
  };

  const getAccountData = useCallback(async () => {
    const profileQuery = await getAccountProfile();
    const queries = [profileQuery];
    let companyQuery, accountTypeQuery, teamQuery, stripeQuery;

    if (userType === userTypes.buyerAdmin) {
      companyQuery = await getAccountCompany();
      if (!stripeInactive) {
        accountTypeQuery = await getAccountType();
      }
      if (stripeActive) {
        teamQuery = await getAccountTeam();
      }
      // stripeQuery = await getAccountStripe();
      queries.push(companyQuery);
      if (!stripeInactive) {
        queries.push(accountTypeQuery);
      }
      if (stripeActive) {
        queries.push(teamQuery);
      }
      // queries.push(stripeQuery);
    }
    if (queries.some((query) => !query.success)) {
      setMessage('There was an error when attempting to get some or all of the data.');
    }

    // Parse data
    let profile,
      company,
      account = '',
      team = [],
      stripeAccountUrl = '';
    if (profileQuery.success) profile = profileQuery.profile;

    if (userType === userTypes.buyerAdmin) {
      if (companyQuery.success) company = companyQuery.company;
      if (!stripeInactive && accountTypeQuery?.success) account = accountTypeQuery.account_type;
      // TODO: Comment in the below call when re-introducing buyer Stripe subscription requirements
      // if (account) {
      //   stripeQuery = await getAccountStripe();
      //   if (!stripeQuery.success) {
      //     setMessage('There was an error when attempting to get some or all of the data.');
      //   }
      //   if (stripeQuery.success) stripeAccountUrl = stripeQuery.url;
      // }
      if (teamQuery?.success && stripeActive) team = teamQuery.team;
    }
    const { profileInfo, email, companyInfo } = parseAccountData(profile, company);

    setProfileData(profileInfo);
    setUserEmail(email);
    setCompanyData(companyInfo);
    setAccountType(account);
    // TODO: Comment in the below call when re-introducing buyer Stripe subscription requirements
    // setStripeAccountUrl(stripeAccountUrl);
    if (stripeActive) {
      setTeamData(team);
    }
    setPageLoading(false);
  }, [userType]);

  useEffect(() => {
    getAccountData();
  }, [getAccountData]);

  const accountNotSetup =
    (profileData?.stripeStatus === STRIPE_STATUS_BUYER.INACTIVE || !profileData?.stripeStatus) && !accountType;

  return (
    <PageWrapper userType={userTypes.buyer} showSecondaryFooter={false}>
      <AccountOptionsWrapper getOptionsStates={getOptionsStates}>
        {(apiLoading || pageLoading) && <Spinner align="center" />}
        {!apiLoading && !pageLoading && (
          // <PageContainer>
          <>
            <CustomTitle text="Account" variant="h2" />
            <Wrapper>
              <ColumnContainer>
                <SectionContainer>
                  <ProfileDetailsForm
                    role={userType === userTypes.buyerAdmin ? userTypes.buyerAdmin : userTypes.buyerUser}
                    formData={profileData}
                    userEmail={userEmail}
                    setSuccess={setMessage}
                  />
                </SectionContainer>
              </ColumnContainer>
              <ColumnContainer>
                <SectionContainer>
                  <ChangePasswordForm setSuccess={setMessage} />
                </SectionContainer>
              </ColumnContainer>
            </Wrapper>
            {userType === userTypes.buyerAdmin && (
              <>
                <CustomDivider orientation="horizontal" />
                <Wrapper>
                  <ColumnContainer>
                    <SectionContainer>
                      <BuyerCompanyDetailsForm
                        companyOptions={companyCategoriesData}
                        productsOptions={productCategoriesData}
                        spacesOptions={spaceTagsData}
                        formData={companyData}
                        accountType={accountType}
                        setSuccess={setMessage}
                      />
                    </SectionContainer>
                  </ColumnContainer>
                  <ColumnContainer>
                    <SectionContainer>
                      <EnterpriseBenefitsList />
                    </SectionContainer>
                    {/* TODO: Comment in the below call when re-introducing buyer Stripe subscription requirements
                    <SectionContainer>
                      <BuyerAccountTypeForm
                        accountType={accountType}
                        setSuccess={setMessage}
                        setAccountType={setAccountType}
                        accountNotSetup={accountNotSetup}
                      />
                    </SectionContainer>
                    {!accountNotSetup && (
                      <SectionContainer>
                        <BuyerManageStripe stripeStatus={stripeStatus} accountUrl={stripeAccountUrl} />
                      </SectionContainer>
                    )}
                    */}
                    {stripeActive && accountType !== accountTypes.solo && (
                      <SectionContainer>
                        <BuyerAdminManageTeam
                          formData={teamData}
                          accountType={accountType ? accountType : ''}
                          setSuccess={setMessage}
                        />
                      </SectionContainer>
                    )}
                  </ColumnContainer>
                </Wrapper>
              </>
            )}
          </>
          // </PageContainer>
        )}
      </AccountOptionsWrapper>
    </PageWrapper>
  );
};

AccountBuyer.propTypes = {
  userType: PropTypes.oneOf([userTypes.buyerAdmin, userTypes.buyerUser]),
};

export default AccountBuyer;
