import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import CustomRichTextEditor from '../CustomRichTextEditor';

/*
 * Controlled Input
 * Needs to be wrapped in a <FormProvider {...methods}> that receives all methods from useForm (react-hook-form)
 * name needs to match field used in useForm defaultValues
 */

const ControlledRichTextEditor = ({ name }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState }) => {
        return (
          <>
            <CustomRichTextEditor
              onEditorChange={(value) => onChange(value)}
              error={fieldState.error?.message}
              value={value}
            />
          </>
        );
      }}
    />
  );
};

ControlledRichTextEditor.propTypes = {};

export default ControlledRichTextEditor;
