import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box } from '@mui/system';
import CustomModal from 'components/CustomModal/CustomModal';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';

import theme from 'theme/theme';
import { StyledActionButtonsContainer, StyledModalContentWrapper } from 'components/CustomModal/CustomModal.styles';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { yupObject, validation } from 'utils/validation';

const validationSchema = yupObject({
  ...validation.cancelItem,
});

const ModalCancelItem = ({ open, handleClose, handleSubmit, title, description }) => {
  const [isLoading, setIsLoading] = useState(false);

  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    defaultValues: {
      cancel: '',
    },
    resolver,
  });

  const handleSubmitAction = useCallback(async () => {
    setIsLoading(true);
    await handleSubmit(true);
    setIsLoading(false);
    handleClose();
  }, [handleClose, handleSubmit]);

  return (
    <CustomModal title={title} open={open} handleClose={handleClose}>
      {isLoading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <StyledModalContentWrapper>
          <Box mt={1}>
            <BodyText colour={theme.palette.grey[500]} text={description} />
          </Box>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmitAction)}>
              <Box mb={2}>
                <ControlledTextField name="cancel" label="cancel" />
              </Box>
              <StyledActionButtonsContainer mt={3}>
                <CustomButton variant="outlined" onClick={handleClose} text="Cancel" fullWidth />
                <CustomButton text="Confirm" buttonType="submit" fullWidth />
              </StyledActionButtonsContainer>
            </form>
          </FormProvider>
        </StyledModalContentWrapper>
      )}
    </CustomModal>
  );
};

ModalCancelItem.propTypes = {};

export default ModalCancelItem;
