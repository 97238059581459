import PropTypes from 'prop-types';

import { CustomCaptionTextStyled } from './CaptionText.styles';

const CaptionText = ({ text, bold }) => {
  return (
    <CustomCaptionTextStyled variant="body2" bold={bold}>
      {text}
    </CustomCaptionTextStyled>
  );
};

CaptionText.propTypes = {
  text: PropTypes.string,
};

export default CaptionText;
