import { styled, Box } from '@mui/material';

export const TopBarContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  borderBottom: '1px solid',
  display: 'flex',
  alignItems: 'center',
  '& > *:nth-of-type(1)': {
    marginRight: theme.spacing(2),
  },
}));
