import { useCallback, useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router';

import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Spinner from 'components/Spinner/Spinner';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import BuyersOpenCallsList from 'components/BuyersOpenCallsList/BuyersOpenCallsList';
import ModalDeleteItem from 'components/ModalDeleteItem/ModalDeleteItem';
import ModalCancelItem from 'components/ModalCancelItem/ModalCancelItem';

import { routes } from 'options/routes';

import { getErrorMessage } from 'utils/getErrorMessage';

import { useTableFilter } from 'hooks/useTableFilter';

import { useMessageContext } from 'context/MessageContext';

import { cancelBuyerOpenCall, deleteBuyerOpenCall, getBuyerOpenCallsList } from 'api';

export const BuyerOpenCallsDashboard = ({ isStory }) => {
  const navigate = useNavigate();
  const [setMessage] = useMessageContext();
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedBriefToCancel, setSelectedBriefToCancel] = useState('');
  const [selectedDeleteOpenCall, setSelectedDeleteOpenCall] = useState('');
  const { parsedFilter, ...tableMethods } = useTableFilter({ initialOrder: 'desc', initialOrderBy: 'deadline' });

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['buyerOpenCallsList', parsedFilter],
    () => getBuyerOpenCallsList(parsedFilter),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
    }
  );

  const buyersOpenCallsList = data?.open_calls || [];
  const totalCount = data?.total || 0;
  const can_create = data?.can_create;

  const onEdit = useCallback(
    (brief_uuid) => {
      navigate(`${routes.openCallsDashboardAdd}/${brief_uuid}`);
    },
    [navigate]
  );

  const deleteBrief = useCallback(async () => {
    setIsDeleting(true);
    const res = await deleteBuyerOpenCall(selectedDeleteOpenCall);
    setIsDeleting(false);
    if (!res.success) {
      return setMessage('There was an error deleting the open call.');
    }
    setMessage('Successfully deleted open call.');
    refetch();
  }, [refetch, selectedDeleteOpenCall, setMessage]);

  const onDelete = useCallback((brief_uuid) => {
    setSelectedDeleteOpenCall(brief_uuid);
  }, []);

  const onView = useCallback(
    (brief_uuid) => {
      navigate(`${routes.openCallsDashboard}/${brief_uuid}`);
    },
    [navigate]
  );

  const onDesignerClick = useCallback(
    (uuid) => {
      navigate(`${routes.designersIndex}/${uuid}`);
    },
    [navigate]
  );

  const handleCancelBrief = useCallback(async () => {
    try {
      const res = await cancelBuyerOpenCall(selectedBriefToCancel);

      if (!res.success) {
        throw new Error(getErrorMessage(res));
      }
      setMessage('You have successfully cancelled this open call.');
      refetch();
    } catch (err) {
      setMessage(err.toString());
    }
  }, [refetch, selectedBriefToCancel, setMessage]);

  const onCancelBrief = useCallback((brief_uuid) => {
    setSelectedBriefToCancel(brief_uuid);
  }, []);

  const loadingStatus = isLoading || isDeleting || isRefetching;
  const isLoadingBriefs = isLoading || isRefetching;
  const notFound = !isLoadingBriefs && !buyersOpenCallsList?.length;

  return (
    <BuyerDashboardWrapper activeTab={routes.openCallsDashboard}>
      <HeaderAndMenu
        title="Open Calls"
        menuOptions={[
          ...(can_create
            ? [
                {
                  buttonTitle: 'New Open Call',
                  icon: <AddIcon />,
                  onClick: () => navigate(routes.openCallsDashboardAdd),
                },
              ]
            : []),
        ]}
      />
      {loadingStatus ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <BuyersOpenCallsList
          buyersBriefList={buyersOpenCallsList}
          totalCount={totalCount}
          tableMethods={tableMethods}
          notFound={notFound}
          onEdit={onEdit}
          onView={onView}
          onDelete={onDelete}
          onCancelBrief={onCancelBrief}
          onDesignerClick={onDesignerClick}
          refetch={refetch}
        />
      )}
      {!!selectedDeleteOpenCall && (
        <ModalDeleteItem
          open={!!selectedDeleteOpenCall}
          handleClose={() => setSelectedDeleteOpenCall('')}
          onSubmit={() => deleteBrief()}
          callback={() => refetch()}
          variant="open call"
        />
      )}
      {!!selectedBriefToCancel && (
        <ModalCancelItem
          open={!!selectedBriefToCancel}
          handleClose={() => setSelectedBriefToCancel('')}
          handleSubmit={handleCancelBrief}
          title="Cancel Open Call"
          description="Cancelling this Open Call will cancel it for all designers who haven’t agreed terms yet. This action cannot be undone."
        />
      )}
    </BuyerDashboardWrapper>
  );
};

export default BuyerOpenCallsDashboard;
