import React, { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FileUploads from 'components/FileUploads/FileUploads';
import Spinner from 'components/Spinner/Spinner';

import { MOCK_DESIGN_FILES } from 'mockData/mockFiles';

import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { PROJECT_STATUS } from 'options/projectStatus';

import { routes } from 'options/routes';

import { downloadBuyersProductFiles, getBuyersProductFiles } from 'api';

const BuyerSingleProductFilesTab = () => {
  const { product_uuid } = useParams();
  const [product, setMessage, userType] = useOutletContext();
  const navigate = useNavigate();

  const { project_status, agreement_status } = product;

  useEffect(() => {
    const agreementStatuses = [AGREEMENT_STATUS.UPFRONT_PAID, AGREEMENT_STATUS.PAID];
    const isFilesDisabled = project_status === PROJECT_STATUS.REJECTED || !agreementStatuses.includes(agreement_status);

    if (isFilesDisabled) {
      navigate(`${routes.productsDashboard}/${product_uuid}/product`);
    }
  }, [product_uuid, navigate, project_status, agreement_status]);

  const { data, isLoading, isError, refetch } = useQuery(
    ['buyerProductFiles', product_uuid],
    () => getBuyersProductFiles(product_uuid),
    // return { design_files: MOCK_DESIGN_FILES };,
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const files = data?.design_files || {};

  return (
    <Box>
      {isLoading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <Box mt={2} width="100%">
          <FileUploads
            files={files}
            setMessage={setMessage}
            refetch={refetch}
            userType={userType}
            downloadFiles={(data) => downloadBuyersProductFiles(product_uuid, data, true)}
          />
        </Box>
      )}
    </Box>
  );
};

BuyerSingleProductFilesTab.propTypes = {};

export default BuyerSingleProductFilesTab;
