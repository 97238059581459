import { styled } from '@mui/system';

export const CustomImageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'imgSrc' && prop !== 'circle',
})(({ theme, circle, imgSrc }) => ({
  width: '100%',
  backgroundColor: imgSrc ? '' : theme.palette.background.gray,
  borderRadius: circle ? '100%' : theme.shape.borderRadius,
}));

export const ImageStyled = styled('img', {
  shouldForwardProp: (props) => props !== 'objectFit' && props !== 'aspect1to1',
})(({ theme, circle, objectFit, aspect1to1 }) => ({
  objectFit: objectFit ? objectFit : '',
  width: '100%',
  borderRadius: circle ? '100%' : theme.shape.borderRadius,
  aspectRatio: aspect1to1 ? '1 / 1' : '',
}));
