import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const GraphSectionTitle = ({ text }) => {
  return (
    <Typography
      variant="h6"
      sx={{
        textTransform: 'uppercase',
        maxWidth: 'max-content',
        fontSize: 16,
        letterSpacing: '1px',
      }}
    >
      {text}
    </Typography>
  );
};

GraphSectionTitle.propTypes = {};

export default GraphSectionTitle;
