import DoneIcon from '@mui/icons-material/Done';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';

export const PILL_STATUSES = {
  success: 'g',
  warning: 'a',
  error: 'r',
};

export const PILL_STATUS_MAP = {
  accepted: PILL_STATUSES.success,
  approved: PILL_STATUSES.success,
  pending_approval: PILL_STATUSES.warning,
  pending: PILL_STATUSES.warning,
  rejected: PILL_STATUSES.error,
  draft: PILL_STATUSES.warning,
  cancelled: PILL_STATUSES.error,
  completed: PILL_STATUSES.success,
  active: PILL_STATUSES.success,
  sold: PILL_STATUSES.success,
  negotiating: PILL_STATUSES.warning,
  deleted: PILL_STATUSES.error,
  archived: PILL_STATUSES.warning,
  yes: PILL_STATUSES.success,
  no: PILL_STATUSES.error,
  converted: PILL_STATUSES.success,
  can_convert: PILL_STATUSES.warning,
  buyer_cancelled: PILL_STATUSES.error,
  designer_cancelled: PILL_STATUSES.error,
};

export const PILL_STATUS_ICON = {
  approved: <DoneIcon />,
  accepted: <DoneIcon />,
  pending_approval: <AutorenewIcon />,
  pending: <AutorenewIcon />,
  rejected: <CloseIcon />,
  draft: <AutorenewIcon />,
  cancelled: <CloseIcon />,
  completed: <DoneIcon />,
  active: <DoneIcon />,
  sold: <DoneIcon />,
  negotiating: <AutorenewIcon />,
  deleted: <CloseIcon />,
  archived: <ArchiveIcon />,
  yes: <DoneIcon />,
  no: <CloseIcon />,
  converted: <DoneIcon />,
  can_convert: <AutorenewIcon />,
  buyer_cancelled: <CloseIcon />,
  designer_cancelled: <CloseIcon />,
};

const BASE_PILL_STATUS_TEXT = {
  approved: 'Approved',
  accepted: 'Accepted',
  pending_approval: 'Pending',
  rejected: 'Rejected',
  draft: 'Draft',
  cancelled: 'Cancelled',
  completed: 'Completed',
  active: 'Active',
  pending: 'Pending - accept or reject',
  sold: 'Sold',
  negotiating: 'Negotiating',
  deleted: 'Deleted',
  archived: 'Archived',
  yes: 'Yes',
  no: 'No',
  designer_cancelled: 'Proposal Cancelled',
  buyer_cancelled: 'Cancelled',
};

export const BUYER_BRIEF_PILL_STATUS_TEXT = {
  ...BASE_PILL_STATUS_TEXT,
  active: 'Active Brief',
  rejected: 'Proposal rejected',
  accepted: 'Proposal approved',
  pending_yes_proposal: 'Pending - accept or reject',
  pending_no_proposal: 'Pending action',
  pending_approval: 'Pending - accept or reject',
};

export const DESIGNER_BRIEF_PILL_STATUS_TEXT = {
  ...BASE_PILL_STATUS_TEXT,
  rejected: 'Brief rejected',
  accepted: 'Accepted brief',
};

export const BUYER_PRODUCT_PILL_STATUS_TEXT = {
  ...BASE_PILL_STATUS_TEXT,
  accepted: 'Negotiating with designer',
};

export const DESIGNER_PRODUCT_PILL_STATUS_TEXT = {
  ...BASE_PILL_STATUS_TEXT,
  approved: 'Uploaded to profile',
  negotiating: 'Negotiating with buyer',
  sold: 'Completed',
};

export const BUYER_PITCH_PILL_STATUS_TEXT = {
  ...BASE_PILL_STATUS_TEXT,
  accepted: 'Negotiating with designer',
};

export const DESIGNER_PITCH_PILL_STATUS_TEXT = {
  ...BASE_PILL_STATUS_TEXT,
  pending: 'Waiting for buyer',
  negotiating: 'Negotiating with buyer',
  rejected: 'Proposal rejected',
  sold: 'Completed',
  converted: 'Uploaded to profile',
  can_convert: 'Pitch returned',
};

export const BUYER_OPEN_CALL_PILL_STATUS_TEXT = {
  ...BASE_PILL_STATUS_TEXT,
  pending_yes_proposal: 'Pending - accept or reject',
  pending_no_proposal: 'Pending action',
  active: 'Active open call',
};

export const DESIGNER_OPEN_CALL_PILL_STATUS_TEXT = {
  ...BASE_PILL_STATUS_TEXT,
  rejected: 'Proposal rejected',
  pending: 'Waiting for Buyer',
  active: 'Negotiating with Buyer',
};

export const CHAT_PILL_STATUSES = {
  submitted: PILL_STATUSES.warning,
  accepted: PILL_STATUSES.success,
  declined: PILL_STATUSES.error,
};

export const CHAT_PILL_ICONS = {
  submitted: <AutorenewIcon />,
  accepted: <CheckIcon />,
  declined: <CloseIcon />,
};
