import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import BodyText from 'components/Typography/BodyText/BodyText';
import { ArrowIconStyled } from './Breadcrumbs.styles';

const Breadcrumbs = ({ links, currentPage }) => {
  return (
    <Box display="flex">
      {links.map((link) => {
        return (
          <Box display="flex" key={link.label}>
            <CustomStandardLink
              text={link.label}
              onClick={link.onClick}
              colour="gray"
              linkType="secondary"
              fontSize="small"
            />
            <Box mx={1} display="flex">
              <ArrowIconStyled fontSize="small" />
            </Box>
          </Box>
        );
      })}
      <BodyText text={currentPage} fontSize="small" />
    </Box>
  );
};

Breadcrumbs.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  currentPage: PropTypes.string,
};

export default Breadcrumbs;
