import { useState, useCallback, useEffect } from 'react';

import { Box, Link } from '@mui/material';
import PageWrapper from '../../components/Wrappers/PageWrapper/PageWrapper';
import Spinner from '../../components/Spinner/Spinner';
import AccountOptionsWrapper from '../../components/Wrappers/OptionsWrapper/AccountOptionsWrapper';
import CustomTitle from '../../components/Typography/CustomTitle/CustomTitle';
import CustomDivider from '../../components/Divider/CustomDivider';
import ProfileDetailsForm from '../../components/Forms/ProfileDetailsForm/ProfileDetailsForm';
import ChangePasswordForm from '../../components/Forms/ChangePasswordForm/ChangePasswordForm';
import DesignerCompanyDetailsForm from '../../components/Forms/DesignerCompanyDetailsForm/DesignerCompanyDetailsForm';
import DesignerManageStripe from '../../components/DesignerManageStripe/DesignerManageStripe';
import DesignerStripeNotSupported from '../../components/DesignerManageStripe/DesignerStripeNotSupported';
import FormError from 'components/Forms/FormError/FormError';

import { userTypes } from '../../options/userTypes';
import { CURRENCY_OPTIONS } from '../../options/currency';
import { STRIPE_STATUS_DESIGNER } from 'options/stripeStatus';
import { parseRemoteOptions } from '../../utils/parseRemoteOptions';
import { parseStringToDate } from '../../utils/parseDates';

import { useMessageContext } from 'context/MessageContext';

import { Wrapper, ColumnContainer, SectionContainer } from './Account.styles';

import { getAccountProfile } from '../../api';

const AccountDesigner = ({ baseProfile }) => {
  const [setMessage] = useMessageContext();
  const [apiLoading, setApiLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [userEmail, setUserEmail] = useState('');
  const [companyData, setCompanyData] = useState({});
  const [productCategoriesData, setProductCategoriesData] = useState([]);

  const { stripe_status, stripe_supported } = baseProfile;
  // const stripe_status = STRIPE_STATUS_DESIGNER.PENDING;

  const getOptionsStates = (states) => {
    const { error, loading, productCategories } = states;
    if (error) setMessage(error);
    setProductCategoriesData(productCategories);
    setApiLoading(loading);
  };

  const parseProfileData = (profile) => {
    const profileInfo = {
      first_name: profile.first_name ? profile.first_name : '',
      last_name: profile.last_name ? profile.last_name : '',
      date_of_birth: profile.date_of_birth ? parseStringToDate(profile.date_of_birth) : null,
      phone_number: profile.phone_number ? profile.phone_number : '',
      gender: profile.gender ? profile.gender : '',
    };

    const email = profile.email ? profile.email : '';

    const companyInfo = {
      portrait: profile.portrait ? [profile.portrait] : [],
      studio_name: profile.studio_name ? profile.studio_name : '',
      studio_address_1: profile.studio_address_1 ? profile.studio_address_1 : '',
      studio_address_2: profile.studio_address_2 ? profile.studio_address_2 : '',
      studio_address_3: profile.studio_address_3 ? profile.studio_address_3 : '',
      studio_city: profile.studio_city ? profile.studio_city : '',
      studio_county: profile.studio_county ? profile.studio_county : '',
      studio_post_code: profile.studio_post_code ? profile.studio_post_code : '',
      studio_country: profile.studio_country ? profile.studio_country : null,
      website: profile.website ? profile.website : '',
      bio: profile.bio ? profile.bio : '',
      currency: profile.currency
        ? [CURRENCY_OPTIONS[CURRENCY_OPTIONS.findIndex((option) => option.value === profile.currency)]]
        : [],
      designer_since: profile.designer_since ? parseStringToDate(profile.designer_since) : null,
      experience_detail: profile.experience_detail ? profile.experience_detail : '',
      study_location: profile.study_location ? profile.study_location : '',
      study_subject: profile.study_subject ? profile.study_subject : '',
      twitter: profile.twitter ? profile.twitter : '',
      instagram: profile.instagram ? profile.instagram : '',
      linkedin: profile.linkedin ? profile.linkedin : '',
      pinterest: profile.pinterest ? profile.pinterest : '',
      product_types: profile.product_types ? parseRemoteOptions(profile.product_types) : [],
    };

    return { profileInfo, email, companyInfo };
  };

  const getAccountProfileData = useCallback(async () => {
    const response = await getAccountProfile();
    if (!response.success) {
      setMessage(response.data.error_message);
      setPageLoading(false);
      return;
    }

    // Parse data
    const { profile } = response;
    const { profileInfo, email, companyInfo } = parseProfileData(profile);

    setProfileData(profileInfo);
    setUserEmail(email);
    setCompanyData(companyInfo);
    setPageLoading(false);
  }, []);

  useEffect(() => {
    getAccountProfileData();
  }, [getAccountProfileData]);

  const scrollToStripeSection = () => {
    const element = document.querySelector('#stripe-section');
    const yOffset = -100;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <PageWrapper userType={userTypes.designer} showSecondaryFooter={false}>
      <AccountOptionsWrapper getOptionsStates={getOptionsStates}>
        {(apiLoading || pageLoading) && <Spinner align="center" />}
        {!apiLoading && !pageLoading && (
          <>
            {/* <PageContainer> */}
            <CustomTitle text="Account" variant="h2" />
            {stripe_status !== STRIPE_STATUS_DESIGNER.CONNECTED && (
              <Box mt={2}>
                <FormError
                  errors={[
                    <>
                      You must complete your Stripe account before you can use the site. Complete it{' '}
                      <Link
                        onClick={scrollToStripeSection}
                        style={{ cursor: 'pointer', color: '#631B16' }}
                        underline="none"
                      >
                        here.
                      </Link>
                    </>,
                  ]}
                  showTitle={false}
                />
              </Box>
            )}
            <Wrapper sx={stripe_status !== STRIPE_STATUS_DESIGNER.CONNECTED ? { paddingTop: '0px' } : {}}>
              <ColumnContainer>
                <SectionContainer>
                  <ProfileDetailsForm
                    role={userTypes.designer}
                    formData={profileData}
                    userEmail={userEmail}
                    setSuccess={setMessage}
                  />
                </SectionContainer>
              </ColumnContainer>
              <ColumnContainer>
                <SectionContainer>
                  <ChangePasswordForm setSuccess={setMessage} />
                </SectionContainer>
              </ColumnContainer>
            </Wrapper>
            <CustomDivider orientation="horizontal" />
            <Wrapper>
              <ColumnContainer>
                <SectionContainer>
                  <DesignerCompanyDetailsForm
                    options={productCategoriesData}
                    formData={companyData}
                    setSuccess={setMessage}
                  />
                </SectionContainer>
              </ColumnContainer>
              <ColumnContainer>
                {stripe_supported ? (
                  <DesignerManageStripe stripe_status={stripe_status} setMessage={setMessage} />
                ) : (
                  <DesignerStripeNotSupported />
                )}
              </ColumnContainer>
            </Wrapper>

            {/* </PageContainer> */}
          </>
        )}
      </AccountOptionsWrapper>
    </PageWrapper>
  );
};

export default AccountDesigner;
