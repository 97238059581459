import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { routes } from 'options/routes';

import MoodboardProductImageOverlay from 'components/MoodboardProductImageOverlay/MoodboardProductImageOverlay';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ImageListItem, useTheme } from '@mui/material';
import PillTag from 'components/PillTag/PillTag';

import { PillWrapper, VisibilityOffOutlinedIconStyled } from './MoodboardProductImage.styles';

import { CUSTOM_PROP_TYPES } from 'options/customPropTypes';

const MoodboardProductImage = ({ productDetails, handleOnHiddenClick, handleOnDeleteClick, isOwner }) => {
  const theme = useTheme();
  const [isHovering, setIsHovering] = useState(false);
  const navigate = useNavigate();

  const { product_image, status, product_uuid, out_of_stock, name, designer_name } = productDetails;

  const fileType = (product_image && product_image.fileType) || 'image';
  const fileSrc = (product_image && product_image.fileSrc) || '';
  const productName = (product_image && product_image.name) || 'default-name';

  const src = fileType === 'url' ? atob(fileSrc) : fileSrc;

  const handleMouseOver = useCallback(() => {
    setIsHovering(true);
  }, []);

  const handleMouseOut = useCallback(() => {
    setIsHovering(false);
  }, []);

  const handleViewNavigation = () => {
    navigate(`/product/${product_uuid}`);
  }

  const isHidden = status !== 'visible';

  const overlayOptions = {
    isHidden,
    isOwner: isOwner,
    product_uuid,
  };

  const pillText = isHidden ? 'Hidden' : out_of_stock ? 'Out of Stock' : '';
  const styles = {
    imageOpacity: {
      opacity: isHidden ? 0.4 : 1
    }
  };
  return (
    <ImageListItem
      key={productName}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={handleViewNavigation}
    >
    {designer_name}
      {fileSrc && (
        <img
          src={src}
          alt={name}
          loading="lazy"
          style={{ borderRadius: theme.shape.borderRadius, maxHeight: '350px', ...styles.imageOpacity }}
        />
      )}
      {pillText && (
        <PillWrapper>
          <VisibilityOffOutlinedIconStyled />
        </PillWrapper>
      )}
      {overlayOptions && !!Object.keys(overlayOptions).length && (
        <MoodboardProductImageOverlay
          {...overlayOptions}
          show={isHovering}
          name={name}
          designer_name={designer_name}
          handleOnHiddenClick={(event) => handleOnHiddenClick(product_uuid, { is_hide: !isHidden }, event)}
          handleOnDeleteClick={(event) => { event.stopPropagation(); handleOnDeleteClick(product_uuid, event); }}
        />
      )}
    </ImageListItem>
  );
};

MoodboardProductImage.propTypes = {
  image: CUSTOM_PROP_TYPES.IMAGE,
  hidden: PropTypes.bool,
  owner: PropTypes.bool,
  product_uuid: PropTypes.string,
  out_of_stock: PropTypes.bool,
};

export default MoodboardProductImage;
