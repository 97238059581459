import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import { StyledFeaturedListsContainer } from './FeaturedList.styles';
import FeaturedProfileTile from 'components/FeaturedProfileTile/FeaturedProfileTile';
import Spinner from 'components/Spinner/Spinner';

const FeaturedList = ({ isLoading, title, data, isStory, aspect1to1 }) => {
  return (
    <Box>
      {title && (
        <Box mb={4}>
          <CustomTitle variant="h3" text={title} />
        </Box>
      )}
      {isLoading ? (
        <Spinner align={'center'} />
      ) : data && data.length > 0 ? (
        <StyledFeaturedListsContainer>
          {data.map((item) => {
            return (
              <FeaturedProfileTile
                aspect1to1={aspect1to1}
                isStory={isStory}
                key={`featuredItem-${item.label}`}
                {...item}
              />
            );
          })}
        </StyledFeaturedListsContainer>
      ) : null}
    </Box>
  );
};

FeaturedList.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      label: PropTypes.string,
      subtitle: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  aspect1to1: PropTypes.bool,
};

export default FeaturedList;
