import FileUploadIconThumbnail from 'components/FileUploadIconThumbnail/FileUploadIconThumbnail';

const AgreementFile = ({ download_url }) => {
  return (
    <a download href={download_url}>
      <FileUploadIconThumbnail fileType=".pdf" />
    </a>
  );
};
export default AgreementFile;
