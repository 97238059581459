import { LICENSE_TYPES } from 'options/briefOptions';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { yupObject, validation } from 'utils/validation';

const firstStepValidation = yupObject({
  ...validation.productProperties,
});

const secondStepValidation = yupObject({
  ...validation.designerAddProductFields,
  ...validation.pitchAdditionalFields,
});

export const formValidation = [firstStepValidation, secondStepValidation, yupObject({})];

export const formDefaultValues = {
  //step 1
  [OPTIONS_TYPE.product]: [],
  [OPTIONS_TYPE.material]: [],
  [OPTIONS_TYPE.space]: [],
  [OPTIONS_TYPE.style]: [],
  [OPTIONS_TYPE.colour]: [],
  [OPTIONS_TYPE.sustainability]: [],

  //step 2
  name: '',
  main_image: [],
  additional_images: [],
  concept: '',
  construction: '',
  colour_material_finish: '',
  width: '',
  height: '',
  depth: '',
  production_files: [],
  agreement_type: LICENSE_TYPES.one_off,
  price: '',
  registered: 'no',
  terms_accepted: true,
  product_uuid: '',

  buyers: [], // new field
};

export const steps = ['Properties', 'Product Details', 'Summary'];
