import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

const FormFieldSetAndInputWrapper = ({ children, isModal, mb = 2 }) => {
  return (
    <Box mb={mb} style={{ flexDirection: isModal ? 'column' : 'row' }}>
      {children}
    </Box>
  );
};

FormFieldSetAndInputWrapper.propTypes = {};

export default FormFieldSetAndInputWrapper;
