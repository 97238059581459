import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  StyledMegaMenuListContainer,
  StyledMegaMenuListTitleContainer,
  StyledMegaMenuSubCatContainer,
} from './MegaMenuList.styles';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';

const MegaMenuList = ({ title, options, handleNavigation }) => {
  return (
    <StyledMegaMenuListContainer>
      {title && (
        <StyledMegaMenuListTitleContainer onClick={() => handleNavigation(title.slug)}>
          <CustomStandardLink linkType="secondary" text={title.name} onClick={() => handleNavigation(title.slug)} />
        </StyledMegaMenuListTitleContainer>
      )}
      {/* {options?.length > 0 &&
        options.map((option, i) => {
          const last = i === options.length - 1;
          return (
            <Fragment key={`megaMenuOption-${option.slug}`}>
              <StyledMegaMenuSubCatContainer onClick={() => handleNavigation(title?.slug, option?.slug)} last={last}>
                <CustomStandardLink linkType="secondary" text={option.name} />
              </StyledMegaMenuSubCatContainer>
            </Fragment>
          );
        })} */}
    </StyledMegaMenuListContainer>
  );
};

MegaMenuList.propTypes = {
  title: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ),
  handleNavigation: PropTypes.func.isRequired,
};

export default MegaMenuList;
