import { Box, Link, styled } from '@mui/material';

export const LinkStyled = styled(Link)(() => ({
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const ImageWrapperStyled = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  aspectRatio: '1/1',
}));
