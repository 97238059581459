import { Box, styled } from '@mui/material';
import { MD_WIDTH } from 'styles/constants';

export const StyledProductsMegaMenuContainer = styled(Box)(({ theme }) => ({
  width: MD_WIDTH,
  margin: 'auto',
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(2),
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: 'unset',
  },

  '& > div': {
    flexBasis: `calc(16.66666% - ${theme.spacing(2)})`,
    [theme.breakpoints.down('md')]: {
      flexBasis: `calc(33.33333% - ${theme.spacing(2)})`,
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: `calc(50% - ${theme.spacing(1)})`,
    },
  },
}));

export const LoadingContainer = styled(Box)(({ theme }) => ({
  minHeight: '50vh',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto',
}));

export const CloseButtonWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: '0rem',
}));
