import React from 'react';
import { useNavigate } from 'react-router';

import { Box, ImageList, useMediaQuery } from '@mui/material';
import CustomImageListItem from 'components/CustomImageListItem/CustomImageListItem';
import StatusPill from 'components/StatusPill/StatusPill';

import { routes } from 'options/routes';
import { PILL_STATUS_ICON, PILL_STATUS_MAP, DESIGNER_PRODUCT_PILL_STATUS_TEXT } from 'options/pillStatusOptions';

import { getImageSrc } from 'utils/getImageSrc';

const ProductBuyerList = ({ productBuyersList, product_uuid }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(450));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const columns = isMobile ? 1 : isTablet ? 2 : 4;

  return (
    <Box>
      <ImageList cols={columns} variant="standard" gap={10}>
        {productBuyersList?.map((productBuyerDetails) => {
          const { company, buyer_product_uuid, project_status } = productBuyerDetails;

          let uuid, name, logo;
          const menuOptions = [
            {
              title: 'View details',
              onClick: () => navigate(`${routes.productsDashboard}/${product_uuid}/${buyer_product_uuid}/product`),
            },
          ];
          if (company) {
            uuid = company.uuid;
            name = company.name;
            logo = company.logo;
            menuOptions.push({ title: 'View profile', onClick: () => navigate(`${routes.buyersIndex}/${uuid}`) });
          }

          const overlayOptions = {
            title: name,
            description: '',
            menuOptions: [
              {
                title: 'View details',
                onClick: () => navigate(`${routes.productsDashboard}/${product_uuid}/${buyer_product_uuid}/product`),
              },
            ],
            statusData: [
              {
                label: 'Project Status',
                icon: (
                  <StatusPill
                    label={DESIGNER_PRODUCT_PILL_STATUS_TEXT[project_status]}
                    status={PILL_STATUS_MAP[project_status]}
                    icon={PILL_STATUS_ICON[project_status]}
                  />
                ),
              },
            ],
          };

          let fileSrc = '',
            imageName = '';
          if (logo) {
            const imageObj = getImageSrc(logo);
            fileSrc = imageObj.fileSrc;
            imageName = imageObj.name;
          }

          return (
            <CustomImageListItem
              key={buyer_product_uuid}
              src={fileSrc}
              name={imageName}
              overlayOptions={overlayOptions}
              onClick={() => navigate(`${routes.productsDashboard}/${product_uuid}/${buyer_product_uuid}/product`)}
            />
          );
        })}
      </ImageList>
    </Box>
  );
};

ProductBuyerList.propTypes = {};

export default ProductBuyerList;
