import { TitleStyled, StyledBox } from './EnterpriseBenefitsList.styles';
import PricingOptionsListItem from '../PricingOptionsTable/PricingOptionsList/PricingOptionsListItem';

import { EnterpriseBenefitsListData } from './EnterpriseBenefits.data';

const EnterpriseBenefitsList = ({ showTitle = true }) => {
  return (
    <>
      {showTitle &&
        <TitleStyled variant="body1" fontSize={24}>
          Account Benefits
        </TitleStyled>
      }
      <StyledBox mt={showTitle ? 0 : 3} mb={2}>
        {EnterpriseBenefitsListData.map((listItem) => (
          <PricingOptionsListItem listItem={listItem} key={listItem.label} />
        ))}
      </StyledBox>
    </>
  );
};

EnterpriseBenefitsList.propTypes = {};

export default EnterpriseBenefitsList;
