import { useEffect } from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import ProductSingle from 'components/ProductSingle/ProductSingle';
import Spinner from 'components/Spinner/Spinner';
import BodyText from 'components/Typography/BodyText/BodyText';

import { PROPOSAL_STATUS } from 'options/proposalStatus';
import { routes } from 'options/routes';

import { getIsProposalTabDisabled } from '../BuyerOpenCallsSingle.utils';
import { parseRemoteData } from 'pages/Dashboard/Designer/Briefs/DesignerBriefProposalNew/utils/DesignerBriefProposalNew.utils';

import { getBuyerOpenCallsSingleProposal } from 'api';

export const BuyerOpenCallProposalTab = ({ isStory }) => {
  const { brief_uuid, brief_designer_uuid } = useParams();
  const [briefData, setMessage, userType, onAccept, onReject] = useOutletContext();

  const navigate = useNavigate();

  const { data, isLoading, isRefetching } = useQuery(
    ['buyerOpenCallProposalDetails', brief_uuid, brief_designer_uuid],
    () => getBuyerOpenCallsSingleProposal(brief_uuid, brief_designer_uuid),

    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const project_status = briefData?.project_status || '';
  const proposal_status = briefData?.proposal_status || '';
  const brief_status = briefData?.status || '';
  const brief_designer = briefData?.brief_designer;
  const designer_name = `${brief_designer && brief_designer?.first_name} ${
    brief_designer && brief_designer?.last_name
  }`;
  const designer_profile = `designer-profile/${brief_designer && brief_designer?.uuid ? brief_designer?.uuid : ''}`;

  useEffect(() => {
    const proposalIsDisabled = getIsProposalTabDisabled(project_status, proposal_status, brief_status);

    if (proposalIsDisabled) {
      navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/open-call`);
    }
  }, [navigate, project_status, brief_uuid, brief_designer_uuid, proposal_status, brief_status]);

  const proposalDetails = (data && data.success && parseRemoteData(data?.proposal)) || {};

  const isRejected = proposalDetails?.status === PROPOSAL_STATUS.REJECTED;
  const isApproved = proposalDetails?.status === PROPOSAL_STATUS.APPROVED;

  let component = (
    <ProductSingle
      data={{ ...proposalDetails, designer_name, designer_profile }}
      userType={userType}
      variant="proposal_view"
      acceptText="Connect with designer"
      rejectText="Decline Proposal"
      onAccept={onAccept}
      onReject={onReject}
      showButtons={!isRejected && !isApproved}
      showPurchase={false}
    />
  );

  const noProposal = !proposalDetails || !Object.keys(proposalDetails).length;
  if (noProposal) {
    component = (
      <Box display="flex" justifyContent="center">
        <BodyText text="Waiting for the designer to submit their proposal" />
      </Box>
    );
  }

  return (
    <Box mt={2}>
      {isLoading || isRefetching ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <>{component}</>
      )}
    </Box>
  );
};

export default BuyerOpenCallProposalTab;
