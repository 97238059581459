import { Box, styled } from '@mui/material';

export const StyledFeaturedProfileTileContainer = styled(Box)(({ containerStyles }) => ({
  width: !containerStyles ? '100%' : '',
  height: !containerStyles ? '100%' : '',
  '&:hover': {
    cursor: 'pointer',
    '& h4': {
      textDecoration: 'underline',
    },
  },
}));
