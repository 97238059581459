import React from 'react';

import { StyledScrollableContainer } from './ScrollableContainer.styles';

const ScrollableContainer = ({ children, collapse }) => {
  return <StyledScrollableContainer collapse={collapse}>{children}</StyledScrollableContainer>;
};

ScrollableContainer.propTypes = {};

export default ScrollableContainer;
