import { LICENSE_TYPES } from 'options/briefOptions';

export const ADD_PRODUCT_AGREEMENT_TYPES = [
  {
    label: 'One off design fee',
    value: LICENSE_TYPES.one_off,
  },
  {
    label: 'Royalty license',
    value: LICENSE_TYPES.royalty,
  },
  {
    label: 'Both',
    value: LICENSE_TYPES.both,
  },
];

export const ADD_PRODUCT_DIMENSIONS = [
  {
    placeholder: 'Width (mm)',
    name: 'width',
    startAdornment: 'W',
  },
  {
    placeholder: 'Height (mm)',
    name: 'height',
    startAdornment: 'H',
  },
  {
    placeholder: 'Depth (mm)',
    name: 'depth',
    startAdornment: 'D',
  },
];

export const ADD_PRODUCT_REGISTERED_OPTIONS = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];
