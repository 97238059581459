import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import PillTag from 'components/PillTag/PillTag';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import Spinner from 'components/Spinner/Spinner';
import CustomTableCheckbox from 'components/Inputs/CustomTableCheckbox/CustomTableCheckbox';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';

import { DESIGNERS_LIST_HEADINGS } from 'options/tableOptions';
import { EXPERIENCE_OPTIONS } from 'options/findDesignerOptions';
import { routes } from 'options/routes';
import { DESIGNER_COUNTRIES } from 'options/countries';
import { OPTIONS_TYPE } from 'options/selectOptions';

import { addFilters } from 'utils/addFilters';
import { getUniqueList } from 'utils/getUniqueList';

import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';
import { TableStyled } from './DesignersList.styles';

import { getChildOptions } from 'api';

const DesignersList = ({
  designersList,
  totalCount,
  loadingStatus,
  tableMethods,
  designersSelected,
  setDesignersSelected,
  handleUnfollow,
  findDesigners,
}) => {
  const { order, orderBy, filter, setFilter, p, l, onPageChange, onRequestSort, onRowsPerPageChange } = tableMethods;
  const navigate = useNavigate();

  const handleCheckboxClick = (designer, isSelected, isHeading) => {
    if (isHeading) {
      return setDesignersSelected((prevState) => (!prevState.length ? [...designersList] : []));
    }

    setDesignersSelected((prevState) =>
      !isSelected ? [...prevState, designer] : prevState.filter((d) => d.uuid !== designer.uuid)
    );
  };

  const {
    data: { options: categories },
  } = useQuery(['options', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const filtersList = useMemo(
    () => ({
      categories: categories,
      location: [...DESIGNER_COUNTRIES],
      experience: [...EXPERIENCE_OPTIONS],
    }),
    [categories]
  );

  const headingsOnClick = useMemo(() => {
    return DESIGNERS_LIST_HEADINGS.map((menuItem) =>
      !menuItem.sortable
        ? {
            ...menuItem,
            menuOptions: addFilters(menuItem, filtersList, setFilter, filter),
          }
        : menuItem
    );
  }, [filter, filtersList, setFilter]);

  const hasFilter = Object.keys(filter).length;

  const noDesigner = (!designersList || designersList?.length === 0) && !loadingStatus;
  const showNoDesignerText = !hasFilter && noDesigner;

  return (
    <Box>
      {loadingStatus ? (
        <Box my={2}>
          <Spinner align={'center'} />
        </Box>
      ) : (
        <CustomTableWrapper
          noResultsTitle={showNoDesignerText ? 'No designers found' : ''}
          noResultsDescription={showNoDesignerText ? "You haven't added any designers yet. Let's get started!" : ''}
          btnText={showNoDesignerText ? 'Find designers' : ''}
          onBtnClick={showNoDesignerText ? findDesigners : null}
        >
          <TableStyled>
            <CustomTableHeader
              columnHeadings={headingsOnClick}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
              checkboxClick={() => handleCheckboxClick(null, null, true)}
              checked={designersList?.length && designersSelected.length === designersList.length}
              indeterminate={!!designersSelected.length && designersSelected.length !== designersList.length}
            />
            <CustomTableBody>
              {!designersList?.length && <NoFilteringMatch item="designers" cols={DESIGNERS_LIST_HEADINGS.length} />}

              {designersList?.map((designer, index) => {
                const { first_name, last_name, categories, location, experience, portrait, products, briefs, uuid } =
                  designer;
                const name = `${first_name} ${last_name}`;
                const threeDotMenuActions = [
                  { title: 'View Profile', onClick: () => navigate(`${routes.designersIndex}/${uuid}`) },
                  {
                    title: `Brief ${first_name}`,
                    onClick: () => navigate(routes.briefsAdd, { state: { designers: [designer] } }),
                  },
                  { title: `Unfollow ${first_name}`, onClick: () => handleUnfollow(uuid, first_name) },
                ];
                const isSelected = !!designersSelected.find((selected) => selected.uuid === uuid);
                return (
                  <CustomTableRow
                    key={`table_${name}_${index}`}
                    onClick={() => navigate(`${routes.designersIndex}/${uuid}`)}
                  >
                    <CustomTableBodyCell>
                      <CustomTableCheckbox
                        checked={isSelected}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCheckboxClick(designer, isSelected);
                        }}
                      />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <ItemInfo avatar={portrait || {}} label={name} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <PillsWrapperStyled>
                        {getUniqueList(categories, 'slug')?.map((category, i) => {
                          const { name, slug } = category;
                          return (
                            <Box key={`${category}_${i}`}>
                              <PillTag option={{ label: name, value: slug }} />
                            </Box>
                          );
                        })}
                      </PillsWrapperStyled>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <BodyText text={location} />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <BodyText text={`${experience} ${experience === '1' ? 'year' : 'years'}`} />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <BodyText text={products} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <BodyText text={briefs} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <ThreeDotMenu actions={threeDotMenuActions} variant="vertical" />
                      </Box>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </TableStyled>
          {!noDesigner && (
            <CustomTablePagination
              count={totalCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPage={l}
              rowsPerPageOptions={[5, 10, 15]}
              page={p}
            />
          )}
        </CustomTableWrapper>
      )}
    </Box>
  );
};

DesignersList.propTypes = {
  isStory: PropTypes.bool,
};

export default DesignersList;
