import { Box, styled } from '@mui/material';

export const StyledRadioWithTextFieldContainer = styled(Box)(({ theme, modal }) => ({
  display: 'flex',
  flexDirection: modal ? 'column' : '',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledTextFieldContainer = styled(Box, {
  shouldForwardProp: (props) => props !== 'radioOptions',
})(({ theme, radioOptions, modal }) => ({
  marginLeft: !!radioOptions?.length && !modal ? theme.spacing(2) : 0,

  width: modal ? '100%' : '30%',
  [theme.breakpoints.down('md')]: {
    marginLeft: theme.spacing(0),
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));
