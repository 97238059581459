import React from 'react';

import FooterLinksColumn from '../FooterLinksColumn/FooterLinksColumn';

import { FooterContainer, FooterLinksWrapper, FooterWrapper } from './SecondaryFooter.styles';
import { FOOTER_LINKS } from './Footer.data';

const SecondaryFooter = () => {
  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterLinksWrapper>
          {FOOTER_LINKS.map((options) => (
            <FooterLinksColumn options={options} key={options.title} />
          ))}
        </FooterLinksWrapper>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default SecondaryFooter;
