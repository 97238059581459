import React, { useEffect, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import MoodboardList from 'components/MoodboardList/MoodboardList';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import Spinner from 'components/Spinner/Spinner';
import ModalCreateMoodboard from 'components/ModalCreateMoodboard/ModalCreateMoodboard';

import { routes } from 'options/routes';

import { useMessageContext } from 'context/MessageContext';

import { getMoodboardsList } from 'api';

const BuyerMoodboardsDashboard = () => {
  const navigate = useNavigate();
  const [setMessage] = useMessageContext();

  const [openCreateMoodboardModal, setOpenCreateMoodboardModal] = useState(false);

  const {
    data: moodboardsListData,
    isLoading,
    refetch,
  } = useQuery(['moodboardsList'], () => getMoodboardsList(), {
    enabled: true,
    staleTime: 2 * 60 * 1000,
  });

  const handleClose = useCallback(
    (shouldRefetch) => {
      setOpenCreateMoodboardModal(false);
      if (shouldRefetch) {
        refetch();
      }
    },
    [refetch]
  );
  const moodboardsList = moodboardsListData?.moodboards;
  const notFound = !isLoading && !moodboardsList?.length;

  return (
    <BuyerDashboardWrapper activeTab={routes.moodboardsDashboard}>
      <HeaderAndMenu
        title="Moodboards"
        menuOptions={[
          {
            buttonTitle: 'Find products',
            icon: <SearchIcon fontSize="small" />,
            onClick: () => navigate(routes.products),
          },
          {
            buttonTitle: 'Create moodboard',
            icon: <AddIcon fontSize="small" />,
            onClick: () => setOpenCreateMoodboardModal(true),
          },
        ]}
      />
      <Box>
        {isLoading ? (
          <Box mt={2}>
            <Spinner align="center" />
          </Box>
        ) : (
          <MoodboardList
            moodboardProducts={moodboardsList}
            notFound={notFound}
            onCreateNewMoodboard={() => setOpenCreateMoodboardModal(true)}
            refetch={() => refetch()}
            setMessage={setMessage}
          />
        )}
      </Box>
      {openCreateMoodboardModal && (
        <ModalCreateMoodboard
          open={openCreateMoodboardModal}
          handleClose={(shouldRefetch) => handleClose(shouldRefetch)}
          setMessage={setMessage}
        />
      )}
    </BuyerDashboardWrapper>
  );
};

BuyerMoodboardsDashboard.propTypes = {};

export default BuyerMoodboardsDashboard;
