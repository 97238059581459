import { parseChoicesToArray } from 'utils/parseChoicesToArray';
import { parseRemoteOptions } from 'utils/parseRemoteOptions';

export const parseProductProposalFormData = (
  {
    main_image,
    registered,
    product_category,
    material_tag,
    space_tag,
    style_tag,
    colour_tag,
    sustainability_tag,
    buyers,
    ...rest
  },
  isLastStep
) => {
  return {
    main_image: main_image[0],
    registered: registered === 'yes',
    categories: parseChoicesToArray(product_category),
    materials: parseChoicesToArray(material_tag),
    spaces: parseChoicesToArray(space_tag),
    styles: parseChoicesToArray(style_tag),
    colours: parseChoicesToArray(colour_tag),
    sustainability_tags: parseChoicesToArray(sustainability_tag),
    ...(buyers && buyers?.length ? { company_uuid: buyers[0]?.company_uuid || buyers[0]?.uuid } : {}),
    ...(isLastStep ? { status: 'pending_approval' } : {}),
    ...rest,
  };
};

export const parseRemoteProductProposalData = ({
  main_image,
  registered,
  categories,
  materials,
  spaces,
  styles,
  colours,
  sustainability_tags,
  file_formats,
  id,
  width,
  height,
  depth,
  price,
  buyers,
  ...rest
}) => {
  return {
    main_image: main_image && Object.keys(main_image).length ? [main_image] : [],
    registered: registered ? 'yes' : 'no',

    product_category: parseRemoteOptions(categories) ?? [],
    material_tag: parseRemoteOptions(materials) ?? [],
    space_tag: parseRemoteOptions(spaces) ?? [],
    style_tag: parseRemoteOptions(styles) ?? [],
    colour_tag: parseRemoteOptions(colours) ?? [],
    sustainability_tag: parseRemoteOptions(sustainability_tags) ?? [],

    width: width ? String(width) : '',
    height: height ? String(height) : '',
    depth: depth ? String(depth) : '',
    price: price ? String(price) : '',

    buyers: buyers ?? [],
    ...rest,
  };
};
