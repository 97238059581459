// export const termsList = [
//   {
//     label: 'Payment terms',
//     description:
//       'As per the existing agreement between Designer Buy Design Management ltd. Royalties will be invoiced by and paid to Buy Design Management ltd. The Buyer agrees to register Buy Design Management ltd as a supplier for the licensed products and any varients or range extensions.',
//     tooltipText: 'Some info',
//     checkbox: { label: 'Agree', name: 'payment_terms_agreement' },
//   },
//   {
//     label: 'Options on new designs',
//     description:
//       'The Buyer agrees that any new designs or range extensions, and or variations (inc size, colour or material) to accompany the licensed product listed here will result in a new brief and be comunicated to the designer and BuyDesign management ltd.',
//     tooltipText: 'Some info',
//     checkbox: { label: 'Agree', name: 'options_on_design_agreement' },
//   },
//   {
//     label: 'Any reference images used',
//     description:
//       'BuyDesign does not hold any liability for any images used as a ‘reference’ or ‘inspiration’ and shared with the designer.',
//     tooltipText: 'Some info',
//     checkbox: { label: 'Agree', name: 'reference_images_agreement' },
//   },
// ];

export const termsList = [];
