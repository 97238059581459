import React from 'react';
import PropTypes from 'prop-types';
import { IconWrapper, LinkStyled } from './CustomStandardLink.styles';

const CustomStandardLink = ({
  text,
  onClick,
  linkType,
  icon,
  colour = 'primary',
  rel,
  fontSize = 'medium',
  href,
  target = '_self',
  download,
}) => {
  const underline = linkType === 'primary' ? 'always' : 'hover';
  return (
    <LinkStyled
      component="a"
      variant="body1"
      href={href}
      target={target}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          onClick(e);
        }
      }}
      linkType={linkType}
      underline={underline}
      colour={colour}
      rel={rel}
      fontSize={fontSize}
      download={download}
    >
      {text}
      <IconWrapper>{icon}</IconWrapper>
    </LinkStyled>
  );
};

CustomStandardLink.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  linkType: PropTypes.oneOf(['primary', 'secondary']).isRequired,
  icon: PropTypes.element,
  colour: PropTypes.oneOf(['primary', 'gray', 'white']),
  rel: PropTypes.string,
};

export default CustomStandardLink;
