export const CATEGORIES_OPTIONS = [
  {
    label: 'Furniture',
    value: 'furniture',
  },
  {
    label: 'Lighting',
    value: 'lighting',
  },
  {
    label: 'Decor and Home Accessories',
    value: 'decor-and-home-accessories',
  },
  {
    label: 'Cook and Dine',
    value: 'cook-and-dine',
  },
  {
    label: 'Lifestyle Products',
    value: 'lifestyle-products',
  },
  {
    label: 'Pattern and Illustration',
    value: 'pattern-and-illustration',
  },
];

export const BUYER_CATEGORIES_OPTIONS = [
  {
    label: 'Brand',
    value: 'brand',
  },
  {
    label: 'Retailer',
    value: 'retailer',
  },
  {
    label: 'Manufacturer',
    value: 'manufacturer',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const EXPERIENCE_OPTIONS = [
  {
    label: '1-3',
    value: '1-3',
  },
  {
    label: '3-5',
    value: '3-5',
  },
  {
    label: '5-10',
    value: '5-10',
  },
  {
    label: '10+',
    value: '10+',
  },
];
