import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import NewBriefLicensingFormsWithController from '../NewBriefLicensingFormsWithController/NewBriefLicensingFormsWithController';
import NewBriefTermsFormWithController from '../NewBriefTermsFormWithController/NewBriefTermsFormWithController';
import NewBriefDesignersList from 'components/NewBriefDesignersList/NewBriefDesignersList';
import NewBriefTermsAcceptForm from 'components/NewBriefTermsAcceptForm/NewBriefTermsAcceptForm';

import { CURRENCY_MAP } from 'options/currency';

import { useAuthContext } from 'context/AuthContext';

const NewBriefAgreementForm = ({
  isStory,
  mockDesignerListData,
  mockDesignerListTotal,
  hasDesigner = true,
  isOpenCall,
}) => {
  const { watch } = useFormContext();
  const designersList = watch('designers');
  const { currency } = useAuthContext();
  const currencyIcon = currency ? CURRENCY_MAP[currency] : '£';

  if (isStory) {
    return (
      <>
        <NewBriefTermsFormWithController isStory={true} />
        <NewBriefLicensingFormsWithController currency={currencyIcon} />
        {hasDesigner && (
          <NewBriefDesignersList isStory={true} mockData={mockDesignerListData} mockTotal={mockDesignerListTotal} />
        )}
        <Box mt={6}>
          <NewBriefTermsAcceptForm />
        </Box>
      </>
    );
  } else
    return (
      <>
        <NewBriefTermsFormWithController isOpenCall={isOpenCall} />
        <NewBriefLicensingFormsWithController currency={currencyIcon} />
        {hasDesigner && (
          <NewBriefDesignersList
            isStory={false}
            mockData={mockDesignerListData}
            mockTotal={mockDesignerListTotal}
            designersList={designersList}
          />
        )}
        <Box mt={6}>
          <NewBriefTermsAcceptForm />
        </Box>
      </>
    );
};

NewBriefAgreementForm.propTypes = {
  isStory: PropTypes.bool,
  designerListData: PropTypes.arrayOf(PropTypes.object),
};

export default NewBriefAgreementForm;
