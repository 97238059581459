import { Box, styled, Table } from '@mui/material';

export const TableStyled = styled(Table)(({ theme }) => ({
  '& th:first-of-type, td:first-of-type': {
    width: '23%',
  },
  '& th:nth-child(2), td:nth-child(2)': {
    width: '40%',
  },
  [theme.breakpoints.down('md')]: {
    minWidth: '900px',
    overflowX: 'scroll',
    '& th:first-of-type, td:first-of-type': {
      width: '30%',
    },
    '& th:nth-child(2), td:nth-child(2)': {
      width: '30%',
    },
  },
}));

export const CategoriesWrapperStyled = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
}));
