import { LICENSE_TYPES, TERM_TYPES } from 'options/briefOptions';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { validation, yupObject } from 'utils/validation';
import { array } from 'yup';

const validationStep2 = yupObject({
  ...validation.productProperties,
});

const validationStep4 = yupObject({
  ...validation.newBriefFormFields,
});

const { newBriefTermsAndLicensingFields, newBriefTermsAccept } = validation;

const schemaStep5 = {
  ...newBriefTermsAndLicensingFields,
  ...newBriefTermsAccept,
  designers: array(), // invalidate designers
};

const validationStep5 = yupObject().shape(schemaStep5, ['agreement_file', 'agreement_body']);

export const validationList = [
  yupObject({}),
  validationStep2,
  yupObject({}),
  validationStep4,
  validationStep5,
  yupObject({}),
];

export const validationListEditting = [validationStep2, yupObject({}), validationStep4, validationStep5, yupObject({})];

export const validationListEdittingActive = [validationStep2, validationStep4, yupObject({})];

export const formDefaultValues = {
  // step 2
  [OPTIONS_TYPE.product]: [],
  [OPTIONS_TYPE.material]: [],
  [OPTIONS_TYPE.space]: [],
  [OPTIONS_TYPE.style]: [],
  [OPTIONS_TYPE.colour]: [],
  [OPTIONS_TYPE.sustainability]: [],

  // step 4
  title: '',
  design_requirements: '',
  file_requirements: [],
  customer_requirements: '',
  commercial_requirements: '',
  main_image: [],
  markets: [],
  reference_file: [],
  additional_images: [],
  manufacturing_requirements: '',
  projected_volume: [],
  projected_retail_price: '',
  projected_cost_price: '',

  // step 5
  // terms and conditions
  term_type: TERM_TYPES.new_terms,
  agreement_title: '',
  agreement_body: '',
  agreement_file: [],
  terms_uuid: '',

  //   designers: [],

  // licensing
  agreement_type: LICENSE_TYPES.royalty,
  payment_terms: '',
  deadline: null,
  upfront_payment: 'true',
  upfront_payment_amount: '',
  //   royalty licensing specific fields
  royalty_percentage: '',
  royalty_review_period: '',
  //   one time fee specific fields
  one_off_budget: '',

  //   terms accept
  payment_terms_agreement: false,
  options_on_design_agreement: false,
  reference_images_agreement: false,

  // api required?
  designer_note: 'test',
};

export const steps = [
  'Type of Open Calls',
  'Product Properties',
  'Product Overview',
  'Design Brief',
  'Terms',
  'Summary',
];
export const stepsEditting = ['Product Properties', 'Product Overview', 'Design Brief', 'Terms', 'Summary'];

export const stepsEdittingActive = ['Product Properties', 'Design Brief', 'Summary'];
