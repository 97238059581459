import { Box, styled } from '@mui/material';

export const HeroBlockStyled = styled(Box)(({ theme, variant }) => ({
  backgroundColor: variant === 'designer' ? theme.palette.background.yellow : theme.palette.background.lightblue,
  height: '100vh',
}));

export const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  maxWidth: '50%',
  height: '100%',
  marginLeft: theme.spacing(6),
  [theme.breakpoints.down('md')]: {
    maxWidth: '100%',
    margin: `0 ${theme.spacing(2)}`,
  },
}));
