import React from 'react';
import { NotificationStyled, NotificationText } from './NotificationCounter.styles';

const NotificationCounter = ({ count }) => {
  return (
    <NotificationStyled>
      <NotificationText>{count}</NotificationText>
    </NotificationStyled>
  );
};

export default NotificationCounter;
