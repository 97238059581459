import { Chip, List } from '@mui/material';
import { styled } from '@mui/system';

export const ListStyled = styled(List)(({ theme }) => ({
  marginTop: 'auto',
  paddingTop: 0,
  marginBottom: theme.spacing(3),
}));

export const PricingOptionChip = styled(Chip)(({ theme }) => ({
  width: '100%',
  height: '36px',
  '& .MuiChip-label': {
    fontSize: 14,
    padding: '6px 16px',
  },
}));
