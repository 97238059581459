import ControlledBriefSummary from 'components/BriefSummary/ControlledBriefSummary/ControlledBriefSummary';
import NewBriefAgreementForm from 'components/Forms/NewBriefAgreementForm/NewBriefAgreementForm';
import NewBriefDetailsForm from 'components/Forms/NewBriefDetailsForm/NewBriefDetailsForm';
import NewBriefSetUp from 'components/NewBriefSetUpForm/NewBriefSetUpForm';
import NewBriefSuggestedProducts from 'components/NewBriefSuggestedProducts/NewBriefSuggestedProducts';
import ProductProposalPropertiesForm from 'components/ProductProposalPropertiesForm/ProductProposalPropertiesForm';
import Spinner from 'components/Spinner/Spinner';

import { formDefaultValues } from './BuyerOpenCallsNew.data';

export const OpenCallsNewSteps = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0: {
      return (
        <NewBriefSetUp
          isStory={true}
          defaultValues={formDefaultValues}
          setIsLoading={stepsOptions.setIsFetching}
          isOpenCall={true}
        />
      );
    }
    case 1: {
      return stepsOptions.isFetching ? <Spinner align="center" /> : <ProductProposalPropertiesForm />;
    }
    case 2: {
      return <NewBriefSuggestedProducts />;
    }
    case 3: {
      return <NewBriefDetailsForm />;
    }
    case 4: {
      return <NewBriefAgreementForm hasDesigner={false} isOpenCall />;
    }
    case 5: {
      return <ControlledBriefSummary userType={userType} />;
    }
    default: {
      return <></>;
    }
  }
};

export const OpenCallsNewEdittingSteps = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0: {
      return stepsOptions.isFetching ? <Spinner align="center" /> : <ProductProposalPropertiesForm />;
    }
    case 1: {
      return <NewBriefSuggestedProducts />;
    }
    case 2: {
      return <NewBriefDetailsForm />;
    }
    case 3: {
      return <NewBriefAgreementForm hasDesigner={false} />;
    }
    case 4: {
      return <ControlledBriefSummary userType={userType} />;
    }
    default: {
      return <></>;
    }
  }
};

export const OpenCallsNewEdittingActiveSteps = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0: {
      return stepsOptions.isFetching ? <Spinner align="center" /> : <ProductProposalPropertiesForm />;
    }
    case 1: {
      return <NewBriefDetailsForm />;
    }
    case 2: {
      return <ControlledBriefSummary userType={userType} disableTerms={true} />;
    }
    default: {
      return <></>;
    }
  }
};
