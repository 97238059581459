import { Link, styled, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const BreadcrumbLinkStyled = styled(Link)(({ theme }) => ({
  fontSize: '0.75rem',
  color: theme.palette.text.secondary,
}));

export const BreadcrumbTextStyled = styled(Typography)(({ theme }) => ({
  fontSize: '0.75rem',
}));

export const ArrowIconStyled = styled(ArrowForwardIcon)(() => ({
  fontSize: '1rem',
}));
