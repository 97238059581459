import { styled, Box } from '@mui/material';

export const StyledScrollableContainer = styled(Box)(({ theme, collapse }) => ({
  transition: collapse ? 'all 400ms ease 150ms' : 'all 400ms 100ms ease ',
  overflowY: 'scroll',
  height: 'calc(100vh - 394px)',
  [theme.breakpoints.up('lg')]: {
    width: !collapse ? '100%' : '74%',
  },
  [theme.breakpoints.down('lg')]: {
    width: !collapse ? '100%' : '65.25%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: 'unset',
    overflowY: 'unset',
  },
  [theme.breakpoints.up(1700)]: {
    width: !collapse ? '100%' : '77%',
  },
}));
