import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import FormError from '../FormError/FormError';
import FormParagraph from 'components/Typography/FormParagraph/FormParagraph';
import Spinner from '../../Spinner/Spinner';
import { FormContainer, Container } from './ForgotPassword.styles';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { yupObject, validation } from 'utils/validation';

import { forgotPassword } from '../../../api';
import { routes } from '../../../options/routes';

const {
  accountFields: { email },
} = validation;

const validationSchema = yupObject({
  email,
});

const ForgotPasswordForm = ({ isStory }) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    resolver,
  });

  const onSubmit = async (data) => {
    // Do not call the endpoint in story
    if (isStory) {
      console.log(data);
      return;
    }

    setLoading(true);
    // Call the forgotPassword endpoint and pass in the data - onSubmit will only be triggered if the form data is valid
    const response = await forgotPassword(data);
    if (!response.success) {
      setError(response.data.error_message);
      setLoading(false);
      return;
    }

    // Redirect to forgot password success route on success
    navigate(routes.forgotPasswordSuccess);
  };

  return (
    <FormContainer>
      <CustomTitle variant="h2" text="Reset your password" />
      <FormParagraph text="Enter the email associated with your account, and we'll send you a link to reset your password." variant="primary" />
      {error && (
        <Container>
          <FormError errors={[error]} />
        </Container>
      )}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <ControlledTextField name="email" label="Email Address" showSuccess={!error} />
        </FormProvider>
        <Container display="flex" justifyContent="flex-end">
          {loading && <Spinner />}
          {!loading && <CustomButton text="Reset password" variant="outlined" buttonType="submit" />}
        </Container>
      </form>
    </FormContainer>
  );
};

ForgotPasswordForm.propTypes = {
  isStory: PropTypes.bool,
};

export default ForgotPasswordForm;
