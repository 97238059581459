import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import CustomFileUploadContainerDND from '../CustomFileUploadContainerDND/CustomFileUploadContainerDND';

const ControlledCustomFiledUploadDND = ({ name, variant, fileTypes, numberOfTiles }) => {
  const { control, clearErrors, setValue, setError } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => {
        return (
          <CustomFileUploadContainerDND
            numberOfTiles={numberOfTiles}
            variant={variant}
            fileTypes={fileTypes}
            selectedFiles={value}
            handleChange={(eventFiles) => {
              clearErrors(name);
              onChange([...value, ...eventFiles]);
            }}
            handleReorderUpdate={(newOrderedArr) => {
              setValue(name, newOrderedArr);
            }}
            error={fieldState.error?.message}
            setError={(message) => setError(name, { message })}
            removeFile={(index) => {
              clearErrors(name);
              setValue(
                name,
                value.filter((_file, i) => i !== index)
              );
            }}
          />
        );
      }}
    />
  );
};

ControlledCustomFiledUploadDND.propTypes = {
  variant: PropTypes.oneOf(['single', 'multi']).isRequired,
  fileTypes: PropTypes.oneOf(['images', 'files', 'pdf', 'all']).isRequired,
  numberOfTiles: PropTypes.number.isRequired,
};

export default ControlledCustomFiledUploadDND;
