import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import ControlledPasswordField from 'components/Inputs/TextFields/CustomPasswordField/ControlledPasswordField/ControlledPasswordField';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import FormParagraph from '../../Typography/FormParagraph/FormParagraph';
import FormError from '../FormError/FormError';
import Spinner from '../../Spinner/Spinner';
import { FormContainer, Container } from './ResetPassword.styles';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { validation, yupObject } from 'utils/validation';

import { verifyResetToken, verifyInviteToken, resetPassword, acceptInvite } from '../../../api';
import { routes } from '../../../options/routes';

const {
  accountFields: { password, confirm_password },
} = validation;

const validationSchema = yupObject({
  password,
  confirm_password,
});

const ResetPasswordForm = ({ variant, isStory }) => {
  const [key, setKey] = useState('');
  const [error, setError] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      password: '',
      confirm_password: '',
    },
    mode: 'onChange',
    resolver,
  });

  const verifyKey = useCallback(async () => {
    const path = window.location.pathname;
    const args = path.split('/');
    // There will be 3 args in the path, as the URL structure ensures it
    const paramKey = args[2];
    setKey(paramKey);

    // Call API endpoint to verify the key - redirect to login page if not valid
    const response =
      variant === 'primary' ? await verifyResetToken({ key: paramKey }) : await verifyInviteToken({ key: paramKey });
    if (!response.success) {
      navigate(routes.login);
      return;
    }
    setPageLoading(false);
  }, [navigate, variant]);

  useEffect(() => {
    if (!isStory) verifyKey();
  }, [verifyKey, isStory]);

  const onSubmit = async (data) => {
    // Do not call the endpoint in story
    if (isStory) {
      console.log(data);
      return;
    }

    setLoading(true);
    // Call the forgotPassword endpoint and pass in the data
    // onSubmit will only be triggered if the form data is valid
    const formData = { key, password: data.password };
    const response = variant === 'primary' ? await resetPassword(formData) : await acceptInvite(formData);
    if (!response.success) {
      setError(response.data.error_message);
      setLoading(false);
      return;
    }

    // Redirect to forgot password success route on success
    navigate(variant === 'primary' ? routes.resetPasswordSuccess : routes.acceptInvitationSuccess);
  };

  const titleText = variant === 'primary' ? 'Reset your password' : 'Set Password';

  return (
    <FormContainer>
      {pageLoading && <Spinner align={'center'} />}
      {!pageLoading && (
        <>
          <CustomTitle variant="h2" text={titleText} />
          <FormParagraph text="Create a new password" variant="primary" />
          {error && (
            <Container>
              <FormError errors={[error]} />
            </Container>
          )}
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <ControlledPasswordField name="password" label="Password" showSuccess={!error} />
              <ControlledPasswordField name="confirm_password" label="Confirm your password" showSuccess={!error} />
              <Container display="flex" justifyContent="flex-end">
                {loading && <Spinner />}
                {!loading && <CustomButton text="Continue" variant="outlined" buttonType="submit" />}
              </Container>
            </FormProvider>
          </form>
        </>
      )}
    </FormContainer>
  );
};

ResetPasswordForm.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
  isStory: PropTypes.bool,
};

export default ResetPasswordForm;
