import { styled, Tab } from '@mui/material';

export const SectionTabStyled = styled(Tab, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'complete',
})(({ theme, active, complete }) => ({
  opacity: 1,
  backgroundColor: active ? theme.palette.common.peach : 'transparent',
  transition: 'all 100ms ease',
  fontWeight: 'bold',
  fontSize: 16,
  color: theme.palette.text.primary,
  alignItems: 'flex-start',
  borderBottom: `0.5px solid ${theme.palette.common.black}`,
  '&:disabled': {
    color: theme.palette.text.secondary,
  },
  '&:hover': {
    backgroundColor: theme.palette.common.peach,
  },
  [theme.breakpoints.down('md')]: {
    flex: 1,
    fontSize: 12,
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 10,
    padding: 10,
    minWidth: 'unset',
  },
}));
