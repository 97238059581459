import { useState, useCallback } from 'react';

import parse from 'html-react-parser';
import { sanitize } from 'dompurify';

import { Box } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomAccordion from 'components/Accordion/CustomAccordion/CustomAccordion';
import CustomAccordionBody from 'components/Accordion/CustomAccordionBody/CustomAccordionBody';
import CustomAccordionHead from 'components/Accordion/CustomAccordionHead/CustomAccordionHead';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import PillTag from 'components/PillTag/PillTag';
import FileUploadIconThumbnail from 'components/FileUploadIconThumbnail/FileUploadIconThumbnail';
import BodyText from 'components/Typography/BodyText/BodyText';
import BriefSummaryRequirements from './BriefSummaryRequirements';
import PreviewReferenceFileModal from '../PreviewReferenceFileModal/PreviewReferenceFileModal';

import { LICENSE_TYPES_NAMES } from 'options/briefOptions';
import { CURRENCY_MAP } from 'options/currency';

import { AccordionContentFlexRow, AccordionContentStyled } from './BriefAccordion.styles';
import { VisibilityIconWrapper } from '../Table/Table.styles';

import { useTheme } from '@mui/system';

import { downloadReferenceFile, downloadTerms } from 'api';

const BriefAccordion = ({
  briefDetails,

  handleDesignReqsEdit,
  handleTermsEngagementEdit,
  handleGeneralTermsEdit,

  disableTerms,

  filePreviewEnabled,
  isActive,
}) => {
  const [expand, setExpand] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
  });
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const {
    design_requirements,
    customer_requirements,
    commercial_requirements,
    markets,
    projected_volume,
    projected_retail_price,
    projected_cost_price,
    manufacturing_requirements,
    reference_file,
    file_requirements,

    agreement_type,
    payment_terms,
    deadline,
    upfront_payment,
    upfront_payment_amount,
    royalty_percentage,
    royalty_review_period,
    one_off_budget,

    agreement_title,
    agreement_body,
    agreement_file,

    currency = '',
  } = briefDetails;

  const currencyIcon = currency ? CURRENCY_MAP[currency] : '£';

  const onChange = useCallback((id) => {
    setExpand((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  const referenceFileOnClick = async (file_uuid, name) => {
    try {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = name ? name : 'File.zip';
      const res = await downloadReferenceFile(file_uuid);

      link.href = URL.createObjectURL(new Blob([res]));
      link.click();
    } catch (err) {
      console.log(err);
      return console.log('There was an error downloading files');
    }
  };

  const agreementFileOnClick = async (terms_uuid, name) => {
    try {
      const link = document.createElement('a');
      link.target = '_blank';
      link.download = name ? name : 'File.zip';
      const res = await downloadTerms(terms_uuid);

      link.href = URL.createObjectURL(new Blob([res]));
      link.click();
    } catch (err) {
      console.log(err);
      return console.log('There was an error downloading files');
    }
  };

  const DESIGN_BRIEF_ACCORDIONS = [
    { label: 'Design Requirements', id: 'design_requirements', value: design_requirements },
    { label: 'Customer Requirements', id: 'customer_requirements', value: customer_requirements },
    { label: 'Commercial Requirements', id: 'commercial_requirements', value: commercial_requirements },
    { label: 'Technical Considerations', id: 'manufacturing_requirements', value: manufacturing_requirements },
    [
      { label: 'Markets', id: 'markets', value: markets },
      {
        label: 'Projected Volume',
        id: 'projected_volume',
        value: projected_volume ? projected_volume[0]?.label : null,
      },
    ],
    [
      { label: 'Projected Retail Price', id: 'projected_retail_price', value: projected_retail_price },
      { label: 'Projected Cost Price', id: 'projected_cost_price', value: projected_cost_price },
    ],
    reference_file && reference_file.length > 0
      ? [
          { label: 'Reference File', id: 'reference_file', value: reference_file },
          { label: 'File Requirements', id: 'file_requirements', value: file_requirements },
        ]
      : [{ label: 'File Requirements', id: 'file_requirements', value: file_requirements }],
  ];

  const TERMS_OF_ENGAGEMENT_ACCORDIONS = [
    { label: 'Agreement Type', id: 'agreement_type', value: agreement_type },
    { label: 'Additional Information', id: 'payment_terms', value: payment_terms },
    { label: 'Deadline', id: 'deadline', value: deadline },
    { label: 'Upfront Payment', id: 'upfront_payment', value: upfront_payment },
    {
      label: 'Upfront payment amount',
      id: 'upfront_payment_amount',
      value: upfront_payment_amount && `${currencyIcon}${upfront_payment_amount}`,
    },
    { label: 'Royalty Percentage', id: 'royalty_percentage', value: royalty_percentage && `${royalty_percentage}%` },
    { label: 'Royalty Review Period', id: 'royalty_review_period', value: royalty_review_period },
    { label: 'One off budget', id: 'one_off_budget', value: one_off_budget && `${currencyIcon}${one_off_budget}` },
  ];

  const GENERAL_TCs_ACCORDIONS = [
    { label: 'Agreement Title', id: 'agreement_title', value: agreement_title },
    { label: 'Agreement Body', id: 'agreement_body', value: parse(sanitize(agreement_body)) },
    { label: 'Agreement File', id: 'agreement_file', value: agreement_file },
  ];

  const ACCORDIONS = {
    'Design Brief': DESIGN_BRIEF_ACCORDIONS.filter((item) =>
      Array.isArray(item) ? item.filter((a) => a.value).length : item?.value?.length
    ),
    ...(!disableTerms
      ? { 'Terms of Engagement': TERMS_OF_ENGAGEMENT_ACCORDIONS.filter((item) => item?.value?.length) }
      : {}),
    ...(!disableTerms ? { 'General T&Cs': GENERAL_TCs_ACCORDIONS.filter((item) => item?.value?.length) } : {}),
  };

  const onClick = {
    'Design Brief': handleDesignReqsEdit,
    'Terms of Engagement': handleTermsEngagementEdit,
    'General T&Cs': handleGeneralTermsEdit,
  };

  const handleTogglePreviewModal = () => {
    setPreviewModalOpen((prev) => !prev);
  };

  const theme = useTheme();

  const GetItemContent = useCallback(
    (item, j) => {
      switch (item.id) {
        case 'markets':
          return (
            <Box pb={2} mt={3} key={item.id}>
              <CustomTitle variant="h4" text={item.label} />
              <Box display="flex">
                {item.value?.map(({ label, value }, i) => (
                  <Box mt={2} mr={1} key={`${label}_${i}`}>
                    <PillTag option={{ label: label, value: value }} />
                  </Box>
                ))}
              </Box>
            </Box>
          );
        case 'agreement_file':
          return (
            <Box pb={2} mt={3} key={item.id}>
              <CustomTitle variant="h4" text={item.label} />
              <Box display="flex" mt={2}>
                {item.value?.map(({ fileSrc, fileType, name, uuid }) => {
                  return (
                    <Box key={name} onClick={() => agreementFileOnClick(uuid, name)} style={{ cursor: 'pointer' }}>
                      <FileUploadIconThumbnail fileSrc={fileSrc} fileType={fileType} />
                      <BodyText text={name} />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          );
        case 'agreement_type':
          return (
            <AccordionContentStyled key={item.label}>
              <CustomAccordionBody>
                <BriefSummaryRequirements title={item.label} text={LICENSE_TYPES_NAMES[item.value]} index={j} />
              </CustomAccordionBody>
            </AccordionContentStyled>
          );
        case 'projected_volume':
          return (
            <AccordionContentStyled key={item.label}>
              <CustomAccordionBody>
                <Box mt={3}>
                  <Box mb={2}>
                    <CustomTitle variant="h4" text={item.label} />
                  </Box>
                  <PillTag option={{ label: item.value, value: item.value }} />
                </Box>
              </CustomAccordionBody>
            </AccordionContentStyled>
          );
        case 'projected_retail_price':
          return (
            <AccordionContentStyled borderBottom key={item.label}>
              <CustomAccordionBody>
                <Box mt={3}>
                  <Box mb={2}>
                    <CustomTitle variant="h4" text={item.label} />
                  </Box>
                  <Box mb={1}>
                    <BodyText colour={theme.palette.text.secondary} text={'Estimate'} />
                  </Box>
                  <CustomTitle variant="h3" text={`${currencyIcon}${parseFloat(item.value).toFixed(2)}`} />
                </Box>
              </CustomAccordionBody>
            </AccordionContentStyled>
          );
        case 'projected_cost_price':
          return (
            <AccordionContentStyled borderBottom key={item.label}>
              <CustomAccordionBody>
                <Box mt={3}>
                  <Box mb={2}>
                    <CustomTitle variant="h4" text={item.label} />
                  </Box>
                  <Box mb={1}>
                    <BodyText colour={theme.palette.text.secondary} text={'Estimate'} />
                  </Box>
                  <CustomTitle variant="h3" text={`${currencyIcon}${parseFloat(item.value).toFixed(2)}`} />
                </Box>
              </CustomAccordionBody>
            </AccordionContentStyled>
          );
        case 'reference_file':
          let description = 'Reference material provided by the company';
          if (!isActive) {
            description += '. Click the thumbnail to download it';
            if (filePreviewEnabled) description += ', or click the icon to preview it';
          }

          return (
            <>
              <AccordionContentStyled key={item.label}>
                <CustomAccordionBody>
                  <Box mt={3}>
                    <Box mb={2}>
                      <CustomTitle variant="h4" text={item.label} />
                    </Box>
                    <Box mb={1}>
                      <BodyText colour={theme.palette.text.secondary} text={description} />
                    </Box>
                    <Box display={'flex'} mt={3}>
                      {item.value?.map(({ fileSrc, fileType, name, uuid }) => (
                        <Box mr={2} display="flex" alignItems="center">
                          <Box
                            key={name}
                            onClick={() => (isActive ? {} : referenceFileOnClick(uuid, name))}
                            style={isActive ? {} : { cursor: 'pointer' }}
                          >
                            <FileUploadIconThumbnail fileSrc={fileSrc} fileType={fileType} />
                          </Box>
                          {filePreviewEnabled && (
                            <Box>
                              <VisibilityIconWrapper>
                                <VisibilityIcon
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleTogglePreviewModal()}
                                />
                              </VisibilityIconWrapper>
                            </Box>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                </CustomAccordionBody>
              </AccordionContentStyled>
            </>
          );
        case 'file_requirements':
          return (
            <AccordionContentStyled key={item.label}>
              <CustomAccordionBody>
                <Box mt={3} pt={reference_file && reference_file.length ? 2 : 0}>
                  <Box mb={2}>
                    <CustomTitle variant="h4" text={item.label} />
                  </Box>
                  <Box mb={1}>
                    <BodyText
                      colour={theme.palette.text.secondary}
                      text={
                        'Technical assets requested by the company. These can be discussed with the company directly'
                      }
                    />
                  </Box>
                  <Box display={'flex'} mt={3}>
                    {item.value?.map((v) => (
                      <Box mr={2}>
                        <FileUploadIconThumbnail fileType={v.value} />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </CustomAccordionBody>
            </AccordionContentStyled>
          );
        default:
          return (
            <AccordionContentStyled key={item.label}>
              <CustomAccordionBody>
                <BriefSummaryRequirements title={item.label} text={item.value} index={j} />
              </CustomAccordionBody>
            </AccordionContentStyled>
          );
      }
    },
    [currencyIcon, theme.palette.text.secondary]
  );

  return (
    <Box>
      {!isActive && reference_file && reference_file.length > 0 && (
        <PreviewReferenceFileModal
          loading={false}
          handleClose={handleTogglePreviewModal}
          open={previewModalOpen}
          reference_file={reference_file[0]}
          isActive={isActive}
        />
      )}
      {Object.entries(ACCORDIONS)
        .filter(([key, value]) => value?.length)
        .map(([key, value], i) => {
          return (
            <CustomAccordion
              id={`panel${i + 1}`}
              expanded={expand[`panel${i + 1}`]}
              onChange={onChange}
              index={i}
              key={key}
            >
              <CustomAccordionHead
                text={key}
                expanded={expand[`panel${i + 1}`]}
                handleOnEdit={onClick[key]}
                key={key}
              />
              {value.map((item, j) => {
                if (Array.isArray(item)) {
                  return (
                    <AccordionContentFlexRow>
                      {item.map((arrItem, i) => GetItemContent(arrItem, i))}
                    </AccordionContentFlexRow>
                  );
                }
                return GetItemContent(item, j);
              })}
            </CustomAccordion>
          );
        })}
    </Box>
  );
};

export default BriefAccordion;
