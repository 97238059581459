import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';
import CreateModalFields from './CreateModalFields';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { validation, yupObject } from 'utils/validation';
import { createMoodboard, editMoodboard, getSingleMoodboard, postSaveMoodboardShared } from 'api';
import { getErrorMessage } from 'utils/getErrorMessage';
import { routes } from 'options/routes';

const validationSchema = yupObject({
  ...validation.createMoodboard,
});

const ModalCreateMoodboard = ({
  open,
  handleClose,
  variant,
  moodboard_uuid,
  setMessage,
  moodboardDetails = { name: '', description: '' },
  share_uuid,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    defaultValues: {
      name: '',
      description: '',
    },
    resolver,
  });

  const clearForm = useCallback(() => {
    methods.reset({ name: '', description: '' });
  }, [methods]);

  const fetchMoodboardDetails = useCallback(async () => {
    setIsLoading(true);
    const res = await getSingleMoodboard(moodboard_uuid);
    setIsLoading(false);
    if (res.success) {
      return methods.reset({ name: res?.moodboard?.name || '', description: res?.moodboard?.description || '' });
    }
  }, [methods, moodboard_uuid]);

  useEffect(() => {
    if (variant === 'edit' && moodboard_uuid) {
      fetchMoodboardDetails();
    }
    if (variant === 'save') {
      return methods.reset({ name: moodboardDetails?.name, description: moodboardDetails?.description });
    }

    return () => clearForm();
  }, [
    clearForm,
    fetchMoodboardDetails,
    methods,
    moodboardDetails?.description,
    moodboardDetails?.name,
    moodboard_uuid,
    variant,
  ]);

  const onSaveMoodboard = useCallback(
    async (data) => {
      try {
        const { name, description } = data;
        setIsLoading(true);
        const res = await postSaveMoodboardShared(moodboard_uuid, share_uuid, { name, description });
        setIsLoading(false);
        if (!res.success) {
          throw res;
        }
        const { moodboard_uuid: new_moodboard_uuid } = res;
        navigate(`${routes.moodboardsDashboard}/${new_moodboard_uuid}`);
      } catch (err) {
        setMessage(err?.data?.error_message || 'An error has occurred.');
      }
    },
    [moodboard_uuid, share_uuid, navigate, setMessage]
  );

  const onSubmit = useCallback(
    async (data) => {
      if (variant === 'save') {
        return onSaveMoodboard(data);
      }
      setIsLoading(true);
      const res =
        variant === 'edit' && moodboard_uuid ? await editMoodboard(moodboard_uuid, data) : await createMoodboard(data);
      setIsLoading(false);
      if (!res.success) {
        setMessage(getErrorMessage(res));
        return handleClose(false);
      }
      setMessage(`Moodboard ${variant === 'edit' ? 'edited' : 'created'} successfully.`);
      if (variant === 'edit') {
        handleClose(true);
      } else {
        navigate(`/dashboard/moodboards/${res.moodboard_uuid}`);
      }
    },
    [handleClose, moodboard_uuid, navigate, onSaveMoodboard, setMessage, variant]
  );

  const title = variant === 'edit' ? 'Edit Moodboard' : variant === 'save' ? 'Save Moodboard' : 'Create Moodboard';
  const btnText = variant === 'edit' || variant === 'save' ? 'Save' : 'Create';

  return (
    <CustomModal open={open} handleClose={() => handleClose(false)} title={title}>
      <StyledModalContentWrapper>
        {isLoading ? (
          <Box>
            <Spinner align="center" />
          </Box>
        ) : (
          <Box>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <CreateModalFields />
                <StyledActionButtonsContainer>
                  <CustomButton text="Cancel" onClick={() => handleClose(false)} variant="outlined" fullWidth />
                  <CustomButton text={btnText} buttonType="submit" fullWidth />
                </StyledActionButtonsContainer>
              </form>
            </FormProvider>
          </Box>
        )}
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

ModalCreateMoodboard.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  variant: PropTypes.oneOf(['', 'create', 'edit', 'save']),
};

export default ModalCreateMoodboard;
