import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { yupObject, validation } from 'utils/validation';
import { capitalize } from 'utils/capitalize';

const validationSchema = yupObject({
  ...validation.retractPitch,
});

const ModalRetractPitch = ({ open, handleClose, onSubmit, callback, variant = 'pitch' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    defaultValues: {
      retract: '',
    },
    resolver,
  });

  useEffect(() => {
    if (!open) {
      methods.reset({ retract: '' });
    }
  }, [methods, open]);

  const handleOnSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await onSubmit();
      setIsLoading(false);
      if (callback) {
        callback();
      }
      handleClose(true);
    } catch (err) {
      console.log(err);
    }
  }, [callback, handleClose, onSubmit]);
  const text =
    variant === 'pitch'
      ? 'Are you sure you want to retract this pitch? Retracting it will close all open negotiations. This action cannot be undone.'
      : 'Are you sure you want to retract the pitch for this company? Retracting it will close this negotiation. This action cannot be undone.';

  return (
    <CustomModal title={capitalize(`Retract ${variant}`)} open={open} handleClose={() => handleClose(false)}>
      <StyledModalContentWrapper>
        {isLoading ? (
          <Box mt={2}>
            <Spinner align="center" />
          </Box>
        ) : (
          <Box mt={1}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
                <Box mb={2}>
                  <BodyText text={text} colour="gray" />
                </Box>
                <Box>
                  <BodyText text="Type 'retract' in the field below to confirm:" />
                </Box>
                <ControlledTextField placeholder="retract" name="retract" />
                <StyledActionButtonsContainer>
                  <CustomButton text="Cancel" onClick={() => handleClose(false)} variant="outlined" fullWidth />
                  <CustomButton text="Retract" buttonType="submit" fullWidth />
                </StyledActionButtonsContainer>
              </form>
            </FormProvider>
          </Box>
        )}
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

ModalRetractPitch.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalRetractPitch;
