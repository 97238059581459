import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQueryClient } from 'react-query';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormStepper from 'components/FormStepper/FormStepper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import {
  OpenCallsNewSteps,
  OpenCallsNewEdittingSteps,
  OpenCallsNewEdittingActiveSteps,
} from './BuyerOpenCallsNewSteps';

import { parseFormData, parseRemoteData } from '../../Briefs/BuyerBriefNew/BuyerBriefNew.utils';

import { routes } from 'options/routes';

import {
  formDefaultValues,
  steps,
  stepsEditting,
  stepsEdittingActive,
  validationList,
  validationListEditting,
  validationListEdittingActive,
} from './BuyerOpenCallsNew.data';

import {
  createBuyerOpenCall,
  createTerms,
  getBuyerOpenCallsSingle,
  putEditActiveOpenCall,
  updateBuyerOpenCall,
} from 'api';

const BuyerOpenCallsNew = ({ userType }) => {
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [apiFormData, setApiFormData] = useState({});
  const queryClient = useQueryClient();
  const [isEditingActive, setIsEditingActive] = useState(false);

  const formSettings = useMemo(() => {
    if (id && isEditingActive) {
      return {
        steps: stepsEdittingActive,
        formValidation: validationListEdittingActive,
        stepsSwitcher: OpenCallsNewEdittingActiveSteps,
        saveAndExitSteps: [],
        saveAndExitRequiredFields: [],
      };
    }
    if (id) {
      return {
        steps: stepsEditting,
        formValidation: validationListEditting,
        stepsSwitcher: OpenCallsNewEdittingSteps,
        saveAndExitSteps: [2, 3, 4],
        saveAndExitRequiredFields: [null, ['title'], ['title'], ['title'], ['title']],
      };
    }
    return {
      steps: steps,
      formValidation: validationList,
      stepsSwitcher: OpenCallsNewSteps,
      saveAndExitSteps: [3, 4, 5],
      saveAndExitRequiredFields: [null, null, ['title'], ['title'], ['title'], ['title']],
    };
  }, [id, isEditingActive]);

  const nextStepAction = async (values, step, isSaveAndExit) => {
    const currentStepsLen = formSettings?.steps?.length;
    const isLastStep = currentStepsLen - 1 === step;
    const parsedFormData = parseFormData(values, isLastStep && !isSaveAndExit);

    const lastStepAction = () => {
      queryClient.invalidateQueries({ queryKey: ['buyerOpenCallsList'] });
      navigate(routes.openCallsDashboardAddThanks, { state: { isEditing: !!id } });
    };

    const lastStepActionSaveAndExit = () => {
      queryClient.invalidateQueries({ queryKey: ['buyerOpenCallsList'] });
      navigate(routes.openCallsDashboard);
    };

    if (id && isEditingActive) {
      // don't save (make API call) until is last step
      if (!isLastStep) {
        return;
      }
      const { brief_uuid } = parsedFormData;
      // change endpoint
      const resBrief = await putEditActiveOpenCall(brief_uuid, parsedFormData);

      if (!resBrief.success) {
        throw new Error(resBrief?.data?.error_message || 'There was an error submitting the brief');
      }

      return lastStepAction();
    }

    if ((!id && step === 4) || (id && step === 3)) {
      const { selected_terms_uuid, agreement_file, agreement_title, agreement_body } = parsedFormData;
      const termsData = { file: agreement_file[0], title: agreement_title, body: agreement_body };
      const hasTermsData = !!Object.values(termsData).some((value) => value?.length); // need this if trying to save and exit without terms
      let new_selected_terms_uuid;

      if (!selected_terms_uuid && hasTermsData) {
        const resTerms = await createTerms(termsData);
        if (!resTerms.success) {
          throw new Error(resTerms.data?.error_message || 'There was a problem saving terms. Please try again');
        }
        const { terms_uuid } = resTerms.terms;
        new_selected_terms_uuid = terms_uuid;
      }
      const { brief_uuid } = parsedFormData;
      const resBrief = await updateBuyerOpenCall(brief_uuid, {
        ...parsedFormData,
        selected_terms_uuid: new_selected_terms_uuid ? new_selected_terms_uuid : selected_terms_uuid,
      });

      if (!resBrief.success) {
        throw new Error(resBrief?.data?.error_message || 'There was an error submitting the brief');
      }

      if (isSaveAndExit) {
        return lastStepActionSaveAndExit();
      }
      return setApiFormData(parseRemoteData({ ...resBrief.open_call }));
    }

    if ((id && step >= 2) || step >= 3) {
      const { brief_uuid } = parsedFormData;
      const res = brief_uuid
        ? await updateBuyerOpenCall(brief_uuid, parsedFormData)
        : await createBuyerOpenCall(parsedFormData);
      if (!res.success) {
        throw new Error(res?.data?.error_message || 'There was an error submitting');
      }
      if (isSaveAndExit) {
        return lastStepActionSaveAndExit();
      }

      return isLastStep ? lastStepAction() : setApiFormData(parseRemoteData(res.open_call));
    }
  };

  const onSaveAndExit = (values, activeStep) => {
    return nextStepAction(values, activeStep, true);
  };

  useEffect(() => {
    const fetchOpenCallDetails = async (id) => {
      // fetch API for id
      setIsFetching(true);
      const res = await getBuyerOpenCallsSingle(id);
      if (res.success) {
        // if brief is active, redirect back to dashboard
        if (res?.open_call?.status === 'active') {
          // navigate(routes.openCallsDashboard);
          setIsEditingActive(true);
        }
        setIsFetching(false);
        return setApiFormData(parseRemoteData(res.open_call));
      }
      // if no success, redirect
      return navigate(routes.openCallsDashboard);
    };
    if (id) {
      fetchOpenCallDetails(id);
    }
  }, [id, navigate]);

  const { steps: formSteps, formValidation, stepsSwitcher, saveAndExitSteps, saveAndExitRequiredFields } = formSettings;

  return (
    <BuyerDashboardWrapper activeTab={routes.openCallsDashboard}>
      <HeaderAndMenu
        title="Open Calls"
        menuOptions={[
          {
            buttonTitle: 'Back to Open Calls',
            icon: <ArrowBackIcon />,
            onClick: () => navigate(routes.openCallsDashboard),
            animate: true,
          },
        ]}
      />
      <FormStepper
        steps={formSteps}
        defaultFormValues={formDefaultValues}
        formValidation={formValidation}
        stepsSwitcher={stepsSwitcher}
        nextStepAction={nextStepAction}
        onSaveAndExit={onSaveAndExit}
        saveAndExitSteps={saveAndExitSteps}
        saveAndExitRequiredFields={saveAndExitRequiredFields}
        apiFormData={apiFormData}
        userType={userType}
        stepsOptions={{ setIsFetching, isFetching }}
      />
    </BuyerDashboardWrapper>
  );
};

export default BuyerOpenCallsNew;
