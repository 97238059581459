import { Box, styled } from '@mui/material';

export const StyledNewConvoTabContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  cursor: 'pointer',
  justifyContent: 'space-between',
  display: 'flex',
  alignItems: 'center',
  borderBottom: `0.5px solid ${theme.palette.common.black}`,
}));
