import { styled } from '@mui/system';

export const PageContainer = styled('div')(({ theme }) => ({
  padding: '80px 90px',
  '& > hr': {
    margin: 0,
  },
  [theme.breakpoints.down('md')]: {
    padding: 40,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

export const Wrapper = styled('div')(({ theme, sx = {} }) => ({
  display: 'flex',
  flex: 1,
  padding: '30px 0',
  '&:last-of-type': {
    paddingBottom: 0,
  },
  ...sx,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ColumnContainer = styled('div')(({ theme }) => ({
  flex: 1,
  '&:first-of-type': {
    paddingRight: 20,
  },
  '&:last-of-type': {
    paddingLeft: 20,
  },
  [theme.breakpoints.down('md')]: {
    padding: '0 !important',
    '&:last-of-type': {
      paddingTop: '20px !important',
    },
  },
}));

export const SectionContainer = styled('div')(() => ({
  paddingBottom: 20,
  '&:last-of-type': {
    paddingBottom: 0,
  },
}));
