import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

const ModalConfirmPurchase = ({ open, handleClose, handlePurchaseConfirmation }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = async () => {
    setIsLoading(true);
    await handlePurchaseConfirmation();
    setIsLoading(false);
  };

  return (
    <CustomModal title="Connect with designer" text="Connect with designer" handleClose={handleClose} open={open}>
      <StyledModalContentWrapper>
        {isLoading ? (
          <Box>
            <Spinner align="center" />
          </Box>
        ) : (
          <Box>
            <BodyText text="You will be able to message and discuss the project directly with the designer." />
            <StyledActionButtonsContainer>
              <CustomButton text="Cancel" onClick={handleClose} variant="outlined" fullWidth />
              <CustomButton text="Continue" onClick={handleOnClick} fullWidth />
            </StyledActionButtonsContainer>
          </Box>
        )}
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

ModalConfirmPurchase.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};

export default ModalConfirmPurchase;
