import { styled, TableHead, TableRow, Typography } from '@mui/material';

export const StyledTableHead = styled(TableHead)(({ theme }) => ({
  minHeight: '48px', // this does not apply properly in safari
  boxSizing: 'border-box',
  boxShadow: `inset 0px -1px 0px ${theme.palette.common.black}`,
  position: 'relative',
}));

export const StyledTableRow = styled(TableRow)(() => ({
  height: '48px', // needed for safari
}));

export const CustomTableHeadCellContentWrapper = styled('div', {
  shouldForwardProp: (props) => props !== 'flexEnd',
})(({ flexEnd }) => ({
  display: 'flex',
  justifyContent: flexEnd ? 'flex-end' : 'flex-start',
  alignItems: 'center',

  '& .MuiSvgIcon-root': { opacity: 1 },
}));

export const StyledTableHeadText = styled(Typography, {
  shouldForwardProp: (props) => props !== 'bold',
})(({ theme, bold }) => ({
  ...theme.typography.body1,
  marginRight: 8,
  fontWeight: bold ? 600 : 400,
}));
