import { styled, Tabs } from '@mui/material';

export const TabsStyled = styled(Tabs)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('lg')]: {
    '& .MuiTabs-flexContainer': {
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
  },
}));
