import NewBriefSuggestedProducts from './NewBriefSuggestedProducts';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DoneIcon from '@mui/icons-material/Done';

export default {
  title: 'Design System/Components/NewBriefSuggestedProducts',
  component: NewBriefSuggestedProducts,
};

const Template = (args) => <NewBriefSuggestedProducts {...args} />;

export const SuggestedProductsExample = Template.bind({});

const overlayOptionsV1 = {
  title: 'Proposal',
  description: 'By Harry Batchelor',
  standardOptions: [
    { label: 'View Proposal', onClick: () => console.log('View Proposal'), icon: <ArrowForwardIcon /> },
  ],
};

const overlayOptionsV2 = {
  title: 'Proposal',
  description: 'By Harry Batchelor',
  menuOptions: [
    { title: 'Edit', onClick: () => console.log('onEdit') },
    { title: 'Save', onClick: () => console.log('onSave') },
  ],
  statusData: [
    { label: 'Brief status', icon: <DoneIcon /> },
    { label: 'Product Uploaded', icon: <DoneIcon /> },
  ],
  standardOptions: [
    { label: 'View Proposal', onClick: () => console.log('View Proposal'), icon: <ArrowForwardIcon /> },
  ],
};

export const newBriefSuggestedProductsMock = [
  {
    img: 'https://images.unsplash.com/photo-1543791988-e2f7a58aec25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3580&q=80',
    title: 'Chair',
    overlayOptions: overlayOptionsV1,
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Chair purple',
    overlayOptions: overlayOptionsV2,
  },
  {
    img: 'https://images.unsplash.com/photo-1543791988-e2f7a58aec25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3580&q=80',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1635622045198-86d86c29c5eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1477&q=80',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Mushrooms',
  },
  {
    img: 'https://images.unsplash.com/photo-1658211312038-4293c7bdd37e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1635622045198-86d86c29c5eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1477&q=80',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1635622045198-86d86c29c5eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1477&q=80',
    title: 'Bike',
  },
  {
    img: 'https://images.unsplash.com/photo-1567016432779-094069958ea5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1630585308572-f53438fc684f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1421&q=80',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1630585308572-f53438fc684f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1421&q=80',
    title: 'Bike',
  },
  {
    img: 'https://images.unsplash.com/photo-1543791988-e2f7a58aec25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3580&q=80',
    title: 'Chair',
    overlayOptions: overlayOptionsV1,
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Chair purple',
    overlayOptions: overlayOptionsV2,
  },
  {
    img: 'https://images.unsplash.com/photo-1543791988-e2f7a58aec25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3580&q=80',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1635622045198-86d86c29c5eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1477&q=80',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Mushrooms',
  },
  {
    img: 'https://images.unsplash.com/photo-1658211312038-4293c7bdd37e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1635622045198-86d86c29c5eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1477&q=80',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1635622045198-86d86c29c5eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1477&q=80',
    title: 'Bike',
  },
  {
    img: 'https://images.unsplash.com/photo-1543791988-e2f7a58aec25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3580&q=80',
    title: 'Chair',
    overlayOptions: overlayOptionsV1,
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Chair purple',
    overlayOptions: overlayOptionsV2,
  },
  {
    img: 'https://images.unsplash.com/photo-1543791988-e2f7a58aec25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3580&q=80',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1635622045198-86d86c29c5eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1477&q=80',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Mushrooms',
  },
  {
    img: 'https://images.unsplash.com/photo-1658211312038-4293c7bdd37e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1635622045198-86d86c29c5eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1477&q=80',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1635622045198-86d86c29c5eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1477&q=80',
    title: 'Bike',
  },
  {
    img: 'https://images.unsplash.com/photo-1567016432779-094069958ea5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1630585308572-f53438fc684f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1421&q=80',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1630585308572-f53438fc684f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1421&q=80',
    title: 'Bike',
  },
  {
    img: 'https://images.unsplash.com/photo-1543791988-e2f7a58aec25?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3580&q=80',
    title: 'Chair',
    overlayOptions: overlayOptionsV1,
  },
  {
    img: 'https://images.unsplash.com/photo-1573500020435-82d72d6459b6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80',
    title: 'Chair purple',
    overlayOptions: overlayOptionsV2,
  },
];

SuggestedProductsExample.args = {
  categories: ['Furniture', 'Dining Chair', 'Wood', 'Dining Room', 'Contemporary'],
  products: newBriefSuggestedProductsMock,
};
