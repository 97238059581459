import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import CustomFileUploadContainer from '../CustomFileUploadContainer/CustomFileUploadContainer';
import { getUniqueList } from 'utils/getUniqueList';

const ControlledCustomFiledUpload = ({ name, variant, fileTypes, numberOfTiles, addFileText }) => {
  const { control, clearErrors, setValue, setError } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState }) => {
        return (
          <CustomFileUploadContainer
            numberOfTiles={numberOfTiles}
            variant={variant}
            fileTypes={fileTypes}
            selectedFiles={value}
            handleChange={(eventFiles) => {
              const newValue = [...value, ...eventFiles];
              const isDuplicate = getUniqueList(newValue, 'name').length !== newValue.length;
              if (isDuplicate) {
                const isImage = fileTypes === 'images';
                return setError(name, { message: `Duplicate ${isImage ? 'images' : ''} not allowed` });
              }
              clearErrors(name);
              onChange(newValue);
            }}
            error={fieldState.error?.message}
            onBlur={() => {
              console.log('onblues');
            }}
            setError={(message) => setError(name, { message })}
            removeFile={(index) => {
              clearErrors(name);
              setValue(
                name,
                value.filter((_file, i) => i !== index)
              );
            }}
            addFileText={addFileText}
          />
        );
      }}
    />
  );
};

ControlledCustomFiledUpload.propTypes = {
  variant: PropTypes.oneOf(['single', 'multi']).isRequired,
  fileTypes: PropTypes.oneOf(['images', 'files', 'all']).isRequired,
  numberOfTiles: PropTypes.number.isRequired,
};

export default React.memo(ControlledCustomFiledUpload);
