import React, { useState } from 'react';

import { Box } from '@mui/material';
import ControlledSelectField from 'components/Inputs/CustomSelectField/ControlledSelectField/ControlledSelectField';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import ControlledAutoCompleteField from 'components/Inputs/CustomAutoCompleteField/ControlledAutoCompleteField.jsx/ControlledAutoCompleteField';
import ControlledCustomFiledUpload from 'components/CustomFileUpload/ControlledCustomFileUpload/ControlledCustomFileUpload';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import ControlledDatePicker from '../../Inputs/CustomDatePicker/ControlledDatePicker/ControlledDatePicker';

import { parseRemoteOptions } from 'utils/parseRemoteOptions';

import { COUNTRIES } from 'options/countries';
import { wpRoutes } from 'options/routes';

const SignUpBuyerProfile = ({ productsOptions, companyOptions, spacesOptions }) => {
  // this will come from backend...
  const [selectOptions] = useState({
    productsOptions: parseRemoteOptions(productsOptions),
    companyOptions: parseRemoteOptions(companyOptions),
    spacesOptions: parseRemoteOptions(spacesOptions),
  });

  return (
    <>
      <Box>
        <CustomFieldSet
          label="Company Logo"
          tooltipText="Please upload your company logo here."
          tooltipParagrahs={[
            'If your company logo is not formatted correctly, BuyDesign will contact you or adjust the formatting to the correct size.',
            'We can assist you in uploading your logo after you have completed your account.',
            'If you have any issues at support@buydesign.com. Please save each section separately.',
          ]}
          description={
            <>
              Follow our guides to upload your company logo found{' '}
              <CustomStandardLink
                linkType="primary"
                text="here,"
                href={wpRoutes.resourcesImageGuidelines}
                target="_blank"
                fontSize="large"
              />{' '}
              we can assist you in uploading your logo after your completing your account.
            </>
          }
        >
          <ControlledCustomFiledUpload variant="single" fileTypes="images" numberOfTiles={1} name="logo" />
        </CustomFieldSet>
      </Box>

      <Box mt={2}>
        <CustomFieldSet>
          <ControlledTextField name="name" label="Company Name" showSuccess showSuccessAdornment />
          <ControlledTextField name="position" label="Position In Company" showSuccess showSuccessAdornment />
          <ControlledTextField name="website" label="Website" showSuccess showSuccessAdornment />
          <ControlledAutoCompleteField name="country" label="Country" options={COUNTRIES} showSuccess />
          <ControlledDatePicker
            name="established_since"
            label="Established Since"
            views={['year']}
            inputFormat="yyyy"
            showSuccess
            disableFuture
          />
        </CustomFieldSet>
      </Box>

      <Box mt={6}>
        <CustomFieldSet label="What type of products are you interested in?">
          <ControlledSelectField
            name="product_interests"
            label="Select All That Apply"
            options={selectOptions.productsOptions}
          />
        </CustomFieldSet>

        <Box mt={6}>
          <CustomFieldSet label="What type of space are you interested in?">
            <ControlledSelectField
              name="buyer_spaces"
              label="Select All That Apply"
              options={selectOptions.spacesOptions}
            />
          </CustomFieldSet>
        </Box>

        <Box mt={6}>
          <CustomFieldSet label="How would you describe your company?">
            <ControlledSelectField
              name="buyer_categories"
              label="Select All That Apply"
              options={selectOptions.companyOptions}
            />
          </CustomFieldSet>
        </Box>
      </Box>
    </>
  );
};

export default SignUpBuyerProfile;
