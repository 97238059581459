import { useEffect } from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import ProductSingle from 'components/ProductSingle/ProductSingle';

import Spinner from 'components/Spinner/Spinner';
import BodyText from 'components/Typography/BodyText/BodyText';

import { PROPOSAL_STATUS } from 'options/proposalStatus';
import { routes } from 'options/routes';

import { parseRemoteData } from 'pages/Dashboard/Designer/Briefs/DesignerBriefProposalNew/utils/DesignerBriefProposalNew.utils';
import { getIsProposalTabDisabled } from '../BriefSingle.utils';

import { getBuyerProposalDetails } from 'api';

export const BuyerBriefProposalDetails = () => {
  const { brief_uuid, brief_designer_uuid } = useParams();
  const [briefData, setMessage, userType, onAccept, onReject] = useOutletContext();

  const navigate = useNavigate();

  const { data, isLoading, isRefetching } = useQuery(
    ['buyerBriefProposalDetails', brief_uuid, brief_designer_uuid],
    () => {
      return getBuyerProposalDetails(brief_uuid, brief_designer_uuid);
    },
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const project_status = briefData?.project_status || '';
  const proposal_status = briefData?.proposal_status || '';
  const brief_status = briefData?.status || '';
  const brief_designer = briefData?.brief_designer;
  const designer_name = `${brief_designer && brief_designer?.first_name} ${
    brief_designer && brief_designer?.last_name
  }`;
  const designer_profile = `designer-profile/${brief_designer && brief_designer?.uuid ? brief_designer?.uuid : ''}`;

  useEffect(() => {
    const proposalIsDisabled = getIsProposalTabDisabled(project_status, proposal_status, brief_status);

    if (proposalIsDisabled) {
      navigate(`${routes.briefsDashboard}/${brief_uuid}/${brief_designer_uuid}/brief`);
    }
  }, [brief_designer_uuid, brief_status, brief_uuid, navigate, project_status, proposal_status]);

  const proposalDetails = (data && data.success && parseRemoteData(data?.proposal)) || {};

  const isRejected = proposalDetails?.status === PROPOSAL_STATUS.REJECTED;
  const isApproved = proposalDetails?.status === PROPOSAL_STATUS.APPROVED;

  let component = (
    <ProductSingle
      data={{ ...proposalDetails, designer_name, designer_profile }}
      userType={userType}
      variant="proposal_view"
      acceptText="Connect with designer"
      rejectText="Decline Proposal"
      onAccept={onAccept}
      onReject={onReject}
      showButtons={!isRejected && !isApproved}
    />
  );

  const noProposal = !proposalDetails || !Object.keys(proposalDetails).length;
  if (noProposal) {
    component = (
      <Box display="flex" justifyContent="center" my={2}>
        <BodyText text="Waiting for the designer to submit their proposal" />
      </Box>
    );
  }

  return (
    <Box mt={0}>
      {isLoading || isRefetching ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <>
          <Box mt={2}>{component}</Box>
        </>
      )}
    </Box>
  );
};

export default BuyerBriefProposalDetails;
