import { useCallback, useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';

import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import Spinner from '../Spinner/Spinner';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import PillTag from 'components/PillTag/PillTag';
import StatusPill from 'components/StatusPill/StatusPill';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import ModalDeleteItem from 'components/ModalDeleteItem/ModalDeleteItem';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';

import { PILL_STATUS_ICON, PILL_STATUS_MAP, DESIGNER_PRODUCT_PILL_STATUS_TEXT } from 'options/pillStatusOptions';
import { PRODUCT_STATUS } from 'options/productStatus';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { designerProductListHeadings } from '../../options/tableOptions';
import { routes } from '../../options/routes';

import { getUniqueList } from 'utils/getUniqueList';
import { addFilters } from 'utils/addFilters';
import { getErrorMessage } from 'utils/getErrorMessage';

import { ProductDetailsWrapper } from './DesignerProductList.styles';
import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';
import { TableStyled } from 'components/Table/Table.styles';

import { useMessageContext } from 'context/MessageContext';

import { getDesignerProductsList, deleteDesignerProduct, getChildOptions, hideOrShowProduct } from '../../api';

import { useTableFilter } from 'hooks/useTableFilter'

const DesignerProductList = ({ isStory, mockData, mockTotal }) => {
  const [loading, setLoading] = useState(!isStory);
  const [setMessage] = useMessageContext();
  const [selectedDeleteProduct, setSelectedDeleteProduct] = useState('');
  const [selectedHideOrShow, setSelectedHideOrShow] = useState(null);
  const [productsList, setProductsList] = useState(isStory ? mockData : []);
  const [totalCount, setTotalCount] = useState(isStory ? mockTotal : 0);
  const { order, orderBy, filter, setFilter, p, l, parsedFilter, onPageChange, onRequestSort, onRowsPerPageChange } =
    useTableFilter({ initialOrder: 'desc', initialOrderBy: 'created' });

  const navigate = useNavigate();

  const productsQuery = useQuery(['designerProductsList', parsedFilter], () => getDesignerProductsList(parsedFilter), {
    enabled: !isStory,
    staleTime: 2 * 60 * 1000,
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if (isStory) return;
    setLoading(true);
    // Set data states
    if (productsQuery.data && productsQuery.data.success) {
      setProductsList(
        productsQuery.data.products && productsQuery.data.products.length ? productsQuery.data.products : []
      );
      setTotalCount(productsQuery.data.total ? productsQuery.data.total : 0);
    }
    // Set loading and error states
    if (productsQuery.status === 'success') {
      setLoading(false);
      if (!productsQuery.data.success) setMessage('There was an error when fetching the products.');
    }
  }, [isStory, productsQuery.status, productsQuery.data, parsedFilter, setMessage]);


  const onView = useCallback(
    (product_uuid) => {
      navigate(`${routes.product}/${product_uuid}`, {
        state: { from: routes.productsDashboard, fromTitle: 'Products' },
      });
    },
    [navigate]
  );

  const onViewNegotiations = useCallback(
    (product_uuid) => {
      navigate(`${routes.productsDashboard}/${product_uuid}`);
    },
    [navigate]
  );

  const deleteProduct = useCallback(async (product_uuid) => {
    // Post to API
    const response = await deleteDesignerProduct(product_uuid);
    if (!response.success) {
      // Set error
      setMessage(response.data.error_message);
      return;
    }
    setMessage(`Product has been successfully deleted.`);
  }, []);

  const onDelete = useCallback(
    async (product_uuid) => {
      if (isStory) {
        console.log('delete', product_uuid);
        return;
      }
      setSelectedDeleteProduct(product_uuid);
    },
    [isStory]
  );

  const onEdit = useCallback(
    (uuid) => {
      if (isStory) {
        console.log(uuid);
        return;
      }
      navigate(`${routes.productsAdd}/${uuid}`);
    },
    [isStory, navigate]
  );

  const onHandleVisible = useCallback(async () => {
    try {
      const { product_uuid, visible } = selectedHideOrShow;
      const res = await hideOrShowProduct(product_uuid, visible);
      if (!res.success) {
        throw new Error(getErrorMessage(res));
      }
      setMessage('You have successfully changed product visibility status.');
      productsQuery.refetch();
    } catch (err) {
      setMessage(err?.toString());
    }
  }, [productsQuery, selectedHideOrShow, setMessage]);

  const {
    data: { options: categories },
  } = useQuery(['options', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });
  const hasFilter = Object.keys(filter).length;

  const filtersList = useMemo(
    () => ({
      main_categories: categories,
      statuses: [...Object.values(PRODUCT_STATUS)],
    }),
    [categories]
  );

  const headingsOnClick = useMemo(
    () =>
      designerProductListHeadings.map((menuItem) =>
        !menuItem.sortable
          ? {
              ...menuItem,
              menuOptions: addFilters(menuItem, filtersList, setFilter, filter, DESIGNER_PRODUCT_PILL_STATUS_TEXT),
            }
          : menuItem
      ),
    [filter, filtersList]
  );

  const noProduct = !productsList || productsList.length === 0;
  const showNoProductsYetMessage = !hasFilter && noProduct;

  return (
    <>
      {loading || productsQuery.isRefetching ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <CustomTableWrapper
          noResultsTitle={showNoProductsYetMessage ? 'No products found' : ''}
          noResultsDescription={
            showNoProductsYetMessage ? "You haven't added any products yet. Let's get started!" : ''
          }
          btnText={showNoProductsYetMessage ? 'Add Product' : ''}
          onBtnClick={showNoProductsYetMessage ? () => navigate(routes.productsAdd) : () => {}}
        >
          <>
            <TableStyled>
              <CustomTableHeader
                columnHeadings={headingsOnClick}
                onRequestSort={onRequestSort}
                order={order}
                orderBy={orderBy}
              />
              <CustomTableBody>
                {!productsList.length && <NoFilteringMatch item="products" cols={designerProductListHeadings.length} />}
                {productsList.map((product, index) => {
                  const {
                    image,
                    name,
                    categories,
                    date_created,
                    views,
                    status,
                    product_uuid,
                    visible,
                    can_send_to_buyer,
                  } = product;

                  const soldOrNegotiating = status === PRODUCT_STATUS.SOLD || status === PRODUCT_STATUS.NEGOTIATING;
                  const approvedOrNegotiating =
                    status === PRODUCT_STATUS.APPROVED || status === PRODUCT_STATUS.NEGOTIATING;
                  const visibleButtonText = visible ? 'Hide from Marketplace' : 'Show on Marketplace';
                  const threeDotMenuActions =
                    status === PRODUCT_STATUS.PENDING_APPROVAL
                      ? [{ title: 'Delete', onClick: () => onDelete(product_uuid) }]
                      : [
                          ...(status !== PRODUCT_STATUS.DRAFT
                            ? [{ title: 'View Product', onClick: () => onView(product_uuid) }]
                            : []),
                          ...(soldOrNegotiating
                            ? [{ title: 'View Negotiations', onClick: () => onViewNegotiations(product_uuid) }]
                            : []),
                          ...(!soldOrNegotiating ? [{ title: 'Edit', onClick: () => onEdit(product_uuid) }] : []),
                          ...(!soldOrNegotiating ? [{ title: 'Delete', onClick: () => onDelete(product_uuid) }] : []),
                          ...(approvedOrNegotiating
                            ? [
                                {
                                  title: visibleButtonText,
                                  onClick: () => setSelectedHideOrShow({ product_uuid, visible, name }),
                                },
                              ]
                            : []),
                          ...(can_send_to_buyer
                            ? [
                                {
                                  title: 'Send To Buyer',
                                  onClick: () => navigate(`${routes.pitchesConversionAdd}/${product_uuid}`),
                                },
                              ]
                            : []),
                        ];
                  return (
                    <CustomTableRow
                      key={`table_${name}_${index}`}
                      onClick={() => (status === PRODUCT_STATUS.DRAFT ? onEdit(product_uuid) : onView(product_uuid))}
                    >
                      <CustomTableBodyCell>
                        <ProductDetailsWrapper>{<ItemInfo avatar={image || {}} label={name} />}</ProductDetailsWrapper>
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <PillsWrapperStyled>
                          {getUniqueList(categories, 'slug')?.map((category, i) => {
                            const { name, slug } = category;
                            return (
                              <Box key={`${category}_${i}`}>
                                <PillTag option={{ label: name, value: slug }} />
                              </Box>
                            );
                          })}
                        </PillsWrapperStyled>
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <Box>
                          <TableBodyText text={date_created ? date_created : '--'} />
                        </Box>
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <TableBodyText text={views ? views : '0'} />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        {approvedOrNegotiating && visible ? <DoneIcon /> : <CloseIcon />}
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <StatusPill
                          label={DESIGNER_PRODUCT_PILL_STATUS_TEXT[status]}
                          status={PILL_STATUS_MAP[status]}
                          icon={PILL_STATUS_ICON[status]}
                        />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <Box>
                          <ThreeDotMenu actions={threeDotMenuActions} variant="vertical" />
                        </Box>
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  );
                })}
              </CustomTableBody>
            </TableStyled>
            {!noProduct && (
              <CustomTablePagination
                count={totalCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                rowsPerPage={l}
                rowsPerPageOptions={[5, 10, 15]}
                page={p}
              />
            )}
          </>
        </CustomTableWrapper>
      )}
      {!!selectedDeleteProduct && (
        <ModalDeleteItem
          open={!!selectedDeleteProduct}
          handleClose={() => setSelectedDeleteProduct('')}
          onSubmit={() => deleteProduct(selectedDeleteProduct)}
          callback={() => productsQuery.refetch()}
          variant="product"
        />
      )}
      {selectedHideOrShow?.product_uuid && (
        <ModalConfirmAccept
          open={!!selectedHideOrShow?.product_uuid}
          titleOverride={`${selectedHideOrShow?.visible ? 'Hide' : 'Show'} ${selectedHideOrShow?.name}`}
          textOverride={`Are you sure you want to ${
            selectedHideOrShow?.visible ? 'hide this product from' : 'show this product on'
          } the marketplace?`}
          handleClose={() => setSelectedHideOrShow(null)}
          handleSubmit={onHandleVisible}
        />
      )}
    </>
  );
};

DesignerProductList.propTypes = {
  mockData: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      label: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          slug: PropTypes.string,
        })
      ),
      date_created: PropTypes.string,
      views: PropTypes.string,
      status: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
  mockTotal: PropTypes.number,
  isStory: PropTypes.bool,
};

export default DesignerProductList;
