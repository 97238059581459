import React from 'react';
import PropTypes from 'prop-types';
import { fileTypesThumbnails } from 'options/fileTypeThumbnails';

const FileUploadIconThumbnail = ({ fileType }) => {
  const fileUrl = fileTypesThumbnails[fileType.replace(/application\//, '.')];
  return fileUrl ? <img src={fileUrl} alt={fileType} /> : null;
};

FileUploadIconThumbnail.propTypes = {
  fileType: PropTypes.string,
};

export default FileUploadIconThumbnail;
