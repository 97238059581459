import { styled, Box } from '@mui/material';

export const OverlayStatusIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '50px',
  backgroundColor: theme.palette.white.main,
  border: `1px solid ${theme.palette.primary.main}`,
}));

export const OverlayStatusOptionWrapper = styled('div')(({ theme, wrapStatus }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: wrapStatus ? 'wrap' : 'no-wrap'
}));

export const OverlayStatusTextWrapper = styled(Box)(({ theme, wrapStatus }) => ({
  display: 'flex',
  flex: 1,
  minWidth: 110,
}));

