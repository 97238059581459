import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';
import queryString from 'query-string';

import ProductsAll from 'components/ProductsAll/ProductsAll';
import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import ProductsNotFound from 'components/ProductsNotFound/ProductsNotFound';

import { FILTER_OPTIONS } from 'options/filterOptions';
import { PRODUCTS_MENU } from 'options/menuOptions';
import { routes } from 'options/routes';
import { PRODUCTS_PER_PAGE } from 'options/pagination';

import { addProductFilters } from 'utils/addFilters';

import { ContentWrapper } from '../Products.styles';

import { mockCategories, mockSpace } from 'components/ProductsAll/ProductsAll.data';

import { useProductOptionsQueries } from 'hooks/useProductOptionsQueries';

import { getProducts } from 'api';

const ProductsCategorySearch = ({ userType, isStory }) => {
  const { category } = useParams();
  const location = useLocation();
  const categoryLabel = location?.state?.categoryLabel;
  const [pagination, setPagination] = useState({ p: 1, l: PRODUCTS_PER_PAGE });
  const [filter, setFilter] = useState({});
  const [allLoaded, setAllLoaded] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState('sub_category_three');
  const { p } = pagination;
  const navigate = useNavigate();


  const parsedFilter = useMemo(
    () => queryString.stringify({ ...filter, ...pagination, [categoryFilter]: category }),
    [filter, pagination, category, categoryFilter]
  );
  const onPageChange = useCallback((_e, newPage) => {
    setPagination((prevState) => ({ ...prevState, p: newPage }));
  }, []);

  const { data, isLoading } = useQuery(['products', parsedFilter], async () => {
    let results = [];
    results = await getProducts(parsedFilter);
    return results;
  }, {
    staleTime: 5 * 60 * 1000,
  });

  const products = useMemo(() => data?.products || [], [data]);

  const total = data?.total || 0;
  const pageCount = useMemo(() => (total ? Math.ceil(total / PRODUCTS_PER_PAGE) : null), [total]);

  const resetPagination = useCallback(() => {
    setPagination({ p: 1, l: PRODUCTS_PER_PAGE });
  }, []);

  useEffect(() => {
    setFilter({});
    resetPagination();
  }, [category, resetPagination]);

  useEffect(() => {
    if (!isLoading && !products.length && !allLoaded) {
      if (categoryFilter === 'sub_category_three') {
        setCategoryFilter('sub_category_two');
      } else if (categoryFilter === 'sub_category_two') {
        setCategoryFilter('sub_category_one');
      } else if ('sub_category_one') {
        setCategoryFilter('main_category');
      }
    }

    if (products.length || categoryFilter === 'main_category') {
      setAllLoaded(true);
    }
  }, [products, isLoading, categoryFilter, allLoaded]);

  useEffect(() => {
    resetPagination();
  }, [filter, resetPagination]);

  const { categories, space, materials, colour } = useProductOptionsQueries();

  const processCategoryLabel = useMemo(() => {
    if (!category) {
      return '';
    };

    const upperCaseCategory = `${category.charAt(0).toUpperCase()}${category.slice(1)}`;

    return upperCaseCategory.replaceAll('-', ' ');
  }, [category]);

  const itemsList = useMemo(
    () => ({
      [FILTER_OPTIONS.CATEGORY]: isStory ? mockCategories : categories,
      [FILTER_OPTIONS.SPACE]: isStory ? mockSpace : space,
      [FILTER_OPTIONS.MATERIAL]: materials || [],
      [FILTER_OPTIONS.COLOUR]: colour || [],
    }),
    [categories, colour, isStory, materials, space]
  );

  const menuOptions = useMemo(
    () =>
      PRODUCTS_MENU.filter((menuItem) => menuItem.id !== FILTER_OPTIONS.CATEGORY).map((menuItem) => {
        return {
          ...menuItem,
          actions: addProductFilters(menuItem, itemsList, setFilter, filter),
        };
      }),
    [filter, itemsList]
  );

  const displayCategory = categoryLabel ? categoryLabel : processCategoryLabel;

  const title = `Results for "${displayCategory}"`;

  let notFound;

  if (!isLoading && !products?.length) {
    notFound = (
      <ProductsNotFound
        message="No products match your filter criteria."
        btnText="View All Products"
        onClick={() => navigate(routes.products)}
      />
    );
  }

  const resetFilter = useCallback(() => {
    setFilter({});
  }, []);

  const hasFilter = !!queryString.stringify(filter);

  return (
    <PageWrapper userType={userType} showSecondaryFooter={false}>
      <ContentWrapper>
        <ProductsAll
          title={title}
          userType={userType}
          menuOptions={menuOptions}
          products={products}
          isLoading={!allLoaded}
          notFound={notFound}
          total={total}
          page={p}
          pageCount={pageCount}
          onPageChange={onPageChange}
          resetFilter={resetFilter}
          hasFilter={hasFilter}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};

ProductsCategorySearch.propTypes = {};

export default ProductsCategorySearch;
