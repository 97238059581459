import { Box } from '@mui/material';
import BodyText from 'components/Typography/BodyText/BodyText';

export const ModalMessageHeader = () => {
  return (
    <Box display="flex" gap={1} alignItems="center" lineHeight={1.2}>
      <BodyText text="Message" bold />
      <Box pt={'2px'}>
        <BodyText text="Edit text below or send directly" fontSize="small" />
      </Box>
    </Box>
  );
};

export default ModalMessageHeader;
