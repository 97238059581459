import { styled, Box } from '@mui/material';

export const DashboardWrapperStyled = styled(Box)(({ Box }) => ({
  // will possibly need more styles when dropped into a page no doubt
  width: '100%',
}));

export const DashboardRowOne = styled(Box)(({ isLoading, theme }) => ({
  display: 'grid',
  gridTemplateColumns: '20% 20% 1fr',
  gridTemplateRows: 'auto',
  minHeight: isLoading ? '80vh' : '',
  paddingTop: 52,
  [theme.breakpoints.down(1200)]: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100vw',
  },
}));

export const DashboardItem = styled(Box, {
  shouldForwardProp: (props) => props !== 'showPadding' && props !== 'gridColumn' && props !== 'gridRow',
})(({ theme, showPadding, gridColumn, gridRow, sx }) => ({
  gridColumn: gridColumn,
  gridRow: gridRow,
  minWidth: 0,
  position: 'relative',
  padding: showPadding ? '24px 16px' : '',
  ...sx,
}));

export const DashboardGraphWrapper = styled(Box)(({ theme }) => ({
  border: '1px solid ',
  borderRadius: 8,
  height: '100%',
  width: '100%',
}));

export const DashboardItemWrapper = styled(Box)(({ theme }) => ({
  border: '1px solid ',
  borderRadius: 8,
}));

export const RecentMarketplaceContainer = styled(Box)(({ theme }) => ({
  maxHeight: '450px',
  overflowY: 'auto',
}));
