import { styled, Typography, Box } from '@mui/material';

export const TitleStyled = styled(Typography)(({ theme, fontSize }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(1),
  fontSize: fontSize === 'medium' ? '1rem' : '1.5rem',
  fontWeight: 400,
}));

export const StyledBox = styled(Box)(() => ({
  '& li': {
    padding: '0 !important',
    width: 250,
  }
}));
