import { styled, Box } from '@mui/material';

export const DashboardItemListWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
}));

export const DashboardItemContainer = styled(Box)(({ theme, index }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.grey[800]}`,
  borderTop: index === 0 ? `1px solid ${theme.palette.grey[800]}` : '',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.background.pink,
  },
}));
