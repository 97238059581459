import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';
import CustomDivider from 'components/Divider/CustomDivider';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import { ButtonsContainer, StickyContainer, StyledContainer } from './HeaderAndMenu.styles';

const HeaderAndMenu = ({ title, menuOptions, avatar }) => {
  const buttons = useMemo(
    () =>
      menuOptions?.map((menuOption) => (
        <CustomIconButton
          text={menuOption.buttonTitle}
          icon={menuOption.icon}
          onClick={menuOption.onClick}
          key={menuOption.buttonTitle}
          colour={menuOption.colour}
          disabled={menuOption.disabled}
          breakpoint={menuOption.breakpoint}
          fontWeight={600}
          animate={menuOption.animate}
        />
      )),
    [menuOptions]
  );

  return (
    <StickyContainer>
      <StyledContainer>
        <Box display="flex" alignItems="center">
          {avatar && (
            <Box mr={1}>
              <CustomAvatar imageUrl={avatar} />
            </Box>
          )}
          {title && <CustomTitle text={title} variant="h2" />}
        </Box>
        <ButtonsContainer>{buttons}</ButtonsContainer>
      </StyledContainer>
      <CustomDivider orientation="horizontal" />
    </StickyContainer>
  );
};

HeaderAndMenu.propTypes = {
  title: PropTypes.string,
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      buttonTitle: PropTypes.string,
      icon: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
};

export default HeaderAndMenu;
