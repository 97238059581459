import { Box, Fab, styled } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';

export const ChatBoxWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  right: '16px',
  bottom: '90px',
  zIndex: 6000,
  height: '100%',
}));

export const ChatBoxStyled = styled(Box)(({ theme, expand }) => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: 'calc(100% - 435px)',
  transition: 'all 500ms ease-in-out, overflow-y 500ms ease-in-out 2s',
  position: 'fixed',
  right: '8px',
  bottom: '90px',
  padding: expand ? theme.spacing(1) : 0,
  minHeight: expand ? '120px' : '0px',
  width: expand ? `calc(25% - 34px)` : '0px',
  height: !expand ? 0 : '100%',
  borderRadius: theme.shape.borderRadius * 2,
  backgroundColor: theme.palette.common.white,
  overflowY: !expand ? 'hidden' : 'unset',
  [theme.breakpoints.down('lg')]: {
    width: expand ? 'calc(30% - 8px)' : '0',
  },
  [theme.breakpoints.down('md')]: {
    height: !expand ? 0 : '34vh',
    width: '92%',
  },
}));

export const MessagesContainerStyled = styled(Box)(({ theme, expand }) => ({
  display: 'flex',
  flexDirection: 'column',
  overflowY: !expand ? 'hidden' : 'scroll',
  padding: theme.spacing(1),
}));

export const MessageBarContainer = styled(Box)(({ expand }) => ({
  marginTop: 'auto',
  overflowY: !expand ? 'hidden' : 'unset',
}));

export const FabStyled = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  right: '16px',
  bottom: '16px',
}));

export const ChatIconStyled = styled(ChatIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const ChatOffIconStyled = styled(SpeakerNotesOffIcon)(({ theme }) => ({
  color: theme.palette.common.white,
}));

export const ChatActionBtnsContainer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  marginTop: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(2),
}));
