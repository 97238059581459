import { OPTIONS_TYPE } from 'options/selectOptions';

export const HEADER_OPTIONS = [
  {
    label: 'Product',
    value: OPTIONS_TYPE.product,
    variant: 'standard',
  },
  {
    label: 'Material',
    value: OPTIONS_TYPE.material,
    variant: 'standard',
  },
  {
    label: 'Space',
    value: OPTIONS_TYPE.space,
    variant: 'standard',
  },
  {
    label: 'Style',
    value: OPTIONS_TYPE.style,
    variant: 'standard',
  },
  {
    label: 'Colour',
    value: OPTIONS_TYPE.colour,
    variant: 'standard',
  },
  {
    label: 'Sustainability',
    value: OPTIONS_TYPE.sustainability,
    variant: 'standard',
  },
];

// options used for story
const product_category_top = [
  {
    name: 'Furniture',
    slug: 'furniture',
    has_nested: true,
  },
];

const furniture = [
  {
    name: 'Soft Seating',
    slug: 'soft-seating',
    has_nested: true,
  },
  {
    name: 'Seating',
    slug: 'seating',
    has_nested: true,
  },
  {
    name: 'Tables',
    slug: 'tables',
    has_nested: true,
  },
];

const softSeating = [
  {
    name: 'Armchairs',
    slug: 'armchairs',
    has_nested: true,
  },
];

const armChairs = [
  {
    name: 'Accent',
    slug: 'accent',
    has_nested: false,
  },
];

export const mockValues = {
  product_category_top,
  furniture,
  'soft-seating': softSeating,
  armchairs: armChairs,
};
