import { styled } from '@mui/material';

export const CustomTabGroupWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  background: theme.palette.common.black,
  position: 'sticky',
  top: 91,
  zIndex: 5000,
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'unset',
    overflowX: 'scroll',
  },
}));
