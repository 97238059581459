export const MOCK_DESIGNER_1 = {
  first_name: 'Harry Batchelor',
  portrait: { fileSrc: 'https://pickaface.net/gallery/avatar/katon5224739f478e0.png', fileType: 'image' },
};

export const MOCK_DESIGNERS_LIST = [
  {
    first_name: 'Mario',
    last_name: 'Alessiani',
    portrait: { fileSrc: 'https://pickaface.net/gallery/avatar/katon5224739f478e0.png', fileType: 'image' },
    categories: [{ name: 'Furniture', slug: 'furniture' }],
    location: 'Italy',
    experience: '7',
    uuid: '12',
    active_products: '3',
    active_briefs: '2',
  },
  {
    first_name: 'Lukas',
    last_name: 'Bazle',
    portrait: {
      fileSrc: 'https://pickaface.net/gallery/avatar/20130120_120455_2881_jjjfjffjfjf.png',
      fileType: 'image',
    },
    categories: [{ name: 'Furniture', slug: 'furniture' }],
    location: 'Italy',
    experience: '3',
    uuid: '123',
    active_products: '3',
    active_briefs: '2',
  },
  {
    first_name: 'Frances',
    last_name: 'Boyd',
    portrait: { fileSrc: 'https://pickaface.net/gallery/avatar/hansss56bc314b1d363.png', fileType: 'image' },
    categories: [
      { name: 'Furniture', slug: 'furniture' },
      { name: 'Ash', slug: 'ash' },
    ],
    location: 'France',
    experience: '8',
    uuid: '1234',
    active_products: '3',
    active_briefs: '2',
  },
  {
    first_name: 'Gayathri',
    last_name: 'Nair',
    portrait: {
      fileSrc: 'https://pickaface.net/gallery/avatar/unr_adrianluizm_190409_2356_bqm3.png',
      fileType: 'image',
    },
    categories: [
      { name: 'Furniture', slug: 'furniture' },
      { name: 'Plastic', slug: 'plastic' },
    ],
    location: 'India',
    experience: '2',
    uuid: '12345',
    active_products: '3',
    active_briefs: '2',
  },
  {
    first_name: 'John',
    last_name: 'Tree',
    portrait: { fileSrc: 'https://pickaface.net/gallery/avatar/reneiviki.152c470109854f.png', fileType: 'image' },
    categories: [{ name: 'Furniture', slug: 'furniture' }],
    location: 'United Kingdom',
    experience: '17',
    uuid: '123456',
    active_products: '3',
    active_briefs: '2',
  },
];
