import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { alpha, useTheme } from '@mui/material/styles';
import { useRef } from 'react';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const GradientLineGraph = ({ labels, data, tooltipLabelText, suggestedMaximum, title }) => {
  const theme = useTheme();
  const chartRef = useRef(null);
  if (!labels || !data) return null;
  const largestValue = Math.max(...data);
  const suggestedMax = largestValue / 2 + largestValue;

  return (
    <Line
      ref={chartRef}
      options={{
        layout: {
          padding: 0,
        },
        elements: {
          line: {
            fill: true,
          },
          point: {
            radius: 0,
          },
        },
        responsive: true,
        scales: {
          yAxes: {
            beginAtZero: true,
            suggestedMax: suggestedMaximum || suggestedMax,
            grid: {
              drawBorder: false,
              color: theme.palette.grey[100],
            },
            ticks: {
              color: theme.palette.text.primary,
              font: {
                family: theme.typography.fontFamily,
                color: theme.palette.text.primary,
                size: 12,
                weight: 200,
              },
              beginAtZero: true,
              callback: function (value) {
                if (value % 1 === 0) {
                  return value;
                }
              },
            },
          },
          xAxes: {
            grid: {
              color: 'transparent',
              drawBorder: false,
            },
            ticks: {
              color: theme.palette.text.primary,
              font: {
                family: theme.typography.fontFamily,
                color: theme.palette.text.primary,
                size: 12,
                weight: 200,
              },
            },
            title: {
              display: true,
              text: title || 'Label',
              padding: {
                top: 20,
                bottom: 0,
              },
              color: theme.palette.text.primary,
              font: {
                family: theme.typography.fontFamily,
                color: theme.palette.text.primary,
                size: 12,
                weight: 200,
              },
            },
          },
        },

        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      }}
      data={{
        labels,
        datasets: [
          {
            label: tooltipLabelText,
            data,
            borderColor: theme.palette.success.light,
            backgroundColor: (context) => {
              const ctx = context.chart.ctx;
              const height = context?.chart?.chartArea?.height || 200;
              const gradient = ctx.createLinearGradient(0, height, 0, 100);
              gradient.addColorStop(1, alpha(theme.palette.success.main, 0.11));
              gradient.addColorStop(1, alpha(theme.palette.success.main, 0.13));
              gradient.addColorStop(0.1, alpha(theme.palette.success.main, 0.01));
              return gradient;
            },
            tension: 0.2,
          },
        ],
      }}
    />
  );
};

export default GradientLineGraph;
