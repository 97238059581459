import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import BDArrowBorder from 'assets/svgs/bd-arrow-border.svg';

import { ListItemButtonStyled, ListItemIconStyled, ListItemStyled } from './ProductPropertyListItem.styles';

const ProductPropertyListItem = ({
  label,
  value,
  onClick,
  has_nested,
  listIndex,
  isCategorySelected,
  isOptionSelected,
}) => {
  let icon;

  if (has_nested) {
    icon = <img src={BDArrowBorder} alt={'outlined arrow'} width="20px" height="8px" />;
  }

  if (isCategorySelected) {
    icon = <ArrowRightIcon fontSize="small" />;
  }

  if (isOptionSelected) {
    icon = <CheckIcon fontSize="small" />;
  }

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick({ label, value, has_nested, listIndex });
    }
  }, [has_nested, label, listIndex, onClick, value]);

  return (
    <ListItemStyled disablePadding>
      <ListItemButtonStyled onClick={handleClick}>
        <ListItemText primary={label} />
        <ListItemIconStyled>{icon}</ListItemIconStyled>
      </ListItemButtonStyled>
    </ListItemStyled>
  );
};

ProductPropertyListItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  onClick: PropTypes.func,
  has_nested: PropTypes.bool,
  isCategorySelected: PropTypes.bool,
  isOptionSelected: PropTypes.bool,
  listIndex: PropTypes.number,
};

export default React.memo(ProductPropertyListItem);
