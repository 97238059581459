import React from 'react';
import PropTypes from 'prop-types';

import BodyText from '../../Typography/BodyText/BodyText';
import { IconWrapper, ListItemStyled } from './PricingOptionsListItem.styles';

const PricingOptionsListItem = ({ listItem }) => {
  const optionIcon = listItem.icon;
  const icon = typeof optionIcon === 'string' ? <BodyText text={optionIcon} /> : optionIcon;

  return (
    <ListItemStyled>
      <BodyText text={listItem.label} />
      <IconWrapper>{icon}</IconWrapper>
    </ListItemStyled>
  );
};

PricingOptionsListItem.propTypes = {
  listItem: PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  }),
};

export default PricingOptionsListItem;
