import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { sanitize } from 'dompurify';

import { Box } from '@mui/material';
import BodyText from 'components/Typography/BodyText/BodyText';
import CaptionText from 'components/Typography/CaptionText/CaptionText';
import FileUploadIconThumbnail from 'components/FileUploadIconThumbnail/FileUploadIconThumbnail';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';

import {
  AvatarWrapper,
  DeleteIconStyled,
  DownloadIcon,
  FileWrapper,
  MessageContainer,
  MessageWrapper,
} from './Message.styles';

const Message = ({
  avatar,
  text,
  caption,
  variant,
  file_extension,
  file_uuid,
  handleDeleteFile,
  handleFileDownload,
  disableDownload,
}) => {
  return (
    <MessageContainer variant={variant}>
      <Box display="flex">
        {avatar && (
          <AvatarWrapper variant={variant}>
            <CustomAvatar imageUrl={avatar ? avatar.fileSrc : {}} fileType={avatar.fileType} />
          </AvatarWrapper>
        )}
        <Box>
          <MessageWrapper variant={variant}>
            {!file_extension ? (
              <BodyText text={parse(sanitize(text))} />
            ) : (
              <Box display="flex" alignItems="center">
                <FileWrapper mr={2}>
                  <FileUploadIconThumbnail fileType={file_extension} />
                </FileWrapper>
                <BodyText text={text} truncateWord={true} />

                {!disableDownload && (
                  <Box ml={2} display="flex">
                    {variant === 'sender' && <DeleteIconStyled onClick={() => handleDeleteFile(file_uuid)} />}
                    <DownloadIcon onClick={() => handleFileDownload(file_uuid, text)} />
                  </Box>
                )}
              </Box>
            )}
          </MessageWrapper>
          <Box ml={2}>
            <CaptionText text={caption} />
          </Box>
        </Box>
      </Box>
    </MessageContainer>
  );
};

Message.propTypes = {
  text: PropTypes.string,
  caption: PropTypes.string,
  variant: PropTypes.oneOf(['sender', 'receiver']),
  file_extension: PropTypes.string,
  file_uuid: PropTypes.string,
};

export default Message;
