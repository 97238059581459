import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const FormErrorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2),
  background: theme.palette.background.error,
  borderRadius: theme.shape.borderRadius,
  alignItems: 'center',
}));

export const FormErrorStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.alertText,
  fontWeight: 600,
}));

export const FormErrorTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.alertText,
}));

export const InfoOutlinedIconStyled = styled(InfoOutlinedIcon)(({ theme }) => ({
  margin: `2px ${theme.spacing(1)} 0 0`,
  color: theme.palette.error.main,
}));
