import { yupObject, validation } from 'utils/validation';

const schema = {
  ...validation.newTermsFields,
};

const firstStepValidation = yupObject().shape(schema, ['agreement_file', 'agreement_body']);

export const formValidation = [firstStepValidation];

export const formDefaultValues = {
  //step 1
  agreement_title: '',
  agreement_body: '',
  agreement_file: [],
};

export const steps = ['Terms Details'];
