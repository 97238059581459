import { Box, styled } from '@mui/material';
import { MAX_WIDTH, MD_WIDTH } from 'styles/constants';

export const SearchResultsSearchBarContainer = styled(Box)(({ theme, showSearch, isDashboard }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.default,
  position: 'absolute',
  zIndex: 1001,
  opacity: !showSearch ? 0 : 1,
  transformOrigin: 'top',
  transform: !showSearch ? 'translateY(100%) scaleY(1)' : 'translateY(100%) scaleY(1)',
  pointerEvents: !showSearch ? 'none' : 'initial',
  bottom: isDashboard ? -50 : 0,
  transition: 'all 200ms ease-out',
  boxShadow: '6px 0px 27px 2px rgba(154, 154, 154, 0.155)',
  '& .BD-SearchResultsContainer': {
    pointerEvents: !showSearch ? 'none' : 'initial',
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: '90vh',
    overflowY: 'auto',
  },
}));

export const ProductsMegaMenuWrapper = styled(Box)(({ theme, showMegaMenu, isDashboard }) => ({
  backgroundColor: theme.palette.background.default,
  padding: `${theme.spacing(3)} 0`,
  maxWidth: MAX_WIDTH,
  width: MD_WIDTH,
  position: 'absolute',
  zIndex: 1001,
  opacity: !showMegaMenu ? 0 : 1,
  transformOrigin: 'top',
  transform: !showMegaMenu ? 'translateY(100%) scaleY(1)' : 'translateY(100%) scaleY(1)',
  pointerEvents: !showMegaMenu ? 'none' : 'initial',
  bottom: isDashboard ? -30 : 0,
  transition: 'all 200ms ease-out',
  boxShadow: '6px 0px 27px 2px rgba(154, 154, 154, 0.155)',
  [theme.breakpoints.down('md')]: {
    padding: `${theme.spacing(2)} ${theme.spacing(2.5)}`,
    width: 'unset',
    bottom: isDashboard ? -60 : 0,
  },
  [theme.breakpoints.down('sm')]: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
    width: 'unset',
    bottom: isDashboard ? -50 : 0,
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: '90vh',
    overflowY: 'auto',
  },
}));
