import { useEffect, useCallback } from 'react';

import { routes } from '../../../options/routes';

const Logout = () => {
  const handleLogout = useCallback(() => {
    localStorage.clear();
    window.location.href = routes.login;
  }, []);

  useEffect(() => {
    handleLogout();
  }, [handleLogout]);

  return <></>;
};

export default Logout;
