import { styled } from '@mui/material';

export const CustomFileUploadContainerStyled = styled('div', {
  shouldForwardProp: (props) => props !== 'numberOfTiles',
})(({ theme, numberOfTiles, error }) => ({
  height: '150px',
  position: 'relative',
  backgroundColor: theme.palette.background.gray,
  borderRadius: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: error ? `1px solid ${theme.palette.error.light}` : '',
  // [theme.breakpoints.down('sm')]: {
  //   height: numberOfTiles === 1 ? '210px' : '150px',
  // },
  '&:hover': {
    backgroundColor: '#FDF4EC',
  },
}));

export const CustomFileUploadFieldStyled = styled('input')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  '&:hover': {
    backgroundColor: '#FDF4EC',
  },
}));

export const FileUploadBackgroundStyled = styled('div')(() => ({
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  '&:hover': {
    backgroundColor: '#FDF4EC',
  },
}));
