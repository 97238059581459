import { Box, styled } from '@mui/material';
import { MAX_WIDTH, MD_WIDTH } from 'styles/constants';

export const StyledSearchFieldContainer = styled(Box)(({ theme }) => ({
  width: MD_WIDTH,
  margin: 'auto',
  marginTop: theme.spacing(4),
  backgroundColor: theme.palette.background.default,
  padding: theme.spacing(2),
  maxWidth: MAX_WIDTH,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    width: 'unset',
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
}));

export const StyledSearchFieldWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  marginRight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  borderBottom: '2px solid ',
  '& .MuiFormControl-root': {
    marginTop: 0,
  },
}));

export const StyledSearchResultsContainer = styled(Box)(({ theme, searchTerm }) => ({
  backgroundColor: theme.palette.background.default,
  position: 'absolute',
  width: '100%',
  boxSizing: 'border-box',
  minHeight: '300px',
  padding: theme.spacing(3),
  paddingTop: 0,
  left: '50%',
  bottom: '0px',
  transition: 'all 300ms ease',
  transform: searchTerm?.length >= 3 ? 'translateX(-50%) translateY(100%)' : 'translateX(-50%) translateY(102%)',
  opacity: searchTerm?.length >= 3 ? 1 : 0,
  pointerEvents: searchTerm?.length >= 3 ? 'initial' : 'none',
  [theme.breakpoints.down('md')]: {
    paddingTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1.5),
  },
  // grid stylings for this component in attached css file
}));

export const SearchResultItemWrapper = styled(Box)(({ theme, gridName }) => ({
  gridArea: gridName,
  // grid stylings for this component in attached css file
  width: '100%',
  '& img': {
    maxHeight: '225px',
    aspectRatio: '1/1',
  },
}));

export const HorizontalLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: 'black',
  bottom: '-16px',
  width: 'calc(100% - 24px)',
  left: '50%',
  transform: 'translateX(-50%)',
  height: '2px',
}));

export const LoadingContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  '& > *': {
    transform: 'scale(0.6)',
  },
}));

export const StyledRefinedSearchContentContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 12,
}));

export const StyledRefinedPillWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
}));

export const StyledForm = styled('form')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: '750px',
  [theme.breakpoints.down('md')]: {
    minHeight: '800px',
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: '850px',
  },
  [theme.breakpoints.down(380)]: {
    minHeight: '120vh',
  },
}));

export const StyledNoSearchTermContainer = styled(Box)(({ theme }) => ({
  width: MD_WIDTH,
  maxWidth: MAX_WIDTH,
  margin: 'auto',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: 'unset',
  },
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
}));

export const NoResultsFoundWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  boxSizing: 'border-box',
  minHeight: '300px',
  padding: theme.spacing(3),
  paddingTop: 0,
  left: '50%',
  bottom: '0px',
  transition: 'all 300ms ease',
  transform: 'translateX(-50%) translateY(100%)',
}));
