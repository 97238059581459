import React from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { CustomTabGroupWrapper } from './CustomTabGroup.styles';
import NavTab from 'components/NavTab/NavTab';

const CustomTabGroup = ({ activeTab, options }) => {
  const navigate = useNavigate();
  return (
    <CustomTabGroupWrapper>
      {options &&
        options.map((option) => (
          <NavTab
            key={`navtab-${option.label}`}
            label={option.label}
            onClick={() => navigate(option.route)}
            active={activeTab === option.route}
          />
        ))}
    </CustomTabGroupWrapper>
  );
};

CustomTabGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      route: PropTypes.string,
    })
  ),
  activeTab: PropTypes.string,
};

export default CustomTabGroup;
