import React, { useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FileUploadThumbnail from '../FileUploadThumbnail/FileUploadThumbnail';
import CustomFileUploadField from '../CustomFileUploadField/CustomFileUploadField';

import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';

import { transformToBase64List } from 'utils/convertToBase64';
import { TilesContainerStyled } from './CustomFileUploadContainerDND.styles';

const CustomFileUploadContainerDND = ({
  variant,
  fileTypes,
  numberOfTiles,
  selectedFiles,
  handleChange,
  removeFile,
  setError,
  error,
  handleReorderUpdate,
}) => {
  const selectedVariant = numberOfTiles === 1 ? 'single' : variant;

  const changeHandler = async (event) => {
    const eventFile = event.target.files;
    const sumOfPrevSelectionWithNew = selectedFiles.length + eventFile.length;

    // will this selection go over NUMBER_OF_TILES?
    if (sumOfPrevSelectionWithNew > numberOfTiles) {
      return setError(`You can only select up to ${numberOfTiles} files.`);
    }

    const base64Files = await transformToBase64List(eventFile);
    handleChange(base64Files.map((f, index) => ({ ...f, order: index + 1 })));
  };

  const handleRemoveFile = (index) => {
    if (selectedFiles.length === numberOfTiles) removeFile(index);
    else removeFile(index - 1);
  };

  const tiles = useMemo(() => {
    if (selectedFiles.length === numberOfTiles) {
      return [...selectedFiles];
    }
    return ['input', ...selectedFiles];
  }, [selectedFiles, numberOfTiles]);

  const handleDrop = (e) => {
    if (!e.destination) return;
    const { destination, source } = e;

    const srcIndex = source.index;
    const destIndex = destination.index;

    const result = Array.from(tiles);
    const [removed] = result.splice(srcIndex, 1);

    result.splice(destIndex, 0, removed);

    const newOrderedArray = result.filter((tile) => tile !== 'input').map((f, index) => ({ ...f, order: index + 1 }));
    if (handleReorderUpdate) {
      handleReorderUpdate(newOrderedArray);
    }
  };

  return (
    <div>
      {tiles && (
        <>
          <DragDropContext onDragEnd={(dropEvent) => handleDrop(dropEvent)}>
            <Droppable direction={'horizontal'} droppableId={`${selectedVariant}-dragndrop-tiles-${Math.random()}`}>
              {(provided) => (
                <div
                  style={{ maxWidth: '100vw', overflowX: 'auto' }}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  <TilesContainerStyled numberOfTiles={numberOfTiles}>
                    {tiles?.map((fileDetails, index) => {
                      if (fileDetails === 'input') {
                        return (
                          <Draggable
                            isDragDisabled={true}
                            disableInteractiveElementBlocking={true}
                            index={index}
                            draggableId={`-draggableid-${index}`}
                            key={`draggableinput_${index}`}
                          >
                            {(provided) => (
                              <div {...provided.draggableProps} ref={provided.innerRef}>
                                <CustomFileUploadField
                                  onChangeHandler={changeHandler}
                                  numberOfTiles={numberOfTiles}
                                  variant={selectedVariant}
                                  fileTypes={fileTypes}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      } else {
                        return (
                          <Draggable
                            index={index}
                            draggableId={fileDetails.name}
                            key={fileDetails.name}
                            disableInteractiveElementBlocking={false}
                          >
                            {(provided) => (
                              <div {...provided.dragHandleProps} {...provided.draggableProps} ref={provided.innerRef}>
                                <FileUploadThumbnail
                                  fileSrc={fileDetails && fileDetails.fileSrc}
                                  name={fileDetails && fileDetails.name}
                                  numberOfTiles={numberOfTiles}
                                  removeFile={() => handleRemoveFile(index)}
                                  index={index}
                                  fileType={fileDetails && fileDetails.fileType}
                                />
                              </div>
                            )}
                          </Draggable>
                        );
                      }
                    })}
                    {provided.placeholder}
                  </TilesContainerStyled>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <Box height="20px">{error && <ErrorMessage>{error}</ErrorMessage>}</Box>
        </>
      )}
    </div>
  );
};

CustomFileUploadContainerDND.propTypes = {
  variant: PropTypes.oneOf(['single', 'multi']),
  fileTypes: PropTypes.oneOf(['images', 'files', 'pdf', 'all']),
  numberOfTiles: PropTypes.number,
  error: PropTypes.string,
};

export default CustomFileUploadContainerDND;
