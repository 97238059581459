import React, { useMemo } from 'react';
import {
  DashboardItem,
  DashboardRowOne,
  DashboardWrapperStyled,
  RecentMarketplaceContainer,
} from 'components/BuyerLoginDashboardComponent/BuyerLoginDashboardComponent.styles';
import ScoreCard from 'components/ScoreCard/ScoreCard';
import DashboardItemList from 'components/DashboardItemList/DashboardItemList';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import { LoadingContainer } from 'components/ProductsMegaMenu/ProductsMegaMenu.styles';
import Spinner from 'components/Spinner/Spinner';
import { Box } from '@mui/system';
import { useQuery } from 'react-query';
import DesignerDashboardProductSalesTable from 'components/DesignerDashboardProductSalesTable/DesignerDashboardProductSalesTable';
import GradientLineGraph from 'components/GradientLineGraph/GradientLineGraph';
import GraphSectionTitle from 'components/Typography/GraphSectionTitle/GraphSectionTitle';
import {
  loginDashboardBorderString,
  loginDashboardTabletBorders,
} from 'components/BuyerLoginDashboardComponent/BuyerLoginDashboardComponent';
import { useMediaQuery } from '@mui/material';
import { getDesignerLoginDashboardData } from 'api';
import { useAuthContext } from 'context/AuthContext';
import { CURRENCY_MAP } from 'options/currency';

const DesignerLoginDashboardComponent = ({ mockData, isLoading, isStory }) => {
  const dashboardFetch = useQuery(['buyerLoginDashboard'], () => getDesignerLoginDashboardData(), {
    enabled: !isStory,
    staleTime: 5 * 60 * 1000,
    refetchOnMount: 'always',
  });

  const { currency } = useAuthContext();
  const currencyIcon = currency ? CURRENCY_MAP[currency] : '£';

  const data = useMemo(
    () => (isStory ? mockData : dashboardFetch?.data?.dashboard_data || {}),
    [mockData, isStory, dashboardFetch?.data?.dashboard_data]
  );

  const {
    last_views,
    last_five_products,
    total_products_sold,
    total_earnings,
    active_direct_sales,
    active_briefs,
    products_sold_thirty_days,
  } = data;

  const isTablet = useMediaQuery((theme) => theme.breakpoints.down(1200));

  const LOADING_MIN_HEIGHT = isTablet ? 100 : 200;

  const loadingStyles = !isTablet
    ? {
        borderTop: loginDashboardBorderString,
        borderRight: loginDashboardBorderString,
        minHeight: LOADING_MIN_HEIGHT,
      }
    : { ...loginDashboardTabletBorders, minHeight: LOADING_MIN_HEIGHT };

  const lastViews = useMemo(
    () =>
      last_views ? last_views.map((item) => ({ label: item.product_name, caption: item.date_viewed, uuid: item.product_uuid })) : [],
    [last_views]
  );

  const thirtyDaysGraphData = useMemo(() => {
    if (products_sold_thirty_days && !isStory) {
      let labels = [];
      let data = [];
      Object.entries(products_sold_thirty_days).forEach((item) => {
        labels.push(item[0]);
        data.push(item[1]);
      });
      return { labels, data, tooltipLabelText: 'Transaction' };
    } else return isStory ? products_sold_thirty_days : {};
  }, [products_sold_thirty_days, isStory]);

  return (
    <DashboardWrapperStyled isLoading={isLoading}>
      <DashboardRowOne>
        {(!dashboardFetch.data && !isStory) || (dashboardFetch.isLoading && !isStory) ? (
          <>
            <DashboardItem sx={loadingStyles} gridColumn={'1 / 2'} gridRow={'1 / 2 '}>
              <Box padding={'24px'}>
                <Spinner align={'center'} />
              </Box>
            </DashboardItem>
            <DashboardItem sx={loadingStyles} gridColumn={'2 / 3'} gridRow={'1 / 2 '}>
              <Box padding={'24px'}>
                <Spinner align={'center'} />
              </Box>
            </DashboardItem>
            <DashboardItem sx={loadingStyles} gridColumn={'1 / 2'} gridRow={'2 / 3 '}>
              <Box padding={'24px'}>
                <Spinner align={'center'} />
              </Box>
            </DashboardItem>
            <DashboardItem sx={loadingStyles} gridColumn={'2 / 3'} gridRow={'2 / 3 '}>
              <Box padding={'24px'}>
                <Spinner align={'center'} />
              </Box>
            </DashboardItem>
          </>
        ) : (
          <>
            <ScoreCard
              sx={
                !isTablet
                  ? { borderTop: loginDashboardBorderString, borderRight: loginDashboardBorderString, paddingLeft: 0 }
                  : loginDashboardTabletBorders
              }
              gridColumn={'1 / 2'}
              gridRow={'1'}
              title={'Total Completed'}
              value={total_products_sold}
            />
            <ScoreCard
              sx={
                !isTablet
                  ? { borderTop: loginDashboardBorderString, borderRight: loginDashboardBorderString }
                  : loginDashboardTabletBorders
              }
              gridColumn={'2 / 3'}
              gridRow={'1'}
              title={'Revenue'}
              value={total_earnings ? currencyIcon + total_earnings.toLocaleString() : currencyIcon + '0.00'}
            />
            <ScoreCard
              sx={
                !isTablet
                  ? { borderTop: loginDashboardBorderString, borderRight: loginDashboardBorderString, paddingLeft: 0 }
                  : loginDashboardTabletBorders
              }
              gridColumn={'1 / 2'}
              gridRow={'2'}
              title={'Active Briefs'}
              value={active_briefs}
            />
            <ScoreCard
              sx={
                !isTablet
                  ? { borderTop: loginDashboardBorderString, borderRight: loginDashboardBorderString }
                  : loginDashboardTabletBorders
              }
              gridColumn={'2 / 3'}
              gridRow={'2'}
              title={'Active Sales'}
              value={active_direct_sales}
            />
          </>
        )}
        <DashboardItem
          sx={!isTablet ? { borderTop: loginDashboardBorderString, paddingRight: 0 } : loginDashboardTabletBorders}
          showPadding
          gridColumn={'3 / 4'}
          gridRow={'1 / 3 '}
        >
          {(!dashboardFetch.data && !isStory) || (dashboardFetch.isLoading && !isStory) ? (
            <Box padding={'24px'}>
              <Spinner align={'center'} />
            </Box>
          ) : (
            <>
              <Box mb={2}>
                <GraphSectionTitle text={'Total sold in the last 30 days'} />
              </Box>
              <Box>
                <GradientLineGraph title={'Day'} {...thirtyDaysGraphData} />
              </Box>
            </>
          )}
        </DashboardItem>
        <DashboardItem
          sx={
            !isTablet
              ? { borderTop: loginDashboardBorderString, borderRight: loginDashboardBorderString, paddingLeft: 0 }
              : loginDashboardTabletBorders
          }
          showPadding
          showBorder
          gridColumn={'1 / 2'}
          gridRow={'3'}
        >
          {(!dashboardFetch.data && !isStory) || (dashboardFetch.isLoading && !isStory) ? (
            <Box padding={'24px'}>
              <Spinner align={'center'} />
            </Box>
          ) : (
            <>
              <Box mb={2}>
                <GraphSectionTitle text={'Recent product views'} />
              </Box>
              <RecentMarketplaceContainer>
                <DashboardItemList data={lastViews} />
              </RecentMarketplaceContainer>
            </>
          )}
        </DashboardItem>
        <DashboardItem
          sx={!isTablet ? { borderTop: loginDashboardBorderString, paddingRight: 0 } : loginDashboardTabletBorders}
          showPadding
          showBorder
          gridColumn={'2 / 4'}
          gridRow={'3'}
        >
          {(!dashboardFetch.data && !isStory) || (dashboardFetch.isLoading && !isStory) ? (
            <Box padding={'24px'}>
              <Spinner align={'center'} />
            </Box>
          ) : (
            <>
              <Box mb={2}>
                <GraphSectionTitle text={'Product history'} />
                </Box>
                <RecentMarketplaceContainer>
                  <DesignerDashboardProductSalesTable
                    data={[...last_five_products] || []}
                    total={last_five_products?.length || 0}
                  />
                </RecentMarketplaceContainer>
            </>
          )}
        </DashboardItem>
      </DashboardRowOne>
    </DashboardWrapperStyled>
  );
};

DesignerLoginDashboardComponent.propTypes = {};

export default DesignerLoginDashboardComponent;
