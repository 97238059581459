import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import CustomDatePicker from '../CustomDatePicker';

const ControlledDatePicker = ({ name, label, showSuccess, views, inputFormat, showError, disableFuture }) => {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => {
        const isSuccess = showSuccess && !!(!fieldState.error && value);
        return (
          <CustomDatePicker
            onChange={async (e) => {
              // force validation onChange
              onChange(e);
              await trigger(name);
            }}
            value={value}
            label={label}
            error={fieldState.error?.message}
            success={isSuccess}
            views={views}
            inputFormat={inputFormat}
            showError={showError}
            disableFuture={disableFuture}
          />
        );
      }}
    />
  );
};

ControlledDatePicker.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default ControlledDatePicker;
