import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';
import CaptionText from 'components/Typography/CaptionText/CaptionText';

import { ItemInfoContainer, ItemInfoTextWrapper } from './ItemInfo.styles';

import { CUSTOM_PROP_TYPES } from 'options/customPropTypes';

const ItemInfo = ({ avatar, label, subtext, actions }) => {
  const { fileSrc, fileType } = avatar || {};
  return (
    <ItemInfoContainer>
      {fileSrc && (
        <Box mr={2}>
          <CustomAvatar imageUrl={fileSrc} fileType={fileType} />
        </Box>
      )}
      <ItemInfoTextWrapper>
        <TableBodyText text={label} truncateWord />
        {subtext && <CaptionText text={subtext} />}
      </ItemInfoTextWrapper>
      {actions && !!actions.length && (
        <Box>
          <ThreeDotMenu actions={actions} variant="vertical" />
        </Box>
      )}
    </ItemInfoContainer>
  );
};

ItemInfo.propTypes = {
  avatar: CUSTOM_PROP_TYPES.IMAGE,
  label: PropTypes.string.isRequired,
  subtext: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      to: PropTypes.string,
    })
  ),
};

export default ItemInfo;
