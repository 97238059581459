import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';

export const FormLabelStyled = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'showBorder',
})(({ theme, showBorder }) => ({
  border: showBorder ? `1px solid ${theme.palette.common.black}` : '',
  borderRadius: showBorder ? '100px' : '',
  padding: showBorder ? theme.spacing(1) : 0,
}));
