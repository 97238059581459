import { validation, yupObject } from 'utils/validation';
import SignUpAccount from '../components/SignUp/SignUpAccount/SignUpAccount';
import DesignerSignUpProfile from '../components/SignUp/DesignerSignUpProfile/DesignerSignUpProfile';
import SignUpAgreement from '../components/SignUp/SignUpAgreement/SignUpAgreement';
import SignUpBuyerProfile from '../components/SignUp/SignUpBuyerProfile/SignUpBuyerProfile';
import PricingOptionsTable from '../components/PricingOptionsTable/PricingOptionsTable';

import { COMPANY_OPTIONS, PRODUCTS_OPTIONS, SPACES_OPTIONS } from 'options/selectOptions';

const {
  personalFields: { first_name, last_name },
} = validation;

const {
  pictureFields: { portrait, logo },
  accountFields: { email, password, confirm_password },
  designerStudioFields: { studio_name, studio_country },
  designerGeneralFields: { website, designer_since },
  selectOptionsDesigner: { product_types },
  companyGeneralFields: { name, established_since },
  buyerPersonalFields: { position },
  companyAddressFields: { country },
  terms: { terms_accepted },
} = validation;

const firstStepValidation = yupObject({
  first_name,
  last_name,
  email,
  password,
  confirm_password,
});

const secondStepDesignerValidation = yupObject({
  portrait,
  studio_name,
  studio_country,
  website,
  product_types,
  designer_since,
});

const secondStepBuyerValidation = yupObject({
  logo,
  name,
  position,
  country,
  website,
  established_since,
  ...validation.selectOptionsBuyer,
});

const thirdStepValidation = yupObject({
  terms_accepted,
});

export const designerStepperArgs = {
  formValues: {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',

    portrait: [],
    studio_name: '',
    studio_country: null,
    website: '',
    product_types: [],
    designer_since: null,

    terms_accepted: false,
    mailchimp_subscribed: false,
  },
  formValidation: [firstStepValidation, secondStepDesignerValidation, thirdStepValidation],
  headerList: [
    {
      titleText: 'Create a Designer account',
      description: 'Start getting your ideas infront of industry leading companies.',
    },
    {
      titleText: 'Create Designer account',
      description: 'Start getting your ideas infront of industry leading companies.',
    },
    { titleText: 'BuyDesign Terms & Conditions', description: '' },
  ],
  componentsList: [
    <SignUpAccount />,
    <DesignerSignUpProfile options={PRODUCTS_OPTIONS} />,
    <SignUpAgreement isDesigner />
  ],
};

export const buyerStepperArgs = {
  formValues: {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',

    logo: [],
    name: '',
    position: '',
    country: null,
    website: '',
    established_since: null,
    product_interests: [],
    buyer_spaces: [],
    buyer_categories: [],

    terms_accepted: false,
    mailchimp_subscribed: false,
  },
  formValidation: [firstStepValidation, secondStepBuyerValidation, thirdStepValidation, yupObject({})],
  headerList: [
    {
      titleText: 'Create a Buyer account',
      description:
        'You will be admin for the account, after completing your application you can invite your colleagues to join the team.',
      helper: 'Please complete all fields',
    },
    { titleText: 'Complete Buyer Profile', description: '', helper: 'Please complete all fields' },
    { titleText: 'BuyDesign Terms & Conditions', description: '', helper: '' },
    {
      // TODO: Replace below lines with commented out lines when re-introducing buyer Stripe subscription requirements
      titleText: 'Buyer account',
      description: 'Here are the benefits you will be entitled to with your account.',
      // titleText: 'Buyer subscriptions',
      // description: 'Get started for free today, choose the account that works best for your business.',
    },
  ],
  componentsList: [
    <SignUpAccount />,
    <SignUpBuyerProfile
      productsOptions={PRODUCTS_OPTIONS}
      companyOptions={COMPANY_OPTIONS}
      spacesOptions={SPACES_OPTIONS}
    />,
    <SignUpAgreement />,
    <PricingOptionsTable />,
  ],
};
