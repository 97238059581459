import React from 'react';
import { useNavigate } from 'react-router';
import parse from 'html-react-parser';
import { sanitize } from 'dompurify';
import PropTypes from 'prop-types';

import { PILL_STATUSES } from 'options/pillStatusOptions';

import { StatusPillStyled } from './StatusPill.styles';

const StatusPill = ({ label, icon, status }) => {
  const navigate = useNavigate();

  return (
    <StatusPillStyled
      label={parse(sanitize(label), {
        replace: (element) => {
          if (element?.attribs?.id === 'chat-link') {
            const host = window?.location?.host;
            const path = element?.attribs?.href.replace(new RegExp(`https?://${host}`), '');
            const text = element?.children[0]?.data;

            return (
              <a
                href={path}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(path);
                }}
              >
                {text}
              </a>
            );
          }
          return element;
        },
      })}
      icon={icon}
      variant="outlined"
      color={status === PILL_STATUSES.error ? 'error' : status === PILL_STATUSES.warning ? 'warning' : 'success'}
    />
  );
};

StatusPill.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.element,
  status: PropTypes.string.isRequired,
};

export default StatusPill;
