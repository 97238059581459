import { styled } from '@mui/system';
import { TextFieldStyled } from '../TextFields/CustomTextField/CustomTextField.styles';

export const CustomDatePickerStyled = styled(TextFieldStyled)(({ theme }) => ({
  width: '100%',
  '& .MuiOutlinedInput-input:hover': {
    borderColor: theme.palette.background.pink,
  },
  '& .MuiIconButton-root': {
    marginRight: '-11px',

    '&:hover': {
      borderRadius: '100%',
    },
  },
}));
