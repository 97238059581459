import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { debounce } from 'debounce';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import CustomCheckboxStandard from 'components/Inputs/CustomCheckboxStandard/CustomCheckboxStandard';
import PillTag from 'components/PillTag/PillTag';
import ModalListItemCheckbox from 'components/ModalListItemCheckbox/ModalListItemCheckbox';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';
import Spinner from 'components/Spinner/Spinner';
import BodyText from 'components/Typography/BodyText/BodyText';

import { DESIGNER_COUNTRIES } from 'options/countries';
import { CATEGORIES_OPTIONS, EXPERIENCE_OPTIONS } from 'options/findDesignerOptions';

import { ListStyled, PillWrapperStyled } from './ModalFindDesigner.styles';
import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

import { addFilters } from 'utils/addFilters';

import { getDesignersList } from 'api';

const FIND_DESIGNER_MENU = [
  {
    label: 'Categories',
    id: 'categories',
    actions: [],
  },
  {
    label: 'Location',
    id: 'location',
    actions: [],
  },
  {
    label: 'Experience',
    id: 'experience',
    actions: [],
  },
];

const DESIGNERS_LIMIT = 10;
const DESIGNERS_VALUE = 'designers';

const ModalFindDesigner = ({ open, handleClose, isStory, mockData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [favouriteDesigners, setFavouriteDesigners] = useState(true);
  const [designersList, setDesignersList] = useState(isStory ? mockData : []);

  const [designersModalSelected, setDesignersModalSelected] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');

  const [filter, setFilter] = useState({});

  const { setValue, clearErrors, getValues } = useFormContext();

  const fetchDesigners = async (query) => {
    setIsLoading(true);
    const res = await getDesignersList(query);
    setIsLoading(false);
    if (!res || !res.success) {
      return;
    }
    const briefSelectedDesigners = getValues('designers');
    setDesignersList(res.designers);
    setDesignersModalSelected(briefSelectedDesigners);
  };

  const debouncedFetch = debounce((query) => fetchDesigners(query), 200);

  useEffect(() => {
    const query = [
      ['data_type=partial'],
      ...(searchTerm && searchTerm.length > 2 ? [`search=${searchTerm}`] : []),
      ...(filter.categories?.length ? filter.categories.map((value) => `categories=${value}`) : []),
      ...(filter.location?.length ? filter.location?.map((country) => `location=${country}`) : []),
      ...(filter.experience?.length ? filter.experience?.map((value) => `experience=${value}`) : []),
      ...(favouriteDesigners ? [`is_favourite=${favouriteDesigners}`] : []),
    ].join('&');

    debouncedFetch(query);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, favouriteDesigners, filter.categories, filter.location, filter.experience]);

  const handleFavouriteDesginers = useCallback((e, val) => {
    setFavouriteDesigners(val);
  }, []);

  const selectOption = useCallback(
    (option) => {
      if (designersModalSelected.length < DESIGNERS_LIMIT) {
        setDesignersModalSelected((prevState) => [...prevState, option]);
      }
    },
    [designersModalSelected.length]
  );

  const unSelectOption = useCallback((option) => {
    setDesignersModalSelected((prevState) => prevState.filter((selected) => selected.uuid !== option.uuid));
  }, []);

  const handleClick = useCallback(
    (option) => {
      const isSelected = !!designersModalSelected.find((v) => v.uuid === option?.uuid);
      if (!isSelected) {
        selectOption(option);
      } else {
        unSelectOption(option);
      }
    },
    [designersModalSelected, selectOption, unSelectOption]
  );

  const handleAccept = useCallback(() => {
    clearErrors(DESIGNERS_VALUE);
    setValue(DESIGNERS_VALUE, designersModalSelected);
    handleClose();
  }, [clearErrors, designersModalSelected, handleClose, setValue]);

  const handleCancel = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const filtersList = useMemo(
    () => ({
      categories: [...CATEGORIES_OPTIONS],
      location: [...DESIGNER_COUNTRIES],
      experience: [...EXPERIENCE_OPTIONS],
    }),
    []
  );

  const menuFilterOptions = useMemo(() => {
    return FIND_DESIGNER_MENU.map((menuItem) => {
      return {
        ...menuItem,
        actions: addFilters(menuItem, filtersList, setFilter, filter),
      };
    });
  }, [filter, filtersList]);

  return (
    <CustomModal title="Find Designer" open={open} handleClose={handleClose}>
      <StyledModalContentWrapper>
        <Box>
          <Box>
            <SearchAndFilterOptions
              placeholder="Search for designers"
              menuOptions={menuFilterOptions}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              isModal
            />
          </Box>
          <Box my={2}>
            <CustomCheckboxStandard
              label="My Favourite Designers"
              labelPlacement="end"
              onChange={handleFavouriteDesginers}
              checked={favouriteDesigners}
            />
          </Box>
          <PillWrapperStyled>
            {designersModalSelected?.map((d) => (
              <Box mb={0.5} key={d.uuid}>
                <PillTag option={{ label: `${d.first_name} ${d.last_name}`, ...d }} removeSelection={handleClick} />
              </Box>
            ))}
          </PillWrapperStyled>
          <Box width="100%">
            {isLoading && <Spinner align="center" flex={0} />}{' '}
            {!isLoading && !designersList?.length && <BodyText text="No results found" />}
            {!isLoading && !!designersList?.length && (
              <ListStyled>
                {designersList?.map((designer) => {
                  const isSelected = !!designersModalSelected.find((v) => v?.uuid === designer?.uuid);
                  return (
                    <ModalListItemCheckbox
                      onClick={handleClick}
                      option={{
                        label: `${designer.first_name} ${designer.last_name}`,
                        ...designer,
                      }}
                      checked={isSelected}
                      key={designer.uuid}
                    />
                  );
                })}
              </ListStyled>
            )}
          </Box>
        </Box>
        <StyledActionButtonsContainer>
          <CustomButton text="Cancel" variant="outlined" onClick={handleCancel} fullWidth />
          <CustomButton text="Accept" colour="primary" onClick={handleAccept} fullWidth />
        </StyledActionButtonsContainer>
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

ModalFindDesigner.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  isStory: PropTypes.bool,
  mockData: PropTypes.array,
};

export default ModalFindDesigner;
