import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import BodyText from 'components/Typography/BodyText/BodyText';
import BriefAccordion from 'components/BriefAccordion/BriefAccordion';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { userTypes } from 'options/userTypes';
import { BriefImageGroup } from 'components/BriefImageGroup/BriefImageGroup';

export const labels = {
  product_category: 'Category:',
  material_tag: 'Material:',
  space_tag: 'Space:',
  style_tag: 'Style:',
  colour_tag: 'Colour:',
  sustainability_tags: 'Sustainability:',
};

const BriefSummary = ({
  briefDetails,

  handleDesignReqsEdit,
  handleTermsEngagementEdit,
  handleGeneralTermsEdit,

  onAcceptBrief,
  onDeclineBrief,
  userType,
  showButtons,

  acceptBtnText = 'Accept',
  rejectBtnText = 'Reject',

  disableTerms,

  filePreviewEnabled,
  isActive,
}) => {
  const {
    product_category,
    material_tag,
    space_tag,
    style_tag,
    colour_tag,
    sustainability_tags,
    main_image,
    additional_images,
    title,
  } = briefDetails;

  const productProperties = {
    product_category,
    material_tag,
    space_tag,
    style_tag,
    colour_tag,
    sustainability_tags,
  };

  const images = useMemo(
    () => [...(main_image ? main_image : []), ...(additional_images ? additional_images : [])],
    [main_image, additional_images]
  );

  return (
    <Box mb={2}>
      <Box mt={2}>
        {title && (
          <Box display="flex">
            <Box mr={1}>
              <BodyText text="Name:" bold />
            </Box>
            <BodyText text={title} />
          </Box>
        )}
        {Object.entries(productProperties)
          ?.filter(([_key, value]) => value?.length)
          .map(([key, value]) => {
            return (
              <Box display="flex" key={key}>
                <Box mr={1}>
                  <BodyText text={labels[key]} bold />
                </Box>
                <BodyText text={value?.map(({ label }) => label).join(', ')} />
              </Box>
            );
          })}
      </Box>
      <Box mt={2}>
        <BriefImageGroup images={images} />
      </Box>
      <Box mt={2}>
        <BriefAccordion
          briefDetails={briefDetails}
          handleDesignReqsEdit={handleDesignReqsEdit}
          handleTermsEngagementEdit={handleTermsEngagementEdit}
          handleGeneralTermsEdit={handleGeneralTermsEdit}
          disableTerms={disableTerms}
          filePreviewEnabled={filePreviewEnabled}
          isActive={isActive}
        />
      </Box>
      {userType === userTypes.designer && showButtons && (
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Box mr={2}>
            <CustomButton text={acceptBtnText} onClick={onAcceptBrief} colour="success" />
          </Box>
          <CustomButton text={rejectBtnText} onClick={onDeclineBrief} colour="error" />
        </Box>
      )}
    </Box>
  );
};

BriefSummary.propTypes = {
  productProperties: PropTypes.arrayOf(PropTypes.string),
  images: PropTypes.arrayOf(PropTypes.string),
  designReqs: PropTypes.string,
  customerReqs: PropTypes.string,
  commercialReqs: PropTypes.string,
  markets: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })),
  manuReqs: PropTypes.string,
  userType: PropTypes.oneOf(Object.values(userTypes)),
  isEdit: PropTypes.bool,
};

export default BriefSummary;
