import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

const ProductsNotFound = ({ message, btnText, onClick }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <CustomTitle variant="h3" text={message} />
      {btnText && onClick && (
        <Box mt={2}>
          <CustomButton text={btnText} onClick={onClick} />
        </Box>
      )}
    </Box>
  );
};

ProductsNotFound.propTypes = {
  message: PropTypes.string,
  btnText: PropTypes.string,
  onClick: PropTypes.func,
};

export default ProductsNotFound;
