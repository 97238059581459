import React from 'react';
import { Box, Link, Typography } from '@mui/material';

const ModalHelperText = ({ mb = 4 }) => {
  return (
    <Box mt={2} mb={mb}>
      <Typography fontSize={10}>
        BuyDesign does not store/keep/hold, share or transfer your email. If you need any assistance, contact us at{' '}
        <Link component="a" href="mailto:support@buydesign.com">
          support@buydesign.com
        </Link>{' '}
        Thank you.
        <br />
        The BuyDesign Team
      </Typography>
    </Box>
  );
};

export default ModalHelperText;
