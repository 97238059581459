import React from 'react';

import { Typography } from '@mui/material';

import { PrimaryFooterContainer, PrimaryFooterWrapper } from './PrimaryFooter.styles';

const PrimaryFooter = ({ variant }) => {
  const textStyle = variant === 'primary' ? 'body1' : 'bodyWhite';
  return (
    <PrimaryFooterContainer variant={variant}>
      <PrimaryFooterWrapper>
        <Typography variant={textStyle} style={{ fontSize: 14 }}>
          {`© ${new Date().getFullYear()} BuyDesign`}
        </Typography>
      </PrimaryFooterWrapper>
    </PrimaryFooterContainer>
  );
};

export default PrimaryFooter;
