import { useCallback } from 'react';
import BodyText from 'components/Typography/BodyText/BodyText';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { ListItemIconStyled, ListItemStyled } from './ModalListItemCheckbox.styles';

const ModalListItemCheckbox = ({ onClick, option, checked, isDisabled }) => {
  let icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

  if (checked || isDisabled) {
    icon = <CheckBoxIcon fontSize="small" />;
  }

  const handleOnClick = useCallback(() => {
    if (!isDisabled) {
      onClick(option, checked, isDisabled);
    }
  }, [checked, isDisabled, onClick, option]);

  return (
    <ListItemStyled onClick={handleOnClick} disabled={isDisabled}>
      <BodyText text={option.label} />
      <ListItemIconStyled>{icon}</ListItemIconStyled>
    </ListItemStyled>
  );
};

export default ModalListItemCheckbox;
