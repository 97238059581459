import { Link } from '@mui/material';
import { styled } from '@mui/system';

export const CustomTermsLinkStyled = styled(Link)(() => ({
  display: 'inline-block',
  fontFamily: 'inherit',
  fontSize: '14px',
  cursor: 'pointer',
  color: 'inherit',
}));
