import { useCallback, useState, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import Spinner from '../Spinner/Spinner';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import PillTag from 'components/PillTag/PillTag';
import StatusPill from 'components/StatusPill/StatusPill';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';
import ModalFindBuyer from 'components/ModalFindBuyer/ModalFindBuyer';
import ModalRetractPitch from 'components/ModalRetractPitch/ModalRetractPitch';

import { routes } from 'options/routes';
import { PILL_STATUS_ICON, PILL_STATUS_MAP, DESIGNER_PITCH_PILL_STATUS_TEXT } from 'options/pillStatusOptions';
import { PRODUCT_STATUS, DESIGNER_PITCH_STATUS } from 'options/productStatus';
import { designerPitchListHeadings } from '../../options/tableOptions';
import { OPTIONS_TYPE } from 'options/selectOptions';

import { getUniqueList } from 'utils/getUniqueList';
import { getErrorMessage } from 'utils/getErrorMessage';
import { addFilters } from 'utils/addFilters';
import { parseRemoteProductProposalData } from 'utils/parseProductProposal';
import { parsePitchToConvert } from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchesSingle/DesignerPitchesSingle.utils';

import { TableStyled } from 'components/Table/Table.styles';
import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';

import { useTableFilter } from 'hooks/useTableFilter';

import { useMessageContext } from 'context/MessageContext';

import {
  deleteDesignerPitch,
  getChildOptions,
  getDesignerPitchesList,
  getDesignerSinglePitch,
  resendDesignerSinglePitch,
  retractSinglePitch,
} from '../../api';

const DesignerPitchList = ({ isStory, mockData }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [setMessage] = useMessageContext();
  const [selectedPitchToResend, setSelectedPitchToResend] = useState('');
  const [selectedPitchToRetract, setSelectedPitchToRetract] = useState('');
  const { order, orderBy, filter, setFilter, p, l, parsedFilter, onPageChange, onRequestSort, onRowsPerPageChange } =
    useTableFilter({ initialOrder: 'desc', initialOrderBy: 'created' });

  const methods = useForm({
    defaultValues: {
      buyers: [],
    },
  });

  const navigate = useNavigate();

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['designerPitchesList', parsedFilter],
    () => getDesignerPitchesList(parsedFilter),
    {
      enabled: !isStory,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const pitchesList = useMemo(() => (isStory ? mockData : data?.pitches || []), [data?.pitches, isStory, mockData]);
  const total = isStory ? mockData.length : data?.total;

  const onView = useCallback(
    (pitch_uuid) => {
      navigate(`${routes.product}/${pitch_uuid}`);
    },
    [navigate]
  );

  const onDelete = useCallback(
    async (pitch_uuid) => {
      if (isStory) {
        console.log('delete', pitch_uuid);
        return;
      }
      // Post to API
      setIsDeleting(true);
      const response = await deleteDesignerPitch(pitch_uuid);
      setIsDeleting(false);
      if (!response.success) {
        // Set error
        setMessage(response.data.error_message);
        return;
      }
      setMessage(`Pitch has been successfully deleted.`);

      refetch();

      // Set all success states
    },
    [isStory, refetch]
  );

  const onEdit = useCallback(
    (uuid) => {
      if (isStory) {
        console.log(uuid);
        return;
      }
      navigate(`${routes.pitchesAdd}/${uuid}`);
    },
    [isStory, navigate]
  );

  const onViewNegotiations = useCallback(
    (product_uuid) => {
      navigate(`${routes.pitchesDashboard}/${product_uuid}`);
    },
    [navigate]
  );

  const handleCovertToMarketPlace = useCallback(
    async (pitch_uuid) => {
      setIsFetching(true);
      const res = await getDesignerSinglePitch(pitch_uuid);
      if (!res.success) {
        setIsFetching(false);
        return setMessage(getErrorMessage(res));
      }
      const parsedValues = parseRemoteProductProposalData(res.pitch);
      navigate(`${routes.productsAdd}`, { state: { proposal: parsePitchToConvert(parsedValues) } });
    },
    [navigate]
  );

  const handleSendToAnotherBuyer = useCallback(async () => {
    try {
      const { buyers } = methods.getValues();

      const res = await resendDesignerSinglePitch(selectedPitchToResend, { company_uuid: buyers[0]?.company_uuid });
      if (!res.success) {
        return setMessage(getErrorMessage(res));
      }
      setMessage('You have resent pitch to another buyer with success');
    } catch (err) {
      console.log(err);
    }
  }, [methods, selectedPitchToResend]);

  const onRetractPitch = useCallback(
    (pitch_uuid) => {
      setSelectedPitchToRetract(pitch_uuid);
    },
    [navigate]
  );

  const handleOnRetractPitch = useCallback(async () => {
    try {
      const res = await retractSinglePitch(selectedPitchToRetract);
      if (!res.success) {
        return setMessage(getErrorMessage(res));
      }
      setMessage('You have successfully retracted this pitch');
    } catch (err) {
      setMessage('There was an error retracting pitch');
    }
  }, [selectedPitchToRetract, setMessage]);

  const {
    data: { options: categories },
  } = useQuery(['options', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const filtersList = useMemo(
    () => ({
      main_categories: categories,
      statuses: [...Object.values(DESIGNER_PITCH_STATUS)],
    }),
    [categories]
  );

  const headingsOnClick = useMemo(
    () =>
      designerPitchListHeadings.map((menuItem) =>
        !menuItem.sortable
          ? {
              ...menuItem,
              menuOptions: addFilters(menuItem, filtersList, setFilter, filter, DESIGNER_PITCH_PILL_STATUS_TEXT),
            }
          : menuItem
      ),
    [filter, filtersList, setFilter]
  );

  const loading = (isLoading || isRefetching || isDeleting || isFetching) && !isStory;

  const hasFilter = Object.keys(filter).length;

  const noPitch = !pitchesList || pitchesList.length === 0;
  const showNoPitchesYetMessage = !hasFilter && noPitch;

  return (
    <>
      {loading && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}
      {!loading && (
        <CustomTableWrapper
          noResultsTitle={showNoPitchesYetMessage ? 'No pitch found' : ''}
          noResultsDescription={showNoPitchesYetMessage ? "You haven't added any pitches yet. Let's get started!" : ''}
          btnText={showNoPitchesYetMessage ? 'Add Pitch' : ''}
          onBtnClick={showNoPitchesYetMessage ? () => navigate(routes.pitchesAdd) : () => {}}
        >
          <TableStyled>
            <CustomTableHeader
              columnHeadings={headingsOnClick}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <CustomTableBody>
              {!pitchesList.length && <NoFilteringMatch item="pitches" cols={designerPitchListHeadings.length} />}
              {/* TODO: Remove hardcoded values */}
              {pitchesList.map((pitch, index) => {
                const {
                  image,
                  name,
                  categories,
                  date_created,
                  views,
                  status,
                  product_uuid,
                  can_convert,
                  can_retract,
                  company_name = 'J B Cole UK',
                  company_logo = {
                    fileSrc:
                      'aHR0cHM6Ly9idXlkZXNpZ24tZG9jdW1lbnRzLnMzLmFtYXpvbmF3cy5jb20vdXBsb2Fkcy9idXllci1sb2dvL2xvZ28taXphdmNtLmpwZWc/WC1BbXotQWxnb3JpdGhtPUFXUzQtSE1BQy1TSEEyNTYmWC1BbXotQ3JlZGVudGlhbD1BS0lBUlFIVlFDVjdZTUhZR1lYVSUyRjIwMjIxMjA3JTJGZXUtd2VzdC0yJTJGczMlMkZhd3M0X3JlcXVlc3QmWC1BbXotRGF0ZT0yMDIyMTIwN1QxMDQ5NDRaJlgtQW16LUV4cGlyZXM9MTIwJlgtQW16LVNpZ25lZEhlYWRlcnM9aG9zdCZYLUFtei1TaWduYXR1cmU9NDEyNjRlYTAwM2Y1M2UzM2IwOWE2NmRhMGJkYjFjMjJlZjI3MjlkNGY3M2QyNmFlNzg5YzRhNDdkZjY5ZGFhMw==',
                    fileType: 'url',
                    name: 'logo-izavcm.jpeg',
                  },
                  company_uuid = 'dbfe118f-3e04-4d29-9015-5b23872ecc0f',
                } = pitch;

                const soldOrNegotiating = status === PRODUCT_STATUS.SOLD || status === PRODUCT_STATUS.NEGOTIATING;
                const threeDotMenuActions =
                  status === PRODUCT_STATUS.PENDING_APPROVAL
                    ? [{ title: 'Delete', onClick: () => onDelete(product_uuid) }]
                    : [
                        // { title: 'View', onClick: () => onView(product_uuid) },
                        ...(soldOrNegotiating
                          ? [{ title: 'View Negotiations', onClick: () => onViewNegotiations(product_uuid) }]
                          : []),
                        ...(!soldOrNegotiating ? [{ title: 'Edit', onClick: () => onEdit(product_uuid) }] : []),
                        ...(!soldOrNegotiating ? [{ title: 'Delete', onClick: () => onDelete(product_uuid) }] : []),
                        ...(can_convert
                          ? [
                              {
                                title: 'Upload to Marketplace',
                                onClick: () => handleCovertToMarketPlace(product_uuid),
                              },
                              {
                                title: 'Send to another company',
                                onClick: () => setSelectedPitchToResend(product_uuid),
                              },
                            ]
                          : []),
                        ...(can_retract
                          ? [{ title: 'Retract Pitch', onClick: () => onRetractPitch(product_uuid) }]
                          : []),
                      ];
                return (
                  <CustomTableRow key={`table_${name}_${index}`} onClick={() => onViewNegotiations(product_uuid)}>
                    <CustomTableBodyCell>{<ItemInfo avatar={image} label={name} />}</CustomTableBodyCell>
                    {/* Company cell */}
                    <CustomTableBodyCell>
                      <Box
                        onClick={
                          company_uuid
                            ? (e) => {
                                e.stopPropagation();
                                navigate(`${routes.buyersIndex}/${company_uuid}`);
                              }
                            : null
                        }
                      >
                        <ItemInfo label={company_name} avatar={company_logo} />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <PillsWrapperStyled>
                        {getUniqueList(categories, 'slug')?.map((category, i) => {
                          const { name, slug } = category;
                          return (
                            <Box key={`${category}_${i}`}>
                              <PillTag option={{ label: name, value: slug }} />
                            </Box>
                          );
                        })}
                      </PillsWrapperStyled>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <TableBodyText text={date_created ? date_created : '--'} />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <TableBodyText text={views ? views : '0'} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <StatusPill
                        label={DESIGNER_PITCH_PILL_STATUS_TEXT[status]}
                        status={PILL_STATUS_MAP[status]}
                        icon={PILL_STATUS_ICON[status]}
                      />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <ThreeDotMenu actions={threeDotMenuActions} variant="vertical" />
                      </Box>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </TableStyled>
          {!!pitchesList.length && (
            <CustomTablePagination
              count={total}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPage={l}
              rowsPerPageOptions={[5, 10, 15]}
              page={p}
            />
          )}
        </CustomTableWrapper>
      )}
      {!!selectedPitchToResend && (
        <FormProvider {...methods}>
          <ModalFindBuyer
            onHandleAccept={handleSendToAnotherBuyer}
            variant="pitch"
            open={!!selectedPitchToResend}
            handleClose={() => setSelectedPitchToResend('')}
            product_uuid={selectedPitchToResend}
          />
        </FormProvider>
      )}
      {!!selectedPitchToRetract && (
        <ModalRetractPitch
          onSubmit={handleOnRetractPitch}
          variant="pitch"
          open={!!selectedPitchToRetract}
          handleClose={() => setSelectedPitchToRetract('')}
          callback={() => refetch()}
        />
      )}
    </>
  );
};

DesignerPitchList.propTypes = {};

export default DesignerPitchList;
