import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';

import { StyledBox } from './ThreeFormFieldsWithOptions.styles';

const ThreeFormFieldsWithOptions = ({ options, children }) => {
  return (
    <Box display="flex" mb={2}>
      <StyledBox
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(230px, 1fr))"
        columnGap={2}
        width="100%"
        marginBottom="4px"
      >
        {children}
      </StyledBox>
      <Box display="flex" justifyContent="center" alignItems="center" width="45px" paddingTop="20px">
        {!!options.length && <ThreeDotMenu actions={options} variant="horizontal" />}
      </Box>
    </Box>
  );
};

ThreeFormFieldsWithOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      onClick: PropTypes.func,
      to: PropTypes.func,
    })
  ),
  children: PropTypes.node,
};

export default ThreeFormFieldsWithOptions;
