import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import FormStepper from 'components/FormStepper/FormStepper';
import ModalCongratulations from 'components/ModalCongratulations/ModalCongratulations';

import { proposalSteps } from './DesignerBriefProposalNewSteps';
import { formDefaultValues, formValidation, steps } from './DesignerBriefProposalNew.data';
import { parseFormData, parseRemoteData } from './utils/DesignerBriefProposalNew.utils';

import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';
import { PROPOSAL_STATUS } from 'options/proposalStatus';

import { createDesignerBriefProposal, getDesignerSingleProposal, updateDesignerBriefProposal } from 'api';

const DesignerBriefProposalNew = ({ userType }) => {
  const { brief_uuid, proposal_uuid } = useParams();
  const navigate = useNavigate();
  const [apiFormData, setApiFormData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    // Redirect user to home page if they are not a designer - only designers have access to this page
    if (userType && userType !== userTypes.designer) navigate(routes.home);
  }, [userType, navigate]);

  useEffect(() => {
    const getInitialValues = async () => {
      // TODO: Integrate API logic once endpoints are done
      const res = await getDesignerSingleProposal(brief_uuid);
      if (res.success) {
        setApiFormData(parseRemoteData(res.proposal));
      }
    };

    if (proposal_uuid) {
      getInitialValues();
    }
  }, [brief_uuid, proposal_uuid]);

  useEffect(() => {
    const { status } = apiFormData;

    if (
      status &&
      ![PROPOSAL_STATUS.DRAFT, PROPOSAL_STATUS.PENDING_APPROVAL, PROPOSAL_STATUS.REJECTED].includes(status)
    ) {
      navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal`);
    }
  }, [apiFormData, brief_uuid, navigate]);

  const createOrUpdateProposal = useCallback(
    async (values, isLastStep) => {
      const parsedFormData = parseFormData(values, isLastStep);

      const { proposal_uuid } = parsedFormData;

      return !proposal_uuid
        ? await createDesignerBriefProposal(brief_uuid, parsedFormData)
        : await updateDesignerBriefProposal(brief_uuid, parsedFormData);
    },
    [brief_uuid]
  );

  const nextStepAction = useCallback(
    async (values, step) => {
      const isLastStep = steps.length - 1 === step;
      const res = await createOrUpdateProposal(values, isLastStep);
      if (!res.success) {
        throw new Error(res.data?.error_message || res.statusText || 'Internal Error');
      }
      //
      // return isLastStep ? navigate(routes.productsAddThanks) : setApiFormData(parseRemoteData(res.product));
      return isLastStep ? setShowModal(true) : setApiFormData(parseRemoteData(res?.proposal));
    },
    [createOrUpdateProposal]
  );

  const saveAndExit = useCallback(
    async (values) => {
      const res = await createOrUpdateProposal(values);

      if (!res.success) {
        throw new Error(res.data?.error_message || res.statusText || 'Internal Error');
      }

      return navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal`);
    },
    [createOrUpdateProposal, navigate, brief_uuid]
  );

  const onModalClose = async () => {
    queryClient.setQueryData(['designerSingleBrief', brief_uuid], (oldData) => {
      if (oldData) {
        return {
          ...oldData,
          brief: {
            ...oldData?.brief,
            proposal_status: PROPOSAL_STATUS.PENDING_APPROVAL,
          },
        };
      }
    });
    navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal`);
  };

  return (
    <DesignerDashboardWrapper
      activeTab={routes.briefsDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Proposals', onClick: () => navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal`) }]}
          currentPage={'Add Proposal'}
        />
      }
    >
      <HeaderAndMenu
        title="New proposal"
        menuOptions={[
          {
            buttonTitle: 'Back',
            icon: <ArrowBackIcon fontSize="small" />,
            onClick: () => navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal`),
            animate: true,
          },
        ]}
      />
      <FormStepper
        steps={steps}
        stepsSwitcher={proposalSteps}
        formValidation={formValidation}
        defaultFormValues={formDefaultValues}
        apiFormData={apiFormData}
        nextStepAction={nextStepAction}
        onSaveAndExit={saveAndExit}
        saveAndExitSteps={[0, 1]}
        saveAndExitRequiredFields={[['name'], null]}
        lastStepAction={nextStepAction}
        submitText="Create Proposal"
      />
      {showModal && (
        <ModalCongratulations
          handleClose={() => setShowModal(false)}
          action={onModalClose}
          text="Your proposal has been submitted successfully to the buyer and it's now pending for approval"
          title="Proposal"
          open={showModal}
        />
      )}
    </DesignerDashboardWrapper>
  );
};

export default DesignerBriefProposalNew;
