import { Box, styled } from '@mui/material';

export const StyledHelpDashboardContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '30% 1fr',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const StyledHelpTabListContainer = styled(Box)(({ theme }) => ({
  maxHeight: '64vh',
  overflowY: 'auto',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    maxHeight: '42.5vh',
  },
  [theme.breakpoints.down('sm')]: {
    maxHeight: '25vh',
  },
}));

export const StyledHelpMessageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  paddingTop: theme.spacing(3),
  height: '60vh',
  overflowY: 'scroll',
  scrollBehavior: 'smooth',
  border: `1px solid ${theme.palette.grey[200]} `,
  borderRight: `none `,
  [theme.breakpoints.down('md')]: {
    height: '55vh',
  },
  [theme.breakpoints.down('sm')]: {
    height: '40vh',
  },
}));

export const NoMessagesShownContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const LoadingContainer = styled(Box)(() => ({
  height: '20vh',
}));

export const CustomMessageLoadingContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginRight: 5,
}));
