import { styled } from '@mui/system';

export const SignupLandingContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  background: `linear-gradient(to right, ${theme.palette.common.white} 0%, ${theme.palette.common.white} 50%,  ${theme.palette.common.black} 50%,  ${theme.palette.common.black} 100%)`,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    background: `linear-gradient(to bottom, ${theme.palette.common.white} 0%, ${theme.palette.common.white} 50%,  ${theme.palette.common.black} 50%,  ${theme.palette.common.black} 100%)`,
  },
}));

export const SignupOptionContainer = styled('div')(({ theme }) => ({
  padding: '120px 90px',
  flex: 1,
  '&:first-of-type': {
    paddingLeft: '0px',
    [theme.breakpoints.down('md')]: {
      paddingBottom: '16px',
      paddingRight: '0px',
    },
  },
  '&:last-of-type': {
    paddingRight: '0px',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  [theme.breakpoints.down('md')]: {
    padding: '20px',
  },
}));
