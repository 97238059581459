import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';

import ImageList from '@mui/material/ImageList';
import ModalRejectBrief from 'components/ModalRejectBrief/ModalRejectBrief';
import CustomImageListItem from 'components/CustomImageListItem/CustomImageListItem';
import StatusPill from 'components/StatusPill/StatusPill';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';

import { routes } from 'options/routes';
import { PROPOSAL_STATUS } from 'options/proposalStatus';
import { PILL_STATUS_ICON, PILL_STATUS_MAP, BUYER_OPEN_CALL_PILL_STATUS_TEXT } from 'options/pillStatusOptions';

import { getImageSrc } from 'utils/getImageSrc';

import { useGalleryColumns } from 'hooks/useGalleryColumns';

import { useAuthContext } from 'context/AuthContext';

import { postBuyerOpenCallsSingleResponse, postCancelOpenCallsNegotiation } from 'api';

const OpenCallsProposalsList = ({ proposals, brief_uuid, setMessage, refetch, brief_name }) => {
  const navigate = useNavigate();
  const { columns } = useGalleryColumns();

  const [rejectBriefDetails, setRejectBriefDetails] = useState(null);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);

  const { company_name } = useAuthContext();

  const onRejectProposalSubmit = useCallback(
    async (__bool, message) => {
      const res = await postBuyerOpenCallsSingleResponse(brief_uuid, rejectBriefDetails?.brief_designer_uuid, {
        response: 'rejected',
        rejected_reason: message,
      });
      if (!res.success) {
        setMessage('There was an error rejecting the proposal.');
        return setRejectBriefDetails(null);
      }
      setMessage('You have rejected the proposal with success');
      setRejectBriefDetails(null);
      refetch();
    },
    [brief_uuid, refetch, rejectBriefDetails, setMessage]
  );

  const handleCancelProposal = useCallback(async () => {
    try {
      const res = await postCancelOpenCallsNegotiation(brief_uuid, openConfirmCancel?.brief_designer_uuid);
      if (!res.success) {
        setOpenConfirmCancel(null);
        throw res;
      }
      setMessage('You have cancelled this negotiation');
      refetch();
    } catch (err) {
      setMessage('There  was a problem cancelling this negotiation. Please try again');
    }
  }, [brief_uuid, openConfirmCancel?.brief_designer_uuid, refetch, setMessage]);

  return (
    <>
      <ImageList cols={columns} variant="standard" gap={10}>
        {proposals?.map((proposalDetails, i) => {
          const {
            brief_designer_uuid,
            designer: { uuid: designer_uuid, first_name: designer_first_name, last_name: designer_last_name },
            proposal = {},
            can_cancel,
          } = proposalDetails;

          const {
            proposal_uuid,
            name: proposal_name = '',
            status: proposal_status = 'pending_approval',
            main_image,
          } = proposal;

          const handleViewNavigation = () => {
            navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/open-call`);
          };
          const hasProposalLength = Object.keys(proposal || {}).length;
          const hasProposal = !!hasProposalLength && proposal_status !== PROPOSAL_STATUS.DRAFT ? 'yes' : 'no';

          const overlayOptions = {
            title: proposal_name,
            description: `By ${designer_first_name} ${designer_last_name}`,
            menuOptions: [
              {
                title: 'View proposal',
                onClick: handleViewNavigation,
              },
              { title: 'View profile', onClick: () => navigate(`${routes.designersIndex}/${designer_uuid}`) },
              ...(proposal_status === PROPOSAL_STATUS.PENDING_APPROVAL
                ? [
                    {
                      title: 'Reject proposal',
                      onClick: () => setRejectBriefDetails({ brief_designer_uuid, designer_first_name }),
                    },
                  ]
                : []),
              ...(can_cancel
                ? [
                    {
                      title: 'Cancel Negotiation',
                      onClick: () => setOpenConfirmCancel({ brief_designer_uuid }),
                    },
                  ]
                : []),
            ],
            statusData: [
              {
                label: 'Proposal Status',
                icon: (
                  <StatusPill
                    label={
                      BUYER_OPEN_CALL_PILL_STATUS_TEXT[
                        proposal_status === PROPOSAL_STATUS.PENDING_APPROVAL
                          ? `pending_${hasProposal}_proposal`
                          : proposal_status
                      ]
                    }
                    status={PILL_STATUS_MAP[proposal_status]}
                    icon={PILL_STATUS_ICON[proposal_status]}
                  />
                ),
              },
              {
                label: 'Proposal Uploaded',
                icon: (
                  <StatusPill
                    label={BUYER_OPEN_CALL_PILL_STATUS_TEXT[hasProposal]}
                    status={PILL_STATUS_MAP[hasProposal]}
                    icon={PILL_STATUS_ICON[hasProposal]}
                  />
                ),
              },
            ],
          };

          const { fileSrc } = getImageSrc(main_image);

          return (
            <CustomImageListItem
              src={fileSrc}
              key={`${proposal_uuid}_${i}`}
              overlayOptions={overlayOptions}
              onClick={handleViewNavigation}
              wrapStatus
              isProposal
            />
          );
        })}
      </ImageList>
      {!!rejectBriefDetails && (
        <ModalRejectBrief
          open={!!rejectBriefDetails}
          handleClose={() => setRejectBriefDetails(null)}
          handleSubmit={onRejectProposalSubmit}
          variant="open-call-proposal"
          designer_name={rejectBriefDetails?.designer_first_name}
          company_name={company_name}
          item_name={brief_name}
        />
      )}
      {!!openConfirmCancel?.brief_designer_uuid && (
        <ModalConfirmAccept
          open={!!openConfirmCancel?.brief_designer_uuid}
          titleOverride={`Cancel Proposal`}
          textOverride={`Are you sure you want to cancel this proposal?`}
          handleClose={() => setOpenConfirmCancel(null)}
          handleSubmit={handleCancelProposal}
        />
      )}
    </>
  );
};

export default OpenCallsProposalsList;
