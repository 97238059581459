import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import BriefProposalReviewForm from 'components/BriefProposalReviewForm/BriefProposalReviewForm';

import { PROJECT_STATUS } from 'options/projectStatus';
import { routes } from 'options/routes';
import { AGREEMENT_STATUS } from 'options/agreementStatus';

import { reviewProductPurchaseDesigner } from 'api';

const BuyerSingleProductReviewTab = (props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { product_uuid } = useParams();
  const [product, setMessage] = useOutletContext();
  const designer = product?.designer;

  const { agreement_status } = product;

  useEffect(() => {
    const { project_status } = product;
    const showReview = agreement_status === AGREEMENT_STATUS.PAID && project_status !== PROJECT_STATUS.COMPLETED;
    if (!showReview) {
      navigate(`${routes.productsDashboard}/${product_uuid}/product`);
    }
  }, [agreement_status, navigate, product, product_uuid]);

  const onSubmit = async (d) => {
    try {
      const res = await reviewProductPurchaseDesigner(product_uuid, {
        rating: d.rating,
        details: d.review,
      });

      if (!res.success) {
        throw new Error('There was an error submitting review');
      }
      queryClient.refetchQueries(['buyerSingleProduct', product_uuid], { type: 'active', exact: true });
      navigate(`${routes.productsDashboard}/${product_uuid}/product`);
    } catch (err) {
      setMessage(err.toString());
    }
  };

  return (
    <Box mt={2}>
      <BriefProposalReviewForm designer={designer} onSubmit={onSubmit} />
    </Box>
  );
};

BuyerSingleProductReviewTab.propTypes = {};

export default BuyerSingleProductReviewTab;
