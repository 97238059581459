import React, { useEffect } from 'react';

import { Box } from '@mui/system';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ControlledCustomRadioGroup from 'components/Inputs/CustomRadioStandardGroup/ControlledCustomRadioGroup/ControlledCustomRadioGroup';
import { ROYALTY_REVIEW_PERIODS, BRIEF_UPFRONT_FEE_OPTIONS } from './NewBriefRoyaltyLicenseForm.data';
import SingleInputResponsiveWrapper from 'components/Wrappers/SingleInputResponsiveWrapper/SingleInputResponsiveWrapper';
import { useFormContext } from 'react-hook-form';
import RadioWithTextFieldWrapper from 'components/Wrappers/RadioWithTextFieldWrapper/RadioWithTextFieldWrapper';
import ControlledCustomRadioGroupWithTextField from 'components/Inputs/ControlledCustomRadioGroupWithTextField/ControlledCustomRadioGroupWithTextField';
import ControlledDatePicker from 'components/Inputs/CustomDatePicker/ControlledDatePicker/ControlledDatePicker';
import FormFieldSetAndInputWrapper from 'components/Wrappers/FormFieldSetAndInputWrapper/FormFieldSetAndInputWrapper';

const NewBriefRoyaltyLicenseForm = ({ variant }) => {
  const { watch, unregister, register } = useFormContext();
  const upfrontPayment = watch('upfront_payment', 'true');

  useEffect(() => {
    if (upfrontPayment === 'no') unregister('upfront_payment_amount');
    if (upfrontPayment === 'yes') register('upfront_payment_amount');
  }, [register, unregister, upfrontPayment]);

  return (
    <div>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Royalty license percentage"
          description="Please add the percentage of your standard royalty payment, referencing your terms and conditions."
          tooltipText="Please enter the % you offer the designer. Please stipulate in your T&Cs if this % is based on wholesale or Retail sales"
        />
        <SingleInputResponsiveWrapper isModal={variant === 'negotiation'}>
          <ControlledTextField placeholder="0.00" startAdornment={'%'} name="royalty_percentage" />
        </SingleInputResponsiveWrapper>
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Royalty review period"
          description="How frequently do you review sales and pay out royalties?"
        />
        <ControlledCustomRadioGroupWithTextField
          radioOptions={ROYALTY_REVIEW_PERIODS}
          name="royalty_review_period"
          inputPlaceholder={'Specify other review period'}
          isModal={variant === 'negotiation'}
        />
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet label="Additional Information" description="Please supply any additional information" />
        <ControlledTextField
          multiline={true}
          minRows={4}
          placeholder={'Any additional information'}
          name="payment_terms"
        />
      </FormFieldSetAndInputWrapper>
      {variant !== 'negotiation' && (
        <>
          <FormFieldSetAndInputWrapper>
            <CustomFieldSet
              label="Deadline"
              description="Please choose a deadline date for the acceptance of the brief. You can discuss the deliverable dates directly with the designer once they have accepted the brief, using chat/messaging."
            />
            <SingleInputResponsiveWrapper>
              <ControlledDatePicker name="deadline" />
            </SingleInputResponsiveWrapper>
          </FormFieldSetAndInputWrapper>
          <FormFieldSetAndInputWrapper>
            <CustomFieldSet
              label="Before work starts will you offer an up front payment?"
              description="Some designers may request an advanced payment to start the work."
              tooltipParagrahs={['The advanced payment to start the work for a one-off payment will be deducted from your final budget amount.',
                'As per T&C, a one-time fee is subject to an additional BuyDesign 15% commission.'
              ]}
            />
            <RadioWithTextFieldWrapper>
              <Box mr={2}>
                <ControlledCustomRadioGroup
                  direction="row"
                  name="upfront_payment"
                  options={BRIEF_UPFRONT_FEE_OPTIONS}
                />
              </Box>
              {upfrontPayment === 'yes' && (
                <SingleInputResponsiveWrapper>
                  <ControlledTextField
                    placeholder="Enter your upfront fee"
                    startAdornment={'£'}
                    name="upfront_payment_amount"
                  />
                </SingleInputResponsiveWrapper>
              )}
            </RadioWithTextFieldWrapper>
          </FormFieldSetAndInputWrapper>
        </>
      )}
    </div>
  );
};

NewBriefRoyaltyLicenseForm.propTypes = {};

export default NewBriefRoyaltyLicenseForm;
