import { useNavigate } from 'react-router';

import { Box } from '@mui/material';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { routes } from 'options/routes';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';

const NewOpenCallThanks = ({ isEditing }) => {
  const navigate = useNavigate();
  return (
    <Box maxWidth="450px">
      <CustomFieldSet
        label="Thank you for your open call"
        description={isEditing ? 'Your open call has been edited.' : 'Your open call has been created.'}
        descriptionMarginTop={2}
        titleFontSize={24}
        tooltipParagrahs={[
          'Thank you for your Open Call.',
          'Your open call has been created and posted to your profile. The BuyDesign community of Designers will be notified.',
          'You will receive a notification when designers upload a proposal.',
          'Please check your spam or contact us if you have any issues at support@buydesign.com.',
        ]}
      />
      <Box display="flex" mt={2}>
        <Box mr={2}>
          <CustomButton
            text="View all open calls"
            onClick={() => navigate(routes.openCallsDashboard)}
            colour="secondary"
          />
        </Box>
        <Box>
          <CustomButton text="Create new open call" onClick={() => navigate(routes.openCallsDashboardAdd)} />
        </Box>
      </Box>
    </Box>
  );
};

export default NewOpenCallThanks;
