import PropTypes from 'prop-types';

import CustomNotification from '../CustomNotification/CustomNotification';

import { NotificationContainer } from './FixedCustomNotification.styles';

const FixedCustomNotification = ({ variant, text, linkText, onLinkClick, onClose }) => {
  return (
    <NotificationContainer variant={variant}>
      <CustomNotification text={text} linkText={linkText} onLinkClick={onLinkClick} onClose={onClose} />
    </NotificationContainer>
  );
};

FixedCustomNotification.propTypes = {
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  onLinkClick: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string,
};

export default FixedCustomNotification;
