import { styled, FormControl, OutlinedInput, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';

export const CustomMessageFieldFormStyled = styled('form')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
}));

export const CustomMessageFormControlStyled = styled(FormControl)(({ theme }) => ({
  flex: 1,
  borderRadius: 10,

  '& > div': { backgroundColor: 'transparent', borderRadius: 100 },
  '& > div:hover': { backgroundColor: 'transparent' },
}));

export const CustomMessageInputStyled = styled(OutlinedInput)(({ theme }) => ({
  outline: 'none!important',
  padding: 8,
  paddingRight: 0,
  paddingLeft: 0,
  '& > textarea': { paddingLeft: 14, paddingRight: 10 },
  borderRadius: '10px !important'
}));

export const CustomMessageAttachIconStyled = styled(AttachFileIcon)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginRight: 5,
  '&:hover': { color: theme.palette.common.black },
}));

export const CustomMessageSendIconStyled = styled(SendIcon)(({ theme }) => ({
  color: theme.palette.white.main,
  height: 20,
  width: 20,
}));

export const CustomMessageSendIconButtonStyled = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.black,
  borderRadius: 50,
  padding: 14,
  marginLeft: 10,
  marginTop: theme.spacing(1),
  '&:hover': { opacity: 0.8, backgroundColor: theme.palette.common.black },
  '&:disabled': { backgroundColor: theme.palette.common.black, opacity: 0.3 },
}));
