import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';

import { SectionInnerWrapper, SectionWrapper, SectionContainer } from 'pages/Authentication/Login/Login.styles';

const TwoColumnBlackWhiteWrapper = ({ children }) => {
  return (
    <PageWrapper userType={'unauthenticated'} showSecondaryFooter>
      <SectionWrapper>
        <SectionInnerWrapper>
          <SectionContainer />
          <SectionContainer>{children}</SectionContainer>
        </SectionInnerWrapper>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default TwoColumnBlackWhiteWrapper;
