import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box, Grid } from '@mui/material';
import SignUpStepperHeader from '../SignUpStepperHeader/SignUpStepperHeader';
import FormActionButton from 'components/Buttons/FormActionButton/FormActionButton';
import FormError from 'components/Forms/FormError/FormError';
import Spinner from '../../Spinner/Spinner';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { parseChoicesToArray } from '../../../utils/parseChoicesToArray';
import { parseDateToMonthYear, parseDateToYear } from '../../../utils/parseDates';
import { designerSignup, buyerSignup, buyerValidateCompany } from '../../../api';
import { routes } from '../../../options/routes';
import { userTypes } from '../../../options/userTypes';
import ValidationHelper from '../ValidationHelper/ValidationHelper';

const SignUpStepper = ({ formValues, formValidation, headerList, componentsList, userType, isStory }) => {
  const [step, setStep] = useState(0);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(formValidation[step]);
  const methods = useForm({
    defaultValues: formValues,
    mode: 'onChange',
    resolver,
  });
  const { trigger, getValues } = methods;
  const navigate = useNavigate();

  const currentComponent = useMemo(() => componentsList[step], [componentsList, step]);
  const { titleText, description, helper } = useMemo(() => headerList[step], [headerList, step]);

  // const actionButtonText = step + 1 === componentsList.length ? 'Submit' : 'Continue';
  const actionButtonText = 'Continue';

  const handleBack = () => {
    if (step !== 0) {
      setStep((prevState) => prevState - 1);
    } else {
      navigate(`/register`);
    }
  };

  const handleNext = async () => {
    setLoading(true);
    const isValid = await trigger();

    if (isValid && step + 1 === componentsList.length) {
      const values = getValues();
      // Parse data
      const standardData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        confirm_password: values.confirm_password,
        website: values.website,
        terms_accepted: values.terms_accepted,
        mailchimp_subscribed: values.mailchimp_subscribed,
      };
      let additionalData;
      if (userType === userTypes.designer) {
        const portrait = values.portrait[0].fileSrc;
        additionalData = {
          portrait,
          product_types: parseChoicesToArray(values.product_types),
          studio_name: values.studio_name,
          studio_country: values.studio_country,
          designer_since: parseDateToMonthYear(values.designer_since),
        };
      } else {
        const logo = values.logo[0]?.fileSrc;
        additionalData = {
          logo,
          position: values.position,
          company_name: values.name,
          country: values.country,
          established_since: parseDateToYear(values.established_since),
          product_interests: parseChoicesToArray(values.product_interests),
          buyer_categories: parseChoicesToArray(values.buyer_categories),
          buyer_spaces: parseChoicesToArray(values.buyer_spaces),
        };
      }

      // Merge all data and do not make API call if using component in storybook
      const data = { ...standardData, ...additionalData };
      if (isStory) {
        console.log(data);
        setLoading(false);
        return;
      }

      // Post to API
      const response = userType === userTypes.designer ? await designerSignup(data) : await buyerSignup(data);
      if (!response.success && !response.key) {
        setError(response.data.error_message);
        setLoading(false);
        return;
      }
      // Redirect to email token verify screen
      navigate(`${routes.signupTokenVerify}/${response.key}`);
      return;
    }

    if (isValid) {
      // Check if the company/email domain already exists on buyer sign up
      if (step === 0 && userType === userTypes.buyer && !isStory) {
        const { email } = getValues();
        const response = await buyerValidateCompany({ email });
        if (!response.success) {
          setError(response.data.error_message);
          setLoading(false);
          return;
        }
        setError('');
      }
      setStep((prevState) => prevState + 1);
    }
    setLoading(false);
  };

  return (
    <Grid item flex={1}>
      <ValidationHelper step={step} methods={methods} />
      <Box>
        <SignUpStepperHeader
          titleText={titleText}
          paragraphText={description}
          additionalDescription={helper}
          showDivider={false}
        />
      </Box>
      {error && (
        <Box mt={2}>
          <FormError errors={[error]} />
        </Box>
      )}
      <FormProvider {...methods}>
        <form>{currentComponent}</form>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          {loading && <Spinner />}
          {!loading && (
            <>
              <Box mr={1}>
                <FormActionButton onClick={handleBack} text="Back" variant="text" isBack />
              </Box>
              <FormActionButton
                onClick={handleNext}
                text={actionButtonText}
                variant="outlined"
                disabled={!methods.formState.isValid}
              />
            </>
          )}
        </Box>
      </FormProvider>
    </Grid>
  );
};

SignUpStepper.propTypes = {
  formValues: PropTypes.object,
  formValidation: PropTypes.array,
  headerList: PropTypes.arrayOf(
    PropTypes.shape({
      titleText: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  componentsList: PropTypes.arrayOf(PropTypes.node),
  userType: PropTypes.oneOf([userTypes.designer, userTypes.buyer]).isRequired,
  // Only passed in when rendered in story - allows console logging data instead of making API call
  isStory: PropTypes.bool,
};

export default SignUpStepper;
