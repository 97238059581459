import ImageOverlay from 'components/ImageOverlay/ImageOverlay';
import { EmptyBoxStyled } from './EmptyImage.styles';

const EmptyImage = ({ handleMouseOver, handleMouseOut, onClick, overlayOptions, isHovering, placeholder }) => {
  return (
    <EmptyBoxStyled
      width="100%"
      height="100%"
      position="relative"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={onClick}
      minWidth="25%"
      minHeight="250px"
      placeholder={placeholder}
    >
      {overlayOptions && !!Object.keys(overlayOptions).length && <ImageOverlay {...overlayOptions} show={isHovering} />}
    </EmptyBoxStyled>
  );
};

export default EmptyImage;
