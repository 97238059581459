import { useMemo, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import VisibilityIcon from '@mui/icons-material/Visibility';

import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import { ProductDetailsWrapper } from 'components/DesignerProductList/DesignerProductList.styles';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import PillTag from 'components/PillTag/PillTag';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';
import Gravatar from 'components/Gravatar/Gravatar';
import StatusPill from 'components/StatusPill/StatusPill';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import ModalShowHideOpenCall from 'components/ModalShowHideOpenCall/ModalShowHideOpenCall';
import PreviewBriefModal from 'components/PreviewBriefModal/PreviewBriefModal';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';

import { PILL_STATUS_ICON, PILL_STATUS_MAP, BUYER_OPEN_CALL_PILL_STATUS_TEXT } from 'options/pillStatusOptions';
import { BUYER_OPEN_CALLS_LIST_HEADINGS } from 'options/tableOptions';
import { BRIEF_STATUS_BUYER, BRIEF_STATUS_BUYER_OPTIONS } from 'options/briefStatus';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { routes } from 'options/routes';

import { getUniqueList } from 'utils/getUniqueList';
import { addFilters } from 'utils/addFilters';

import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';
import { TableStyled, VisibilityIconWrapper } from 'components/Table/Table.styles';

import { getChildOptions } from 'api';

const BuyersOpenCallsList = ({
  buyersBriefList,
  totalCount,
  tableMethods,
  notFound,
  onEdit,
  onView,
  onDelete,
  onCancelBrief,
  onDesignerClick,
  refetch,
}) => {
  const { order, orderBy, filter, setFilter, p, l, onPageChange, onRequestSort, onRowsPerPageChange } = tableMethods;
  const navigate = useNavigate();
  const [showHideModal, setShowHideModal] = useState({
    open: false,
    uuid: null,
    visible: null,
  });
  const [previewUUID, setPreviewUUID] = useState();

  const {
    data: { options: categories },
  } = useQuery(['options', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const filtersList = useMemo(
    () => ({
      categories: categories,
      statuses: [...Object.values(BRIEF_STATUS_BUYER_OPTIONS)],
    }),
    [categories]
  );

  const headingsOnClick = useMemo(
    () =>
      BUYER_OPEN_CALLS_LIST_HEADINGS.map((menuItem) => {
        if (menuItem.id === 'visible') return menuItem;
        return !menuItem.sortable
          ? {
              ...menuItem,
              menuOptions: addFilters(menuItem, filtersList, setFilter, filter, BUYER_OPEN_CALL_PILL_STATUS_TEXT),
            }
          : menuItem;
      }),
    [filter, filtersList, setFilter]
  );

  const handleViewNavigation = useCallback(
    (brief_uuid, isDraft) => {
      if (isDraft) {
        onEdit(brief_uuid);
        return;
      }
      onView(brief_uuid);
      return;
    },
    [onEdit, onView]
  );

  const openShowHideModal = (uuid, visible) => {
    setShowHideModal({
      open: true,
      uuid: uuid,
      visible: visible,
    });
  };

  const handleCloseShowHideModal = (shouldRefetch) => {
    setShowHideModal({
      open: false,
      uuid: null,
      visible: null,
    });
    if (shouldRefetch) refetch();
  };

  const onPreviewClick = useCallback(async (event, uuid) => {
    event.stopPropagation();
    setPreviewUUID(uuid);
  }, []);

  const hasFilter = !!Object.keys(filter).length;
  const showNotFoundMessage = notFound && !hasFilter;

  return (
    <>
      <Box>
        <CustomTableWrapper
          noResultsTitle={showNotFoundMessage ? 'No open calls found' : ''}
          noResultsDescription={showNotFoundMessage ? "You haven't added any open calls yet. Let's get started!" : ''}
          btnText={showNotFoundMessage ? 'Add Open Call' : ''}
          onBtnClick={showNotFoundMessage ? () => navigate(routes.openCallsDashboardAdd) : null}
        >
          <TableStyled>
            <CustomTableHeader
              columnHeadings={headingsOnClick}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <CustomTableBody>
              {!buyersBriefList?.length && (
                <NoFilteringMatch item="open calls" cols={BUYER_OPEN_CALLS_LIST_HEADINGS.length} />
              )}
              {buyersBriefList?.map((brief, index) => {
                const {
                  image,
                  name,
                  title,
                  categories,
                  designers,
                  additional_designers,
                  deadline,
                  status,
                  brief_uuid,
                  visible,
                  can_cancel,
                } = brief;
                const isDraft = status === BRIEF_STATUS_BUYER.DRAFT;
                const isActive = status === BRIEF_STATUS_BUYER.ACTIVE;
                const isCancelled = status === BRIEF_STATUS_BUYER.CANCELLED;
                const visibleButtonText = visible ? 'Hide from Profile' : 'Show on Profile';

                const threeDotMenuActions = [
                  (isDraft || isActive) && { title: 'Edit', onClick: () => onEdit(brief_uuid) },
                  isDraft && !isCancelled && { title: 'Delete', onClick: () => onDelete(brief_uuid) },
                  !isDraft && !isCancelled && { title: 'View', onClick: () => onView(brief_uuid) },
                  !isDraft &&
                    !isCancelled && { title: visibleButtonText, onClick: () => openShowHideModal(brief_uuid, visible) },
                  can_cancel && !isCancelled && { title: 'Cancel Open Call', onClick: () => onCancelBrief(brief_uuid) },
                ].filter(Boolean);

                return (
                  <CustomTableRow
                    key={`table_${name}_${index}`}
                    onClick={!isCancelled && (() => handleViewNavigation(brief_uuid, isDraft))}
                  >
                    <CustomTableBodyCell>
                      <ProductDetailsWrapper>
                        <ItemInfo avatar={image || {}} label={title} />
                      </ProductDetailsWrapper>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <PillsWrapperStyled>
                        {getUniqueList(categories, 'slug')?.map((category, i) => {
                          const { name, slug } = category;
                          return (
                            <Box key={`${category}_${i}`}>
                              <PillTag option={{ label: name, value: slug }} />
                            </Box>
                          );
                        })}
                      </PillsWrapperStyled>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <Gravatar
                          data={designers.map(({ portrait: { fileSrc, fileType }, user_uuid }) => ({
                            fileSrc,
                            fileType,
                            uuid: user_uuid,
                          }))}
                          onClick={onDesignerClick}
                          additional_designers={additional_designers}
                        />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <TableBodyText text={deadline ? deadline : '--'} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>{visible ? <DoneIcon /> : <CloseIcon />}</CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <StatusPill
                        label={BUYER_OPEN_CALL_PILL_STATUS_TEXT[status]}
                        status={PILL_STATUS_MAP[status]}
                        icon={PILL_STATUS_ICON[status]}
                      />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <VisibilityIconWrapper>
                        <VisibilityIcon onClick={(event) => onPreviewClick(event, brief_uuid)} />
                      </VisibilityIconWrapper>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <ThreeDotMenu actions={threeDotMenuActions} variant="vertical" />
                      </Box>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </TableStyled>
          {!!buyersBriefList?.length && (
            <CustomTablePagination
              count={totalCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPage={l}
              rowsPerPageOptions={[5, 10, 15]}
              page={p}
            />
          )}
        </CustomTableWrapper>
      </Box>
      {!!previewUUID && (
        <PreviewBriefModal
          open={!!previewUUID}
          handleClose={() => setPreviewUUID('')}
          brief_uuid={previewUUID}
          isStory={false}
          wide
          showTerms
          isOpenCall
        />
      )}
      <ModalShowHideOpenCall
        open={showHideModal.open}
        visible={showHideModal.visible}
        openCallUUID={showHideModal.uuid}
        handleClose={handleCloseShowHideModal}
      />
    </>
  );
};

BuyersOpenCallsList.propTypes = {
  isStory: PropTypes.bool,
  mockData: PropTypes.array,
  mockTotal: PropTypes.number,
};

export default BuyersOpenCallsList;
