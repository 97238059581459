import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';

import { InfoOutlinedIconStyled, TooltipContainer, TooltipText } from './InfoTooltip.styles';
import { Box, Stack } from '@mui/material';

const InfoTooltip = ({ title, tooltipText, paragraphs }) => {
  return (
    <Tooltip
      title={
        <TooltipContainer>
          <Box pt={0.5}>
            <InfoOutlinedIconStyled />
          </Box>
          <Stack justifyContent="center">
            {title && (
              <Box mb={0.5}>
                <CustomTitle variant="h6" text={title} colour="white" />
              </Box>
            )}
            <TooltipText>{tooltipText}</TooltipText>
            <Stack mt={0.5}>
              {paragraphs && paragraphs.map((paragraph) => <TooltipText mt={0.75}>{paragraph}</TooltipText>)}
            </Stack>
          </Stack>
        </TooltipContainer>
      }
      placement="right"
      id={'custom-tooltip'}
    >
      <InfoOutlinedIcon />
    </Tooltip>
  );
};

InfoTooltip.propTypes = {
  tooltipText: PropTypes.string,
};

export default InfoTooltip;
