export const getUniqueList = (list, prop) => {
  if (!list) {
    return [];
  }
  const newList = [];
  list.forEach((item) => {
    const compItem = prop ? item[prop] : item;
    if (prop && !newList.find((option) => option[prop] === compItem)) {
      newList.push(item);
    }
    if (!prop && !newList.includes(compItem)) {
      newList.push(item);
    }
  });
  return newList;
};
