import React from 'react';
import PropTypes from 'prop-types';
import { StyledHelpTabContainer, StyledHelpTabLabelDateBox } from './HelpTab.styles';
import CaptionText from 'components/Typography/CaptionText/CaptionText';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import BodyText from 'components/Typography/BodyText/BodyText';

const HelpTab = ({ title, chat_uuid, caption, onClick, menuOptions, isSelected }) => {
  return (
    <StyledHelpTabContainer isSelected={isSelected} onClick={() => onClick(chat_uuid)}>
      <StyledHelpTabLabelDateBox isSelected={isSelected}>
        <BodyText text={title} bold />
        <CaptionText text={caption} />
      </StyledHelpTabLabelDateBox>
      <ThreeDotMenu variant="horizontal" actions={menuOptions} />
    </StyledHelpTabContainer>
  );
};

HelpTab.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  chat_uuid: PropTypes.string,
  caption: PropTypes.string,
};

export default HelpTab;
