import React from 'react';
import PropTypes from 'prop-types';

import { TableBodyTextStyled } from './TableBodyText.styles';

const TableBodyText = ({ text, colour, bold, variant = 'body1', truncateWord, handleOnClick }) => {
  return (
    <TableBodyTextStyled
      style={{ color: colour ? colour : undefined }}
      variant={variant}
      bold={bold ? 'true' : ''}
      truncateWord={truncateWord}
      onClick={handleOnClick}
    >
      {text}
    </TableBodyTextStyled>
  );
};

TableBodyText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]), // sometimes the parsing of text becoming an array of elements
  colour: PropTypes.string,
  bold: PropTypes.bool,
};

export default TableBodyText;
