import React from 'react';
import PropTypes from 'prop-types';
import { StyledRadioWithTextFieldWrapper } from './RadioWithTextFieldWrapper.styles';

const RadioWithTextFieldWrapper = ({ children, isModal }) => {
  return <StyledRadioWithTextFieldWrapper modal={isModal}>{children}</StyledRadioWithTextFieldWrapper>;
};

RadioWithTextFieldWrapper.propTypes = {};

export default RadioWithTextFieldWrapper;
