import { useCallback, useEffect } from 'react';
import { useQueries, useQueryClient } from 'react-query';

import { MOCK_NAV_NOTIFICATIONS_LIST } from 'mockData/mockNavNotifications';

import { deleteNotification, getNavBarNotificationsCount, getNavBarNotificationsList, markAsReadNavBarNotifications } from 'api';

export const useNotification = (open, isStory) => {
  const queryClient = useQueryClient();
  const results = useQueries({
    queries: [
      {
        queryKey: ['notificationsCount'],
        queryFn: () => getNavBarNotificationsCount(),
        staleTime: 2 * 60 * 1000,
        enabled: !isStory,
        refetchInterval: !isStory ? 2 * 60 * 1000 : null,
      },
      {
        queryKey: ['notificationsList'],
        queryFn: () => getNavBarNotificationsList(),
        enabled: open && !isStory,
        refetchInterval: open && !isStory ? 2 * 60 * 1000 : null,
      },
    ],
  });

  const [notificationsCountQuery, notificationsListQuery] = results;
  const count = notificationsCountQuery?.data?.count;
  const notificationsList = isStory ? MOCK_NAV_NOTIFICATIONS_LIST : notificationsListQuery?.data?.notifications;
  const isNotificationsLoading = notificationsListQuery?.isLoading;

  // useEffect(() => {
  //   if (open && notificationsList?.length && notificationsList?.some((item) => !item?.read) && !isStory) {
  //     markAsReadNavBarNotifications();
  //   }
  //   if (open) {
  //     queryClient.setQueriesData(['notificationsCount'], (oldData) => {
  //       if (oldData?.count) {
  //         return {
  //           ...oldData,
  //           count: 0,
  //         };
  //       }
  //     });
  //   }
  // }, [open, notificationsList, isStory, queryClient]);

  const removeNotification = useCallback(async (notification_uuid) => {
    try {
      await deleteNotification(notification_uuid);
      queryClient.setQueriesData(['notificationsList'], (oldData) => {
          return {
            ...oldData,
            notifications: oldData.notifications.filter(notification => notification.notification_uuid !== notification_uuid)
          };
      });

      queryClient.setQueriesData(['notificationsCount'], (oldData) => {
        if (oldData?.count) {
          return {
            ...oldData,
            count: oldData.count - 1,
          };
        }
      });
    } catch(err){
      console.log("removeNotification ~ err:", err)
    }
  },[])

  return {
    count,
    notificationsList,
    isNotificationsLoading,
    removeNotification
  };
};
