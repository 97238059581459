import React from 'react';
import PropTypes from 'prop-types';
import { StyledNamesColumnWrapper, StyledNamesListContainer } from './NamesList.styles';
import NamesColumn from 'components/NamesColumn/NamesColumn';

const NamesList = ({ title, description, namesList }) => {
  return (
    <StyledNamesListContainer>
      <StyledNamesColumnWrapper>
        {namesList?.length > 0 &&
          namesList.map((names) => {
            return <NamesColumn key={`namescol-${names.title}`} title={names.title} options={names.options} />;
          })}
      </StyledNamesColumnWrapper>
    </StyledNamesListContainer>
  );
};

NamesList.propTypes = {
  namesList: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          onClick: PropTypes.func,
        })
      ),
    })
  ),
};

export default NamesList;
