import PropTypes from 'prop-types';

import TableContainer from '@mui/material/TableContainer';
import EmptyListCreateNew from 'components/EmptyListCreateNew/EmptyListCreateNew';
import { StyledEmptyListMessageWrapper } from './CustomTableWrapper.styles';

const CustomTableWrapper = ({ children, noResultsTitle, noResultsDescription, btnText, onBtnClick, icon }) => {
  return (
    <>
      {noResultsTitle || noResultsDescription || btnText || (btnText && onBtnClick) ? (
        <StyledEmptyListMessageWrapper>
          <EmptyListCreateNew
            title={noResultsTitle}
            description={noResultsDescription}
            btnText={btnText}
            onClick={onBtnClick}
            icon={icon}
          />
        </StyledEmptyListMessageWrapper>
      ) : (
        <TableContainer>{children}</TableContainer>
      )}
    </>
  );
};

CustomTableWrapper.propTypes = {
  noResultsTitle: PropTypes.string,
  noResultsDescription: PropTypes.string,
  btnText: PropTypes.string,
  onBtnClick: PropTypes.func,
  icon: PropTypes.node,
};

export default CustomTableWrapper;
