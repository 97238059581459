import React, { useCallback, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '@mui/icons-material/Close';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Spinner from 'components/Spinner/Spinner';
import DesignerTabSectionsWrapper from 'components/Wrappers/TabSectionsWrapper/DesignerTabSectionsWrapper/DesignerTabSectionsWrapper';
import TopBarTitleStatus from 'components/TopBarTitleStatus/TopBarTitleStatus';
import ScrollableContainer from 'components/ScrollableContainer/ScrollableContainer';
import ChatBox from 'components/ChatBox/ChatBox';
import BodyText from 'components/Typography/BodyText/BodyText';
import ModalRetractPitch from 'components/ModalRetractPitch/ModalRetractPitch';

import { routes } from 'options/routes';
import { PROJECT_STATUS } from 'options/projectStatus';

import { capitalize } from 'utils/capitalize';
import { parseRemoteProductProposalData } from 'utils/parseProductProposal';
import { parseRemoteData as parseRemoteTermsData } from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/utils/BuyerTermsNew.utils';
import { getIsAgreementTabDisabled, getIsFilesTabDisabled } from '../DesignerPitchesSingle.utils';
import { getErrorMessage } from 'utils/getErrorMessage';

import { useMessageContext } from 'context/MessageContext';

import { MOCK_PITCH } from 'mockData/mockPitch';
import { options } from './DesignerPitchesSingleLanding.data';

import {
  deleteDesignerPitchBuyerSingleFiles,
  downloadDesignerPitchBuyerSingleFiles,
  getDesignerPitchBuyerSingleDetails,
  getProductChatMessages,
  getSingleTerm,
  postProductChatMessage,
  retractPitchNegotiation,
  uploadDesignerPitchBuyerSingleFiles,
} from 'api';

const DesignerPitchesSingleLanding = ({ isStory, userType }) => {
  const { pitch_uuid, buyer_pitch_uuid } = useParams();
  const navigate = useNavigate();
  const [setMessage] = useMessageContext();

  const [showMessages, setShowMessages] = useState(true);
  const [openRetractPitch, setOpenRetractPitch] = useState(false);

  const location = useLocation();
  const pathnameArr = location?.pathname.split('/');
  const pathName = pathnameArr[pathnameArr.length - 1];

  const { data, isLoading, refetch } = useQuery(
    ['designerSinglePitch', pitch_uuid, buyer_pitch_uuid],
    () => getDesignerPitchBuyerSingleDetails(pitch_uuid, buyer_pitch_uuid),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
    }
  );

  const { selected_terms_uuid: terms_uuid } = data?.product || {};
  const can_retract = data?.can_retract;

  const { data: termsData } = useQuery(
    ['designerSingleTerm', terms_uuid],
    () => (terms_uuid ? getSingleTerm(terms_uuid) : {}),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
    }
  );

  const parsedTermsData = (termsData?.terms && parseRemoteTermsData(termsData.terms)) || {};

  const pitchData = useMemo(
    () => (isStory ? MOCK_PITCH : (data && data?.product && parseRemoteProductProposalData(data?.product)) || {}),
    [data, isStory]
  );

  const pitchName = pitchData?.name;
  const chat_uuid = pitchData?.chat_uuid || '';

  const { project_status, agreement_status } = pitchData;

  const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status);
  const isAgreementTabDisabled = getIsAgreementTabDisabled(project_status);
  const isProjectCompleted = project_status === PROJECT_STATUS.COMPLETED;

  const handleSectionClick = useCallback(
    (path) => {
      navigate(`${routes.pitchesDashboard}/${pitch_uuid}/${buyer_pitch_uuid}${path}`);
    },
    [buyer_pitch_uuid, navigate, pitch_uuid]
  );

  const handleGetChatMessages = useCallback(() => {
    return getProductChatMessages(chat_uuid);
  }, [chat_uuid]);

  const handlePostChatMessages = useCallback((chat_uuid, data) => {
    return postProductChatMessage(chat_uuid, data);
  }, []);

  const handleChatDownloadFiles = useCallback(
    (data) => {
      return downloadDesignerPitchBuyerSingleFiles(pitch_uuid, buyer_pitch_uuid, data);
    },
    [pitch_uuid, buyer_pitch_uuid]
  );

  const handleChatUploadFiles = useCallback(
    (data) => {
      return uploadDesignerPitchBuyerSingleFiles(pitch_uuid, buyer_pitch_uuid, data);
    },
    [pitch_uuid, buyer_pitch_uuid]
  );

  const handleChatDeleteFiles = useCallback(
    (file_uuid) => {
      return deleteDesignerPitchBuyerSingleFiles(pitch_uuid, buyer_pitch_uuid, file_uuid);
    },
    [pitch_uuid, buyer_pitch_uuid]
  );

  const toggleMessages = useCallback(() => {
    setShowMessages((prev) => !prev);
  }, []);

  const handleOnRetractPitchNegotiation = useCallback(async () => {
    try {
      const res = await retractPitchNegotiation(pitch_uuid, buyer_pitch_uuid);

      if (!res.success) {
        return setMessage(getErrorMessage(res));
      }
      setMessage('You have successfully retracted this negotiation');
    } catch (err) {
      setMessage('There was an error retracting pitch negotiation');
    }
  }, [buyer_pitch_uuid, pitch_uuid, setMessage]);

  return (
    <DesignerTabSectionsWrapper
      breadcrumbs={
        <Breadcrumbs
          links={[
            { label: 'Pitches', onClick: () => navigate(routes.pitchesDashboard) },
            { label: pitchName, onClick: () => navigate(`${routes.pitchesDashboard}/${pitch_uuid}`) },
          ]}
          currentPage={capitalize(pathName || '')}
        />
      }
      title={pitchName}
      headerMenuOptions={[
        {
          buttonTitle: showMessages ? 'Hide Messages' : 'Show Messages',
          icon: showMessages ? <SpeakerNotesOffIcon fontSize="small" /> : <ChatIcon fontSize="small" />,
          onClick: toggleMessages,
        },
        {
          buttonTitle: 'Back',
          icon: <ArrowBackIcon fontSize="small" />,
          onClick: () => navigate(`${routes.pitchesDashboard}`),
          animate: true,
        },
        ...(can_retract
          ? [
              {
                buttonTitle: 'Retract Negotiation',
                icon: <CloseIcon fontSize="small" />,
                onClick: () => setOpenRetractPitch(true),
              },
            ]
          : []),
      ]}
      chatbox={
        <ChatBox
          chat_uuid={chat_uuid}
          getChatMessages={handleGetChatMessages}
          postChatMessage={handlePostChatMessages}
          downloadFiles={handleChatDownloadFiles}
          uploadFiles={handleChatUploadFiles}
          deleteFiles={handleChatDeleteFiles}
          userType={userType}
          expand={showMessages}
          setExpand={toggleMessages}
        />
      }
      activeTab={routes.pitchesDashboard}
      collapse={showMessages}
      handleSectionClick={handleSectionClick}
      options={options.map((option) => {
        if (option.path === '/agreement') {
          return {
            ...option,
            disabled: isAgreementTabDisabled,
          };
        }
        if (option.path === '/files') {
          return {
            ...option,
            disabled: isFilesTabDisabled,
          };
        }
        return option;
      })}
    >
      {isLoading && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}
      {!isLoading && (
        <>
          <TopBarTitleStatus name={data?.product?.company?.name} project_status={project_status} />
          {!isLoading && isProjectCompleted && (
            <Box mt={2}>
              <BodyText text="This project has been completed." />
            </Box>
          )}
          <ScrollableContainer collapse={showMessages}>
            <Outlet context={[{ ...pitchData, ...parsedTermsData }, setMessage, userType]} />
          </ScrollableContainer>
        </>
      )}
      {!!openRetractPitch && (
        <ModalRetractPitch
          onSubmit={handleOnRetractPitchNegotiation}
          variant="negotiation"
          open={openRetractPitch}
          handleClose={() => setOpenRetractPitch(false)}
          callback={() => refetch()}
        />
      )}
    </DesignerTabSectionsWrapper>
  );
};

DesignerPitchesSingleLanding.propTypes = {};

export default DesignerPitchesSingleLanding;
