import { Box, styled } from '@mui/material';

export const StyledFeaturedListsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  flexWrap: 'wrap',
  '& > div': {
    flex: 1,
    [theme.breakpoints.down('md')]: {
      flexBasis: `calc(50% - ${theme.spacing(1)})`,
      flexGrow: 0,
    },
    [theme.breakpoints.down('sm')]: {
      flexBasis: '100%',
      flexGrow: 0,
    },
  },
}));
