import DesignerLoginDashboardComponent from 'components/DesignerLoginDashboardComponent/DesignerLoginDashboardComponent';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';

import { routes } from '../../../../options/routes';

const DesignerDashboardLanding = () => {
  return (
    <DesignerDashboardWrapper activeTab={routes.dashboard}>
      <DesignerLoginDashboardComponent />
    </DesignerDashboardWrapper>
  );
};

export default DesignerDashboardLanding;
