import React from 'react';

import { useOutletContext } from 'react-router';

import BriefSummary from 'components/BriefSummary/BriefSummary';

const BuyerBriefDetails = () => {
  const [briefData, setMessages, userType] = useOutletContext();

  return <BriefSummary briefDetails={briefData} userType={userType} filePreviewEnabled={true} />;
};

BuyerBriefDetails.propTypes = {};

export default BuyerBriefDetails;
