import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import {  IconButton, InputAdornment } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';

import {
  CustomMessageFieldFormStyled,
  CustomMessageFormControlStyled,
  CustomMessageInputStyled,
  CustomMessageAttachIconStyled,
  CustomMessageSendIconButtonStyled,
  CustomMessageSendIconStyled,
} from './CustomMessageField.styles';

const CustomMessageField = React.forwardRef((props, ref) => {
  const { onSubmit, handleAttachFile, handleRemoveAttachment, fileToSendType, disableDownload, isLoadingAttachFile } = props;

  const [fieldVal, setFieldVal] = useState('');

  const fileInputRef = useRef(null);

  const handleUserInput = (e) => {
    setFieldVal(e.target.value);

  };

  const openFiles = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      handleOnClick(e);
    }
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    if (fieldVal) {
      onSubmit(fieldVal);
      setFieldVal('');
    
    }
  };

  return (
    <CustomMessageFieldFormStyled onSubmit={(e) => e.preventDefault()}>
      {/* {fileToSendType && (
        <Box>
          <FileUploadIconThumbnail fileType={fileToSendType} />
          <CloseIcon
            onClick={() => {
              fileInputRef.current.value = '';
              handleRemoveAttachment();
            }}
          />
        </Box>
      )} */}
      <CustomMessageFormControlStyled>
        <CustomMessageInputStyled
          type={'text'}
          onKeyDown={handleKeyDown}
          placeholder={'Message...'}
          value={fieldVal}
          onChange={handleUserInput}
          aria-label="type a message"
          ref={ref ? ref : null}
          multiline={true}
          maxRows={6}
        />
      </CustomMessageFormControlStyled>
      <InputAdornment position="end" style={{marginLeft: 0, paddingTop: '5px'}}>
              <input
                accept="image/jpeg, image/png, .ai, .ind, .psd, .tiff, .pdf, .3dm, .3DS, .acis, .dwg, .dxf, .FBX, .iges, .obj, .prt, .SKP, .sldasm, .sldprt, .step, .stl, .ppt"
                ref={fileInputRef}
                style={{ display: 'none' }}
                type="file"
                onChange={handleAttachFile}
              />
              {!disableDownload && !isLoadingAttachFile && (
                <IconButton
                  onClick={openFiles}
                  type="button"
                  aria-label="attach a file"
                  disableRipple={true}
                  edge="end"
                >
                  <CustomMessageAttachIconStyled />
                </IconButton>
              )}
              {isLoadingAttachFile && <div style={{marginLeft: 8}}><Spinner size={16}/></div>}
            </InputAdornment>
      <CustomMessageSendIconButtonStyled aria-label="send message" onClick={handleOnClick}>
        <CustomMessageSendIconStyled />
      </CustomMessageSendIconButtonStyled>
    </CustomMessageFieldFormStyled>
  );
});

CustomMessageField.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  handleAttachFile: PropTypes.func.isRequired,
};

export default CustomMessageField;
