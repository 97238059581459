export const parseRemoteNegotiatedTerms = ({
  negotiated_agreement_type,
  negotiated_royalty_percentage,
  negotiated_royalty_review_period,
  negotiated_payment_terms,
  negotiated_deadline,
  negotiated_upfront_payment,
  negotiated_upfront_payment_amount,
  negotiated_one_off_budget,
  ...rest
}) => ({
  agreement_type: negotiated_agreement_type,
  payment_terms: negotiated_payment_terms,
  deadline: negotiated_deadline,
  ...(negotiated_upfront_payment ? { upfront_payment: 'Yes' } : {}),
  upfront_payment_amount: negotiated_upfront_payment_amount,
  royalty_percentage: negotiated_royalty_percentage,
  royalty_review_period: negotiated_royalty_review_period,
  ...(negotiated_one_off_budget ? { one_off_budget: String(negotiated_one_off_budget) } : {}),
  ...rest,
});
