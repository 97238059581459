import React from 'react';
import PropTypes from 'prop-types';

import { NavBarIconStyled, SearchStyled } from './NavBarIconButton.styles';

const NavBarIcon = ({ onClick }) => {
  return (
    <NavBarIconStyled onClick={onClick}>
      <SearchStyled />
    </NavBarIconStyled>
  );
};

NavBarIcon.propTypes = {
  onClick: PropTypes.func,
};

export default NavBarIcon;
