import React from 'react';
import PropTypes from 'prop-types';
import { PillTagCloseStyled, PillTagStyled, PillTagText } from './PillTag.styles';

const PillTag = ({ option, removeSelection, styleOverrides, colour, outlined }) => {
  return (
    <PillTagStyled outlined={outlined} style={{ ...styleOverrides }} colour={colour}>
      <PillTagText variant="body1" colour={colour}>
        {option?.label}
      </PillTagText>
      {removeSelection && (
        <PillTagCloseStyled onClick={() => removeSelection(option)} fontSize="small" colour={colour} />
      )}
    </PillTagStyled>
  );
};

PillTag.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  removeSelection: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  colour: PropTypes.oneOf(['white', 'black', 'gray']),
};

export default PillTag;
