import PropTypes from 'prop-types';

import CustomModal from 'components/CustomModal/CustomModal';
import AgreementReviewForm from 'components/Forms/AgreementReviewForm/AgreementReviewForm';

const ModalAgreementReview = ({ open, handleClose, handleSubmit, editValues, variant, agreement_type, currency }) => {
  return (
    <CustomModal title="Agreement Review" open={open} handleClose={handleClose} variant="secondary">
      <AgreementReviewForm
        variant={variant}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        editValues={editValues}
        agreement_type={agreement_type}
        currency={currency}
        isModal
      />
    </CustomModal>
  );
};

ModalAgreementReview.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default ModalAgreementReview;
