import React, { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';

import { routes } from 'options/routes';

import { getCheckoutSuccessPitch } from 'api';

const BuyerSinglePitchSuccess = (props) => {
  const navigate = useNavigate();
  const { pitch_uuid } = useParams();
  const [product, setMessage] = useOutletContext();

  useEffect(() => {
    const getSuccess = async () => {
      const res = await getCheckoutSuccessPitch(pitch_uuid);
      if (!res.success) {
        setMessage(res?.data?.error_message || 'There was an error while checking out.');
        return navigate(`${routes.pitchesDashboard}/${pitch_uuid}/pitch`);
      }
      setMessage('Thanks for your payment.');
      navigate(`${routes.pitchesDashboard}/${pitch_uuid}/pitch`);
    };
    getSuccess();
  }, [navigate, pitch_uuid, setMessage]);
  return (
    <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
      <Spinner align="center" />
    </Box>
  );
};

BuyerSinglePitchSuccess.propTypes = {};

export default BuyerSinglePitchSuccess;
