import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { Box, ImageList } from '@mui/material';
import EmptyListCreateNew from 'components/EmptyListCreateNew/EmptyListCreateNew';
import ModalCreateMoodboard from 'components/ModalCreateMoodboard/ModalCreateMoodboard';
import ModalDeleteMoodboard from 'components/ModalDeleteMoodboard/ModalDeleteMoodboard';
import ModalShareMoodboard from 'components/ModalShareMoodboard/ModalShareMoodboard';
import ModalExternalShareMoodboard from 'components/ModalExternalShareMoodboard/ModalExternalShareMoodboard';
import CustomImageListItem from 'components/CustomImageListItem/CustomImageListItem';

import { routes } from 'options/routes';
import { getImageSrc } from 'utils/getImageSrc';

import MoodboardEmpty from '../../assets/pngs/MoodboardEmpty.png';

import { useGalleryColumns } from 'hooks/useGalleryColumns';

const MoodboardList = ({ moodboardProducts, isStory, notFound, onCreateNewMoodboard, refetch, setMessage }) => {
  const navigate = useNavigate();
  const { columns } = useGalleryColumns();

  const [openEditMoodboard, setOpenEditMoodboard] = useState(false);
  const [openShareMoodboard, setOpenShareMoodboard] = useState(false);
  const [openShareMoodboardExternally, setOpenShareMoodboardExternally] = useState(false);
  const [openDeleteMoodboard, setOpenDeleteMoodboard] = useState(false);
  const [moodboardSelected, setMoodboardSelected] = useState(null);

  const onEditMoodboard = useCallback((moodboard) => {
    setMoodboardSelected(moodboard);
    setOpenEditMoodboard(true);
  }, []);

  const onShareMoodboard = useCallback((moodboard) => {
    setMoodboardSelected(moodboard);
    setOpenShareMoodboard(true);
  }, []);

  const onShareMoodboardExternally = useCallback((moodboard) => {
    setMoodboardSelected(moodboard);
    setOpenShareMoodboardExternally(true);
  }, []);

  const onDeleteMoodboard = useCallback((moodboard) => {
    setMoodboardSelected(moodboard);
    setOpenDeleteMoodboard(true);
  }, []);

  const handleCloseModal = (setModalState, refetchList) => {
    setModalState(false);
    setMoodboardSelected(null);
    if (refetchList && refetch) {
      refetch();
    }
  };

  return notFound ? (
    <EmptyListCreateNew
      title="No moodboards found"
      description="You don't have any moodboards set up yet. Let's get started!"
      btnText="Create Moodboard"
      onClick={() => onCreateNewMoodboard()}
    />
  ) : (
    <Box>
      <ImageList cols={columns} variant="standard" gap={10}>
        {moodboardProducts?.map((item, i) => {
          const { cover_image, name, moodboard_uuid, description } = item;

          const { fileSrc, name: imageName } = getImageSrc(cover_image);

          const handleViewNavigation = () => {
            navigate(`${routes.moodboardsDashboard}/${moodboard_uuid}`);
          };

          const overlayOptions = {
            title: name,
            description: description,
            menuOptions: [
              { title: 'View Moodboard', onClick: handleViewNavigation },
              { title: 'Edit Moodboard', onClick: () => onEditMoodboard({ moodboard_uuid }) },
              { title: 'Share to team', onClick: () => onShareMoodboard({ moodboard_uuid }) },
              {
                title: 'Share externally',
                onClick: () => onShareMoodboardExternally({ moodboard_uuid, moodboard_name: name }),
              },
              { title: 'Delete Moodboard', onClick: () => onDeleteMoodboard({ moodboard_uuid }) },
            ],
          };

          return (
            <CustomImageListItem
              src={fileSrc || MoodboardEmpty}
              name={imageName}
              overlayOptions={overlayOptions}
              onClick={handleViewNavigation}
              key={moodboard_uuid}
            />
          );
        })}
      </ImageList>
      {openEditMoodboard && (
        <ModalCreateMoodboard
          open={openEditMoodboard}
          handleClose={(shouldRefetch) => handleCloseModal(setOpenEditMoodboard, shouldRefetch)}
          variant="edit"
          moodboard_uuid={moodboardSelected?.moodboard_uuid}
          setMessage={setMessage}
        />
      )}
      {openDeleteMoodboard && (
        <ModalDeleteMoodboard
          open={openDeleteMoodboard}
          handleClose={(shouldRefetch) => handleCloseModal(setOpenDeleteMoodboard, shouldRefetch)}
          variant="edit"
          moodboard_uuid={moodboardSelected?.moodboard_uuid}
          setMessage={setMessage}
        />
      )}
      {openShareMoodboard && (
        <ModalShareMoodboard
          open={openShareMoodboard}
          handleClose={() => handleCloseModal(setOpenShareMoodboard)}
          moodboard_uuid={moodboardSelected?.moodboard_uuid}
          isStory={isStory}
          setMessage={setMessage}
        />
      )}
      {openShareMoodboardExternally && (
        <ModalExternalShareMoodboard
          open={openShareMoodboardExternally}
          handleClose={() => handleCloseModal(setOpenShareMoodboardExternally)}
          moodboard_uuid={moodboardSelected?.moodboard_uuid}
          moodboard_name={moodboardSelected?.moodboard_name}
          isStory={isStory}
          setMessage={setMessage}
        />
      )}
    </Box>
  );
};

MoodboardList.propTypes = {};

export default MoodboardList;
