import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from '@mui/material';

const CustomTableCheckbox = ({ checked, onClick, indeterminate }) => {
  return <Checkbox checked={checked} onClick={onClick} indeterminate={indeterminate} size="small" />;
};

CustomTableCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  indeterminate: PropTypes.bool,
};

export default CustomTableCheckbox;
