import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import CustomSelectField from '../CustomSelectField';

import { findOptionSelected } from 'utils/findOptionSelected';

/*
 * Controlled Input
 * Needs to be wrapped in a <FormProvider {...methods}> that receives all methods from useForm (react-hook-form)
 * name needs to match field used in useForm defaultValues
 */

const ControlledSelectField = ({ name, label, options, fieldStyle, isSingleSelect }) => {
  const { control, setError, clearErrors, getValues, setValue } = useFormContext();

  const handleRemove = (fieldName, option) => {
    const prevState = getValues(fieldName);
    const newValue = prevState.filter((currentValue) => currentValue.value !== option.value);

    setValue(fieldName, newValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value }, fieldState }) => {
        return (
          <CustomSelectField
            onChange={(e) => {
              if (isSingleSelect) {
                return onChange([e.target.value]);
              }

              if (!!findOptionSelected(value, e)) {
                // Error: cannot select same option twice
                return setError(name, { message: 'You cannot select same option twice' });
              }
              clearErrors(name);
              return onChange([...value, e.target.value]);
            }}
            onBlur={(e) => {
              clearErrors(name);
              return onBlur(e);
            }}
            value={value}
            label={label}
            options={options}
            handleRemove={(option) => handleRemove(name, option)}
            error={fieldState.error?.message}
            fieldStyle={fieldStyle}
          />
        );
      }}
    />
  );
};

ControlledSelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  fieldStyle: PropTypes.oneOf(['primary', 'secondary']),
};

export default ControlledSelectField;
