import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  CustomSearchFieldWrapper,
  CustomSearchFieldFormControlSyled,
  CustomSearchFieldInputSyled,
} from './CustomSearchField.styles';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const CustomSearchField = ({ searchTerm, setSearchTerm, placeholder }) => {
  const handleUpdateInput = (e) => {
    const val = e.target.value;
    setSearchTerm(val);
  };

  return (
    <CustomSearchFieldWrapper>
      <CustomSearchFieldFormControlSyled>
        <CustomSearchFieldInputSyled
          onChange={handleUpdateInput}
          placeholder={placeholder ? placeholder : 'Search'}
          value={searchTerm}
          disableUnderline={true}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
      </CustomSearchFieldFormControlSyled>
    </CustomSearchFieldWrapper>
  );
};

CustomSearchField.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  placeholder: PropTypes.string,
};

export default CustomSearchField;
