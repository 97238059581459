import React from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import CustomCheckBoxTerms from '../CustomCheckBoxTerms';

const ControlledCheckBoxTerms = ({ name }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => {
        return <CustomCheckBoxTerms error={fieldState.error?.message} onChange={onChange} checked={value} />;
      }}
    />
  );
};

ControlledCheckBoxTerms.propTypes = {
  name: PropTypes.string,
};

export default ControlledCheckBoxTerms;
