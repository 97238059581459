import { useNavigate } from 'react-router-dom';

import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import HeaderAndMenu from '../../../../../components/HeaderAndMenu/HeaderAndMenu';
import DesignerProductList from '../../../../../components/DesignerProductList/DesignerProductList';
import AddIcon from '@mui/icons-material/Add';

import { routes } from '../../../../../options/routes';

export const DesignerProductsDashboard = () => {
  const navigate = useNavigate();
  return (
    <DesignerDashboardWrapper activeTab={routes.productsDashboard}>
      <HeaderAndMenu
        title="Products"
        menuOptions={[{ buttonTitle: 'Add Product', icon: <AddIcon />, onClick: () => navigate(routes.productsAdd) }]}
      />
      <DesignerProductList />
    </DesignerDashboardWrapper>
  );
};

export default DesignerProductsDashboard;
