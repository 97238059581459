import React from 'react';
import PropTypes from 'prop-types';
import { CustomImageContainer, ImageStyled } from './CustomImage.styles';

const CustomImage = ({ objectFit, imgSrc, isCircle, fileType = 'url', overrideImgStyles = {}, aspect1to1 }) => {
  const src = fileType === 'url' ? atob(imgSrc) : imgSrc;
  return (
    <CustomImageContainer circle={isCircle ? 'true' : ''} imgSrc={imgSrc}>
      {imgSrc && (
        <ImageStyled
          aspect1to1={aspect1to1}
          objectFit={objectFit}
          src={src}
          circle={isCircle ? 'true' : ''}
          style={overrideImgStyles}
        />
      )}
    </CustomImageContainer>
  );
};

CustomImage.propTypes = {
  objectFit: PropTypes.oneOf(['contain', 'cover']),
  imgSrc: PropTypes.string.isRequired,
  isCircle: PropTypes.bool,
  isUrl: PropTypes.bool,
};

export default CustomImage;
