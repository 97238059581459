import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import { wpRoutes, socialRoutes } from 'options/routes';

export const FOOTER_LINKS = [
  {
    title: 'Home',
    links: [
      { linkText: 'Features', linkPath: wpRoutes.features, external: true },
      { linkText: 'Pricing', linkPath: wpRoutes.pricing, external: true },
      { linkText: 'Stories', linkPath: wpRoutes.stories, external: true },
    ],
  },
  {
    title: 'About',
    links: [
      { linkText: 'About', linkPath: wpRoutes.about, external: true },
      // { linkText: 'Team', linkPath: wpRoutes.team, external: true },
      { linkText: 'Contact', linkPath: wpRoutes.contact, external: true },
    ],
  },
  {
    title: 'Info',
    links: [
      // { linkText: 'T&Cs', linkPath: wpRoutes.termsAndConditions, external: true },
      { linkText: `Buyer T&C's`, linkPath: wpRoutes.termsAndConditionsBuyer, external: true },
      { linkText: `Designer T&C's`, linkPath: wpRoutes.termsAndConditionsDesigner, external: true },
      // { linkText: 'Legal', linkPath: wpRoutes.legal, external: true },
      { linkText: 'Privacy and GDPR', linkPath: wpRoutes.privacyPolicy, external: true },
      { linkText: 'FAQs', linkPath: wpRoutes.faqs, external: true },
    ],
  },
  {
    title: 'Community',
    links: [
      { linkText: 'Instagram', linkPath: socialRoutes.instagram, external: true },
      { linkText: 'LinkedIn', linkPath: socialRoutes.linkedIn, external: true },
      // { linkText: 'Email', linkPath: 'mailto:support@buydesign.com', external: true },
    ],
  },
];

export const FOOTER_SOCIAL_MEDIA = [
  { icon: <InstagramIcon />, linkPath: socialRoutes.instagram },
  { icon: <LinkedInIcon />, linkPath: socialRoutes.linkedIn },
  { icon: <EmailIcon />, linkPath: 'mailto:info@buydesign.com' },
];
