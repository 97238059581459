import { Box } from '@mui/system';
import AccountFormContainer from 'components/Forms/AccountFormContainer/AccountFormContainer';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { wpRoutes } from 'options/routes';

const DesignerManageStripe = () => {
  const handlePaymentInstructionsClick = () => window.open(wpRoutes.unsupportedPayments, '_blank');

  return (
    <div id="stripe-section">
      <Box flexDirection="row" display="flex">
        <AccountFormContainer
          titleText={'Payments'}
          descriptionText={'Visit the link to find out more about how we process payments to your country.'}
        />
      </Box>

      <Box display="flex" mt={1}>
        <CustomButton text={'How we process payments'} onClick={handlePaymentInstructionsClick} colour="primary" />
      </Box>
    </div>
  );
};

DesignerManageStripe.propTypes = {};

export default DesignerManageStripe;
