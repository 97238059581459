import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { styled } from '@mui/system';

export const VisibilityIconStyled = styled(VisibilityIcon)(() => ({
  cursor: 'pointer',
}));

export const VisibilityOffIconStyled = styled(VisibilityOffIcon)(() => ({
  cursor: 'pointer',
}));
