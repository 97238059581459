import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import OpenBriefsAccordion from 'components/OpenBriefsAccordion/OpenBriefsAccordion';
import OpenBriefCreateProposalModal from 'components/OpenBriefCreateProposalModal/OpenBriefCreateProposalModal';
import PreviewReferenceFileModal from '../PreviewReferenceFileModal/PreviewReferenceFileModal';

import { throwNewErrorFromRes } from 'utils/apiErrors';

import { routes } from 'options/routes';

import { startNegotationsOpenCall } from 'api';

const OpenBriefsAccordionsContainer = ({ role, openBriefsData }) => {
  const expandedInitialObj = Object.fromEntries(openBriefsData.map((_, index) => [`expand${index}`, true]));
  const [expanded, setExpanded] = useState(expandedInitialObj);
  const [proposalModalOpen, setProposalModalOpen] = useState(false);
  const [proposalModalDetails, setProposalModalDetails] = useState(null);
  const [startNegotationError, setStartNegotationError] = useState(null);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [previewModalFile, setPreviewModalFile] = useState(null);
  const navigate = useNavigate();

  const handleExpanded = (id) => {
    setExpanded((prev) => {
      return { ...prev, [id]: !prev[id] };
    });
  };

  const handleOpenProposalModal = (title, brief_uuid) => {
    setProposalModalOpen(true);
    setProposalModalDetails({ title: title, brief_uuid: brief_uuid });
  };
  const handleCloseProposalModal = () => {
    setProposalModalOpen(false);
    setProposalModalDetails(null);
  };

  const handleProposalModalSubmit = async () => {
    try {
      const res = await startNegotationsOpenCall(proposalModalDetails.brief_uuid);
      throwNewErrorFromRes(res);
      // code unreachable if error is found in the above line, is then thrown with its message to below
      // - handle successful response here
      navigate(`${routes.openCallsDashboard}/${proposalModalDetails.brief_uuid}/open-call`);
    } catch (error) {
      // - handle error response here
      setStartNegotationError(error.message);
    }

    setProposalModalOpen(false);
    setProposalModalDetails(null);
  };

  const handleOpenPreviewModal = (referenceFile) => {
    if (referenceFile) {
      setPreviewModalOpen(true);
      setPreviewModalFile(referenceFile);
    }
  };

  const handleClosePreviewModal = () => {
    setPreviewModalOpen(false);
    setProposalModalDetails(null);
  };

  return (
    <>
      <OpenBriefCreateProposalModal
        open={proposalModalOpen}
        handleSubmit={handleProposalModalSubmit}
        handleClose={handleCloseProposalModal}
        name={proposalModalDetails?.title}
      />
      <PreviewReferenceFileModal
        loading={false}
        reference_file={previewModalFile}
        open={previewModalOpen}
        handleClose={handleClosePreviewModal}
      />
      <Box>
        {openBriefsData?.map((d, index) => (
          <OpenBriefsAccordion
            key={d.brief_uuid}
            id={`expand${index}`}
            handleExpanded={handleExpanded}
            briefData={d}
            index={index}
            expanded={expanded[`expand${index}`]}
            handleOpenProposalModal={handleOpenProposalModal}
            handleOpenPreviewModal={handleOpenPreviewModal}
            startNegotationError={startNegotationError}
            role={role}
          />
        ))}
      </Box>
    </>
  );
};

OpenBriefsAccordionsContainer.propTypes = {
  openBriefsData: PropTypes.arrayOf(PropTypes.object),
};

export default OpenBriefsAccordionsContainer;
