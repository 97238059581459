import { Button, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { pulsate } from 'styles/animations';

export const FormActionButtonStyled = styled(Button)(() => ({
  height: '36px',
  '&.MuiButton-textPrimary': {
    padding: '6px 4px',
  },
  ':hover > svg': {
    animation: `${pulsate} 1s infinite`,
  },
}));

export const ArrowBackStyled = styled(ArrowBackIcon)(({ theme, disabled }) => ({
  marginRight: theme.spacing(0.5),
  color: disabled ? theme.palette.text.secondary : '',
  '&:hover': {
    animation: `${pulsate} 1s infinite`,
  },
}));
