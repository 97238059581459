import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';
import queryString from 'query-string';

import ProductsAll from 'components/ProductsAll/ProductsAll';
import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import ProductsNotFound from 'components/ProductsNotFound/ProductsNotFound';

import { FILTER_OPTIONS } from 'options/filterOptions';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { PRODUCTS_MENU, PRODUCTS_MENU_WITH_SUBCATEGORY } from 'options/menuOptions';
import { PRODUCTS_PER_PAGE } from 'options/pagination';
import { routes } from 'options/routes';

import { cleanupTitle } from 'utils/parseStrings';
import { getUniqueList } from 'utils/getUniqueList';
import { addProductFilters } from 'utils/addFilters';

import { ContentWrapper } from '../Products.styles';

import { mockCategories, mockSpace } from 'components/ProductsAll/ProductsAll.data';

import { useProductOptionsQueries } from 'hooks/useProductOptionsQueries';

import { getChildOptions, getProducts } from 'api';

const Products = ({ userType, isStory }) => {
  const { category, sub_category } = useParams();
  const { state } = useLocation();
  const routeParamsMainCategory = state?.main_category;
  const hasUpdated = useRef(false);

  const [filter, setFilter] = useState({
    ...(routeParamsMainCategory ? { main_category: getUniqueList(routeParamsMainCategory) } : {}),
  });
  const [pagination, setPaginationOptions] = useState({ p: 1, l: PRODUCTS_PER_PAGE });
  const { p, l } = pagination;
  const navigate = useNavigate();

  const parsedFilter = useMemo(
    () =>
      queryString.stringify({
        ...filter,
        p: p,
        l,
        ...(category ? { main_category: category } : {}),
        ...(sub_category ? { sub_category_one: sub_category } : {}),
      }),
    [filter, p, l, category, sub_category]
  );

  const { data = {}, isLoading: isProductsLoading } = useQuery(
    ['products', parsedFilter],
    async () => getProducts(parsedFilter),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
    }
  );

  const total = useMemo(() => data.total || null, [data.total]);
  const pageCount = useMemo(() => (data.total ? Math.ceil(data.total / PRODUCTS_PER_PAGE) : null), [data.total]);

  const onPageChange = useCallback((_e, newPage) => {
    setPaginationOptions((prevState) => ({ ...prevState, p: newPage }));
  }, []);

  const resetFilter = useCallback(() => {
    setFilter({});
  }, []);

  const resetPagination = useCallback(() => {
    setPaginationOptions({ p: 1, l: PRODUCTS_PER_PAGE });
  }, []);

  useEffect(() => {
    if (!hasUpdated.current) {
      hasUpdated.current = true;
      return;
    }
    resetFilter();
    resetPagination();
  }, [category, sub_category, resetFilter, resetPagination]);

  useEffect(() => {
    resetPagination();
  }, [filter, resetPagination]);

  useEffect(() => {
    return () => {
      hasUpdated.current = false;
    };
  }, []);

  const { categories, space, materials, colour } = useProductOptionsQueries();

  const {
    data: { options: subCategories },
  } = useQuery(['options', [OPTIONS_TYPE.product], category], () => getChildOptions([OPTIONS_TYPE.product], category), {
    enabled: !isStory && !!category,
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const itemsList = useMemo(
    () => ({
      [FILTER_OPTIONS.CATEGORY]: isStory ? mockCategories : categories,
      [FILTER_OPTIONS.SUB_CATEGORY]: isStory ? mockCategories : subCategories,
      [FILTER_OPTIONS.SPACE]: isStory ? mockSpace : space,
      [FILTER_OPTIONS.MATERIAL]: materials || [],
      [FILTER_OPTIONS.COLOUR]: colour || [],
    }),
    [categories, colour, isStory, materials, space, subCategories]
  );

  const menuOptions = useMemo(() => {
    let productMenuArr = category && !sub_category ? PRODUCTS_MENU_WITH_SUBCATEGORY : PRODUCTS_MENU;
    return productMenuArr
      .filter((menuItem) => {
        if (category && menuItem.id === FILTER_OPTIONS.CATEGORY) {
          return false;
        }
        if (menuItem.id === FILTER_OPTIONS.SUB_CATEGORY) {
          if (!itemsList[FILTER_OPTIONS.SUB_CATEGORY]?.length) return false;
        }
        return true;
      })
      .map((menuItem) => {
        return {
          ...menuItem,
          actions: addProductFilters(menuItem, itemsList, setFilter, filter),
        };
      });
  }, [category, filter, itemsList, sub_category]);

  const title = sub_category || category || 'All Products';

  const products = data?.products;

  let notFound;

  if (!isProductsLoading && !products?.length) {
    notFound = <ProductsNotFound message="No products have been added to the platform yet." />;

    if (category || !!Object.keys(filter).length) {
      notFound = (
        <ProductsNotFound
          message="No products match your filter criteria."
          btnText={category ? 'View All Products' : ''}
          onClick={category ? () => navigate(routes.products, { state: { clearFilter: true } }) : null}
        />
      );
    }
  }

  const hasFilter = !!queryString.stringify(filter);

  return (
    <PageWrapper userType={userType} showSecondaryFooter={false}>
      <ContentWrapper>
        <ProductsAll
          title={cleanupTitle(title)}
          userType={userType}
          menuOptions={menuOptions}
          products={products}
          isLoading={isProductsLoading}
          notFound={notFound}
          pageCount={pageCount}
          total={total}
          page={p}
          onPageChange={onPageChange}
          resetFilter={resetFilter}
          hasFilter={hasFilter}
        />
      </ContentWrapper>
    </PageWrapper>
  );
};

Products.propTypes = {};

export default Products;
