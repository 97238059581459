import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { Box } from '@mui/material';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ControlledDatePicker from 'components/Inputs/CustomDatePicker/ControlledDatePicker/ControlledDatePicker';
import ControlledCustomRadioGroup from 'components/Inputs/CustomRadioStandardGroup/ControlledCustomRadioGroup/ControlledCustomRadioGroup';
import TwoFormFieldsRow from 'components/TwoFormFieldsRow/TwoFormFieldsRow';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import CustomTextField from 'components/Inputs/TextFields/CustomTextField/CustomTextField';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import AccountFormContainer from '../AccountFormContainer/AccountFormContainer';
import FormError from '../FormError/FormError';

import { GENDER_OPTIONS } from 'options/genderOptions';
import { userTypes } from '../../../options/userTypes';
import { parseDateToFullDateString } from '../../../utils/parseDates';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { rolesDefaultValues, schemaValidation } from './ProfileDetailsForm.data';

import { updateAccountProfile } from '../../../api';

const ProfileDetailsForm = ({ role, formData, userEmail, setSuccess, isStory }) => {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const formDefaultValues = rolesDefaultValues[role];
  const resolver = useYupValidationResolver(schemaValidation[role]);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: formDefaultValues,
    mode: 'onChange',
    resolver,
  });

  const { handleSubmit, trigger, reset } = methods;

  useEffect(() => {
    reset({ ...formData });
  }, [reset, formData]);

  const onSubmit = async (data) => {
    setLoading(true);
    const isValid = await trigger();
    // Parse form data
    if (isValid) {
      const submitData = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: userEmail,
      };
      if (role !== userTypes.designer) {
        submitData.position = data.position;
      }
      if (data.date_of_birth) submitData.date_of_birth = parseDateToFullDateString(new Date(data.date_of_birth));
      if (data.phone_number) submitData.phone_number = data.phone_number;
      if (data.gender) submitData.gender = data.gender;

      // Do not make API call if using component in storybook
      if (isStory) {
        console.log(submitData);
        setLoading(false);
        return;
      }

      // Post to API - pass try in for isDesignerProfile arg for designers
      const response = await updateAccountProfile(submitData, role === userTypes.designer);
      if (!response.success) {
        // Set error and reset form data to original values
        setFormError(response.data.error_message);
        reset({ ...formData });
        setLoading(false);
        return;
      }

      // Set success message in parent component
      setSuccess('Profile data has been successfully updated.');
    }
    setLoading(false);
  };

  return (
    <AccountFormContainer
      onClick={handleSubmit(onSubmit)}
      buttonText="Save this section"
      titleText="Profile Details"
      tooltipText={`Your personal profile is private.`}
      tooltipParagrahs={[
        'The company details you can enter below, are visible to designers and sits at the top of your profile, next to your logo for all designers to see.',
        "Please view 'Profile' in the menu to view what a designer will see. If you have any issues at support@buydesign.com.",
        'Please save each section separately.',
      ]}
      descriptionText={
        <>
          Edit your profile and manage account settings here. If you would like to change your email or need any help
          managing your account please send us a message{' '}
          <CustomStandardLink
            linkType="primary"
            text="here"
            href="/help"
            onClick={() => {
              navigate('/help');
            }}
            fontSize="large"
          />
          , or email{' '}
          <CustomStandardLink
            linkType="primary"
            text="support@buydesign.com"
            href={'mailto:support@buydesign.com'}
            fontSize="large"
            target="_blank"
          />
          .
        </>
      }
      loading={loading}
    >
      {formError && <FormError errors={[formError]} />}
      <FormProvider {...methods}>
        <form>
          <CustomFieldSet>
            <ControlledTextField name="first_name" label="First Name" />
            <ControlledTextField name="last_name" label="Last Name" />
            <CustomTextField label="Email" value={userEmail} disabled />
            <TwoFormFieldsRow>
              <ControlledDatePicker name="date_of_birth" label="Date of Birth" showError={true} />
              <ControlledTextField name="phone_number" label="Phone Number" />
            </TwoFormFieldsRow>
            {role !== userTypes.designer && <ControlledTextField name="position" label="Position In Company" />}
          </CustomFieldSet>
          <Box mt={2}>
            <CustomFieldSet description="Gender">
              <ControlledCustomRadioGroup
                name="gender"
                options={GENDER_OPTIONS}
                labelPlacement="end"
                direction="column"
                removeError={true}
              />
            </CustomFieldSet>
          </Box>
        </form>
      </FormProvider>
    </AccountFormContainer>
  );
};

ProfileDetailsForm.propTypes = {
  role: PropTypes.oneOf([userTypes.buyerUser, userTypes.buyerAdmin, userTypes.designer]),
  formData: PropTypes.object,
  userEmail: PropTypes.string,
  setSuccess: PropTypes.func.isRequired,
  isStory: PropTypes.bool,
};

export default ProfileDetailsForm;
