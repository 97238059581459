import React, { useEffect } from 'react';
import { useState } from 'react';

const ValidationHelper = ({ step, methods }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 0);
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, [step]);

  return (
    visible && (
      <input
        type="hidden"
        {...methods.register(`step${step}`, {
          shouldUnregister: true,
        })}
      />
    )
  );
};

export default ValidationHelper;
