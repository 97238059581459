import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { Box } from '@mui/system';
import { DashboardItemContainer, DashboardItemListWrapperStyled } from './DashboardItemList.styles';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CaptionText from 'components/Typography/CaptionText/CaptionText';
import { routes } from 'options/routes';

const DashboardItemList = ({ data }) => {
  const navigate = useNavigate();

  const onView = useCallback(
    (product_uuid) => {
      navigate(`${routes.product}/${product_uuid}`, {
        state: { from: routes.dashboard, fromTitle: 'Dashboard' },
      });
    },
    [navigate]
  );

  if (!data || !data.length) return null;

  return (
    <DashboardItemListWrapperStyled>
      {data.map((item, index) => {
        return (
          <DashboardItemContainer index={index} onClick={() => onView(item.uuid)} key={`${item.label}_${index}`}>
            <Box mb={1}>
              <CustomTitle text={item.label} />
            </Box>
            <CaptionText text={`Last viewed ${item.caption}`} />
          </DashboardItemContainer>
        );
      })}
    </DashboardItemListWrapperStyled>
  );
};

DashboardItemList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      caption: PropTypes.string,
      link_url: PropTypes.string,
    })
  ),
};

export default DashboardItemList;
