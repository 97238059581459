import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { Box } from '@mui/system';

import {
  HorizontalLine,
  LoadingContainer,
  NoResultsFoundWrapper,
  SearchResultItemWrapper,
  StyledForm,
  StyledNoSearchTermContainer,
  StyledRefinedPillWrapper,
  StyledRefinedSearchContentContainer,
  StyledSearchFieldContainer,
  StyledSearchFieldWrapper,
  StyledSearchResultsContainer,
} from './SearchResults.styles';
import CustomSearchField from 'components/CustomSearchField/CustomSearchField';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';
import ProductPropertyListGroup from 'components/ProductPropertyListGroup/ProductPropertyListGroup'
import CloseIcon from '@mui/icons-material/Close';
import { debounce } from 'debounce';
import Spinner from 'components/Spinner/Spinner';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';

import PillTag from 'components/PillTag/PillTag';

import './SearchResults.styles.css';
import BodyText from 'components/Typography/BodyText/BodyText';
import { getProductSearchBarResults } from 'api';
import { NavBarBuyerContext } from 'components/NavBar/NavBarBuyer/NavBarBuyer';
import { routes } from 'options/routes';

import CustomImageListItem from 'components/CustomImageListItem/CustomImageListItem';
import { getImageSrc } from 'utils/getImageSrc';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

const SearchResults = ({ isStory, mockData, mockLoading }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [refineOptions, setRefineOptions] = useState([]);
  const [data, setData] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const parsedFilter = useMemo(
    () => queryString.stringify({ search: searchTerm, tags: refineOptions }),
    [searchTerm, refineOptions]
  );

  const NavContext = useContext(NavBarBuyerContext);

  const debouncedApiCall = debounce(() => {
    setIsLoading(true);
    setNoResults('');
    getProductSearchBarResults(parsedFilter)
      .then((res) => {
        if (res?.success === true) {
          setNoResults(false);
          setData({
            featured: res.featured,
            suggested: res.suggested,
            tags: res.tags,
          });
          setIsLoading(false);
        }
        if (res?.data?.success === false && res?.data?.error_message === 'No results found.') {
          setData(null);
          setNoResults(true);
          setIsLoading(false);
        } else if (res?.data?.success === false && res?.data?.error_message !== 'No results found.') {
          setData(null);
        }
      })
      .catch((err) => console.log(err));
  }, 300);

  useEffect(() => {
    if (searchTerm.length < 3) return;
    debouncedApiCall();
  }, [parsedFilter]);

  const results = useMemo(() => (isStory ? mockData : data), [data, mockData, isStory]);
  const loading = useMemo(() => (isStory ? mockLoading : isLoading), [isStory, isLoading, mockLoading]);

  const navigate = useNavigate();
  const suggestedHandleNavigation = ({ value, label }) => {
    const { handleSearchClick } = NavContext;
    navigate(`${routes.productsCategorySearch}/${value}`, { state: { categoryLabel: label } });
    setSearchTerm('');
    handleSearchClick();
  };

  const featuredHandleNavigation = () => {
    const { handleSearchClick } = NavContext;
    navigate(`${routes.product}/${results?.featured?.product_uuid}`);
    setSearchTerm('');
    handleSearchClick();
  };

  const LoadingComponent = () => {
    return (
      <LoadingContainer>
        <Spinner align="center" />
      </LoadingContainer>
    );
  };

  const handleUpdateRefineSearch = (val) => {
    const isSelected = refineOptions.find((rop) => rop === val);
    setRefineOptions((prev) => (isSelected ? prev.filter((rop) => rop !== val) : [...prev, val]));
  };

  const RefineSearchPill = ({ option, onClick, colour }) => {
    return (
      <StyledRefinedPillWrapper onClick={onClick}>
        <PillTag colour={colour} option={{ label: option.name }} />
      </StyledRefinedPillWrapper>
    );
  };

  const overlayOptions = useMemo(() => {
    if (!results?.featured) return null;
    return {
      title: results?.featured?.name,
      description: `By ${results?.featured?.designer_name}`,
      menuOptions: [
        {
          title: 'View Design',
          onClick: () => featuredHandleNavigation(),
        },
      ],
    };
  }, [results?.featured, results?.featured?.name, results?.featured?.designer_name]);

  const products = results?.suggested || [];

  const componentArr = [
    {
      title: 'Suggested',
      gridName: 'suggested',
      component: loading ? (
        <LoadingComponent />
      ) : (
        <ProductPropertyListGroup
          fullWidth
          options={
            products.map(({ name, slug }) => {
              return {
                label: name,
                value: slug,
              }
            }
          )}
          onClick={suggestedHandleNavigation}
          listIndex={0}
          key={`list}`}
        />
      ),
    },
    {
      title: `New in ${!loading && results?.featured?.category_name ? results.featured.category_name : '...'}`,
      gridName: 'featured',
      component: loading ? (
        <LoadingComponent />
      ) : results?.featured ? (
        <CustomImageListItem
          src={results?.featured?.main_image ? getImageSrc(results?.featured?.main_image).fileSrc : ''}
          name={results?.featured?.name}
          overlayOptions={overlayOptions}
          onClick={() => featuredHandleNavigation()}
          styleOverride={{ maxHeight: 'unset' }}
        />
      ) : (
        <BodyText text="No featured product found for this search" />
      ),
    },
    {
      title: 'Refine your search',
      gridName: 'refine',
      component: loading ? (
        <LoadingComponent />
      ) : (
        <StyledRefinedSearchContentContainer>
          {results?.tags?.length > 0
            ? results.tags.map((tag) => {
                return (
                  <RefineSearchPill
                    option={tag}
                    onClick={() => handleUpdateRefineSearch(tag.slug)}
                    colour={refineOptions.find((o) => o === tag.slug) ? 'black' : 'gray'}
                    key={tag.slug}
                  />
                );
              })
            : null}
        </StyledRefinedSearchContentContainer>
      ),
    },
  ];

  useEffect(() => {
    if (searchTerm === '' || searchTerm.length < 3) {
      setRefineOptions([]);
      setIsLoading(false);
      setNoResults(false);
    }
  }, [searchTerm]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm?.length > 3) {
      NavContext.handleSearchClick();
      navigate(`${routes.products}/search/${searchTerm}`);
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledSearchFieldContainer>
        {searchTerm && noResults ? (
          <NoResultsFoundWrapper>
            <Box mb={2}>
              <BodyText
                text={
                  'There are no results matching your criteria in the quick search. You can search our site by clicking the button below.'
                }
              />
            </Box>
            <CustomButton variant="outlined" text="Search our site" onClick={handleSubmit} />
          </NoResultsFoundWrapper>
        ) : (
          <StyledSearchResultsContainer className="BD-SearchResultsContainer" searchTerm={searchTerm}>
            {componentArr.map((c) => {
              return (
                <SearchResultItemWrapper key={`grid-${c.gridName}`} className={c.gridName} gridName={c.gridName}>
                  <Box mb={2}>
                    <CustomTitle variant="h6" text={c.title} />
                  </Box>

                  {c.component}
                </SearchResultItemWrapper>
              );
            })}
            <HorizontalLine />
          </StyledSearchResultsContainer>
        )}
        <StyledSearchFieldWrapper>
          <CustomSearchField
            setSearchTerm={setSearchTerm}
            searchTerm={searchTerm}
            placeholder={'Search for a product'}
          />
          {searchTerm && (
            <Box>
              <CustomIconButton onClick={() => setSearchTerm('')} icon={<CloseIcon />} />
            </Box>
          )}
        </StyledSearchFieldWrapper>
      </StyledSearchFieldContainer>
      {(!searchTerm || searchTerm.length < 3) && !noResults && (
        <StyledNoSearchTermContainer>
          <BodyText text="Use the search bar above to browse the BuyDesign market of available products, created by our plethora of talented designers..." />
        </StyledNoSearchTermContainer>
      )}
    </StyledForm>
  );
};

SearchResults.propTypes = {
  isStory: PropTypes.bool,
  mockData: PropTypes.object,
  mockLoading: PropTypes.bool,
};

export default React.memo(SearchResults);
