import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid } from '@mui/material';
import CustomDivider from 'components/Divider/CustomDivider';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import FormParagraph from 'components/Typography/FormParagraph/FormParagraph';
import PricingOptionsListItem from '../PricingOptionsList/PricingOptionsListItem';

import { ListStyled, PricingOptionChip } from './PricingOptionsTableSection.styles';

const PricingOptionsTableSection = ({
  title,
  priceTitle,
  description,
  listItems,
  buttonText,
  buttonColour,
  onClick,
  showDivider,
  accountType,
}) => {
  return (
    <Grid display="flex" flexDirection="column">
      {showDivider && <CustomDivider orientation="horizontal" />}
      <Box my={2}>
        <CustomTitle variant="h4" text={title} align="center" />
        <CustomTitle variant="h4" text={priceTitle} align="center" />
      </Box>
      <Box mt={2} mb={8} height="25%">
        <FormParagraph text={description} />
      </Box>
      <ListStyled>
        {listItems.map((listItem) => (
          <PricingOptionsListItem listItem={listItem} key={listItem.label} />
        ))}
      </ListStyled>
      <Box mt={1}>
        <PricingOptionChip
          variant={'contained'}
          color={buttonColour}
          label={buttonText}
          onClick={onClick && (() => onClick(accountType))}
        />
      </Box>
    </Grid>
  );
};

PricingOptionsTableSection.propTypes = {
  title: PropTypes.string,
  priceTitle: PropTypes.string,
  description: PropTypes.string,
  listItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ),
  buttonText: PropTypes.string,
  buttonColour: PropTypes.oneOf(['primary', 'secondary', 'white']),
  onClick: PropTypes.func,
  showDivider: PropTypes.bool,
};

export default PricingOptionsTableSection;
