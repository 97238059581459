import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQueryClient } from 'react-query';

import SearchIcon from '@mui/icons-material/Search';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import FormStepper from 'components/FormStepper/FormStepper';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';

import { termsSteps } from './BuyerTermsNewSteps';

import { formDefaultValues, formValidation, steps } from './BuyerTermsNew.data';

import { parseFormData, parseRemoteData } from './utils/BuyerTermsNew.utils';

import { routes } from 'options/routes';

import { createTerms, getSingleTerm, updateTerms } from 'api';

import { mapErrorsObject } from 'utils/apiErrors';

export const BuyerTermsNew = ({ isStory }) => {
  const { terms_uuid } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [apiFormData, setApiFormData] = useState({});

  useEffect(() => {
    const getInitialValues = async () => {
      const res = await getSingleTerm(terms_uuid);
      if (res.success) {
        setApiFormData(parseRemoteData(res.terms));
      }
    };

    if (terms_uuid) {
      getInitialValues();
    }
  }, [terms_uuid]);

  const createOrUpdateTerms = useCallback(async (values) => {
    const parsedFormData = parseFormData(values);
    const { terms_uuid } = parsedFormData;

    return !terms_uuid ? await createTerms(parsedFormData) : await updateTerms(terms_uuid, parsedFormData);
  }, []);

  const nextStepAction = useCallback(
    async (values) => {
      const res = await createOrUpdateTerms(values);

      if (!res.success) {
        if (res?.data?.errors)
          throw new Error(res?.data?.errors ? mapErrorsObject(res?.data?.errors) : 'Internal Error');
        else throw new Error(res.data?.error_message || res.statusText || 'Internal Error');
      }
      queryClient.invalidateQueries({ queryKey: ['existingTermsList'] });
      return navigate(routes.termsThanks);
    },
    [createOrUpdateTerms, navigate, queryClient]
  );

  return (
    <BuyerDashboardWrapper
      activeTab={routes.termsDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Terms', onClick: () => navigate(routes.termsDashboard) }]}
          currentPage={'New Template'}
        />
      }
    >
      <HeaderAndMenu
        title="New Terms Template"
        menuOptions={[
          { buttonTitle: 'View all terms', icon: <SearchIcon />, onClick: () => navigate(routes.termsDashboard) },
        ]}
      />
      <FormStepper
        steps={steps}
        stepsSwitcher={termsSteps}
        formValidation={formValidation}
        defaultFormValues={formDefaultValues}
        apiFormData={apiFormData}
        nextStepAction={nextStepAction}
        saveAndExitSteps={[]}
        lastStepAction={nextStepAction}
        isStory={isStory}
      />
    </BuyerDashboardWrapper>
  );
};

export default BuyerTermsNew;
