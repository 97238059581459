import React from 'react';
import PropTypes from 'prop-types';
import { CustomAccordionWrapperStyled } from './CustomAccordion.styles';

const CustomAccordion = ({ id, expanded, onChange, children, index }) => {
  return (
    <CustomAccordionWrapperStyled
      disableGutters
      square
      elevation={0}
      onChange={() => onChange(id)}
      expanded={expanded}
      index={index}
    >
      {children}
    </CustomAccordionWrapperStyled>
  );
};

CustomAccordion.propTypes = {
  id: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomAccordion;
