import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { LICENSE_TYPES } from 'options/briefOptions';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { userTypes } from 'options/userTypes';
import { mockProductImageObject } from './mockProductImage';
import { MOCK_DESIGNER_1 } from './mockDesigner';
import { MOCK_COMPANY_IMG_OBJ } from './mockCompanyImage';

export const MOCK_BRIEF_DATA = {
  title: 'Dining Chair',
  [OPTIONS_TYPE.product]: [
    { label: 'Furniture', value: 'furniture' },
    {
      label: 'Accent',
      value: 'accent',
    },
    {
      label: 'ArmChair',
      value: 'armchair',
    },
  ],
  [OPTIONS_TYPE.material]: [
    { label: 'Wood', value: 'wood' },
    { label: 'Ash', value: 'ash' },
  ],
  [OPTIONS_TYPE.space]: [{ label: 'Indoor', value: 'indoor' }],
  [OPTIONS_TYPE.style]: [{ label: 'Modern', value: 'modern' }],
  [OPTIONS_TYPE.colour]: [{ label: 'Red', value: 'red' }],
  [OPTIONS_TYPE.sustainability]: [],
  main_image: [mockProductImageObject],
  additional_images: [
    mockProductImageObject,
    mockProductImageObject,
    mockProductImageObject,
    mockProductImageObject,
    mockProductImageObject,
  ],
  design_requirements:
    'Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.',
  customer_requirements:
    'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.',
  commercial_requirements:
    'Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.',
  markets: [
    { label: 'EU', value: 'eu' },
    { label: 'Asia', value: 'asia' },
    { label: 'UK', value: 'uk' },
    { label: 'USA', value: 'usa' },
  ],
  manufacturing_requirements:
    'Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.',
  userType: userTypes.buyer,
  isEdit: true,

  //licensing

  agreement_type: LICENSE_TYPES.royalty,
  payment_terms: 'Payment terms',
  deadline: new Date(),
  upfront_payment: 'true',
  upfront_payment_amount: '10',
  //   royalty licensing specific fields
  royalty_percentage: '10',
  royalty_review_period: '10',
  //   one time fee specific fields
  one_off_budget: '10',

  agreement_title: 'Terms agreement title',
  agreement_body: 'Terms body',
  agreement_file: [
    {
      file_uuid: 'asdgfarg-865251-54984-8465',
      fileSrc: 'base64',
      fileType: '.pdf',
      name: 'test-file-1.pdf',
    },
  ],
  project_status: 'pending',
  proposal_status: 'pending',
  agreement_status: AGREEMENT_STATUS.AGREED,
  chat_uuid: '123',
  selected_terms_uuid: '123',
};

// 'brief_uuid',
//   'title',
//   'categories',
//   'materials',
//   'spaces',
//   'styles',
//   'colours',
//   'main_image',
//   'additional_images',
//   'proposal_submitted';

export const MOCK_BRIEF_DATA_OPEN_ACCORDION = {
  title: 'Dining Chair',
  categories: [
    { label: 'Furniture', value: 'furniture' },
    {
      label: 'Accent',
      value: 'accent',
    },
    {
      label: 'ArmChair',
      value: 'armchair',
    },
  ],
  materials: [
    { label: 'Wood', value: 'wood' },
    { label: 'Ash', value: 'ash' },
  ],
  spaces: [{ label: 'Indoor', value: 'indoor' }],
  styles: [{ label: 'Modern', value: 'modern' }],
  colours: [{ label: 'Red', value: 'red' }],
  main_image: [mockProductImageObject],
  additional_images: [mockProductImageObject, mockProductImageObject, mockProductImageObject],
  design_requirements:
    'Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.',
};

export const MOCK_BRIEF_LIST_ITEM_BUYERS = {
  brief_uuid: 'e154d6be-71dc-410e-a2b6-4d91569864b0',
  link_to_brief: 'designer-briefs/e154d6be-71dc-410e-a2b6-4d91569864b0',
  title: 'new',
  designers: [
    {
      user_uuid: '1d52c4f3-8677-41ff-9508-ebf9e63c7dd3',
      name: 'Sunny Hebbar',
      portrait: MOCK_DESIGNER_1.portrait,
    },
  ],
  categories: [
    {
      name: 'Furniture',
      slug: 'furniture',
    },
  ],
  date_sent: '2022-10-12',
  deadline: '2022-11-25',
  status: 'active',
};

export const MOCK_BRIEF_LIST_BUYERS = Array(5)
  .fill()
  .map((_i) => ({ ...MOCK_BRIEF_LIST_ITEM_BUYERS, brief_uuid: String(Math.round(Math.random())) }));

export const MOCK_BRIEF_LIST_ITEM_DESIGNERS = {
  brief_uuid: 'e154d6be-71dc-410e-a2b6-4d91569864b0',
  link_to_brief: 'designer-briefs/e154d6be-71dc-410e-a2b6-4d91569864b0',
  title: 'Brief Title',
  company_logo: MOCK_COMPANY_IMG_OBJ,
  company_name: 'J B Cole UK',
  company_categories: [],
  categories: [
    {
      name: 'Furniture',
      slug: 'furniture',
    },
  ],
  date_sent: '2022-10-12',
  deadline: '2022-11-25',
  status: 'pending',
};

export const MOCK_BRIEF_LIST_DESIGNERS = Array(5)
  .fill()
  .map((_i) => ({ ...MOCK_BRIEF_LIST_ITEM_DESIGNERS, brief_uuid: String(Math.round(Math.random() * 30)) }));

export const MOCK_EXISTING_BRIEF_LIST_ITEM = {
  title: 'Favia furniture',
  categories: [
    { name: 'Furniture', slug: 'furniture' },
    { name: 'Wood', slug: 'wood' },
  ],
  date_sent: '03/05/2020',
  brief_uuid: '123',
};

export const MOCK_EXISTING_BRIEF_LIST = Array(5)
  .fill()
  .map((_i) => ({ ...MOCK_EXISTING_BRIEF_LIST_ITEM, brief_uuid: String(Math.round(Math.random())) }));
