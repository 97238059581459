import CheckIcon from '@mui/icons-material/Check';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

export const EnterpriseBenefitsListData = [
  { label: 'Browse Products', icon: <CheckIcon /> },
  { label: 'Search By Designer', icon: <CheckIcon /> },
  { label: 'Brief individual designers', icon: <CheckIcon /> },
  { label: 'Brief multiple designers', icon: <CheckIcon /> },
  { label: 'Number of Users', icon: <AllInclusiveIcon /> },
  { label: 'Moodboards', icon: <AllInclusiveIcon /> },
];
