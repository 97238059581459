import SelectCompanyForm from 'components/Forms/SelectCompanyForm/SelectCompanyForm';
import ProductListPreviewPage from 'components/ProductListPreviewPage/ProductListPreviewPage';

export const productPitchConversion = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0: {
      return <ProductListPreviewPage />;
    }
    case 1: {
      return <SelectCompanyForm />;
    }

    default: {
      return <></>;
    }
  }
};

export const productPitchConversionWithProduct = (activeStep, isStory, userType, stepsOptions) => {
  switch (activeStep) {
    case 0: {
      return <SelectCompanyForm />;
    }

    default: {
      return <></>;
    }
  }
};
