import React from 'react';
import { CustomTermsLinkStyled } from './CustomTermsLink.styles';

const CustomTermsLink = ({ text, to }) => {
  return (
    <CustomTermsLinkStyled component="a" to={to} underline="hover">
      {text}
    </CustomTermsLinkStyled>
  );
};

export default CustomTermsLink;
