import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { Box } from '@mui/system';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import ControlledCustomRadioGroup from 'components/Inputs/CustomRadioStandardGroup/ControlledCustomRadioGroup/ControlledCustomRadioGroup';
import { NEW_BRIEF_BUDGET_OPTIONS } from './NewBriefOneTimeFeeForm.data';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import SingleInputResponsiveWrapper from 'components/Wrappers/SingleInputResponsiveWrapper/SingleInputResponsiveWrapper';
import { BRIEF_UPFRONT_FEE_OPTIONS } from '../NewBriefRoyaltyLicenseForm/NewBriefRoyaltyLicenseForm.data';
import ControlledCustomRadioGroupWithTextField from 'components/Inputs/ControlledCustomRadioGroupWithTextField/ControlledCustomRadioGroupWithTextField';
import RadioWithTextFieldWrapper from 'components/Wrappers/RadioWithTextFieldWrapper/RadioWithTextFieldWrapper';
import ControlledDatePicker from 'components/Inputs/CustomDatePicker/ControlledDatePicker/ControlledDatePicker';
import FormFieldSetAndInputWrapper from 'components/Wrappers/FormFieldSetAndInputWrapper/FormFieldSetAndInputWrapper';

const NewBriefOneTimeFeeForm = ({ variant, currency = '£' }) => {
  const { watch, unregister, register } = useFormContext();
  const upfrontPayment = watch('upfront_payment', 'yes');

  useEffect(() => {
    if (upfrontPayment === 'no') unregister('upfront_payment_amount');
    if (upfrontPayment === 'yes') register('upfront_payment_amount');
  }, [register, unregister, upfrontPayment]);

  return (
    <div>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Budget"
          description="If you wish to pay the designer a one-time fee, please indicate the amount this can be negotiated later. As per T&C, a one-time fee is subject to an additional BuyDesign 15% commission."
          // tooltipText='The final price you pay will include 15% application fee'
        />
        <ControlledCustomRadioGroupWithTextField
          radioOptions={NEW_BRIEF_BUDGET_OPTIONS.map(({ label, value }) => ({ label: `${currency}${label}`, value }))}
          name="one_off_budget"
          inputPlaceholder={'Specify other budget'}
          inputStartAdornment={currency}
          isModal={variant === 'negotiation'}
        />
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet label="Additional Information" description="Please supply any additional information" />
        <ControlledTextField
          multiline={true}
          minRows={4}
          placeholder={'Any additional information'}
          name="payment_terms"
        />
      </FormFieldSetAndInputWrapper>
      {variant !== 'negotiation' && (
        <>
          <FormFieldSetAndInputWrapper>
            <CustomFieldSet
              label="Deadline"
              description="Please choose a deadline date for the acceptance of the brief. You can discuss the deliverable dates directly with the designer once they have accepted the brief, using chat/messaging."
            />
            <SingleInputResponsiveWrapper>
              <ControlledDatePicker name="deadline" />
            </SingleInputResponsiveWrapper>
          </FormFieldSetAndInputWrapper>
          <FormFieldSetAndInputWrapper>
            <CustomFieldSet
              label="Before work starts will you offer an up front payment?"
              description="Some designers may request an advanced payment to start the work."
              tooltipParagrahs={['The advanced payment to start the work for a one-off payment will be deducted from your final budget amount.',
                'As per T&C, a one-time fee is subject to an additional BuyDesign 15% commission.'
              ]}
            />
            <RadioWithTextFieldWrapper>
              <Box mr={2}>
                <ControlledCustomRadioGroup
                  direction="row"
                  name="upfront_payment"
                  options={BRIEF_UPFRONT_FEE_OPTIONS}
                />
              </Box>
              {upfrontPayment === 'yes' && (
                <SingleInputResponsiveWrapper>
                  <ControlledTextField
                    placeholder="Enter your upfront fee"
                    startAdornment={currency}
                    name="upfront_payment_amount"
                  />
                </SingleInputResponsiveWrapper>
              )}
            </RadioWithTextFieldWrapper>
          </FormFieldSetAndInputWrapper>
        </>
      )}
    </div>
  );
};

NewBriefOneTimeFeeForm.propTypes = {};

export default NewBriefOneTimeFeeForm;
