import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { PROJECT_STATUS } from 'options/projectStatus';

export const getIsAgreementTabDisabled = (project_status) => {
  return project_status === PROJECT_STATUS.REJECTED;
};

export const getIsFilesTabDisabled = (agreement_status) => {
  return agreement_status !== AGREEMENT_STATUS.AGREED && agreement_status !== AGREEMENT_STATUS.PAID;
};

export const getIsProjectCompleted = (project_status) => {
  return project_status === PROJECT_STATUS.COMPLETED;
};
