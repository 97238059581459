import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import { NavBarBuyerContext } from 'components/NavBar/NavBarBuyer/NavBarBuyer';
import NavBarNotificationButton from '../NavBarIcon/NavBarNotificationButton';
import NavBarMenu from '../NavBarMenu/NavBarMenu';
import NavBarLink from '../NavNarLink/NavBarLink';
import NavBarIcon from '../NavBarIcon/NavBarIconButton';

import { routes } from '../../../../options/routes';

import { useMediaQuery } from '@mui/material';

import { LINKS_OPTIONS, LINKS_OPTIONS_MOBILE, MENU_OPTIONS, MENU_OPTIONS_MOBILE } from './NavBarLinkList.data';

import { IconsWrapper, LinkListStyled } from './NavBarLinkList.styles';

const NavBarLinkList = ({ type, isStory }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(1050));
  const navigate = useNavigate();

  const NavContext = useContext(type === 'buyer' ? NavBarBuyerContext : {});

  const options = useMemo(() => {
    const links = isMobile ? LINKS_OPTIONS_MOBILE : LINKS_OPTIONS;
    return links[type];
  }, [type, isMobile]);

  const menuOptions = useMemo(() => {
    return isMobile ? MENU_OPTIONS_MOBILE[type] : MENU_OPTIONS[type];
  }, [isMobile, type]);

  return (
    <LinkListStyled>
      {options.map((link) => {
        // navBarLinksIconsAndFns can be an array of objects that will include an icon, the linkText it corresponds to, and an onClick fn for the icon
        if (NavContext?.navBarLinksIconsAndFns) {
          const thisIconAndFn = NavContext.navBarLinksIconsAndFns.find((item) => item.linkText === link.linkText);
          if (thisIconAndFn)
            return (
              <NavBarLink iconOnClick={thisIconAndFn.onClick} icon={thisIconAndFn.icon} {...link} key={link.linkText} />
            );
        }
        return <NavBarLink {...link} key={link.linkText} />;
      })}
      {type !== 'standard' && (
        <IconsWrapper>
          <NavBarNotificationButton isStory={isStory} />
          {NavContext && (
            <NavBarIcon
              type="search"
              onClick={NavContext?.handleSearchClick ? NavContext.handleSearchClick : undefined}
            />
          )}
          <NavBarMenu options={menuOptions} />
        </IconsWrapper>
      )}
      {type === 'standard' && isMobile && <NavBarMenu options={menuOptions} />}
      {type === 'standard' && !isMobile && (
        <CustomButton colour="white" variant="outlined" text="Sign up" onClick={() => navigate(routes.signup)} />
      )}
    </LinkListStyled>
  );
};

NavBarLinkList.propTypes = {
  iconsAndFns: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      linkText: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default NavBarLinkList;
