import { Box } from '@mui/material';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';
import ProductList from 'components/ProductList/ProductList';
import Spinner from 'components/Spinner/Spinner';
import Pagination from '@mui/material/Pagination';

import { mockData } from './ProductsAll.data';

import { PaginationWrapper, TitleWrapper } from './ProductsAll.styles';
import { PRODUCTS_PER_PAGE } from 'options/pagination';

const ProductsAll = ({
  isStory,
  userType,
  title,
  isLoading,
  products,
  menuOptions,
  notFound,
  total,
  pageCount,
  onPageChange,
  page,
  resetFilter,
  hasFilter,
}) => {
  return (
    <Box mb={6}>
      <TitleWrapper mb={4}>
        <SearchAndFilterOptions
          hasFilter={hasFilter}
          resetFilter={resetFilter}
          title={title}
          menuOptions={menuOptions}
        />
      </TitleWrapper>
      {isLoading && <Spinner align="center" />}
      {!isLoading && !products?.length && notFound}
      {!isLoading && !!products?.length && <ProductList products={isStory ? mockData : products} userType={userType} />}
      {!isLoading && total > PRODUCTS_PER_PAGE && (
        <PaginationWrapper>
          <Pagination showFirstButton showLastButton page={page} count={pageCount} onChange={onPageChange} />
        </PaginationWrapper>
      )}
    </Box>
  );
};

ProductsAll.propTypes = {};

export default ProductsAll;
