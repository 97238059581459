import { styled } from '@mui/material';

export const ScoreCardWrapperStyled = styled('div', {
  shouldForwardProp: (props) => props !== 'gridRow' && props !== 'gridColumn',
})(({ gridRow, gridColumn, theme, sx }) => ({
  padding: '24px 16px',
  ...sx,

  gridColumn: gridColumn,
  gridRow: gridRow,

  '& .MuiTypography-h4': {
    marginBottom: 24,
    fontWeight: '500',
    fontSize: 22,
  },
  '& .MuiTypography-body1': {
    fontSize: 36,
    fontWeight: 'bold',
  },
}));
