import React from 'react';
import PropTypes from 'prop-types';
import { useOutletContext } from 'react-router';

import BriefSummary from 'components/BriefSummary/BriefSummary';

const DesignerOpenCallsSingleTab = () => {
  const [briefData, setMessage, userType] = useOutletContext();

  return <BriefSummary briefDetails={briefData} userType={userType} filePreviewEnabled={true} />;
};

DesignerOpenCallsSingleTab.propTypes = {};

export default DesignerOpenCallsSingleTab;
