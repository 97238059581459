export const STRIPE_STATUS_BUYER = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
  EXPIRED: 'expired',
};

export const STRIPE_STATUS_DESIGNER = {
  PENDING: 'pending',
  INCOMPLETE: 'incomplete',
  CONNECTED: 'connected',
};
