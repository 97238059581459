import { routes } from './routes';

export const DESIGNER_TAB_GROUP_OPTIONS = [
  {
    label: 'Dashboard',
    route: routes.dashboard,
  },
  {
    label: 'Products',
    route: routes.productsDashboard,
  },
  {
    label: 'Briefs',
    route: routes.briefsDashboard,
  },
  {
    label: 'Open  Calls',
    route: routes.openCallsDashboard,
  },
  {
    label: 'Pitches',
    route: routes.pitchesDashboard,
  },
];

export const BUYER_TAB_GROUP_OPTIONS = [
  {
    label: 'Dashboard',
    route: routes.dashboard,
  },
  {
    label: 'Designers',
    route: routes.buyerDesignersDashboard,
  },
  {
    label: 'Moodboards',
    route: routes.moodboardsDashboard,
  },
  {
    label: 'Products',
    route: routes.productsDashboard,
  },
  {
    label: 'Briefs',
    route: routes.briefsDashboard,
  },
  {
    label: 'Open Calls',
    route: routes.openCallsDashboard,
  },
  {
    label: 'Pitches',
    route: routes.pitchesDashboard,
  },
  {
    label: 'Terms',
    route: routes.termsDashboard,
  },
];

export const BUYER_USER_TAB_GROUP_OPTIONS = [
  {
    label: 'Dashboard',
    route: routes.dashboard,
  },
  {
    label: 'Designers',
    route: routes.buyerDesignersDashboard,
  },
  {
    label: 'Moodboards',
    route: routes.moodboardsDashboard,
  },
  {
    label: 'Products',
    route: routes.productsDashboard,
  },
  {
    label: 'Briefs',
    route: routes.briefsDashboard,
  },
  {
    label: 'Open Calls',
    route: routes.openCallsDashboard,
  },
  {
    label: 'Terms',
    route: routes.termsDashboard,
  },
];
