import { styled, Box } from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

export const PillWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(3),
  right: theme.spacing(3),
}));

export const VisibilityOffOutlinedIconStyled = styled(VisibilityOffOutlinedIcon)(({ theme }) => ({
  color: `${theme.palette.text.secondary}`,
}));
