import { useEffect, useState, useCallback } from 'react';

import { Box } from '@mui/system';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import BodyText from 'components/Typography/BodyText/BodyText';
import Spinner from 'components/Spinner/Spinner';

import { STRIPE_STATUS_DESIGNER } from 'options/stripeStatus';

import { ErrorIconStyled } from './DesignerManageStripe.styles';

import { getStripeAccountUrl } from 'api';

const DesignerManageStripe = ({ stripe_status, setMessage }) => {
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const buttonTextOptions = {
    [STRIPE_STATUS_DESIGNER.PENDING]: 'Connect Account',
    [STRIPE_STATUS_DESIGNER.INCOMPLETE]: 'Complete Account',
    [STRIPE_STATUS_DESIGNER.CONNECTED]: 'Manage Account',
  };

  const getUrl = useCallback(async () => {
    const res = await getStripeAccountUrl();
    if (res.success && res.url) {
      setUrl(res.url);
    } else {
      setMessage('There was an error when attempting to get some or all of the data.');
      setError('Unable to connect to Stripe');
    }
    setLoading(false);
  }, [setMessage]);

  useEffect(() => {
    getUrl();
  }, [getUrl]);

  return (
    <div id="stripe-section">
      <Box flexDirection="row" display="flex">
        <CustomTitle variant="h4" text="Manage Your Stripe Account" />
        {stripe_status !== STRIPE_STATUS_DESIGNER.CONNECTED && <ErrorIconStyled color="warning" />}
      </Box>

      <Box display="flex" mt={1}>
        {loading ? (
          <Spinner align={'center'} />
        ) : (
          <>
            {url && (
              <CustomButton
                text={stripe_status ? buttonTextOptions[stripe_status] : 'Connect Account'}
                onClick={() => {
                  window.location.href = url;
                }}
                colour="primary"
              />
            )}
            {error && <BodyText text={error} />}
          </>
        )}
      </Box>
    </div>
  );
};

DesignerManageStripe.propTypes = {};

export default DesignerManageStripe;
