import React, { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import BriefProposalReviewForm from 'components/BriefProposalReviewForm/BriefProposalReviewForm';

import { PROJECT_STATUS } from 'options/projectStatus';
import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { routes } from 'options/routes';

import { getShowReview } from '../BuyerPitchesSingle.utils';

import { reviewPitchPurchase } from 'api';

const BuyerPitchesSingleReviewTab = (props) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pitch_uuid } = useParams();
  const [pitch, setMessage] = useOutletContext();
  const designer = pitch?.designer;

  useEffect(() => {
    const { project_status } = pitch;
    const showReview = getShowReview(project_status);
    if (!showReview) {
      navigate(`${routes.pitchesDashboard}/${pitch_uuid}/pitch`);
    }
  }, [navigate, pitch, pitch_uuid]);

  const onSubmit = async (d) => {
    try {
      const res = await reviewPitchPurchase(pitch_uuid, {
        rating: d.rating,
        details: d.review,
      });

      if (!res.success) {
        throw new Error('There was an error submtting review');
      }
      queryClient.refetchQueries(['buyerSinglePitch', pitch_uuid], { type: 'active', exact: true });
      navigate(`${routes.pitchesDashboard}/${pitch_uuid}/pitch`);
    } catch (err) {
      setMessage(err.toString());
    }
  };

  return (
    <Box mt={2}>
      <BriefProposalReviewForm designer={designer} onSubmit={onSubmit} />
    </Box>
  );
};

BuyerPitchesSingleReviewTab.propTypes = {};

export default BuyerPitchesSingleReviewTab;
