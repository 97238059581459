import React from 'react';
import PropTypes from 'prop-types';

import { IconButtonStyled, IconButtonTextStyled } from './CustomIconButton.styles';

const CustomIconButton = ({
  icon,
  onClick,
  text,
  disabled,
  colour,
  fontWeight = 'unset',
  breakpoint,
  animate,
  hoverPeachBg,
}) => (
  <IconButtonStyled
    hoverPeachBg={hoverPeachBg}
    onClick={onClick}
    text={text}
    disabled={disabled}
    colour={colour}
    animate={animate}
    hasIcon={!!icon}
  >
    {icon}
    {text && (
      <IconButtonTextStyled
        icon={!!icon}
        breakpoint={breakpoint ? breakpoint : 'sm'}
        disabled={disabled}
        fontWeight={fontWeight}
      >
        {text}
      </IconButtonTextStyled>
    )}
  </IconButtonStyled>
);

CustomIconButton.propTypes = {
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default CustomIconButton;
