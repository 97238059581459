import React, { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FileUploads from 'components/FileUploads/FileUploads';
import Spinner from 'components/Spinner/Spinner';

import { PROJECT_STATUS } from 'options/projectStatus';
import { routes } from 'options/routes';

import { getIsFilesTabDisabled } from '../DesignerSingleBrief.utils';

import {
  deleteBriefDesignerFiles,
  downloadBriefDesignerFiles,
  getBriefDesignerFiles,
  uploadBriefDesignerFiles,
} from 'api';

const DesignerBriefDesigns = ({ userType }) => {
  const navigate = useNavigate();
  const { brief_uuid } = useParams();
  const [briefData, setMessage] = useOutletContext();

  const { data, isLoading, refetch } = useQuery(
    ['designerFiles', brief_uuid],
    () => getBriefDesignerFiles(brief_uuid),
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const project_status = briefData?.project_status || '';
  const proposal_status = briefData?.proposal_status || '';
  const agreement_status = briefData?.agreement_status || '';
  const brief_status = briefData?.status || '';
  const isProjectCompleted = project_status === PROJECT_STATUS.COMPLETED;

  useEffect(() => {
    const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status, brief_status);

    if (isFilesTabDisabled) {
      navigate(`${routes.briefsDashboard}/${brief_uuid}/brief`);
    }
  }, [brief_uuid, navigate, isProjectCompleted, project_status, proposal_status, agreement_status, brief_status]);

  const files = data?.design_files || {};

  const onUploadFile = async (filesData) => {
    const data = {
      files: filesData,
      is_chat: false,
    };

    const res = await uploadBriefDesignerFiles(brief_uuid, data);
    if (!res.success) {
      return setMessage('There was an error uploading the files. Please try again.');
    }
    setMessage('You have successfully uploaded files');
    refetch();
  };

  return (
    <Box>
      {isLoading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <Box mt={2} width="100%">
          <FileUploads
            withUploader={!isProjectCompleted}
            uploadFile={onUploadFile}
            files={files}
            downloadFiles={(data) => downloadBriefDesignerFiles(brief_uuid, data, true)}
            deleteFiles={!isProjectCompleted && ((file_uuid) => deleteBriefDesignerFiles(brief_uuid, file_uuid))}
            setMessage={setMessage}
            refetch={refetch}
            userType={userType}
          />
        </Box>
      )}
    </Box>
  );
};

DesignerBriefDesigns.propTypes = {};

export default DesignerBriefDesigns;
