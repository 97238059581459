import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { PROJECT_STATUS } from 'options/projectStatus';
import { getRandomFromList } from 'utils/getRandomFromList';
import { mockProductImageObject, mockProductImageUrl_1, mockProductList } from './mockProductImage';

export const MOCK_PRODUCT_LIST_ITEM_DESIGNER = {
  image: { fileSrc: mockProductImageUrl_1, fileType: 'image' },
  name: 'Hop Chair',
  categories: [
    { name: 'Chair', slug: 'chair' },
    { name: 'Wood', slug: 'wood' },
    { name: 'Dining Room', slug: 'dining-room' },
  ],
  date_created: '03/05/2020',
  views: '10',
  status: 'pending_approval',
  product_uuid: '123',
  edit_product_url: '/some-edit-url',
};

export const MOCK_PRODUCT_LIST_DESIGNER = Array(10)
  .fill()
  .map((_item) => ({
    ...MOCK_PRODUCT_LIST_ITEM_DESIGNER,
    image: getRandomFromList(mockProductList),
    product_uuid: String(Math.random()),
  }));

export const MOCK_PRODUCT_LIST_ITEM_BUYER = {
  image: { fileSrc: mockProductImageUrl_1, fileType: 'image' },
  name: 'Hop Chair',
  categories: [
    { name: 'Chair', slug: 'chair' },
    { name: 'Wood', slug: 'wood' },
    { name: 'Dining Room', slug: 'dining-room' },
  ],
  purchased: '03/05/2020',
  price: 1000,
  status: 'pending_approval',
  product_uuid: '123',
};

export const MOCK_PRODUCT_LIST_BUYER = Array(10)
  .fill()
  .map((_item) => ({
    ...MOCK_PRODUCT_LIST_ITEM_BUYER,
    product_uuid: String(Math.random()),
    image: getRandomFromList(mockProductList),
  }));

export const MOCK_PRODUCT = {
  main_image: [mockProductImageObject],
  additional_images: [mockProductImageObject, mockProductImageObject, mockProductImageObject],
  name: 'Coast Chair',
  designer_name: 'Harry Batchelor',
  concept:
    'An outdoor chair with a robust galvanised steel frame and inviting soft wooden slats. Durable, stackable, and designed from materials that will gain a natural patina over time the Coast chair with its warm wooden seat and back provides durability and comfort beyond most contemporary painted steel outdoor furniture. This is part of a family including tables and lower lounge chairs.',
  price: '10.00',
  construction:
    'Dynamic Wood Product, flat packed self-assembled. Self assembly of 5 elements with dowel and fixing construction.',
  colour_material_finish: 'Some colour material finish description',
  width: '532',
  height: '200',
  depth: '100',
  material_tag: [
    { label: 'Wood', value: 'wood' },
    { label: 'Plastic', value: 'plastic' },
    { label: 'Metal', value: 'metal' },
  ],
  colour_tag: [
    { label: 'Red', value: 'red' },
    { label: 'Orange', value: 'orange' },
    { label: 'Blue', value: 'blue' },
  ],
  production_files: [
    { fileType: '.ai', name: 'ai' },
    { fileType: '.ai', name: 'ai' },
    { fileType: '.ai', name: 'ai' },
    { fileType: '.ind', name: 'ind' },
    { fileType: '.psd', name: 'psd' },
    { fileType: '.tiff', name: 'tiff' },
    { fileType: '.pdf', name: 'pdf' },
    { fileType: '.pdf', name: 'pdf' },
    { fileType: '.pdf', name: 'pdf' },
    { fileType: '.pdf', name: 'pdf' },
  ],
  agreement_type: 'one_off',
  registered: 'yes',
  registered_info:
    'You are buying the rights, to manufacture and sell this design. As part of the purchase you will have download access to all the Technical Data Sheets, Drawings, 3D models & Visual Assets required to manufacture this design.',
  terms_accepted: true,
  product_uuid: '123',
  project_status: PROJECT_STATUS.PENDING,
  agreement_status: AGREEMENT_STATUS.AWAITING_SIGN,
  is_negotiating: false,
};

export const MOCK_PRODUCT_PROPOSAL_LIST_ITEM = {
  company: {
    uuid: '123',
    name: 'JB Cole',
    logo: mockProductImageObject,
  },
  buyer_product_uuid: '1234',
  project_status: 'pending',
};

export const MOCK_PRODUCT_PROPOSAL_LIST = Array(5)
  .fill()
  .map((_i) => ({
    ...MOCK_PRODUCT_PROPOSAL_LIST_ITEM,
    uuid: String(Math.random()),
    buyer_product_uuid: String(Math.random()),
  }));

const MOCK_PRODUCT_LIST_PREVIEW = {
  image: { fileSrc: mockProductImageUrl_1, fileType: 'image' },
  name: 'Hop Chair',
  categories: [
    { name: 'Chair', slug: 'chair' },
    { name: 'Wood', slug: 'wood' },
    { name: 'Dining Room', slug: 'dining-room' },
  ],
  date_created: '03/05/2020',
  product_uuid: '123',
};

export const MOCK_PRODUCT_LIST_PREVIEW_LIST = Array(10)
  .fill()
  .map((_item) => ({
    ...MOCK_PRODUCT_LIST_PREVIEW,
    image: getRandomFromList(mockProductList),
    product_uuid: String(Math.random()),
  }));
