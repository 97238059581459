import { FILTER_OPTIONS } from './filterOptions';

export const PRODUCTS_MENU = [
  {
    label: 'Categories',
    id: FILTER_OPTIONS.CATEGORY,
    actions: [],
  },
  { label: 'Space', id: FILTER_OPTIONS.SPACE, actions: [] },
  {
    label: 'Material',
    id: FILTER_OPTIONS.MATERIAL,
    actions: [],
  },
  {
    label: 'Colour',
    id: FILTER_OPTIONS.COLOUR,
    actions: [],
  },
];

export const PRODUCTS_MENU_WITH_SUBCATEGORY = [
  {
    label: 'Sub Categories',
    id: FILTER_OPTIONS.SUB_CATEGORY,
    actions: [],
  },
  { label: 'Space', id: FILTER_OPTIONS.SPACE, actions: [] },
  {
    label: 'Material',
    id: FILTER_OPTIONS.MATERIAL,
    actions: [],
  },
  {
    label: 'Colour',
    id: FILTER_OPTIONS.COLOUR,
    actions: [],
  },
];

export const PRODUCTS_MENU_SPACES = [
  {
    label: 'Categories',
    id: FILTER_OPTIONS.CATEGORY,
    actions: [],
  },
  {
    label: 'Material',
    id: FILTER_OPTIONS.MATERIAL,
    actions: [],
  },
  {
    label: 'Colour',
    id: FILTER_OPTIONS.COLOUR,
    actions: [],
  },
];
