const img =
  'https://images.unsplash.com/photo-1598300056393-4aac492f4344?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1434&q=80';

export const mockData = Array(12).fill({
  name: 'Dining Chair',
  designer_name: 'Harry Batcherlor',
  product_url: '/some-url',
  image: { fileSrc: img, fileType: 'image' },
  product_uuid: '123',
});

export const mockCategories = [
  {
    name: 'Furniture',
    slug: 'furniture',
    has_nested: true,
  },
  {
    name: 'Lighting',
    slug: 'lighting',
    has_nested: true,
  },
  {
    name: 'Decor and Home Accessories',
    slug: 'decor-and-home-accessories',
    has_nested: true,
  },
  {
    name: 'Cook and Dine',
    slug: 'cook-and-dine',
    has_nested: true,
  },
  {
    name: 'Lifestyle Products',
    slug: 'lifestyle-products',
    has_nested: true,
  },
  {
    name: 'Pattern and Illustration',
    slug: 'pattern-and-illustration',
    has_nested: true,
  },
];

export const mockSpace = [
  {
    name: 'Bathroom',
    slug: 'bathroom',
    has_nested: false,
  },
  {
    name: 'Bedroom',
    slug: 'bedroom',
    has_nested: false,
  },
  {
    name: 'Dining',
    slug: 'dining',
    has_nested: false,
  },
  {
    name: 'Hallway',
    slug: 'hallway',
    has_nested: false,
  },
  {
    name: 'Kids',
    slug: 'kids',
    has_nested: false,
  },
  {
    name: 'Kitchen',
    slug: 'kitchen',
    has_nested: false,
  },
  {
    name: 'Living',
    slug: 'living',
    has_nested: false,
  },
  {
    name: 'Office',
    slug: 'office',
    has_nested: false,
  },
  {
    name: 'Laundry & Utility',
    slug: 'laundry-utility',
    has_nested: false,
  },
  {
    name: 'Outdoor',
    slug: 'outdoor',
    has_nested: false,
  },
];
