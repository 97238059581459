import { styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const getBgColour = (theme, colour) => {
  const bgColours = {
    black: theme.palette.common.black,
    white: theme.palette.common.white,
    gray: theme.palette.background.lightgray,
  };

  return bgColours[colour] || theme.palette.background.gray;
};

export const PillTagStyled = styled('div')(({ theme, colour, outlined }) => ({
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '100px',
  backgroundColor: outlined ? 'transparent' : getBgColour(theme, colour),
  border: outlined ? '1px solid' : 'none',
  padding: `5px ${theme.spacing(2)}`,
  whiteSpace: 'nowrap',
  '&:hover': {
    backgroundColor: colour === 'black' ? theme.palette.grey[100] : theme.palette.grey[300],
    '& p': {
      color: colour === 'black' ? theme.palette.text.primary : '',
    },
  },
}));

export const PillTagText = styled(Typography)(({ theme, colour }) => ({
  color: colour === 'black' ? theme.palette.text.white : '',
  fontSize: '14px',
}));

export const PillTagCloseStyled = styled(CloseIcon)(({ theme, colour }) => ({
  position: 'relative',
  left: '6px',
  cursor: 'pointer',
  color: colour === 'black' ? theme.palette.text.white : '',
  transition: 'all 150ms ease',
  borderRadius: 50,
  padding: 1,
  '&:hover': {
    backgroundColor: colour === 'black' ? theme.palette.grey[100] : theme.palette.grey[300],
    color: colour === 'black' ? theme.palette.text.primary : '',
  },
  '&:active': {
    transform: 'scale(0.8)',
    backgroundColor: colour === 'black' ? theme.palette.grey[300] : theme.palette.grey[300],
  },
}));
