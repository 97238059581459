import React from 'react';

import { CustomAccordionBodyStyled } from './CustomAccordionBody.styles';

const CustomAccordionBody = ({ children }) => {
  return <CustomAccordionBodyStyled>{children}</CustomAccordionBodyStyled>;
};

CustomAccordionBody.propTypes = {};

export default CustomAccordionBody;
