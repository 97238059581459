import { Box, styled } from '@mui/material';

export const StyledModalHelpActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const ModalLoading = styled(Box)(({ theme }) => ({
  minHeight: '200px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
