import { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import Spinner from 'components/Spinner/Spinner';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import PreviewTermsModal from 'components/PreviewTermsModal/PreviewTermsModal';

import { routes } from 'options/routes';

import { useMessageContext } from 'context/MessageContext';

import { TableStyled } from './ExistingTermsList.styles';
import { VisibilityIconWrapper } from 'components/Table/Table.styles';

import { EXISTING_TERMS_LIST_VARIANTS } from './ExistingTermsList.data';

import { useTableFilter } from 'hooks/useTableFilter';

import { deleteSingleTerm, getSingleTerm, getTermsList } from 'api';

const ExistingTermsList = ({ variant, formAction, isStory, mockData, mockTotal, headings, selected_terms_uuid }) => {
  const navigate = useNavigate();
  const { order, orderBy, p, l, parsedFilter, onPageChange, onRequestSort, onRowsPerPageChange } = useTableFilter({
    initialOrder: 'asc',
    initialOrderBy: 'title',
  });
  const [setMessage] = useMessageContext();
  const [previewTermsData, setPreviewTermsData] = useState(null);
  const [previewTermsModalOpen, setPreviewTermsModalOpen] = useState(false);
  const [previewTermsLoading, setPreviewTermsLoading] = useState(false);

  const [selected, setSelected] = useState(selected_terms_uuid);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['existingTermsList', parsedFilter],
    () => getTermsList(parsedFilter),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
    }
  );

  const termsList = useMemo(() => (isStory ? mockData : data?.terms || []), [data?.terms, isStory, mockData]);
  const totalCount = useMemo(() => (isStory ? mockTotal : data?.total || 0), [data?.total, isStory, mockTotal]);

  const onEdit = useCallback(
    (terms_uuid) => {
      navigate(`${routes.termsAdd}/${terms_uuid}`);
    },
    [navigate]
  );

  const onDelete = useCallback(
    (terms_uuid) => {
      const deleteTerm = async () => {
        const res = await deleteSingleTerm(terms_uuid);
        if (res.success) {
          return refetch();
        }
        setMessage('There was an error deleting terms. Please try again.');
      };
      deleteTerm();
    },
    [refetch]
  );

  const onRowClick = useCallback(
    (terms_uuid) => {
      if (variant === EXISTING_TERMS_LIST_VARIANTS.FORM && formAction) {
        formAction(terms_uuid);
      }
      setSelected(terms_uuid);
    },
    [formAction, variant]
  );

  const onPreviewClick = useCallback(
    async (terms_uuid) => {
      handleTogglePreviewTermsModal();
      setPreviewTermsLoading(true);
      const res = await getSingleTerm(terms_uuid);
      if (res.success) {
        setPreviewTermsData(res.terms);
        setPreviewTermsLoading(false);
      }
    },
    [termsList]
  );

  const handleTogglePreviewTermsModal = () => {
    setPreviewTermsData(null);
    setPreviewTermsModalOpen((prev) => !prev);
  };

  const noResults = !isLoading && termsList.length === 0;

  return (
    <>
      <PreviewTermsModal
        loading={previewTermsLoading}
        handleClose={handleTogglePreviewTermsModal}
        open={previewTermsModalOpen}
        terms={previewTermsData}
      />
      <Box>
        {(isLoading || isRefetching) && !isStory ? (
          <Box mt={2}>
            <Spinner align="center" />
          </Box>
        ) : (
          <CustomTableWrapper
            noResultsTitle={noResults ? 'No terms found' : ''}
            noResultsDescription={noResults ? "You haven't added any terms yet. Let's get started!" : ''}
            btnText={noResults && variant === EXISTING_TERMS_LIST_VARIANTS.PAGE ? 'Add terms' : ''}
            onBtnClick={
              noResults && variant === EXISTING_TERMS_LIST_VARIANTS.PAGE ? () => navigate(routes.termsAdd) : () => {}
            }
          >
            <TableStyled>
              <CustomTableHeader
                columnHeadings={headings}
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
              />
              <CustomTableBody>
                {termsList?.map((item) => {
                  const { title, date_created, last_used, active_briefs, can_edit, terms_uuid } = item;
                  const isSelected = selected === terms_uuid;
                  const threeDotMenuActions = [
                    can_edit &&
                      variant === EXISTING_TERMS_LIST_VARIANTS.PAGE && {
                        title: 'Edit',
                        onClick: () => onEdit(terms_uuid),
                      },
                    !active_briefs &&
                      variant === EXISTING_TERMS_LIST_VARIANTS.PAGE && {
                        title: 'Delete',
                        onClick: () => onDelete(terms_uuid),
                      },
                  ].filter(Boolean);
                  return (
                    <CustomTableRow
                      key={`table_row_${terms_uuid}`}
                      onClick={() => onRowClick(terms_uuid)}
                      isSelected={isSelected}
                    >
                      {variant === EXISTING_TERMS_LIST_VARIANTS.FORM && (
                        <CustomTableBodyCell>
                          {isSelected ? (
                            <CheckBoxIcon fontSize="small" />
                          ) : (
                            <CheckBoxOutlineBlankIcon fontSize="small" />
                          )}
                        </CustomTableBodyCell>
                      )}
                      <CustomTableBodyCell>
                        <ItemInfo avatar={{}} label={title} />
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <TableBodyText text={date_created} />
                      </CustomTableBodyCell>

                      <CustomTableBodyCell>
                        <TableBodyText text={last_used || '--'} />
                      </CustomTableBodyCell>

                      {variant === EXISTING_TERMS_LIST_VARIANTS.PAGE && (
                        <CustomTableBodyCell>
                          <TableBodyText text={active_briefs || '0'} />
                        </CustomTableBodyCell>
                      )}
                      <CustomTableBodyCell>
                        <VisibilityIconWrapper>
                          <VisibilityIcon onClick={() => onPreviewClick(terms_uuid)} />
                        </VisibilityIconWrapper>
                      </CustomTableBodyCell>
                      <CustomTableBodyCell>
                        <Box>
                          <ThreeDotMenu actions={threeDotMenuActions} variant="vertical" />
                        </Box>
                      </CustomTableBodyCell>
                    </CustomTableRow>
                  );
                })}
              </CustomTableBody>
            </TableStyled>
            <Box>
              <CustomTablePagination
                count={totalCount}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
                rowsPerPage={l}
                rowsPerPageOptions={[5, 10, 15]}
                page={p}
              />
            </Box>
          </CustomTableWrapper>
        )}
      </Box>
    </>
  );
};

ExistingTermsList.propTypes = {};

export default ExistingTermsList;
