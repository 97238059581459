import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CustomMessageLoadingContainer,
  NoMessagesShownContainer,
  StyledHelpDashboardContainer,
  StyledHelpMessageContainer,
  StyledHelpTabListContainer,
} from './HelpDashboard.styles';
import { useQuery } from 'react-query';
import ModalHelpConversation from 'components/ModalHelpConversation/ModalHelpConversation';
import HelpTabList from 'components/HelpTabList/HelpTabList';
import Message from 'components/Message/Message';
import CustomMessageField from 'components/CustomMessageField/CustomMessageField';
import { Box } from '@mui/system';
import BodyText from 'components/Typography/BodyText/BodyText';
import theme from 'theme/theme';
import CaptionText from 'components/Typography/CaptionText/CaptionText';
import { userTypes } from 'options/userTypes';
import ChatPill from 'components/ChatPill/ChatPill';
import Spinner from 'components/Spinner/Spinner';
import { LoadingContainer } from 'components/ProductsMegaMenu/ProductsMegaMenu.styles';
import { transformToBase64List } from 'utils/convertToBase64';
import {
  createHelpConversation,
  deleteHelpConversation,
  deleteHelpConversationAttachedFile,
  downloadHelpConversationAttachedFile,
  editHelpConversation,
  getHelpConversationMessages,
  getHelpConversations,
  sendHelpConversationAttachedFile,
  sendHelpConversationMessage,
} from 'api';

const HelpDashboard = ({ isStory, mockTabData, mockLoading, mockMessages }) => {
  const [chatShownUUID, setChatShownUUID] = useState('');
  const [helpModal, setHelpModal] = useState({ open: false, chat_uuid: '', chat_title: '' });
  const [convoLoading, setConvoLoading] = useState(false);
  const [sendMessageLoading, setSendMessageLoading] = useState(false);
  const messageContainerRef = useRef(null);
  const customMessageFieldRef = useRef(null);
  const handleTitleInput = (e) => {
    setHelpModal((prev) => ({ ...prev, chat_title: e.target.value }));
  };

  const { data, isLoading, refetch } = useQuery(['helpDashboardConvos'], () => getHelpConversations(), {
    enabled: !isStory,
    staleTime: 5 * 60 * 1000,
    refetchOnMount: 'always',
  });

  const messagesQuery = useQuery(['messages', chatShownUUID], () => getHelpConversationMessages(chatShownUUID), {
    enabled: chatShownUUID !== '',
    staleTime: 5 * 60 * 1000,
    refetchOnMount: 'always',
  });

  const setChatShown = useCallback(
    (chat_uuid) => {
      setChatShownUUID(chat_uuid);
    },
    [chatShownUUID]
  );

  const closeHelpModal = useCallback(() => {
    setHelpModal({ open: false, chat_uuid: '', chat_title: '' });
  }, []);

  const handleCreateNewConvo = useCallback(async () => {
    const { chat_title } = helpModal;
    if (chat_title === '') return;
    setConvoLoading(true);
    await createHelpConversation({ title: chat_title });
    refetch();
    closeHelpModal();
    setConvoLoading(false);
  }, [helpModal.chat_title]);

  const handleEditExistingConvo = useCallback(async () => {
    const { chat_uuid, chat_title } = helpModal;
    if (chat_title === '') return;
    setConvoLoading(true);
    await editHelpConversation(chat_uuid, { title: chat_title });
    setConvoLoading(false);
    refetch();
    closeHelpModal();
  }, [helpModal.chat_title, helpModal.chat_uuid]);

  const handleNewConvo = useCallback(
    () =>
      setHelpModal({
        open: true,
        chat_uuid: '',
        chat_title: '',
      }),
    []
  );

  const handleEditConvo = useCallback((id, title) => {
    setHelpModal({
      open: true,
      chat_uuid: id,
      chat_title: title,
    });
  }, []);

  const handleSubmitMessage = useCallback(
    async (val) => {
      if (!val) return;
      setSendMessageLoading(true);
      await sendHelpConversationMessage(chatShownUUID, { body: val });
      await messagesQuery.refetch();
      setSendMessageLoading(false);
      await refetch();
    },
    [chatShownUUID, messagesQuery]
  );

  const handleAttachFile = useCallback(
    async (e) => {
      const _newFile = e.target.files;
      if (_newFile && _newFile.length > 0) {
        setSendMessageLoading(true);
        const base64Files = await transformToBase64List(_newFile);
        console.log(base64Files);
        await sendHelpConversationAttachedFile(chatShownUUID, { files: base64Files });
        await messagesQuery.refetch();
        setSendMessageLoading(false);
        await refetch();
      }
    },
    [chatShownUUID, messagesQuery]
  );

  const handleDeleteConvo = useCallback(
    async (chat_uuid) => {
      setConvoLoading(true);
      await deleteHelpConversation(chat_uuid);
      if (chatShownUUID === chat_uuid) setChatShownUUID('');
      setConvoLoading(false);
      refetch();
    },

    [chatShownUUID]
  );

  const handleDeleteFileClick = useCallback(
    async (file_uuid) => {
      await deleteHelpConversationAttachedFile(chatShownUUID, { files: [file_uuid] });
      await messagesQuery.refetch();
    },
    [chatShownUUID]
  );

  const handleDownloadFileClick = async (file_uuid) => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = 'Files.zip';
    const res = await downloadHelpConversationAttachedFile(chatShownUUID, { files: [file_uuid] });
    link.href = URL.createObjectURL(new Blob([res]), { type: res.type });
    link.click();
  };

  const convos = useMemo(() => (isStory ? mockTabData : data?.chats), [data, mockTabData, isStory]);

  const loading = useMemo(() => (isStory ? mockLoading : isLoading), [isLoading, mockLoading, isStory]);

  const messages = useMemo(
    () => (isStory ? mockMessages : messagesQuery?.data?.messages),
    [messagesQuery?.data?.messages, mockMessages, isStory]
  );

  const helpTabs = useMemo(
    () =>
      convos?.map((c) => {
        return {
          ...c,
          isSelected: chatShownUUID === c.chat_uuid,
          onClick: (chat_uuid) => setChatShown(chat_uuid),
          menuOptions: [
            {
              title: 'Edit',
              onClick: () => handleEditConvo(c.chat_uuid, c.title),
            },
            {
              title: 'Delete',
              onClick: () => handleDeleteConvo(c.chat_uuid),
            },
          ],
        };
      }),
    [chatShownUUID, convos]
  );

  useEffect(() => {
    if (messages && Object.entries(messages).length > 0 && messageContainerRef && chatShownUUID) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [chatShownUUID, messages]);

  return (
    <>
      {loading && (
        <LoadingContainer>
          <Spinner align={'center'} />
        </LoadingContainer>
      )}

      {!loading && (
        <>
          <StyledHelpDashboardContainer>
            <Box>
              <StyledHelpTabListContainer>
                <HelpTabList data={helpTabs} handleNewConvo={handleNewConvo} />
              </StyledHelpTabListContainer>
              {convoLoading && (
                <Box mt={2}>
                  <Spinner />
                </Box>
              )}
            </Box>
            {chatShownUUID && messagesQuery.isLoading && (
              <Box>
                <Spinner align={'center'} />
              </Box>
            )}
            {chatShownUUID && !messagesQuery.isLoading && messages && (
              <Box>
                <StyledHelpMessageContainer ref={messageContainerRef}>
                  {Object.entries(messages).length > 0 &&
                    Object.entries(messages).map(([key, value]) => {
                      return (
                        <Box display="flex" flexDirection="column" key={key}>
                          <Box display="flex" justifyContent="center" mb={1}>
                            <CaptionText text={key} />
                          </Box>
                          {value?.map(
                            ({ message_uuid, body, type, sender, created_time, file_extension, file_uuid, avatar }) => {
                              console.log(value);
                              const variant = sender !== userTypes.admin ? 'sender' : 'receiver';
                              return type === 'system' ? (
                                <ChatPill variant={variant} body={body} created={created_time} key={message_uuid} />
                              ) : (
                                <Message
                                  avatar={avatar}
                                  text={body}
                                  caption={created_time}
                                  variant={variant}
                                  key={message_uuid}
                                  file_extension={file_extension}
                                  file_uuid={file_uuid}
                                  handleDeleteFile={handleDeleteFileClick}
                                  handleFileDownload={handleDownloadFileClick}
                                />
                              );
                            }
                          )}
                        </Box>
                      );
                    })}
                </StyledHelpMessageContainer>
                <Box mt={1}>
                  <CustomMessageField
                    handleAttachFile={handleAttachFile}
                    onSubmit={handleSubmitMessage}
                    ref={customMessageFieldRef}
                  />
                </Box>
              </Box>
            )}
            {!chatShownUUID && (
              <NoMessagesShownContainer>
                <BodyText
                  colour={theme.palette.grey[600]}
                  text={convos ? 'Open an exisiting conversation or start a new one' : 'Start a new conversation'}
                />
              </NoMessagesShownContainer>
            )}
          </StyledHelpDashboardContainer>
          <ModalHelpConversation
            {...helpModal}
            handleClose={closeHelpModal}
            handleCreateNewConvo={handleCreateNewConvo}
            handleEditExistingConvo={handleEditExistingConvo}
            handleTitleInput={handleTitleInput}
            helpModalLoading={convoLoading}
          />
        </>
      )}
    </>
  );
};

HelpDashboard.propTypes = {};

export default HelpDashboard;
