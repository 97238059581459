import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ProductSingle from 'components/ProductSingle/ProductSingle';
import Spinner from 'components/Spinner/Spinner';
import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import BodyText from 'components/Typography/BodyText/BodyText';
import ModalAddMoodboardProduct from 'components/ModalAddMoodboardProduct/ModalAddMoodboardProduct';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';

import { routes } from 'options/routes';
import { userTypes } from 'options/userTypes';

import { parseRemoteProductProposalData } from 'utils/parseProductProposal';
import { isAnyBuyer } from 'utils/isAnyBuyer';

import useCustomMediaQueries from 'hooks/useCustomMediaQueries';

import { useMessageContext } from 'context/MessageContext';

import { getDesignerProduct, getProduct } from 'api';

const SingleProduct = ({ isStory, userType }) => {
  const [setMessage] = useMessageContext();
  const { product_uuid } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from;
  const fromTitle = location?.state?.fromTitle;
  const [openAddProductToMoodboard, setOpenAddProductToMoodboard] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ['product', product_uuid],
    () => (userType === userTypes.designer ? getDesignerProduct(product_uuid) : getProduct(product_uuid)),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
    }
    );

  const isProductFound = !!Object.keys(data?.product || {}).length;
  const productData = isProductFound ? parseRemoteProductProposalData(data?.product) : {};

  const {selected_agreement_type, agreement_type} = productData;

  const [agreementTypeSelected, setAgreementTypeSelected] = useState('');

  useEffect(() => {
    if(agreement_type) {
      setAgreementTypeSelected(agreement_type === 'both' ? 
      selected_agreement_type : agreement_type || selected_agreement_type)
    }
  },[agreement_type])

  const { isTablet } = useCustomMediaQueries();

  return (
    <PageWrapper userType={userType} showSecondaryFooter={false}>
      <PageContentWrapper breadcrumbsMargin>
        <Box mb={!isTablet ? 4 : 1}>
          <Breadcrumbs
            links={[
              {
                label: fromTitle ? fromTitle : 'Products',
                onClick: () =>
                  navigate(from ? from : userType !== userTypes.designer ? routes.products : routes.productsDashboard),
              },
            ]}
            currentPage={data?.product?.name}
          />
        </Box>

        <HeaderAndMenu
          title="Product"
          menuOptions={[
            {
              buttonTitle: 'Add to moodboard',
              icon: <BookmarkBorderIcon fontSize="small" />,
              onClick: () => setOpenAddProductToMoodboard(true),
              disabled: !isProductFound || userType === userTypes.designer,
            },
          ]}
        />
        {isLoading && (
          <Box mt={2}>
            <Spinner align="center" flex={0} />
          </Box>
        )}
        {isProductFound && (
          <Box mt={2}>
            <ProductSingle
              variant="product_view"
              data={productData}
              setMessage={setMessage}
              userType={userType}
              showRecommendedProducts={isAnyBuyer(userType)}
              refetch={refetch}
              agreementTypeSelected={agreementTypeSelected}
              setAgreementTypeSelected={setAgreementTypeSelected}
            />
          </Box>
        )}
        {!isLoading && !isProductFound && (
          <Box display="flex" justifyContent="center" mt={4}>
            <BodyText text="Product not found" />
          </Box>
        )}
      </PageContentWrapper>
      {openAddProductToMoodboard && (
        <ModalAddMoodboardProduct
          open={openAddProductToMoodboard}
          handleClose={() => setOpenAddProductToMoodboard(false)}
          product_uuid={product_uuid}
          setMessage={setMessage}
        />
      )}
    </PageWrapper>
  );
};

SingleProduct.propTypes = {};

export default SingleProduct;
