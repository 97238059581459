import React from 'react';
import PropTypes from 'prop-types';

import ProductPropertyListItem from 'components/ProductPropertyListItem/ProductPropertyListItem';

import { ListStyled } from './ProductPropertyListGroup.styles';

const ProductPropertyListGroup = ({ options, onClick, listIndex, fullWidth }) => {
  return (
    <ListStyled fullWidth={fullWidth} >
      {options.map((option) => (
        <ProductPropertyListItem {...option} onClick={onClick} listIndex={listIndex} key={option.value} />
      ))}
    </ListStyled>
  );
};

ProductPropertyListGroup.args = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number,
      onClick: PropTypes.func,
      has_nested: PropTypes.bool,
      selected: PropTypes.bool,
    })
  ),
};

export default ProductPropertyListGroup;
