import { styled, List } from '@mui/material';

export const ListStyled = styled(List, {
  shouldForwardProp: (props) => props !== 'fullWidth',
})(({ fullWidth, theme }) => ({
  marginRight: theme.spacing(2),
  width: fullWidth ? `100%` : `calc(25% - ${theme.spacing(2)})`,
  '&:last-child': {
    marginRight: 0,
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
