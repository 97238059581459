import React from 'react';
import PropTypes from 'prop-types';
import { DESIGNER_TAB_GROUP_OPTIONS } from 'options/tabGroupOptions';
import CustomTabGroup from 'components/CustomTabGroup/CustomTabGroup';

const DesignerDashboardMenu = ({ activeTab }) => {
  return <CustomTabGroup activeTab={activeTab} options={DESIGNER_TAB_GROUP_OPTIONS} />;
};

DesignerDashboardMenu.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default DesignerDashboardMenu;
