import { styled, Box } from '@mui/material';

export const StyledRadioWithTextFieldWrapper = styled(Box)(({ theme, modal }) => ({
  display: 'flex',
  flexDirection: modal ? 'column' : 'row',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledRadioWithTextFieldTextInputWrapper = styled(Box)(({ theme }) => ({
  width: '20%',
  [theme.breakpoints.down('md')]: {
    width: '40%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '60%',
  },
}));
