import { TablePagination, styled } from '@mui/material';

export const CustomTablePaginationStyled = styled(TablePagination)(({ theme }) => ({
  '& .MuiTablePagination-selectLabel': { fontSize: 12, fontFamily: 'InterRegular' },
  '& .MuiTablePagination-displayedRows': {
    fontSize: 12,
    color: theme.palette.primary.main,
    fontFamily: 'InterRegular',
  },
  '& .MuiInputBase-root .MuiTablePagination-select': { paddingLeft: 0 },
}));
