import React from 'react';

import { Grid } from '@mui/material';
import PricingOptionsTableSection from './PricingOptionsTableSection/PricingOptionsTableSection';

import { PricingOptionsTableData } from './PricingOptionsTable.data';

const PricingOptionsTable = ({ onClick, optionSelected }) => {
  return (
    <Grid display="grid" gridTemplateColumns={'repeat(auto-fit, minmax(200px , 1fr))'} gap="16px">
      {PricingOptionsTableData?.map((option) => (
        <PricingOptionsTableSection
          {...option}
          onClick={onClick}
          buttonColour={optionSelected === option.accountType ? 'primary' : 'secondary'}
          key={option.title}
        />
      ))}
    </Grid>
  );
};

export default PricingOptionsTable;
