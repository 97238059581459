import { TERM_TYPES } from 'options/briefOptions';
import { parseChoicesToArray } from 'utils/parseChoicesToArray';
import { parseDateToFullDateString } from 'utils/parseDates';
import { parseRemoteOptions } from 'utils/parseRemoteOptions';

export const parseFormData = (
  {
    main_image,
    registered,
    product_category,
    material_tag,
    space_tag,
    style_tag,
    colour_tag,
    sustainability_tag,
    reference_file,
    file_requirements,
    markets,
    projected_volume,
    deadline,

    designers,
    upfront_payment,

    payment_terms_agreement,
    options_on_design_agreement,
    reference_images_agreement,
    status, // dont use status from here

    ...rest
  },
  isLastStep
) => {
  return {
    main_image: main_image[0],
    registered: registered === 'yes',
    categories: parseChoicesToArray(product_category),
    materials: parseChoicesToArray(material_tag),
    spaces: parseChoicesToArray(space_tag),
    styles: parseChoicesToArray(style_tag),
    colours: parseChoicesToArray(colour_tag),
    sustainability_tags: parseChoicesToArray(sustainability_tag),
    reference_file: reference_file.length ? reference_file[0] : null,

    file_requirements: parseChoicesToArray(file_requirements),
    markets: parseChoicesToArray(markets),
    projected_volume: (projected_volume?.length && projected_volume[0]?.value) || '',
    deadline: deadline ? parseDateToFullDateString(deadline) : null,
    designers: designers?.map(({ uuid }) => uuid),
    upfront_payment: upfront_payment === 'yes',

    buyer_details_confirmed: true, // payment_terms_agreement && options_on_design_agreement && reference_images_agreement,
    designer_note: 'test',

    ...(isLastStep ? { status: 'active' } : {}),

    ...rest,
  };
};

// need to strip off UUID off image if it's a copied brief
const parseImage = (image, isCopy) => {
  if (isCopy) {
    // const { fileSrc, fileType, name } = image;
    return null;
  }
  return image;
};

export const parseRemoteData = (
  {
    main_image,
    reference_file,
    additional_images,
    registered,
    categories,
    materials,
    spaces,
    styles,
    colours,
    sustainability_tags,
    file_requirements,
    markets,

    deadline,

    projected_volume,
    projected_cost_price,
    projected_retail_price,

    agreement_body,
    agreement_file,
    agreement_title,

    royalty_percentage,
    royalty_review_period,

    upfront_payment,
    upfront_payment_amount,

    buyer_details_confirmed,

    term_type,
    selected_terms_uuid,

    brief_uuid,

    designers,

    title,

    ...rest
  },
  isCopy
) => {
  return {
    main_image: main_image && Object.keys(main_image).length && !isCopy ? [main_image] : [],
    reference_file: reference_file && Object.keys(reference_file).length && !isCopy ? [reference_file] : [],
    additional_images: additional_images && !isCopy ? additional_images : [],
    registered: registered ? 'yes' : 'no',

    product_category: parseRemoteOptions(categories) ?? [],
    material_tag: parseRemoteOptions(materials) ?? [],
    space_tag: parseRemoteOptions(spaces) ?? [],
    style_tag: parseRemoteOptions(styles) ?? [],
    colour_tag: parseRemoteOptions(colours) ?? [],
    sustainability_tag: parseRemoteOptions(sustainability_tags) ?? [],

    file_requirements: file_requirements?.map((fileReq) => ({ label: fileReq?.toUpperCase(), value: fileReq })),
    markets: markets?.map((market) => ({ label: market?.toUpperCase(), value: market })),
    projected_volume: [{ label: projected_volume, value: projected_volume }],

    projected_cost_price: projected_cost_price ? String(projected_cost_price) : '',
    projected_retail_price: projected_retail_price ? String(projected_retail_price) : '',

    royalty_percentage: royalty_percentage ? royalty_percentage : '',
    royalty_review_period: royalty_review_period ? royalty_review_period : '',

    upfront_payment: upfront_payment === true ? 'yes' : upfront_payment === false ? 'no' : undefined,
    upfront_payment_amount: upfront_payment_amount ? upfront_payment_amount : '',

    agreement_body: agreement_body ? agreement_body : '',
    agreement_file: agreement_file ? agreement_file : [],
    agreement_title: agreement_title ? agreement_title : '',

    term_type: selected_terms_uuid ? TERM_TYPES.existing_terms : TERM_TYPES.new_terms,

    payment_terms_agreement: buyer_details_confirmed ? buyer_details_confirmed : false,
    options_on_design_agreement: buyer_details_confirmed ? buyer_details_confirmed : false,
    reference_images_agreement: buyer_details_confirmed ? buyer_details_confirmed : false,

    designers: designers,

    designer_note: 'test',

    ...(isCopy ? {} : { brief_uuid }),
    title: isCopy ? '' : title,

    selected_terms_uuid: selected_terms_uuid ? selected_terms_uuid : '',

    deadline: deadline ? new Date(deadline) : null,

    ...rest,
  };
};
