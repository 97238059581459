import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import { StyledFeaturedProfileTileContainer } from './FeaturedProfileTile.styles';
import CustomImage from 'components/CustomImage/CustomImage';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import { EmptyBoxStyled } from 'components/EmptyImage/EmptyImage.styles';
import BodyText from 'components/Typography/BodyText/BodyText';

import theme from 'theme/theme';

const FeaturedProfileTile = ({
  containerStyles,
  isStory,
  imageUrl,
  fileType,
  label,
  subtitle,
  onClick,
  aspect1to1,
}) => {
  return (
    <StyledFeaturedProfileTileContainer onClick={onClick} style={containerStyles ? containerStyles : {}}>
      {imageUrl ? (
        <Box>
          <CustomImage
            aspect1to1={aspect1to1}
            objectFit={'cover'}
            imgSrc={imageUrl}
            fileType={isStory ? 'image' : fileType}
          />
        </Box>
      ) : (
        <EmptyBoxStyled />
      )}
      {label && (
        <Box mt={2}>
          <CustomTitle variant="h4" text={label} />
        </Box>
      )}
      {subtitle && <BodyText colour={theme.palette.grey[400]} text={subtitle} />}
    </StyledFeaturedProfileTileContainer>
  );
};

FeaturedProfileTile.propTypes = {
  onClick: PropTypes.func,
  containerStyles: PropTypes.object,
  imageUrl: PropTypes.string.isRequired,
  label: PropTypes.string,
  subtitle: PropTypes.string,
};

export default FeaturedProfileTile;
