import { getChildOptions } from 'api';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { useQueries } from 'react-query';

export const useProductOptionsQueries = () => {
  const optionQueries = useQueries({
    queries:
      [OPTIONS_TYPE.product, OPTIONS_TYPE.space, OPTIONS_TYPE.material, OPTIONS_TYPE.colour].map((option, i) => {
        return {
          queryKey: [`options`, option, 'top'],
          queryFn: () => getChildOptions(option, 'top'),
          enabled: true,
          staleTime: 5 * 60 * 1000,
          placeholderData: { options: [] },
        };
      }) ?? [],
  });

  const [categories, space, materials, colour] = optionQueries.map((query) => query?.data?.options);

  return {
    categories,
    space,
    materials,
    colour,
  };
};
