import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';

import ItemInfo from 'components/ItemInfo/ItemInfo';
import PillTag from 'components/PillTag/PillTag';
import Spinner from 'components/Spinner/Spinner';
import StatusPill from 'components/StatusPill/StatusPill';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';

import { DESIGNER_OPEN_CALLS_HEADINGS } from 'options/tableOptions';
import { PILL_STATUS_ICON, PILL_STATUS_MAP, DESIGNER_OPEN_CALL_PILL_STATUS_TEXT } from 'options/pillStatusOptions';
import { routes } from 'options/routes';

import { getUniqueList } from 'utils/getUniqueList';
import { addFilters } from 'utils/addFilters';

import { TableStyled } from 'components/Table/Table.styles';
import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';

import { useTableFilter } from 'hooks/useTableFilter';

import { getChildOptions, getDesignerOpenCallsList } from 'api';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { OPEN_CALL_STATUS_DESIGNER } from 'options/briefStatus';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';

const DesignerOpenCallsList = ({ isStory, mockData, mockTotal }) => {
  const { order, orderBy, filter, setFilter, p, l, parsedFilter, onPageChange, onRequestSort, onRowsPerPageChange } =
    useTableFilter({ initialOrder: 'desc', initialOrderBy: 'brief_name' });

  const navigate = useNavigate();

  const { data, isLoading } = useQuery(
    ['designerOpenCallsList', parsedFilter],
    () => getDesignerOpenCallsList(parsedFilter),
    {
      enabled: !isStory,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const briefList = useMemo(() => (isStory ? mockData : data?.open_calls || []), [data?.open_calls, isStory, mockData]);
  const totalCount = useMemo(() => (isStory ? mockTotal : data?.total || []), [data?.total, isStory, mockTotal]);

  const {
    data: { options: categories },
  } = useQuery(['options', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const filtersList = useMemo(
    () => ({
      categories: categories,
      statuses: [...Object.values(OPEN_CALL_STATUS_DESIGNER)],
    }),
    [categories]
  );

  const columnHeadings = useMemo(
    () =>
      DESIGNER_OPEN_CALLS_HEADINGS.map((item) =>
        !item.sortable
          ? {
              ...item,
              menuOptions: addFilters(item, filtersList, setFilter, filter, DESIGNER_OPEN_CALL_PILL_STATUS_TEXT),
            }
          : item
      ),
    [filtersList, setFilter, filter]
  );

  const handleNavigateToBrief = useCallback(
    (brief_uuid) => {
      navigate(`${routes.openCallsDashboard}/${brief_uuid}/open-call`);
    },
    [navigate]
  );
  const hasFilter = Object.keys(filter).length;
  const noBrief = !briefList || briefList.length === 0;
  const showNoBriefsYetMessage = !hasFilter && noBrief;

  return (
    <>
      {isLoading && !isStory ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <CustomTableWrapper
          noResultsTitle={showNoBriefsYetMessage ? 'No open calls found' : ''}
          noResultsDescription={showNoBriefsYetMessage ? "You haven't got any open calls yet" : ''}
        >
          <TableStyled>
            <CustomTableHeader
              columnHeadings={columnHeadings}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <CustomTableBody>
              {!briefList?.length && <NoFilteringMatch item="open calls" cols={DESIGNER_OPEN_CALLS_HEADINGS.length} />}
              {briefList?.map((brief) => {
                const { company_name, company_logo, categories, deadline, status, title, brief_uuid, views } = brief;
                const menuActions = [{ title: 'View Open Call', onClick: () => handleNavigateToBrief(brief_uuid) }];
                return (
                  <CustomTableRow key={`${title}-uuid_${brief_uuid}`} onClick={() => handleNavigateToBrief(brief_uuid)}>
                    {/* Name cell */}
                    <CustomTableBodyCell>
                      <Box cursor="pointer">
                        <ItemInfo label={title} avatar={{}} />
                      </Box>
                    </CustomTableBodyCell>
                    {/* Company cell */}
                    <CustomTableBodyCell>
                      <ItemInfo label={company_name} avatar={company_logo} />
                    </CustomTableBodyCell>
                    {/* Category cell */}
                    <CustomTableBodyCell>
                      <PillsWrapperStyled>
                        {getUniqueList(categories, 'slug')?.map((category, i) => {
                          const { name, slug } = category;
                          return (
                            <Box mr={1} mb={1} key={`pill_${name}_${i}`}>
                              <PillTag option={{ label: name, value: slug }} />
                            </Box>
                          );
                        })}
                      </PillsWrapperStyled>
                    </CustomTableBodyCell>
                    {/* Deadline cell */}
                    <CustomTableBodyCell>
                      <TableBodyText text={deadline} />
                    </CustomTableBodyCell>
                    {/* Views cell */}
                    <CustomTableBodyCell>
                      <TableBodyText text={views ? views : '0'} />
                    </CustomTableBodyCell>
                    {/* Status cell */}
                    <CustomTableBodyCell>
                      <StatusPill
                        label={DESIGNER_OPEN_CALL_PILL_STATUS_TEXT[status]}
                        status={PILL_STATUS_MAP[status]}
                        icon={PILL_STATUS_ICON[status]}
                      />
                    </CustomTableBodyCell>
                    {/* Menu cell */}
                    <CustomTableBodyCell>
                      <Box>
                        <ThreeDotMenu actions={menuActions} variant="vertical" />
                      </Box>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </TableStyled>
          {!!briefList?.length && (
            <CustomTablePagination
              count={totalCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPage={l}
              rowsPerPageOptions={[5, 10, 15]}
              page={p}
            />
          )}
        </CustomTableWrapper>
      )}
    </>
  );
};

DesignerOpenCallsList.propTypes = {
  isStory: PropTypes.bool,
};

export default DesignerOpenCallsList;
