import { array, object, string } from 'yup';
import { validation } from 'utils/validation';

const {
  personalFields: { first_name, last_name },
  accountFields: { email },
} = validation;

export const fieldsSchema = object().shape({
  fields: array()
    .of(
      object().shape({
        first_name,
        last_name,
        email,
        uuid: string(),
      })
    )
    .test('Test buyer admin fields', 'Check if fields are all server values', (value) => {
      if (value.every((v) => v.uuid)) return false;
      return true;
    }),
});

export const defaultFormValues = [
  {
    first_name: '',
    last_name: '',
    email: '',
    uuid: '',
  },
];

// used for stories
export const savedBuyers = [
  { first_name: 'John', last_name: 'Doe', email: 'john@mail.com', uuid: '1123-123123', has_moodboard_access: true },
  { first_name: 'Sam', last_name: 'Smith', email: 'sam@mail.com', uuid: '1123-123124', has_moodboard_access: false },
  { first_name: 'Theo', last_name: 'Foo', email: 'theo@mail.com', uuid: '1123-123125', has_moodboard_access: true },
  { first_name: 'James', last_name: 'Baz', email: 'theo@mail.com', uuid: '1123-123126', has_moodboard_access: false },
];
