import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import NewBriefTermsFormWithController from 'components/Forms/NewBriefTermsFormWithController/NewBriefTermsFormWithController';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import BuyerAgreementForm from 'components/Forms/BuyerAgreementForm/BuyerAgreementForm';
import Spinner from 'components/Spinner/Spinner';

import { TERM_TYPES } from 'options/briefOptions';
import { CURRENCY_MAP } from 'options/currency';

import { validation, yupObject } from 'utils/validation';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

const { term_type, agreement_title, agreement_body, agreement_file, selected_terms_uuid } =
  validation.newBriefTermsAndLicensingFields;

const validationObj = {
  term_type,
  agreement_title,
  agreement_body,
  agreement_file,
  selected_terms_uuid,
  ...validation.agreementReviewCommon,
};

const validationSchema = yupObject().shape(validationObj, ['agreement_file', 'agreement_body']);

const BuyerTermsForm = ({ agreement_type, onCancel, onSubmit, currency, price }) => {
  const [isLoading, setIsLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);

  const methods = useForm({
    defaultValues: {
      term_type: TERM_TYPES.new_terms,
      agreement_title: '',
      agreement_description: '',
      agreement_file: [],

      // licensing
      negotiated_agreement_type: agreement_type,
      // royalty
      negotiated_royalty_percentage: '',
      negotiated_royalty_review_period: '',
      // one_off
      negotiated_one_off_budget: price ? String(price) : '',
      // common to both
      negotiated_payment_terms: '',
    },
    resolver,
  });

  const onHandleSubmit = async (data) => {
    setIsLoading(true);
    await onSubmit(data);
    setIsLoading(false);
  };

  const selectedCurrency = CURRENCY_MAP[currency] || '£';

  return isLoading ? (
    <Box my={2}>
      <Spinner align="center" />
    </Box>
  ) : (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onHandleSubmit)}>
        <Box>
          <NewBriefTermsFormWithController variant="modal" />
        </Box>
        <Box>
          <BuyerAgreementForm agreement_type={agreement_type} currency={selectedCurrency} />
        </Box>
        <Box display="flex" justifyContent="space-between" mt={4}>
          <Box width="48%">
            <CustomButton text="Cancel" onClick={onCancel} variant="outlined" fullWidth />
          </Box>
          <Box width="48%">
            <CustomButton text="Submit" buttonType="submit" fullWidth />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};

BuyerTermsForm.propTypes = {};

export default BuyerTermsForm;
