import React from 'react';
import PropTypes from 'prop-types';

import { Box, FormControl } from '@mui/material';
import CustomRadioFieldStandard from '../CustomRadioFieldStandard/CustomRadioFieldStandard';
import { ErrorMessage } from '../TextFields/CustomTextField/CustomTextField.styles';
import { RadioGroupStyled } from './CustomRadioStandardGroup.styles';

const CustomRadioStandardGroup = ({
  value,
  onChange,
  onBlur,
  options,
  labelPlacement,
  showBorder,
  direction,
  error,
  removeError,
}) => {
  const isRow = direction === 'row';
  return (
    <>
      <FormControl sx={{ marginTop: 0 }}>
        <RadioGroupStyled
          name="controlled-radio-buttons-group"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          row={isRow}
        >
          {options?.map((option, i) => (
            <CustomRadioFieldStandard
              {...option}
              labelPlacement={labelPlacement}
              showBorder={showBorder}
              disabled={option.disabled}
              key={`${option.label}_${i}`}
            />
          ))}
        </RadioGroupStyled>
        {!removeError && (
          <Box height="20px">{error && <ErrorMessage className="controlled-radio-error">{error}</ErrorMessage>}</Box>
        )}
      </FormControl>
    </>
  );
};

CustomRadioStandardGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })
  ),
  labelPlacement: PropTypes.oneOf(['start', 'end']),
  direction: PropTypes.oneOf(['row', 'column']),
  disabled: PropTypes.bool,
};

export default CustomRadioStandardGroup;
