export const PRODUCT_STATUS = {
  DRAFT: 'draft',
  APPROVED: 'approved',
  // PENDING_APPROVAL: 'pending_approval',
  NEGOTIATING: 'negotiating',
  REJECTED: 'rejected',
  SOLD: 'sold',
};

export const DESIGNER_PITCH_STATUS = {
  DRAFT: 'draft',
  // APPROVED: 'approved',
  // PENDING_APPROVAL: 'pending_approval',
  CONVERTED: 'converted',
  CAN_CONVERT: 'can_convert',
  NEGOTIATING: 'negotiating',
  // REJECTED: 'rejected',
  SOLD: 'sold',
};
