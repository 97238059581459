import React, { useCallback, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FileUploads from 'components/FileUploads/FileUploads';
import Spinner from 'components/Spinner/Spinner';

import { routes } from 'options/routes';

import { getIsFilesTabDisabled } from '../BuyerOpenCallsSingle.utils';

import { downloadBuyerOpenCallsSingleFiles, getBuyerOpenCallsSingleFiles } from 'api';

const BuyerOpenCallsFilesTab = () => {
  const navigate = useNavigate();
  const { brief_uuid, brief_designer_uuid } = useParams();
  const [briefData, setMessage, userType] = useOutletContext();

  const { project_status, proposal_status, agreement_status, status } = briefData;

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['buyersOpenCallsDesignFiles'],
    () => getBuyerOpenCallsSingleFiles(brief_uuid, brief_designer_uuid),
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  useEffect(() => {
    const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status, status);

    if (isFilesTabDisabled) {
      navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/open-calls`);
    }
  }, [agreement_status, brief_designer_uuid, brief_uuid, navigate, project_status, proposal_status, status]);

  const design_files = data?.design_files || {};

  const handleDownloadFiles = useCallback(
    (data) => downloadBuyerOpenCallsSingleFiles(brief_uuid, brief_designer_uuid, data, true),
    [brief_designer_uuid, brief_uuid]
  );

  const loading = isLoading || isRefetching;
  return (
    <Box>
      <Box mt={2}>
        {loading ? (
          <Box>
            <Spinner align="center" />
          </Box>
        ) : (
          <FileUploads
            files={design_files}
            setMessage={setMessage}
            refetch={refetch}
            userType={userType}
            downloadFiles={handleDownloadFiles}
          />
        )}
      </Box>
    </Box>
  );
};

BuyerOpenCallsFilesTab.propTypes = {};

export default BuyerOpenCallsFilesTab;
