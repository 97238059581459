import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FormStepper from 'components/FormStepper/FormStepper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';

import { routes } from 'options/routes';
import { BRIEF_STATUS_BUYER } from 'options/briefStatus';

import { getErrorMessage } from 'utils/getErrorMessage';
import { parseFormData, parseRemoteData } from './BuyerBriefNew.utils';

import {
  formDefaultValues,
  steps,
  stepsEditingActive,
  stepsEditting,
  validationList,
  validationListEditingActive,
  validationListEditting,
} from './BuyerBriefNew.data';

import { BriefsEditingActiveSteps, BuyerBriefNewSteps, BuyerBriefNewStepsEditting } from './BuyerBriefNewSteps';

import { createBuyersBrief, createTerms, getBuyersBrief, putEditActiveBrief, updateBuyersBrief } from 'api';

const BuyerBriefNew = ({ userType }) => {
  const [isFetching, setIsFetching] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const [apiFormData, setApiFormData] = useState({});
  const [isEditingActive, setIsEditingActive] = useState(false);
  const location = useLocation();
  const designers = location?.state?.designers;

  const formSettings = useMemo(() => {
    if (id && isEditingActive) {
      return {
        steps: stepsEditingActive,
        formValidation: validationListEditingActive,
        stepsSwitcher: BriefsEditingActiveSteps,
        saveAndExitSteps: [],
        saveAndExitRequiredFields: [],
      };
    }
    if (id) {
      return {
        steps: stepsEditting,
        formValidation: validationListEditting,
        stepsSwitcher: BuyerBriefNewStepsEditting,
        saveAndExitSteps: [2, 3, 4],
        saveAndExitRequiredFields: [null, ['title'], ['title'], ['title'], ['title']],
      };
    }
    return {
      steps: steps,
      formValidation: validationList,
      stepsSwitcher: BuyerBriefNewSteps,
      saveAndExitSteps: [3, 4, 5],
      saveAndExitRequiredFields: [null, null, ['title'], ['title'], ['title'], ['title']],
    };
  }, [id, isEditingActive]);

  const nextStepAction = async (values, step, isSaveAndExit) => {
    const currentStepsLen = formSettings?.steps.length;
    const isLastStep = currentStepsLen - 1 === step;
    const parsedFormData = parseFormData(values, isLastStep && !isSaveAndExit);

    const lastStepAction = () => {
      queryClient.invalidateQueries({ queryKey: ['buyerBriefList'] });
      navigate(routes.briefThanks, { state: { isEditing: !!id } });
    };

    const lastStepActionSaveAndExit = () => {
      queryClient.invalidateQueries({ queryKey: ['buyerBriefList'] });
      navigate(routes.briefsDashboard);
    };

    if (id && isEditingActive) {
      // don't save (make API call) until is last step
      if (!isLastStep) {
        return;
      }
      const { brief_uuid } = parsedFormData;
      // change endpoint
      const resBrief = await putEditActiveBrief(brief_uuid, parsedFormData);

      if (!resBrief.success) {
        throw new Error(resBrief?.data?.error_message || 'There was an error submitting the brief');
      }

      return lastStepAction();
    }

    if ((!id && step === 4) || (id && step === 3)) {
      const { selected_terms_uuid, agreement_file, agreement_title, agreement_body } = parsedFormData;
      const termsData = { file: agreement_file[0], title: agreement_title, body: agreement_body };

      let new_selected_terms_uuid;

      if (!selected_terms_uuid) {
        const resTerms = await createTerms(termsData);

        if (!resTerms.success) {
          throw new Error(getErrorMessage(resTerms));
        }

        const { terms_uuid } = resTerms.terms;

        new_selected_terms_uuid = terms_uuid;
      }

      const { brief_uuid } = parsedFormData;

      const resBrief = await updateBuyersBrief(brief_uuid, {
        ...parsedFormData,
        selected_terms_uuid: new_selected_terms_uuid ? new_selected_terms_uuid : selected_terms_uuid,
      });

      if (!resBrief.success) {
        throw new Error(getErrorMessage(resBrief));
      }

      if (isSaveAndExit) {
        return lastStepActionSaveAndExit();
      }

      return setApiFormData(parseRemoteData({ ...resBrief.brief }));
    }

    if ((id && step >= 2) || step >= 3) {
      const { brief_uuid } = parsedFormData;

      const res = brief_uuid
        ? await updateBuyersBrief(brief_uuid, parsedFormData)
        : await createBuyersBrief(parsedFormData);

      if (!res.success) {
        throw new Error(getErrorMessage(res));
      }

      if (isSaveAndExit) {
        return lastStepActionSaveAndExit();
      }

      return isLastStep ? lastStepAction() : setApiFormData(parseRemoteData(res.brief));
    }
  };

  const onSaveAndExit = (values, activeStep) => {
    return nextStepAction(values, activeStep, true);
  };

  useEffect(() => {
    const fetchBriefDetails = async (id) => {
      // fetch API for id
      setIsFetching(true);
      const res = await getBuyersBrief(id);
      setIsFetching(false);
      if (res.success) {
        // if brief is active, redirect back to dashboard
        if (res?.brief?.status === BRIEF_STATUS_BUYER.ACTIVE) {
          // navigate(routes.briefsDashboard);
          setIsEditingActive(true);
        }
        return setApiFormData(parseRemoteData(res.brief));
      }
      // if no success, redirect
      return navigate(routes.briefsDashboard);
    };
    if (id) {
      fetchBriefDetails(id);
    }
  }, [id, navigate]);

  useEffect(() => {
    if (designers?.length > 0) {
      const newData = { ...formDefaultValues, designers };
      setApiFormData(newData);
    }
  }, [designers, navigate]);

  const { steps: formSteps, formValidation, stepsSwitcher, saveAndExitSteps, saveAndExitRequiredFields } = formSettings;

  return (
    <BuyerDashboardWrapper activeTab={routes.briefsDashboard}>
      <HeaderAndMenu
        title="Brief"
        menuOptions={[
          {
            buttonTitle: 'Back to briefs',
            icon: <ArrowBackIcon />,
            onClick: () => {
              navigate(routes.briefsDashboard);
            },
            animate: true,
          },
        ]}
      />
      <FormStepper
        steps={formSteps}
        defaultFormValues={formDefaultValues}
        formValidation={formValidation}
        stepsSwitcher={stepsSwitcher}
        nextStepAction={nextStepAction}
        onSaveAndExit={onSaveAndExit}
        saveAndExitSteps={saveAndExitSteps}
        saveAndExitRequiredFields={saveAndExitRequiredFields}
        apiFormData={apiFormData}
        userType={userType}
        stepsOptions={{ setIsFetching, isFetching }}
      />
    </BuyerDashboardWrapper>
  );
};

export default BuyerBriefNew;
