import { useNavigate } from 'react-router-dom';

import PageWrapper from '../../../../components/Wrappers/PageWrapper/PageWrapper';
import SignUpUserOption from '../../../../components/SignUp/SignUpUserOption/SignUpUserOption';
import CustomStandardLink from '../../../../components/CustomStandardLink/CustomStandardLink';
import { SectionInnerWrapper } from 'pages/Authentication/Login/Login.styles';

import { SignupLandingContainer, SignupOptionContainer } from './SignupLanding.styles';
import { routes } from '../../../../options/routes';

const SignupLanding = () => {
  const navigate = useNavigate();

  const onOptionClick = (option) => {
    navigate(option === 'designer' ? routes.signupDesigner : routes.signupBuyer);
  };

  return (
    <PageWrapper userType={'unauthenticated'} showSecondaryFooter>
      <SignupLandingContainer>
        <SectionInnerWrapper>
          <SignupOptionContainer>
            <SignUpUserOption
              title={'COMPANIES'}
              header={'Collaborate with product designers'}
              descriptionText={`We've curated a space that brands, retailers, and manufacturers want to browse. Meaning designers' work gets in front of trusted experts from all areas of the industry, and companies gain exclusive access to our international network of designers.`}
              buttonText={'Buyer sign up'}
              onClick={() => onOptionClick('buyer')}
              subText={
                <>
                  Already have an account?{' '}
                  <CustomStandardLink
                    text="Log In."
                    onClick={() => navigate(routes.login)}
                    linkType="primary"
                    colour="gray"
                  />
                </>
              }
            />
          </SignupOptionContainer>
          <SignupOptionContainer>
            <SignUpUserOption
              title={'DESIGNERS'}
              header={'Propose ideas directly to companies'}
              descriptionText={`We know networking is tough, and a lot of brilliant design just doesn't get seen. So we've created specific channels where buyers can brief designers, put out open calls, and receive pitches — and designers are guaranteed a response.`}
              buttonText={'Designer sign up'}
              onClick={() => onOptionClick('designer')}
              subText={
                <>
                  Already have an account?{' '}
                  <CustomStandardLink
                    text="Log In."
                    onClick={() => navigate(routes.login)}
                    linkType="primary"
                    colour="gray"
                  />
                </>
              }
              colour={'white'}
            />
          </SignupOptionContainer>
        </SectionInnerWrapper>
      </SignupLandingContainer>
    </PageWrapper>
  );
};

export default SignupLanding;
