import { styled } from '@mui/material';
import { MAX_WIDTH, MD_WIDTH, SM_WIDTH } from 'styles/constants';

export const PrimaryFooterContainer = styled('div')(({ theme, variant }) => ({
  height: '50px',
  boxSizing: 'border-box',
  backgroundColor: variant === 'primary' ? theme.palette.common.white : theme.palette.primary.main,
  borderTop:
    variant === 'primary' ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.common.white}`,
  padding: `${theme.spacing(1)} 0`,
  width: '100%',
}));

export const PrimaryFooterWrapper = styled('div')(({ theme, variant }) => ({
  margin: '0 auto',
  maxWidth: MAX_WIDTH,
  width: MD_WIDTH,
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    width: SM_WIDTH,
  },
}));
