import React from 'react';
import { CustomTableBodyCellStyled } from './CustomTableBodyCell.styles';

const CustomTableBodyCell = ({ children }) => {
  return <CustomTableBodyCellStyled>{children}</CustomTableBodyCellStyled>;
};

CustomTableBodyCell.propTypes = {};

export default CustomTableBodyCell;
