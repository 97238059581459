import { yupObject, validation } from 'utils/validation';

const firstStepValidation = yupObject({
  ...validation.designerAddProposalFields,
});

export const formValidation = [firstStepValidation, yupObject({})];

export const formDefaultValues = {
  //step 1
  product_uuid: '',
  name: '',
  main_image: [],
  additional_images: [],
  concept: '',
  construction: '',
  colour_material_finish: '',
  width: '',
  height: '',
  depth: '',
};

export const steps = ['Proposal Details', 'Summary'];
