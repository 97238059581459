import { Accordion, styled } from '@mui/material';

export const CustomAccordionWrapperStyled = styled(Accordion)(({ theme, index }) => ({
  '&::before': {
    all: 'unset',
  },
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.common.black}`,
  borderTop: index === 0 ? `1px solid ${theme.palette.common.black}` : '',
}));
