import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const CustomButton = ({
  text,
  onClick,
  colour = 'primary',
  variant = 'contained',
  buttonType = 'button',
  fullWidth,
  disabled = false,
  styleOverrides = {},
}) => {
  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={colour}
      type={buttonType}
      disableElevation
      fullWidth={fullWidth}
      disabled={disabled}
      sx={{ ...styleOverrides }}
    >
      {text}
    </Button>
  );
};

CustomButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  colour: PropTypes.oneOf(['primary', 'secondary', 'white', 'error', 'warning', 'success']),
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
  buttonType: PropTypes.oneOf(['button', 'submit']),
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CustomButton;
