import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import AddIcon from '@mui/icons-material/Add';

const Gravatar = ({ data, onClick, additional_designers }) => {
  return (
    <Box display="flex">
      {data?.map(({ fileSrc, fileType, uuid }, index) => (
        <Box
          mr={0.5}
          key={`${uuid}_${index}`}
          onClick={(e) => {
            if (onClick) {
              e.stopPropagation();
              onClick(uuid);
            }
          }}
        >
          <CustomAvatar imageUrl={fileSrc} fileType={fileType} />
        </Box>
      ))}
      {additional_designers && (
        <Box display="flex" justifyContent="center" alignItems="center">
          <AddIcon style={{ width: '20px', height: '20px' }} />
        </Box>
      )}
    </Box>
  );
};

Gravatar.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      fileType: PropTypes.oneOf(['image/jpg', 'url']),
      fileSrc: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default Gravatar;
