import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { routes } from 'options/routes';

import { PitchProposalSuccessContainer, StyledDescriptionText } from './PitchProposalSuccess.styles';

const PitchProposalSuccess = () => {
  const navigate = useNavigate();

  return (
    <PitchProposalSuccessContainer>
      <Box>
        <CustomTitle variant="h3" text="Thank you for your pitch proposal" />
        <Box my={2} display={'flex'}>
          <StyledDescriptionText variant={'body1'}>
            Your pitch has been sent to the selected company. Please wait for their response.
          </StyledDescriptionText>
        </Box>
        <Box my={2} display={'flex'}>
          <StyledDescriptionText variant={'body1'}>
            If you submitted your proposal from your existing product list, this will be temporarily removed from your profile. This cannot be undone unless your proposal is rejected. If rejected use the buttons at the bottom of your proposal images to upload them again to your profile or pitch directly to a new company.
          </StyledDescriptionText>
        </Box>
      </Box>
      <Box display="flex" mt={2}>
        <Box mr={2}>
          <CustomButton text="View all pitches" onClick={() => navigate(routes.pitchesDashboard)} colour="secondary" />
        </Box>
        <Box>
          <CustomButton text="Create new pitch" onClick={() => navigate(routes.pitchesAdd)} />
        </Box>
      </Box>
    </PitchProposalSuccessContainer>
  );
};

PitchProposalSuccess.propTypes = {};

export default PitchProposalSuccess;
