import React, { useEffect, useCallback } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FileUploads from 'components/FileUploads/FileUploads';
import Spinner from 'components/Spinner/Spinner';

import { getIsFilesTabDisabled } from '../BuyerPitchesSingle.utils';

import { routes } from 'options/routes';

import { downloadBuyersPitchFiles, getBuyersPitchFiles } from 'api';

const BuyerPitchesSingleFileTab = () => {
  const { pitch_uuid } = useParams();
  const [pitch, setMessage, userType] = useOutletContext();
  const navigate = useNavigate();

  const { agreement_status } = pitch;

  useEffect(() => {
    const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status);

    if (isFilesTabDisabled) {
      navigate(`${routes.pitchesDashboard}/${pitch_uuid}/pitch`);
    }
  }, [pitch_uuid, navigate, agreement_status]);

  const { data, isLoading, refetch } = useQuery(
    ['buyerProductFiles', pitch_uuid],
    () => getBuyersPitchFiles(pitch_uuid),

    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const files = data?.design_files || {};

  const handleDownloadFiles = useCallback(
    async (data) => downloadBuyersPitchFiles(pitch_uuid, data, true),
    [pitch_uuid]
  );

  return (
    <Box>
      {isLoading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <Box mt={2} width="100%">
          <FileUploads
            files={files}
            setMessage={setMessage}
            refetch={refetch}
            userType={userType}
            downloadFiles={handleDownloadFiles}
          />
        </Box>
      )}
    </Box>
  );
};

BuyerPitchesSingleFileTab.propTypes = {};

export default BuyerPitchesSingleFileTab;
