import PropTypes, { oneOfType } from 'prop-types';

import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import BuyerDashboardMenu from 'components/BuyerDashboardMenu/BuyerDashboardMenu';

import { StyledContainer, BreadcrumbsContainer } from '../DashboardWrapper.styles';

import { userTypes } from 'options/userTypes';

const BuyerDashboardWrapper = ({ activeTab, breadcrumbs, children, collapse }) => {
  return (
    <PageWrapper userType={userTypes.buyer} showSecondaryFooter={false} collapse={collapse}>
      <BuyerDashboardMenu activeTab={activeTab} />
      <StyledContainer collapse={collapse}>
        {breadcrumbs && <BreadcrumbsContainer>{breadcrumbs}</BreadcrumbsContainer>}
        {children}
      </StyledContainer>
    </PageWrapper>
  );
};

BuyerDashboardWrapper.propTypes = {
  activeTab: PropTypes.string,
  breadcrumbs: oneOfType([PropTypes.elementType, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default BuyerDashboardWrapper;
