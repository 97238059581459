import { Autocomplete, styled } from '@mui/material';

export const AutoCompleteStyled = styled(Autocomplete)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: 0,
  },
  '& .MuiAutocomplete-endAdornment': {
    margin: `0 ${theme.spacing(0.5)} `,
  },
}));
