import React from 'react';
import PropTypes from 'prop-types';
import { StyledNewConvoTabContainer } from './NewConvoTab.styles';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';

const NewConvoTab = ({ onClick }) => {
  return (
    <StyledNewConvoTabContainer onClick={() => onClick()}>
      <Box mr={1}>
        <CustomTitle variant="h6" text={'New convo'} />
      </Box>
      <CustomIconButton onClick={() => onClick()} icon={<AddIcon />} />
    </StyledNewConvoTabContainer>
  );
};

NewConvoTab.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default NewConvoTab;
