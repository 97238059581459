import React, { useCallback, useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router';

import { Box } from '@mui/material';
import AgreementAccordion from 'components/AgreementAccordion/AgreementAccordion';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';
import ModalProposalAgreementSigned from 'components/ModalProposalAgreementSigned/ModalProposalAgreementSigned';
import Spinner from 'components/Spinner/Spinner';

import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';

import { parseRemoteNegotiatedTerms } from './DesignerBriefAgreement.utils';
import { getIsAgreementTabDisabled } from '../DesignerSingleBrief.utils';
import { parseRemoteData as parseRemoteTermsData } from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/utils/BuyerTermsNew.utils';

import { acceptDesignerAgreement, getDesignerAgreement, getSingleTerm } from 'api';

const DesignerBriefAgreement = ({ isStory }) => {
  const queryClient = useQueryClient();
  const { brief_uuid } = useParams();
  const [briefData, setMessage, userType] = useOutletContext();
  const navigate = useNavigate();

  const {
    project_status,
    proposal_status,
    agreement_status,
    selected_terms_uuid: terms_uuid,
    currency,
    status,
  } = briefData;

  const [openAcceptAgreement, setOpenAcceptAgreement] = useState(false);
  const [openHasAcceptedAgreement, setOpenHasAcceptedAgreement] = useState(false);

  useEffect(() => {
    const isAgreementDisabled = getIsAgreementTabDisabled(project_status, proposal_status, status);

    if (isAgreementDisabled) {
      navigate(`${routes.briefsDashboard}/${brief_uuid}/brief`);
    }
  }, [brief_uuid, navigate, project_status, proposal_status, status]);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['briefProposalAgreement', brief_uuid],
    () => getDesignerAgreement(brief_uuid),
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const { data: termsData } = useQuery(
    ['designerSingleTerm', terms_uuid],
    () => (terms_uuid ? getSingleTerm(terms_uuid) : {}),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const parsedTermsData = (termsData?.terms && parseRemoteTermsData(termsData.terms)) || {};

  const nonParsedValues = data?.agreement || {};
  // use parsed negotiated terms in brief summary component
  const parsedAgreementData =
    (data && data?.agreement && !data?.agreement?.download_url && parseRemoteNegotiatedTerms(nonParsedValues)) || {};
  const { agreement_uuid } = parsedAgreementData;
  const download_url = data?.agreement?.download_url;
  const agreementError = !data?.data?.success && data?.data?.error_message;

  useEffect(() => {
    if (agreementError) {
      setMessage(agreementError);
    }
  }, [agreementError, setMessage]);

  const handleAcceptAgreement = useCallback(async () => {
    const res = await acceptDesignerAgreement(brief_uuid, agreement_uuid, data);
    if (!res.success) {
      return setMessage('There was an error while accepting the agreement. Please try again.');
    }
    setOpenAcceptAgreement(false);
    setOpenHasAcceptedAgreement(true);

    queryClient.refetchQueries(['designerSingleBrief', brief_uuid], { type: 'active', exact: true });
    refetch();
  }, [agreement_uuid, brief_uuid, data, queryClient, refetch, setMessage]);

  const onAcceptAgreement = useCallback(() => {
    setOpenAcceptAgreement(true);
  }, []);

  const loading = isLoading || isRefetching;

  return (
    <Box mt={2}>
      {loading ? (
        <Box>
          <Spinner align="center" />
        </Box>
      ) : (
        <AgreementAccordion
          briefDetails={{
            ...([AGREEMENT_STATUS.IN_REVIEW, AGREEMENT_STATUS.AWAITING_BUYER, AGREEMENT_STATUS.AWAITING_SIGN].includes(
              agreement_status
            )
              ? parsedAgreementData
              : {}),
            ...parsedTermsData,
            currency,
          }}
          project_status={project_status}
          agreement_status={agreement_status}
          userType={userTypes.designer}
          download_url={download_url}
          onAcceptAgreement={onAcceptAgreement}
          proposal_status={proposal_status}
        />
      )}
      {openAcceptAgreement && (
        <ModalConfirmAccept
          open={openAcceptAgreement}
          name="agreement"
          handleSubmit={handleAcceptAgreement}
          handleClose={() => setOpenAcceptAgreement(false)}
        />
      )}
      {openHasAcceptedAgreement && (
        <ModalProposalAgreementSigned
          open={openHasAcceptedAgreement}
          handleClose={() => setOpenHasAcceptedAgreement(false)}
          userType={userType}
        />
      )}
    </Box>
  );
};

DesignerBriefAgreement.propTypes = {};

export default DesignerBriefAgreement;
