import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { IconContainerStyled, LinkContainerStyled, LinkStyled } from './NavBarLink.styles';

const NavBarLink = ({ icon, iconOnClick, linkText, linkPath, active, openNewTab, external }) => {
  const navigate = useNavigate();
  const underline = active ? 'always' : 'hover';

  return (
    <LinkContainerStyled>
      <LinkStyled
        component="a"
        text={linkText}
        onClick={() => {
    
          if (openNewTab) {
            return window.open(linkPath, '_blank');
          }
          if (external) {
            window.location.href = linkPath;
            return;
          }
          navigate(linkPath);
        }}

        variant="body1"
        underline={underline}
        {... external ? {href: linkPath} : {}}
      >
        {linkText}
      </LinkStyled>
      {icon && <IconContainerStyled onClick={iconOnClick}>{icon}</IconContainerStyled>}
    </LinkContainerStyled>
  );
};

NavBarLink.propTypes = {
  icon: PropTypes.element,
  iconOnClick: PropTypes.func,
  linkText: PropTypes.string,
  linkPath: PropTypes.string,
  active: PropTypes.bool,
};

export default NavBarLink;
