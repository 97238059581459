import React, { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import ProductListPreview from 'components/ProductListPreview/ProductListPreview';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import ModalPreviewProduct from 'components/ModalPreviewProduct/ModalPreviewProduct';
import CustomCheckboxStandard from 'components/Inputs/CustomCheckboxStandard/CustomCheckboxStandard';
import BodyText from 'components/Typography/BodyText/BodyText';
import Spinner from 'components/Spinner/Spinner';

import { getErrorMessage } from 'utils/getErrorMessage';

import { StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';
import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';

import { useTableFilter } from 'hooks/useTableFilter';

import { createBriefProposalConversion, getDesignerMarketPlaceProducts } from 'api';

const ModalUploadProductToBrief = ({ open, onClose, brief_uuid, onSuccess }) => {
  const { parsedFilter, ...tableMethods } = useTableFilter({ initialOrder: 'desc', initialOrderBy: 'created' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState({ productListError: false, termsError: false });

  const [selectedProductPreview, setSelectedProductPreview] = useState('');
  const [itemsSelected, setItemsSelected] = useState([]);
  // const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

  const { data, isLoading, isRefetching } = useQuery(
    ['designerConvertableProductsList', parsedFilter],
    () => getDesignerMarketPlaceProducts(parsedFilter),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const handleCheckboxClick = useCallback((product, isSelected) => {
    setError((prevState) => ({ ...prevState, productListError: false }));
    const { product_uuid } = product;
    setItemsSelected(isSelected ? [] : [product_uuid]);
  }, []);

  const onPreviewClick = useCallback((uuid) => {
    setSelectedProductPreview(uuid);
  }, []);

  // const onAcceptTermsClick = useCallback(() => {
  //   setError((prevState) => ({ ...prevState, termsError: false }));
  //   setHasAcceptedTerms((prevState) => !prevState);
  // }, []);

  const onSubmitProduct = useCallback(async () => {
    const [product_uuid] = itemsSelected;
    if (product_uuid) {
      setIsSubmitting(true);
      const res = await createBriefProposalConversion(brief_uuid, { product_uuid });
      setIsSubmitting(false);
      if (!res.success) {
        throw new Error(getErrorMessage(res));
      }
      onSuccess();
    } else {
      if (!product_uuid) {
        setError((prevState) => ({ ...prevState, productListError: true }));
      }
      // if (!hasAcceptedTerms) {
      //   setError((prevState) => ({ ...prevState, termsError: true }));
      // }
    }
  }, [brief_uuid, itemsSelected, onSuccess]);

  const productsList = data?.products || [];
  const totalCount = data?.total || 0;
  const loading = isLoading || isRefetching || isSubmitting;

  return (
    <CustomModal title="Select product to use for proposal" open={open} handleClose={onClose} variant="secondary" wide>
      {loading ? (
        <Box my={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <Box>
          <Box>
            <ProductListPreview
              productsList={productsList}
              totalCount={totalCount}
              loading={loading}
              tableMethods={tableMethods}
              itemsSelected={itemsSelected}
              handleCheckboxClick={handleCheckboxClick}
              onPreviewClick={onPreviewClick}
            />
          </Box>
          <Box mt={0.5}>{error.productListError && <ErrorMessage>You must select a product.</ErrorMessage>}</Box>
          <Box my={2}>
            <BodyText text="Once submitted your proposal will be removed from your profile. This cannot be undone unless your proposal is rejected. If rejected upload to your profile, use the upload to profile button at the bottom of your images." />
            {/* <CustomCheckboxStandard
              label="Once submitted, this product will be removed from the marketplace. This action cannot be undone. Tick this box to confirm you accept this."
              labelPlacement="end"
              checked={hasAcceptedTerms}
              onChange={onAcceptTermsClick}
            /> */}
            <Box mt={0.5}>{error.termsError && <ErrorMessage>You must accept terms.</ErrorMessage>}</Box>
          </Box>
          <StyledActionButtonsContainer>
            <CustomButton text="Cancel" onClick={onClose} colour="secondary" />
            <CustomButton text="Submit" onClick={onSubmitProduct} colour="primary" />
          </StyledActionButtonsContainer>
        </Box>
      )}
      {!!selectedProductPreview && (
        <ModalPreviewProduct
          open={!!selectedProductPreview}
          handleClose={() => setSelectedProductPreview('')}
          product_uuid={selectedProductPreview}
        />
      )}
    </CustomModal>
  );
};

ModalUploadProductToBrief.propTypes = {};

export default ModalUploadProductToBrief;
