import React, { useCallback } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, ImageList } from '@mui/material';
import MoodboardProductImage from 'components/MoodboardProductImage/MoodboardProductImage';
import ModalRemoveMoodboardProduct from 'components/ModalRemoveMoodboardProduct/ModalRemoveMoodboardProduct';

import { useGalleryColumns } from 'hooks/useGalleryColumns';

const MoodboardProductList = ({
  moodboardProducts,
  handleOnHiddenClick,
  moodboard_uuid,
  isOwner,
  refetch,
  setMessage,
}) => {
  const { columns } = useGalleryColumns();

  const [product_uuid, setProductUUID] = useState('');
  const [openDeleteProduct, setOpenDeleteProduct] = useState(false);

  const handleCloseModal = useCallback(
    (shouldRefetch) => {
      setOpenDeleteProduct(false);
      if (shouldRefetch && refetch) {
        refetch();
      }
    },
    [refetch]
  );

  return (
    <Box>
      <ImageList cols={columns} variant="standard" gap={10}>
        {moodboardProducts?.map((item, i) => (
          <MoodboardProductImage
            productDetails={item}
            key={`${item.title}_${i}`}
            handleOnHiddenClick={handleOnHiddenClick}
            isOwner={isOwner}
            handleOnDeleteClick={(product_uuid) => {
              setProductUUID(product_uuid);
              setOpenDeleteProduct(true);
              return;
            }}
          />
        ))}
      </ImageList>
      {openDeleteProduct && (
        <ModalRemoveMoodboardProduct
          open={openDeleteProduct}
          handleClose={(shouldRefetch) => handleCloseModal(shouldRefetch)}
          moodboard_uuid={moodboard_uuid}
          product_uuid={product_uuid}
          setMessage={setMessage}
        />
      )}
    </Box>
  );
};

MoodboardProductList.propTypes = {};

export default MoodboardProductList;
