import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';

import { ErrorMessage, TextFieldStyled } from '../CustomTextField/CustomTextField.styles';
import { VisibilityIconStyled, VisibilityOffIconStyled } from './CustomPasswordField.styles';

const CustomPasswordField = ({ onChange, onBlur, value, success, error, id, label, fieldStyle = 'primary' }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const icon = showPassword ? <VisibilityOffIconStyled /> : <VisibilityIconStyled />;

  return (
    <div>
      <TextFieldStyled
        label={label}
        type={showPassword ? 'text' : 'password'}
        id={id}
        value={value}
        variant="outlined"
        margin="normal"
        onChange={onChange}
        onBlur={onBlur}
        color={success ? 'success' : 'primary'}
        error={!!error}
        fieldStyle={fieldStyle}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CustomIconButton icon={icon} onClick={handleShowPassword} />
            </InputAdornment>
          ),
        }}
      />
      {
        error && (
          <Box height="20px">
            <ErrorMessage variant="body1" colour="error.main">
              {error}
            </ErrorMessage>
        </Box>)
      }
    </div>
  );
};

CustomPasswordField.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string]).isRequired,
  id: PropTypes.string,
  success: PropTypes.bool,
  error: PropTypes.string,
  fieldStyle: PropTypes.oneOf(['primary', 'secondary']),
};

export default CustomPasswordField;
