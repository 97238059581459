import { Box, styled } from '@mui/material';

import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';

export const StyledFileUploadsContainer = styled(Box)(({ theme, isLast }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: 'max-content',
  border: `1px solid`,
  borderRadius: 5,
  padding: theme.spacing(2),
  paddingBottom: 0,
  marginBottom: !isLast ? theme.spacing(2) : 0,
  position: 'relative',
}));

export const StyledFilesWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
}));

export const StyledCaptionWrapper = styled(Box)(({ theme }) => ({
  alignSelf: 'flex-end',
  marginBottom: theme.spacing(2),
}));

export const FileThumbnailWrapper = styled(Box)(({ theme }) => ({
  width: '68px',
  [theme.breakpoints.down('sm')]: {
    width: '50%',
  },
}));

export const DownloadAllWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

export const DeleteIconStyled = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.error.light,
  cursor: 'pointer',
}));

export const DownloadIcon = styled(GetAppIcon)(() => ({
  cursor: 'pointer',
  marginTop: '2px',
}));
