import { Box, styled } from '@mui/material';

export const StyledDimensionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 'max-content',
  marginTop: 10,
}));

export const StyledDimensionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  '& > div ': {
    flex: 1,
    '& > .MuiFormControl-root': {
      marginTop: 0,
    },
  },
  '& > p': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: '10px',
  },
}));
