import { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import queryString from 'query-string';

import { Box } from '@mui/material';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import PillTag from 'components/PillTag/PillTag';
import ProductList from 'components/ProductList/ProductList';
import FormActionButton from 'components/Buttons/FormActionButton/FormActionButton';
import Spinner from 'components/Spinner/Spinner';

import { getSuggestedProducts } from 'api';
import BodyText from 'components/Typography/BodyText/BodyText';

const NewBriefSuggestedProducts = ({ categories, products, isStory }) => {
  const [productsInView, setProductsInView] = useState(1);
  const { getValues } = useFormContext();
  const { product_category, material_tag, colour_tag, space_tag, style_tag, sustainability_tag } = getValues();

  const filters = {
    categories: product_category.map(({ value }) => value),
    materials: material_tag.map(({ value }) => value),
    spaces: space_tag.map(({ value }) => value),
    colours: colour_tag.map(({ value }) => value),
    styles: style_tag.map(({ value }) => value),
    sustainability_tags: sustainability_tag.map(({ value }) => value),
  };

  const parsedFilter = queryString.stringify(filters);

  const { data, isLoading } = useQuery(['suggestedProducts', parsedFilter], () => getSuggestedProducts(parsedFilter), {
    enabled: !isStory,
    staleTime: 2 * 60 * 1000,
  });

  const handleViewMore = useCallback(() => {
    setProductsInView((prevState) => prevState + 1);
  }, []);

  const productsSuggested = data?.suggested || [];
  const productsShown = productsSuggested?.slice(0, 12 * productsInView);
  return (
    <Box>
      <CustomFieldSet
        label="Is this what you’re looking for?"
        description="We’ve searched BuyDesign to find ideas that match your requirements, you can find them here. If they’re not what you’re looking for continue to complete your brief"
      >
        {isLoading ? (
          <Spinner align="center" />
        ) : (
          <>
            <Box display="flex" flexWrap="wrap" mb={4}>
              {product_category?.map(({ label, value }) => (
                <Box key={`${value}_tag`} mr={2} mt={1}>
                  <PillTag option={{ label: label, value: value }} />
                </Box>
              ))}
            </Box>
            <Box>
              {productsShown?.length > 0 ? (
                <ProductList products={productsShown} isBrief />
              ) : (
                <BodyText text="There are no current products that meet the brief requirements." />
              )}
            </Box>
            {productsShown.length !== productsSuggested.length && (
              <Box display="flex" justifyContent="flex-end">
                <FormActionButton text="View More of These" onClick={handleViewMore} variant="text" />
              </Box>
            )}
          </>
        )}
      </CustomFieldSet>
    </Box>
  );
};

NewBriefSuggestedProducts.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      img: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      overlayOptions: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        menuOptions: PropTypes.arrayOf(
          PropTypes.shape({ title: PropTypes.string, onClick: PropTypes.func, icon: PropTypes.element })
        ),
        statusData: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, icon: PropTypes.element })),
        show: PropTypes.bool,
      }),
    })
  ),
};

export default NewBriefSuggestedProducts;
