import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FeaturedProfileTile from 'components/FeaturedProfileTile/FeaturedProfileTile';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

import { StyledSplideWrapper } from './ProductsCarousel.styles';

const ProductsCarousel = ({ height, data, variant = 3, title }) => {
  return data ? (
    <StyledSplideWrapper>
      {title && (
        <Box mb={2}>
          <CustomTitle text={title} variant="h2" />
        </Box>
      )}
      <Splide
        options={{
          pagination: false,
          perPage: variant,
          perMove: variant,
          gap: 16,
          breakpoints: {
            768: {
              perPage: 2,
              perMove: 2,
            },
            500: {
              perPage: 1,
              perMove: 1,
            },
          },
          height: height ? height : null,
        }}
        aria-label="Product slider"
      >
        {data?.map((tile, i) => {
          const { imageUrl, fileType, label, subtitle, isStory, onClick } = tile;
          return (
            <SplideSlide key={`${label}_${i}`}>
              <FeaturedProfileTile
                onClick={onClick}
                isStory={isStory}
                imageUrl={imageUrl}
                fileType={fileType}
                label={label}
                subtitle={subtitle}
                aspect1to1={true}
              />
            </SplideSlide>
          );
        })}
      </Splide>
    </StyledSplideWrapper>
  ) : null;
};

ProductsCarousel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      label: PropTypes.string,
      subtitle: PropTypes.string,
    })
  ),
  variant: PropTypes.oneOf([3, 4]),
};

export default ProductsCarousel;
