import React from 'react';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import ProductSingle from 'components/ProductSingle/ProductSingle';
import Spinner from 'components/Spinner/Spinner';

import { userTypes } from 'options/userTypes';

import { getDesignerProduct } from 'api';
import { parseRemoteProductProposalData } from 'utils/parseProductProposal';

const ModalPreviewProduct = ({ open, handleClose, product_uuid, isStory, mockData }) => {
  const { data, isLoading } = useQuery(['product', product_uuid], () => getDesignerProduct(product_uuid), {
    enabled: !isStory,
    staleTime: 2 * 60 * 1000,
  });

  const product = !isStory ? (data?.product && parseRemoteProductProposalData(data?.product)) || {} : mockData;
  const loading = isLoading && !isStory;
  return (
    <CustomModal open={open} handleClose={handleClose} title="Preview Product" variant="secondary">
      <Box my={2}>
        {loading ? (
          <Spinner align="center" />
        ) : (
          <ProductSingle data={product} userType={userTypes.designer} variant="product_view" />
        )}
      </Box>
    </CustomModal>
  );
};

ModalPreviewProduct.propTypes = {};

export default ModalPreviewProduct;
