import React from 'react';
import PropTypes from 'prop-types';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box, Checkbox } from '@mui/material';
import { FormLabelStyled } from './CustomCheckboxStandard.styles';
import { ErrorMessage } from '../TextFields/CustomTextField/CustomTextField.styles';

const CustomCheckboxStandard = ({ label, checked, labelPlacement, onChange, showBorder, error, showError }) => {
  return (
    <>
      <FormLabelStyled
        label={label}
        checked={checked}
        labelPlacement={labelPlacement}
        control={<Checkbox icon={<CheckBoxOutlineBlankIcon />} checkedIcon={<CheckBoxIcon />} size="small" />}
        onChange={onChange}
        showBorder={showBorder}
      />
      {showError && (
        <Box minHeight="20px">
          {error && (
            <ErrorMessage variant="body1" colour="error.main">
              {error}
            </ErrorMessage>
          )}
        </Box>
      )}
    </>
  );
};

CustomCheckboxStandard.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  labelPlacement: PropTypes.oneOf(['start', 'end']),
  onChange: PropTypes.func.isRequired,
  showBorder: PropTypes.bool,
};

export default CustomCheckboxStandard;
