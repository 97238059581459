import { keyframes } from '@mui/material';

export const pulsate = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(4px);
  }

  100% {
    transform: translateX(0px);
  }
`;
