import { capitalize } from './capitalize';

export const parseStringToBool = (input) => {
  if (input === 'true') return true;
  if (input === 'false') return false;
  else {
    console.error(`Error: parseStringToBool expects a string === "true" OR "false", instead got ${input}`);
    return undefined;
  }
};

export const cleanupTitle = (title) => {
  return capitalize(title.replace(/-/g, ' '));
};
