import { Box, styled } from '@mui/material';

export const StyledBuyerHomeFeaturedProdctsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const StyledContentContainer = styled(Box)(({ theme, onClick }) => ({
  flex: 1,
  cursor: onClick ? 'pointer' : '',
  '&:first-of-type': {
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    '&:first-of-type': {
      marginBottom: theme.spacing(2),
      paddingRight: theme.spacing(0),
    },
  },
}));
