import styled from '@emotion/styled';
import { AccordionSummary } from '@mui/material';

export const CustomAccordionHeadStyled = styled(AccordionSummary)(() => ({
  padding: '14px 0 14px 0',

  '& .MuiAccordionSummary-content': {
    margin: 0,
    paddingRight: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transition: 'unset',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'translateY(-35%)',
    transition: 'unset',
  },
}));
