export const designerProductListHeadings = [
  {
    id: 'name',
    label: 'Product Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'main_categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'created',
    label: 'Created',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'views',
    label: 'Views',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'visible',
    label: 'Visible',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'statuses',
    label: 'Status',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
];

export const designerPitchListHeadings = [
  {
    id: 'name',
    label: 'Product Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'company_name',
    label: 'Companies',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'main_categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'created',
    label: 'Created',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'views',
    label: 'Views',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'statuses',
    label: 'Status',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
];

export const buyerProductsListHeadings = [
  {
    id: 'name',
    label: 'Products',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'main_categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'date_sold',
    label: 'Purchased',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'price',
    label: 'Cost',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'application_fee',
    label: 'Application Fee',
    sortable: false,
    readOnly: true,
    menuOptions: [],
  },
  {
    id: 'statuses',
    label: 'Status',
    sortable: false,
    menuOptions: [],
  },
];

export const buyerPitchesListHeadings = [
  {
    id: 'name',
    label: 'Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'main_categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'date_sold',
    label: 'Purchased',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'price',
    label: 'Cost',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'statuses',
    label: 'Status',
    sortable: false,
    menuOptions: [],
  },
];

export const BUYER_BRIEFS_LIST_HEADINGS = [
  {
    id: 'brief_name',
    label: 'Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'designers',
    label: 'Designers',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'date_sent',
    label: 'Date Sent',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'deadline',
    label: 'Deadline',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'statuses',
    label: 'Status',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
];

export const designerBriefsListHeadings = [
  {
    id: 'brief_name',
    label: 'Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'company_name',
    label: 'Companies',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'date_sent',
    label: 'Date Received',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'deadline',
    label: 'Deadline',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'views',
    label: 'Views',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'statuses',
    label: 'Status',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
];

export const existingBriefsHeadings = [
  {
    id: 'checkbox_visual',
    label: '',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'brief_name',
    label: 'Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'main_categories',
    label: 'Category',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'date_sent',
    label: 'Date Sent',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'preview',
    label: 'Preview',
    sortable: false,
    menuOptions: [],
  },
];

export const existingTermsHeading = [
  {
    id: 'checkbox_visual',
    label: ' ',
    sortable: false,
  },
  {
    id: 'title',
    label: 'Name',
    sortable: true,
  },
  {
    id: 'created',
    label: 'Created',
    sortable: true,
  },
  {
    id: 'last_used',
    label: 'Last Used',
    sortable: true,
  },
  {
    id: 'preview',
    label: 'Preview',
    sortable: false,
    menuOptions: [],
  },
];

export const existingTermsHeadingPage = [
  {
    id: 'title',
    label: 'Name',
    sortable: true,
  },
  {
    id: 'created',
    label: 'Created',
    sortable: true,
  },
  {
    id: 'last_used',
    label: 'Last Used',
    sortable: true,
  },
  {
    id: 'active_briefs',
    label: 'Active',
    sortable: true,
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
];

export const newBriefDesignersList = [
  {
    id: 'name',
    label: 'Designer Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'location',
    label: 'Location',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'experience',
    label: 'Experience',
    sortable: true,
    menuOptions: [],
  },
];

export const proposalListHeadings = [
  {
    id: 'proposals',
    label: 'Proposal',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'sent',
    label: 'Sent',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'status',
    label: 'Status',
    sortable: false,
    menuOptions: [],
  },
];

export const DESIGNERS_LIST_HEADINGS = [
  {
    id: 'checkbox',
    label: 'Checkbox',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'name',
    label: 'Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'location',
    label: 'Location',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'experience',
    label: 'Experience',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'products',
    label: 'Products',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'briefs',
    label: 'Briefs',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
];

export const DESIGNER_OPEN_CALLS_HEADINGS = [
  {
    id: 'brief_name',
    label: 'Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'company_name',
    label: 'Companies',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'deadline',
    label: 'Deadline',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'views',
    label: 'Views',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'statuses',
    label: 'Status',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
];

export const BUYER_OPEN_CALLS_LIST_HEADINGS = [
  {
    id: 'brief_name',
    label: 'Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'designers',
    label: 'Designers',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'deadline',
    label: 'Deadline',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'visible',
    label: 'Visible',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'statuses',
    label: 'Status',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'invisible',
    label: '',
    sortable: false,
    menuOptions: [],
  },
];

export const BUYERS_LIST_HEADINGS = [
  {
    id: 'name',
    label: 'Company Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
];

export const BUYER_PRODUCT_HISTORY_HEADINGS = [
  {
    id: 'products',
    label: 'Products',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'cost',
    label: 'Cost',
    sortable: false,
    menuOptions: [],
    flexEnd: true,
  },
  {
    id: 'application_fee',
    label: 'Application Fee',
    sortable: false,
    menuOptions: [],
    flexEnd: true,
  },
];

export const DESIGNER_PRODUCT_SALES_HEADINGS = [
  {
    id: 'products',
    label: 'Products',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'amount',
    label: 'Amount',
    sortable: false,
    menuOptions: [],
    flexEnd: true,
  },
];

export const PRODUCTS_LIST_PREVIEW_HEADINGS = [
  {
    id: 'checkbox_visual',
    label: '',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'name',
    label: 'Name',
    sortable: true,
    menuOptions: [],
  },
  {
    id: 'categories',
    label: 'Categories',
    sortable: false,
    menuOptions: [],
  },
  {
    id: 'created',
    label: 'Created',
    sortable: true,
    menuOptions: [],
  },

  {
    id: 'preview',
    label: 'Preview',
    sortable: false,
    menuOptions: [],
  },
];
