import React, { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';

import { routes } from 'options/routes';

import { getCheckoutSuccessBrief } from 'api';

const BuyerBriefSuccess = (props) => {
  const navigate = useNavigate();
  const { brief_uuid, brief_designer_uuid } = useParams();
  const [briefData, setMessage] = useOutletContext();

  useEffect(() => {
    const getSuccess = async () => {
      const res = await getCheckoutSuccessBrief(brief_uuid, brief_designer_uuid);
      if (!res.success) {
        setMessage(res?.data?.error_message || 'There was an error while checking out.');
        return navigate(`${routes.briefsDashboard}/${brief_uuid}/${brief_designer_uuid}/brief`);
      }
      setMessage('Thanks for your payment.');
      window.location.href = `${routes.briefsDashboard}/${brief_uuid}/${brief_designer_uuid}/brief`;
    };
    getSuccess();
  }, [navigate, brief_uuid, setMessage, brief_designer_uuid]);
  return (
    <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
      <Spinner align="center" />
    </Box>
  );
};

BuyerBriefSuccess.propTypes = {};

export default BuyerBriefSuccess;
