// Project status is the same as buyer's product statues
export const PROJECT_STATUS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
  REJECTED: 'rejected',
  DESIGNER_CANCELLED: 'designer_cancelled',
  BUYER_CANCELLED: 'buyer_cancelled',
};

// Filter options shouldn't have rejected option
export const PROJECT_STATUS_FILTER = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  COMPLETED: 'completed',
};
