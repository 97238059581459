import React from 'react';
import PropTypes from 'prop-types';
import { PropertyPillGroupWrapperStyled } from './PropertyPillGroup.styles';
import PillTagToolTip from 'components/Tooltips/PillTagToolTip/PillTagToolTip';

const PropertyPillGroup = ({ options, onDelete }) => {
  return (
    <PropertyPillGroupWrapperStyled>
      {options.map((option) => {
        const { tooltipText, label, value } = option;
        return (
          <PillTagToolTip
            toolTipText={tooltipText}
            option={{ value, label }}
            removeSelection={onDelete}
            key={`property_pill_${option.value}`}
          />
        );
      })}
    </PropertyPillGroupWrapperStyled>
  );
};

PropertyPillGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      tooltipText: PropTypes.string,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PropertyPillGroup;
