import React from 'react';
import PropTypes from 'prop-types';

import { CheckIconStyled, TabStyled, TabWrapperStyled } from './ProductPropertyTab.styles';

const ProductPropertyTab = ({ label, value, variant, ...props }) => {
  const isCompleted = variant === 'completed';
  return (
    <TabWrapperStyled variant={variant}>
      <TabStyled label={label} variant={variant} value={value} {...props} />
      {isCompleted && <CheckIconStyled fontSize="small" />}
    </TabWrapperStyled>
  );
};

ProductPropertyTab.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'active', 'completed']),
  // must spread ...props so Tab gets onChange event from Tabs
};

export default React.memo(ProductPropertyTab);
