import React, { useCallback, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChatIcon from '@mui/icons-material/Chat';
import DoneIcon from '@mui/icons-material/Done';
import Spinner from 'components/Spinner/Spinner';
import ChatBox from 'components/ChatBox/ChatBox';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import BuyerTabSectionsWrapper from 'components/Wrappers/TabSectionsWrapper/BuyerTabSectionsWrapper/BuyerTabSectionsWrapper';
import ModalBriefProposalComplete from 'components/ModalBriefProposalComplete/ModalBriefProposalComplete';
import ModalCongratulations from 'components/ModalCongratulations/ModalCongratulations';
import TopBarTitleStatus from 'components/TopBarTitleStatus/TopBarTitleStatus';
import ScrollableContainer from 'components/ScrollableContainer/ScrollableContainer';
import BodyText from 'components/Typography/BodyText/BodyText';

import { routes } from 'options/routes';
import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { PROJECT_STATUS } from 'options/projectStatus';

import { parseRemoteProductProposalData } from 'utils/parseProductProposal';

import { options } from './BuyerSingleProductLanding.data';

import { useMessageContext } from 'context/MessageContext';

import {
  deleteBuyersProductFiles,
  downloadBuyersProductFiles,
  getBuyersSingleProduct,
  getCheckoutProduct,
  getProductChatMessages,
  postBuyersProductFiles,
  postProductChatMessage,
  completePurchase,
} from 'api';

const BuyerSingleProductLanding = ({ userType }) => {
  const { product_uuid } = useParams();
  const [setMessage] = useMessageContext();
  const navigate = useNavigate();

  const [openCompletePurchase, setOpenCompletePurchase] = useState(false);
  const [openCongratulations, setOpenCongratulations] = useState(false);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);

  const [showMessages, setShowMessages] = useState(true);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['buyerSingleProduct', product_uuid],
    () => getBuyersSingleProduct(product_uuid),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const product = (data && data.product && parseRemoteProductProposalData(data?.product)) || {};
  const hasError = data?.data?.error_message;
  const { agreement_status, project_status } = product;
  const chat_uuid = product?.chat_uuid;
  const title = product?.name;

  const handleSectionClick = useCallback(
    (path) => {
      navigate(`${routes.productsDashboard}/${product_uuid}${path}`);
    },
    [navigate, product_uuid]
  );

  const handleOnCompletePurchase = useCallback(async () => {
    const res = await completePurchase(product_uuid);
    if (!res.success) {
      setOpenCompletePurchase(false);
      return setMessage(res?.data?.error_message || 'There was a problem completing the purchase');
    }
    setOpenCompletePurchase(false);
    setOpenCongratulations(true);
  }, [product_uuid, setMessage]);

  const handleOnCheckout = useCallback(async () => {
    if (!isCheckoutLoading) {
      setIsCheckoutLoading(true);
      const res = await getCheckoutProduct(product_uuid);
      setIsCheckoutLoading(false);
      if (!res.success || !res.url) {
        return setMessage(res?.data?.error_message || 'There was a problem checking out.');
      }

      //redirect to stripe
      window.location.href = res?.url;
    }
  }, [isCheckoutLoading, product_uuid, setMessage]);

  const showCompletePurchase =
    agreement_status === AGREEMENT_STATUS.PAID && project_status !== PROJECT_STATUS.COMPLETED;
  const showCheckoutButton = agreement_status === AGREEMENT_STATUS.UPFRONT_PAID;
  const showFilesTab = agreement_status === AGREEMENT_STATUS.PAID;
  const isProjectCompleted = project_status === PROJECT_STATUS.COMPLETED;

  const loading = isLoading || isRefetching;

  const toggleMessages = useCallback(() => {
    setShowMessages((prev) => !prev);
  }, []);

  const briefDesignerName = useMemo(() => {
    const designer = product?.designer;
    if (designer) {
      return designer.first_name + ' ' + designer.last_name;
    }
    return '';
  }, [product?.designer]);

  return (
    <BuyerTabSectionsWrapper
      title={title}
      headerMenuOptions={[
        {
          buttonTitle: showMessages ? 'Hide Messages' : 'Show Messages',
          icon: showMessages ? <SpeakerNotesOffIcon fontSize="small" /> : <ChatIcon fontSize="small" />,
          onClick: toggleMessages,
        },
        {
          buttonTitle: 'Back',
          icon: <ArrowBackIcon fontSize="small" />,
          onClick: () => navigate(`${routes.productsDashboard}`),
          animate: true,
        },
        ...(showCompletePurchase
          ? [
              {
                buttonTitle: 'Complete Purchase',
                icon: <DoneIcon fontSize="small" />,
                onClick: () => setOpenCompletePurchase(true),
                colour: 'black',
              },
            ]
          : []),
        ...(showCheckoutButton
          ? [
              {
                buttonTitle: 'Checkout',
                icon: isCheckoutLoading && <Spinner size="12px" />,
                onClick: () => handleOnCheckout(),
                colour: 'black',
              },
            ]
          : []),
      ]}
      activeTab={routes.productsDashboard}
      collapse={showMessages}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Products', onClick: () => navigate(routes.productsDashboard) }]}
          currentPage={title}
        />
      }
      handleSectionClick={handleSectionClick}
      options={options.map((option) => {
        if (option.path === '/files') {
          return {
            ...option,
            disabled: !showFilesTab,
          };
        }
        return option;
      })}
      chatbox={
        <ChatBox
          chat_uuid={chat_uuid}
          getChatMessages={() => getProductChatMessages(chat_uuid)}
          postChatMessage={(chat_uuid, data) => postProductChatMessage(chat_uuid, data)}
          downloadFiles={(data) => downloadBuyersProductFiles(product_uuid, data, true)}
          uploadFiles={(data) => postBuyersProductFiles(product_uuid, data)}
          deleteFiles={(file_uuid) => deleteBuyersProductFiles(product_uuid, file_uuid)}
          userType={userType}
          expand={showMessages}
          setExpand={toggleMessages}
        />
      }
    >
      {loading && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}
      {!loading && (
        <>
          <TopBarTitleStatus name={briefDesignerName} project_status={project_status} />
          <ScrollableContainer collapse={showMessages}>
            {hasError ? (
              <Box mt={2}>
                <BodyText text="Product no longer available." />
              </Box>
            ) : (
              <Outlet context={[product, setMessage, userType]} />
            )}
          </ScrollableContainer>
        </>
      )}
      {openCompletePurchase && (
        <ModalBriefProposalComplete
          open={openCompletePurchase}
          handleClose={() => setOpenCompletePurchase(false)}
          onSubmit={handleOnCompletePurchase}
          variant="purchase"
        />
      )}
      {openCongratulations && (
        <ModalCongratulations
          handleClose={() => setOpenCongratulations(false)}
          action={() => navigate(`${routes.productsDashboard}/${product_uuid}/review`)}
          text="You have completed the purchase!"
          title="Product"
          open={openCongratulations}
        />
      )}
    </BuyerTabSectionsWrapper>
  );
};

BuyerSingleProductLanding.propTypes = {};

export default BuyerSingleProductLanding;
