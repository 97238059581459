import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';

import { Box } from '@mui/material';
import Close from '@mui/icons-material/Close';
import ModalCancelItem from 'components/ModalCancelItem/ModalCancelItem';
import Spinner from 'components/Spinner/Spinner';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import EmptyListCreateNew from 'components/EmptyListCreateNew/EmptyListCreateNew';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import OpenCallsProposalsList from 'components/OpenCallsProposalsList/OpenCallsProposalsList';

import { routes } from 'options/routes';

import { getErrorMessage } from 'utils/getErrorMessage';

import { useMessageContext } from 'context/MessageContext';

import { cancelBuyerOpenCall, getBuyerOpenCallsProposalsList } from 'api';

const BuyerOpenCallsProposalsList = () => {
  const { brief_uuid } = useParams();
  const [openCancel, setOpenCancel] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const navigate = useNavigate();
  const [setMessage] = useMessageContext();
  const queryClient = useQueryClient();

  const {
    data = {},
    isLoading,
    refetch,
  } = useQuery(['buyerOpenCallsProposalsList', brief_uuid], () => getBuyerOpenCallsProposalsList(brief_uuid), {
    enabled: true,
    staleTime: 2 * 60 * 1000,
    refetchOnMount: 'always',
  });

  const handleCancelBrief = useCallback(async () => {
    setIsCancelling(true);
    const res = await cancelBuyerOpenCall(brief_uuid);
    setIsCancelling(false);
    if (!res.success) {
      return setMessage(getErrorMessage(res));
    }
    setMessage('You have successfully cancelled this open call.');
    queryClient.invalidateQueries({ queryKey: ['buyerOpenCallsList'] });
    navigate(`${routes.openCallsDashboard}`);
  }, [brief_uuid, navigate, queryClient, setMessage]);

  const { brief_designers: proposals, brief_name, can_cancel } = data;

  return (
    <BuyerDashboardWrapper
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Open calls', onClick: () => navigate(routes.openCallsDashboard) }]}
          currentPage={brief_name}
        />
      }
      activeTab={routes.openCallsDashboard}
    >
      <HeaderAndMenu
        title={brief_name}
        menuOptions={[
          ...(can_cancel
            ? [
                {
                  buttonTitle: 'Cancel Open Call',
                  onClick: () => setOpenCancel(true),
                  icon: isCancelling ? <Spinner size="12px" /> : <Close fontSize="small" />,
                },
              ]
            : []),
        ]}
      />
      {!isLoading && !proposals?.length && (
        <EmptyListCreateNew
          btnText="View Open Calls"
          onClick={() => navigate(routes.openCallsDashboard)}
          title="There are no proposals for this open call yet."
        />
      )}
      {isLoading ? (
        <Box my={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <OpenCallsProposalsList
          proposals={proposals}
          brief_uuid={brief_uuid}
          setMessage={setMessage}
          refetch={refetch}
          brief_name={brief_name}
        />
      )}
      {openCancel && (
        <ModalCancelItem
          open={openCancel}
          handleClose={() => setOpenCancel(false)}
          handleSubmit={handleCancelBrief}
          title="Cancel Open Call"
          description="Cancelling this Open Call will cancel it for all designers who haven’t agreed terms yet. This action cannot be undone."
        />
      )}
    </BuyerDashboardWrapper>
  );
};

BuyerOpenCallsProposalsList.propTypes = {};

export default BuyerOpenCallsProposalsList;
