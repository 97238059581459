import PageWrapper from '../../../../components/Wrappers/PageWrapper/PageWrapper';
import FormParagraph from '../../../../components/Typography/FormParagraph/FormParagraph';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';

import { SignUpSuccessContainer, Container } from './SignUpSuccess.styles';
import { SectionWrapper, SectionContainer } from '../../Login/Login.styles';
import TwoColumnBlackWhiteWrapper from 'components/Layout/TwoColumnBlackWhiteWrapper/TwoColumnBlackWhiteWrapper';

const SignUpSuccess = () => {
  return (
    <TwoColumnBlackWhiteWrapper>
      <SignUpSuccessContainer>
        <CustomTitle variant="h3" text="Thanks for applying to BuyDesign" align="left" />
        <Container>
          <FormParagraph
            text={
              <>
                We’re processing your application and will send you a confirmation email soon notifying you whether your
                application has been accepted. If you have any questions about the process please direct them{' '}
                <CustomStandardLink
                  linkType="primary"
                  text="here"
                  href={'mailto:info@buydesign.com'}
                  fontSize="large"
                  target="_blank"
                />
                .
              </>
            }
            variant="primary"
          />
        </Container>
        <Container>
          <FormParagraph text={'We look forward to supporting you on your design journey.'} variant="primary" />
        </Container>
        <Container>
          <FormParagraph text={'Check your email for confirmation'} variant="primary" />
        </Container>
      </SignUpSuccessContainer>
    </TwoColumnBlackWhiteWrapper>
  );
};

export default SignUpSuccess;
