import React from 'react';
import PropTypes from 'prop-types';

import { Controller, useFormContext } from 'react-hook-form';
import CustomCheckboxStandard from '../CustomCheckboxStandard';

const ControlledCheckBoxStandard = ({ label, labelPlacement = 'end', showBorder = false, name, showError }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => {
        return (
          <CustomCheckboxStandard
            label={label}
            labelPlacement={labelPlacement}
            showBorder={showBorder}
            error={fieldState.error?.message}
            onChange={onChange}
            checked={value}
            showError={showError}
          />
        );
      }}
    />
  );
};

ControlledCheckBoxStandard.propTypes = {
  label: PropTypes.string.isRequired,
  labelPlacement: PropTypes.oneOf(['start', 'end']),
  showBorder: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

export default ControlledCheckBoxStandard;
