import PropTypes from 'prop-types';

import PageWrapper from '../../PageWrapper/PageWrapper';
import DesignerDashboardMenu from 'components/DesignerDashboardMenu/DesignerDashboardMenu';
import { StyledContainer, BreadcrumbsContainer } from '../DashboardWrapper.styles';

import { userTypes } from 'options/userTypes';

const DesignerDashboardWrapper = ({ activeTab, breadcrumbs, children, collapse }) => {
  return (
    <PageWrapper userType={userTypes.designer} showSecondaryFooter={false} collapse={collapse}>
      <DesignerDashboardMenu activeTab={activeTab} />
      <StyledContainer collapse={collapse}>
        {breadcrumbs && <BreadcrumbsContainer>{breadcrumbs}</BreadcrumbsContainer>}
        {children}
      </StyledContainer>
    </PageWrapper>
  );
};

DesignerDashboardWrapper.propTypes = {
  activeTab: PropTypes.string,
  breadcrumbs: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default DesignerDashboardWrapper;
