import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import DesignerPitchList from 'components/DesignerPitchList/DesignerPitchList';

import { routes } from 'options/routes';

import { MOCK_PITCH_LIST_DESIGNER } from 'mockData/mockPitch';

export const DesignerPitchesDashboard = () => {
  const navigate = useNavigate();
  return (
    <DesignerDashboardWrapper activeTab={routes.pitchesDashboard}>
      <HeaderAndMenu
        title="Pitches"
        menuOptions={[
          { buttonTitle: 'Add Pitch', icon: <AddIcon />, onClick: () => navigate(routes.pitchesAdd) },
          { buttonTitle: 'Send Product', icon: <SendIcon />, onClick: () => navigate(routes.pitchesConversionAdd) },
        ]}
      />
      <DesignerPitchList isStory={false} mockData={MOCK_PITCH_LIST_DESIGNER} />
    </DesignerDashboardWrapper>
  );
};

export default DesignerPitchesDashboard;
