import { useCallback, useState, useMemo, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Box, Table } from '@mui/material';
import Spinner from '../Spinner/Spinner';
import CustomTableWrapper from '../CustomTableWrapper/CustomTableWrapper';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import StatusPill from 'components/StatusPill/StatusPill';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import BodyText from 'components/Typography/BodyText/BodyText';

import { PILL_STATUS_ICON, PILL_STATUS_MAP, DESIGNER_BRIEF_PILL_STATUS_TEXT } from 'options/pillStatusOptions';
import { proposalListHeadings } from '../../options/tableOptions';
import theme from '../../theme/theme';
import { routes } from '../../options/routes';

import { getDesignerSingleProposal } from 'api';

const ProposalTableList = ({ brief_uuid, isStory, mockData, setMessage }) => {
  const [loading, setLoading] = useState(!isStory);
  const [proposalsList, setProposalsList] = useState(isStory ? mockData : []);
  const navigate = useNavigate();

  const productsQuery = useQuery(['proposalTableList', brief_uuid], () => getDesignerSingleProposal(brief_uuid), {
    enabled: !isStory,
    staleTime: 2 * 60 * 1000,
  });

  useEffect(() => {
    if (isStory) return;
    setLoading(true);
    // Set data states
    if (productsQuery.data && productsQuery.data.success) {
      setProposalsList(
        productsQuery.data.products && productsQuery.data.products.length ? productsQuery.data.products : []
      );
    }
    // Set loading and error states
    if (productsQuery.status === 'success') {
      setLoading(false);
    }
  }, [isStory, productsQuery.status, productsQuery.data, setMessage]);

  const onView = useCallback(
    async (uuid) => {
      // if (isStory) {
      //   console.log(uuid);
      //   return;
      // }
      navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal/${uuid}`);
    },
    [isStory, navigate, brief_uuid]
  );

  const onEdit = useCallback(
    async (uuid) => {
      if (isStory) {
        console.log(uuid);
        return;
      }
      navigate(`${routes.briefsDashboard}/${brief_uuid}/proposal/add/${uuid}`);
    },
    [isStory, navigate, brief_uuid]
  );

  const onDelete = useCallback(
    async (uuid) => {
      if (isStory) {
        console.log(uuid);
        return;
      }
      navigate(`/`);
    },
    [isStory, navigate]
  );

  const onAddToMarketplace = useCallback(
    async (uuid) => {
      if (isStory) {
        console.log(uuid);
        return;
      }
      navigate(`/`);
    },
    [isStory, navigate]
  );

  const headingsOnClick = useMemo(() => proposalListHeadings.map((item) => ({ ...item, selected: false })), []);

  return (
    <>
      {loading && !isStory && (
        <Box mt={theme.spacing(2)}>
          <Spinner align="center" />
        </Box>
      )}
      {!loading && (
        <CustomTableWrapper
          noResultsTitle={proposalsList.length === 0 ? 'No proposals found' : ''}
          noResultsDescription={
            proposalsList.length === 0 ? "You haven't added any proposals yet. Let's get started!" : ''
          }
          btnText={proposalsList.length === 0 ? 'Add a Proposal' : ''}
          onBtnClick={
            proposalsList.length === 0 ? () => navigate(routes.proposalAdd.replace(':brief_uuid', brief_uuid)) : null
          }
        >
          <>
            {proposalsList.length > 0 && (
              <>
                <Table>
                  <CustomTableHeader
                    columnHeadings={headingsOnClick}
                    onRequestSort={() => {}}
                    order={''}
                    orderBy={''}
                  />
                  <CustomTableBody>
                    {proposalsList.map((proposal, index) => {
                      const { product_uuid, name, sent, status } = proposal;
                      const threeDotMenuActions = [
                        { title: 'View proposal', onClick: () => onView(product_uuid) },
                        { title: 'Edit proposal', onClick: () => onEdit(product_uuid) },
                        { title: 'Delete proposal', onClick: () => onDelete(product_uuid) },
                      ];
                      if (status === 'rejected')
                        threeDotMenuActions.push({
                          title: 'Add to Marketplace',
                          onClick: () => onAddToMarketplace(product_uuid),
                        });
                      return (
                        <CustomTableRow key={`table_${name}_${index}`}>
                          <CustomTableBodyCell>
                            <Box display="flex">
                              <ItemInfo
                                label={name}
                                avatar={{ fileSrc: '', fileType: '' }}
                                actions={threeDotMenuActions}
                              />
                            </Box>
                          </CustomTableBodyCell>
                          <CustomTableBodyCell>
                            <Box pl={2}>
                              <BodyText text={sent ? sent : '--'} />
                            </Box>
                          </CustomTableBodyCell>
                          <CustomTableBodyCell>
                            <StatusPill
                              label={DESIGNER_BRIEF_PILL_STATUS_TEXT[status]}
                              status={PILL_STATUS_MAP[status]}
                              icon={PILL_STATUS_ICON[status]}
                            />
                          </CustomTableBodyCell>
                        </CustomTableRow>
                      );
                    })}
                  </CustomTableBody>
                </Table>
              </>
            )}
          </>
        </CustomTableWrapper>
      )}
    </>
  );
};

ProposalTableList.propTypes = {};

export default ProposalTableList;
