import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IosShareIcon from '@mui/icons-material/IosShare';
import SearchIcon from '@mui/icons-material/Search';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import MoodboardSingle from 'components/MoodboardSingle/MoodboardSingle';
import Spinner from 'components/Spinner/Spinner';
import EmptyListCreateNew from 'components/EmptyListCreateNew/EmptyListCreateNew';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';
import ModalCreateMoodboard from 'components/ModalCreateMoodboard/ModalCreateMoodboard';
import ModalShareMoodboard from 'components/ModalShareMoodboard/ModalShareMoodboard';
import ModalDeleteMoodboard from 'components/ModalDeleteMoodboard/ModalDeleteMoodboard';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import ModalExternalShareMoodboard from 'components/ModalExternalShareMoodboard/ModalExternalShareMoodboard';

import { routes } from 'options/routes';

import { useMessageContext } from 'context/MessageContext';

import { getSingleMoodboard, hideOrUnhideFromMoodboard } from 'api';

const BuyerSingleMoodboard = (props) => {
  const navigate = useNavigate();
  const [setMessage] = useMessageContext('');
  const { moodboard_uuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [moodboardDetails, setMoodboardDetails] = useState({});

  const [openEditMoodboard, setOpenEditMoodboard] = useState(false);
  const [openShareMoodboard, setOpenShareMoodboard] = useState(false);
  const [openShareMoodboardExternally, setOpenShareMoodboardExternally] = useState(false);
  const [openDeleteMoodboard, setOpenDeleteMoodboard] = useState(false);

  const fetchMoodboard = useCallback(async () => {
    setIsLoading(true);
    localStorage.removeItem('moodboard');
    const res = await getSingleMoodboard(moodboard_uuid);
    if (res.success) {
      setMoodboardDetails(res?.moodboard);
    }
    setIsLoading(false);
  }, [moodboard_uuid]);

  useEffect(() => {
    fetchMoodboard();
  }, [fetchMoodboard]);

  const handleAddProductsClick = useCallback(() => {
    navigate(routes.products);
  }, [navigate]);

  const handleOnHiddenClick = useCallback(
    async (product_uuid, data, event) => {
      if (event) {
        event.stopPropagation();
      }
      // setIsLoading(true);
      const res = await hideOrUnhideFromMoodboard(moodboard_uuid, product_uuid, data);
      // setIsLoading(false);
      if (res.success) {
        const messageText = data.is_hide ? 'Product hidden from your Moodboard' : 'Product visible on your Moodboard';
        setMessage(messageText);
        return fetchMoodboard();
      }
      setMessage('There was a problem changing product visibility');
    },
    [fetchMoodboard, moodboard_uuid, setMessage]
  );

  const handleCloseModal = (setModalState, shouldRefetch, shouldRedirect) => {
    setModalState(false);
    if (shouldRefetch) {
      fetchMoodboard();
    }
    if (shouldRedirect) {
      navigate(routes.moodboardsDashboard);
    }
  };

  return (
    <BuyerDashboardWrapper
      activeTab={routes.moodboardsDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Moodboards', onClick: () => navigate(routes.moodboardsDashboard) }]}
          currentPage={moodboardDetails?.name || ''}
        />
      }
    >
      <HeaderAndMenu
        title="Moodboards"
        menuOptions={[
          {
            buttonTitle: 'Find Products',
            icon: <SearchIcon fontSize="small" />,
            onClick: () => {
              localStorage.setItem('moodboard', moodboardDetails?.moodboard_uuid);
              return window.open(`/products`, '_blank');
            },
            breakpoint: '1000',
          },
          {
            buttonTitle: 'Share to team',
            icon: <ShareIcon />,
            onClick: () => setOpenShareMoodboard(true),
            breakpoint: '1000',
          },
          {
            buttonTitle: 'Share externally',
            icon: <IosShareIcon />,
            onClick: () => setOpenShareMoodboardExternally(true),
            breakpoint: '1000',
          },
          {
            buttonTitle: 'Edit board',
            icon: <EditIcon />,
            onClick: () => setOpenEditMoodboard(true),
            breakpoint: '1000',
          },
          {
            buttonTitle: 'Delete board',
            icon: <DeleteIcon />,
            onClick: () => setOpenDeleteMoodboard(true),
            breakpoint: '1000',
          },
        ]}
      />
      {isLoading && !moodboardDetails?.products?.length && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}
      {!isLoading && !moodboardDetails?.products?.length && (
        <>
          <Box my={4}>
            <CustomTitle text={moodboardDetails.name} variant="h3" />
            <Box mt={2}>
              <BodyText text={moodboardDetails.description} />
            </Box>
          </Box>
          <Box mt={2}>
            <EmptyListCreateNew
              title="No products"
              description="Add products to your moodboard"
              btnText="Add Products"
              onClick={handleAddProductsClick}
            />
          </Box>
        </>
      )}
      {!!moodboardDetails?.products?.length && (
        <MoodboardSingle
          moodboardProducts={moodboardDetails.products}
          name={moodboardDetails.name}
          description={moodboardDetails.description}
          moodboard_uuid={moodboardDetails.moodboard_uuid}
          handleOnHiddenClick={handleOnHiddenClick}
          isOwner={moodboardDetails.is_owner}
          refetch={fetchMoodboard}
          setMessage={setMessage}
        />
      )}
      {openEditMoodboard && (
        <ModalCreateMoodboard
          open={openEditMoodboard}
          handleClose={(shouldRefetch) => handleCloseModal(setOpenEditMoodboard, shouldRefetch)}
          moodboard_uuid={moodboard_uuid}
          variant="edit"
          setMessage={setMessage}
        />
      )}
      {openShareMoodboard && (
        <ModalShareMoodboard
          open={openShareMoodboard}
          openShareMoodboard={openShareMoodboard}
          handleClose={() => handleCloseModal(setOpenShareMoodboard)}
          moodboard_uuid={moodboard_uuid}
          setMessage={setMessage}
        />
      )}
      {openDeleteMoodboard && (
        <ModalDeleteMoodboard
          open={openDeleteMoodboard}
          handleClose={(shouldRefetch) => handleCloseModal(setOpenDeleteMoodboard, false, shouldRefetch)}
          moodboard_uuid={moodboard_uuid}
          setMessage={setMessage}
        />
      )}
      {openShareMoodboardExternally && (
        <ModalExternalShareMoodboard
          open={openShareMoodboardExternally}
          openShareMoodboard={openShareMoodboard}
          handleClose={() => handleCloseModal(setOpenShareMoodboardExternally)}
          moodboard_uuid={moodboard_uuid}
          moodboard_name={moodboardDetails?.name}
          setMessage={setMessage}
        />
      )}
    </BuyerDashboardWrapper>
  );
};

BuyerSingleMoodboard.propTypes = {};

export default BuyerSingleMoodboard;
