import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import { Box } from '@mui/material';
import AccountFormContainer from '../AccountFormContainer/AccountFormContainer';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import ControlledCustomFiledUpload from '../../CustomFileUpload/ControlledCustomFileUpload/ControlledCustomFileUpload';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import FormError from '../FormError/FormError';
import ControlledDatePicker from 'components/Inputs/CustomDatePicker/ControlledDatePicker/ControlledDatePicker';
import TwoFormFieldsRow from 'components/TwoFormFieldsRow/TwoFormFieldsRow';
import ControlledAutoCompleteField from 'components/Inputs/CustomAutoCompleteField/ControlledAutoCompleteField.jsx/ControlledAutoCompleteField';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import ControlledSelectField from '../../Inputs/CustomSelectField/ControlledSelectField/ControlledSelectField';

import { DESIGNER_COUNTRIES } from 'options/countries';
import { CURRENCY_OPTIONS } from '../../../options/currency';
import { wpRoutes } from 'options/routes';

import { parseDateToMonthYear } from '../../../utils/parseDates';
import { parseRemoteOptions } from '../../../utils/parseRemoteOptions';
import { parseChoicesToArray } from '../../../utils/parseChoicesToArray';

import { validationSchema } from './DesignerCompanyDetailsForm.data';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { useAuthContext } from 'context/AuthContext';

import { updateAccountProfile } from '../../../api';

const DesignerCompanyDetailsForm = ({ options, formData, setSuccess, isStory }) => {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const [productsOptions] = useState(parseRemoteOptions(options));
  const navigate = useNavigate();
  const { getUserProfile } = useAuthContext();

  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      portrait: [],
      studio_name: '',
      studio_address_1: '',
      studio_address_2: '',
      studio_address_3: '',
      studio_city: '',
      studio_county: '',
      studio_country: null,
      studio_post_code: '',

      website: '',
      bio: '',

      currency: [],

      designer_since: null,
      experience_detail: '',
      study_location: '',
      study_subject: '',

      product_types: [],

      twitter: '',
      instagram: '',
      linkedin: '',
      pinterest: '',
    },
    resolver,
  });

  const { reset } = methods;

  useEffect(() => {
    reset({ ...formData });
  }, [reset, formData]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);

      // Parse form data
      const submitData = {
        portrait: data.portrait[0] instanceof Object ? data.portrait[0].fileSrc : data.portrait[0],
        studio_name: data.studio_name,
        studio_country: data.studio_country,
        website: data.website,
        designer_since: data.designer_since ? parseDateToMonthYear(new Date(data.designer_since)) : '',
        product_types: parseChoicesToArray(data.product_types),
      };
      if (data.studio_address_1) submitData.studio_address_1 = data.studio_address_1;
      if (data.studio_address_2) submitData.studio_address_2 = data.studio_address_2;
      if (data.studio_address_3) submitData.studio_address_3 = data.studio_address_3;
      if (data.studio_city) submitData.studio_city = data.studio_city;
      if (data.studio_county) submitData.studio_county = data.studio_county;
      if (data.studio_post_code) submitData.studio_post_code = data.studio_post_code;
      if (data.bio) submitData.bio = data.bio;
      if (data.currency) submitData.currency = data.currency[0]?.value;
      if (data.experience_detail) submitData.experience_detail = data.experience_detail;
      if (data.study_location) submitData.study_location = data.study_location;
      if (data.study_subject) submitData.study_subject = data.study_subject;
      if (data.twitter) submitData.twitter = data.twitter;
      if (data.instagram) submitData.instagram = data.instagram;
      if (data.linkedin) submitData.linkedin = data.linkedin;
      if (data.pinterest) submitData.pinterest = data.pinterest;

      // Do not make API call if using component in storybook
      if (isStory) {
        console.log(submitData);
        setLoading(false);
        return;
      }

      // Post to API
      const response = await updateAccountProfile(submitData);
      if (!response.success) {
        // Set error and reset form data to original values
        setFormError(response.data.error_message);
        reset({ ...formData });
        setLoading(false);
        return;
      }

      // Set success message in parent component
      setSuccess('Company data has been successfully updated.');
      getUserProfile();
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <AccountFormContainer
      titleText="Company Details"
      descriptionText={
        <>
          Manage your company profile and settings here. If need any help managing your account please send us a message{' '}
          <CustomStandardLink
            linkType="primary"
            text="here"
            href="/help"
            onClick={() => {
              navigate('/help');
            }}
            fontSize="large"
          />
          , or email{' '}
          <CustomStandardLink
            linkType="primary"
            text="support@buydesign.com"
            href={'mailto:support@buydesign.com'}
            fontSize="large"
          />
          .
        </>
      }
      onClick={methods.handleSubmit(onSubmit)}
      buttonText="Save this section"
      loading={loading}
    >
      {formError && <FormError errors={[formError]} />}
      <FormProvider {...methods}>
        <form>
          <CustomFieldSet
            label="Portrait"
            tooltipText="Designer Portrait"
            description={
              <>
                Follow our guides found in the resources page{' '}
                <CustomStandardLink
                  linkType="primary"
                  text="here"
                  href={wpRoutes.resourcesImageGuidelines}
                  target="_blank"
                  fontSize="large"
                />{' '}
                to upload your portrait.
              </>
            }
          >
            <ControlledCustomFiledUpload variant="single" fileTypes="images" numberOfTiles={1} name="portrait" />
          </CustomFieldSet>
          <CustomFieldSet>
            <ControlledTextField name="studio_name" label="Studio Name" />
            <ControlledTextField name="studio_address_1" label="Studio Address 1" />
            <ControlledTextField name="studio_address_2" label="Studio Address 2" />
            <ControlledTextField name="studio_address_3" label="Studio Address 3" />
            <ControlledTextField name="studio_city" label="City" />
            <TwoFormFieldsRow>
              <ControlledTextField name="studio_county" label="County" />
              <ControlledAutoCompleteField name="studio_country" label="Country" options={DESIGNER_COUNTRIES} />
            </TwoFormFieldsRow>
            <ControlledTextField name="studio_post_code" label="Post Code" />
            <ControlledTextField name="website" label="Website" />
            <ControlledTextField name="bio" label="Biography" multiline minRows={4} />
          </CustomFieldSet>

          <Box mt={6}>
            <CustomFieldSet label="Designer Since">
              <ControlledDatePicker
                name="designer_since"
                label="Designer Since"
                views={['year', 'month']}
                inputFormat="MM/yyyy"
              />
            </CustomFieldSet>
          </Box>

          <Box>
            <CustomFieldSet label="Select Currency">
              <ControlledSelectField name="currency" label="Currency" options={CURRENCY_OPTIONS} isSingleSelect />
            </CustomFieldSet>
          </Box>

          <Box mt={6}>
            <CustomFieldSet label="Tell us more">
              <ControlledTextField name="experience_detail" label="Tell us more" multiline />
              <ControlledTextField name="study_location" label="Where did you study" />
              <ControlledTextField name="study_subject" label="What did you study?" />
            </CustomFieldSet>
          </Box>

          <Box mt={6}>
            <CustomFieldSet
              label="What type of products are you interested in?"
              description={
                'Select one or more categories. We’ll use this information to recommend relevant content and ' +
                'resources around Buyers, resources, and more – just for you.'
              }
            >
              <ControlledSelectField name="product_types" label="Select All That Apply" options={productsOptions} />
            </CustomFieldSet>
          </Box>

          <Box mt={6}>
            <CustomFieldSet
              label="Social Media (Company)"
              description="Please provide the full url of the account starting: https://www..."
            >
              <TwoFormFieldsRow>
                <ControlledTextField name="twitter" label="Twitter" />
                <ControlledTextField name="instagram" label="Instagram" />
              </TwoFormFieldsRow>
              <TwoFormFieldsRow>
                <ControlledTextField name="linkedin" label="Linkedin" />
                <ControlledTextField name="pinterest" label="Pinterest" />
              </TwoFormFieldsRow>
            </CustomFieldSet>
          </Box>
        </form>
      </FormProvider>
    </AccountFormContainer>
  );
};

DesignerCompanyDetailsForm.propTypes = {
  options: PropTypes.array,
  formData: PropTypes.object,
  setSuccess: PropTypes.func.isRequired,
  isStory: PropTypes.bool,
};

export default DesignerCompanyDetailsForm;
