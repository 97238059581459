import React, { useCallback, useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import FileUploads from 'components/FileUploads/FileUploads';
import Spinner from 'components/Spinner/Spinner';

import { routes } from 'options/routes';
import { PROJECT_STATUS } from 'options/projectStatus';

import { getIsFilesTabDisabled } from '../DesignerPitchesSingle.utils';

import {
  deleteDesignerPitchBuyerSingleFiles,
  downloadDesignerPitchBuyerSingleFiles,
  getDesignerPitchBuyerSingleFiles,
  uploadDesignerPitchBuyerSingleFiles,
} from 'api';

const DesignerPitchesSingleFilesTab = () => {
  const { pitch_uuid, buyer_pitch_uuid } = useParams();
  const [pitch, setMessage, userType] = useOutletContext();
  const navigate = useNavigate();

  const { project_status, agreement_status } = pitch;

  useEffect(() => {
    const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status);

    if (isFilesTabDisabled) {
      navigate(`${routes.pitchesDashboard}/${pitch_uuid}/pitch`);
    }
  }, [pitch_uuid, navigate, agreement_status]);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['designerPitchFiles', pitch_uuid],
    () => getDesignerPitchBuyerSingleFiles(pitch_uuid, buyer_pitch_uuid),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const files = data?.design_files || {};

  const onUploadFile = useCallback(
    async (filesData) => {
      const data = {
        files: filesData,
        is_chat: false,
      };

      const res = await uploadDesignerPitchBuyerSingleFiles(pitch_uuid, buyer_pitch_uuid, data);
      if (!res.success) {
        return setMessage('There was an error uploading the files. Please try again.');
      }
      setMessage('You have successfully uploaded files');
      refetch();
    },
    [buyer_pitch_uuid, pitch_uuid, refetch, setMessage]
  );

  const handleDownloadFiles = useCallback(
    async (data) => downloadDesignerPitchBuyerSingleFiles(pitch_uuid, buyer_pitch_uuid, data, true),
    [buyer_pitch_uuid, pitch_uuid]
  );

  const handleDeletedFiles = useCallback(
    async (file_uuid) => deleteDesignerPitchBuyerSingleFiles(pitch_uuid, buyer_pitch_uuid, file_uuid),
    [buyer_pitch_uuid, pitch_uuid]
  );

  const isProjectCompleted = project_status === PROJECT_STATUS.COMPLETED;
  const loading = isLoading || isRefetching;

  return (
    <Box>
      {loading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <Box mt={2} width="100%">
          <FileUploads
            withUploader
            uploadFile={onUploadFile}
            downloadFiles={handleDownloadFiles}
            deleteFiles={!isProjectCompleted && handleDeletedFiles}
            files={files}
            setMessage={setMessage}
            refetch={refetch}
            userType={userType}
          />
        </Box>
      )}
    </Box>
  );
};

DesignerPitchesSingleFilesTab.propTypes = {};

export default DesignerPitchesSingleFilesTab;
