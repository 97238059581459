import { React, useState } from 'react';
import parse from 'html-react-parser';

import { Box } from '@mui/material';
import FieldLabel from 'components/Typography/FieldLabel/FieldLabel';
import FormParagraph from 'components/Typography/FormParagraph/FormParagraph';
import CustomTermsText from 'components/Typography/CustomTermsText/CustomTermsText';
import ControlledCheckBoxTerms from 'components/Inputs/CustomCheckBoxTerms/ControlledCheckBoxTerms/ControlledCheckBoxTerms';
import ControlledCheckBoxStandard from 'components/Inputs/CustomCheckboxStandard/ControlledCheckBoxStandard/ControlledCheckBoxStandard';

import { sanitize } from 'dompurify';

import { marked } from 'marked';

import BodyText from 'components/Typography/BodyText/BodyText';
import CustomAccordion from 'components/Accordion/CustomAccordion/CustomAccordion';
import CustomAccordionHead from 'components/Accordion/CustomAccordionHead/CustomAccordionHead';

import DesignerTerms from './DesignerTerms';
import BuyerTerms from './BuyerTerms';

const SignUpAgreement = ({ isDesigner }) => {
  const [expand, setExpand] = useState({
    panel1: false,
    panel2: false,
    panel3: false,
    panel4: false,
  });

  const texts = {
    text1: isDesigner ? DesignerTerms : BuyerTerms,
    text2:
      '<p>Body text – Lorem ipsum dolor sit amet, consectetur adipiscing elit.<p>Aenean iaculis sollicitudin elit, a placerat nisi vulputate a. Sed aliquet, sapien vitae viverra faucibus, nisi enim mattis libero, nec venenatis ipsum dolor vel mauris.<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis sollicitudin elit, a placerat nisi vulputate a. Sed aliquet, sapien vitae viverra faucibus, nisi enim mattis libero, nec venenatis ipsum dolor vel mauris.<p>Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus disc<p><ul><li>Lectus id duis vitae porttitor enim gravida morbic<li>Eu turpis posuere semper feugiat volutpat elit, ultrices suspendisse. Auctor vel in vitae placeratc<li>Suspendisse maecenas ac donec scelerisque diam sed est duis purus.<p>Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
    text3:
      '<p>Body text – Lorem ipsum dolor sit amet, consectetur adipiscing elit.<p>Aenean iaculis sollicitudin elit, a placerat nisi vulputate a. Sed aliquet, sapien vitae viverra faucibus, nisi enim mattis libero, nec venenatis ipsum dolor vel mauris.<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis sollicitudin elit, a placerat nisi vulputate a. Sed aliquet, sapien vitae viverra faucibus, nisi enim mattis libero, nec venenatis ipsum dolor vel mauris.<p>Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus disc<p><ul><li>Lectus id duis vitae porttitor enim gravida morbic<li>Eu turpis posuere semper feugiat volutpat elit, ultrices suspendisse. Auctor vel in vitae placeratc<li>Suspendisse maecenas ac donec scelerisque diam sed est duis purus.<p>Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
    text4:
      '<p>Body text – Lorem ipsum dolor sit amet, consectetur adipiscing elit.<p>Aenean iaculis sollicitudin elit, a placerat nisi vulputate a. Sed aliquet, sapien vitae viverra faucibus, nisi enim mattis libero, nec venenatis ipsum dolor vel mauris.<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean iaculis sollicitudin elit, a placerat nisi vulputate a. Sed aliquet, sapien vitae viverra faucibus, nisi enim mattis libero, nec venenatis ipsum dolor vel mauris.<p>Sagittis et eu at elementum, quis in. Proin praesent volutpat egestas sociis sit lorem nunc nunc sit. Eget diam curabitur mi ac. Auctor rutrum lacus malesuada massa ornare et. Vulputate consectetur ac ultrices at diam dui eget fringilla tincidunt. Arcu sit dignissim massa erat cursus vulputate gravida id. Sed quis auctor vulputate hac elementum gravida cursus disc<p><ul><li>Lectus id duis vitae porttitor enim gravida morbic<li>Eu turpis posuere semper feugiat volutpat elit, ultrices suspendisse. Auctor vel in vitae placeratc<li>Suspendisse maecenas ac donec scelerisque diam sed est duis purus.<p>Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque congue eget consectetur turpis. Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.',
  };

  const onChange = (id) => {
    setExpand((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <Box>
      <Box paddingY={2}>
        <Box mb={1}>
          <FieldLabel text="BuyDesign is a community for original ideas, to ensure this we ask you to commit to the following: " />
        </Box>
      </Box>

      <Box paddingY={2}>
        <Box mb={1}>
          <CustomAccordion id="panel1" expanded={expand.panel1} onChange={onChange} index={0}>
            <CustomAccordionHead
              text={`${isDesigner ? 'Designer' : 'Buyer'} Terms and Conditions`}
              expanded={expand.panel1}
            />
            <Box my={1}>
              <BodyText text={parse(sanitize(marked.parse(texts.text1)))} />
            </Box>
          </CustomAccordion>
          {/* <CustomAccordion id="panel2" expanded={expand.panel2} onChange={onChange} index={1}>
            <CustomAccordionHead text="Designer agreement" expanded={expand.panel2} />
            <Box my={1}>
              <BodyText text={parse(sanitize(texts.text2))} />
            </Box>
          </CustomAccordion>
          <CustomAccordion id="panel3" expanded={expand.panel3} onChange={onChange} index={2}>
            <CustomAccordionHead text="Privacy policy" expanded={expand.panel3} />
            <Box my={1}>
              <BodyText text={parse(sanitize(texts.text3))} />
            </Box>
          </CustomAccordion>
          <CustomAccordion id="panel4" expanded={expand.panel4} onChange={onChange} index={3}>
            <CustomAccordionHead text="Non discriminatory policy" expanded={expand.panel4} />
            <Box my={1}>
              <BodyText text={parse(sanitize(texts.text4))} />
            </Box>
          </CustomAccordion> */}
        </Box>
      </Box>

      <Box mb={2} py={1} px={0}>
        <FormParagraph text={parse(sanitize(texts.terms))} />
        <Box my={2}>
          <ControlledCheckBoxTerms name="terms_accepted" />
        </Box>
        <Box my={2}>
          <ControlledCheckBoxStandard
            label={
              <Box ml={1}>
                <CustomTermsText variant="primary">
                  I would like to receive marketing email updates from BuyDesign
                </CustomTermsText>
              </Box>
            }
            name={'mailchimp_subscribed'}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SignUpAgreement;
