import { Box, styled } from '@mui/material';

export const StyledBodyTextContainer = styled(Box)(({ theme }) => ({
  lineBreak: 'strict',

  paddingTop: theme.spacing(2),
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '95%',
  },
}));
