export const CURRENCY_OPTIONS = [
  { label: 'Pounds (£)', value: 'gbp' },
  { label: 'Euros (€)', value: 'eur' },
  { label: 'US Dollars ($)', value: 'usd' },
];

export const CURRENCY_MAP = {
  gbp: '£',
  eur: '€',
  usd: '$',
};
