import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';

import { Box } from '@mui/material';
import Close from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DoneIcon from '@mui/icons-material/Done';
import ChatIcon from '@mui/icons-material/Chat';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Spinner from 'components/Spinner/Spinner';
import BuyerTabSectionsWrapper from 'components/Wrappers/TabSectionsWrapper/BuyerTabSectionsWrapper/BuyerTabSectionsWrapper';
import ChatBox from 'components/ChatBox/ChatBox';
import ModalBriefProposalComplete from 'components/ModalBriefProposalComplete/ModalBriefProposalComplete';
import ModalCongratulations from 'components/ModalCongratulations/ModalCongratulations';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';
import ModalAcceptProposal from 'components/ModalAcceptProposal/ModalAcceptProposal';
import ModalRejectBrief from 'components/ModalRejectBrief/ModalRejectBrief';
import TopBarTitleStatus from 'components/TopBarTitleStatus/TopBarTitleStatus';
import ScrollableContainer from 'components/ScrollableContainer/ScrollableContainer';
import BodyText from 'components/Typography/BodyText/BodyText';

import { routes } from 'options/routes';
import { PROPOSAL_STATUS } from 'options/proposalStatus';
import { PROJECT_STATUS } from 'options/projectStatus';

import { capitalize } from 'utils/capitalize';
import { parseRemoteData } from 'pages/Dashboard/Buyer/Briefs/BuyerBriefNew/BuyerBriefNew.utils';
import {
  getIsAgreementTabDisabled,
  getIsFilesTabDisabled,
  getIsProposalTabDisabled,
  getShowCheckoutButton,
  getShowCompleteProject,
} from '../BuyerOpenCallsSingle.utils';

import { options } from './BuyerOpenCallsSingleCallLanding.data';

import { MOCK_BRIEF_DATA } from 'mockData/mockBrief';

import { useMessageContext } from 'context/MessageContext';
import {
  deleteBuyerOpenCallsSingleFiles,
  downloadBuyerOpenCallsSingleFiles,
  getBriefChatMessages,
  getBuyerOpenCallsSingleDetails,
  getBuyerOpenCallsSingleProposal,
  postBriefChatMessage,
  postBuyerOpenCallsSingleCheckout,
  postBuyerOpenCallsSingleComplete,
  postBuyerOpenCallsSingleResponse,
  postCancelOpenCallsNegotiation,
  uploadBuyerOpenCallsSingleFiles,
} from 'api';

const BuyerOpenCallsSingleCallLanding = ({ isStory, userType }) => {
  const { brief_uuid, brief_designer_uuid } = useParams();
  const navigate = useNavigate();
  const [setMessage] = useMessageContext('');
  const [openCompleteProject, setOpenCompleteProject] = useState(false);
  const [openCongratulations, setOpenCongratulations] = useState(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [isCheckingOut, setIsCheckingOut] = useState(false);

  const queryClient = useQueryClient();

  const [openAcceptProposal, setOpenAcceptProposal] = useState(false);
  const [openHaveAccepted, setOpenHaveAccepted] = useState(false);

  const [openRejectProposal, setOpenRejectProposal] = useState(false);

  const [showMessages, setShowMessages] = useState(true);

  const location = useLocation();
  const pathnameArr = location?.pathname.split('/');
  const pathName = pathnameArr[pathnameArr.length - 1];

  const { data, isLoading, isError, refetch } = useQuery(
    ['buyerSingleOpenCall', brief_uuid, brief_designer_uuid],
    () => getBuyerOpenCallsSingleDetails(brief_uuid, brief_designer_uuid),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
    }
  );

  if (isError) {
    setMessage('There was an error fetching proposal details.');
  }

  const openCallData = useMemo(
    () => (isStory ? MOCK_BRIEF_DATA : (data && data?.brief && parseRemoteData(data?.brief)) || {}),
    [data, isStory]
  );

  const openCallName = openCallData?.title;
  const chat_uuid = openCallData?.chat_uuid || '';
  const designer_name = openCallData?.brief_designer?.first_name;
  const company_name = openCallData?.company?.name;
  const item_name = openCallData?.title;

  const { project_status, proposal_status, agreement_status, status, can_cancel_negotiation } = openCallData;

  const wasDirected = useRef(null);

  const { data: proposalData } = useQuery(
    ['buyerOpenCallProposalDetails', brief_uuid],
    () => getBuyerOpenCallsSingleProposal(brief_uuid, brief_designer_uuid),

    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  useEffect(() => {
    if (!wasDirected.current && proposalData?.proposal && !location.pathname.includes('/success')) {
      wasDirected.current = true;
      navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/proposal`);
    }
  }, [proposalData?.proposal]);

  const handleSectionClick = useCallback(
    (path) => {
      navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}${path}`);
    },
    [navigate, brief_uuid, brief_designer_uuid]
  );

  const handleOnComplete = async () => {
    const res = await postBuyerOpenCallsSingleComplete(brief_uuid, brief_designer_uuid);
    if (!res.success) {
      setOpenCompleteProject(false);
      return setMessage('There was a problem completing the project');
    }
    setOpenCompleteProject(false);
    setOpenCongratulations(true);
  };

  const handleOnCheckout = async () => {
    if (!isCheckingOut) {
      setIsCheckingOut(true);
      const res = await postBuyerOpenCallsSingleCheckout(brief_uuid, brief_designer_uuid);
      setIsCheckingOut(false);
      if (!res.success || !res.url) {
        return setMessage(res?.data?.error_message || 'There was a problem checking out.');
      }
      // redirect to stripe
      window.location.href = res?.url;
    }
  };

  const isProposalTabDisabled = getIsProposalTabDisabled(project_status, proposal_status, status);
  const isAgreementTabDisabled = getIsAgreementTabDisabled(project_status, proposal_status, status);
  const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status, status);

  const showCompleteProject = getShowCompleteProject(agreement_status, project_status);
  const showCheckoutButton = getShowCheckoutButton(agreement_status, project_status);

  const isProjectCompleted = project_status === PROJECT_STATUS.COMPLETED;

  const handleGetChatMessages = useCallback(() => {
    return getBriefChatMessages(chat_uuid);
  }, [chat_uuid]);

  const handlePostChatMessages = useCallback((chat_uuid, data) => {
    return postBriefChatMessage(chat_uuid, data);
  }, []);

  const handleChatDownloadFiles = useCallback(
    (data) => {
      return downloadBuyerOpenCallsSingleFiles(brief_uuid, brief_designer_uuid, data);
    },
    [brief_designer_uuid, brief_uuid]
  );

  const handleChatUploadFiles = useCallback(
    (data) => {
      return uploadBuyerOpenCallsSingleFiles(brief_uuid, brief_designer_uuid, data);
    },
    [brief_designer_uuid, brief_uuid]
  );

  const handleChatDeleteFiles = useCallback(
    (file_uuid) => {
      return deleteBuyerOpenCallsSingleFiles(brief_uuid, brief_designer_uuid, file_uuid);
    },
    [brief_designer_uuid, brief_uuid]
  );

  const toggleMessages = useCallback(() => {
    setShowMessages((prev) => !prev);
  }, []);

  const onAccept = useCallback(() => {
    setOpenAcceptProposal(true);
  }, []);

  const onReject = useCallback(() => {
    setOpenRejectProposal(true);
  }, []);

  const updateProposalCache = useCallback(
    (accept) => {
      queryClient.setQueryData(['buyerOpenCallProposalDetails', brief_uuid], (oldData) => {
        if (oldData?.proposal) {
          return {
            proposal: {
              ...oldData.proposal,
              status: accept ? PROPOSAL_STATUS.APPROVED : PROPOSAL_STATUS.REJECTED,
            },
            success: true,
          };
        }
      });

      queryClient.setQueryData(['buyerSingleOpenCall', brief_uuid, brief_designer_uuid], (oldData) => ({
        brief: {
          ...oldData.brief,
          proposal_status: accept ? PROPOSAL_STATUS.APPROVED : PROPOSAL_STATUS.REJECTED,
        },
      }));
    },
    [brief_uuid, brief_designer_uuid, queryClient]
  );

  const handleAcceptSubmit = useCallback(async () => {
    const res = await postBuyerOpenCallsSingleResponse(brief_uuid, brief_designer_uuid, { response: 'accepted' });
    if (!res.success) {
      setMessage('There was an error accepting proposal');
      return setOpenAcceptProposal(false);
    }
    setOpenAcceptProposal(false);
    setOpenHaveAccepted(true);
    updateProposalCache(true);
  }, [brief_uuid, brief_designer_uuid, setMessage, updateProposalCache]);

  const handleRejectSubmit = useCallback(
    async (bool, message) => {
      const res = await postBuyerOpenCallsSingleResponse(brief_uuid, brief_designer_uuid, {
        response: 'rejected',
        rejected_reason: message,
      });
      if (!res.success) {
        setMessage('There was an error rejecting the proposal.');
        return setOpenRejectProposal(false);
      }
      setMessage('You have sucessfully rejected the proposal.');
      updateProposalCache(false);
      setOpenRejectProposal(false);
    },
    [brief_designer_uuid, brief_uuid, setMessage, updateProposalCache]
  );

  const handleCloseAcceptedModal = async () => {
    // refetch data or set the proposal to be accepted
    setOpenHaveAccepted(false);
  };

  const navigateToTerms = () => {
    navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/agreement`);
    handleCloseAcceptedModal();
  };

  const showButtons =
    proposal_status === PROPOSAL_STATUS.PENDING_APPROVAL && project_status !== PROJECT_STATUS.REJECTED;

  const briefDesignerName = useMemo(() => {
    const designer = openCallData?.brief_designer;
    if (designer) {
      return designer.first_name + ' ' + designer.last_name;
    }
    return '';
  }, [openCallData.brief_designer]);

  const handleCancelNegotiation = useCallback(async () => {
    try {
      const res = await postCancelOpenCallsNegotiation(brief_uuid, brief_designer_uuid);
      if (!res.success) {
        setOpenConfirmCancel(null);
        throw res;
      }
      setMessage('You have cancelled this negotiation');
      navigate(`${routes.openCallsDashboard}/${brief_uuid}`);
    } catch (err) {
      setMessage('There  was a problem cancelling this negotiation. Please try again');
    }
  }, [brief_designer_uuid, brief_uuid, navigate, setMessage]);

  return (
    <>
      <BuyerTabSectionsWrapper
        breadcrumbs={
          <Breadcrumbs
            links={[
              { label: 'Open Calls', onClick: () => navigate(routes.openCallsDashboard) },
              { label: openCallName, onClick: () => navigate(`${routes.openCallsDashboard}/${brief_uuid}`) },
            ]}
            currentPage={capitalize(pathName?.replace('-', ' ') || '')}
          />
        }
        title={openCallName}
        headerMenuOptions={[
          ...(can_cancel_negotiation
            ? [
                {
                  buttonTitle: 'Cancel Negotiation',
                  onClick: () => setOpenConfirmCancel(true),
                  icon: <Close fontSize="small" />,
                },
              ]
            : []),
          {
            buttonTitle: showMessages ? 'Hide Messages' : 'Show Messages',
            icon: showMessages ? <SpeakerNotesOffIcon fontSize="small" /> : <ChatIcon fontSize="small" />,
            onClick: toggleMessages,
          },
          {
            buttonTitle: 'Back',
            icon: <ArrowBackIcon fontSize="small" />,
            onClick: () => navigate(`${routes.openCallsDashboard}`),
            animate: true,
          },
          ...(showCompleteProject
            ? [
                {
                  buttonTitle: 'Complete Project',
                  icon: <DoneIcon fontSize="small" />,
                  onClick: () => setOpenCompleteProject(true),
                  colour: 'black',
                },
              ]
            : []),
          ...(showCheckoutButton
            ? [
                {
                  buttonTitle: 'Checkout',
                  icon: isCheckingOut ? <Spinner size="20px" /> : <ShoppingCartIcon fontSize="small" />,
                  onClick: () => handleOnCheckout(),
                  colour: 'black',
                },
              ]
            : []),
        ]}
        chatbox={
          <ChatBox
            chat_uuid={chat_uuid}
            setMessage={setMessage}
            getChatMessages={handleGetChatMessages}
            postChatMessage={handlePostChatMessages}
            downloadFiles={handleChatDownloadFiles}
            uploadFiles={handleChatUploadFiles}
            deleteFiles={handleChatDeleteFiles}
            userType={userType}
            expand={showMessages}
            setExpand={toggleMessages}
            onAcceptClick={showButtons ? onAccept : null}
            onRejectClick={showButtons ? onReject : null}
            acceptBtnText={'Connect with designer'}
            rejectBtnText={'Decline Proposal'}
          />
        }
        activeTab={routes.openCallsDashboard}
        handleSectionClick={handleSectionClick}
        collapse={showMessages}
        options={options.map((option) => {
          if (option.path === '/proposal') {
            return {
              ...option,
              disabled: isProposalTabDisabled,
            };
          }
          if (option.path === '/agreement') {
            return {
              ...option,
              disabled: isAgreementTabDisabled,
            };
          }
          if (option.path === '/files') {
            return { ...option, disabled: isFilesTabDisabled };
          }
          return option;
        })}
      >
        {isLoading && (
          <Box mt={2}>
            <Spinner align="center" />
          </Box>
        )}{' '}
        {!isLoading && (
          <>
            <TopBarTitleStatus name={briefDesignerName} project_status={project_status} />
            {!isLoading && isProjectCompleted && (
              <Box mt={2}>
                <BodyText text="This project has been completed." />
              </Box>
            )}
            <ScrollableContainer collapse={showMessages}>
              <Outlet context={[{ ...openCallData }, setMessage, userType, onAccept, onReject]} />
            </ScrollableContainer>
          </>
        )}
        {openCompleteProject && (
          <ModalBriefProposalComplete
            open={openCompleteProject}
            handleClose={() => setOpenCompleteProject(false)}
            onSubmit={handleOnComplete}
          />
        )}
        {openCongratulations && (
          <ModalCongratulations
            handleClose={() => setOpenCongratulations(false)}
            action={() => navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/review`)}
            text="You have completed the project!"
            title="Project"
            open={openCongratulations}
          />
        )}
      </BuyerTabSectionsWrapper>
      {openAcceptProposal && (
        <ModalConfirmAccept
          open={openAcceptProposal}
          name="proposal"
          titleOverride="Connect with Designer"
          textOverride={
            "Please confirm you want to connect with this designer. After you've confirmed you can continue to develop the work together."
          }
          handleSubmit={handleAcceptSubmit}
          handleClose={() => setOpenAcceptProposal(false)}
        />
      )}
      {openHaveAccepted && (
        <ModalAcceptProposal
          open={openHaveAccepted}
          handleClose={() => handleCloseAcceptedModal()}
          navigateAction={navigateToTerms}
        />
      )}
      {openRejectProposal && (
        <ModalRejectBrief
          open={openRejectProposal}
          handleClose={() => setOpenRejectProposal(false)}
          handleSubmit={handleRejectSubmit}
          variant="open-call-proposal"
          designer_name={designer_name}
          item_name={item_name}
          company_name={company_name}
        />
      )}
      {openConfirmCancel && (
        <ModalConfirmAccept
          open={openConfirmCancel}
          name="proposal"
          handleSubmit={handleCancelNegotiation}
          handleClose={() => setOpenConfirmCancel(false)}
          titleOverride="Cancel Negotiation"
          textOverride={'Please confirm you want to cancel this negotiation.'}
        />
      )}
    </>
  );
};

BuyerOpenCallsSingleCallLanding.propTypes = {};

export default BuyerOpenCallsSingleCallLanding;
