import Bathroom from 'assets/pngs/Bathroom.png';
import Bedroom from 'assets/pngs/Bedroom.png';
import Dining from 'assets/pngs/Dining.png';
import Hallway from 'assets/pngs/Hallway.png';
import Kids from 'assets/pngs/Kids.png';
import Kitchen from 'assets/pngs/Kitchen.png';
import Laundry from 'assets/pngs/Laundry.png';
import Living from 'assets/pngs/Living.png';
import Office from 'assets/pngs/Office.png';
import Outdoor from 'assets/pngs/Outdoor.png';

export const BuyerLandingData = {
  buyerHomeFeaturedProducts: {
    title: 'Featured Products',
    bodyText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Cursus metus aliquam eleifend mi. In mollis nunc sed id semper risus in. Ornare aenean euismod elementum nisi quis eleifend quam. Vel pretium lectus quam id leo in. Scelerisque fermentum dui faucibus in ornare quam. Egestas tellus rutrum tellus pellentesque eu tincidunt tortor. ',
    linkText: 'Discover new products',
    linkOnClick: () => console.log('link on click'),
    imageFileType: 'image',
    imageUrl:
      'https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',
  },
  mockCarouselData: Array(10).fill({
    isStory: true,
    imageUrl:
      'https://images.unsplash.com/photo-1592078615290-033ee584e267?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80',
    label: 'Daniil Silantev',
    subtitle: 'Manchester, UK',
    onClick: () => console.log('clicked!'),
  }),
  buyerHomeDiscoverByRoom: [
    {
      title: 'Bathroom',
      imageUrl: Bathroom,
      link: '/products/spaces/bathroom',
    },
    {
      title: 'Bedroom',
      imageUrl: Bedroom,
      link: '/products/spaces/bedroom',
    },
    {
      title: 'Dining',
      imageUrl: Dining,
      link: '/products/spaces/dining',
    },
    {
      title: 'Outdoor',
      imageUrl: Outdoor,
      link: '/products/spaces/outdoor',
    },
    {
      title: 'Hallway',
      imageUrl: Hallway,
      link: '/products/spaces/hallway',
    },
    {
      title: 'Kitchen',
      imageUrl: Kitchen,
      link: '/products/spaces/kitchen',
    },
    {
      title: 'Living',
      imageUrl: Living,
      link: '/products/spaces/living',
    },
    {
      title: 'Playroom & Nursery',
      imageUrl: Kids,
      link: '/products/spaces/playroom-&-nursery',
    },
    {
      title: 'Workspace',
      imageUrl: Office,
      link: '/products/spaces/workspace',
    },
  ],
  featuredDesigners: {
    title: 'Featured Designers',
    bodyText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Cursus metus aliquam eleifend mi. In mollis nunc sed id semper risus in. Ornare aenean euismod elementum nisi quis eleifend quam.',
    designers: Array(3).fill({
      isStory: true,
      imageUrl:
        'https://images.unsplash.com/photo-1587398396609-5bc29d58a096?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjF8fGhlYWRzaG90JTIwYmxhY2slMjBhbmQlMjB3aGl0ZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60',
      label: 'Helena Taylor',
      subtitle: 'London, UK',
      onClick: () => console.log('click'),
    }),
  },
};
