import React from 'react';
import PropTypes from 'prop-types';
import { FieldLabelStyled } from './FieldLabel.styles';

const FieldLabel = ({ text, bold, fontSize = 'medium' }) => {
  return (
    <FieldLabelStyled variant="body1" bold={bold} fontSize={fontSize}>
      {text}
    </FieldLabelStyled>
  );
};

FieldLabel.propTypes = {
  text: PropTypes.string,
  bold: PropTypes.bool,
  fontSize: PropTypes.oneOf(['medium', 'large']),
};

export default FieldLabel;
