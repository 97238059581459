import { styled } from '@mui/system';
import { ListItem, ListItemButton, ListItemIcon } from '@mui/material';

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.text.secondary}`,
  '&:first-of-type': {
    borderTop: `1px solid ${theme.palette.text.secondary}`,
  },
  '&:hover': {
    backgroundColor: theme.palette.background.pink,
  },
}));

export const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} 8px ${theme.spacing(0.5)} 0px`,
  '&:hover': {
    backgroundColor: theme.palette.background.pink,
  },
}));

export const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'unset',
}));
