import { styled, TableCell } from '@mui/material';

export const CustomTableHeadCellStyled = styled(TableCell)(({ theme }) => ({
  ...theme.components.MuiTableHeadCell.styleOverrides.root,
  padding: `0px 10px`,
  position: 'relative',
  // hack so borders apply properly on safari
  '::before': {
    content: "''",
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    boxShadow: `inset 0px -1px 0px ${theme.palette.common.black}`,
    width: '100%',
    height: '48px',
  },
}));
