import { styled, Box } from '@mui/material';

export const StyledSignUpWrapper = styled(Box)(({ theme, sx }) => ({
  width: '100%',
  margin: '0 auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));
