import { styled, Typography } from '@mui/material';

export const NotificationStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.notification.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '100%',
  width: '15px',
  height: '15px',
}));

export const NotificationText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  fontSize: '10px',
}));
