import React, { useCallback } from 'react';
import { Box } from '@mui/material';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import ProductPropertyListGroup from 'components/ProductPropertyListGroup/ProductPropertyListGroup';
import BodyText from 'components/Typography/BodyText/BodyText';
import Spinner from 'components/Spinner/Spinner';

export const ProductPropertiesFormMobile = ({
  listOptions,
  onClick,
  currentHeader,
  categoriesSelected,
  isLoading,
  isStory,
}) => {
  const headerObjectList = [currentHeader, ...categoriesSelected];
  // list shown will always be last list from listOptions
  const currentList = listOptions?.length ? [listOptions[listOptions.length - 1]] : listOptions;

  const linkOnClick = useCallback(
    ({ label, value }, listIndex) => {
      onClick({ label, value, listIndex, has_nested: true });
    },
    [onClick]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" flexWrap="wrap">
        {headerObjectList.map((header, index) => {
          const addedText = index + 1 === headerObjectList.length ? '' : '>';
          return (
            <Box display="flex" key={header.value}>
              <CustomStandardLink
                onClick={() => linkOnClick(header, index)}
                text={`${header.label}`}
                linkType="primary"
                fontSize="small"
              />
              {addedText && (
                <Box mx={1}>
                  <BodyText text={addedText} fontSize="small" />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      <Box display="flex" flexDirection="column">
        {isLoading && !isStory && (
          <Box mt={2} minHeight="100px">
            <Spinner flex={0} align="center" />{' '}
          </Box>
        )}
        {!isLoading &&
          currentList?.map((options, index) => (
            <ProductPropertyListGroup options={options} onClick={onClick} listIndex={index} key={`list_${index}`} />
          ))}
      </Box>
    </Box>
  );
};
