import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';

import { getSingleTerm } from 'api';
import BriefSummary from '../BriefSummary';
import { parseRemoteData as parseRemoteTermsData } from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/utils/BuyerTermsNew.utils';

const ControlledBriefSummary = ({ userType, disableTerms, isActive }) => {
  const { getValues } = useFormContext();
  const briefDetails = getValues();

  const { selected_terms_uuid: terms_uuid } = briefDetails || {};

  const { data: termsData } = useQuery(
    ['buyerSingleTerm', terms_uuid],
    () => (terms_uuid ? getSingleTerm(terms_uuid) : {}),
    {
      enabled: !disableTerms,
      staleTime: 5 * 60 * 1000,
    }
  );

  const parsedTermsData = (termsData?.terms && parseRemoteTermsData(termsData.terms)) || {};

  return (
    <BriefSummary
      briefDetails={{ ...briefDetails, ...parsedTermsData }}
      userType={userType}
      disableTerms={disableTerms}
      isActive={isActive}
    />
  );
};

export default ControlledBriefSummary;
