import PropTypes from 'prop-types';

import NavBar from '../../NavBar/NavBarStandard/NavBarStandard';
import NavBarDesigner from '../../NavBar/NavBarDesigner/NavBarDesigner';
import NavBarBuyer from '../../NavBar/NavBarBuyer/NavBarBuyer';
import PrimaryFooter from '../../Footer/PrimaryFooter/PrimaryFooter';
import SecondaryFooter from '../../Footer/SecondaryFooter/SecondaryFooter';

import { userTypes } from 'options/userTypes';

import { StyledPageContainer, BodyContainer, FooterContainer } from './PageWrapper.styles';

const PageWrapper = ({ children, userType, showSecondaryFooter, collapse }) => {
  const getNavbarComponent = () => {
    switch (userType) {
      case 'unauthenticated':
        return <NavBar />;
      case userTypes.designer:
        return <NavBarDesigner />;
      case userTypes.buyer:
      case userTypes.buyerAdmin:
      case userTypes.buyerUser:
        return <NavBarBuyer />;
      default:
        return <NavBar />;
    }
  };

  return (
    <StyledPageContainer collapse={collapse ? String(collapse) : undefined}>
      {getNavbarComponent()}
      <BodyContainer>{children}</BodyContainer>
      <FooterContainer>
        {showSecondaryFooter && <SecondaryFooter />}
        <PrimaryFooter variant={showSecondaryFooter ? null : 'primary'} />
      </FooterContainer>
    </StyledPageContainer>
  );
};

PageWrapper.propTypes = {
  userType: PropTypes.oneOf(['unauthenticated', ...Object.values(userTypes), '']),
  showSecondaryFooter: PropTypes.bool.isRequired,
};

export default PageWrapper;
