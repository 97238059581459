import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router';

import { Box } from '@mui/system';
import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import ProfileDetails from 'components/ProfileDetails/ProfileDetails';
import OpenBriefsAccordionsContainer from 'components/OpenBriefsAccordionsContainer/OpenBriefsAccordionsContainer';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import BodyText from 'components/Typography/BodyText/BodyText';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import Spinner from 'components/Spinner/Spinner';

import { OPTIONS_TYPE } from 'options/selectOptions';
import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';

import { addFilters } from 'utils/addFilters';
import { DateObjFromUKDateStr } from 'utils/parseDates';

import { LoadingContainer } from 'components/ProductsMegaMenu/ProductsMegaMenu.styles';
import { OpenCallAccordionLoadingContainer } from './DesignerBuyerProfile.styles';

import { getBuyerProfile, getBuyersOpenCalls, getChildOptions } from 'api';

import { useTableFilter } from 'hooks/useTableFilter';

const DesignerBuyerProfile = () => {
  const [buyer, setBuyer] = useState(null);
  const { company_uuid } = useParams();
  const { order, orderBy, filter, setFilter, onRequestSort } = useTableFilter({
    initialOrder: null,
    initialOrderBy: null,
  });

  const navigate = useNavigate();
  const fetchBuyer = useCallback(async () => {
    try {
      if (company_uuid) {
        const res = await getBuyerProfile(company_uuid);
        setBuyer(res.buyer_profile);
      }
    } catch (error) {
      console.error(error);
    }
  }, [company_uuid]);

  useEffect(() => {
    fetchBuyer();
    window.scroll(0, 0);
  }, [fetchBuyer]);

  const {
    data: { options: categories },
  } = useQuery(['categories', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const fetchOpenCalls = useQuery(['allBuyerOpenCalls', buyer], () => getBuyersOpenCalls(company_uuid), {
    enabled: !!buyer,
    staleTime: 5 * 60 * 1000,
    refetchOnMount: 'always',
  });

  const buyersOpenCalls = useMemo(() => fetchOpenCalls?.data?.open_calls || null, [fetchOpenCalls?.data?.open_calls]);

  const filteredAndSorted = useMemo(() => {
    if (!buyersOpenCalls) return null;
    let mutatedArray = buyersOpenCalls;
    if (!!filter?.categories?.length > 0) {
      mutatedArray = mutatedArray.filter((item) =>
        item.filter_categories.some((i) => filter.categories.includes(i.slug))
      );
    }
    if (order && orderBy) {
      switch (orderBy) {
        case 'deadline':
          if (order === 'asc') {
            mutatedArray.sort(
              (a, b) => DateObjFromUKDateStr(b.deadline).getTime() - DateObjFromUKDateStr(a.deadline).getTime()
            );
          }
          if (order === 'desc') {
            mutatedArray.sort(
              (a, b) => DateObjFromUKDateStr(a.deadline).getTime() - DateObjFromUKDateStr(b.deadline).getTime()
            );
          }

          break;
        case 'name':
          if (order === 'asc') {
            mutatedArray.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
          }
          if (order === 'desc') {
            mutatedArray.sort((a, b) => (b.title.toLowerCase() < a.title.toLowerCase() ? -1 : 1));
          }
          break;
        default:
          break;
      }
    }
    return mutatedArray;
  }, [order, orderBy, buyersOpenCalls, filter?.categories?.length]);

  const buyerProfileInfo = useMemo(() => {
    if (!buyer) return null;
    return {
      uuid: buyer.uuid,
      first: buyer.name,
      last: '',
      bio: buyer.about,
      portrait: buyer.logo && buyer.logo.fileSrc ? buyer.logo.fileSrc : null,
      city: buyer.city,
      country: buyer.country,
      website: buyer.website,
      instagram: buyer.instagram,
      linkedin: buyer.linkedin,
      pinterest: buyer.pinterest,
      twitter: buyer.twitter,
      products: buyer.product_interests,
      joined: buyer.date_joined,
    };
  }, [buyer]);

  const filtersList = useMemo(
    () => ({
      categories: [...categories],
    }),
    [categories]
  );

  const menuFilterOptions = useMemo(() => {
    const FIND_BUYER_MENU = [
      {
        label: 'Name',
        id: 'name',
        actions: [],
        sortable: true,
      },
      {
        label: 'Deadline',
        id: 'deadline',
        actions: [],
        sortable: true,
      },
      {
        label: 'Categories',
        id: 'categories',
      },
    ];
    return FIND_BUYER_MENU.map((menuItem) => {
      if (menuItem.sortable)
        return {
          ...menuItem,
        };
      else
        return {
          ...menuItem,
          actions: addFilters(menuItem, filtersList, setFilter, filter),
        };
    });
  }, [filter, filtersList]);

  const proposeBuyer = useCallback(() => {
    navigate(routes.pitchesAdd, {
      state: {
        buyers: [buyer],
      },
    });
  }, [buyer, navigate]);

  const noResultsMessage = () => {
    if (buyersOpenCalls?.length === 0) return `${buyerProfileInfo.first} hasn't got open calls`;
    if (buyersOpenCalls?.length > 0 && filteredAndSorted.length === 0)
      return `${buyerProfileInfo.first} hasn't got any open calls match this search criteria`;
  };

  return (
    <PageWrapper showSecondaryFooter={false} userType={userTypes.designer}>
      <PageContentWrapper breadcrumbsMargin>
        {!buyer && (
          <LoadingContainer>
            <Spinner align={'center'} />
          </LoadingContainer>
        )}
        {buyer && buyerProfileInfo && (
          <>
            <Box mb={4}>
              <Breadcrumbs
                currentPage={buyerProfileInfo.first}
                links={[{ label: 'Companies', onClick: () => navigate(routes.buyersIndex) }]}
              />
            </Box>

            <ProfileDetails
              proposeBuyer={proposeBuyer}
              viewType={userTypes.designer}
              profileDetails={buyerProfileInfo}
            />
            <SearchAndFilterOptions
              onRequestSort={onRequestSort}
              orderBy={orderBy}
              order={order}
              title={`Open Calls (${buyersOpenCalls?.length || '...'})`}
              menuOptions={menuFilterOptions}
            />
            {fetchOpenCalls.isLoading && (
              <OpenCallAccordionLoadingContainer>
                <Spinner align={'center'} />
              </OpenCallAccordionLoadingContainer>
            )}
            {!fetchOpenCalls.isLoading && filteredAndSorted?.length > 0 && (
              <OpenBriefsAccordionsContainer
                role={userTypes.designer}
                openBriefsData={fetchOpenCalls.data.open_calls}
              />
            )}
            {!fetchOpenCalls.isLoading && (!filteredAndSorted || filteredAndSorted?.length === 0) && (
              <Box style={{ minHeight: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <BodyText text={noResultsMessage()} />
              </Box>
            )}
          </>
        )}
      </PageContentWrapper>
    </PageWrapper>
  );
};

DesignerBuyerProfile.propTypes = {};

export default DesignerBuyerProfile;
