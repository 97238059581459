import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';

import { Box } from '@mui/system';
import Pagination from '@mui/material/Pagination';
import Spinner from 'components/Spinner/Spinner';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';
import BuyerPitchesList from 'components/BuyerPitchesList/BuyerPitchesList';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';
import ModalRejectBrief from 'components/ModalRejectBrief/ModalRejectBrief';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';

import { routes } from 'options/routes';
import { PROJECT_STATUS, PROJECT_STATUS_FILTER } from 'options/projectStatus';
import { buyerPitchesListHeadings } from 'options/tableOptions';
import { CATEGORIES_OPTIONS } from 'options/findDesignerOptions';
import { BUYER_PITCH_PILL_STATUS_TEXT } from 'options/pillStatusOptions';
import { userTypes } from 'options/userTypes';
import { PaginationWrapper } from 'components/ProductsAll/ProductsAll.styles';
import { PITCHES_PER_PAGE } from 'options/pagination';

import { addFilters } from 'utils/addFilters';

import { MOCK_PRODUCT_LIST_BUYER } from 'mockData/mockProduct';

import { StyledContainer } from './BuyerPitchesListDashboard.styles';

import { useTableFilter } from 'hooks/useTableFilter';

import { useMessageContext } from 'context/MessageContext';
import { useAuthContext } from 'context/AuthContext';

import { getBuyersPitchesList, postBuyersSinglePitch } from 'api';

export const BuyerPitchesListDashboard = ({ isStory }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { parsedFilter, setFilter, filter, hasFilter, ...tableMethods } = useTableFilter({
    initialOrder: 'desc',
    initialOrderBy: 'name',
    isTablePagination: false,
  });

  const { onPageChange, p } = tableMethods;

  const { company_name, role } = useAuthContext();

  useEffect(() => {
    if (role !== userTypes.buyerAdmin) {
      navigate(routes.home);
    }
  }, [navigate, role]);

  const { data, isLoading, isRefetching } = useQuery(
    ['buyersPitchesList', parsedFilter],
    () => getBuyersPitchesList(parsedFilter),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
    }
  );

  const [selectedRejectPitch, setSelectedRejectPitch] = useState(null);
  const [setMessage] = useMessageContext();

  const pitchesList = useMemo(() => data?.pitches || [], [data?.pitches]);
  const totalCount = data?.total || 0;
  const pageCount = useMemo(() => (totalCount ? Math.ceil(totalCount / PITCHES_PER_PAGE) : null), [totalCount]);

  const updatePitchCache = useCallback(
    (selectedProductUUID) => {
      queryClient.setQueryData(['buyerSinglePitch', selectedRejectPitch?.product_uuid], (oldData) => {
        if (oldData?.pitch && oldData?.pitch?.product_uuid === selectedProductUUID) {
          return {
            pitch: {
              ...oldData.pitch,
              project_status: PROJECT_STATUS.REJECTED,
            },
            success: true,
          };
        }
      });
      queryClient.setQueryData(['buyersPitchesList', parsedFilter], (oldData) => {
        return {
          pitches: oldData?.pitches.filter((pitch) => pitch?.product_uuid !== selectedProductUUID),
          total: (oldData?.total || 0) - 1,
          success: true,
        };
      });
    },
    [selectedRejectPitch, queryClient, parsedFilter]
  );

  const handleRejectSubmit = useCallback(
    // could refactor this later, bool is not needed here
    async (bool, message) => {
      const res = await postBuyersSinglePitch(selectedRejectPitch?.product_uuid, {
        response: 'rejected',
        rejected_reason: message,
      });
      if (!res.success) {
        setMessage('There was an error rejecting the pitch.');
        return setSelectedRejectPitch('');
      }
      updatePitchCache(selectedRejectPitch?.product_uuid);
      setMessage('You have sucessfully rejected the pitch.');
    },
    [selectedRejectPitch, setMessage, updatePitchCache]
  );

  const filtersList = useMemo(
    () => ({
      main_categories: [...CATEGORIES_OPTIONS],
      statuses: [...Object.values(PROJECT_STATUS_FILTER)],
    }),
    []
  );

  const menuOptions = useMemo(
    () =>
      buyerPitchesListHeadings.map((menuItem) =>
        !menuItem.sortable
          ? {
              ...menuItem,
              menuOptions: addFilters(menuItem, filtersList, setFilter, filter, BUYER_PITCH_PILL_STATUS_TEXT),
            }
          : menuItem
      ),
    [filter, filtersList, setFilter]
  );

  const loading = isLoading || isRefetching;

  return (
    <BuyerDashboardWrapper activeTab={routes.pitchesDashboard}>
      <StyledContainer>
        <Box display="flex" alignItems="center">
          <CustomTitle text={'Pitches'} variant="h2" />
        </Box>
      </StyledContainer>
      <SearchAndFilterOptions menuOptions={menuOptions} title=" " hasBorder {...tableMethods} />
      {loading && !pitchesList.length && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}

      <Box display="flex" justifyContent="center" width="100%">
        {!!pitchesList.length && (
          <BuyerPitchesList
            pitchesList={pitchesList}
            mockData={MOCK_PRODUCT_LIST_BUYER}
            isStory={false}
            pagination={tableMethods}
            totalCount={totalCount}
            handleRejectPitch={setSelectedRejectPitch}
          />
        )}
        {!pitchesList.length && hasFilter && !loading && (
          <Box width="100%" display="flex" justifyContent="center">
            <NoFilteringMatch item="pitch" />
          </Box>
        )}
      </Box>

      {!loading && !pitchesList.length && !hasFilter && (
        <Box width="100%" display="flex" justifyContent="center" my={2}>
          <BodyText text="You have no pitches yet." />
        </Box>
      )}
      {!isLoading && totalCount > PITCHES_PER_PAGE && (
        <PaginationWrapper marginTop="16px !important" marginBottom={3}>
          <Pagination showFirstButton showLastButton page={p} count={pageCount} onChange={onPageChange} />
        </PaginationWrapper>
      )}
      {!!selectedRejectPitch && (
        <ModalRejectBrief
          open={!!selectedRejectPitch}
          handleClose={() => setSelectedRejectPitch('')}
          handleSubmit={handleRejectSubmit}
          variant="pitch"
          designer_name={selectedRejectPitch?.designer_name}
          item_name={selectedRejectPitch?.name}
          company_name={company_name}
        />
      )}
    </BuyerDashboardWrapper>
  );
};

export default BuyerPitchesListDashboard;
