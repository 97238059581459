import React from 'react';
import PropTypes from 'prop-types';
import {
  StyledBodyTextWrapper,
  StyledBuyerHomeFeaturedDesignersWrapper,
  StyledDesignersWrapper,
} from './BuyerHomeFeaturedDesigners.styles';
import { Box } from '@mui/system';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';
import FeaturedProfileTile from 'components/FeaturedProfileTile/FeaturedProfileTile';

const BuyerHomeFeaturedDesigners = ({ noBodyText, data }) => {
  if (!data || !data?.length) return null;
  return (
    <StyledBuyerHomeFeaturedDesignersWrapper>
      <Box mb={4}>
        <CustomTitle variant="h2" text={'Featured Designers'} />
      </Box>
      {!noBodyText && (
        <StyledBodyTextWrapper mb={4}>
          <BodyText
            text={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Cursus metus aliquam eleifend mi. In mollis nunc sed id semper risus in. Ornare aenean euismod elementum nisi quis eleifend quam.'
            }
          />
        </StyledBodyTextWrapper>
      )}
      {data && data.length > 0 && (
        <StyledDesignersWrapper>
          {data.map((designer, i) => {
            return <FeaturedProfileTile aspect1to1={true} key={`feat-designer-${designer.label}_${i}`} {...designer} />;
          })}
        </StyledDesignersWrapper>
      )}
    </StyledBuyerHomeFeaturedDesignersWrapper>
  );
};

BuyerHomeFeaturedDesigners.propTypes = {
  data: PropTypes.shape({
    designer: PropTypes.arrayOf(
      PropTypes.shape({
        onClick: PropTypes.func,
        imageUrl: PropTypes.string.isRequired,
        label: PropTypes.string,
        subtitle: PropTypes.string,
      })
    ),
    title: PropTypes.string,
    bodyText: PropTypes.string,
  }),
  imageHeights: PropTypes.shape({
    desktop: PropTypes.string,
    tablet: PropTypes.string,
    mobile: PropTypes.string,
  }),
};

export default BuyerHomeFeaturedDesigners;
