import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ErrorMessage, TextFieldStyled } from '../TextFields/CustomTextField/CustomTextField.styles';
import { AutoCompleteStyled } from './CustomAutoCompleteField.styles';

const CustomAutoCompleteField = ({
  label,
  options,
  value,
  onChange,
  onBlur,
  success,
  error,
  fieldStyle = 'primary',
}) => {
  return (
    <AutoCompleteStyled
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      options={options}
      autoHighlight
      getOptionLabel={(option) => option}
      popupIcon={<KeyboardArrowDownIcon />}
      renderInput={(params) => {
        return (
          <Box display="flex" flexDirection="column">
            <TextFieldStyled
              {...params}
              variant="outlined"
              label={label}
              inputProps={{
                ...params.inputProps,
              }}
              error={!!error}
              color={success ? 'success' : 'primary'}
              fieldStyle={fieldStyle}
              fullWidth
            />
            {error &&
              <Box height="20px">{error && <ErrorMessage>{error}</ErrorMessage>}</Box>
            }
          </Box>
        );
      }}
    />
  );
};

CustomAutoCompleteField.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  success: PropTypes.bool,
  error: PropTypes.string,
};

export default CustomAutoCompleteField;
