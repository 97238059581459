import PropTypes from 'prop-types';

import { Grid, useMediaQuery, Box } from '@mui/material';
import SectionTabsList from 'components/SectionTabsList/SectionTabsList';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import BuyerDashboardWrapper from '../../DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import { StyledTabsSectionContainer } from '../TabsSectionStyles';

const BuyerTabSectionsWrapper = ({
  title,
  headerMenuOptions,
  children,
  breadcrumbs,
  activeTab,
  chatbox,
  options,
  handleSectionClick,
  collapse,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Box>
      <BuyerDashboardWrapper activeTab={activeTab} breadcrumbs={breadcrumbs} collapse={collapse}>
        <HeaderAndMenu title={title} menuOptions={headerMenuOptions} />
        <Grid container direction={isMobile ? 'column' : 'row'} position="relative" pt={'0 !important'}>
          <StyledTabsSectionContainer md={2} item>
            <SectionTabsList
              onClick={handleSectionClick}
              options={options}
              orientation={isMobile ? 'horizontal' : 'vertical'}
            />
          </StyledTabsSectionContainer>
          <Grid md={10} pt={'0 !important'} item sx={{ paddingLeft: { sm: 0, md: 2 } }}>
            {children}
          </Grid>
        </Grid>
      </BuyerDashboardWrapper>
      {chatbox}
    </Box>
  );
};

BuyerTabSectionsWrapper.propTypes = {
  title: PropTypes.string,
  headerMenuOptions: PropTypes.array,
  breadcrumbs: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  activeTab: PropTypes.string,
  chatbox: PropTypes.node,
  options: PropTypes.array,
  handleSectionClick: PropTypes.func,
};

export default BuyerTabSectionsWrapper;
