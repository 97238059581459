import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const FormParagraph = ({ text, variant }) => {
  const textVariant = variant === 'secondary' ? 'body2' : 'body1';
  return <Typography variant={textVariant}>{text}</Typography>;
};

FormParagraph.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default FormParagraph;
