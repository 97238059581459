import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { pulsate } from 'styles/animations';

export const IconButtonStyled = styled(IconButton, {
  shouldForwardProp: (props) => props !== 'hoverPeachBg' && props !== 'hasIcon',
})(({ theme, text, colour, disabled, animate, hoverPeachBg, hasIcon }) => ({
  borderRadius: text ? theme.shape.borderRadius * 5 : '50%',
  padding: colour === 'black' ? '8px 12px!important' : `6px 6px !important`,
  backgroundColor: colour === 'black' ? theme.palette.primary.main : '',
  '& svg, & p': {
    color:
      colour === 'black'
        ? theme.palette.common.white
        : disabled
        ? theme.palette.text.secondary
        : theme.palette.common.black,
  },
  '&:hover': {
    backgroundColor: hoverPeachBg ? theme.palette.common.peach : '',
    '& svg, & p': {
      color: colour === 'black' ? theme.palette.common.black : '',
    },
  },
  '&:hover  svg': {
    animation: animate ? `${pulsate} 1s infinite ease` : '',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0.5),
  },
}));

export const IconButtonTextStyled = styled(Typography, {
  shouldForwardProp: (props) => props !== 'icon' && props !== 'fontWeight',
})(({ theme, icon, breakpoint, disabled, fontWeight }) => ({
  color: disabled ? theme.palette.text.secondary : theme.palette.common.black,
  fontWeight: fontWeight,
  marginLeft: icon ? theme.spacing(1) : '',
  [theme.breakpoints.down(breakpoint)]: {
    display: 'none',
  },
}));
