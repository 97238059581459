import PageLoading from 'components/PageLoading/PageLoading';
import { wpRoutes } from 'options/routes';
import { useEffect, useCallback } from 'react';

import { isAnyBuyer } from 'utils/isAnyBuyer';

const HomeRedirect = ({ role }) => {
  const handleRedirect = useCallback(() => {
    if (isAnyBuyer(role)) {
      window.location.href = wpRoutes.discoverDesigners;
    } else {
      window.location.href = wpRoutes.discoverBuyers;
    }
  }, [role]);

  useEffect(() => {
    handleRedirect();
  }, [handleRedirect]);

  return <PageLoading />;
};

export default HomeRedirect;
