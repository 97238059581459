import React from 'react';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ControlledCustomFiledUpload from 'components/CustomFileUpload/ControlledCustomFileUpload/ControlledCustomFileUpload';
import FormFieldSetAndInputWrapper from 'components/Wrappers/FormFieldSetAndInputWrapper/FormFieldSetAndInputWrapper';
import ControlledRichTextEditor from 'components/CustomRichTextEditor/ControlledRichTextEditor/ControlledRichTextEditor';

const NewTermsForm = ({ isModal }) => {
  return (
    <div>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Terms & Conditions name"
          description="Give this Terms & Conditions a name so you can easily add it to future briefs"
          marginTop={isModal ? 3 : 4}
        />
        <ControlledTextField name="agreement_title" placeholder="Give this Terms and Conditions document a name" />
      </FormFieldSetAndInputWrapper>

      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Terms details"
          description="Please list your Terms and Conditions here, or upload a document below. You can copy and paste your existing agreement text and format."
          marginTop={isModal ? 3 : 4}
        />
        <ControlledRichTextEditor name="agreement_body" />
      </FormFieldSetAndInputWrapper>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="Terms and Conditions document"
          description="You can upload here your company terms and conditions with all your information.  Please provide the terms and conditions in PDF format. This, will be attached to the final document for signing."
          marginTop={isModal ? 3 : 4}
        />
        <ControlledCustomFiledUpload
          variant="single"
          numberOfTiles={1}
          fileTypes="pdf"
          name={'agreement_file'}
          addFileText="Add a PDF"
        />
      </FormFieldSetAndInputWrapper>
    </div>
  );
};

NewTermsForm.propTypes = {};

export default NewTermsForm;
