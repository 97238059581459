import { styled, Grid } from '@mui/material';

export const StyledTabsSectionContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flex: 1,
  },
  borderRight: `1px solid ${theme.palette.common.black}`,
  paddingTop: `0 !important`,
}));

export const StyledChildrenContainer = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    flex: 1,
  },
}));
