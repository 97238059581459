import React from 'react';
import propTypes from 'prop-types';
import { CustomAvatarStyled, CustomAvatarWrapper } from './CustomAvatar.styles';
import BodyText from 'components/Typography/BodyText/BodyText';

const CustomAvatar = ({ imageUrl, name, fileType }) => {
  const src = fileType === 'url' ? atob(imageUrl) : imageUrl;
  return (
    <CustomAvatarWrapper>
      <CustomAvatarStyled src={src} alt={name} name={name} />
      {name && <BodyText text={name} />}
    </CustomAvatarWrapper>
  );
};

CustomAvatar.propTypes = {
  imageUrl: propTypes.string.isRequired,
  fileType: propTypes.string.isRequired,
  name: propTypes.string,
};

export default CustomAvatar;
