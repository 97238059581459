import React, { useCallback, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import ChatIcon from '@mui/icons-material/Chat';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import Spinner from 'components/Spinner/Spinner';
import DesignerTabSectionsWrapper from 'components/Wrappers/TabSectionsWrapper/DesignerTabSectionsWrapper/DesignerTabSectionsWrapper';
import ModalCancelItem from 'components/ModalCancelItem/ModalCancelItem';
import ChatBox from 'components/ChatBox/ChatBox';
import TopBarTitleStatus from 'components/TopBarTitleStatus/TopBarTitleStatus';
import ScrollableContainer from 'components/ScrollableContainer/ScrollableContainer';
import BodyText from 'components/Typography/BodyText/BodyText';

import { routes } from 'options/routes';
import { PROJECT_STATUS } from 'options/projectStatus';

import { getErrorMessage } from 'utils/getErrorMessage';
import { capitalize } from 'utils/capitalize';
import { parseRemoteData } from 'pages/Dashboard/Buyer/Briefs/BuyerBriefNew/BuyerBriefNew.utils';
import { getIsAgreementTabDisabled, getIsFilesTabDisabled, getIsProposalTabDisabled } from '../DesignerOpenCalls.utils';

import { options } from './DesignerOpenCallsSingleLanding.data';
import { MOCK_BRIEF_DATA } from 'mockData/mockBrief';

import { useMessageContext } from 'context/MessageContext';

import {
  cancelDesignerOpenCallsProposal,
  deleteDesignerOpenCallsFiles,
  downloadDesignerOpenCallsFiles,
  getBriefChatMessages,
  getDesignerOpenCallsSingle,
  postBriefChatMessage,
  postDesignerOpenCallsFiles,
} from 'api';

const DesignerOpenCallsSingleCallLanding = ({ isStory, userType }) => {
  const { brief_uuid } = useParams();
  const navigate = useNavigate();
  const [openCancel, setOpenCancel] = useState(false);
  const [setMessage] = useMessageContext();

  const [showMessages, setShowMessages] = useState(true);

  const location = useLocation();
  const pathnameArr = location?.pathname.split('/');
  const pathName = pathnameArr[pathnameArr.length - 1];

  const { data, isLoading, isRefetching, refetch } = useQuery(
    ['designerSingleOpenCall', brief_uuid],
    () => getDesignerOpenCallsSingle(brief_uuid),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const openCallData = useMemo(
    () => (isStory ? MOCK_BRIEF_DATA : (data && data?.open_call && parseRemoteData(data?.open_call)) || {}),
    [data, isStory]
  );

  const { agreement_status, proposal_status, project_status } = openCallData;

  const openCallName = openCallData?.title;
  const chat_uuid = openCallData?.chat_uuid || '';
  const can_cancel = data?.can_cancel;

  const handleSectionClick = useCallback(
    (path) => {
      navigate(`${routes.openCallsDashboard}/${brief_uuid}${path}`);
    },
    [navigate, brief_uuid]
  );

  const isProposalTabDisabled = getIsProposalTabDisabled(project_status);
  const isAgreementTabDisabled = getIsAgreementTabDisabled(project_status, proposal_status);
  const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status);

  const isProjectCompleted = project_status === PROJECT_STATUS.COMPLETED;

  const handleGetChatMessages = useCallback(() => {
    return getBriefChatMessages(chat_uuid);
  }, [chat_uuid]);

  const handlePostChatMessages = useCallback((chat_uuid, data) => {
    return postBriefChatMessage(chat_uuid, data);
  }, []);

  const handleChatDownloadFiles = useCallback(
    (data) => {
      return downloadDesignerOpenCallsFiles(brief_uuid, data);
    },
    [brief_uuid]
  );

  const handleChatUploadFiles = useCallback(
    (data) => {
      return postDesignerOpenCallsFiles(brief_uuid, data);
    },
    [brief_uuid]
  );

  const handleChatDeleteFiles = useCallback(
    (file_uuid) => {
      return deleteDesignerOpenCallsFiles(brief_uuid, file_uuid);
    },
    [brief_uuid]
  );

  const toggleMessages = useCallback(() => {
    setShowMessages((prev) => !prev);
  }, []);

  const handleCancelBriefProposal = useCallback(async () => {
    const res = await cancelDesignerOpenCallsProposal(brief_uuid);
    if (!res.success) {
      return setMessage(getErrorMessage(res));
    }
    setMessage('You have successfully cancelled the proposal for this open call.');
    navigate(routes.openCallsDashboard);
    // refetch();
  }, [brief_uuid, refetch]);

  const loading = isLoading || isRefetching;

  return (
    <DesignerTabSectionsWrapper
      breadcrumbs={
        <Breadcrumbs
          links={[
            { label: 'Open Calls', onClick: () => navigate(routes.openCallsDashboard) },
            { label: openCallName, onClick: () => navigate(`${routes.openCallsDashboard}/${brief_uuid}/open-call`) },
          ]}
          currentPage={capitalize(pathName?.replace('-', ' ') || '')}
        />
      }
      title={openCallName}
      headerMenuOptions={[
        ...(can_cancel
          ? [
              {
                buttonTitle: 'Cancel Proposal',
                onClick: () => setOpenCancel(true),
                icon: <Close />,
              },
            ]
          : []),
        {
          buttonTitle: showMessages ? 'Hide Messages' : 'Show Messages',
          icon: showMessages ? <SpeakerNotesOffIcon fontSize="small" /> : <ChatIcon fontSize="small" />,
          onClick: toggleMessages,
        },
        {
          buttonTitle: 'Back',
          icon: <ArrowBackIcon fontSize="small" />,
          onClick: () => navigate(`${routes.openCallsDashboard}`),
          animate: true,
        },
      ]}
      chatbox={
        <ChatBox
          chat_uuid={chat_uuid}
          setMessage={setMessage}
          getChatMessages={handleGetChatMessages}
          postChatMessage={handlePostChatMessages}
          downloadFiles={handleChatDownloadFiles}
          uploadFiles={handleChatUploadFiles}
          deleteFiles={handleChatDeleteFiles}
          userType={userType}
          expand={showMessages}
          setExpand={toggleMessages}
        />
      }
      activeTab={routes.openCallsDashboard}
      collapse={showMessages}
      handleSectionClick={handleSectionClick}
      options={options.map((option) => {
        if (option.path === '/files') {
          return {
            ...option,
            disabled: isFilesTabDisabled,
          };
        }
        if (option.path === '/proposal') {
          return {
            ...option,
            disabled: isProposalTabDisabled,
          };
        }
        if (option.path === '/agreement') {
          return {
            ...option,
            disabled: isAgreementTabDisabled,
          };
        }
        return option;
      })}
    >
      {loading && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}{' '}
      {!loading && (
        <>
          <TopBarTitleStatus name={data?.open_call?.company?.name} project_status={project_status} />
          {!isLoading && isProjectCompleted && (
            <Box mt={2}>
              <BodyText text="This project has been completed." />
            </Box>
          )}
          <ScrollableContainer collapse={showMessages}>
            <Outlet context={[{ ...openCallData }, setMessage, userType]} />
          </ScrollableContainer>
        </>
      )}
      {openCancel && (
        <ModalCancelItem
          open={openCancel}
          handleClose={() => setOpenCancel(false)}
          handleSubmit={handleCancelBriefProposal}
          title="Cancel Proposal"
          description="Cancelling this Proposal will automatically reject the Open Call. This action cannot be undone."
        />
      )}
    </DesignerTabSectionsWrapper>
  );
};

DesignerOpenCallsSingleCallLanding.propTypes = {};

export default DesignerOpenCallsSingleCallLanding;
