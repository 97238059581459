import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { PROJECT_STATUS } from 'options/projectStatus';

export const getIsAgreementTabDisabled = (project_status) => {
  return project_status === PROJECT_STATUS.REJECTED || project_status === PROJECT_STATUS.PENDING;
};

export const getIsFilesTabDisabled = (agreement_status) => {
  return agreement_status !== AGREEMENT_STATUS.PAID;
};

export const getIsProjectCompleted = (project_status) => {
  return project_status === PROJECT_STATUS.COMPLETED;
};

// To convert Pitch to Product:
// remove product_uuid
// add proposal_uuid with old product_uuid
export const parsePitchToConvert = ({ product_uuid, ...pitch }) => ({ ...pitch, proposal_uuid: product_uuid });
