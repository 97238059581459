import { FormControl, InputLabel, Select } from '@mui/material';
import { styled } from '@mui/system';

export const FormControlStyled = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== 'fieldStyle',
})(({ theme, fieldStyle }) => ({
  backgroundColor: fieldStyle === 'primary' ? theme.palette.background.pink : '',
  minHeight: '47px',
  display: 'flex',
  flexFlow: 'row',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(3),
  borderRadius: fieldStyle === 'primary' ? theme.shape.borderRadius * 2 : 0,
  marginTop: 'auto',
  border: `1px solid ${theme.palette.background.pink}`,
  '&:hover, &:active': {
    border: `1px solid ${theme.palette.common.border}`,
  },
}));

export const CustomSelectInputLabelStyled = styled(InputLabel)(({ theme }) => ({
  top: '-13px',
  lineHeight: '1.8em',
  paddingLeft: theme.spacing(2),
  borderRadius: '100px',
  zIndex: 1,
  color: theme.palette.text.secondary,
  '&.Mui-focused': {
    color: `${theme.palette.text.primary}`,
  },
  '& + .MuiInput-root': {
    marginTop: 0,
  },
  '&.Mui-error': {
    color: theme.palette.text.secondary,
  },
}));

export const CustomSelectStyled = styled(Select, {
  shouldForwardProp: (prop) => prop !== 'fieldStyle',
})(({ theme, fieldStyle, error }) => ({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
  borderBottom:
    fieldStyle === 'primary' ? '' : `1px solid ${error ? theme.palette.error.main : theme.palette.common.black}`,

  '&.MuiInput-root .MuiSelect-select': {
    height: '100%',
  },

  '& .MuiSelect-select': {
    width: '100%',
    padding: 0,
    '&:focus': {
      height: '100%',
      backgroundColor: 'unset',
    },
  },

  '&:hover .MuiSelect-icon': {
    backgroundColor: theme.palette.background.grayHover,
    borderRadius: '100%',
  },

  '& .MuiOutlinedInput-root': {
    lineHeight: '1.8em',
  },

  '& .MuiSvgIcon-root': {
    position: 'absolute',
    right: theme.spacing(1),
    color: theme.palette.common.black,
  },

  '&::before, :hover:not(.Mui-disabled):before': {
    border: 0,
  },

  '&:after': {
    border: 0,
  },

  '&.Mui-error': {
    border: `1px solid ${theme.palette.error.main}`,
    borderRadius: theme.shape.borderRadius * 2,
    color: theme.palette.text.secondary,
  },
}));
