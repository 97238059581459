import PropTypes from 'prop-types';
import BodyText from '../Typography/BodyText/BodyText';
import { PillContainer } from './ButtonPill.styles';
import theme from '../../theme/theme';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useTheme } from '@mui/system';

const ButtonPill = ({ text }) => {
  const theme = useTheme();
  return (
    <PillContainer>
      <AutorenewIcon sx={{ marginRight: 1, color: theme.palette.warning.main }} />
      <BodyText text={text} colour={theme.palette.warning.main} />
    </PillContainer>
  );
};

ButtonPill.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ButtonPill;
