export const OPTIONS_TYPE = {
  company: 'company_category',
  product: 'product_category',
  material: 'material_tag',
  space: 'space_tag',
  style: 'style_tag',
  colour: 'colour_tag',
  sustainability: 'sustainability_tag',
};

export const PRODUCTS_OPTIONS = [
  { name: 'Furniture', slug: 'furniture' },
  { name: 'Lighting', slug: 'lighting' },
  { name: 'Industrial Products', slug: 'industrial-products' },
  { name: 'Patterns & Illustrations', slug: 'patterns-illustrations' },
  { name: 'Décor & Accessories', slug: 'decor-accessories' },
  { name: 'Cook & Dine', slug: 'cook-dine' },
  { name: 'Other', slug: 'other' },
];

export const SPACES_OPTIONS = [
  { name: 'Living Room', slug: 'living-room' },
  { name: 'Dining Room', slug: 'dining-room' },
  { name: 'Kitchen', slug: 'kitchen' },
  { name: 'Bedroom', slug: 'bedroom' },
  { name: 'Bathroom', slug: 'bathroom' },
  { name: 'Kids', slug: 'kids' },
  { name: 'Other', slug: 'other' },
];

export const COMPANY_OPTIONS = [
  { name: 'Brand', slug: 'brand' },
  { name: 'Retailer', slug: 'retailer' },
  { name: 'Manufacturer', slug: 'manufacturer' },
  { name: 'Other', slug: 'other' },
];

export const NUMBER_OF_EMPLOYESS = [
  { name: '1-10', slug: '1-10' },
  { name: '11-50', slug: '11-50' },
  { name: '51-200', slug: '51-200' },
  { name: '201-500', slug: '201-500' },
  { name: '501-1000', slug: '501-1000' },
  { name: '1001-5000', slug: '1001-5000' },
  { name: '5001-10,000', slug: '5001-10,000' },
  { name: '10,001+', slug: '10,001+' },
];

export const ANNUAL_PRODUCTS_LAUNCH = [
  { name: '1-5', slug: '1-5' },
  { name: '6-10', slug: '6-10' },
  { name: '11-50', slug: '11-50' },
  { name: '50+', slug: '50+' },
];
