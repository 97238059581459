import React from 'react';
import { useNavigate } from 'react-router';

import { ImageList } from '@mui/material';
import CustomImageListItem from 'components/CustomImageListItem/CustomImageListItem';
import StatusPill from 'components/StatusPill/StatusPill';

import { routes } from 'options/routes';
import { PROJECT_STATUS } from 'options/projectStatus';
import { BUYER_PITCH_PILL_STATUS_TEXT, PILL_STATUS_ICON, PILL_STATUS_MAP } from 'options/pillStatusOptions';

import { getImageSrc } from 'utils/getImageSrc';

import { useGalleryColumns } from 'hooks/useGalleryColumns';

const BuyerPitchesList = ({ pitchesList, handleRejectPitch }) => {
  const navigate = useNavigate();
  const { columns } = useGalleryColumns();

  return (
    <ImageList cols={columns} variant="standard" gap={10} style={{ width: '100%' }}>
      {pitchesList?.map((pitch, i) => {
        const { name, product_uuid, image, status, designer_name = '' } = pitch;
        const designerFirstName = designer_name.split(' ')[0];

        const handleViewNavigation = () => navigate(`${routes.pitchesDashboard}/${product_uuid}/pitch`);

        const overlayOptions = {
          title: name,
          description: designer_name,
          menuOptions: [
            {
              title: 'View details',
              onClick: handleViewNavigation,
            },
            ...(status === PROJECT_STATUS.PENDING
              ? [
                  {
                    title: 'Reject Pitch',
                    onClick: () => handleRejectPitch({ product_uuid, designer_name: designerFirstName, name }),
                  },
                ]
              : []),
          ],
          statusData: [
            {
              label: 'Project Status',
              icon: (
                <StatusPill
                  status={PILL_STATUS_MAP[status]}
                  icon={PILL_STATUS_ICON[status]}
                  label={BUYER_PITCH_PILL_STATUS_TEXT[status]}
                />
              ),
            },
          ],
        };

        const { fileSrc, name: imageName } = getImageSrc(image);

        return (
          <CustomImageListItem
            src={fileSrc}
            name={imageName}
            overlayOptions={overlayOptions}
            onClick={handleViewNavigation}
            key={product_uuid}
            wrapStatus
          />
        );
      })}
    </ImageList>
  );
};

BuyerPitchesList.propTypes = {};

export default BuyerPitchesList;
