import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import {
  ProductProposalSuccessContainer,
  StyledDescriptionText,
} from 'components/Forms/ProductProposalSuccess/ProductProposalSuccess.styles';

import { routes } from 'options/routes';

const ProductConversionSuccess = () => {
  const navigate = useNavigate();

  return (
    <ProductProposalSuccessContainer>
      <Box>
        <CustomTitle variant="h3" text="Thank you for your product upload" />
        <Box my={2} display={'flex'} flexDirection="column">
          <StyledDescriptionText variant={'body1'}>
            Thanks, your product has been uploaded to the marketplace successfully.
          </StyledDescriptionText>
          <Box mt={2}>
            <CustomButton text="View your products" onClick={() => navigate(`${routes.productsDashboard}`)} />
          </Box>
        </Box>
      </Box>
    </ProductProposalSuccessContainer>
  );
};

ProductConversionSuccess.propTypes = {};

export default ProductConversionSuccess;
