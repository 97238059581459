import React from 'react';
import propTypes from 'prop-types';

import { Box } from '@mui/material';
import { OverlayStatusOptionWrapper, OverlayStatusTextWrapper } from './OverlayStatusOption.styles';
import BodyText from 'components/Typography/BodyText/BodyText';

const OverlayStatusOption = ({ label, icon, wrapStatus }) => {
  return (
    <OverlayStatusOptionWrapper wrapStatus={wrapStatus}>
      <OverlayStatusTextWrapper wrapStatus={wrapStatus}>
        <BodyText text={label} styleOverrides={{whiteSpace: wrapStatus ? 'no-wrap' : 'pre-wrap', marginBottom: 4}} /> 
      </OverlayStatusTextWrapper>
      <Box>{icon}</Box>
    </OverlayStatusOptionWrapper>
  );
};

OverlayStatusOption.propTypes = {
  label: propTypes.string.isRequired,
  icon: propTypes.element.isRequired,
};

export default OverlayStatusOption;
