import { styled, FormControl, OutlinedInput } from '@mui/material';

export const CustomSearchFieldInputSyled = styled(OutlinedInput, {
  shouldForwardProp: (props) => props !== 'disableUnderline',
})(({ theme }) => ({
  paddingLeft: 0,
  '& .MuiOutlinedInput-input::placeholder': {
    color: theme.palette.text.main,
    opacity: 1,
  },
  '& fieldset': { border: 'none', outline: 'none' },
}));

export const CustomSearchFieldFormControlSyled = styled(FormControl)(({ theme }) => ({
  width: '100%',
  border: 'none',
  outline: 'none',
  minWidth: '200px',
}));

export const CustomSearchFieldWrapper = styled('div')(({ theme }) => ({
  flex: '1',
}));
