import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import NewOpenCallThanks from 'components/NewOpenCallThanks/NewOpenCallThanks';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';

const BuyerOpenCallsThanks = ({ userType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isEditing = location?.state?.isEditing;

  useEffect(() => {
    if (userType && userType === userTypes.designer) navigate(routes.home);
  }, [userType, navigate]);

  return (
    <BuyerDashboardWrapper
      activeTab={routes.openCallsDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[
            {
              label: 'Open Calls',
              onClick: () => navigate(routes.openCallsDashboard, { state: { shouldRefetch: true } }),
            },
            { label: 'New Open Call', onClick: () => navigate(routes.openCallsDashboardAdd) },
          ]}
          currentPage={'Thanks'}
        />
      }
    >
      <NewOpenCallThanks isEditing={isEditing} />
    </BuyerDashboardWrapper>
  );
};

export default BuyerOpenCallsThanks;
