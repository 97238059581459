import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import ImageList from '@mui/material/ImageList';
import { useMediaQuery } from '@mui/material';
import ModalAddMoodboardProduct from 'components/ModalAddMoodboardProduct/ModalAddMoodboardProduct';
import CustomImageListItem from 'components/CustomImageListItem/CustomImageListItem';

import { CUSTOM_PROP_TYPES } from 'options/customPropTypes';
import { isAnyBuyer } from 'utils/isAnyBuyer';
import { getImageSrc } from 'utils/getImageSrc';

import { useMessageContext } from 'context/MessageContext';

const ProductList = ({ products, userType, isBrief, location }) => {
  const navigate = useNavigate();
  const [setMessage] = useMessageContext();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(450));
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const columns = isMobile ? 1 : isTablet ? 2 : 4;

  const [productSelected, setProductSelected] = useState('');
  const [openAddProductToMoodboard, setOpenAddProductToMoodboard] = useState(false);

  const onAddProductToMoodboard = (product_uuid) => {
    setProductSelected(product_uuid);
    setOpenAddProductToMoodboard(true);
  };

  const handleCloseModal = () => {
    setProductSelected('');
    setOpenAddProductToMoodboard(false);
  };

  return (
    <>
      <ImageList cols={columns} variant="standard" gap={10}>
        {products.map((item, i) => {
          const { name, designer_name, image, product_uuid, product_image = {} } = item;

          const { fileSrc, name: imageName, file_uuid } = getImageSrc(image || product_image);

          const handleViewNavigation = () => {
            if (isBrief) {
              return window.open(`/product/${product_uuid}`, '_blank');
            }
            navigate(`/product/${product_uuid}`);
          };

          const overlayOptions = {
            title: name,
            description: designer_name,
            menuOptions: [
              {
                title: 'View Design',
                onClick: handleViewNavigation,
              },
              isAnyBuyer(userType) && {
                title: 'Add to moodboard',
                onClick: () => onAddProductToMoodboard(product_uuid),
              },
            ].filter(Boolean),
          };
          return (
            <CustomImageListItem
              src={fileSrc}
              name={imageName}
              overlayOptions={overlayOptions}
              onClick={handleViewNavigation}
              file_uuid={file_uuid}
              key={product_uuid}
              location={location}
            />
          );
        })}
      </ImageList>
      {openAddProductToMoodboard && (
        <ModalAddMoodboardProduct
          open={openAddProductToMoodboard}
          handleClose={() => handleCloseModal()}
          product_uuid={productSelected}
          setMessage={setMessage}
        />
      )}
    </>
  );
};

ProductList.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      product_uuid: PropTypes.string,
      image: CUSTOM_PROP_TYPES.IMAGE,
      name: PropTypes.string,
      designer_name: PropTypes.string,
      product_url: PropTypes.string,
    })
  ),
  userType: CUSTOM_PROP_TYPES.USER_TYPE,
};

export default React.memo(ProductList);
