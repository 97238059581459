import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import BodyText from 'components/Typography/BodyText/BodyText';

const NoFilteringMatch = ({ item, cols = 1 }) => {
  return (
    <td colSpan={cols}>
      <Box my={2} display="flex" justifyContent="center">
        <BodyText text={`No ${item} match your filtering criteria.`} />
      </Box>
    </td>
  );
};

NoFilteringMatch.propTypes = {};

export default NoFilteringMatch;
