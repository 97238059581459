import { useState } from 'react';
import PropTypes from 'prop-types';

import PageWrapper from '../../../../components/Wrappers/PageWrapper/PageWrapper';
import Spinner from '../../../../components/Spinner/Spinner';
import SignUpWrapper from 'components/Wrappers/SignUpWrapper/SignUpWrapper';
import SignUpStepper from '../../../../components/SignUp/SignUpStepper/SignUpStepper';
import SignUpAccount from '../../../../components/SignUp/SignUpAccount/SignUpAccount';
import DesignerSignUpProfile from '../../../../components/SignUp/DesignerSignUpProfile/DesignerSignUpProfile';
import SignUpBuyerProfile from '../../../../components/SignUp/SignUpBuyerProfile/SignUpBuyerProfile';
import SignUpAgreement from '../../../../components/SignUp/SignUpAgreement/SignUpAgreement';
import EnterpriseBenefitsList from 'components/EnterpriseBenefitsList/EnterpriseBenefitsList';

import { designerStepperArgs, buyerStepperArgs } from '../../../../options/signupStepper';
import { userTypes } from '../../../../options/userTypes';

import { SectionWrapper, SectionContainer, SectionInnerWrapper } from '../../Login/Login.styles';

import { useMessageContext } from 'context/MessageContext';

const SignUpForm = ({ userType, isStory }) => {
  const [setMessage] = useMessageContext();
  const [apiLoading, setApiLoading] = useState(true);

  const [companyCategoriesData, setCompanyCategoriesData] = useState([]);
  const [productCategoriesData, setProductCategoriesData] = useState([]);
  const [spaceTagsData, setSpaceTagsData] = useState([]);
  const { formValues, formValidation, headerList } =
    userType === userTypes.designer ? designerStepperArgs : buyerStepperArgs;

  const getOptionsStates = (states) => {
    const { error, loading, companyCategories, productCategories, spaceTags } = states;
    if (error) setMessage(error);
    setCompanyCategoriesData(companyCategories);
    setProductCategoriesData(productCategories);
    setSpaceTagsData(spaceTags);
    setApiLoading(loading);
  };

  return (
    <PageWrapper userType={'unauthenticated'} showSecondaryFooter>
      <SignUpWrapper getOptionsStates={getOptionsStates}>
        <SectionWrapper>
          <SectionInnerWrapper>
            <SectionContainer />
            {apiLoading && (
              <SectionContainer>
                <Spinner align="center" />
              </SectionContainer>
            )}
            {!apiLoading && (
              <SectionContainer>
                <SignUpStepper
                  formValues={formValues}
                  formValidation={formValidation}
                  headerList={headerList}
                  componentsList={
                    // Need to render components here to resolve infinite re-render error
                    userType === userTypes.designer
                      ? [
                          <SignUpAccount />,
                          <DesignerSignUpProfile options={productCategoriesData} />,
                          <SignUpAgreement isDesigner />,
                        ]
                      : [
                          <SignUpAccount />,
                          <SignUpBuyerProfile
                            productsOptions={productCategoriesData}
                            companyOptions={companyCategoriesData}
                            spacesOptions={spaceTagsData}
                          />,
                          <SignUpAgreement />,
                          // TODO: Replace EnterpriseBenefitsList with OptionsTable when re-introducing
                          //  buyer Stripe subscription requirements
                          // <OptionsTable />,
                          <EnterpriseBenefitsList showTitle={false} />,
                        ]
                  }
                  userType={userType === userTypes.designer ? userTypes.designer : userTypes.buyer}
                  isStory={!!isStory}
                />
              </SectionContainer>
            )}
          </SectionInnerWrapper>
        </SectionWrapper>
      </SignUpWrapper>
    </PageWrapper>
  );
};

SignUpForm.propTypes = {
  userType: PropTypes.oneOf([userTypes.designer, userTypes.buyer]).isRequired,
  isStory: PropTypes.bool,
};

export default SignUpForm;
