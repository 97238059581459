import { useState, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import FormParagraph from '../../Typography/FormParagraph/FormParagraph';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import FormError from '../FormError/FormError';
import Spinner from '../../Spinner/Spinner';
import { SignUpVerifyFormContainer, Container } from './SignUpVerifyForm.styles';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { validationSchema } from './SignUpVerifyForm.data';
import { routes } from '../../../options/routes';

import { verifySignupToken, refreshSignupToken } from '../../../api';

const SignUpVerifyForm = ({ isStory }) => {
  const [key, setKey] = useState('');
  const [token, setToken] = useState('');
  const [error, setError] = useState('');
  const [pageLoading, setPageLoading] = useState(true);
  const [time, setTime] = useState(60);
  const [requested, setRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      token: '',
    },
    mode: 'onChange',
    resolver,
  });

  const getTokenDetails = useCallback(() => {
    const path = window.location.pathname;
    const args = path.split('/');
    // There will be at least 4 args in the path, as the URL structure ensures it
    setKey(args[3]);
    if (args.length > 4) {
      // Get token and pad string with leading zeroes to make it 6 chars, if needed
      const userToken = args[4];
      setToken(String(userToken).padStart(6, '0'));
    } else {
      setPageLoading(false);
    }
  }, []);

  useEffect(() => {
    getTokenDetails();
  }, [getTokenDetails]);


  useEffect(() => {
    const interval = setTimeout(() => {
      setTime(time - 1);
    }, 1000);
    // return () => clearInterval(interval);
  }, [time]);

  const verify = useCallback(
    async (data) => {
      // Get token and pad string with leading zeroes to make it 6 chars, if needed
      const userToken = data.token;
      const formData = {
        key,
        token: String(userToken).padStart(6, '0'),
      };
      const response = await verifySignupToken(formData);
      if (!response.success) {
        setError(response.data.error_message);
        setPageLoading(false);
        setLoading(false);
        return;
      }
      navigate(routes.signupSuccess);
    },
    [navigate, key]
  );

  const requestNewCode = useCallback(
    async () => {
      // Get token and pad string with leading zeroes to make it 6 chars, if needed
      const formData = {
        key
      };
      const response = await refreshSignupToken(formData);
      if (!response.success) {
        setError(response.data.error_message);
        setPageLoading(false);
        setLoading(false);
        return;
      }
      setLoading(false);
      setRequested(true);
    },
    [key]
  );

  const completeVerification = useCallback(async () => {
    if (key && token) {
      const data = { key, token };
      await verify(data);
    }
  }, [key, token, verify]);

  useEffect(() => {
    getTokenDetails();
  }, [getTokenDetails]);

  useEffect(() => {
    completeVerification();
  }, [completeVerification]);

  const onSubmit = async (data) => {
    // Do not run the API logic when rendering the component in a story
    if (isStory) {
      console.log(data);
      return;
    }

    setLoading(true);
    // Call the verifySignupToken endpoint and pass in the data
    // onSubmit will only be triggered if the form data is valid
    const { token } = data;
    const tokenData = { key, token };
    await verify(tokenData);
  };

  const handleRequestNewCode = async () => {
    // Do not run the API logic when rendering the component in a story
    if (isStory) {
      return;
    }

    setLoading(true);
    await requestNewCode();
  };

  return (
    <SignUpVerifyFormContainer>
      {pageLoading && <Spinner align={'center'} />}
      {!pageLoading && (
        <>
          <CustomTitle variant="h3" text="Check your email" />
          <Container>
            <FormParagraph
              text="We've sent a 6-digit code to your email, paste the code in here to complete your application."
              variant="secondary"
            />
          </Container>
          {error && (
            <Container>
              <FormError errors={[error]} />
            </Container>
          )}
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormProvider {...methods}>
              <ControlledTextField name="token" label="Code" showSuccess={!error} />
              <Box display="flex" justifyContent="flex-end" mt={2} mb={10}>
                {loading && <Spinner />}
                {!loading && <CustomButton text="Verify your email" variant="outlined" buttonType="submit" />}
              </Box>
            </FormProvider>
          </form>
          <Container>
            <FormParagraph
              text="Not received a code? You can request a new one after 60 seconds."
              variant="secondary"
            />
            <Box display="flex" mt={2}>
            {!requested && <>
              {time >= 0 && <FormParagraph
                text={`Please wait... ${time} seconds`}
                variant="secondary"
                colour="gray"
              />}
              {time < 0 && <CustomStandardLink text="Request a new one" onClick={handleRequestNewCode} colour="gray" linkType="primary" />}
            </>}
              {requested && <FormParagraph
                text="A new code has been requested and sent to your email. If you still have issues, please contact the BuyDesign admin."
                variant="secondary"
              />}
            </Box>
          </Container>
        </>
      )}
    </SignUpVerifyFormContainer>
  );
};

export default SignUpVerifyForm;
