import { styled } from '@mui/material';
import { MAX_WIDTH, MD_WIDTH, SM_WIDTH } from 'styles/constants';

export const FooterContainer = styled('div')(({ theme }) => ({
  width: '100%',
  minHeight: '200px',
  backgroundColor: theme.palette.primary.main,
  borderTop: `1px solid ${theme.palette.common.white}`,
}));

export const FooterWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(4),
  minHeight: '200px',
  width: MD_WIDTH,
  maxWidth: MAX_WIDTH,
  margin: '0 auto',
  height: '100%',
  padding: `${theme.spacing(4)} 0`,
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    width: SM_WIDTH,
  },
}));

export const FooterLinksWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
  gap: '2em',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
  },
}));

export const FooterSubscribeWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));
