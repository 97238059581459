import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { BRIEF_STATUS_BUYER } from 'options/briefStatus';
import { PROJECT_STATUS } from 'options/projectStatus';
import { PROPOSAL_STATUS } from 'options/proposalStatus';

export const getIsProposalTabDisabled = (project_status) => {
  return project_status === PROJECT_STATUS.PENDING;
};

export const getIsAgreementTabDisabled = (project_status, proposal_status, brief_status) => {
  if (brief_status === BRIEF_STATUS_BUYER.CANCELLED) return true;
  return project_status === PROJECT_STATUS.REJECTED || proposal_status === PROPOSAL_STATUS.REJECTED;
};

export const getIsFilesTabDisabled = (agreement_status, brief_status) => {
  if (brief_status === BRIEF_STATUS_BUYER.CANCELLED) return true;
  return !(agreement_status === AGREEMENT_STATUS.UPFRONT_PAID || agreement_status === AGREEMENT_STATUS.PAID);
};

export const getIsProjectCompleted = (project_status) => {
  return project_status === PROJECT_STATUS.COMPLETED;
};
