import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from 'react-query';
import queryString from 'query-string';

import { debounce } from 'debounce';

import { Box } from '@mui/system';
import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';
import BodyText from 'components/Typography/BodyText/BodyText';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import NamesList from 'components/NamesList/NamesList';
import FeaturedList from 'components/FeaturedList/FeaturedList';
import Spinner from 'components/Spinner/Spinner';

import { BUYER_CATEGORIES_OPTIONS, EXPERIENCE_OPTIONS } from 'options/findDesignerOptions';
import { COUNTRIES } from 'options/countries';
import { userTypes } from 'options/userTypes';

import { addFilters } from 'utils/addFilters';

import { routes } from 'options/routes';

import theme from 'theme/theme';

import { StyledBodyTextContainer } from 'components/DesignersHeader/DesignersHeader.styles';
import { LoadingContainer } from 'components/ProductsMegaMenu/ProductsMegaMenu.styles';

import useCustomMediaQueries from 'hooks/useCustomMediaQueries';

import { getBuyersList, getFeaturedBuyers } from 'api';

const BuyersIndex = () => {
  const [filter, setFilter] = useState({});
  const [buyersList, setBuyersList] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const parsedFilter = useMemo(
    () => queryString.stringify({ ...filter, search: searchTerm && searchTerm.length > 2 ? searchTerm : '' }),
    [filter, searchTerm]
  );
  // console.log(parsedFilter);

  const fetchBuyers = async (query) => {
    setIsLoading(true);
    try {
      const res = await getBuyersList(query);
      setBuyersList(res.buyers);
    } catch (error) {
      if (error) setError(error.message);
    }
    setIsLoading(false);
  };

  const filtersList = useMemo(
    () => ({
      categories: [...BUYER_CATEGORIES_OPTIONS],
      location: [...COUNTRIES],
      experience: [...EXPERIENCE_OPTIONS],
    }),
    []
  );

  const debouncedFetch = debounce((query) => fetchBuyers(query), 200);

  const menuFilterOptions = useMemo(() => {
    const FIND_BUYER_MENU = [
      {
        id: 'open_calls',
        label: 'Open Calls',
        onClick: () => setFilter((prev) => ({ ...prev, open_calls: !prev.open_calls })),
        selected: !!filter['open_calls'],
      },
      {
        label: 'Categories',
        id: 'categories',
        actions: [],
      },
      {
        label: 'Location',
        id: 'location',
        actions: [],
      },
      {
        label: 'Experience',
        id: 'experience',
        actions: [],
      },
    ];
    return FIND_BUYER_MENU.map((menuItem) => {
      return {
        ...menuItem,
        actions: addFilters(menuItem, filtersList, setFilter, filter),
      };
    });
  }, [filter, filtersList]);

  console.log(menuFilterOptions);

  useEffect(() => {
    debouncedFetch(parsedFilter);
  }, [parsedFilter]);

  const namesListData = useMemo(() => {
    if (!buyersList) return null;
    const alphabet = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    const buyerOnClick = (uuid) => navigate(`${routes.buyersIndex}/${uuid}`);
    const namesList = buyersList.map((buyer) => {
      return { label: buyer.name, onClick: () => buyerOnClick(buyer.company_uuid) };
    });
    const sortedBuyersNamesListByAlphabet = alphabet.map((l) => {
      const thisLetterBuyers = namesList?.filter((buyers) => {
        return buyers.label.charAt(0).toUpperCase() === l;
      });

      return { title: l, options: thisLetterBuyers };
    });
    return sortedBuyersNamesListByAlphabet;
  }, [buyersList]);

  const featBuyersCall = useQuery(['featuredBuyers'], () => getFeaturedBuyers(), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
  });

  const featBuyers = useMemo(
    () =>
      featBuyersCall?.data?.featured_buyer_companies
        ? featBuyersCall?.data?.featured_buyer_companies.map((buyer) => {
            const { company_uuid, logo, name } = buyer;
            return {
              onClick: () => navigate(`${routes.buyersIndex}/${company_uuid}`),
              imageUrl: logo.fileSrc,
              label: name,
            };
          })
        : null,
    [featBuyersCall?.data?.featured_buyer_companies, navigate]
  );

  const hasFilter = useMemo(() => {
    const initialFilter = 'search=';
    if (parsedFilter !== initialFilter) return true;
    else return false;
  }, [parsedFilter]);

  const resetFilter = useCallback(() => {
    setFilter({});
    setSearchTerm('');
  }, []);

  return (
    <PageWrapper userType={userTypes.designer} showSecondaryFooter={false}>
      <PageContentWrapper>
        <>
          <Box pb={theme.spacing(1)} borderBottom={'1.4px solid'}>
            <CustomTitle variant="h2" text="Discover buyers" />
          </Box>
          <StyledBodyTextContainer>
            <Box mb={2}>
              <BodyText text="We value originality and collaborate with a diverse international network of companies." />
            </Box>
            <Box mb={2}>
              <BodyText text="You can view our featured companies or discover buyers by category, location or experience." />
            </Box>
            <Box mb={2}>
              <BodyText text="Review the company's profile, pitch directly to the company or view and respond to their Open Calls." />
            </Box>
          </StyledBodyTextContainer>
        </>
        {featBuyers && (
          <Box mt={8}>
            <Box mb={2}>
              <CustomTitle variant="h2" text="Featured buyers" />
            </Box>
            <FeaturedList aspect1to1={true} data={featBuyers} />
          </Box>
        )}
        <Box mt={8}>
          <SearchAndFilterOptions
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            menuOptions={menuFilterOptions}
            hasFilter={hasFilter}
            resetFilter={resetFilter}
          />
          {isLoading && (
            <LoadingContainer>
              <Spinner align={'center'} />
            </LoadingContainer>
          )}
          {namesListData && !isLoading && (
            <Box mt={2}>
              <NamesList namesList={namesListData} />
            </Box>
          )}
        </Box>
      </PageContentWrapper>
    </PageWrapper>
  );
};

BuyersIndex.propTypes = {};

export default BuyersIndex;
