import React from 'react';
import PropTypes from 'prop-types';
import { CustomTablePaginationStyled } from './CustomTablePagination.styles';

const CustomTablePagination = ({ count, onPageChange, rowsPerPage, onRowsPerPageChange, rowsPerPageOptions, page }) => {
  return (
    <CustomTablePaginationStyled
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
    />
  );
};

CustomTablePagination.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowsPerPageOptions: PropTypes.array.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
};

export default CustomTablePagination;
