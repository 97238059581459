import React, { useCallback, useState, useEffect } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router';

import { Box } from '@mui/material';

import AgreementAccordion from 'components/AgreementAccordion/AgreementAccordion';
import Spinner from 'components/Spinner/Spinner';
import ModalCongratulations from 'components/ModalCongratulations/ModalCongratulations';

import { PROJECT_STATUS } from 'options/projectStatus';
import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { CURRENCY_MAP } from 'options/currency';
import { userTypes } from 'options/userTypes';
import { routes } from 'options/routes';

import { parseRemoteNegotiatedTerms } from '../../Briefs/DesignerBriefAgreement/DesignerBriefAgreement.utils';
import { parseRemoteData as parseRemoteTermsData } from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/utils/BuyerTermsNew.utils';

import { acceptDesignerProductAgreement, getDesignerProductAgreement, getSingleTerm } from 'api';

const DesignerSingleProductAgreementTab = ({ isStory }) => {
  const queryClient = useQueryClient();
  const { product_uuid, buyer_product_uuid } = useParams();
  const [product, setMessage] = useOutletContext();
  const navigate = useNavigate();

  const { project_status, agreement_status, currency } = product;

  useEffect(() => {
    const isAgreementDisabled = project_status === PROJECT_STATUS.REJECTED;

    if (isAgreementDisabled) {
      navigate(`${routes.productsDashboard}/${product_uuid}/${buyer_product_uuid}/product`);
    }
  }, [product_uuid, navigate, project_status, buyer_product_uuid]);

  const {
    data: agreementData,
    isLoading: isAgreementLoading,
    refetch: agreementRefetch,
    isRefetching: isAgreementRefetching,
  } = useQuery(
    ['designerProductAgreement', product_uuid],
    () => getDesignerProductAgreement(product_uuid, buyer_product_uuid),
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const [openCongratulations, setOpenCongratulations] = useState(false);
  const nonParsedValues = agreementData?.agreement || {};

  // use parsed negotiated terms in brief summary component
  const parsedNegotiatedAgreement =
    (agreementData && !agreementData?.agreement?.download_url && parseRemoteNegotiatedTerms(nonParsedValues)) || {};
  const download_url = agreementData?.agreement?.download_url;
  const agreementError = !agreementData?.data?.success && agreementData?.data?.error_message;

  useEffect(() => {
    if (agreementError) {
      setMessage(agreementError);
    }
  }, [agreementError, setMessage]);

  const { selected_terms_uuid } = nonParsedValues;

  const { data: termsData, isLoading: isTermsLoading } = useQuery(
    ['designerProductTerms', selected_terms_uuid],
    () => (selected_terms_uuid ? getSingleTerm(selected_terms_uuid) : {}),
    {
      enabled: true,
    }
  );

  const terms = (termsData?.terms && parseRemoteTermsData(termsData?.terms)) || {};

  const onAcceptAgreement = useCallback(async () => {
    const res = await acceptDesignerProductAgreement(product_uuid, buyer_product_uuid);
    if (!res.success) {
      return setMessage('There was an error while accepting the agreement. Please try again.');
    }
    queryClient.refetchQueries(['designerSingleProduct', product_uuid, buyer_product_uuid], {
      type: 'active',
      exact: true,
    });
    agreementRefetch();
    setOpenCongratulations(true);
  }, [agreementRefetch, buyer_product_uuid, product_uuid, queryClient, setMessage]);

  const loading = isAgreementLoading || isAgreementRefetching || isTermsLoading;
  const selectedCurrency = CURRENCY_MAP[currency] || '£';

  return (
    <Box mt={2}>
      {loading ? (
        <Box>
          <Spinner align="center" />
        </Box>
      ) : (
        <AgreementAccordion
          briefDetails={{
            ...([AGREEMENT_STATUS.IN_REVIEW, AGREEMENT_STATUS.AWAITING_BUYER, AGREEMENT_STATUS.AWAITING_SIGN].includes(
              agreement_status
            )
              ? parsedNegotiatedAgreement
              : {}),
            ...terms,
            currency: selectedCurrency,
          }}
          agreement_status={agreement_status}
          userType={userTypes.designer}
          download_url={download_url}
          project_status={project_status}
          onAcceptAgreement={onAcceptAgreement}
        />
      )}
      {openCongratulations && (
        <ModalCongratulations
          handleClose={() => setOpenCongratulations(false)}
          action={() => {}}
          text="You have accepted the agreement!"
          title="Congratulations"
          open={openCongratulations}
        />
      )}
    </Box>
  );
};

DesignerSingleProductAgreementTab.propTypes = {};

export default DesignerSingleProductAgreementTab;
