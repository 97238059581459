import { styled } from '@mui/material';

export const StyledPageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'collapse',
})(({ theme, collapse }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  [theme.breakpoints.down('md')]: {
    overflowY: collapse ? 'hidden' : '', // helps parent container not scroll when collapse is true
  },
}));

export const BodyContainer = styled('main')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  position: 'relative',
  boxSizing: 'border-box',
}));

export const FooterContainer = styled('footer')(() => ({
  marginTop: 'auto',
}));
