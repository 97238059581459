import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';

import { Box, useMediaQuery } from '@mui/material';
import ProductPropertyTabHeader from 'components/ProductPropertyTabHeader/ProductPropertyTabHeader';
import ProductPropertyListGroup from 'components/ProductPropertyListGroup/ProductPropertyListGroup';
import PropertyPillGroup from 'components/PropertyPillGroup/PropertyPillGroup';
import { ProductPropertiesFormMobile } from './ProductPropertiesFormMobile';
import Spinner from 'components/Spinner/Spinner';

import { parseRemoteOptions } from 'utils/parseRemoteOptions';

import { OPTIONS_TYPE } from 'options/selectOptions';

import { HEADER_OPTIONS, mockValues } from './ProductProposalPropertiesForm.data';
import {
  getHeaderVariant,
  insertOrRemoveCategory,
  isOptionInList,
  selectItem,
  transform,
} from './utils/categoryHelper';

import { getChildOptions } from 'api';

const ProductProposalPropertiesForm = ({ isStory }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { getValues, setValue, control } = useFormContext();

  // pulls all values from form
  const allValues = useWatch({
    control,
  });

  const [tabValue, setTabValue] = useState(OPTIONS_TYPE.product); // 'product_category' etc
  const currentSelectedOptions = allValues[tabValue];
  const [categoriesSelected, setCategoriesSelected] = useState([]); // { label: 'Furniture', value: 'furniture' } etc

  const [listOptionsSaved, setListOptionsSaved] = useState({
    [tabValue]: [],
  });

  const currentCategory = !categoriesSelected.length ? 'top' : categoriesSelected[categoriesSelected.length - 1]?.value;

  const { data, isLoading } = useQuery(
    ['options', tabValue, currentCategory],
    () => getChildOptions(tabValue, currentCategory),
    {
      enabled: !isStory,
      staleTime: 5 * 60 * 1000,
    }
  );

  useEffect(() => {
    const setListData = () => {
      if (data) {
        const { options } = data;
        const parsedOptions = parseRemoteOptions(options, { parent: currentCategory, selected: false });

        setListOptionsSaved((prevState) => ({
          [tabValue]: insertOrRemoveCategory(prevState[tabValue], currentCategory, parsedOptions, categoriesSelected),
        }));
      }
      // use mock value for story and server is not running
      if (isStory) {
        const cat = currentCategory === 'top' ? `${tabValue}_top` : currentCategory;
        const parsedOptions = parseRemoteOptions(mockValues[cat], { parent: currentCategory, selected: false });
        setListOptionsSaved((prevState) => ({
          [tabValue]: insertOrRemoveCategory(prevState[tabValue], currentCategory, parsedOptions, categoriesSelected),
        }));
      }
    };

    setListData();
  }, [categoriesSelected.length, data, currentCategory, tabValue, categoriesSelected, isStory]);

  const handleChange = useCallback((_e, newValue) => {
    setListOptionsSaved([]);
    setTabValue(newValue);
    setCategoriesSelected([]);
  }, []);

  const selectOption = useCallback(
    ({ label, value, showTooltip }) => {
      const categoriesSelectedText = categoriesSelected.map((c) => c.label).join(' > ');

      // RHF value
      const prevValue = getValues(tabValue);
      setValue(tabValue, [
        ...prevValue,
        { label, value, category: tabValue, tooltipText: showTooltip ? categoriesSelectedText : '' },
      ]);
    },
    [categoriesSelected, getValues, setValue, tabValue]
  );

  const unSelectOption = useCallback(
    (optionDeleted) => {
      // RHF value
      const prevValue = getValues(tabValue);
      setValue(
        tabValue,
        prevValue.filter((selectedOption) => selectedOption.value !== optionDeleted.value)
      );
    },
    [getValues, setValue, tabValue]
  );

  const handleItemOnClick = useCallback(
    ({ label, value, has_nested, listIndex }) => {
      // item is category, should select/unselect
      if (has_nested) {
        // if category is selected, then unselect it
        if (isOptionInList(categoriesSelected, value)) {
          setCategoriesSelected((prevState) => prevState.slice(0, listIndex));
        } else {
          // if category is NOT selected, then select it

          // if it's mobile and at top level, unselect categories
          if (isMobile && value === tabValue) {
            return setCategoriesSelected([]);
          }

          // selecting a category in the same level
          if (categoriesSelected.length >= listIndex + 1 && !isMobile) {
            return setCategoriesSelected((prevState) =>
              !listIndex ? [{ label, value }] : [...prevState.slice(0, listIndex), { label, value }]
            );
          }
          // add category to categories selected list
          setCategoriesSelected((prevState) => [...prevState, { label, value }]);
        }
      } else {
        // item is an option to be selected, should select/unselect option
        if (isOptionInList(currentSelectedOptions, value)) {
          unSelectOption({ value });
        } else {
          selectOption({ label, value, showTooltip: !(categoriesSelected.length >= listIndex + 1) });
        }
      }
    },
    [categoriesSelected, isMobile, tabValue, currentSelectedOptions, unSelectOption, selectOption]
  );

  const headerOptionsWithVariant = useMemo(() => {
    return getHeaderVariant(HEADER_OPTIONS, allValues, tabValue);
  }, [tabValue, allValues]);

  const currentHeader = headerOptionsWithVariant.find((header) => header.value === tabValue);

  const listOptions = useMemo(() => {
    const list = listOptionsSaved[tabValue];

    return transform(list, (item) => selectItem(categoriesSelected, currentSelectedOptions, item));
  }, [categoriesSelected, currentSelectedOptions, listOptionsSaved, tabValue]);

  return (
    <Box>
      <Box>
        <ProductPropertyTabHeader value={tabValue} options={headerOptionsWithVariant} onChange={handleChange} />
      </Box>
      <Box>
        {!!currentSelectedOptions?.length && (
          <PropertyPillGroup options={currentSelectedOptions} onDelete={unSelectOption} />
        )}
      </Box>
      {!isMobile ? (
        <Box display="flex" minHeight="200px">
          {listOptions?.map((options, index) => (
            <ProductPropertyListGroup
              options={options}
              onClick={handleItemOnClick}
              listIndex={index}
              key={`list_${index}`}
            />
          ))}
          {isLoading && !isStory && (
            <Box width="20%" display="flex" alignItems="center">
              <Spinner />
            </Box>
          )}
        </Box>
      ) : (
        <ProductPropertiesFormMobile
          listOptions={listOptions}
          onClick={handleItemOnClick}
          currentHeader={currentHeader}
          categoriesSelected={categoriesSelected}
          isLoading={isLoading}
          isStory={isStory}
        />
      )}
    </Box>
  );
};

export default ProductProposalPropertiesForm;
