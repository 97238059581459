import { routes, wpRoutes } from '../../../../options/routes';

export const LINKS_OPTIONS = {
  standard: [
    { linkText: 'Businesses', linkPath: wpRoutes.businesses, active: false, external: true },
    { linkText: 'Designers', linkPath: wpRoutes.designers, active: false, external: true },
    // { linkText: 'Features', linkPath: wpRoutes.features, active: false, external: true },
    // { linkText: 'About', linkPath: wpRoutes.about, active: false, external: true },
    // { linkText: 'Pricing', linkPath: wpRoutes.pricing, active: false, external: true },
    // { linkText: 'Stories', linkPath: wpRoutes.stories, active: false, external: true },
    { linkText: 'Log in', linkPath: routes.login, active: false },
  ],
  buyer: [
    { linkText: 'Discover', linkPath: wpRoutes.discoverDesigners, active: false, external: true },
    { linkText: 'Products', linkPath: routes.products, active: false },
    { linkText: 'Designers A - Z', linkPath: routes.designersIndex, active: false },
    { linkText: 'My Studio', linkPath: routes.dashboard, active: false },
  ],
  designer: [
    { linkText: 'Discover', linkPath: wpRoutes.discoverBuyers, active: false, external: true },
    { linkText: 'Companies A - Z', linkPath: routes.buyersIndex, active: false },
    { linkText: 'My Studio', linkPath: routes.dashboard, active: false },
  ],
};

export const MENU_OPTIONS = {
  standard: [{ linkText: 'Help', linkPath: '/help' }],
  buyer: [
    { linkText: 'Profile', linkPath: routes.profile },
    { linkText: 'Account', linkPath: routes.account },
    { linkText: 'Help', linkPath: routes.help },
    { linkText: 'Resources', linkPath: wpRoutes.resources, openNewTab: true },
    { linkText: 'Log Out', linkPath: routes.logout },
  ],
  designer: [
    { linkText: 'Profile', linkPath: routes.profile },
    { linkText: 'Account', linkPath: routes.account },
    { linkText: 'Help', linkPath: routes.help },
    { linkText: 'Resources', linkPath: wpRoutes.resources, openNewTab: true },
    { linkText: 'Log Out', linkPath: routes.logout },
  ],
};

// MOBILE
export const LINKS_OPTIONS_MOBILE = {
  standard: [],
  buyer: [{ linkText: 'My Studio', linkPath: routes.dashboard, active: false }],
  designer: [{ linkText: 'My Studio', linkPath: routes.dashboard, active: false }],
};

export const MENU_OPTIONS_MOBILE = {
  standard: [
    { linkText: 'Sign up', linkPath: routes.signup, active: false },
    { linkText: 'Log in', linkPath: routes.login, active: false },
    { linkText: 'Businesses', linkPath: wpRoutes.businesses, active: false, external: true },
    { linkText: 'Designers', linkPath: wpRoutes.designers, active: false, external: true },
    // { linkText: 'How BuyDesign Works', linkPath: '/', active: false },
    // { linkText: 'Accounts', linkPath: routes.home, active: false },
    // { linkText: 'Pricing', linkPath: wpRoutes.pricing, active: false, external: true },
    // { linkText: 'About', linkPath: wpRoutes.about, active: false, external: true },
    // { linkText: 'Stories', linkPath: wpRoutes.stories, active: false, external: true },
  ],
  buyer: [
    { linkText: 'Discover', linkPath: wpRoutes.discoverDesigners, active: false, external: true },
    { linkText: 'Products', linkPath: routes.products, active: false },
    { linkText: 'Designers A - Z', linkPath: routes.designersIndex, active: false },
    { linkText: 'Profile', linkPath: routes.profile },
    { linkText: 'Account', linkPath: routes.account },
    { linkText: 'Help', linkPath: routes.help },
    { linkText: 'Resources', linkPath: wpRoutes.resources, openNewTab: true },
    { linkText: 'Log Out', linkPath: routes.logout },
  ],
  designer: [
    // TODO: Update 'Discover' linkPath to wpRoutes.discoverBuyers when correct home route is introduced
    { linkText: 'Discover', linkPath: routes.home, active: false, external: true },
    { linkText: 'Companies A - Z', linkPath: routes.buyersIndex, active: false },
    { linkText: 'Profile', linkPath: routes.profile },
    { linkText: 'Account', linkPath: routes.account },
    { linkText: 'Help', linkPath: routes.help },
    { linkText: 'Resources', linkPath: wpRoutes.resources, openNewTab: true },
    { linkText: 'Log Out', linkPath: routes.logout },
  ],
};
