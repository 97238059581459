import { Box, styled, Table } from '@mui/material';

export const TableStyled = styled(Table, {
  shouldForwardProp: (prop) => prop !== 'minWidth' && prop !== 'minHeight',
})(({ theme, minWidth, minHeight }) => ({
  position: 'relative',
  minHeight: minHeight ? minHeight : 'unset',
  [theme.breakpoints.down('md')]: {
    minWidth: minWidth || '900px',
    overflowX: 'scroll',
  },
}));

export const VisibilityIconWrapper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  // helps it be clickable even with Safari before element hack
  zIndex: 1,
  position: 'relative',
}));
