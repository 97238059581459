import React, { useCallback, useState } from 'react';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import PropTypes from 'prop-types';
import {
  CloseIconStyled,
  FileNameText,
  FileUploadThmbnailContainer,
  OverlayStyled,
  FileUploadThmbnailImg,
  ImageThumbnailContainer,
} from './FileUploadThumbnail.styles';
import { Typography } from '@mui/material';

const FileUploadThumbnail = ({ fileSrc, name, removeFile, index, fileType, numberOfTiles }) => {
  const [onHover, setHover] = useState(false);
  const isImage =
    fileType &&
    !!(fileType.match('image') || fileType === 'url') &&
    !fileType.match('photoshop') &&
    !fileType.match('tiff');

  const handleRemove = useCallback(() => {
    if (removeFile) {
      removeFile(index);
    }
  }, [removeFile, index]);

  const preview = isImage ? (
    <ImageThumbnailContainer onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <FileUploadThmbnailImg src={fileType === 'url' ? atob(fileSrc) : fileSrc} alt={name} />
      <OverlayStyled show={onHover}>
        <CloseIconStyled onClick={handleRemove} />
      </OverlayStyled>
    </ImageThumbnailContainer>
  ) : (
    <>
      <FilePresentIcon />
      <Typography variant="body1">File Uploaded</Typography>
      {name && <FileNameText variant="body1">{name}</FileNameText>}
      <CloseIconStyled onClick={handleRemove} />
    </>
  );

  return <FileUploadThmbnailContainer numberOfTiles={numberOfTiles}>{preview}</FileUploadThmbnailContainer>;
};

FileUploadThumbnail.propTypes = {
  fileSrc: PropTypes.string,
  name: PropTypes.string,
  removeFile: PropTypes.func,
  index: PropTypes.number,
  fileType: PropTypes.oneOf(['image/png', 'image/jpeg', 'application/pdf', 'url', 'all']),
};

export default FileUploadThumbnail;
