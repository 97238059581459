import { styled } from '@mui/system';
import Link from '@mui/material/Link';

const getColour = (theme, colour) => {
  const colours = {
    primary: theme.palette.text.primary,
    gray: theme.palette.text.secondary,
    white: theme.palette.text.white,
  };

  return colours[colour] || theme.palette.text.primary;
};

export const LinkStyled = styled(Link, {
  shouldForwardProp: (props) => props !== 'linkType' && props !== 'colour' && props !== 'fontSize',
})(({ theme, colour, fontSize }) => ({
  cursor: 'pointer',
  color: getColour(theme, colour),
  display: 'inline-flex',
  textDecorationColor: 'unset',
  textUnderlineOffset: '5px',
  fontSize: fontSize === 'small' ? '0.750rem' : fontSize === 'medium' ? '0.875rem' : 'unset',
  '& > span': {
    margin: 0,
  },
  '& .MuiSvgIcon-root': {
    color: getColour(theme, colour),
    marginRight: theme.spacing(0.5),
  },
}));

export const IconWrapper = styled('span')(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));
