import { styled } from '@mui/material';
import ListItem from '@mui/material/ListItem';

export const ListItemStyled = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `4px ${theme.spacing(2)}`,
  minHeight: '37px',
}));

export const IconWrapper = styled('div')(() => ({
  width: '24px',
  textAlign: 'center',
}));
