import { styled, Box } from '@mui/material';

export const DescriptionTextWrapper = styled(Box)(({ theme }) => ({
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '80%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

export const ButtonTextWrapperStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  '& svg': {
    height: '0.8em',
  },
}));

export const ImagesContainerStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  '& > div': {
    width: `calc(25% - ${theme.spacing(1)})`,
  },
  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    '& > div': {
      width: `calc(50% - ${theme.spacing(1)})`,
    },
  },
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    rowGap: theme.spacing(1),
    '& > div': {
      width: `calc(100% - ${theme.spacing(1)})`,
    },
  },
}));

export const ReferenceFileSectionWrapper = styled(Box)(() => ({}));

export const SectionTitle = styled('h2')(({ theme }) => ({
  margin: '20px 0 0',
  fontFamily: 'InterRegular',
  fontWeight: 400,
  fontSize: 24,
  lineHeight: 1.3,
  letterSpacing: '0.00735em',
  color: theme.palette.primary.main,
}));

export const SectionDescription = styled('p')(() => ({
  margin: '10px 0 20px',
  fontFamily: 'InterRegular',
  fontWeight: 400,
  lineHeight: 1.3,
  fontSize: 'unset',
  letterSpacing: '0.00938em',
  color: 'rgb(153, 153, 153)',
}));

export const ThumbnailContainer = styled(Box)(() => ({}));
