import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';

import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import ModalStaticMessage from 'components/ModalStaticMessage/ModalStaticMessage';
import ModalHelperText from 'components/ModalHelperText/ModalHelperText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import ModalMessageHeader from 'components/ModalHelperText/ModalMessageHeader';
import Spinner from 'components/Spinner/Spinner';

import { StyledActionButtonsContainer, StyledModalContentWrapper } from 'components/CustomModal/CustomModal.styles';

import theme from 'theme/theme';

const getItemNaming = (variant) => {
  switch (variant) {
    case 'pitch':
      return 'Pitch';
    case 'brief-proposal':
      return 'Brief Proposal';
    case 'designer-brief':
      return 'Brief';
    case 'open-call-proposal':
      return 'Open-Call Proposal';
    default:
      return 'proposal';
  }
};

// Used to reject:
// - Brief proposal
// - Open call proposal
// - Pitch
const ModalRejectBrief = ({
  open,
  handleClose,
  handleSubmit,
  variant = 'brief',
  company_name,
  designer_name,
  item_name,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm({
    defaultValues: {
      message: '',
    },
  });

  const handleSubmitAction = useCallback(
    async (data) => {
      setIsLoading(true);
      await handleSubmit(false, data?.message);
      setIsLoading(false);
      handleClose();
    },
    [handleClose, handleSubmit]
  );

  const item = getItemNaming(variant);

  return (
    <CustomModal title={`Decline ${item}`} open={open} handleClose={handleClose}>
      {isLoading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <StyledModalContentWrapper>
          <Box mt={1}>
            <BodyText
              colour={theme.palette.grey[500]}
              text={`Are you sure you want to decline this ${item?.toLocaleLowerCase()}? Once confirmed, this action cannot be undone.`}
            />
          </Box>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmitAction)}>
              {variant !== 'designer-brief' ? (
                <Box mt={2}>
                  <ModalMessageHeader />
                  <Box textAlign="justify" mt={1} backgroundColor="background.staticMessage" py={1}>
                    <ModalStaticMessage
                      variant={variant}
                      designer_name={designer_name}
                      company_name={company_name}
                      item_name={item_name}
                    />
                  </Box>
                  <ModalHelperText />
                </Box>
              ) : null}
              <StyledActionButtonsContainer>
                <CustomButton variant="outlined" onClick={handleClose} text="Cancel" />
                <CustomButton buttonType="submit" text="Confirm" />
              </StyledActionButtonsContainer>
            </form>
          </FormProvider>
        </StyledModalContentWrapper>
      )}
    </CustomModal>
  );
};

ModalRejectBrief.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.oneOf(['brief-proposal', 'open-call-proposal', 'pitch']),
};

export default ModalRejectBrief;
