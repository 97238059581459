import { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FileUploads from 'components/FileUploads/FileUploads';
import Spinner from 'components/Spinner/Spinner';

import { MOCK_DESIGN_FILES } from 'mockData/mockFiles';

import {
  deleteDesignerProductFiles,
  downloadDesignerProductFiles,
  getDesignerProductFiles,
  uploadDesignerProductFiles,
} from 'api';

import { PROJECT_STATUS } from 'options/projectStatus';
import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { routes } from 'options/routes';

const DesignerSingleProductFilesTab = ({ userType }) => {
  const navigate = useNavigate();
  const { product_uuid, buyer_product_uuid } = useParams();
  const [product, setMessage] = useOutletContext();

  const { project_status, agreement_status } = product;

  useEffect(() => {
    const isFilesDisabled =
      project_status === PROJECT_STATUS.REJECTED ||
      (agreement_status !== AGREEMENT_STATUS.AGREED && agreement_status !== AGREEMENT_STATUS.PAID);

    if (isFilesDisabled) {
      navigate(`${routes.productsDashboard}/${product_uuid}/${buyer_product_uuid}/product`);
    }
  }, [product_uuid, navigate, project_status, agreement_status, buyer_product_uuid]);

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['designerProductFiles', product_uuid],
    () => getDesignerProductFiles(product_uuid, buyer_product_uuid),
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const files = data?.design_files || {};

  const onUploadFile = async (filesData) => {
    const data = {
      files: filesData,
      is_chat: false,
    };

    const res = await uploadDesignerProductFiles(product_uuid, buyer_product_uuid, data);
    if (!res.success) {
      return setMessage('There was an error uploading the files. Please try again.');
    }
    setMessage('You have successfully uploaded files');
    refetch();
  };
  const isProjectCompleted = project_status === PROJECT_STATUS.COMPLETED;
  const loading = isLoading || isRefetching;

  return (
    <Box>
      {loading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <Box mt={2} width="100%">
          <FileUploads
            withUploader={!isProjectCompleted}
            uploadFile={onUploadFile}
            downloadFiles={(data) => downloadDesignerProductFiles(product_uuid, buyer_product_uuid, data, true)}
            deleteFiles={
              !isProjectCompleted &&
              ((file_uuid) => deleteDesignerProductFiles(product_uuid, buyer_product_uuid, file_uuid))
            }
            files={files}
            setMessage={setMessage}
            refetch={refetch}
            userType={userType}
          />
        </Box>
      )}
    </Box>
  );
};

DesignerSingleProductFilesTab.propTypes = {};

export default DesignerSingleProductFilesTab;
