import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import FileUploadIconThumbnail from 'components/FileUploadIconThumbnail/FileUploadIconThumbnail';
import CaptionText from 'components/Typography/CaptionText/CaptionText';
import FormFieldSetAndInputWrapper from 'components/Wrappers/FormFieldSetAndInputWrapper/FormFieldSetAndInputWrapper';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import CustomFileUploadField from 'components/CustomFileUpload/CustomFileUploadField/CustomFileUploadField';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import BodyText from 'components/Typography/BodyText/BodyText';
import Spinner from 'components/Spinner/Spinner';

import {
  DeleteIconStyled,
  DownloadAllWrapper,
  DownloadIcon,
  FileThumbnailWrapper,
  StyledCaptionWrapper,
  StyledFilesWrapper,
  StyledFileUploadsContainer,
} from './FileUploads.styles';

import { userTypes } from 'options/userTypes';

import { isAnyBuyer } from 'utils/isAnyBuyer';
import { transformToBase64List } from 'utils/convertToBase64';

const FileUploads = ({
  withUploader,
  isStory,
  mockData,
  files,
  uploadFile,
  setMessage,
  refetch,
  userType,
  downloadFiles,
  deleteFiles,
}) => {
  const [isDeletingFile, setIsDeletingFile] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const fileUploads = useMemo(() => {
    const _files = isStory && mockData ? mockData : files;

    if (_files) return Object.entries(_files);
    else return [];
  }, [files, isStory, mockData]);

  const handleFileDownload = useCallback(
    async (file_uuid, name) => {
      try {
        setIsDownloadingFile(true);
        const link = document.createElement('a');
        link.target = '_blank';
        link.download = 'Files.zip';
        const data = file_uuid ? { files: [file_uuid] } : undefined;
        const res = await downloadFiles(data);

        setIsDownloadingFile(false);
        link.href = URL.createObjectURL(new Blob([res]));
        link.click();
      } catch (err) {
        console.log(err);
        setIsDownloadingFile(false);
        setMessage('There was an error downloading files');
      }
    },
    [downloadFiles, setMessage]
  );

  const handleDeleteFile = useCallback(
    async (file_uuid) => {
      try {
        setIsDeletingFile(true);
        const res = await deleteFiles(file_uuid);
        setIsDeletingFile(false);
        if (!res.success) {
          throw new Error('There was an error deleting the file');
        }
        setMessage('You have successfully deleted the file');
        if (refetch) {
          refetch();
        }
      } catch (err) {
        console.log(err);
        return setMessage('There was an error deleting the file');
      }
    },
    [deleteFiles, refetch, setMessage]
  );

  const handleUploadFile = async (e) => {
    const _newFile = e.target.files;
    if (_newFile && _newFile.length > 0) {
      const base64Files = await transformToBase64List(_newFile);
      setIsUploading(true);
      await uploadFile(base64Files);
      setIsUploading(false);
    }
  };

  return (
    <Box position="relative" pt={!!fileUploads.length ? 8 : 0}>
      {fileUploads && !!fileUploads.length && (
        <DownloadAllWrapper>
          <CustomButton onClick={() => handleFileDownload()} text="Download All" />
        </DownloadAllWrapper>
      )}
      <Box>
        {fileUploads &&
          fileUploads.length > 0 &&
          fileUploads.map((uploadDateAndFilesArr, index) => {
            const [uploadDate, filesArr] = uploadDateAndFilesArr;
            const isLast = index === fileUploads.length - 1;
            return (
              <StyledFileUploadsContainer isLast={isLast} key={`files-${uploadDate}`}>
                <StyledFilesWrapper>
                  {filesArr.map((file, i) => {
                    const { uuid, fileType, name, can_delete } = file;
                    return (
                      // <button
                      //   key={`file-${name}_${i}`}
                      //   onClick={() => handleFileDownload(uuid, name)}
                      //   type="button"
                      //   style={{ all: 'unset', cursor: 'pointer' }}
                      // >
                      <FileThumbnailWrapper mr={2} mb={2} key={`${name}_${i}`}>
                        <FileUploadIconThumbnail fileType={fileType} />
                        <Box>
                          <BodyText text={name} fontSize="small" truncateWord={true} />
                        </Box>
                        <Box display="flex" justifyContent="center">
                          {isDeletingFile ? (
                            <Box>
                              <Spinner align="center" size="25px" />
                            </Box>
                          ) : userType === userTypes.designer && can_delete && deleteFiles ? (
                            <DeleteIconStyled onClick={() => handleDeleteFile(uuid)} />
                          ) : null}
                          {isDownloadingFile ? (
                            <Spinner align="center" size="25px" />
                          ) : (
                            <DownloadIcon onClick={() => handleFileDownload(uuid, name)} />
                          )}
                        </Box>
                      </FileThumbnailWrapper>
                      // </button>
                    );
                  })}
                </StyledFilesWrapper>
                <StyledCaptionWrapper>
                  <CaptionText text={uploadDate} />
                </StyledCaptionWrapper>
              </StyledFileUploadsContainer>
            );
          })}
      </Box>
      {!fileUploads?.length && isAnyBuyer(userType) && (
        <Box my={2}>
          <BodyText text="Waiting for the designer to upload production files." />
        </Box>
      )}
      {!fileUploads?.length && !isAnyBuyer(userType) && withUploader && (
        <Box my={2}>
          <BodyText text="You haven’t uploaded any production files yet. Get Started now!" />
        </Box>
      )}
      {withUploader && uploadFile && (
        <FormFieldSetAndInputWrapper>
          <CustomFieldSet description="Lorem ipsum text" label="Upload files" />
          {isUploading ? (
            <Box>
              <Spinner align="center" />
            </Box>
          ) : (
            <CustomFileUploadField variant="single" fileTypes="files" onChangeHandler={(e) => handleUploadFile(e)} />
          )}
        </FormFieldSetAndInputWrapper>
      )}
    </Box>
  );
};

FileUploads.propTypes = {
  files: PropTypes.object,
  uploadFile: PropTypes.func,
  withUploader: PropTypes.bool,
};

export default FileUploads;
