import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import CustomTitle from '../Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';
import OverlayStatusOption from 'components/Overlay/OverlayStatusOption/OverlayStatusOption';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import { ImageButtonsStyled, TopWrapper, ImageOverlayStyled, ThreeDotWrapper } from './ImageOverlay.styles';
import theme from '../../theme/theme';

const ImageOverlay = ({ title, description, menuOptions, statusData, show, handleOnClick, wrapStatus }) => {
  return (
    <ImageOverlayStyled show={show}>
      <TopWrapper>
        {menuOptions && menuOptions.length && (
          <ThreeDotWrapper>
            <ThreeDotMenu actions={menuOptions} variant="vertical" />
          </ThreeDotWrapper>
        )}
      </TopWrapper>
      <ImageButtonsStyled>
        <Box mb={2}>
          {statusData?.map((status, i) => {
            return (
              <Box mb={1} key={`${status.label}_${i}`}>
                <OverlayStatusOption label={status.label} icon={status.icon} wrapStatus={wrapStatus} />
              </Box>
            );
          })}
        </Box>
        <Box mb={1}>
          <CustomTitle variant="h4" text={title} noWrap />
          <BodyText
            text={description}
            colour={theme.palette.text.secondary}
            handleOnClick={handleOnClick}
            truncateWord
          />
        </Box>
      </ImageButtonsStyled>
    </ImageOverlayStyled>
  );
};

ImageOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  standardOptions: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, onClick: PropTypes.func, icon: PropTypes.element })
  ),
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({ title: PropTypes.string, onClick: PropTypes.func, icon: PropTypes.element })
  ),
  statusData: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, icon: PropTypes.element })),
  show: PropTypes.bool,
};

export default ImageOverlay;
