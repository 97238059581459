import React from 'react';
import { PageContentWrapperStyled } from './PageContentWrapper.styles';

const PageContentWrapper = ({ children, sx, breadcrumbsMargin, noTopMargin }) => {
  return (
    <PageContentWrapperStyled sx={sx} breadcrumbsMargin={breadcrumbsMargin} noTopMargin={noTopMargin}>
      {children}
    </PageContentWrapperStyled>
  );
};

PageContentWrapper.propTypes = {};

export default PageContentWrapper;
