import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import ControlledCustomRadioGroup from '../CustomRadioStandardGroup/ControlledCustomRadioGroup/ControlledCustomRadioGroup';
import ControlledTextField from '../TextFields/CustomTextField/ControlledTextField/ControlledTextField';

import { ErrorMessage } from '../TextFields/CustomTextField/CustomTextField.styles';
import {
  StyledRadioWithTextFieldContainer,
  StyledTextFieldContainer,
} from './ControlledCustomRadioGroupWithTextField.styles';
/*
 * Controlled Input
 * Needs to be wrapped in a <FormProvider {...methods}> that receives all methods from useForm (react-hook-form)
 * name needs to match field used in useForm defaultValues
 */

const ControlledCustomRadioGroupWithTextField = ({
  radioShowBorder,
  name,
  radioOptions,
  radioLabelPlacement,
  radioDirection,
  inputStartAdornment,
  inputPlaceholder,
  inputLabel,
  isModal,
}) => {
  const { formState } = useFormContext();
  const error = useMemo(() => formState.errors?.[name]?.message, [formState.errors, name]);

  return (
    <>
      <StyledRadioWithTextFieldContainer modal={isModal}>
        {!!radioOptions?.length && (
          <Box sx={{ marginTop: { sm: 0, md: isModal ? 0 : 2 } }}>
            <ControlledCustomRadioGroup
              showBorder={radioShowBorder}
              direction={'row'}
              name={name}
              options={radioOptions}
              labelPlacement={radioLabelPlacement}
              removeError={true}
              defaultValue=""
            />
          </Box>
        )}
        <StyledTextFieldContainer radioOptions={radioOptions} modal={isModal}>
          <ControlledTextField
            conditionallyShowValue={(v) => {
              if (radioOptions?.some((option) => option.value === v)) return '';
              else return v;
            }}
            startAdornment={inputStartAdornment}
            placeholder={inputPlaceholder}
            label={inputLabel}
            name={name}
            removeError={true}
            defaultValue=""
          />
        </StyledTextFieldContainer>
      </StyledRadioWithTextFieldContainer>
      {error && (
        <ErrorMessage variant="body1" colour="error.main">
          {error}
        </ErrorMessage>
      )}
    </>
  );
};

ControlledCustomRadioGroupWithTextField.propTypes = {
  name: PropTypes.string,
  radioOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    })
  ),
  radioLabelPlacement: PropTypes.oneOf(['start', 'end']),
  radioDirection: PropTypes.oneOf(['row', 'column']),
};

export default ControlledCustomRadioGroupWithTextField;
