import React, { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router';

import SendIcon from '@mui/icons-material/Send';
import CreateIcon from '@mui/icons-material/Create';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import DesignersList from 'components/DesignersList/DesignersList';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import ModalInviteDesigner from 'components/ModalInviteDesigner/ModalInviteDesigner';

import { MOCK_DESIGNERS_LIST } from 'mockData/mockDesigner';

import { routes } from 'options/routes';

import { useTableFilter } from 'hooks/useTableFilter';

import { useMessageContext } from 'context/MessageContext';

import { getDesignersList, removeFavouritedDesigner } from 'api';

const BuyerDesignersDashboard = ({ isStory }) => {
  const navigate = useNavigate();
  const [setMessage] = useMessageContext();
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const { parsedFilter, ...tableMethods } = useTableFilter({ initialOrder: 'desc', initialOrderBy: 'name' });
  const [designersSelected, setDesignersSelected] = useState([]);

  const setFormError = (error) => {
    setMessage(error);
  };

  const setFormSuccess = (successMSg) => {
    setMessage(successMSg);
  };

  const handleToggleInviteModal = () => {
    setInviteModalOpen((prev) => !prev);
  };

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['designers', parsedFilter],
    () => getDesignersList(`is_favourite=true&${parsedFilter}`),
    {
      enabled: !isStory,
      staleTime: 2 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const designersList = useMemo(
    () => (isStory ? MOCK_DESIGNERS_LIST : data?.designers || []),
    [data?.designers, isStory]
  );
  const totalCount = useMemo(() => (isStory ? MOCK_DESIGNERS_LIST.length : data?.total || []), [data?.total, isStory]);
  const can_create = data?.can_create;

  const handleBriefDesigners = useCallback(() => {
    if (designersSelected.length === 0) {
      setMessage('Please select one or multiple designers to brief');
      return;
    } else return navigate(routes.briefsAdd, { state: { designers: designersSelected } });
  }, [designersSelected, navigate, setMessage]);

  const handleUnfollow = useCallback(
    async (uuid, name) => {
      const res = await removeFavouritedDesigner(uuid);
      if (!res.success) {
        return setMessage(`There was a problem unfollowing designer`);
      }
      setMessage(`${name} was successfully unfollowed.`);
      refetch();
    },
    [refetch, setMessage]
  );

  const findDesigners = useCallback(() => {
    navigate(`${routes.designersIndex}`);
  }, [navigate]);

  const loadingStatus = isLoading || isRefetching;

  const addDesigner = {
    buttonTitle: 'Add Designers',
    icon: <PersonAddIcon />,
    onClick: () => navigate(routes.designersIndex),
  };

  const inviteDesigner = {
    buttonTitle: 'Invite Designer',
    icon: <SendIcon />,
    onClick: () => handleToggleInviteModal(),
  };

  return (
    <>
      <BuyerDashboardWrapper activeTab={routes.buyerDesignersDashboard}>
        <HeaderAndMenu
          title="Designers"
          menuOptions={
            can_create
              ? [
                  addDesigner,
                  inviteDesigner,
                  { buttonTitle: 'Brief Designers', icon: <CreateIcon />, onClick: () => handleBriefDesigners() },
                ]
              : [addDesigner, inviteDesigner]
          }
        />
        <DesignersList
          designersList={designersList}
          totalCount={totalCount}
          loadingStatus={loadingStatus}
          designersSelected={designersSelected}
          tableMethods={tableMethods}
          setDesignersSelected={setDesignersSelected}
          handleUnfollow={handleUnfollow}
          findDesigners={findDesigners}
          setMessage={setMessage}
        />
      </BuyerDashboardWrapper>
      <ModalInviteDesigner
        open={inviteModalOpen}
        handleClose={handleToggleInviteModal}
        setFormError={setFormError}
        setFormSuccess={setFormSuccess}
      />
    </>
  );
};

BuyerDesignersDashboard.propTypes = {};

export default BuyerDesignersDashboard;
