import React from 'react';
import PropTypes from 'prop-types';

import ProductPropertyTab from 'components/ProductPropertyTab/ProductPropertyTab';
import { TabsStyled } from './ProductPropertyTabHeader.styles';

const ProductPropertyTabHeader = ({ value, onChange, options }) => {
  return (
    <TabsStyled value={value} onChange={onChange} TabIndicatorProps={{ style: { display: 'none' } }}>
      {options.map((option) => (
        <ProductPropertyTab {...option} variant={option.variant} key={option.value} />
      ))}
    </TabsStyled>
  );
};

ProductPropertyTabHeader.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      variant: PropTypes.oneOf(['standard', 'active', 'completed']),
    })
  ),
};

export default React.memo(ProductPropertyTabHeader);
