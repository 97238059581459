import { Box, Stepper, styled, Grid } from '@mui/material';

export const FormPageWrapper = styled(Grid)(({ theme, flexDirection }) => ({
  display: 'flex',
  flexDirection: flexDirection,
}));

export const TitleContainer = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(1 * 1.5)} 0 ${theme.spacing(1 * 1.5)} ${theme.spacing(1)}`,
  borderBottom: `1px solid ${theme.palette.common.black}`,
  marginLeft: theme.spacing(1),

  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    paddingLeft: 0,
  },
}));

export const StepperContainer = styled(Grid)(({ theme }) => ({
  width: 206,
  borderRight: `1px solid ${theme.palette.common.black}`,
  paddingTop: `${theme.spacing(1)}`,
  position: 'sticky',
  top: 140,
  backgroundColor: 'white',
  [theme.breakpoints.down('md')]: {
    top: 138,
    width: '100%',
    borderRight: 0,
    margin: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
    zIndex: 1001,
  },
  [theme.breakpoints.down('sm')]: {
    // top: 138,
  },
}));

export const StepperStyled = styled(Stepper)(({ theme }) => ({
  position: 'sticky',
  top: 220,
  paddingTop: theme.spacing(2),
  backgroundColor: 'white',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    height: 80,
    borderBottom: '1px solid',
    zIndex: 1001,
  },
  '& .MuiStepIcon-root.Mui-active': {
    color: theme.palette.primary.main,
  },

  '& .MuiStepIcon-root.Mui-completed': {
    color: theme.palette.success.light,
  },

  '& .MuiButtonBase-root': {
    padding: '0 8px',
  },

  '& .MuiStepLabel-label, .MuiStepLabel-label.Mui-active, .MuiStepLabel-label.Mui-completed': {
    fontWeight: 600,
    fontSize: '0.75rem',
  },

  '& .MuiStepConnector-line': {
    minHeight: '80px',
    borderLeftWidth: '2px',
    borderTopWidth: '2px',
  },

  '& .MuiStepConnector-vertical.Mui-active .MuiStepConnector-line': {
    borderColor: theme.palette.success.light,
  },

  '& .MuiStepConnector-vertical.Mui-completed .MuiStepConnector-line': {
    borderColor: theme.palette.success.light,
  },

  '& .MuiStepConnector-root.MuiStepConnector-horizontal': {
    top: '-6px',
    left: 'calc(-50% + 4px)',
    right: 'calc(50% + 20px)',
  },

  '& .MuiStepConnector-horizontal.Mui-active .MuiStepConnector-line': {
    borderColor: theme.palette.success.light,
  },

  '& .MuiStepConnector-horizontal.Mui-completed .MuiStepConnector-line': {
    borderColor: theme.palette.success.light,
  },

  '& .MuiStepLabel-labelContainer': {
    [theme.breakpoints.down('md')]: {
      height: '36px',
    },
  },
}));

export const ContentContainer = styled(Grid)(({ theme }) => ({
  width: '80%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

export const FormContainer = styled('div')(({ theme }) => ({
  padding: `0 0 0 ${theme.spacing(2)}`,
  [theme.breakpoints.down('sm')]: {
    padding: 0,
  },
}));

export const SpinnerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '80%',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));
