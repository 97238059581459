import { styled } from '@mui/system';

export const SupportingDetailsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.text.secondary,
  '& p': {
    fontSize: '0.875rem',
    lineHeight: 1.8,
  },
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  fontSize: '10px',
  '& .MuiSvgIcon-root': {
    color: theme.palette.text.secondary,
  },
}));
