import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

// general modal to accept brief, proposal, agreement
const ModalConfirmAccept = ({ open, handleClose, handleSubmit, name, titleOverride, textOverride }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmitAction = useCallback(async () => {
    setIsLoading(true);
    await handleSubmit(true);
    setIsLoading(false);
    handleClose();
  }, [handleClose, handleSubmit]);

  return (
    <CustomModal title={titleOverride ? titleOverride : `Accept ${name}`} open={open} handleClose={handleClose}>
      {isLoading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <StyledModalContentWrapper>
          <Box mt={1}>
            <BodyText
              text={textOverride ? `${textOverride}` : `Please confirm that you want to accept this ${name}.`}
            />
          </Box>
          <StyledActionButtonsContainer>
            <CustomButton variant="outlined" onClick={handleClose} text="Cancel" />
            <CustomButton onClick={handleSubmitAction} text="Confirm" />
          </StyledActionButtonsContainer>
        </StyledModalContentWrapper>
      )}
    </CustomModal>
  );
};

ModalConfirmAccept.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.oneOf(['proposal', 'brief', 'agreement']),
};

export default ModalConfirmAccept;
