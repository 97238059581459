import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

import ProductConversionSuccess from './ProductConversionSuccess';

import { userTypes } from 'options/userTypes';

import { routes } from 'options/routes';

const ProductConversionThanks = ({ userType }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (userType && userType !== userTypes.designer) navigate(routes.home);
  }, [userType, navigate]);

  return (
    <DesignerDashboardWrapper
      activeTab={routes.productsDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[
            { label: 'Products', onClick: () => navigate(routes.productsDashboard) },
            { label: 'Add Product', onClick: () => navigate(routes.productsAdd) },
          ]}
          currentPage={'Thanks'}
        />
      }
    >
      <ProductConversionSuccess />
    </DesignerDashboardWrapper>
  );
};

export default ProductConversionThanks;
