import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Box, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PillTag from 'components/PillTag/PillTag';
import { CustomSelectInputLabelStyled, CustomSelectStyled, FormControlStyled } from './CustomSelectField.styles';
import { ErrorMessage } from '../TextFields/CustomTextField/CustomTextField.styles';

const CustomSelectField = ({
  label,
  onChange,
  onBlur,
  options,
  value,
  error,
  handleRemove,
  fieldStyle = 'primary',
}) => {
  const pillTagStyles = useMemo(
    () => ({
      zIndex: 2,
      margin: '8px 4px 0',
    }),
    []
  );

  const pillColour = fieldStyle === 'primary' ? 'white' : 'gray';
  const [showHiddenOptions, setShowHiddenOptions] = useState(false);

  return (
    <>
      <FormControlStyled variant="standard" fullWidth hiddenLabel={true} error={!!error} fieldStyle={fieldStyle}>
        {!value.length && <CustomSelectInputLabelStyled shrink={false}>{label}</CustomSelectInputLabelStyled>}
        {value?.map((optionSelected) => (
          <PillTag
            option={optionSelected}
            removeSelection={handleRemove}
            key={optionSelected.value}
            styleOverrides={pillTagStyles}
            colour={pillColour}
          />
        ))}
        <CustomSelectStyled
          labelId={`${label}-select-label`}
          value=""
          label={label}
          onChange={onChange}
          onBlur={onBlur}
          MenuProps={{
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
            elevation: 1,
            anchorPosition: {
              top: 10,
            },
          }}
          IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
          fieldStyle={fieldStyle}
          error={!!error}
        >
          {options?.map((option) => (
            ((option.hide && showHiddenOptions) || (!showHiddenOptions && option.value === 'show-more-options') || (!option.hide &&  option.value !== 'show-more-options' )) ? 
            <MenuItem value={option} key={option.label}
              onClickCapture={(e) => {
                if (option.value === 'show-more-options') {
                  e.stopPropagation();
                  setShowHiddenOptions(true);
                }
              }}
            >
              {option.label}
            </MenuItem> : <></>
          ))}
        </CustomSelectStyled>
      </FormControlStyled>
      {error && (
        <Box height="20px">
          <ErrorMessage variant="body1" colour="error.main">
            {error}
          </ErrorMessage>
        </Box>
      )}
    </>
  );
};

CustomSelectField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  optionsSelected: PropTypes.array,
  error: PropTypes.string,
  handleRemove: PropTypes.func,
  fieldStyle: PropTypes.oneOf(['primary', 'secondary']),
};

export default React.memo(CustomSelectField);
