import { styled, Modal, Box } from '@mui/material';

export const CustomModalStyled = styled(Modal)(({ theme }) => ({}));

export const CustomModalContentContainer = styled('div')(({ theme, variant, wide }) => ({
  backgroundColor: theme.palette.white.main,
  width: wide ? '80%' : variant === 'secondary' ? 550 : 400,
  maxWidth: 1080,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '16px 16px 24px',
  boxSizing: 'border-box',
  zIndex: '5000',
  maxHeight: '90%',
  overflowY: 'auto',
}));

export const StyledModalContentWrapper = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(1)} 0`,
}));

export const CustomModalTitleAndCloseBtnWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.palette.common.black}`,
  paddingBottom: theme.spacing(1),

  '& .MuiButtonBase-root': {
    padding: 0,
  },
}));

export const StyledActionButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(2),

  '& button:nth-of-type(1)': {
    marginRight: theme.spacing(1),
  },

  '& button:nth-of-type(2)': {
    marginLeft: theme.spacing(1),
  },

  '& button': {
    width: '100%',
  },
}));
