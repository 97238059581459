import React, { useCallback, useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';

import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import DesignersHeader from 'components/DesignersHeader/DesignersHeader';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';

import { addFilters } from 'utils/addFilters';
import { getDesignersList, getFeaturedDesigners } from 'api';
import { debounce } from 'debounce';
import { CATEGORIES_OPTIONS, EXPERIENCE_OPTIONS } from 'options/findDesignerOptions';
import { DESIGNER_COUNTRIES } from 'options/countries';
import { userTypes } from 'options/userTypes';
import NamesList from 'components/NamesList/NamesList';
import { useNavigate } from 'react-router';
import { LoadingContainer } from 'components/ProductsMegaMenu/ProductsMegaMenu.styles';
import Spinner from 'components/Spinner/Spinner';
import { Box } from '@mui/system';
import BuyerHomeFeaturedDesigners from 'components/BuyerHomeFeaturedDesigners/BuyerHomeFeaturedDesigners';
import { BuyerLandingData } from 'pages/BuyerLanding/BuyerLanding.data';
import { routes } from 'options/routes';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';
import { useQuery } from 'react-query';

const DesignersIndex = () => {
  const [filter, setFilter] = useState({});
  const [designersList, setDesignersList] = useState(null);
  const [searchTerm, setSearchTerm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const parsedFilter = useMemo(
    () =>
      queryString.stringify({
        ...filter,
        search: searchTerm && searchTerm.length > 2 ? searchTerm : '',
        data_type: 'partial',
      }),
    [filter, searchTerm]
  );

  const { data } = useQuery(['featuredDesigners'], () => getFeaturedDesigners(), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
  });

  const featDesigners = useMemo(
    () =>
      data?.featured_designers
        ? data?.featured_designers.map((designer) => {
            const { designer_uuid, portrait, first_name, last_name, location } = designer;
            return {
              onClick: () => navigate(`${routes.designersIndex}/${designer_uuid}`),
              imageUrl: portrait?.fileSrc,
              label: first_name + ' ' + last_name,
              subtitle: location,
            };
          })
        : null,
    [data?.featured_designers, navigate]
  );

  const fetchDesigners = async (query) => {
    setIsLoading(true);
    try {
      const res = await getDesignersList(query);
      setDesignersList(res.designers);
    } catch (error) {
      if (error) setError(error.message);
    }
    setIsLoading(false);
  };

  const filtersList = useMemo(
    () => ({
      categories: [...CATEGORIES_OPTIONS],
      location: [...DESIGNER_COUNTRIES],
      experience: [...EXPERIENCE_OPTIONS],
    }),
    []
  );

  const debouncedFetch = debounce((query) => fetchDesigners(query), 200);

  // console.log(designersList);

  const menuFilterOptions = useMemo(() => {
    const FIND_DESIGNER_MENU = [
      {
        label: 'Categories',
        id: 'categories',
        actions: [],
      },
      {
        label: 'Location',
        id: 'location',
        actions: [],
      },
      {
        label: 'Experience',
        id: 'experience',
        actions: [],
      },
    ];
    return FIND_DESIGNER_MENU.map((menuItem) => {
      return {
        ...menuItem,
        actions: addFilters(menuItem, filtersList, setFilter, filter),
      };
    });
  }, [filter, filtersList]);

  useEffect(() => {
    debouncedFetch(parsedFilter);
  }, [parsedFilter]);

  const namesListData = useMemo(() => {
    if (!designersList) return null;
    const alphabet = [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
    ];
    const designerOnClick = (uuid) => navigate(`${routes.designersIndex}/${uuid}`);
    const namesList = designersList.map((designer) => {
      return { label: designer.first_name + ' ' + designer.last_name, onClick: () => designerOnClick(designer.uuid) };
    });
    const sortedDesignersNamesListByAlphabet = alphabet.map((l) => {
      const thisLetterDesigners = namesList?.filter((designer) => {
        return designer.label.charAt(0).toUpperCase() === l;
      });

      return { title: l, options: thisLetterDesigners };
    });
    return sortedDesignersNamesListByAlphabet;
  }, [designersList]);

  const hasFilter = useMemo(() => {
    const initialFilter = 'data_type=partial&search=';
    if (parsedFilter !== initialFilter) return true;
    else return false;
  }, [parsedFilter]);

  const resetFilter = useCallback(() => {
    setFilter({});
    setSearchTerm('');
  }, []);

  return (
    <PageWrapper userType={userTypes.buyer} showSecondaryFooter={false}>
      <PageContentWrapper>
        <DesignersHeader />
        <Box mt={8}>
          <BuyerHomeFeaturedDesigners noBodyText={true} data={BuyerLandingData.featuredDesigners} />
        </Box>
        {featDesigners && <BuyerHomeFeaturedDesigners noBodyText={true} data={featDesigners} />}
        <Box mt={8}>
          <SearchAndFilterOptions
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            menuOptions={menuFilterOptions}
            hasFilter={hasFilter}
            resetFilter={resetFilter}
            resetBtnFlexEnd={true}
          />
          {isLoading && (
            <LoadingContainer>
              <Spinner align={'center'} />
            </LoadingContainer>
          )}
          {namesListData && !isLoading && (
            <Box mt={2}>
              <NamesList namesList={namesListData} />
            </Box>
          )}
        </Box>
      </PageContentWrapper>
    </PageWrapper>
  );
};

DesignersIndex.propTypes = {};

export default DesignersIndex;
