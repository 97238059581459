import { Controller, FormProvider, useForm } from 'react-hook-form';

import { Box } from '@mui/material';
import CustomAvatar from 'components/CustomAvatar/CustomAvatar';
import StarRating from 'components/StarRating/StarRating';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { validation, yupObject } from 'utils/validation';
import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';
import { useState } from 'react';
import Spinner from 'components/Spinner/Spinner';

const validationSchema = yupObject({
  ...validation.reviewForm,
});

const BriefProposalReviewForm = ({ designer, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    defaultValues: {
      review: '',
      rating: null,
    },
    mode: 'onChange',
    resolver,
  });

  if (!designer) return null;

  const {
    first_name,
    portrait: { fileSrc, fileType },
  } = designer;

  const handleSubmit = async (d) => {
    setIsLoading(true);
    await onSubmit(d);
    setIsLoading(false);
  };

  return (
    <Box>
      {isLoading ? (
        <Box my={2}>
          <Spinner align="center" flex={0} />
        </Box>
      ) : (
        <>
          {fileSrc && (
            <Box display="flex" justifyContent="center" mb={1}>
              <CustomAvatar imageUrl={fileSrc} fileType={fileType} />
            </Box>
          )}
          <CustomTitle text={`What was it like working with ${first_name}?`} variant="h3" align="center" />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>
              <Box mt={2} display="flex" justifyContent="center">
                <Controller
                  name="rating"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Box>
                      <StarRating value={value} onChange={(e, newVal) => onChange(newVal)} />
                      {error && error.message && <ErrorMessage>{error.message}</ErrorMessage>}
                    </Box>
                  )}
                />
              </Box>
              <CustomFieldSet label={`Add a few words about ${first_name}`}>
                <ControlledTextField label="Review" name="review" />
              </CustomFieldSet>
              <Box mt={2}>
                <CustomButton text="Submit Review" buttonType="submit" fullWidth />
              </Box>
            </form>
          </FormProvider>
        </>
      )}
    </Box>
  );
};

export default BriefProposalReviewForm;
