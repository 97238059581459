import { useCallback, useState } from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';

import { Box } from '@mui/material';
import ProductSingle from 'components/ProductSingle/ProductSingle';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import ModalFindBuyer from 'components/ModalFindBuyer/ModalFindBuyer';

import { routes } from 'options/routes';

import { getErrorMessage } from 'utils/getErrorMessage';
import { parsePitchToConvert } from '../DesignerPitchesSingle.utils';

import { resendDesignerSinglePitch } from 'api';

export const DesignerPitchesSinglePitchTab = () => {
  const queryClient = useQueryClient();
  const { pitch_uuid, buyer_pitch_uuid } = useParams();
  const [pitch, setMessage, userType] = useOutletContext();

  const navigate = useNavigate();

  const { can_convert } = pitch;

  const [openFindBuyers, setOpenFindBuyers] = useState(false);

  const methods = useForm({
    defaultValues: {
      buyers: [],
    },
  });

  const handleSendToAnotherBuyer = useCallback(async () => {
    try {
      const { buyers } = methods.getValues();
      const res = await resendDesignerSinglePitch(pitch_uuid, { company_uuid: buyers[0]?.company_uuid });
      if (!res.success) {
        return setMessage(getErrorMessage(res));
      }
      // set can_convert to false in pitch data
      queryClient.setQueryData(['designerSinglePitch', pitch_uuid, buyer_pitch_uuid], (oldData) => ({
        success: true,
        product: {
          ...oldData.product,
          can_convert: false,
        },
      }));
      setMessage('You have resent pitch to another buyer with success');
    } catch (err) {
      console.log(err);
    }
  }, [buyer_pitch_uuid, methods, pitch_uuid, queryClient, setMessage]);

  return (
    <Box>
      <Box mt={2}>
        <ProductSingle data={pitch} userType={userType} variant="product_view" />
        {can_convert && (
          <Box display="flex" my={2}>
            <Box mr={2}>
              <CustomButton
                text="Upload to Marketplace"
                onClick={() =>
                  navigate(`${routes.productsAdd}`, {
                    state: { proposal: parsePitchToConvert(pitch) },
                  })
                }
              />
            </Box>
            <Box>
              <CustomButton text="Send to Another Buyer" onClick={() => setOpenFindBuyers(true)} />
            </Box>
          </Box>
        )}
      </Box>
      <FormProvider {...methods}>
        {openFindBuyers && (
          <ModalFindBuyer
            onHandleAccept={handleSendToAnotherBuyer}
            variant="pitch"
            open={openFindBuyers}
            handleClose={() => setOpenFindBuyers(false)}
            product_uuid={pitch_uuid}
          />
        )}
      </FormProvider>
    </Box>
  );
};

export default DesignerPitchesSinglePitchTab;
