import React from 'react';

import { Box } from '@mui/material';
import NewPitchBuyersList from 'components/NewPitchBuyersList/NewPitchBuyersList';

const SelectCompanyForm = (props) => {
  return (
    <Box>
      <NewPitchBuyersList />
    </Box>
  );
};

SelectCompanyForm.propTypes = {};

export default SelectCompanyForm;
