import { useLocation } from 'react-router';
import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import SectionTab from 'components/SectionTab/SectionTab';
import { StyledTabsContainer } from './SectionTabsList.styles';

const SectionTabsList = ({ options, onClick, orientation }) => {
  const location = useLocation();
  const pathnameArr = location?.pathname.split('/');
  const pathName = pathnameArr[pathnameArr.length - 1];

  const [activeTab, setActiveTab] = useState(0);

  const handleChange = useCallback(
    (index, path) => {
      setActiveTab(index);
      onClick(path);
    },
    [onClick]
  );

  useEffect(() => {
    // The relevant path will always be at index 4
    const index = options.findIndex((option) => option.path === `/${pathName}`);
    setActiveTab(index);
  }, [options, pathName]);

  const flexDirection = orientation === 'horizontal' ? 'row' : 'column';

  return (
    <StyledTabsContainer display="flex" flexDirection={flexDirection}>
      {options.map((option, index) => {
        return (
          <SectionTab
            label={option.label}
            complete={option.completed}
            onClick={() => handleChange(index, option.path)}
            active={activeTab === index}
            key={option.label}
            disabled={option.disabled}
          />
        );
      })}
    </StyledTabsContainer>
  );
};

SectionTabsList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
};

export default SectionTabsList;
