import React, { useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import PropTypes from 'prop-types';

import ProductListPreview from 'components/ProductListPreview/ProductListPreview';
import ModalPreviewProduct from 'components/ModalPreviewProduct/ModalPreviewProduct';

import { useTableFilter } from 'hooks/useTableFilter';

import { getDesignerMarketPlaceProducts } from 'api';

const ProductListPreviewPage = (props) => {
  const { parsedFilter, ...tableMethods } = useTableFilter({ initialOrder: 'desc', initialOrderBy: 'created' });

  const [selectedProductPreview, setSelectedProductPreview] = useState('');

  const { setValue, watch } = useFormContext();
  const itemsSelected = watch('product_uuid');

  const { data, isLoading, isRefetching } = useQuery(
    ['designerConvertableProductsList', parsedFilter],
    () => getDesignerMarketPlaceProducts(parsedFilter),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const handleCheckboxClick = useCallback(
    (product, isSelected) => {
      const { product_uuid } = product;
      setValue('product_uuid', isSelected ? [] : [product_uuid]);
    },
    [setValue]
  );

  const onPreviewClick = useCallback((uuid) => {
    setSelectedProductPreview(uuid);
  }, []);

  const productsList = data?.products || [];
  const totalCount = data?.total || 0;
  const loading = isLoading || isRefetching;

  return (
    <>
      <ProductListPreview
        productsList={productsList}
        totalCount={totalCount}
        loading={loading}
        tableMethods={tableMethods}
        itemsSelected={itemsSelected}
        handleCheckboxClick={handleCheckboxClick}
        onPreviewClick={onPreviewClick}
      />
      {!!selectedProductPreview && (
        <ModalPreviewProduct
          open={!!selectedProductPreview}
          handleClose={() => setSelectedProductPreview('')}
          product_uuid={selectedProductPreview}
        />
      )}
    </>
  );
};

ProductListPreviewPage.propTypes = {};

export default ProductListPreviewPage;
