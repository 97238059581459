import { useNavigate } from 'react-router';

import { Box } from '@mui/material';

import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import ButtonPill from 'components/ButtonPill/ButtonPill';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import { ContainerStyled, MessageContainerStyled } from './NewBriefThanks.styles';

import { routes } from 'options/routes';

const NewBriefThanks = ({ isEditing }) => {
  const navigate = useNavigate();
  return (
    <ContainerStyled>
      <CustomFieldSet
        label={isEditing ? 'Your Brief has been edited.' : 'Your Brief has been sent to the Designer'}
        description={
          isEditing
            ? 'You should receive a confirmation email soon.'
            : 'You should receive a confirmation email soon, wait for their response or create a new brief.'
        }
        descriptionMarginTop={2}
        titleFontSize={24}
        tooltipParagrahs={[
          'Thank you for your brief',
          'Your brief has been sent to the selected designers, you should receive a confirmation email soon. Please check your spam or contact us if you have any issues at support@buydesign.com.',
          "Please wait for their response from the designer; this may not be immediate, if you wish you can also message the designer; go to your briefs tab and select brief and select 'send a message' inside the three dots.",
        ]}
      />
      {/* <CustomTitle variant="h3" text="Your Brief has been sent to the Designer" /> */}
      <MessageContainerStyled mt={2}>
        {/* <BodyText
          text="You should receive a confirmation email soon, wait for their response or create a new brief."
          variant="body2"
        /> */}
        <Box mt={2}>
          <ButtonPill text="Check your email for status confirmation" />
        </Box>
      </MessageContainerStyled>
      <Box display="flex" mt={4}>
        <Box mr={2}>
          <CustomButton
            text="View all briefs"
            onClick={() => navigate(routes.briefsDashboard, { state: { shouldRefetch: true } })}
            colour="secondary"
          />
        </Box>
        <Box>
          <CustomButton text="Create new brief" onClick={() => navigate(routes.briefsAdd)} />
        </Box>
      </Box>
    </ContainerStyled>
  );
};

export default NewBriefThanks;
