import { Box, ImageListItem } from '@mui/material';
import React from 'react';

export const BriefImageGroup = ({ images }) => {
  return (
    <Box display="flex" gap="8px" flexWrap="wrap">
      {images?.map(({ fileSrc, fileType }, index) => {
        const src = fileType === 'url' ? atob(fileSrc) : fileSrc;
        return (
          <ImageListItem key={`${fileType}_${index}`}>
            <img
              src={src}
              alt="product"
              loading="lazy"
              style={{
                borderRadius: '5px',
                height: '350px',
                width: 'auto'
              }}
            />
          </ImageListItem>
        );
      })}
    </Box>
  );
};
