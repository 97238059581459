import { Routes, Route, Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Login from 'pages/Authentication/Login/Login';
import SignupLanding from 'pages/Authentication/Signup/SignupLanding/SignupLanding';
import SignUpForm from 'pages/Authentication/Signup/SignUpForm/SignUpForm';
import SignUpVerify from 'pages/Authentication/Signup/SignUpVerify/SignUpVerify';
import SignUpSuccess from 'pages/Authentication/Signup/SignUpSuccess/SignUpSuccess';
import ForgotPassword from 'pages/Authentication/ForgotPassword/ForgotPassword';
import ForgotPasswordSuccess from 'pages/Authentication/ForgotPassword/ForgotPasswordSuccess';
import ResetPassword from 'pages/Authentication/ResetPassword/ResetPassword';
import ResetPasswordSuccessPage from 'pages/Authentication/ResetPassword/ResetPasswordSuccess';
import BuyerLanding from 'pages/BuyerLanding/BuyerLanding';
import Profile from 'pages/Profile/Profile';
import AccountDesigner from 'pages/Account/AccountDesigner';
import AccountBuyer from 'pages/Account/AccountBuyer';
import DesignerDashboardLanding from 'pages/Dashboard/Designer/DesignerDashboardLanding/DesignerDashboardLanding';
import DesignerProductsDashboard from 'pages/Dashboard/Designer/Products/DesignerProductsDashboard/DesignerProductsDashboard';
import ProductProposal from 'pages/Dashboard/Designer/Products/ProductProposal/ProductProposal';
import BuyerBriefsDashboard from 'pages/Dashboard/Buyer/Briefs/BuyerBriefsDashboard/BuyerBriefsDashboard';
import BuyerDashboardLanding from 'pages/Dashboard/Buyer/BuyerDashboardLanding/BuyerDashboardLanding';
import BuyerBriefNew from 'pages/Dashboard/Buyer/Briefs/BuyerBriefNew/BuyerBriefNew';
import BuyerBriefThanks from 'pages/Dashboard/Buyer/Briefs/BuyerBriefsThanks/BuyerBriefsThanks';
import BuyerTermsDashboard from 'pages/Dashboard/Buyer/Terms/BuyerTermsDashboard/BuyerTermsDashboard';
import BuyerTermsNew from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/BuyerTermsNew';
import BuyerTermsThanks from 'pages/Dashboard/Buyer/Terms/BuyerTermsThanks/BuyerTermsThanks';
import DesignerBriefsDashboard from 'pages/Dashboard/Designer/Briefs/DesignerBriefsDashboard/DesignerBriefsDashboard';
import DesignerSingleBriefLanding from 'pages/Dashboard/Designer/Briefs/DesignerSingleBriefLanding/DesignerSingleBriefLanding';
import DesignerBriefDetails from 'pages/Dashboard/Designer/Briefs/DesignerBriefDetails/DesignerBriefDetails';
import DesignerBriefProposalList from 'pages/Dashboard/Designer/Briefs/DesignerBriefProposalList/DesignerBriefProposalList';
import DesignerBriefProposalDetails from 'pages/Dashboard/Designer/Briefs/DesignerBriefProposalDetails/DesignerBriefProposalDetails';
import DesignerBriefProposalNew from 'pages/Dashboard/Designer/Briefs/DesignerBriefProposalNew/DesignerBriefProposalNew';
import DesignersIndex from 'pages/Designers/DesignersIndex/DesignersIndex';
import BuyerDesignerProfile from 'pages/Designers/BuyerDesignerProfile/BuyerDesignerProfile';
import DesignerLanding from 'pages/DesignerLanding/DesignerLanding';
import Products from 'pages/Products/Products/Products';
import ProductsSearch from 'pages/Products/ProductsSearch/ProductsSearch';
import ProductsCategorySearch from 'pages/Products/ProductsCategorySearch/ProductsCategorySearch';
import ProductsSpaces from 'pages/Products/ProductsSpaces/ProductsSpaces';
import SingleProduct from 'pages/SingleProduct/SingleProduct';
import BuyerMoodboardsDashboard from 'pages/Dashboard/Buyer/Moodboards/BuyerMoodboardsDashboard/BuyerMoodboardsDashboard';
import BuyerSingleMoodboard from 'pages/Dashboard/Buyer/Moodboards/BuyerSingleMoodboard/BuyerSingleMoodboard';
import BuyerDesignersDashboard from 'pages/Dashboard/Buyer/BuyerDesignersDashboard/BuyerDesignersDashboard';
import DesignerBriefAgreement from 'pages/Dashboard/Designer/Briefs/DesignerBriefAgreement/DesignerBriefAgreement';
import DesignerBriefDesigns from 'pages/Dashboard/Designer/Briefs/DesignerBriefDesigns/DesignerBriefDesigns';
import ProductConversionThanks from 'pages/Dashboard/Designer/Products/ProductConversionThanks/ProductConversionThanks';
import BuyerBriefProposalList from 'pages/Dashboard/Buyer/Briefs/BuyerBriefProposalList/BuyerBriefProposalList';
import BuyerSingleBriefLanding from 'pages/Dashboard/Buyer/Briefs/BriefsSingle/BuyerSingleBriefLanding/BuyerSingleBriefLanding';
import BuyerBriefDetails from 'pages/Dashboard/Buyer/Briefs/BriefsSingle/BuyerBriefDetails/BuyerBriefDetails';
import BuyerBriefProposalDetails from 'pages/Dashboard/Buyer/Briefs/BriefsSingle/BuyerBriefProposalDetails/BuyerBriefProposalDetails';
import BuyerProposalAgreement from 'pages/Dashboard/Buyer/Briefs/BriefsSingle/BuyerProposalAgreement/BuyerProposalAgreement';
import BuyerProposalDesigns from 'pages/Dashboard/Buyer/Briefs/BriefsSingle/BuyerProposalDesigns/BuyerProposalDesigns';
import BuyerReviewDesigner from 'pages/Dashboard/Buyer/Briefs/BriefsSingle/BuyerReviewDesigner/BuyerReviewDesigner';
import HelpDashboardLanding from 'pages/Help/HelpDashboardLanding';
import DesignerProductBuyerList from 'pages/Dashboard/Designer/SingleProduct/DesignerProductBuyerList/DesignerProductBuyerList';
import DesignerSingleProductLanding from 'pages/Dashboard/Designer/SingleProduct/DesignerSingleProductLanding/DesignerSingleProductLanding';
import DesignerSingleProductTab from 'pages/Dashboard/Designer/SingleProduct/DesignerSingleProductTab/DesignerSingleProductTab';
import DesignerSingleProductAgreementTab from 'pages/Dashboard/Designer/SingleProduct/DesignerSingleProductAgreementTab/DesignerSingleProductAgreementTab';
import DesignerSingleProductFilesTab from 'pages/Dashboard/Designer/SingleProduct/DesignerSingleProductFilesTab/DesignerSingleProductFilesTab';
import BuyerSingleProductLanding from 'pages/Dashboard/Buyer/SingleProduct/BuyerSingleProductLanding/BuyerSingleProductLanding';
import BuyerSingleProductSuccess from 'pages/Dashboard/Buyer/SingleProduct/BuyerSingleProductSuccess/BuyerSingleProductSuccess';
import BuyerProductsListDashboard from 'pages/Dashboard/Buyer/Products/BuyerProductsListDashboard/BuyerProductsListDashboard';
import BuyerSingleProductTab from 'pages/Dashboard/Buyer/SingleProduct/BuyerSingleProductTab/BuyerSingleProductTab';
import BuyerSingleProductAgreementTab from 'pages/Dashboard/Buyer/SingleProduct/BuyerSingleProductAgreementTab/BuyerSingleProductAgreementTab';
import BuyerSingleProductFilesTab from 'pages/Dashboard/Buyer/SingleProduct/BuyerSingleProductFilesTab/BuyerSingleProductFilesTab';
import BuyerSingleProductReviewTab from 'pages/Dashboard/Buyer/SingleProduct/BuyerSingleProductReviewTab/BuyerSingleProductReviewTab';
import BuyerOpenCallsDashboard from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsDashboard/BuyerOpenCallsDashboard';
import BuyerOpenCallsProposalsList from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsProposalsList/BuyerOpenCallsProposalsList';
import BuyerOpenCallsNew from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsNew/BuyerOpenCallsNew';
import BuyerOpenCallsThanks from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsThanks/BuyerOpenCallsThanks';
import BuyerOpenCallsSingleCallLanding from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsSingleCall/BuyerOpenCallsSingleCallLanding/BuyerOpenCallsSingleCallLanding';
import BuyerOpenCallsDetailsTab from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsSingleCall/BuyerOpenCallsDetailsTab/BuyerOpenCallsDetailsTab';
import BuyerOpenCallProposalTab from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsSingleCall/BuyerOpenCallsProposalTab/BuyerOpenCallsProposalTab';
import BuyerOpenCallsAgreementTab from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsSingleCall/BuyerOpenCallsAgreementTab/BuyerOpenCallsAgreementTab';
import BuyerOpenCallsFilesTab from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsSingleCall/BuyerOpenCallsFilesTab/BuyerOpenCallsFilesTab';
import BuyerOpenCallsReview from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsSingleCall/BuyerOpenCallsReview/BuyerOpenCallsReview';
import BuyerOpenCallsSingleSuccess from 'pages/Dashboard/Buyer/BuyerOpenCalls/BuyerOpenCallsSingleCall/BuyerOpenCallsSingleSuccess/BuyerOpenCallsSingleSuccess';
import DesignerOpenCallsDashboard from 'pages/Dashboard/Designer/DesignerOpenCalls/DesignerOpenCallsDashboard/DesignerOpenCallsDashboard';
import DesignerOpenCallsSingleLanding from 'pages/Dashboard/Designer/DesignerOpenCalls/DesignerOpenCallsSingleLanding/DesignerOpenCallsSingleLanding';
import DesignerOpenCallsSingleTab from 'pages/Dashboard/Designer/DesignerOpenCalls/DesignerOpenCallsSingleTab/DesignerOpenCallsSingleTab';
import DesignerOpenCallsProposalTab from 'pages/Dashboard/Designer/DesignerOpenCalls/DesignerOpenCallsProposalTab/DesignerOpenCallsProposalTab';
import DesignerOpenCallsAgreementTab from 'pages/Dashboard/Designer/DesignerOpenCalls/DesignerOpenCallsAgreementTab/DesignerOpenCallsAgreementTab';
import DesignerOpenCallsFilesTab from 'pages/Dashboard/Designer/DesignerOpenCalls/DesignerOpenCallsFilesTab/DesignerOpenCallsFilesTab';
import DesignerOpenCallsProposalNew from 'pages/Dashboard/Designer/DesignerOpenCalls/DesignerOpenCallsProposalNew/DesignerOpenCallsProposalNew';
import DesignerPitchesDashboard from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchesDashboard/DesignerPitchesDashboard';
import DesignerPitchesBuyersList from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchesBuyersList/DesignerPitchesBuyersList';
import DesignerPitchesSinglePitchTab from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchesSingle/DesignerPitchesSinglePitchTab/DesignerPitchesSinglePitchTab';
import DesignerPitchesSingleAgreementTab from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchesSingle/DesignerPitchesSingleAgreementTab/DesignerPitchesSingleAgreementTab';
import DesignerPitchesSingleFilesTab from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchesSingle/DesignerPitchesSingleFilesTab/DesignerPitchesSingleFilesTab';
import DesignerPitchesSingleLanding from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchesSingle/DesignerPitchesSingleLanding/DesignerPitchesSingleLanding';
import DesignerPitchNew from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchNew/DesignerPitchNew';
import DesignerPitchNewThanks from 'pages/Dashboard/Designer/DesignerPitches/DesignerPitchNew/DesignerPitchNewThanks';
import BuyerPitchesListDashboard from 'pages/Dashboard/Buyer/BuyerPitchesDashboard/BuyerPitchesListDashboard/BuyerPitchesDashboard';
import BuyerPitchesSingleLanding from 'pages/Dashboard/Buyer/BuyerPitchesDashboard/BuyerPitchesSingle/BuyerPitchesSingleLanding/BuyerPitchesSingleLanding';
import BuyerPitchesSingleReviewTab from 'pages/Dashboard/Buyer/BuyerPitchesDashboard/BuyerPitchesSingle/BuyerPitchesSingleReviewTab/BuyerPitchesSingleReviewTab';
import BuyerPitchesSinglePitchTab from 'pages/Dashboard/Buyer/BuyerPitchesDashboard/BuyerPitchesSingle/BuyerPitchesSinglePitchTab/BuyerPitchesSinglePitchTab';
import BuyerPitchesSingleAgreementTab from 'pages/Dashboard/Buyer/BuyerPitchesDashboard/BuyerPitchesSingle/BuyerPitchesSingleAgreementTab/BuyerPitchesSingleAgreementTab';
import BuyerPitchesSingleFileTab from 'pages/Dashboard/Buyer/BuyerPitchesDashboard/BuyerPitchesSingle/BuyerPitchesSingleFileTab/BuyerPitchesSingleFileTab';
import BuyerSinglePitchSuccess from 'pages/Dashboard/Buyer/BuyerPitchesDashboard/BuyerPitchesSingle/BuyerSinglePitchSuccess/BuyerSinglePitchSuccess';
import BuyerMoodboardShared from 'pages/Dashboard/Buyer/Moodboards/BuyerMoodboardShared/BuyerMoodboardShared';
import BuyersIndex from 'pages/BuyersIndex/BuyersIndex';
import BuyerBriefSuccess from 'pages/Dashboard/Buyer/Briefs/BriefsSingle/BuyerBriefSuccess/BuyerBriefSuccess';
import IncompleteProfile from 'pages/IncompleteProfile/IncompleteProfile';
import StripeRefresh from 'pages/StripeRefresh/StripeRefresh';
import DesignerBuyerProfile from 'pages/DesignerBuyerProfile/DesignerBuyerProfile';
import ProductPitchConversion from 'pages/Dashboard/Designer/ProductPitchConversion/ProductPitchConversion';
import HomeRedirect from 'components/HomeRedirect/HomeRedirect';
import Logout from 'pages/Authentication/Logout/Logout';

import { routes } from 'options/routes';
import { userTypes } from 'options/userTypes';
import { STRIPE_STATUS_BUYER, STRIPE_STATUS_DESIGNER } from 'options/stripeStatus';

const Content = ({ userAuthenticated, baseProfile }) => {
  const authenticatedScreens = () => {
    return (
      <>
        {[STRIPE_STATUS_BUYER.ACTIVE, STRIPE_STATUS_DESIGNER.CONNECTED].includes(baseProfile.stripe_status) ? (
          <Route exact path={routes.profile} element={<Profile baseProfile={baseProfile} />} />
        ) : (
          <Route exact path={routes.profile} element={<IncompleteProfile userType={baseProfile.role} />} />
        )}
        <Route
          exact
          path={routes.account}
          element={
            baseProfile.role === userTypes.designer ? (
              <AccountDesigner baseProfile={baseProfile} />
            ) : (
              <AccountBuyer userType={baseProfile.role} stripeStatus={baseProfile.stripe_status} />
            )
          }
        />
        {/* TODO: Reintroduce home page at a later date - disable for now and redirect to profile page */}
        {/*<Route*/}
        {/*  exact*/}
        {/*  path={routes.home}*/}
        {/*  element={*/}
        {/*    baseProfile.role === userTypes.designer ? (*/}
        {/*      baseProfile.stripe_status === STRIPE_STATUS_DESIGNER.CONNECTED ? (*/}
        {/*        <DesignerLanding userType={baseProfile.role} />*/}
        {/*      ) : (*/}
        {/*        <IncompleteProfile userType={baseProfile.role} />*/}
        {/*      )*/}
        {/*    ) : baseProfile.stripe_status === STRIPE_STATUS_BUYER.ACTIVE ? (*/}
        {/*      <BuyerLanding userType={baseProfile.role} />*/}
        {/*    ) : (*/}
        {/*      <IncompleteProfile userType={baseProfile.role} />*/}
        {/*    )*/}
        {/*  }*/}
        {/*/>*/}
        <Route exact path={routes.home} element={<HomeRedirect role={baseProfile?.role} />} />

        {/* STRIPE REFRESH */}
        <Route exact path={routes.stripeRefresh} element={<StripeRefresh userType={baseProfile.role} />} />

        <Route exact path={routes.logout} element={<Logout />} />

        {((baseProfile.role !== userTypes.designer && baseProfile.stripe_status === STRIPE_STATUS_BUYER.ACTIVE) ||
          (baseProfile.role === userTypes.designer &&
            baseProfile.stripe_status === STRIPE_STATUS_DESIGNER.CONNECTED)) && (
          <Route path={routes.product}>
            <Route path={':product_uuid'} element={<SingleProduct userType={baseProfile.role} />} />
          </Route>
        )}

        {/* PRODUCTS ROUTES */}
        {baseProfile.role !== userTypes.designer && baseProfile.stripe_status === STRIPE_STATUS_BUYER.ACTIVE && (
          <>
            {/* PRODUCTS ROUTES */}
            <Route exact path={routes.products}>
              <Route index element={<Products userType={baseProfile.role} />} />
              <Route path={'search'} element={<ProductsSearch userType={baseProfile.role} />}>
                <Route path={':category'} element={<ProductsSearch userType={baseProfile.role} />} />
              </Route>
              <Route path={'category-search'} element={<ProductsCategorySearch userType={baseProfile.role} />}>
                <Route path={':category'} element={<ProductsCategorySearch userType={baseProfile.role} />} />
              </Route>
              <Route path={'spaces'}>
                <Route path=":sub_category" element={<ProductsSpaces userType={baseProfile.role} />} />
              </Route>
              <Route path={':category'} element={<Products userType={baseProfile.role} />}>
                <Route path={':sub_category'} element={<Products userType={baseProfile.role} />} />
              </Route>
            </Route>
            <Route path={routes.product}>
              <Route path={':product_uuid'} element={<SingleProduct userType={baseProfile.role} />} />
            </Route>

            {/* DESIGNERS INDEX && BUYER DESIGNERS PROFILE */}
            <Route exact path={routes.designersIndex}>
              <Route index element={<DesignersIndex />} />
              <Route path={':designer_uuid'} element={<BuyerDesignerProfile />} />
            </Route>

            {/* MOODBOARD ROUTES   /moodboard/{moodboard_uuid}/{share_uuid} */}
            <Route exact path={routes.moodboardsDashboard}>
              <Route index element={<BuyerMoodboardsDashboard />} />
              <Route path={':moodboard_uuid'}>
                <Route index element={<BuyerSingleMoodboard />} />
              </Route>
            </Route>

            <Route exact path={routes.moodboard}>
              <Route path={':moodboard_uuid'}>
                <Route path={':share_uuid'} element={<BuyerMoodboardShared />} />
              </Route>
            </Route>
          </>
        )}
        {/* DESIGNERS INDEX && BUYER DESIGNERS PROFILE */}
        {baseProfile.role !== userTypes.designer && baseProfile.stripe_status === STRIPE_STATUS_BUYER.ACTIVE && (
          <Route exact path={routes.designersIndex}>
            <Route index element={<DesignersIndex />} />
            <Route path={':designer_uuid'} element={<BuyerDesignerProfile />} />
          </Route>
        )}

        {/* BUYERS INDEX && DESIGNER BUYERS PROFILE */}
        {baseProfile.role === userTypes.designer && baseProfile.stripe_status === STRIPE_STATUS_DESIGNER.CONNECTED && (
          <Route exact path={routes.buyersIndex}>
            <Route index element={<BuyersIndex />} />
            <Route path={':company_uuid'} element={<DesignerBuyerProfile />} />
          </Route>
        )}

        {/* HELP DASHBOARD ROUTE */}
        <Route exact path={routes.help} element={<HelpDashboardLanding userType={baseProfile.role} />} />

        {/* MOODBOARD ROUTES */}
        {baseProfile.role !== userTypes.designer && baseProfile.stripe_status === STRIPE_STATUS_BUYER.ACTIVE && (
          <>
            <Route exact path={routes.moodboardsDashboard}>
              <Route index element={<BuyerMoodboardsDashboard />} />
              <Route path={':moodboard_uuid'} element={<BuyerSingleMoodboard />} />
            </Route>
          </>
        )}
        {/* DASHBOARD ROUTES */}
        {((baseProfile.role === userTypes.designer && baseProfile.stripe_status === STRIPE_STATUS_DESIGNER.CONNECTED) ||
          (baseProfile.role !== userTypes.designer && baseProfile.stripe_status === STRIPE_STATUS_BUYER.ACTIVE)) && (
          <Route exact path={routes.dashboard}>
            <Route
              index
              element={
                baseProfile.role === userTypes.designer ? <DesignerDashboardLanding /> : <BuyerDashboardLanding />
              }
            />
            {baseProfile.role === userTypes.designer && (
              <>
                <Route path={routes.productsDashboard}>
                  <Route index element={<DesignerProductsDashboard />} />
                  <Route path=":product_uuid">
                    <Route index element={<DesignerProductBuyerList />} />
                    <Route
                      path=":buyer_product_uuid"
                      element={<DesignerSingleProductLanding userType={baseProfile.role} />}
                    >
                      <Route path="product" element={<DesignerSingleProductTab />} />
                      <Route path="agreement" element={<DesignerSingleProductAgreementTab />} />
                      <Route path="files" element={<DesignerSingleProductFilesTab />} />
                    </Route>
                  </Route>
                </Route>
                <Route path={routes.productsAdd}>
                  <Route index element={<ProductProposal currency={baseProfile?.currency} />} />
                  <Route path={':product_uuid'} element={<ProductProposal currency={baseProfile?.currency} />} />
                </Route>
                <Route
                  path={routes.productsAddThanks}
                  element={<ProductConversionThanks userType={baseProfile.role} />}
                />
                <Route
                  path={routes.productConversionThanks}
                  element={<ProductConversionThanks userType={baseProfile.role} />}
                />

                <Route path={routes.briefsDashboard}>
                  <Route index element={<DesignerBriefsDashboard />} />
                  <Route path=":brief_uuid" element={<DesignerSingleBriefLanding userType={baseProfile.role} />}>
                    <Route path={'brief'} element={<DesignerBriefDetails />} />
                    <Route path={'proposal'} element={<DesignerBriefProposalDetails />} />
                    <Route path={'agreement'} element={<DesignerBriefAgreement />} />
                    <Route path={'files'} element={<DesignerBriefDesigns userType={baseProfile.role} />} />
                  </Route>
                  <Route path={routes.proposalAdd}>
                    <Route index element={<DesignerBriefProposalNew userType={baseProfile.role} />} />
                    <Route path=":proposal_uuid" element={<DesignerBriefProposalNew userType={baseProfile.role} />} />
                  </Route>
                  {/* TODO: Replace route with final components once components are built */}
                  <Route path={routes.proposalAddThanks} element={<DesignerBriefProposalList />} />
                </Route>

                <Route path={routes.openCallsDashboard}>
                  <Route index element={<DesignerOpenCallsDashboard />} />
                  <Route path=":brief_uuid" element={<DesignerOpenCallsSingleLanding userType={baseProfile.role} />}>
                    <Route path={'open-call'} element={<DesignerOpenCallsSingleTab />} />
                    <Route path={'proposal'} element={<DesignerOpenCallsProposalTab />} />
                    <Route path={'agreement'} element={<DesignerOpenCallsAgreementTab />} />
                    <Route path={'files'} element={<DesignerOpenCallsFilesTab />} />
                  </Route>
                  <Route path={routes.openCallsProposalAdd}>
                    <Route index element={<DesignerOpenCallsProposalNew userType={baseProfile.role} />} />
                    <Route
                      path=":proposal_uuid"
                      element={<DesignerOpenCallsProposalNew userType={baseProfile.role} />}
                    />
                  </Route>
                </Route>
                {/* DESIGNERS PITCHES */}
                <Route path={routes.pitchesDashboard}>
                  <Route index element={<DesignerPitchesDashboard />} />
                  <Route path=":pitch_uuid">
                    <Route index element={<DesignerPitchesBuyersList />} />
                    <Route
                      path=":buyer_pitch_uuid"
                      element={<DesignerPitchesSingleLanding userType={baseProfile.role} />}
                    >
                      <Route path="pitch" element={<DesignerPitchesSinglePitchTab />} />
                      <Route path="agreement" element={<DesignerPitchesSingleAgreementTab />} />
                      <Route path="files" element={<DesignerPitchesSingleFilesTab />} />
                    </Route>
                  </Route>
                </Route>

                <Route path={routes.pitchesAdd}>
                  <Route index element={<DesignerPitchNew />} />
                  <Route path={':id'} element={<DesignerPitchNew />} />
                  <Route path="conversion">
                    <Route index element={<ProductPitchConversion />} />
                    <Route path=":id" element={<ProductPitchConversion />} />
                  </Route>
                </Route>
                <Route path={routes.pitchesAddThanks} element={<DesignerPitchNewThanks />} />
              </>
            )}
            {baseProfile.role !== userTypes.designer && (
              <>
                {/* TODO: Replace nested routes with final components once components are built */}
                <Route path={routes.briefsDashboard}>
                  <Route index element={<BuyerBriefsDashboard />} />
                  <Route path={routes.briefsAdd}>
                    <Route index element={<BuyerBriefNew userType={baseProfile.role} />} />
                    <Route path={':id'} element={<BuyerBriefNew />} />
                    <Route path="thanks" element={<BuyerBriefThanks userType={baseProfile.role} />} />
                  </Route>
                  <Route path=":brief_uuid">
                    <Route index element={<BuyerBriefProposalList />} />
                    <Route
                      path={':brief_designer_uuid'}
                      element={<BuyerSingleBriefLanding userType={baseProfile.role} />}
                    >
                      <Route path={'brief'}>
                        <Route index element={<BuyerBriefDetails />} />
                        <Route path="success" element={<BuyerBriefSuccess />} />
                      </Route>
                      <Route path={'proposal'}>
                        <Route index element={<BuyerBriefProposalDetails />} />
                      </Route>
                      <Route path={'agreement'} element={<BuyerProposalAgreement />} />
                      <Route path={'files'} element={<BuyerProposalDesigns />} />
                      <Route path={'review'} element={<BuyerReviewDesigner />} />
                    </Route>
                  </Route>
                  {/*
                  Below routes need to be rendered for buyers to enable also rendering for designers
                  Buyers will be redirected to home page in the component if accessing these routes
                  BuyerProposalAgreement
                */}
                  <Route path={routes.proposalAdd}>
                    <Route index element={<DesignerBriefProposalNew userType={baseProfile.role} />} />
                    <Route path=":proposal_uuid" element={<DesignerBriefProposalNew userType={baseProfile.role} />} />
                  </Route>
                  {/* TODO: Replace route with final components once components are built */}
                  <Route
                    path={routes.proposalAddThanks}
                    element={<DesignerBriefProposalNew userType={baseProfile.role} />}
                  />
                </Route>

                <Route path={routes.termsDashboard} element={<BuyerTermsDashboard />} />
                <Route path={routes.termsAdd}>
                  <Route index element={<BuyerTermsNew />} />
                  <Route path={':terms_uuid'} element={<BuyerTermsNew />} />
                </Route>
                <Route path={routes.termsThanks} element={<BuyerTermsThanks userType={baseProfile.role} />} />

                <Route path={routes.buyerDesignersDashboard} element={<BuyerDesignersDashboard />} />

                <Route path={routes.productsDashboard}>
                  <Route index element={<BuyerProductsListDashboard />} />
                  <Route path=":product_uuid" element={<BuyerSingleProductLanding userType={baseProfile.role} />}>
                    <Route path="product">
                      <Route index element={<BuyerSingleProductTab />} />
                      <Route path="success" element={<BuyerSingleProductSuccess />} />
                    </Route>
                    <Route path="agreement" element={<BuyerSingleProductAgreementTab />} />
                    <Route path="files" element={<BuyerSingleProductFilesTab />} />
                    <Route path="review" element={<BuyerSingleProductReviewTab />} />
                  </Route>
                </Route>
                {/* BUYER OPEN CALLS */}
                <Route path={routes.openCallsDashboard}>
                  <Route index element={<BuyerOpenCallsDashboard />} />

                  <Route path=":brief_uuid">
                    <Route index element={<BuyerOpenCallsProposalsList />} />
                    <Route
                      path={':brief_designer_uuid'}
                      element={<BuyerOpenCallsSingleCallLanding userType={baseProfile.role} />}
                    >
                      <Route path={'open-call'}>
                        <Route index element={<BuyerOpenCallsDetailsTab />} />
                        <Route path="success" element={<BuyerOpenCallsSingleSuccess />} />
                      </Route>
                      <Route path={'proposal'} element={<BuyerOpenCallProposalTab />} />
                      <Route path={'agreement'} element={<BuyerOpenCallsAgreementTab />} />
                      <Route path={'files'} element={<BuyerOpenCallsFilesTab />} />
                      <Route path={'review'} element={<BuyerOpenCallsReview />} />
                    </Route>
                  </Route>
                </Route>
                <Route path={routes.openCallsDashboardAdd}>
                  <Route index element={<BuyerOpenCallsNew userType={baseProfile.role} />} />
                  <Route path=":id" element={<BuyerOpenCallsNew userType={baseProfile.role} />} />
                </Route>
                <Route path={routes.openCallsDashboardAddThanks} element={<BuyerOpenCallsThanks />} />

                {/* BUYER PITCHES */}
                <Route path={routes.pitchesDashboard}>
                  <Route index element={<BuyerPitchesListDashboard />} />
                  <Route path=":pitch_uuid" element={<BuyerPitchesSingleLanding userType={baseProfile.role} />}>
                    <Route path="pitch">
                      <Route index element={<BuyerPitchesSinglePitchTab />} />
                      <Route path="success" element={<BuyerSinglePitchSuccess />} />
                    </Route>
                    <Route path="agreement" element={<BuyerPitchesSingleAgreementTab />} />
                    <Route path="files" element={<BuyerPitchesSingleFileTab />} />
                    <Route path="review" element={<BuyerPitchesSingleReviewTab />} />
                  </Route>
                </Route>
              </>
            )}
          </Route>
        )}
      </>
    );
  };

  return (
    <Routes>
      {/* TODO: Update to redirect to home instead of profile page when home page is reintroduced */}
      <Route exact path={routes.login} element={<Login />} />
      <Route exact path={routes.signup} element={<SignupLanding />} />
      <Route exact path={routes.signupDesigner} element={<SignUpForm userType={userTypes.designer} />} />
      <Route exact path={routes.signupBuyer} element={<SignUpForm userType={userTypes.buyer} />} />
      <Route exact path={`${routes.signupTokenVerify}/:key`}>
        <Route index element={<SignUpVerify />} />
        <Route path=":token" element={<SignUpVerify />} />
      </Route>
      <Route exact path={routes.signupSuccess} element={<SignUpSuccess />} />
      <Route exact path={routes.forgotPassword} element={<ForgotPassword />} />
      <Route exact path={routes.forgotPasswordSuccess} element={<ForgotPasswordSuccess />} />
      <Route exact path={`${routes.resetPassword}/:key`} element={<ResetPassword variant="primary" />} />
      <Route exact path={routes.resetPasswordSuccess} element={<ResetPasswordSuccessPage variant="primary" />} />
      <Route exact path={`${routes.acceptInvitation}/:key`} element={<ResetPassword variant="secondary" />} />
      <Route exact path={routes.acceptInvitationSuccess} element={<ResetPasswordSuccessPage variant="secondary" />} />
      {userAuthenticated && baseProfile && authenticatedScreens()}
      <Route exact path="*" element={<Navigate to={userAuthenticated ? routes.profile : routes.login} replace />} />
    </Routes>
  );
};

Content.propTypes = {
  userAuthenticated: PropTypes.bool.isRequired,
  baseProfile: PropTypes.object,
};

export default Content;
