import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomDivider from 'components/Divider/CustomDivider';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import FormParagraph from 'components/Typography/FormParagraph/FormParagraph';
import BodyText from 'components/Typography/BodyText/BodyText';

import { SignUpStepperHeaderContainer } from './SignUpStepperHeader.styles';

const SignUpStepperHeader = ({ titleText, paragraphText, additionalDescription, showDivider, align }) => {
  return (
    <SignUpStepperHeaderContainer>
      <CustomTitle text={titleText} variant="h2" align={align} />
      {paragraphText && <FormParagraph text={paragraphText} variant="primary" />}
      {additionalDescription && (
        <Box sx={{marginTop: `28px`}}>
          <BodyText text={additionalDescription} bold />
        </Box>
      )}
      {showDivider && <CustomDivider orientation="horizontal" />}
    </SignUpStepperHeaderContainer>
  );
};

SignUpStepperHeader.propTypes = {
  titleText: PropTypes.string,
  paragraphText: PropTypes.string,
  additionalDescription: PropTypes.string,
  showDivider: PropTypes.bool,
  align: PropTypes.oneOf(['center', 'left', 'right']),
};

export default SignUpStepperHeader;
