import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import CustomTextField from 'components/Inputs/TextFields/CustomTextField/CustomTextField';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';
import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

import theme from 'theme/theme';

const ModalBriefProposalComplete = ({ open, handleClose, onSubmit, variant = 'project' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [completeText, setCompleteText] = useState('');
  const [error, setError] = useState(false);

  const updateCompleteText = (e) => {
    setError(false);
    setCompleteText(e.target.value);
  };

  const handleSubmit = async () => {
    if (completeText === 'complete' || completeText === 'Complete') {
      setError(false);
      setIsLoading(true);
      await onSubmit();
      setIsLoading(false);
      return;
    } else {
      setError(true);
    }
  };

  return (
    <CustomModal title={`Complete ${variant}`} open={open} handleClose={handleClose}>
      {isLoading ? (
        <Box my={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <StyledModalContentWrapper>
          <BodyText
            colour={theme.palette.grey[500]}
            text={`Are you sure you want to complete this ${variant}? Once confirmed, this action cannot be undone. You will be able to discuss further the project and exchange files via the chat. But you sould create a new brief if you wish to start something new.`}
          />
          <CustomFieldSet label="Type 'complete' in the below field to confirm" />
          <CustomTextField
            placeholder={'e.g complete'}
            value={completeText}
            onChange={(e) => updateCompleteText(e)}
            removeError={true}
          />
          {error && (
            <ErrorMessage>You must type complete in the above text box before submitting this form</ErrorMessage>
          )}
          <StyledActionButtonsContainer>
            <CustomButton onClick={handleClose} variant="outlined" text="Cancel" />
            <CustomButton onClick={handleSubmit} variant="contained" colour="primary" text="Complete" />
          </StyledActionButtonsContainer>
        </StyledModalContentWrapper>
      )}
    </CustomModal>
  );
};

ModalBriefProposalComplete.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ModalBriefProposalComplete;
