import PropTypes from 'prop-types';

import ResetPasswordSuccess from '../../../components/Forms/ResetPasswordForm/ResetPasswordSuccess';
import TwoColumnBlackWhiteWrapper from 'components/Layout/TwoColumnBlackWhiteWrapper/TwoColumnBlackWhiteWrapper';

const ResetPasswordSuccessPage = ({ variant }) => {
  return (
    <TwoColumnBlackWhiteWrapper>
      <ResetPasswordSuccess variant={variant} />
    </TwoColumnBlackWhiteWrapper>
  );
};

ResetPasswordSuccess.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default ResetPasswordSuccessPage;
