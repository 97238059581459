import { Box } from '@mui/material';
import BriefProposalAgreementForm from 'components/Forms/BriefProposalAgreementForm/BriefProposalAgreementForm';
import BriefAccordion from 'components/BriefAccordion/BriefAccordion';

import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { isAnyBuyer } from 'utils/isAnyBuyer';

const AgreementAccordion = ({
  briefDetails,
  download_url,
  agreement_status,
  project_status,
  userType,
  handleEditAgreement,
  onAcceptAgreement,
  variant,
  proposal_status,
}) => {
  return (
    <Box>
      <Box mb={4}>
        <BriefAccordion
          briefDetails={briefDetails}
          showButtons={false}
          userType={userType}
          handleTermsEngagementEdit={
            isAnyBuyer(userType) && agreement_status === AGREEMENT_STATUS.IN_REVIEW && handleEditAgreement
          }
        />
        <Box mt={2}>
          <BriefProposalAgreementForm
            agreement_status={agreement_status}
            project_status={project_status}
            download_url={download_url}
            userType={userType}
            onAcceptAgreement={onAcceptAgreement}
            variant={variant}
            proposal_status={proposal_status}
          />
        </Box>
      </Box>
    </Box>
  );
};

AgreementAccordion.propTypes = {};

export default AgreementAccordion;
