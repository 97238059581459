import ProposalForm from 'components/ProposalForm/ProposalForm';
import ControlledProductSingle from 'components/ProductSingle/ControlledProductSingle/ControlledProductSingle';
import { userTypes } from 'options/userTypes';

export const proposalSteps = (activeStep) => {
  switch (activeStep) {
    case 0:
      return <ProposalForm />;
    case 1:
      return <ControlledProductSingle userType={userTypes.designer} variant="proposal_view" />;
    default:
      return <></>;
  }
};
