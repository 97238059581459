import { Box } from '@mui/material';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import { userTypes } from 'options/userTypes';

const ModalProposalAgreementSigned = ({ open, handleClose, userType }) => {
  const buyerText =
    'You have reviewed and accepted the T&C’s and the Agreement. You will receive an email with further instructions once the designer has also approved them. Please check your emails.';
  const designerText = 'You have reviewed and accepted the T&C’s and the Agreement. Please sign the Agreement.';

  return (
    <CustomModal title="Signed Agreement" open={open} handleClose={handleClose}>
      <Box>
        <Box mt={2}>
          <CustomTitle variant="h3" text="Congratulations!" />
        </Box>
        <Box mt={1} mb={2}>
          <BodyText text={userType === userTypes.designer ? designerText : buyerText} colour="gray" />
        </Box>
        <CustomButton text="Close" fullWidth onClick={handleClose} />
      </Box>
    </CustomModal>
  );
};

export default ModalProposalAgreementSigned;
