import { styled, TableCell, Typography } from '@mui/material';

export const CustomTableBodyCellStyled = styled(TableCell)(({ theme }) => ({
  ...theme.components.MuiTableBodyCell.styleOverrides.root,
  position: 'relative',
  // Safari hack so table shadows display
  '::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: 0,
    left: 0,
    boxShadow: `inset 0px -0.52px 0px ${theme.palette.common.black}`,
    width: '100%',
    height: 50,
    pointerEvents: 'none',
  },
}));

export const TableBodyText = styled(Typography)(({ theme }) => ({
  ...theme.typography.body1,
  fontSize: 14,
}));
