import React, { useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';

import { routes } from 'options/routes';

import { postBuyerOpenCallsSingleCheckoutSuccess } from 'api';

const BuyerOpenCallsSingleSuccess = (props) => {
  const navigate = useNavigate();
  const { brief_uuid, brief_designer_uuid } = useParams();
  const [briefData, setMessage] = useOutletContext();

  useEffect(() => {
    const getSuccess = async () => {
      const res = await postBuyerOpenCallsSingleCheckoutSuccess(brief_uuid, brief_designer_uuid);
      if (!res.success) {
        setMessage(res?.data?.error_message || 'There was an error while checking out.');
        return navigate(`${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/open-call`);
      }
      setMessage('Thanks for your payment.');
      window.location.href = `${routes.openCallsDashboard}/${brief_uuid}/${brief_designer_uuid}/open-call`;
    };
    getSuccess();
  }, [navigate, brief_uuid, setMessage, brief_designer_uuid]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
      <Spinner align="center" />
    </Box>
  );
};

BuyerOpenCallsSingleSuccess.propTypes = {};

export default BuyerOpenCallsSingleSuccess;
