import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/system';
import CustomModal from 'components/CustomModal/CustomModal';
import Spinner from 'components/Spinner/Spinner';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { StyledModalContentWrapper } from 'components/ModalBriefProposalComplete/ModalBriefProposalComplete.styles';
import { StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

import theme from 'theme/theme';

const OpenBriefCreateProposalModal = ({ open, handleClose, handleSubmit, name }) => {
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    await handleSubmit();
    setIsLoading(false);
  }, [handleSubmit]);
  return (
    <CustomModal title={`View ${name} Open Call`} open={open} handleClose={handleClose}>
      {isLoading ? (
        <Box my={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <StyledModalContentWrapper>
          <Box>
            <BodyText
              colour={theme.palette.grey[500]}
              text={`View the complete Open Call and make a proposal for ${name}.`}
            />
          </Box>
          <StyledActionButtonsContainer>
            <CustomButton colour="secondary" onClick={handleClose} text="Cancel" />
            <CustomButton onClick={onSubmit} text="Confirm" />
          </StyledActionButtonsContainer>
        </StyledModalContentWrapper>
      )}
    </CustomModal>
  );
};

OpenBriefCreateProposalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default OpenBriefCreateProposalModal;
