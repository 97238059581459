import { styled, Typography, Box } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import theme from 'theme/theme';

export const FileUploadThmbnailContainer = styled('div', {
  shouldForwardProp: (props) => props !== 'numberOfTiles',
})(({ theme, numberOfTiles }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '150px',
  backgroundColor: theme.palette.background.gray,
  borderRadius: '10px',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    height: numberOfTiles === 1 ? '210px' : '150px',
  },
  // '&:hover': {
  //   // backgroundColor: '#FDF4EC',
  //   backgroundColor: `rgba(242, 242, 242, 0.8);`,
  //   transition: 'opacity 300ms ease-in-out',
  //   img: {
  //     opacity: 0,
  //   },
  //   svg: {
  //     opacity: 1,
  //   },
  // },
}));


export const ImageThumbnailContainer = styled('div')(() => ({}));

export const OverlayStyled = styled(Box)(({ show, theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  transition: 'opacity 300ms ease-in-out',
  opacity: show ? 1 : 0,
  height: '100%',
  width: '100%',
  backgroundColor: `rgba(242, 242, 242, 0.8);`,
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
}));

export const FileUploadThmbnailImg = styled('img')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  objectFit: 'cover',
  borderRadius: '10px',
}));

export const CloseIconStyled = styled(DeleteOutlineIcon)(({ theme }) => ({
  position: 'absolute',
  top: 5,
  right: 5,
  borderRadius: '100%',
  cursor: 'pointer',
  opacity: 1,
  width: 24,
  height: 24,
  padding: 8,
  '&:hover': {
    backgroundColor: theme.palette.background.grayHover,
  },
}));

export const FileNameText = styled(Typography)(() => ({
  fontSize: '12px',
  textAlign: 'center',
  wordBreak: 'break-all',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));
