import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import FieldLabel from 'components/Typography/FieldLabel/FieldLabel';
import InfoTooltip from 'components/Tooltips/InfoTooltip/InfoTooltip';
import FieldDescription from 'components/Typography/FieldDescription/FieldDescription';
import CustomDivider from 'components/Divider/CustomDivider';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';

import { StyledBox } from './CustomFieldSet.styles';

const CustomFieldSet = ({
  label,
  tooltipTitle,
  tooltipText,
  tooltipParagraphs,
  description,
  descriptionMarginTop,
  showDivider,
  linkText,
  linkPath,
  linkIsNewTab,
  children,
  bold = true,
  titleFontSize,
  marginTop = 4,
  linkInline = false,
}) => {
  const navigate = useNavigate();

  return (
    <StyledBox mt={marginTop} display="flex" flexDirection="column">
      {(label || tooltipText || description || linkText || linkPath) && (
        <Box mb={1}>
          <Box display="flex" justifyContent="space-between">
            {label && <FieldLabel text={label} bold={bold} fontSize={titleFontSize} />}
            {(tooltipText || tooltipParagraphs) && (
              <InfoTooltip title={tooltipTitle} tooltipText={tooltipText} paragraphs={tooltipParagraphs} />
            )}
          </Box>
          {description && (
            <Box mt={descriptionMarginTop} pr="40px">
              <FieldDescription inline={linkInline} text={description} />
              {linkText && linkPath && linkInline && (
                <CustomStandardLink
                  text={linkText}
                  onClick={linkIsNewTab ? () => window.open(linkPath, '_blank') : () => navigate(linkPath)}
                  href={linkPath}
                  linkType="primary"
                />
              )}
            </Box>
          )}
          {linkText && linkPath && !linkInline && (
            <Box my={2}>
              <CustomStandardLink
                text={linkText}
                onClick={linkIsNewTab ? () => window.open(linkPath, '_blank') : () => navigate(linkPath)}
                href={linkPath}
                linkType="primary"
              />
            </Box>
          )}
          {showDivider && (
            <Box mt={1}>
              <CustomDivider orientation="horizontal" />
            </Box>
          )}
        </Box>
      )}
      {children}
    </StyledBox>
  );
};

CustomFieldSet.propTypes = {
  label: PropTypes.string,
  tooltipText: PropTypes.string,
  description: PropTypes.string,
  descriptionMarginTop: PropTypes.number,
  showDivider: PropTypes.bool,
  linkText: PropTypes.string,
  linkPath: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default CustomFieldSet;
