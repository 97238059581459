import { Typography, styled } from '@mui/material';

export const TableBodyTextStyled = styled(Typography, {
  shouldForwardProp: (props) => props !== 'fontSize' && props !== 'truncateWord',
})(({ bold, fontSize, truncateWord }) => ({
  fontWeight: bold ? 600 : 400,
  fontSize: 14,
  textOverflow: truncateWord ? 'ellipsis' : 'unset',
  overflow: truncateWord ? 'hidden' : 'unset',
  whiteSpace: truncateWord ? 'nowrap' : 'pre-wrap',
}));
