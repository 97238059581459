import { useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';

import { Box } from '@mui/material';
import AccountFormContainer from 'components/Forms/AccountFormContainer/AccountFormContainer';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import TwoFormFieldsRow from 'components/TwoFormFieldsRow/TwoFormFieldsRow';
import ControlledPasswordField from 'components/Inputs/TextFields/CustomPasswordField/ControlledPasswordField/ControlledPasswordField';
import FormError from '../FormError/FormError';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { validationSchema } from './ChangePasswordForm.data';

import { changePassword } from '../../../api';

const ChangePasswordForm = ({ setSuccess, isStory }) => {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');
  const resolver = useYupValidationResolver(validationSchema);

  const defaultValues = {
    password: '',
    confirm_password: '',
  };

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver,
  });

  const { reset } = methods;

  const onSubmit = async (data) => {
    setLoading(true);

    // Do not make API call if using component in storybook
    if (isStory) {
      console.log(data);
      setLoading(false);
      return;
    }

    // Post to API
    const response = await changePassword(data);
    if (!response.success) {
      // Set error and reset form data to original values
      setFormError(response.data.error_message);
      reset({ ...defaultValues });
      setLoading(false);
      return;
    }

    // Set success message in parent component
    setSuccess('Password has been successfully changed.');
    reset({ ...defaultValues });
    setLoading(false);
  };

  return (
    <Box>
      <AccountFormContainer
        titleText="Password"
        buttonText="Update Password"
        onClick={methods.handleSubmit(onSubmit)}
        showDivider={false}
        loading={loading}
      >
        {formError && <FormError errors={[formError]} />}
        <FormProvider {...methods}>
          <form>
            <CustomFieldSet label="Update Password">
              <TwoFormFieldsRow>
                <ControlledPasswordField name="password" label="New Password" />
                <ControlledPasswordField name="confirm_password" label="Confirm Password" />
              </TwoFormFieldsRow>
            </CustomFieldSet>
          </form>
        </FormProvider>
      </AccountFormContainer>
    </Box>
  );
};

ChangePasswordForm.propTypes = {
  setSuccess: PropTypes.func.isRequired,
  isStory: PropTypes.bool,
};

export default ChangePasswordForm;
