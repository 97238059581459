import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';

import { Box } from '@mui/material';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import FormActionButton from 'components/Buttons/FormActionButton/FormActionButton';
import ThreeFormFieldsWithOptions from 'components/ThreeFormFieldsWIthOptions/ThreeFormFieldsWithOptions';
import FormError from '../Forms/FormError/FormError';
import Spinner from '../Spinner/Spinner';

import { accountTypes } from '../../options/accountTypes';

import { defaultFormValues, fieldsSchema } from './BuyerAdminManageTeam.data';
import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { addTeamMembers, deleteTeamMember } from '../../api';

const BUSINESS_ACCOUNT_LIMIT = 4;

const getIsAddMoreDisabled = (values, accountType) => {
  const fields = values?.fields;
  if (accountType === accountTypes.business && fields.length === BUSINESS_ACCOUNT_LIMIT) {
    return true;
  }
  // all fields are completed
  return !fields.every((field) => field?.first_name && field?.last_name && field?.email);
};

const getNewCompleteFormData = (data) =>
  data.fields.filter((field) => !field.uuid && field.first_name && field.last_name && field.email);

const getHasSavedUserLimit = (accountType, values) =>
  accountType === accountTypes.business &&
  values.fields.filter((field) => field.uuid)?.length === BUSINESS_ACCOUNT_LIMIT;

const getFieldsLimit = (accountType, values) =>
  accountType === accountTypes.business && values.fields.length === BUSINESS_ACCOUNT_LIMIT;

const BuyerAdminManageTeam = ({ formData, accountType, setSuccess, isStory }) => {
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState('');

  const resolver = useYupValidationResolver(fieldsSchema);
  const methods = useForm({
    defaultValues: { fields: [...(formData || []), ...defaultFormValues] },
    mode: 'onChange',
    resolver,
  });

  const { control, getValues, formState, reset } = methods;

  const { fields, append, prepend, remove } = useFieldArray({
    control: control,
    name: 'fields',
  });

  useEffect(() => {
    // fetch buyers?
    if (formData?.length > 0) {
      if (accountType === accountTypes.business && formData.length === BUSINESS_ACCOUNT_LIMIT) {
        remove(BUSINESS_ACCOUNT_LIMIT);
      }
    }
  }, [formData, accountType, remove]);

  const addMore = useCallback(() => {
    append({
      first_name: '',
      last_name: '',
      email: '',
      uuid: '',
    });
  }, [append]);

  const deleteUser = useCallback(
    async (index) => {
      setLoading(true);
      const values = getValues();
      const uuid = values?.fields[index].uuid;

      // Do not make API call if using component in storybook
      if (isStory) {
        remove(index);
        setLoading(false);
        return;
      }

      // Only run API logic when deleting a user
      if (uuid) {
        // Post to API
        const response = await deleteTeamMember(uuid);
        if (!response.success) {
          // Set error in parent component
          setFormError(response.data.error_message);
          setLoading(false);
          return;
        }
        setSuccess(
          `${values?.fields[index].first_name} ${values?.fields[index].last_name} has been successfully deleted.`
        );
      }

      // remove field row
      remove(index);
      setLoading(false);
    },
    [getValues, remove, setFormError, setSuccess, isStory]
  );

  const onSubmit = useCallback(
    async (data) => {
      setLoading(true);
      const newData = getNewCompleteFormData(data);
      newData.forEach((member) => delete member.uuid);

      // Do not make API call if using component in storybook
      if (isStory) {
        console.log(newData);
        setLoading(false);
        return;
      }

      // TODO: Remove below line and comment in below code
      console.log(newData);
      // Post to API
      const response = await addTeamMembers(newData);
      // console.log('!response.success');
      console.log(!response.success);
      if (!response.success) {
        // Set error in parent component and reset form data to original values
        console.log('setting error');
        setFormError(response.data.error_message);
        reset({ fields: formData && formData.length ? formData : defaultFormValues });
        setLoading(false);
        return;
      }

      const { team } = response;
      reset({ fields: team });
      if (
        accountType === accountTypes.enterprise ||
        (accountType === accountTypes.business && team.length < BUSINESS_ACCOUNT_LIMIT)
      ) {
        addMore();
      }
      setSuccess('Team member data has been successfully updated.');
      setLoading(false);
    },
    [formData, reset, addMore, accountType, isStory, setFormError, setSuccess]
  );

  const values = methods.getValues();

  const isAddMoreDisabled = getIsAddMoreDisabled(values, accountType);
  const hasSavedUserLimit = getHasSavedUserLimit(accountType, values);
  const hasFieldsLimit = getFieldsLimit(accountType, values);

  return (
    <Box>
      <Box mb={3}>
        <CustomTitle text="Manage my team" variant="h4" />
      </Box>
      {formError && <FormError errors={[formError]} />}
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {fields.map((field, index) => {
            // if field has uuid, it means it exist in the server
            const isDisabled = !!field?.uuid;
            const hasAvailableFields = fields.filter((field) => !field.uuid);
            const isLastField = fields.length - 1 === index;
            const hideOptions = hasAvailableFields?.length === 1 && isLastField;
            const options = !hideOptions ? [{ title: 'Delete', onClick: () => deleteUser(index) }] : [];

            return (
              <ThreeFormFieldsWithOptions options={options} key={field.id} mb={1}>
                <ControlledTextField
                  name={`fields[${index}].first_name`}
                  label={`First Name (${index + 1})`}
                  disabled={isDisabled}
                />
                <ControlledTextField
                  name={`fields[${index}].last_name`}
                  label={`Last Name (${index + 1})`}
                  disabled={isDisabled}
                />
                <ControlledTextField
                  name={`fields[${index}].email`}
                  label={`Email (${index + 1})`}
                  disabled={isDisabled}
                />
              </ThreeFormFieldsWithOptions>
            );
          })}
          <Box display="flex" justifyContent="flex-end" mt={1}>
            {loading && <Spinner />}
            {!loading && !hasSavedUserLimit && (
              <>
                <Box>
                  {!hasFieldsLimit && (
                    <Box mr={1}>
                      <FormActionButton
                        text="Add more"
                        type="button"
                        onClick={addMore}
                        variant="outlined"
                        disabled={isAddMoreDisabled}
                      />
                    </Box>
                  )}
                </Box>
                <FormActionButton text="Submit" type="submit" variant="outlined" disabled={!formState.isValid} />
              </>
            )}
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};

BuyerAdminManageTeam.propTypes = {
  accountType: PropTypes.oneOf([accountTypes.solo, accountTypes.business, accountTypes.enterprise, '']),
  formData: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      email: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
  setSuccess: PropTypes.func.isRequired,
  isStory: PropTypes.bool,
};

export default BuyerAdminManageTeam;
