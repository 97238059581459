export const mockData = [
  {
    name: 'Mario Alessiani',
    image: { fileSrc: 'https://pickaface.net/gallery/avatar/katon5224739f478e0.png' },
    categories: [{ name: 'Furniture', slug: 'furniture' }],
    location: 'Milan, Italy',
    experience: '7 years',
    designer_uuid: '12',
  },
  {
    name: 'Lukas Bazle',
    image: { fileSrc: 'https://pickaface.net/gallery/avatar/20130120_120455_2881_jjjfjffjfjf.png' },
    categories: [{ name: 'Furniture', slug: 'furniture' }],
    location: 'Venice, Italy',
    experience: '3 years',
    designer_uuid: '123',
  },
  {
    name: 'Frances Boyd',
    image: { fileSrc: 'https://pickaface.net/gallery/avatar/hansss56bc314b1d363.png' },
    categories: [
      { name: 'Furniture', slug: 'furniture' },
      { name: 'Ash', slug: 'ash' },
    ],
    location: 'Paris, France',
    experience: '8 years',
    designer_uuid: '1234',
  },
  {
    name: 'Gayathri Nair',
    image: { fileSrc: 'https://pickaface.net/gallery/avatar/unr_adrianluizm_190409_2356_bqm3.png' },
    categories: [
      { name: 'Furniture', slug: 'furniture' },
      { name: 'Plastic', slug: 'plastic' },
    ],
    location: 'Delhi, India',
    experience: '2 years',
    designer_uuid: '12345',
  },
  {
    name: 'John Tree',
    image: { fileSrc: 'https://pickaface.net/gallery/avatar/reneiviki.152c470109854f.png' },
    categories: [{ name: 'Furniture', slug: 'furniture' }],
    location: 'London, UK',
    experience: '17 years',
    designer_uuid: '123456',
  },
];
