import React from 'react';
import PropTypes from 'prop-types';
import { CustomTermsTextStyled } from './CustomTermsText.styles';

const CustomTermsText = ({ children, variant }) => {
  const variantSelected = variant === 'secondary' ? 'body2' : 'body1';
  return <CustomTermsTextStyled variant={variantSelected}>{children}</CustomTermsTextStyled>;
};

CustomTermsText.propType = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

export default CustomTermsText;
