import { styled, TextField, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export const TextFieldStyled = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'fieldStyle',
})(({ theme, fieldStyle }) => ({
  width: '100%',

  '& .MuiInputLabel-root': {
    color: theme.palette.text.secondary,
    top: '-4px',

    '&.Mui-focused, &.MuiFormLabel-filled': {
      color: theme.palette.text.secondary,
      top: 0,
    },

    '&.Mui-error': {
      color: theme.palette.error.main,
    },
  },

  '& textarea': {
    paddingRight: theme.spacing(1),
  },

  '& .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.background.pink,
  },

  '& .MuiInput-root, .MuiOutlinedInput-root': {
    color: theme.palette.text.primary,
    borderBottom: fieldStyle === 'primary' ? '' : `1px solid ${theme.palette.common.black}`,
    backgroundColor: fieldStyle === 'primary' ? theme.palette.background.pink : '',
    borderRadius: fieldStyle === 'primary' ? theme.shape.borderRadius * 2 : '',

    '&.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.common.border}`,
    },

    '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.background.pink,
    },

    '& input.MuiInputBase-input': {
      padding: `12.5px ${theme.spacing(2)}`,
    },

    '& input.MuiInputBase-inputAdornedStart': {
      paddingLeft: 0,
    },

    '&:before, &:after, &:hover:not(.Mui-disabled):before': {
      border: '0px',
    },

    '&.MuiInputBase-colorSuccess': {
      borderBottom: 0,

      '&:after': {
        borderBottom: 0,
      },
    },

    '&.Mui-error': {
      color: theme.palette.common.black,

      '&:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline, &:hover .MuiOutlinedInput-notchedOutline':
        {
          border: `1px solid ${theme.palette.error.main}`,
        },

      '&:before': {
        border: '0px',
      },
    },
  },

  '& .MuiInputBase-multiline': {
    padding: `12.5px ${theme.spacing(2)}`,
  },
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: '0.765rem',
}));

export const CheckIconStyled = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.success.main,
}));
