import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ModalStaticMessage from 'components/ModalStaticMessage/ModalStaticMessage';
import ModalHelperText from 'components/ModalHelperText/ModalHelperText';
import CustomDivider from 'components/Divider/CustomDivider';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { validationSchema } from './ModalExternalShareMoodboard.data';

import { capitalize } from 'utils/capitalize';

import { useAuthContext } from 'context/AuthContext';

import { getShareMoodboardExternalLink, postShareMoodboardEmail } from 'api';

const defaultValues = {
  name: '',
  email: '',
  message: '',
};

const ModalExternalShareMoodboard = ({ open, handleClose, setMessage, moodboard_name, moodboard_uuid }) => {
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver,
  });

  const { reset, watch } = methods;
  const invitee_name = watch('name');
  const { company_name, first_name, last_name, uuid } = useAuthContext();

  const getMoodboardCopyLink = useCallback(async () => {
    try {
      const { ClipboardItem } = window;
      if (ClipboardItem && navigator?.clipboard?.write) {
        //   NOTE: Safari locks down the clipboard API to only work when triggered
        //   by a direct user interaction. You can't use it async in a promise.
        //   But! You can wrap the promise in a ClipboardItem, and give that to
        //   the clipboard API.
        //   Found this on https://developer.apple.com/forums/thread/691873
        const text = new ClipboardItem({
          'text/plain': new Promise((resolve) => {
            resolve(getShareMoodboardExternalLink(moodboard_uuid, uuid));
          })
            .then((data) => {
              setMessage(`The link ${data?.share_url} has been copied to your clipboard!`);
              return new Blob([data?.share_url], { type: 'text/plain' });
            })
            .catch(() => setMessage('There was an error fetching copy link.')),
        });
        navigator.clipboard.write([text]);
      } else {
        // NOTE: Firefox has support for ClipboardItem and navigator.clipboard.write,
        //   but those are behind `dom.events.asyncClipboard.clipboardItem` preference.
        //   Good news is that other than Safari, Firefox does not care about
        //   Clipboard API being used async in a Promise.
        const response = await getShareMoodboardExternalLink(moodboard_uuid, uuid);

        if (response.success) {
          const { share_url } = response;
          navigator.clipboard.writeText(share_url);

          setMessage(`The link ${share_url} has been copied to your clipboard!`);
        }
        if (!response.success || !response?.data?.success) {
          throw response;
        }
      }
    } catch (err) {
      setMessage(err?.data?.error_message || 'There was an error copying to clipboard');
      return;
    }
  }, [moodboard_uuid, uuid, setMessage]);

  const onSubmit = async (data) => {
    setLoading(true);
    // Post to API
    const response = await postShareMoodboardEmail(moodboard_uuid, uuid, data);
    if (response.success) {
      setMessage(`${capitalize(data.name)} has been sent an email with moodboard link.`);
      handleClose();
      setLoading(false);
      reset({ ...defaultValues });
      return;
    }
    if (!response.success || !response?.data?.success) {
      setMessage(response.data.error_message);
      reset({ ...defaultValues });
      setLoading(false);
      return;
    }
  };

  const onClose = () => {
    reset({ ...defaultValues });
    handleClose();
  };

  return (
    <CustomModal title="Share Moodboard" open={open} handleClose={onClose}>
      {loading ? (
        <Box mt={1} mb={4}>
          <Spinner align={'center'} />
        </Box>
      ) : (
        <Box>
          <Box mb={2} mt={2}>
            <BodyText text="Share moodboard with someone outside of your team" />
          </Box>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box mt={1} mb={1}>
                <ControlledTextField name="name" label="Name" />
                <ControlledTextField name="email" label="Email Address" />
                <ControlledTextField
                  name="message"
                  placeholder="Enter your optional message"
                  autoComplete="off"
                  multiline={true}
                  minRows={6}
                />
                <CustomDivider overrideStyles={{ marginTop: '10px' }} />
              </Box>
              {/* <ModalMessageHeader /> */}
              <Box textAlign="justify" mt={2} backgroundColor="background.staticMessage" py={1}>
                <ModalStaticMessage
                  variant="share-mooodboard-external"
                  buyer_user_name={first_name}
                  buyer_last_name={last_name}
                  company_name={company_name}
                  moodboard_name={moodboard_name}
                  invitee_name={invitee_name}
                />
              </Box>
              <ModalHelperText mb={0} />
              <Box my={2}>
                <CustomButton
                  variant="outlined"
                  text="Copy Link"
                  fullWidth
                  onClick={getMoodboardCopyLink}
                  // icon={<ContentCopyIcon />}
                  styleOverrides={{ borderRadius: '10px' }}
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" gap="8px">
                <CustomButton variant="outlined" text="Cancel" fullWidth onClick={onClose} />
                <CustomButton text="Send Email" fullWidth buttonType="submit" />
              </Box>
            </form>
          </FormProvider>
        </Box>
      )}
    </CustomModal>
  );
};

export default ModalExternalShareMoodboard;
