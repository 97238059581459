import { differenceInSeconds, differenceInYears } from 'date-fns';
import { string, array, number, object, ref, bool, lazy, boolean } from 'yup';
import { validate as validateEmail } from 'email-validator';
import { LICENSE_TYPES, TERM_TYPES } from 'options/briefOptions';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { getUniqueList } from './getUniqueList';

const numberInTextFieldTestCallback = (v, additionalTests = null, acceptZero) => {
  if (v === '0' && acceptZero) return true;
  if (!v || isNaN(parseInt(v)) || parseInt(v) <= 0) return false;
  if (additionalTests) return additionalTests(v);
  // If additional test fn is passed and runs, it has access to a number > 0
  else if (!additionalTests) return true;
};

export const validation = {
  // personal fields
  personalFields: {
    first_name: string().required('You must enter first name'),
    last_name: string().required('You must enter last name'),
    date_of_birth: string()
      .typeError('You must enter a date of birth')
      .required()
      .test('Age check', 'You must be over 18 years old', (value) => {
        if (!value) return true; // ignore this validation if field is empty

        return differenceInYears(new Date(), new Date(value)) >= 18;
      })
      .test('Age check', 'Date must not be in the future', (value) => {
        if (!value) return true; // ignore this validation if field is empty
        return differenceInSeconds(new Date(), new Date(value)) >= 0;
      }), // initial value must be null
    phone_number: string().test('Phone number check', 'You must enter a valid phone number', (value) => {
      if (!value.length) return true; // ignore this validation if field is empty
      return value.match(/(\+)?\d+/);
    }),
    gender: string(),
  },

  pictureFields: {
    portrait: array().min(1, 'You must upload a picture'),
    logo: array(),
  },

  //account fields
  accountFields: {
    email: string()
      .test('Email validation', 'Must enter a valid email address', (value) => {
        return validateEmail(value);
      })
      .required('You must enter an email'),
    password: string()
      .matches(/[@$!%*#?&]+/, 'Must have special character')
      .matches(/\d+/, 'Must have one number')
      .matches(/[a-z]+/, 'Must have one lowercase character')
      .matches(/[A-Z]+/, 'Must have uppercase character')
      .min(8, 'Must be 8 characters or more')
      .required('No password provided.'),
    confirm_password: string()
      .required('Must retype your password')
      .oneOf([ref('password'), null], 'Passwords must match'),
    requiredPassword: string().required('Password is required'),
  },

  // designer company fields
  designerStudioFields: {
    studio_name: string().required('You must enter a studio name'),
    studio_address_1: string(),
    studio_address_2: string(),
    studio_address_3: string(),
    studio_city: string(),
    studio_county: string(),
    studio_country: string().nullable().required('You must enter a country'),
    studio_post_code: string(),
  },

  designerGeneralFields: {
    designer_since: string()
      .test('Date check', 'Date must not be in the future', (value) => {
        return differenceInSeconds(new Date(), new Date(value)) >= 0;
      })
      .test('Designer since', 'Designer since is required', (value) => {
        return !!value;
      })
      .typeError('You must enter designer since'),
    experience_detail: string(),
    study_location: string(),
    study_subject: string(),
    website: string()
      .matches(
        /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
        'Must be a valid website url'
      )
      .required('You must enter website'),
    bio: string(),
    currency: array(),
  },

  designerAddProductFields: {
    name: string().required('You must enter a product name').max(50, 'Title must not exceed 50 characters.'),
    main_image: array().min(1, 'You must provide a cover image').required(),
    concept: string().required('Tell us about the concept behind this product').min(50).max(5000),
    additional_images: array().test('Duplicate images check', 'Duplicate images not allowed', (value) => {
      if (getUniqueList(value, 'name').length !== value.length) return false;
      return true;
    }),
    production_files: array()
      .when('agreement_type', {
        is: (value) => value !== LICENSE_TYPES.royalty,
        then: array().min(1, 'You must provide at least one production file').required(),
        otherwise: array(),
      })
      .test('Duplicate files check', 'Duplicate files not allowed', (value) => {
        if (getUniqueList(value, 'name').length !== value.length) return false;
        return true;
      }),
    construction: string().required('Provide some details about the construction of this product').max(3000),
    colour_material_finish: string()
      .required('Provide details about the colour, material and finish of the product')
      .max(3000),
    width: string(),
    height: string(),
    depth: string(),
    agreement_type: string().required('You must choose an agreement type'),
    price: string().when('agreement_type', {
      is: (value) => value === LICENSE_TYPES.one_off || value === LICENSE_TYPES.both,
      then: string()
        .nullable()
        .test('Price entered check', 'You must enter a price for this product', (v) => {
          if (!v || isNaN(parseInt(v)) || parseInt(v) <= 0) return false;
          else return parseInt(v) > 0;
        }),
      otherwise: string(),
    }),
    registered: string().required('You must tell us if the product is registered or not'),
    registered_info: string().when('registered', {
      is: (value) => value === 'yes',
      then: string().required('You must enter registered info.'),
      otherwise: string(),
    }),
    terms_accepted: boolean().isTrue('You must accept the Ts and Cs and before continuing', (v) => {
      if (!v) return false;
      else return true;
    }),
  },

  pitchAdditionalFields: {
    buyers: array().min(1, 'You must select a buyer').required(),
  },

  designerAddProposalFields: {
    name: string().required('You must enter a product name').max(50, 'Title must not exceed 50 characters.'),
    main_image: array().min(1, 'You must provide a cover image').required(),
    concept: string().required('Tell us about the concept behind this product').min(50).max(5000),
    additional_images: array().test('Duplicate images check', 'Duplicate images not allowed', (value) => {
      if (getUniqueList(value, 'name').length !== value.length) return false;
      return true;
    }),
    construction: string().required('Provide some details about the construction of this product').max(3000),
    colour_material_finish: string()
      .required('Provide details about the colour, material and finish of the product')
      .max(3000),
    width: string(),
    height: string(),
    depth: string(),
  },

  // address fields for company
  companyAddressFields: {
    address_1: string(),
    address_2: string(),
    address_3: string(),
    city: string(),
    county: string(),
    country: string().nullable().required('You must enter a country'),
    post_code: string(),
  },

  companyGeneralFields: {
    name: string().required('You must enter company name'),
    jurisdiction: string(),
    company_number: lazy((value) => (value === '' ? string() : number().typeError('Must enter a number'))),

    established_since: string()
      .test('Date check', 'Date must not be in the future', (value) => {
        return differenceInSeconds(new Date(), new Date(value)) >= 0;
      })
      .test('Established since', 'You must enter Established Since', (value) => {
        return !!value;
      })
      .typeError('You must enter Established Since'),
    employee_count: array(),
    annual_product_launches: array(),
    currency: array(),

    website: string()
      .required('You must enter website')
      .matches(
        /(https?:\/\/)?([\w\-])+\.{1}([a-zA-Z]{2,63})([\/\w-]*)*\/?\??([^#\n\r]*)?#?([^\n\r]*)/,
        'Must be a valid website url'
      ),
    about: string(),
  },

  buyerPersonalFields: {
    position: string().required('You must enter position in company'),
  },

  newBriefFormFields: {
    title: string().max(40, 'Maximum 40 characters').required('You must enter name for the brief'),
    design_requirements: string()
      .max(3000, 'Maximum 3000 characters')
      .required('You must provide your design requirements for this brief'),
    file_requirements: array().min(1, 'You must provide at least one file type required for this brief'),
    customer_requirements: string().required('You must enter customer requirements for this brief'),
    commercial_requirements: string()
      .max(3000, 'Maximum 3000 characters')
      .required('You must enter commercial requirements for this brief'),
    markets: array().min(1, 'You must provide at least one market for this brief'),
    main_image: array().min(1, 'You must provide at least a main image for this brief'),
    reference_file: array(),
    additional_images: array().test('Duplicate images check', 'Duplicate images not allowed', (value) => {
      if (getUniqueList(value, 'name').length !== value.length) return false;
      return true;
    }),
    manufacturing_requirements: string()
      .max(3000, 'Maximum 3000 characters')
      .required('You must provide manfucturing requirements for this brief'),
    projected_volume: array().min(1, 'You must provide your projected volume'),
    projected_retail_price: string().test(
      'Projected retail price check',
      'You must specify a projected retail price',
      (value) => numberInTextFieldTestCallback(value, null, true)
    ),
    projected_cost_price: string().test(
      'Projected cost price check',
      'You must specify a projected cost price',
      (value) => numberInTextFieldTestCallback(value, null, true)
    ),
  },

  newBriefTermsAndLicensingFields: {
    // Terms and Conditions

    term_type: string().required('You must either create new Terms and Conditions or use an existing one'),
    agreement_title: string().when('term_type', {
      is: (value) => value === TERM_TYPES.new_terms,
      then: string().required('You must give your terms and conditions a title'),
      otherwise: string(),
    }),
    agreement_body: string().when(['term_type', 'agreement_file'], {
      is: (termType, agreementFile) => termType === TERM_TYPES.new_terms && agreementFile.length === 0,
      then: string().required(
        'You must add your terms and conditions in this field or alternatively upload a PDF below'
      ),
      otherwise: string(),
    }),
    agreement_file: array().when(['term_type', 'agreement_body'], {
      is: (termType, agreementBody) => termType === TERM_TYPES.new_terms && !agreementBody,
      then: array().min(1, 'If you wish to provide a terms and conditions document, it must be uploaded in PDF format'),
      otherwise: array(),
    }),
    selected_terms_uuid: string().when('term_type', {
      is: (value) => value === TERM_TYPES.existing_terms,
      then: string().required('You must choose which terms to apply from your existing terms and conditions'),
      otherwise: string(),
    }),
    // Licensing
    agreement_type: string().required('You must choose a type of agreement'),
    payment_terms: string(),
    deadline: string()
      .test('Brief deadline check', 'You must provide a date in the future', (v) => {
        if (!v) return false;
        const date = new Date(v);
        if (!date) return false;
        const now = new Date();
        if (date <= now) return false;
        else return true;
      })
      .typeError('You must provide a deadline'),
    designers: array().min(1, 'You must select at least 1 designer'),
    upfront_payment: string().required('You must tell us if you want to pay an upfront fee'),
    upfront_payment_amount: string().when('upfront_payment', {
      is: (value) => value === 'yes',
      then: string().test('Royalty percentage check', 'You must provide a value', (value) =>
        numberInTextFieldTestCallback(value)
      ),
      otherwise: string(),
    }),
    // Royalty licensing
    royalty_percentage: string().when('agreement_type', {
      is: (value) => value === LICENSE_TYPES.royalty,
      then: string().test('Royalty percentage check', 'You must specify a percentage', (value) =>
        numberInTextFieldTestCallback(value)
      ),
      otherwise: string(),
    }),
    royalty_review_period: string().when('agreement_type', {
      is: (value) => value === LICENSE_TYPES.royalty,
      then: string().required('You must specify a review period'),
      otherwise: string(),
    }),
    // One time fee licensing
    one_off_budget: string().when('agreement_type', {
      is: (value) => value === LICENSE_TYPES.one_off,
      then: string().test('Budget check', 'You must specify a budget', (value) => numberInTextFieldTestCallback(value)),
      otherwise: string(),
    }),
  },

  newTermsFields: {
    agreement_title: string().required('You must give your terms and conditions a title'),
    agreement_body: string().when(['agreement_file'], {
      is: (agreementFile) => agreementFile.length === 0,
      then: string().required(
        'You must add your terms and conditions in this field or alternatively upload a PDF below'
      ),
      otherwise: string(),
    }),
    agreement_file: array().when(['agreement_body'], {
      is: (agreementBody) => !agreementBody,
      then: array().min(1, 'If you wish to provide a terms and conditions document, it must be uploaded in PDF format'),
      otherwise: array(),
    }),
  },

  // products, spaces, company details
  selectOptionsBuyer: {
    product_interests: array().min(1, 'You must select a product'),
    buyer_spaces: array().min(1, 'You must select a space'),
    buyer_categories: array().min(1, 'You must select company description'),
  },

  selectOptionsDesigner: {
    product_types: array().min(1, 'You must select a product'),
  },

  // social media fields
  socialMediaFields: {
    twitter: string(),
    instagram: string(),
    linkedin: string(),
    pinterest: string(),
  },

  terms: {
    terms_accepted: bool().oneOf([true], 'Field must be checked'),
  },

  // token verification field
  tokenVerification: {
    token: number().typeError('You must provide a valid token').required(),
  },

  productProperties: {
    [OPTIONS_TYPE.product]: array().min(1, 'You must select a category'),
    [OPTIONS_TYPE.material]: array().min(1, 'You must select a material'),
    [OPTIONS_TYPE.space]: array().min(1, 'You must select a space'),
    [OPTIONS_TYPE.style]: array().min(1, 'You must select a style'),
    [OPTIONS_TYPE.colour]: array().min(1, 'You must select a colour'),
  },

  // newBriefTermsAccept: {
  //   payment_terms_agreement: bool().oneOf([true], 'Payment terms must be checked'),
  //   options_on_design_agreement: bool().oneOf([true], 'Options on designs terms must be checked'),
  //   reference_images_agreement: bool().oneOf([true], 'Reference images terms must be checked'),
  // },

  reviewForm: {
    review: string().required(),
    rating: number().required().typeError('You must give a rating'),
  },

  rejectProposal: {
    reject: string().matches(/reject/i, 'You must type reject to confirm.'),
  },

  createMoodboard: {
    name: string().max(50).required(),
    description: string().max(500),
  },

  deleteProduct: {
    delete: string().matches(/delete/i, 'You must type delete to confirm.'),
  },

  retractPitch: {
    retract: string().matches(/retract/i, 'You must type retract to confirm.'),
  },

  cancelItem: {
    cancel: string().matches(/cancel/i, 'You must type cancel to confirm.'),
  },

  agreementReviewCommon: {
    negotiated_agreement_type: string().required('You must choose a type of agreement'),
    // Royalty licensing
    negotiated_royalty_percentage: string().when('negotiated_agreement_type', {
      is: (value) => value === LICENSE_TYPES.royalty,
      then: string().test('Royalty percentage check', 'You must specify a percentage', (value) =>
        numberInTextFieldTestCallback(value)
      ),
      otherwise: string(),
    }),
    negotiated_royalty_review_period: string().when('negotiated_agreement_type', {
      is: (value) => value === LICENSE_TYPES.royalty,
      then: string().required('You must specify a review period'),
      otherwise: string(),
    }),
    // One time fee licensing
    negotiated_one_off_budget: string().when('negotiated_agreement_type', {
      is: (value) => value === LICENSE_TYPES.one_off,
      then: string().test('Budget check', 'You must specify a budget', (value) => numberInTextFieldTestCallback(value)),
      otherwise: string(),
    }),
    negotiated_payment_terms: string(),
  },

  agreementReviewBriefs: {
    negotiated_deadline: string()
      .test('Brief deadline check', 'You must provide a date in the future', (v) => {
        if (!v) return false;
        const date = new Date(v);
        if (!date) return false;
        const now = new Date();
        if (date <= now) return false;
        else return true;
      })
      .typeError('You must enter a date'),
    negotiated_upfront_payment: string().required('You must tell us if you want to pay an upfront fee'),
    negotiated_upfront_payment_amount: string().when('negotiated_upfront_payment', {
      is: (value) => value === 'yes',
      then: string().test('Royalty percentage check', 'You must provide a value', (value) =>
        numberInTextFieldTestCallback(value)
      ),
      otherwise: string(),
    }),
  },

  sendReminder: {
    message: string().required('You must enter a message'),
  },

  inviteDesigner: {
    name: string().required('You must provide their full name'),
    email: string()
      .test('Email validation', 'Must enter a valid email address', (value) => {
        return validateEmail(value);
      })
      .required('You must enter an email'),
  },

  pitchConversion: {
    product_uuid: array().min(1, 'You must select a product'),
    buyers: array().min(1, 'You must select a company to send the pitch to'),
  },
};

export const yupObject = object;
