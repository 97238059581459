import ForgotPasswordForm from '../../../components/Forms/ForgotPasswordForm/ForgotPasswordForm';
import TwoColumnBlackWhiteWrapper from 'components/Layout/TwoColumnBlackWhiteWrapper/TwoColumnBlackWhiteWrapper';

const ForgotPassword = () => {
  return (
    <TwoColumnBlackWhiteWrapper>
      <ForgotPasswordForm />
    </TwoColumnBlackWhiteWrapper>
  );
};

export default ForgotPassword;
