import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { PROJECT_STATUS } from 'options/projectStatus';

export const getIsAgreementTabDisabled = (project_status) => {
  return project_status === PROJECT_STATUS.REJECTED || project_status === PROJECT_STATUS.PENDING;
};

export const getIsFilesTabDisabled = (agreement_status) => {
  return agreement_status !== AGREEMENT_STATUS.PAID;
};

export const getIsProjectCompleted = (project_status) => {
  return project_status === PROJECT_STATUS.COMPLETED;
};

export const getShowCompletePurchase = (agreement_status, project_status) => {
  return agreement_status === AGREEMENT_STATUS.PAID && project_status !== PROJECT_STATUS.COMPLETED;
};

export const getShowCheckoutButton = (agreement_status) => {
  return agreement_status === AGREEMENT_STATUS.UPFRONT_PAID;
};

export const getShowReview = (project_status) => {
  return !(project_status === AGREEMENT_STATUS.PAID && project_status !== PROJECT_STATUS.COMPLETED);
};
