import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import SearchIcon from '@mui/icons-material/Search';
import FormStepper from 'components/FormStepper/FormStepper';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';

import { productPitchConversion, productPitchConversionWithProduct } from './ProductPitchConversionSteps';

import { routes } from 'options/routes';

import { getErrorMessage } from 'utils/getErrorMessage';

import { formDefaultValues, formValidation, steps, stepsWithProduct } from './ProductPitchConversion.data';

import { createPitchConversion } from 'api';

const ProductPitchConversion = ({ isStory, currency }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [apiFormData, setApiFormData] = useState({});

  useEffect(() => {
    if (id) {
      setApiFormData({ product_uuid: [id], buyers: [] });
    }
  }, [id]);

  const nextStepAction = useCallback(
    async (values, step) => {
      if ((id && step === 0) || (!id && step > 0)) {
        const { product_uuid, buyers } = values;

        const res = await createPitchConversion(product_uuid[0], { company_uuid: buyers[0].company_uuid });

        if (!res.success) {
          throw new Error(getErrorMessage(res));
        }

        return navigate(routes.pitchesAddThanks, { state: { from: 'conversion' } });
      }
    },
    [id, navigate]
  );

  return (
    <DesignerDashboardWrapper
      activeTab={routes.pitchesDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Pitches', onClick: () => navigate(routes.pitchesDashboard) }]}
          currentPage={'New Pitch Conversion'}
        />
      }
    >
      <HeaderAndMenu
        title="New pitch conversion"
        menuOptions={[
          { buttonTitle: 'View all pitches', icon: <SearchIcon />, onClick: () => navigate(routes.pitchesDashboard) },
        ]}
      />
      <FormStepper
        steps={id ? stepsWithProduct : steps}
        stepsSwitcher={id ? productPitchConversionWithProduct : productPitchConversion}
        formValidation={formValidation}
        defaultFormValues={formDefaultValues}
        apiFormData={apiFormData}
        nextStepAction={nextStepAction}
        onSaveAndExit={() => {}}
        saveAndExitSteps={[]}
        saveAndExitRequiredFields={[]}
        isStory={isStory}
        stepsOptions={{ currency }}
      />
    </DesignerDashboardWrapper>
  );
};

export default ProductPitchConversion;
