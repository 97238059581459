export const mockData = {
  suggested: {
    title: {
      name: 'Suggested',
      slug: 'furniture',
    },
    options: [
      {
        name: 'Sofas',
        slug: 'sofas',
      },
      {
        name: 'Chairs',
        slug: 'chairs',
      },
      {
        name: 'Lamps',
        slug: 'lamps',
      },
    ],
  },
  featured: {
    category: {
      name: 'Furniture',
    },
    imageUrl:
      'https://images.unsplash.com/photo-1551298370-9d3d53740c72?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80',
    label: 'Multi-coloured Dining Chairs',
    subtitle: 'By James Gill',
    slug: '/someproducturl',
  },
  tags: [
    {
      label: 'Wood',
      value: 'wood',
    },
    {
      label: 'Velvet',
      value: 'velvet',
    },
    {
      label: 'Outdoor',
      value: 'outdoor',
    },
    {
      label: 'Luxury',
      value: 'luxury',
    },
    {
      label: 'Rustic',
      value: 'rustic',
    },
    {
      label: 'License only',
      value: 'license_only',
    },
  ],
};
