import { Box, styled } from '@mui/material';

export const ProductSingleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ImagesContainer = styled('div')(({ theme }) => ({
  width: '60%',
  margin: `0 ${theme.spacing(1)} 0 0`,
  '> div': {
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(1),
    width: '100%',
    '> div': {
      marginBottom: 0,
    },
  },
}));

export const ContentContainer = styled('div')(({ theme }) => ({
  width: '40%',
  padding: `0 0 0 ${theme.spacing(1)}`,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: `${theme.spacing(1)} 0`,
  },
}));

export const ProductInfoContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'last',
})(({ theme, last }) => ({
  borderBottom: last ? '' : `1px solid ${theme.palette.common.black}`,
  paddingBottom: last ? theme.spacing(1) : theme.spacing(2),
}));

export const AgreementTextContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
}));

export const AgreementTextRow = styled(Box)(() => ({
  display: 'flex',
}));

export const AgreementTextWrapper = styled(Box)(({ theme }) => ({
  // height: '12px',
  padding: theme.spacing(1),
  '& .MuiFormControlLabel-root': {
    padding: 0,
    margin: 0,
  },
  '& p': {
    marginBottom: '2px',
  },
}));

export const RecommendedProductsWrapper = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.black}`,
}));
