import React, { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useOutletContext, useParams } from 'react-router';
import { Box } from '@mui/material';

import ProductSingle from 'components/ProductSingle/ProductSingle';

import { PROJECT_STATUS } from 'options/projectStatus';

import { useAuthContext } from 'context/AuthContext';


const BuyerPitchesSinglePitchTab = (props) => {
  const [pitch, setMessage, userType, onAccept, onReject, agreementTypeSelected, setAgreementTypeSelected] = useOutletContext();
  const { project_status, selected_agreement_type } = pitch;

  const showButtons = !selected_agreement_type && project_status !== PROJECT_STATUS.REJECTED;

  return (
    <Box mt={2}>
      <ProductSingle
        data={pitch}
        userType={userType}
        variant="pitch_view"
        showPurchase={false}
        showButtons={showButtons}
        acceptText="Connect with designer"
        rejectText="Decline Proposal"
        onAccept={onAccept}
        onReject={onReject}
        agreementTypeSelected={agreementTypeSelected}
        setAgreementTypeSelected={setAgreementTypeSelected}
      />
    </Box>
  );
};

BuyerPitchesSinglePitchTab.propTypes = {};

export default BuyerPitchesSinglePitchTab;
