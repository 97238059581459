import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import {
  CustomFileUploadContainerStyled,
  CustomFileUploadFieldStyled,
  FileUploadBackgroundStyled,
} from './FileUploadField.styles';

const fileTypesAccepted = {
  images: 'image/jpeg, image/png',
  files:
    '.jpeg,.jpg,.png,.ai,.ind,.psd,.tiff,.pdf,.3dm,.3DS,.acis,.dwg,.dxf,.FBX,.iges,.obj,.prt,.SKP,.sldasm,.sldprt,.step,.stp,.stl,.ppt',
  all: '*',
  pdf: '.pdf',
};

const CustomFileUploadField = ({
  variant,
  fileTypes,
  onChangeHandler,
  numberOfTiles,
  addFileText = 'Add a file',
  error,
}) => {
  const acceptedFileTypes = fileTypesAccepted[fileTypes];
  const isMultiSelect = variant === 'multi';

  return (
    <CustomFileUploadContainerStyled numberOfTiles={numberOfTiles} error={!!error}>
      <FileUploadBackgroundStyled>
        {fileTypes === 'images' ? (
          <>
            <CameraAltIcon />
            <Typography variant="body1">Add an image</Typography>
          </>
        ) : (
          <>
            <AttachFileIcon />
            <Typography variant="body1">{addFileText}</Typography>
          </>
        )}
      </FileUploadBackgroundStyled>
      <CustomFileUploadFieldStyled
        type="file"
        name="file"
        onChange={onChangeHandler}
        accept={acceptedFileTypes}
        multiple={isMultiSelect}
      />
    </CustomFileUploadContainerStyled>
  );
};

CustomFileUploadField.propTypes = {
  variant: PropTypes.oneOf(['single', 'multi']),
  fileTypes: PropTypes.oneOf(['images', 'files', 'pdf', 'all']),
  onChangeHandler: PropTypes.func,
};

export default CustomFileUploadField;
