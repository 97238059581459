import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useOutletContext, useParams } from 'react-router';

import { Box } from '@mui/material';
import FileUploads from 'components/FileUploads/FileUploads';

import { routes } from 'options/routes';

import { getIsFilesTabDisabled } from '../BriefSingle.utils';

import { downloadBuyerDesignFiles, getBuyerDesignFiles } from 'api';

const BuyerProposalDesigns = () => {
  const navigate = useNavigate();
  const { brief_uuid, brief_designer_uuid } = useParams();
  const [briefData, setMessages, userType] = useOutletContext();

  const { agreement_status, status } = briefData;

  const { data } = useQuery(['buyersDesignFiles'], () => getBuyerDesignFiles(brief_uuid, brief_designer_uuid), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
    refetchOnMount: 'always',
  });

  useEffect(() => {
    const isDisabled = getIsFilesTabDisabled(agreement_status, status);

    if (isDisabled) {
      navigate(`${routes.briefsDashboard}/${brief_uuid}/${brief_designer_uuid}/brief`);
    }
  }, [agreement_status, brief_designer_uuid, brief_uuid, navigate, status]);

  const design_files = data?.design_files || {};

  return (
    <Box>
      <Box mt={2}>
        <FileUploads
          files={design_files}
          userType={userType}
          downloadFiles={(data) => downloadBuyerDesignFiles(brief_uuid, brief_designer_uuid, data, true)}
          deleteFiles={() => {}}
        />
      </Box>
    </Box>
  );
};

BuyerProposalDesigns.propTypes = {};

export default BuyerProposalDesigns;
