import SignUpVerifyForm from '../../../../components/Forms/SignUpVerifyForm/SignUpVerifyForm';
import TwoColumnBlackWhiteWrapper from 'components/Layout/TwoColumnBlackWhiteWrapper/TwoColumnBlackWhiteWrapper';

const SignUpVerify = () => {
  return (
    <TwoColumnBlackWhiteWrapper>
      <SignUpVerifyForm />
    </TwoColumnBlackWhiteWrapper>
  );
};

export default SignUpVerify;
