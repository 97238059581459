import { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomMessageField from 'components/CustomMessageField/CustomMessageField';
import Message from 'components/Message/Message';
import CaptionText from 'components/Typography/CaptionText/CaptionText';
import ChatPill from 'components/ChatPill/ChatPill';
import Spinner from 'components/Spinner/Spinner';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { getBase64 } from 'utils/convertToBase64';
import { isAnyBuyer } from 'utils/isAnyBuyer';

import {
  ChatActionBtnsContainer,
  ChatBoxStyled,
  ChatBoxWrapper,
  ChatIconStyled,
  ChatOffIconStyled,
  FabStyled,
  MessageBarContainer,
  MessagesContainerStyled,
} from './ChatBox.styles';

const ChatBox = ({
  userType,
  chat_uuid,
  setMessage = () => {},
  disableDownload,
  getChatMessages,
  postChatMessage,
  downloadFiles,
  uploadFiles,
  deleteFiles,
  expand,
  setExpand,
  acceptBtnText,
  onAcceptClick,
  rejectBtnText,
  onRejectClick,
  isAcceptDisabled
}) => {
  const queryClient = useQueryClient();
  const [fileToSend, setFileToSend] = useState({});
  const containerRef = useRef(null);
  const [isLoadingAttachFile, setIsLoadingAttachFile] = useState(false);
  const { data, isLoading, refetch } = useQuery(
    ['fetchChatMessages', chat_uuid],
    () => (chat_uuid ? getChatMessages() : {}),
    {
      enabled: expand,
      refetchOnMount: 'always',
      refetchInterval: expand ? 1 * 60 * 1000 : null,
    }
  );

  const scrollDown = useCallback(() => {
    setTimeout(() => {
      const scrollHeight = containerRef?.current.scrollHeight;
      containerRef?.current.scrollTo(0, scrollHeight);
    }, 0);
  }, []);

  useEffect(() => {
    if (expand) {
      refetch();
      scrollDown();
    }
  }, [expand, refetch, scrollDown]);

  const messages = useMemo(() => data?.messages || {}, [data?.messages]);

  const onSubmit = useCallback(
    async (message) => {
      // if (!!Object.keys(fileToSend).length) {
      //   // const res = await postFileMessage(chat_uuid, { message });
      //   return;
      // }
      try {
        const res = await postChatMessage(chat_uuid, { body: message });

        if (!res.success) {
          throw new Error('There was an error submtting message');
        }

        queryClient.setQueryData(['fetchChatMessages', chat_uuid], (oldData) => {
          return {
            messages: {
              ...oldData?.messages,
              ...(!!oldData?.messages['Today']?.length
                ? { Today: [...oldData?.messages['Today'], res.message] }
                : { Today: [res.message] }),
            },
          };
        });
      } catch (err) {
        console.log('err', err);
        setMessage(err);
      }
    },
    [chat_uuid, postChatMessage, queryClient, setMessage]
  );

  useEffect(() => {
    scrollDown();
  }, [messages, scrollDown]);

  useEffect(() => {
    if (expand && containerRef?.current) {
      scrollDown();
    }
  }, [expand, scrollDown]);

  const handleRemoveAttachment = () => {
    setFileToSend({});
  };

  const handleDeleteFile = useCallback(
    async (file_uuid) => {
      try {
        const res = await deleteFiles(file_uuid);

        if (!res.success) {
          throw new Error('There was an error deleting the file');
        }
        setMessage('You have successfully deleted the file');
        if (refetch) {
          refetch();
        }
      } catch (err) {
        console.log(err);
        return setMessage('There was an error deleting the file');
      }
    },
    [deleteFiles, refetch, setMessage]
  );

  const handleFileDownload = useCallback(
    async (file_uuid, name) => {
      try {
        // setIsDownloadingFile(true);
        const link = document.createElement('a');
        link.target = '_blank';
        link.download = 'Files.zip';
        const data = file_uuid ? { files: [file_uuid], is_chat: true } : undefined;
        const res = await downloadFiles(data);

        // setIsDownloadingFile(false);
        link.href = URL.createObjectURL(new Blob([res], { type: res.type }));
        link.click();
      } catch (err) {
        console.log(err);
        return setMessage('There was an error downloading files');
      }
    },
    [downloadFiles, setMessage]
  );

  const handleAttachFile = useCallback(
    async (e) => {
      setIsLoadingAttachFile(true)
      const file = e.target.files[0];

      const based64File = await getBase64(file);
      const data = {
        files: [
          {
            fileSrc: based64File,
            fileType: file?.type || '',
            name: file?.name || '',
          },
        ],
        is_chat: true,
      };

      // setFileToSend(data);
      const res = await uploadFiles(data);

      if (!res.success) {
        return setMessage('There was an error uploading the files. Please try again.');
      }
      if (refetch) {
        await refetch();
      }
      setIsLoadingAttachFile(false)

      // const responseFile = res.file;
      // attach message to messages?
    },
    [refetch, setMessage, uploadFiles]
  );

  const selectedFileType = (!!Object.keys(fileToSend).length && fileToSend.files[0]?.fileType) || '';

  const showActions = useMemo(() => !!onAcceptClick || !!onRejectClick, [onAcceptClick, onRejectClick]);

  return (
    <ChatBoxWrapper>
      <ChatBoxStyled expand={expand} boxShadow={1}>
        <MessagesContainerStyled expand={expand} ref={containerRef}>
          {isLoading && (
            <Box>
              <Spinner align="center" flex={0} />
            </Box>
          )}
          {!isLoading && !Object.keys(messages).length && (
            <Box display="flex" justifyContent="center">
              <BodyText text="There are no messages yet." />
            </Box>
          )}
          {!!Object.keys(messages).length &&
            Object.entries(messages).map(([key, value]) => {
              return (
                <Box display="flex" flexDirection="column" key={key}>
                  <Box display="flex" justifyContent="center" mb={1}>
                    <CaptionText text={key} />
                  </Box>
                  {value?.map(
                    ({ message_uuid, body, type, sender, created_time, file_extension, file_uuid, avatar }) => {
                      const variant =
                        sender === userType || (isAnyBuyer(sender) && isAnyBuyer(userType)) ? 'sender' : 'receiver';
                      return type === 'system' ? (
                        <ChatPill variant={variant} body={body} created={created_time} key={message_uuid} />
                      ) : (
                        <Message
                          avatar={avatar}
                          text={body}
                          caption={created_time}
                          variant={variant}
                          key={message_uuid}
                          file_extension={file_extension}
                          file_uuid={file_uuid}
                          handleDeleteFile={handleDeleteFile}
                          handleFileDownload={handleFileDownload}
                          disableDownload={disableDownload}
                        />
                      );
                    }
                  )}
                </Box>
              );
            })}
        </MessagesContainerStyled>

        <MessageBarContainer expand={expand}>
          {showActions && (
            <ChatActionBtnsContainer>
              {!!onAcceptClick && (
                <CustomButton fullWidth text={acceptBtnText || 'Accept'} onClick={onAcceptClick} colour="success" disabled={isAcceptDisabled} />
              )}
              {!!onRejectClick && (
                <CustomButton fullWidth text={rejectBtnText || 'Reject'} onClick={onRejectClick} colour="error" />
              )}
            </ChatActionBtnsContainer>
          )}
          <CustomMessageField
            onSubmit={onSubmit}
            handleAttachFile={handleAttachFile}
            handleRemoveAttachment={handleRemoveAttachment}
            fileToSendType={selectedFileType}
            disableDownload={disableDownload}
            isLoadingAttachFile={isLoadingAttachFile}
          />
        </MessageBarContainer>
      </ChatBoxStyled>
      <FabStyled color="primary" onClick={() => setExpand()}>
        {expand ? <ChatOffIconStyled /> : <ChatIconStyled />}
      </FabStyled>
    </ChatBoxWrapper>
  );
};

ChatBox.propTypes = {
  messages: PropTypes.object,
  userType: PropTypes.string.isRequired,
};

export default ChatBox;
