import { yupObject, validation } from 'utils/validation';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { LICENSE_TYPES, TERM_TYPES } from 'options/briefOptions';

const validationStep2 = yupObject({
  ...validation.productProperties,
});

const validationStep4 = yupObject({
  ...validation.newBriefFormFields,
});

const { newBriefTermsAndLicensingFields, newBriefTermsAccept } = validation;

const schemaStep5 = {
  ...newBriefTermsAndLicensingFields,
  ...newBriefTermsAccept,
};

const validationStep5 = yupObject().shape(schemaStep5, ['agreement_file', 'agreement_body']);

export const validationList = [
  yupObject({}),
  validationStep2,
  yupObject({}),
  validationStep4,
  validationStep5,
  yupObject({}),
];

export const validationListEditting = [validationStep2, yupObject({}), validationStep4, validationStep5, yupObject({})];
export const validationListEditingActive = [validationStep2, validationStep4, yupObject({})];

export const formDefaultValues = {
  // step 2
  [OPTIONS_TYPE.product]: [],
  [OPTIONS_TYPE.material]: [],
  [OPTIONS_TYPE.space]: [],
  [OPTIONS_TYPE.style]: [],
  [OPTIONS_TYPE.colour]: [],
  [OPTIONS_TYPE.sustainability]: [],

  // step 4
  title: '',
  design_requirements: '',
  file_requirements: [],
  customer_requirements: '',
  commercial_requirements: '',
  main_image: [],
  markets: [],
  reference_file: [],
  additional_images: [],
  manufacturing_requirements: '',
  projected_volume: [],
  projected_retail_price: '',
  projected_cost_price: '',

  // step 5
  // terms and conditions
  term_type: TERM_TYPES.new_terms,
  agreement_title: '',
  agreement_body: '',
  agreement_file: [],
  terms_uuid: '',

  designers: [],

  // licensing
  agreement_type: LICENSE_TYPES.royalty,
  payment_terms: '',
  deadline: null,
  upfront_payment: 'true',
  upfront_payment_amount: '',
  //   royalty licensing specific fields
  royalty_percentage: '',
  royalty_review_period: '',
  //   one time fee specific fields
  one_off_budget: '',

  //   terms accept
  payment_terms_agreement: false,
  options_on_design_agreement: false,
  reference_images_agreement: false,

  // api required?
  designer_note: 'test',
};

export const steps = ['Type of Brief', 'Product Properties', 'Product Overview', 'Design Brief', 'Terms', 'Summary'];
export const stepsEditting = ['Product Properties', 'Product Overview', 'Design Brief', 'Terms', 'Summary'];
export const stepsEditingActive = ['Product Properties', 'Design Brief', 'Summary'];

export const fakeData = {
  product_category: [
    {
      label: 'Filing Storage',
      value: 'filing-storage',
      category: 'product_category',
      tooltipText: 'Decor and Home Accessories > Home Office',
    },
  ],
  material_tag: [
    {
      label: 'Polyester',
      value: 'polyester',
      category: 'material_tag',
      tooltipText: 'Textile > Synthetic',
    },
  ],
  space_tag: [
    {
      label: 'Kids',
      value: 'kids',
      category: 'space_tag',
      tooltipText: '',
    },
    {
      label: 'Kitchen',
      value: 'kitchen',
      category: 'space_tag',
      tooltipText: '',
    },
    {
      label: 'Living',
      value: 'living',
      category: 'space_tag',
      tooltipText: '',
    },
  ],
  style_tag: [
    {
      label: 'Scandinavian',
      value: 'scandinavian',
      category: 'style_tag',
      tooltipText: '',
    },
    {
      label: 'Modern/Contemporary',
      value: 'moderncontemporary',
      category: 'style_tag',
      tooltipText: '',
    },
  ],
  colour_tag: [
    {
      label: 'Green',
      value: 'green',
      category: 'colour_tag',
      tooltipText: '',
    },
  ],
  sustainability_tag: [],
  title: 'reqs',
  design_requirements: 'reqs',
  file_requirements: [
    {
      label: '.ACIS',
      value: '.acis',
    },
  ],
  customer_requirements: 'reqs',
  commercial_requirements: 'eqrqdasd',
  main_image: [],
  markets: [
    {
      label: 'EU',
      value: 'eu',
    },
  ],
  reference_file: [],
  additional_images: [],
  manufacturing_requirements: 'manus',
  projected_volume: [
    {
      label: '1,000-5,000',
      value: '1000-5000',
    },
  ],
  projected_retail_price: '20',
  projected_cost_price: '20',
  term_type: 'new_terms',
  agreement_title: 'terms name',
  agreement_body: 'details',
  agreement_file: [],
  selected_terms_uuid: '',
  agreement_type: 'royalty',
  payment_terms: 'terms',
  deadline: '2022-09-15T15:12:38.000Z',
  upfront_payment: 'no',
  upfront_payment_amount: '',
  royalty_percentage: '20',
  royalty_review_period: 'Quarterly',
  one_off_budget: '',
  payment_terms_agreement: true,
  options_on_design_agreement: true,
  reference_images_agreement: true,
};

export const fakeTags = {
  product_category: [
    {
      label: 'Filing Storage',
      value: 'filing-storage',
      category: 'product_category',
      tooltipText: 'Decor and Home Accessories > Home Office',
    },
  ],
  material_tag: [
    {
      label: 'Polyester',
      value: 'polyester',
      category: 'material_tag',
      tooltipText: 'Textile > Synthetic',
    },
  ],
  space_tag: [
    {
      label: 'Kids',
      value: 'kids',
      category: 'space_tag',
      tooltipText: '',
    },
    {
      label: 'Kitchen',
      value: 'kitchen',
      category: 'space_tag',
      tooltipText: '',
    },
    {
      label: 'Living',
      value: 'living',
      category: 'space_tag',
      tooltipText: '',
    },
  ],
  style_tag: [
    {
      label: 'Scandinavian',
      value: 'scandinavian',
      category: 'style_tag',
      tooltipText: '',
    },
    {
      label: 'Modern/Contemporary',
      value: 'moderncontemporary',
      category: 'style_tag',
      tooltipText: '',
    },
  ],
  colour_tag: [
    {
      label: 'Green',
      value: 'green',
      category: 'colour_tag',
      tooltipText: '',
    },
  ],
  sustainability_tag: [],
};
