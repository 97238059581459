import React from 'react';
import { useOutletContext } from 'react-router';

import { Box } from '@mui/material';
import ProductSingle from 'components/ProductSingle/ProductSingle';

import { userTypes } from 'options/userTypes';

const BuyerSingleProductTab = (props) => {
  const [product] = useOutletContext();

  return (
    <Box mt={2}>
      <ProductSingle data={product} userType={userTypes.buyer} variant="product_view" showPurchase={false} />
    </Box>
  );
};

BuyerSingleProductTab.propTypes = {};

export default BuyerSingleProductTab;
