const DesignerTerms = `

**These terms and conditions govern your use of the BuyDesign Platform, and your sale and licensing of product designs through it. By accepting these terms and conditions, you agree to enter into a contract with BuyDesign Management Ltd of Flat 2, 9 Palace Gate, London, England, W8 5LS (“BuyDesign”), as set out below.**

**1. DEFINITIONS**

“ **Design** ” means a design for a product, logo, artwork, or pattern created by you that you submit to us for inclusion on the Platform.

“ **Designer** ” means you.

**"Intellectual Property Rights"** means all copyright, patents, database rights, registered and unregistered design rights, topography rights, trade marks and service marks and applications for any of the foregoing, together with all trade secrets, know-how, rights in confidential information and other intellectual and industrial property rights in all parts of the world.

**“Platform”** means the website operated and made available by BuyDesign through which Designs can be offered for sale by Designers and purchased or licensed by Purchasers.

“ **Purchaser** ” means a person or organization that purchases or wishes to purchase a Design through the Platform.

We do not guarantee that your request to create an account with BuyDesign will be approved. We review all applications carefully, and we may reject or approve any request at our sole discretion.

**2. SUBMITTING DESIGNS TO THE PLATFORM**

2.1. Any Designs you wish to submit to us for inclusion on the Platform must be submitted using the
platform or mechanism that we notify to you, which may be updated from time to time. Any
Designs you submit to the Platform must comply with the BuyDesign design guidelines
published on the Platform.
2.2. You acknowledge that we have no obligation to include any Design submitted by you on the
Platform, and that we do not guarantee that any given Design will be sold, or that any Design
will be sold for a specific price.
2.3. We reserve the right to remove a Design from the Platform at any time at our sole discretion,
for reasons including sale of the Design to a Purchaser, notification of a claim of infringement of
third-party Intellectual Property Rights, or any breach of these terms and conditions. We will
endeavour to notify you of the removal of any of your Designs from the Platform as promptly as
possible, except where we are prevented from notifying you by any applicable law.
2.4. When submitting a Design to us, you will have the option to provide details of any registered
design protection applicable to your Design, including full details of such registration(s).
2.5. You warrant that any Designs which you submit to us for inclusion on the Platform will be your
own original work.
2.6. You authorize BuyDesign to act as your exclusive agent for any commercialization of any
Designs which you submit to us for inclusion on the Platform whilst those Designs are visible to
Purchasers on the Platform. You are free to remove any Design from being visible on the
Platform at any time.

**3. CONTACT WITH PURCHASERS**

3.1. You acknowledge and agree that, without the prior written consent of BuyDesign, all
commercial transactions in relation to (a) the sale or license of any Designs that you have
uploaded to the Platform or (b) in relation to a request for product design services published on
the Platform by a Purchaser or (c) any other provision of product design work by you to a
Purchaser that you have been introduced to through the Platform, must be conducted through
the Platform.
3.2. If a Purchaser contacts you directly (i.e. outside of the Platform) as a result of the promotion of
your Designs or your profile on the Platform or in relation to a brief or open call published on
the Platform by the Purchaser in order to engage you with product design work other than in
relation to Designs that you have uploaded to the Platform or product design briefs that the
Purchaser has created on the Platform, you shall ensure that such engagement is exclusively
conducted through the Platform.
3.3. If you act in breach of this section 3, you acknowledge that BuyDesign may suffer financial and
other losses, and you hereby indemnify BuyDesign in relation to any such losses and other
financial penalties that BuyDesign may impose at its sole discretion without limitation. We
reserve the right to immediately terminate your BuyDesign account in these circumstances.
3.4. This section 3 shall survive termination or expiry of these terms and conditions.
3.5. This section 3 shall not apply to relationships between you and any Purchaser or prospective
Purchaser with whom you have a relationship that pre-dates your relationship with us, or who
you have made initial contact with outside of the Platform, and which can be demonstrated by
way of documented evidence of such prior collaboration.

**4. INTELLECTUAL PROPERTY RIGHTS**

4.1. You remain the owner of the Intellectual Property Rights in all Designs that you submit to us for
inclusion in the Platform, until such Design is sold to a Purchaser.
4.2. You hereby grant us an exclusive, sublicensable, irrevocable and royalty-free license to use the
Designs and all associated Intellectual Property Rights for the purposes contemplated by this
Agreement, including to advertise and sell your Designs.
4.3. You acknowledge that you provide us with Designs for the purposes of selling those Designs to
Purchasers, and you hereby authorize us to act as your agent in promoting your Designs and
receiving payment on your behalf in respect of any transaction entered into in respect of them.
4.4. You agree that we can publish any Design you provide us with on the Platform, and that you
will not assert any moral rights against us in relation to our operation of the Platform. We will
notify you of any third party claim we receive that any Design infringes any Intellectual Property
Rights. You remain solely responsible for the handling of any such claim.
4.5. You can notify us of any issue you may encounter regarding misuse of any of your Designs by
any other user of the Platform. We may, at our sole discretion, share with you information
within our control regarding views and downloads of your Designs by such other users.
4.6. You acknowledge and agree that we may, at our sole discretion, share information regarding
your use of the Platform with other users in relation to protection and enforcement of
Intellectual Property Rights and any contract entered into through the Platform.

**5. WARRANTIES**


5.1. In respect of each Design you submit to us for inclusion on the Platform, you hereby warrant
and undertake that:
5.1.1. the Design is your own original work, and has not been copied from any third party;
5.1.2. you are the sole owner of the Intellectual Property Rights in the Design and have full
authority to enter into these terms and conditions;
5.1.3. you have not sold or licensed any Intellectual Property Rights in the Design to any third
party prior to submitting it to us;
5.1.4. the Design does not incorporate any material that infringes the Intellectual Property Rights
of any third party;
5.1.5. the Design does not contain any offensive or defamatory matter, and the use of the Design
shall not infringe any laws or regulations;
5.1.6. you shall not upload to the Platform any content owned by any third party in respect of
which you do not have sufficient permissions to share such content with us;
5.1.7. you have not received any notification that the Design infringes or threatens to infringe
any third-party Intellectual Property Rights; and
5.1.8. The use of the Design does not require the payment of any license fee or royalty to any
third party, or the use of any notice of attribution.
5.2. You hereby indemnify BuyDesign, any Purchaser of a Design submitted by you, and all of their
respective assignees, licensees, and successors in title, in relation to all actions, proceedings,
costs, claims and damages whatsoever incurred by or awarded against that person in
consequence of any breach by you of any of the warranties and undertakings in these terms
and conditions.

**6. SALE & LICENSING OF DESIGNS**

6.1. You acknowledge and agree that these terms and conditions only govern your use of the
Platform. Any agreement with a Purchaser regarding the use of any of your Designs shall be
subject to a separate agreement between you and the Purchaser. You remain solely
responsible for any such agreement and acknowledge that BuyDesign shall have no liability for
any act or omission in relation to your agreement with any Purchaser. You are advised to seek
independent legal advice if you have any uncertainty regarding any agreement that a Purchaser
may ask you to sign.
6.2. You are free to set the price for any Design, and any agreement you enter into with a Purchaser
for any given Design shall include a provision for any payments owed to you to be made
through BuyDesign acting as your agent in relation to the sale or licensing of that Design. You
acknowledge and agree that any agreement you enter into with a Purchaser in relation to any
Design must be concluded through the Platform, and that we shall be permitted to retain a
copy for our records. You accept that BuyDesign shall have no liability in relation to non-
payment by the Purchaser. BuyDesign does not act as your agent for anything other than the
receipt of payments for transactions concluded on the Platform.
6.3. In respect of any Designs which are purchased by a Buyer through the Platform on a one-off
payment basis, we shall take payment on top of the agreed one-off amount from the Buyer for
our commission.
6.4. In respect of any Designs which are licensed by you to a Buyer through the Platform on an
ongoing royalty basis, you shall pay BuyDesign a commission of 15% of the royalty income
payable to you. You agree that we may deduct our commission from the amounts we receive
from the Buyer on your behalf, before remitting the remaining royalty payments to you. We ask
the Buyer to provide us with a report on a periodic basis (being calendar quarterly unless
otherwise agreed between you and the Buyer in the relevant contract) of royalties payable for
the preceding royalty period for any Designs you license to a Buyer. We will invoice the Buyer
the royalty payable, and they must pay such invoice within 30 days. Upon receipt of full cleared
payment from the Buyer, we will notify you of such receipt, and you may then invoice us for
that royalty payment (less the above-stated commission). We will pay your invoice within 30
days of receipt.
6.5. You remain responsible for the payment of all taxes in relation to the receipt of the payments
made to you under these terms and conditions.
6.6. You acknowledge that any payment made to you in accordance with sections 6.3 and 6.4 are in
full and final compensation for the purchase or use of the Design(s) submitted by you, and you
acknowledge that you shall not be entitled to any further fees or compensation in respect of
any Design. All sums received by BuyDesign by way of commission are non-cancellable and non-
refundable in any circumstances.
6.7. You acknowledge and agree that payments for any Design sold or licensed to a Purchaser
through the Platform shall continue to be made through BuyDesign for the duration of your
agreement with the Purchaser, regardless of whether you or the Purchaser continue to
maintain an account on the Platform. This obligation shall survive termination or expiry of
these terms for any reason.

**7. TERMINATION**

7.1. These terms and conditions may be terminated immediately by either party for failure of the
other party to comply with any material term of these terms and conditions, unless the failure
to comply has been remedied within 30 days of notification of such failure.
7.2. Upon termination of these terms and conditions for any reason and unless specified otherwise
herein, all rights and licenses granted pursuant to this terms and conditions shall cease, save
that (a) BuyDesign’s right to use and sell any Designs submitted to it before the date of
termination shall continue as if these terms and conditions were still in force, and (b)
BuyDesign shall continue to act as your agent for receipt of any sums due in relation to specific
Designs that you uploaded to the Platform under these terms and conditions in relation to any
agreement entered into with a Purchaser prior to the date of termination for such Designs.

**8. OTHER PROVISIONS**
    8.1. BuyDesign shall not be liable to you for any costs, expenses, losses or damages (whether direct,
       indirect or consequential and whether economic or other) arising in relation to these terms and
       conditions, or any claim by a Purchaser against you in relation to their use of the Designs
       purchased by them.
    8.2. Our liability to you shall be limited to the commission which you have paid to us in the 12
       months preceding the date of any liability arising.


8.3. We are entitled to amend or vary these terms and conditions from time to time at our sole
discretion. We will notify you of any change which materially affects your rights and
obligations.
8.4. We are entitled to assign or license all of the rights granted to us under these terms and
conditions to any third party. You shall not be entitled to assign or sublicense your rights under
these terms and conditions.
8.5. If any provision or part-provision of these terms and conditions is or becomes invalid, illegal or
unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid,
legal and enforceable. If such modification is not possible, the relevant provision or part-
provision shall be deemed deleted. Any modification to or deletion of a provision or part-
provision under this section shall not affect the validity and enforceability of the rest of these
terms and conditions.
8.6. These terms and conditions and the documents referred to in them constitute the entire
agreement between the parties and supersedes and extinguishes all previous agreements,
promises, assurances, warranties, representations and understandings between them, whether
written or oral, relating to its subject matter.
8.7. These terms and conditions shall be governed by the laws of England and the parties submit to
the exclusive jurisdiction of the courts of England in relation to any disputes arising under them.


`;

export default DesignerTerms;
