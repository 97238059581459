import { styled } from '@mui/material';
import { Box } from '@mui/material';

export const FormContainer = styled('div')(() => ({
  flex: 1,
}));

export const Container = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));
