import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import DesignerBriefsList from 'components/DesignerBriefsList/DesignerBriefsList';

import { routes } from 'options/routes';

export const DesignerBriefsDashboard = () => {
  return (
    <DesignerDashboardWrapper activeTab={routes.briefsDashboard}>
      <HeaderAndMenu title="Briefs" menuOptions={[]} />
      <DesignerBriefsList />
    </DesignerDashboardWrapper>
  );
};

export default DesignerBriefsDashboard;
