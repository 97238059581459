import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';

import { Box } from '@mui/material';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';
import Spinner from 'components/Spinner/Spinner';
import ModalProposalAgreementSigned from 'components/ModalProposalAgreementSigned/ModalProposalAgreementSigned';
import AgreementAccordion from 'components/AgreementAccordion/AgreementAccordion';

import { AGREEMENT_STATUS } from 'options/agreementStatus';
import { routes } from 'options/routes';
import { userTypes } from 'options/userTypes';

import { parseRemoteNegotiatedTerms } from 'pages/Dashboard/Designer/Briefs/DesignerBriefAgreement/DesignerBriefAgreement.utils';
import { parseRemoteData as parseRemoteTermsData } from 'pages/Dashboard/Buyer/Terms/BuyerTermsNew/utils/BuyerTermsNew.utils';
import { getIsAgreementTabDisabled } from '../DesignerOpenCalls.utils';

import { acceptDesignerOpenCallsAgreement, getDesignerOpenCallsAgreement, getSingleTerm } from 'api';

const DesignerOpenCallsAgreementTab = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { brief_uuid } = useParams();
  const [briefData, setMessage, userType] = useOutletContext();

  const { project_status, proposal_status, agreement_status, selected_terms_uuid, currency } = briefData;

  useEffect(() => {
    const isAgreementDisabled = getIsAgreementTabDisabled(project_status, proposal_status);

    if (isAgreementDisabled) {
      navigate(`${routes.openCallsDashboard}/${brief_uuid}/open-call`);
    }
  }, [navigate, project_status, proposal_status, brief_uuid]);

  const { data, isLoading, isError, refetch, isRefetching } = useQuery(
    ['designerOpenCallAgreement', brief_uuid],
    () => getDesignerOpenCallsAgreement(brief_uuid),
    {
      enabled: true,
      staleTime: 5 * 60 * 1000,
    }
  );

  const nonParsedValues = data?.agreement;
  const parsedAgreementData =
    (data && !data?.agreement?.download_url && parseRemoteNegotiatedTerms(nonParsedValues)) || {};
  const download_url = data?.agreement?.download_url;
  const agreementError = !data?.data?.success && data?.data?.error_message;

  const { data: termsData } = useQuery(
    ['designerSingleTerm', selected_terms_uuid],
    () => (selected_terms_uuid ? getSingleTerm(selected_terms_uuid) : {}),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const parsedTermsData = (termsData?.terms && parseRemoteTermsData(termsData.terms)) || {};

  useEffect(() => {
    if (agreementError) {
      setMessage(agreementError);
    }
  }, [agreementError, setMessage]);

  const [openAcceptAgreement, setOpenAcceptAgreement] = useState(false);
  const [openHasAcceptedAgreement, setOpenHasAcceptedAgreement] = useState(false);

  const agreement_uuid = parsedAgreementData?.agreement_uuid;

  const handleAcceptAgreement = async () => {
    const res = await acceptDesignerOpenCallsAgreement(brief_uuid, agreement_uuid);
    if (!res.success) {
      setMessage('There was a problem accepting the agreement.');
      return setOpenAcceptAgreement(false);
    }
    setOpenAcceptAgreement(false);
    setOpenHasAcceptedAgreement(true);
    // refetch and refresh data
    refetch();
    queryClient.refetchQueries(['designerSingleOpenCall', brief_uuid], { type: 'active', exact: true });
  };

  const onAcceptAgreement = () => {
    setOpenAcceptAgreement(true);
  };

  const loading = isLoading || isRefetching;

  return (
    <Box mt={2}>
      {loading ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <>
          <AgreementAccordion
            briefDetails={{
              ...([
                AGREEMENT_STATUS.IN_REVIEW,
                AGREEMENT_STATUS.AWAITING_BUYER,
                AGREEMENT_STATUS.AWAITING_SIGN,
              ].includes(agreement_status)
                ? parsedAgreementData
                : {}),
              ...parsedTermsData,
              currency,
            }}
            project_status={project_status}
            agreement_status={agreement_status}
            userType={userTypes.designer}
            download_url={download_url}
            onAcceptAgreement={onAcceptAgreement}
            proposal_status={proposal_status}
          />
          {openAcceptAgreement && (
            <ModalConfirmAccept
              open={openAcceptAgreement}
              name="agreement"
              handleSubmit={handleAcceptAgreement}
              handleClose={() => setOpenAcceptAgreement(false)}
            />
          )}
          {openHasAcceptedAgreement && (
            <ModalProposalAgreementSigned
              open={openHasAcceptedAgreement}
              handleClose={() => setOpenHasAcceptedAgreement(false)}
              userType={userType}
            />
          )}
        </>
      )}
    </Box>
  );
};

DesignerOpenCallsAgreementTab.propTypes = {};

export default DesignerOpenCallsAgreementTab;
