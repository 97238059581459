import { ListItem, ListItemIcon, styled } from '@mui/material';

export const ListItemStyled = styled(ListItem)(({ theme, disabled }) => ({
  padding: `${theme.spacing(1)} 0`,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  overflowY: 'scroll',
  maxHeight: '80%',
  opacity: disabled ? 0.4 : 1,
  '&:hover': {
    backgroundColor: theme.palette.background.pink,
  },
}));

export const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
  minWidth: 'unset',
}));
