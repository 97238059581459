import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';

const ModalStaticMessage = ({
  variant,
  designer_name,
  company_name,
  item_name,
  buyer_user_name,
  buyer_last_name,
  moodboard_name,
  invitee_name,
}) => {
  const getStaticMessageComponent = () => {
    switch (variant) {
      case 'pitch': {
        return (
          <>
            <Typography variant="body1">
              Hi <strong>{designer_name}</strong>,<br /> Thank you very much for submitting your pitch,{' '}
              <strong>{item_name}</strong>, and expressing your interest in working with <strong>{company_name}</strong>
              .
              <br />
              We regret that, on this occasion, your project doesn't correspond with our strategy for new products.
              <br />
              We do hope you consider us for future ideas and for the chance to work with each other in the future.
              <br />
              <br />
            </Typography>
            <ControlledTextField
              name="message"
              placeholder="Add a personal note here"
              autoComplete="off"
              multiline={true}
              minRows={1}
            />
            <Typography variant="body1">
              <br />
              Please keep an eye out for any Briefs or Open Calls we may publish on BuyDesign.
              <br />
              <br /> Thank you, <br />
              All the best
              <br />
              <strong>{company_name}</strong>.
            </Typography>
          </>
        );
      }
      case 'open-call-proposal': {
        return (
          <>
            <Typography variant="body1">
              Hi <strong>{designer_name}</strong>,<br /> Thank you very much for submitting your proposal to our Open
              Call, <strong>{item_name}</strong>, and expressing your interest in working with{' '}
              <strong>{company_name}</strong>.
              <br />
              We regret that, on this occasion, your project doesn't correspond with our strategy for new products. We
              do hope you consider us for future ideas and for the chance to work with each other in the future.
              <br />
              <br />
            </Typography>
            <ControlledTextField
              name="message"
              placeholder="Enter your optional message"
              autoComplete="off"
              multiline={true}
              minRows={1}
            />
            <Typography variant="body1">
              <br />
              Please keep an eye out for any Briefs or Open Calls we may publish on BuyDesign.
              <br />
              <br /> Thank you, <br />
              All the best
              <br />
              <strong>{company_name}</strong>.
            </Typography>
          </>
        );
      }
      case 'brief-proposal': {
        return (
          <>
            <Typography variant="body1">
              Hi <strong>{designer_name}</strong>,<br /> Thank you very much for submitting your proposal to our Brief,{' '}
              <strong>{item_name}</strong>, and expressing your interest in working with <strong>{company_name}</strong>
              .
              <br />
              We regret that, on this occasion, your project doesn't correspond with our strategy for new products. We
              do hope you consider us for future ideas and for the chance to work with each other in the future.
              <br />
              <br />
            </Typography>
            <ControlledTextField
              name="message"
              placeholder="Enter your optional message"
              autoComplete="off"
              multiline={true}
              minRows={1}
            />
            <Typography variant="body1">
              <br />
              Please keep an eye out for any Briefs or Open Calls we may publish on BuyDesign.
              <br />
              <br /> Thank you, <br />
              All the best
              <br />
              <strong>{company_name}</strong>.
            </Typography>
          </>
        );
      }
      case 'invite-designer': {
        return (
          <>
            <Typography variant="body1">
              Hi <strong>{designer_name}</strong>,
              <br />
              <strong>{buyer_user_name}</strong> at <strong>{company_name}</strong> has invited you to join
              buydesign.com, a digital platform where brands, retailers, and manufacturers connect, create, and
              collaborate with designers.
              <br />
              You can receive briefs privately, pitch ideas securely and reply to open calls directly. Please accept the
              invite to create your account and begin working with {company_name}.
              <br />
              <br />
            </Typography>
            <ControlledTextField
              name="message"
              placeholder="Enter your optional message"
              autoComplete="off"
              multiline={true}
              minRows={1}
            />
            <Typography variant="body1">
              <br />
              We hope to hear from you,
              <br />
              All the Best
              <br />
              <strong>BuyDesign</strong>
            </Typography>
          </>
        );
      }
      case 'share-mooodboard-external': {
        return (
          <>
            <Typography variant="body1">
              <strong>
                {buyer_user_name} {buyer_last_name} has shared a mood board with you. To access the moodboard, please
                log in and click on the link provided below.
              </strong>
              <br />
              <br />
              <strong>
                After logging in, you will be able to save the mood board directly to your personal mood board tab. This
                will allow you to conveniently make edits and share it with your colleagues.
              </strong>
              <br />
            </Typography>
            {/* <Typography variant="body1">
              <br />
              All the Best
              <br />
              <strong>BuyDesign</strong>
            </Typography> */}
          </>
        );
      }
      default: {
        return <></>;
      }
    }
  };
  return getStaticMessageComponent();
};

ModalStaticMessage.propTypes = {};

export default ModalStaticMessage;
