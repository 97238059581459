import { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import PillTag from 'components/PillTag/PillTag';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomFieldSet from 'components/Forms/CustomFieldSet/CustomFieldSet';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';

import { CategoriesWrapperStyled, TableStyled } from './NewBriefDesignersList.styles';

import { newBriefDesignersList } from 'options/tableOptions';
import { filterCategories, sortByObjProp } from 'utils/sortHelpers';

import { addFilters } from 'utils/addFilters';
import { getUniqueList } from 'utils/getUniqueList';
import ModalFindDesigner from 'components/ModalFindDesigner/ModalFindDesigner';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';

import { useTableFilter } from 'hooks/useTableFilter';

const NewBriefDesignersList = ({ isStory, mockData, mockTotal, designersList }) => {
  const { order, orderBy, filter, setFilter, onRequestSort } = useTableFilter({
    initialOrder: 'desc',
    initialOrderBy: 'experience',
  });
  const [openFindDesigners, setOpenFindDesigners] = useState(false);

  const { setValue, getValues, formState } = useFormContext();

  const onRemove = useCallback(
    (uuid) => {
      // remove
      const designersValue = getValues('designers');
      setValue(
        'designers',
        designersValue.filter((d) => d.uuid !== uuid)
      );
    },
    [getValues, setValue]
  );

  const addMoreDesigners = useCallback(() => {
    setOpenFindDesigners(true);
  }, []);

  const allCategories = useMemo(
    () =>
      getUniqueList(
        designersList
          ?.map(({ categories }) => categories)
          ?.flat()
          .filter(Boolean),
        'slug'
      ),
    [designersList]
  );

  const itemList = useMemo(
    () => ({
      categories: allCategories,
    }),
    [allCategories]
  );

  const headingsOnClick = useMemo(() => {
    return newBriefDesignersList.map((item) =>
      !item.sortable
        ? {
            ...item,
            menuOptions: addFilters(item, itemList, setFilter, filter),
          }
        : item
    );
  }, [filter, itemList]);

  return (
    <Box>
      <Box>
        <CustomTitle text="Add designers" variant="h3" />
      </Box>
      <Box mt={2}>
        <CustomFieldSet
          label="Designers you've selected"
          description="You can add more designers here or remove them too."
        />
      </Box>
      <CustomTableWrapper
        noResultsTitle={designersList.length === 0 ? 'No designers found' : ''}
        noResultsDescription={
          designersList.length === 0 ? "You haven't added any designers yet.\nYou can select multiple designers." : ''
        }
      >
        <TableStyled>
          <CustomTableHeader
            columnHeadings={headingsOnClick}
            onRequestSort={onRequestSort}
            order={order}
            orderBy={orderBy}
          />
          <CustomTableBody>
            {designersList
              ?.sort((a, b) => sortByObjProp(a, b, order, orderBy))
              .filter(({ categories }) => filterCategories(categories, filter?.categories))
              .map((designer, index) => {
                const { first_name, last_name, categories, location, experience, image, uuid } = designer;
                const name = `${first_name} ${last_name}`;
                const threeDotMenuActions = [{ title: 'Remove', onClick: () => onRemove(uuid) }];
                return (
                  <CustomTableRow key={`table_${name}_${index}`}>
                    <CustomTableBodyCell>
                      <ItemInfo avatar={image || {}} label={name} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <CategoriesWrapperStyled>
                        {!!categories?.length
                          ? categories.map((category, i) => {
                              const { name, slug } = category;
                              return (
                                <Box mr={1} mb={1} key={`${category}_${i}`}>
                                  <PillTag option={{ label: name, value: slug }} />
                                </Box>
                              );
                            })
                          : '--'}
                      </CategoriesWrapperStyled>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <TableBodyText text={location} />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box pl={2}>
                        <TableBodyText text={`${experience} ${experience === '1' ? 'year' : 'years'}`} />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <ThreeDotMenu actions={threeDotMenuActions} variant="vertical" />
                      </Box>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
          </CustomTableBody>
        </TableStyled>
      </CustomTableWrapper>
      <Box mt={2}>
        <CustomButton text="Select Designers" onClick={addMoreDesigners} variant="outlined" />
      </Box>
      {openFindDesigners && (
        <ModalFindDesigner open={openFindDesigners} handleClose={() => setOpenFindDesigners(false)} />
      )}
      <Box height="20px">
        {formState?.errors?.designers && <ErrorMessage>{formState?.errors?.designers?.message}</ErrorMessage>}
      </Box>
    </Box>
  );
};

NewBriefDesignersList.propTypes = {
  isStory: PropTypes.bool,
};

export default NewBriefDesignersList;
