import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ControlledPasswordField from 'components/Inputs/TextFields/CustomPasswordField/ControlledPasswordField/ControlledPasswordField';
import FormError from '../FormError/FormError';
import FormParagraph from 'components/Typography/FormParagraph/FormParagraph';
import Spinner from '../../Spinner/Spinner';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';

import { routes } from '../../../options/routes';

import { LoginFormContainer, ErrorContainer, HelperContainer } from './LoginForm.styles';

import { validationSchema } from './LoginForm.data';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { login } from '../../../api';

const LoginForm = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
    resolver,
  });

  const onSubmit = async (data) => {
    // Call the login endpoint and pass in the data - onSubmit will only be triggered if the form data is valid
    setLoading(true);
    const response = await login(data);
    if (!response.success) {
      setError(response.data.error_message);
      setLoading(false);
      return;
    }

    // Trigger success response
    localStorage.setItem('token', response.token);
    window.location.href = routes.home;
  };

  return (
    <LoginFormContainer>
      <CustomTitle variant="h2" text="Welcome Back" />
      <FormParagraph text="Welcome back to BuyDesign, please enter your details." variant="primary" />
      {error && (
        <ErrorContainer>
          <FormError errors={[error]} />
        </ErrorContainer>
      )}
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <CustomFieldSet>
            <ControlledTextField name="email" label="Email Address" showSuccess={!error} />
            <ControlledPasswordField name="password" label="Password" />
          </CustomFieldSet>
          <HelperContainer display="flex" flex={1} justifyContent="space-between" my={2}>
            <Box display="flex">
              <BodyText text="Don't have an account?" />
              <Box ml={1}>
                <CustomStandardLink text="Sign Up" onClick={() => navigate(routes.signup)} linkType="primary" />
              </Box>
            </Box>
            <CustomStandardLink
              text="Forgot Your Password?"
              onClick={() => navigate(routes.forgotPassword)}
              linkType="secondary"
            />
          </HelperContainer>
          <Box display="flex" justifyContent="flex-end">
            {loading && <Spinner />}
            {!loading && <CustomButton text="Log In" variant="outlined" buttonType="submit" />}
          </Box>
        </FormProvider>
      </form>
    </LoginFormContainer>
  );
};

export default LoginForm;
