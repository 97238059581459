import React from 'react';
import { useNavigate } from 'react-router';

import { Box, Link } from '@mui/material';
import BodyText from 'components/Typography/BodyText/BodyText';
import CaptionText from 'components/Typography/CaptionText/CaptionText';

import { NotificationItemStyled, NotificationUnreadCircle } from './NotificationListItem.styles';

const NotificationListItem = ({ title, link, created, read, notification_uuid, removeNotification }) => {
  const navigate = useNavigate();
  return (
    <NotificationItemStyled read={read}>
      <Link
        href={link}
        underline="none"
        onClick={async (e) => {
          e.preventDefault();
          navigate(link);
          await removeNotification(notification_uuid)
        }}
      >
        {!read && <NotificationUnreadCircle />}
        <Box>
          <Box pb={0.5}>
            <BodyText text={title} bold={!read} />
          </Box>
          <CaptionText text={created} bold={!read} />
        </Box>
      </Link>
    </NotificationItemStyled>
  );
};

NotificationListItem.propTypes = {};

export default NotificationListItem;
