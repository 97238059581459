export const NEW_BRIEF_MARKETS = [
  {
    label: 'United Kingdom',
    value: 'uk',
  },
  {
    label: 'EU',
    value: 'eu',
  },
  {
    label: 'USA',
    value: 'usa',
  },
  {
    label: 'Asia',
    value: 'asia',
  },
  {
    label: 'World',
    value: 'world',
  },
];

export const NEW_BRIEF_PROJECTED_VOLUME_OPTIONS = [
  {
    label: '0-100',
    value: '0-100',
  },
  {
    label: '100-500',
    value: '100-500',
  },
  {
    label: '1,000-5,000',
    value: '1000-5000',
  },
  {
    label: '10,000+',
    value: '10000+',
  },
];


export const FILE_REQUIREMENTS = [
  {
    label: '.PDF',
    value: '.pdf',
  },
  {
    label: '.PSD',
    value: '.psd',
  },
  {
    label: '.AI',
    value: '.ai',
  },
  {
    label: '.TIFF',
    value: '.tiff',
  },
  {
    label: '.STEP',
    value: '.step',
  },
  {
    label: '.IGES',
    value: '.iges',
  },
  {
    label: '.3DM',
    value: '.3dm',
  },
  {
    label: '.3DS',
    value: '.3DS',
    hide: true,
  },
  {
    label: '.ACIS',
    value: '.acis',
    hide: true,
  },
  {
    label: '.DWG',
    value: '.dwg',
    hide: true,
  },
  {
    label: '.DXF',
    value: '.dxf',
    hide: true,
  },
  {
    label: '.FBX',
    value: '.FBX',
    hide: true,
  },
  {
    label: '.IND',
    value: '.ind',
    hide: true,
  },
  {
    label: '.JPEG',
    value: '.jpeg',
    hide: true,
  },
  {
    label: '.OBJ',
    value: '.obj',
    hide: true,
  },
  {
    label: '.PNG',
    value: '.png',
    hide: true,
  },
  {
    label: '.PRT',
    value: '.prt',
    hide: true,
  },
  {
    label: '.SKP',
    value: '.SKP',
    hide: true,
  },
  {
    label: '.SLDASM',
    value: '.sldasm',
    hide: true,
  },
  {
    label: '.SLDPRT',
    value: '.sldprt',
    hide: true,
  },
  {
    label: '.STL',
    value: '.stl',
    hide: true,
  },
  {
    label: '.TIFF',
    value: '.tiff',
    hide: true,
  },
];
