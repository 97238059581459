import { Box, styled } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import DeleteIcon from '@mui/icons-material/Delete';

export const MessageContainer = styled(Box)(({ variant, theme }) => ({
  marginLeft: variant === 'sender' ? 'auto' : '',
  marginBottom: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  '&:last-child': {
    marginBottom: 0
  }
}));

export const MessageWrapper = styled(Box)(({ theme, variant }) => ({
  backgroundColor: variant === 'sender' ? theme.palette.background.gray : theme.palette.background.darkgray,
  borderRadius: theme.shape.borderRadius * 3,
  padding: theme.spacing(2),
  maxWidth: '260px',
}));

export const FileWrapper = styled(Box)(() => ({
  '& img': {
    width: '100%',
  },
}));

export const DownloadIcon = styled(GetAppIcon)(() => ({
  cursor: 'pointer',
  marginTop: '2px',
}));

export const AvatarWrapper = styled(Box)(({ theme, variant }) => ({
  order: variant === 'sender' ? 1 : 0,
  marginTop: '8px',
  marginLeft: variant === 'sender' ? theme.spacing(2) : '',
  marginRight: variant !== 'sender' ? theme.spacing(2) : '',
}));

export const DeleteIconStyled = styled(DeleteIcon)(({ theme }) => ({
  color: theme.palette.error.light,
  cursor: 'pointer',
}));
