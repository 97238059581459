import { addToTableFilter } from './addToTableFilter';

export const addFilters = (menuItem, filtersList, setFilter, filter, pillStatusTextMap) => {
  if (
    menuItem.id === 'checkbox' ||
    menuItem.id === 'designers' ||
    menuItem.id === 'preview' ||
    menuItem.id === 'checkbox_visual' ||
    menuItem.id === 'open_calls' ||
    menuItem.id === 'visible' ||
    menuItem.id === 'invisible' // invisible needed so border applies on Safari
  ) {
    return menuItem;
  }

  if (menuItem.id === 'location') {
    return filtersList[menuItem.id].map((location) => ({
      id: location,
      title: location,
      onClick: () => setFilter((prevState) => addToTableFilter(prevState, menuItem.id, location)),
      selected: !!filter[menuItem.id]?.includes(location),
    }));
  }

  if (menuItem.id === 'statuses' || menuItem.id === 'status') {
    return filtersList[menuItem.id].map((status) => ({
      id: status,
      title: pillStatusTextMap ? pillStatusTextMap[status] : 'missing',
      onClick: () => setFilter((prevState) => addToTableFilter(prevState, menuItem.id, status)),
      selected: !!filter[menuItem.id]?.includes(status),
    }));
  }

  return filtersList[menuItem.id].map(({ name, slug, label, value }) => ({
    id: slug || value,
    title: name || label,
    onClick: () => setFilter((prevState) => addToTableFilter(prevState, menuItem.id, slug || value)),
    selected: !!filter[menuItem.id]?.includes(slug || value),
  }));
};

export const addProductFilters = (menuItem, itemsList, setFilter, filter) => {
  return itemsList[menuItem.id]?.map(({ name, slug }) => ({
    id: slug,
    title: name,
    onClick: () => setFilter((prevState) => addToTableFilter(prevState, menuItem.id, slug)),
    selected: !!filter[menuItem.id]?.includes(slug),
  }));
};
