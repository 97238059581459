import { styled, Box } from '@mui/material';

export const StyledProposalWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    marginTop: 0,
  },
}));

export const StyledButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(1),
  },
}));

export const StyledButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));
