import { styled, Box } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  height: 48,
  paddingBottom: 1,
  paddingTop: 3,
  [theme.breakpoints.down('md')]: {
    padding: '0 12px',
    paddingTop: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0',
    paddingTop: theme.spacing(1),
  },
}));