import React from 'react';
import PropTypes from 'prop-types';
import { StyledHelpTabListContainer } from './HelpTabList.styles';
import HelpTab from 'components/HelpTab/HelpTab';
import NewConvoTab from 'components/NewConvoTab/NewConvoTab';

const HelpTabList = ({ data, handleNewConvo }) => {
  return (
    <StyledHelpTabListContainer>
      {data?.length > 0 &&
        data.map((d) => {
          return <HelpTab key={d.chat_uuid} {...d} />;
        })}
      <NewConvoTab onClick={handleNewConvo} />
    </StyledHelpTabListContainer>
  );
};

HelpTabList.propTypes = {};

export default HelpTabList;
