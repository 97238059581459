import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LICENSE_TYPES } from 'options/briefOptions';
import { Box } from '@mui/material';
import CustomFieldSet from '../CustomFieldSet/CustomFieldSet';
import NewBriefOneTimeFeeForm from '../NewBriefOneTImeFeeForm/NewBriefOneTimeFeeForm';
import NewBriefRoyaltyLicenseForm from '../NewBriefRoyaltyLicenseForm/NewBriefRoyaltyLicenseForm';
import ControlledCustomRadioGroup from 'components/Inputs/CustomRadioStandardGroup/ControlledCustomRadioGroup/ControlledCustomRadioGroup';
import { useFormContext } from 'react-hook-form';
import FormFieldSetAndInputWrapper from 'components/Wrappers/FormFieldSetAndInputWrapper/FormFieldSetAndInputWrapper';
import { LICENSE_OPTIONS } from 'options/agreementFormOptions';

const NewBriefLicensingFormsWithController = ({ variant, agreement_type, currency }) => {
  const { clearErrors, register, unregister, watch } = useFormContext();

  const agreementType = watch('agreement_type');

  useEffect(() => {
    clearErrors();
    switch (agreementType) {
      case LICENSE_TYPES.royalty:
        unregister('one_off_budget');
        register('royalty_percentage', '');
        register('royalty_review_period', '');
        break;
      case LICENSE_TYPES.one_off:
        register('one_off_budget', '');
        unregister('royalty_percentage');
        unregister('royalty_review_period');
        break;
      default:
        break;
    }
  }, [agreementType, clearErrors, register, unregister]);

  return (
    <>
      <FormFieldSetAndInputWrapper>
        <CustomFieldSet
          label="What type of agreement do you need?"
          description="Add your company's general Terms and Conditions in the next step"
        />
        <ControlledCustomRadioGroup
          name="agreement_type"
          direction="row"
          options={LICENSE_OPTIONS.map((option) => {
            if (variant === 'negotiation' && option.value !== agreement_type) {
              return { ...option, disabled: true };
            }
            return option;
          })}
        />
      </FormFieldSetAndInputWrapper>
      {agreementType === LICENSE_TYPES.royalty && <NewBriefRoyaltyLicenseForm variant={variant} />}
      {agreementType === LICENSE_TYPES.one_off && <NewBriefOneTimeFeeForm variant={variant} currency={currency} />}
    </>
  );
};

NewBriefLicensingFormsWithController.propTypes = {};

export default NewBriefLicensingFormsWithController;
