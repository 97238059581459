export const parseFormData = ({ agreement_title, agreement_body, agreement_file, ...rest }) => {
  return {
    title: agreement_title,
    body: agreement_body,
    file: agreement_file && agreement_file.length ? agreement_file[0] : null,
    ...rest,
  };
};

export const parseRemoteData = ({ title, body, file, terms_uuid }) => {
  return {
    agreement_title: title,
    agreement_body: body,
    agreement_file: file ? [file] : [],
    terms_uuid,
  };
};
