import React from 'react';

import { Box } from '@mui/material';
import BodyText from 'components/Typography/BodyText/BodyText';
import GraphSectionTitle from 'components/Typography/GraphSectionTitle/GraphSectionTitle';

import { ScoreCardWrapperStyled } from './ScoreCard.styles';

const ScoreCard = ({ title, value, gridColumn, gridRow, sx }) => {
  return (
    <ScoreCardWrapperStyled sx={sx} gridColumn={gridColumn} gridRow={gridRow}>
      <Box mb={1}>
        <GraphSectionTitle text={title} />
      </Box>
      <BodyText text={value} />
    </ScoreCardWrapperStyled>
  );
};

ScoreCard.propTypes = {};

export default ScoreCard;
