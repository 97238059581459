import React, { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import Spinner from 'components/Spinner/Spinner';

import { sendDesignerReminder } from 'api';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { validation, yupObject } from 'utils/validation';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

const validationSchema = yupObject({
  ...validation.sendReminder,
});

const ModalSendReminder = ({ open, handleClose, setMessage, brief_designer_uuid, brief_uuid }) => {
  const [isLoading, setIsLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({ defaultValues: { message: '' }, resolver });

  const handleSubmitAction = useCallback(
    async ({ message }) => {
      setIsLoading(true);
      const res = await sendDesignerReminder(brief_uuid, brief_designer_uuid, { message });
      setIsLoading(false);
      if (!res.success) {
        setMessage('There was an error sending the message.');
        return handleClose();
      }
      setMessage('You have sent a message with success');
      handleClose();
    },
    [brief_designer_uuid, brief_uuid, handleClose, setMessage]
  );

  return (
    <CustomModal title="Send a message" open={open} handleClose={handleClose}>
      <StyledModalContentWrapper>
        {isLoading ? (
          <Box mt={2}>
            <Spinner align="center" />
          </Box>
        ) : (
          <Box>
            <Box mt={1}>
              <BodyText colour="gray" text="Please send a message to the designer." />
            </Box>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmitAction)}>
                <ControlledTextField label="message" name="message" defaultValue="" />
                <StyledActionButtonsContainer>
                  <CustomButton variant="outlined" onClick={handleClose} text="Cancel" />
                  <CustomButton buttonType="submit" text="Confirm" />
                </StyledActionButtonsContainer>
              </form>
            </FormProvider>
          </Box>
        )}
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

ModalSendReminder.propTypes = {};

export default ModalSendReminder;
