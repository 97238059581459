import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';

const BriefSummaryRequirements = ({ title, text, index }) => {
  return (
    <Box mt={index === 0 ? 0 : 3}>
      <Box mb={2}>
        <CustomTitle variant="h4" text={title} />
      </Box>
      <BodyText text={text} />
    </Box>
  );
};

BriefSummaryRequirements.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  index: PropTypes.number,
};

export default BriefSummaryRequirements;
