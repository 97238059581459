import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useQuery } from 'react-query';

import { Box, Pagination } from '@mui/material';
import ProductList from 'components/ProductList/ProductList';
import BodyText from 'components/Typography/BodyText/BodyText';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';
import { LoadingContainer } from 'components/ProductsMegaMenu/ProductsMegaMenu.styles';
import Spinner from 'components/Spinner/Spinner';
import { PaginationWrapper } from 'components/ProductsAll/ProductsAll.styles';

import { useProductOptionsQueries } from 'hooks/useProductOptionsQueries';

import { userTypes } from 'options/userTypes';
import { FILTER_OPTIONS } from 'options/filterOptions';
import { PRODUCTS_PER_PAGE } from 'options/pagination';
import { getDesignersProfileProducts } from 'api';
import { PRODUCTS_MENU } from 'options/menuOptions';
import { addProductFilters } from 'utils/addFilters';

const DesignerProducts = ({ location }) => {
  const [filter, setFilter] = useState({});
  const [pagination, setPaginationOptions] = useState({ p: 1, l: PRODUCTS_PER_PAGE });
  const { p, l } = pagination;

  const parsedFilter = useMemo(() => queryString.stringify({ ...filter, p: p, l: l }), [filter, p, l]);

  const fetchProducts = useQuery(
    ['designersProfileProducts', parsedFilter],
    () => getDesignersProfileProducts(parsedFilter),
    {
      enabled: true,
      // staleTime: 5 * 60 * 1000,
      refetchOnMount: 'always',
    }
  );

  const designersProducts = useMemo(() => fetchProducts?.data?.products ?? null, [fetchProducts?.data?.products]);

  const { space, categories, materials, colour } = useProductOptionsQueries();

  useEffect(() => {
    setPaginationOptions({ p: 1, l: PRODUCTS_PER_PAGE });
  }, [filter]);

  const itemsList = useMemo(
    () => ({
      [FILTER_OPTIONS.CATEGORY]: categories ?? [],
      [FILTER_OPTIONS.SPACE]: space ?? [],
      [FILTER_OPTIONS.MATERIAL]: materials ?? [],
      [FILTER_OPTIONS.COLOUR]: colour ?? [],
    }),
    [categories, colour, materials, space]
  );

  const menuOptions = useMemo(
    () =>
      PRODUCTS_MENU.filter((menuItem) => menuItem.id !== FILTER_OPTIONS.CATEGORY).map((menuItem) => {
        return {
          ...menuItem,
          actions: addProductFilters(menuItem, itemsList, setFilter, filter),
        };
      }),
    [filter, itemsList]
  );

  const pageCount = useMemo(
    () => (fetchProducts?.data?.total ? Math.ceil(fetchProducts?.data?.total / PRODUCTS_PER_PAGE) : null),
    [fetchProducts?.data?.total]
  );

  const totalProds = useMemo(() => {
    if (fetchProducts?.isLoading) {
      return '...';
    }
    return fetchProducts?.data?.total || '0';
  }, [fetchProducts?.data?.total, fetchProducts?.isLoading]);

  const onPageChange = useCallback((_e, newPage) => {
    setPaginationOptions((prevState) => ({ ...prevState, p: newPage }));
  }, []);

  const hasFilter = !!Object.keys(filter).length;

  const resetFilter = useCallback(() => {
    setFilter({});
  }, []);
  return (
    <Box mb={6}>
      {/* <SearchAndFilterOptions title={`Products (${products.length || 0})`} menuOptions={[]} hasBorder />
       */}
      {((!fetchProducts.isLoading && !!designersProducts?.length) || hasFilter) && (
        <SearchAndFilterOptions
          title={`Products (${totalProds})`}
          hasFilter={hasFilter}
          menuOptions={menuOptions}
          hasBorder
          resetFilter={resetFilter}
        />
      )}
      {fetchProducts.isLoading && (
        <LoadingContainer>
          <Spinner align={'center'} />
        </LoadingContainer>
      )}
      {!fetchProducts.isLoading && designersProducts?.length > 0 && (
        <Box mb={4}>
          <ProductList products={designersProducts} userType={userTypes.designer} location={location} />
          {totalProds > PRODUCTS_PER_PAGE && (
            <PaginationWrapper>
              <Pagination showFirstButton showLastButton page={p} count={pageCount} onChange={onPageChange} />
            </PaginationWrapper>
          )}
        </Box>
      )}
      {!fetchProducts.isLoading && hasFilter && (!designersProducts || designersProducts.length === 0) && (
        <Box style={{ minHeight: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <BodyText text={`Sorry, none of your products match your search criteria...`} />
        </Box>
      )}
    </Box>
  );
};

DesignerProducts.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object),
};

export default DesignerProducts;
