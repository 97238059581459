import React from 'react';
import PropTypes from 'prop-types';

import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import DesignerOpenCallsList from 'components/DesignerOpenCallsList/DesignerOpenCallsList';

import { routes } from 'options/routes';

import { MOCK_BRIEF_LIST_DESIGNERS } from 'mockData/mockBrief';

const DesignerOpenCallsDashboard = (props) => {
  return (
    <DesignerDashboardWrapper activeTab={routes.openCallsDashboard}>
      <HeaderAndMenu title="Open Calls" />
      <DesignerOpenCallsList isStory={false} mockData={MOCK_BRIEF_LIST_DESIGNERS} mockTotal={10} />
    </DesignerDashboardWrapper>
  );
};

DesignerOpenCallsDashboard.propTypes = {};

export default DesignerOpenCallsDashboard;
