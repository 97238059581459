import { Box, MenuItem, styled } from '@mui/material';

export const NotificationItemStyled = styled(MenuItem)(({ theme, read }) => ({
  position: 'relative',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  paddingLeft: !read ? theme.spacing(3) : theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.common.black}`,
  '&:last-of-type': {
    borderBottom: 'none',
  },
  '&:hover': {
    backgroundColor: theme.palette.background.pink,
  },
  fontSize: 14,
}));

export const NotificationUnreadCircle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.notification.main,
  width: 10,
  height: 10,
  borderRadius: '100%',
  position: 'absolute',
  transform: 'translateY(-50%)',
  top: '50%',
  left: 7,
}));
