import { styled } from '@mui/material';
import { Menu } from '@mui/material';

export const MenuStyled = styled(Menu)(() => ({
  maxWidth: '350px',
}));

export const ActionTitleStyled = styled('span')(() => ({
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const MenuContainer = styled('div')(() => ({
  width: 24,
  display: 'flex',
  justifyContent: 'center',
}));
