import { useNavigate } from 'react-router';

import { Box } from '@mui/material';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';

//TODO: Update link for navigation
const ModalAcceptProposal = ({ open, handleClose, navigateAction }) => {
  return (
    <CustomModal title="Proposal" open={open} handleClose={handleClose}>
      <Box>
        <Box mt={2}>
          <CustomTitle variant="h3" text="Congratulations!" />
        </Box>
        <Box mt={1} mb={2}>
          <BodyText
            text="You have connected with the designer. Please review, sign and return the T&C's and the Agreement"
            colour="gray"
          />
        </Box>
        <CustomButton text="View T&C's agreement" fullWidth onClick={() => navigateAction()} />
      </Box>
    </CustomModal>
  );
};

export default ModalAcceptProposal;
