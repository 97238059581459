import PropTypes from 'prop-types';
import { StyledNavTab } from './NavTab.styles';

const NavTab = ({ label, onClick, active }) => {
  return <StyledNavTab label={label} onClick={onClick} active={active} />;
};

NavTab.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
};

export default NavTab;
