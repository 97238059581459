import { validation, yupObject } from 'utils/validation';

const firstStepValidation = yupObject({
  product_uuid: validation.pitchConversion.product_uuid,
});

const secondStepValidation = yupObject({
  buyers: validation.pitchConversion.buyers,
});

export const formValidation = [firstStepValidation, secondStepValidation];

export const formDefaultValues = {
  product_uuid: [],
  buyers: [],
};

export const steps = ['Select Product', 'Select Company'];

export const stepsWithProduct = ['Select Company'];
