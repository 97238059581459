import { useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import Spinner from 'components/Spinner/Spinner';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import DesignerDashboardWrapper from 'components/Wrappers/DashboardWrapper/DesignerDashboardWrapper/DesignerDashboardWrapper';
import PitchBuyerList from 'components/PitchBuyerList/PitchBuyerList';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import EmptyListCreateNew from 'components/EmptyListCreateNew/EmptyListCreateNew';
import ModalFindBuyer from 'components/ModalFindBuyer/ModalFindBuyer';

import { routes } from 'options/routes';

import { parseRemoteProductProposalData } from 'utils/parseProductProposal';
import { getErrorMessage } from 'utils/getErrorMessage';
import { parsePitchToConvert } from '../DesignerPitchesSingle/DesignerPitchesSingle.utils';

import { useMessageContext } from 'context/MessageContext';

import { getDesignerPitchBuyersList, getDesignerSinglePitch, resendDesignerSinglePitch, retractSinglePitch } from 'api';
import ModalRetractPitch from 'components/ModalRetractPitch/ModalRetractPitch';

export const DesignerPitchesBuyersList = () => {
  const [isFetching, setIsFetching] = useState(false);
  const { pitch_uuid } = useParams();
  const [setMessage] = useMessageContext();
  const navigate = useNavigate();
  const [openFindBuyers, setOpenFindBuyers] = useState('');
  const [openRetractPitch, setOpenRetractPitch] = useState(false);

  const { data, isLoading, refetch } = useQuery(
    ['pitchBuyerList', pitch_uuid],
    () => getDesignerPitchBuyersList(pitch_uuid),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const methods = useForm({
    defaultValues: {
      buyers: [],
    },
  });

  const pitchBuyersList = data?.product_buyers || [];
  const pitchName = data?.product_name;
  const can_convert = data?.can_convert;
  const can_retract = data?.can_retract;

  const handleCovertToMarketPlace = useCallback(async () => {
    if (!isFetching) {
      setIsFetching(true);
      const res = await getDesignerSinglePitch(pitch_uuid);
      if (!res.success) {
        setIsFetching(false);
        return setMessage(getErrorMessage(res));
      }
      const parsedValues = parseRemoteProductProposalData(res.pitch);
      navigate(`${routes.productsAdd}`, { state: { proposal: parsePitchToConvert(parsedValues) } });
    }
  }, [isFetching, navigate, pitch_uuid]);

  const handleSendToAnotherBuyer = useCallback(async () => {
    try {
      const { buyers } = methods.getValues();

      const res = await resendDesignerSinglePitch(pitch_uuid, { company_uuid: buyers[0]?.company_uuid });
      if (!res.success) {
        return setMessage(getErrorMessage(res));
      }
      setMessage('You have resent pitch to another buyer with success');
    } catch (err) {
      console.log(err);
    }
  }, [methods, pitch_uuid, setMessage]);

  const handleOnRetractPitch = useCallback(async () => {
    try {
      const res = await retractSinglePitch(pitch_uuid);
      if (!res.success) {
        return setMessage(getErrorMessage(res));
      }
      setMessage('You have successfully retracted this pitch');
    } catch (err) {
      setMessage('There was an error retracting pitch');
    }
  }, [pitch_uuid, setMessage]);

  return (
    <DesignerDashboardWrapper
      activeTab={routes.pitchesDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Pitches', onClick: () => navigate(`${routes.pitchesDashboard}`) }]}
          currentPage={pitchName}
        />
      }
    >
      <HeaderAndMenu
        title={pitchName}
        menuOptions={[
          ...(can_convert
            ? [
                {
                  buttonTitle: 'Upload to Marketplace',
                  onClick: handleCovertToMarketPlace,
                  icon: isFetching ? <Spinner size="12px" /> : <AddIcon />,
                },
                {
                  buttonTitle: 'Send to another buyer',
                  onClick: () => setOpenFindBuyers(true),
                  icon: <SendIcon />,
                },
              ]
            : []),
          ...(can_retract
            ? [
                {
                  buttonTitle: 'Retract Pitch',
                  onClick: () => setOpenRetractPitch(true),
                  icon: <CloseIcon />,
                },
              ]
            : []),
        ]}
      />
      {!isLoading && !pitchBuyersList.length && <EmptyListCreateNew title="There are no buyers for this pitch yet." />}
      {isLoading ? (
        <Box my={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <PitchBuyerList pitchBuyersList={pitchBuyersList} pitch_uuid={pitch_uuid} refetch={refetch} />
      )}
      <FormProvider {...methods}>
        {openFindBuyers && (
          <ModalFindBuyer
            onHandleAccept={handleSendToAnotherBuyer}
            variant="pitch"
            open={openFindBuyers}
            handleClose={() => setOpenFindBuyers(false)}
            product_uuid={pitch_uuid}
          />
        )}
      </FormProvider>
      {!!openRetractPitch && (
        <ModalRetractPitch
          onSubmit={handleOnRetractPitch}
          variant="pitch"
          open={openRetractPitch}
          handleClose={() => setOpenRetractPitch(false)}
          callback={() => refetch()}
        />
      )}
    </DesignerDashboardWrapper>
  );
};

export default DesignerPitchesBuyersList;
