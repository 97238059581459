import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import CustomTitle from '../Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { ImageButtonsStyled, ContentWrapper, DeleteWrapper, HiddenWrapper, OverlayStyled } from './MoodboardProductImageOverlay.styles';
import { TopWrapper, ImageOverlayStyled, ThreeDotWrapper } from '../ImageOverlay/ImageOverlay.styles';

import theme from '../../theme/theme';

const MoodboardProductImageOverlay = ({ isOwner, isHidden, show, handleOnDeleteClick, handleOnHiddenClick, name, designer_name }) => {
  let hiddenIcon = null;
  if (isOwner) hiddenIcon = isHidden ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />;
  return (
    <OverlayStyled show={show}>
      {/*<ContentWrapper>
        <DeleteWrapper onClick={handleOnDeleteClick}>
          <DeleteOutlineIcon />
        </DeleteWrapper>
        {hiddenIcon && <HiddenWrapper onClick={handleOnHiddenClick}>{hiddenIcon}</HiddenWrapper>}
      </ContentWrapper>*/}
      <TopWrapper>
        <DeleteWrapper onClick={handleOnDeleteClick} mr={1} mt={2} p={1}>
          <DeleteOutlineIcon />
        </DeleteWrapper>
        {hiddenIcon && <HiddenWrapper onClick={handleOnHiddenClick} mr={2} mt={2} p={1}>{hiddenIcon}</HiddenWrapper>}
      </TopWrapper>
      <ImageButtonsStyled>
        <Box mb={1}>
          <CustomTitle variant="h4" text={name} />
          <BodyText text={`By ${designer_name}`} colour={theme.palette.text.secondary} />
        </Box>
      </ImageButtonsStyled>
    </OverlayStyled>
  );
};

MoodboardProductImageOverlay.propTypes = {
  isOwner: PropTypes.bool,
  isHidden: PropTypes.bool,
  product_uuid: PropTypes.string,
  show: PropTypes.bool,
};

export default MoodboardProductImageOverlay;
