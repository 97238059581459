import { styled, Box } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  zIndex: 1000000,
  backgroundColor: 'white',
  minHeight: '48px',
  paddingBottom: theme.spacing(0.5),
  [theme.breakpoints.down('md')]: {
    padding: '0 12px',
    paddingTop: theme.spacing(1),
  },
  [theme.breakpoints.down('sm')]: {
    padding: '0',
    paddingTop: theme.spacing(1),
  },
}));

export const StickyContainer = styled(Box)(({ theme }) => ({
  zIndex: 1000,
  backgroundColor: 'white',
}));

export const ButtonsContainer = styled(Box)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignSelf: 'flex-end',
  justifyContent: 'flex-end',
}));
