import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueries, useQuery } from 'react-query';

import { CloseButtonWrapper, LoadingContainer, StyledProductsMegaMenuContainer } from './ProductsMegaMenu.styles';
import Spinner from 'components/Spinner/Spinner';
import MegaMenuList from 'components/MegaMenuList/MegaMenuList';

import { OPTIONS_TYPE } from 'options/selectOptions';

import { getChildOptions, getTopOptions } from 'api';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';
import CloseIcon from '@mui/icons-material/Close';
import { NavBarBuyerContext } from 'components/NavBar/NavBarBuyer/NavBarBuyer';

const ProductsMegaMenu = ({ showMegaMenu, isStory, mockData, mockError = null }) => {
  const [loading, setLoading] = useState(!isStory);
  const [categoryData, setCategoryData] = useState(isStory ? mockData : null);

  const NavContext = useContext(NavBarBuyerContext);

  const { data } = useQuery(['main_categories'], () => getTopOptions(OPTIONS_TYPE.product), {
    enabled: !isStory && showMegaMenu,
    staleTime: 5 * 60 * 1000,
  });
  const options = data?.options;

  const optionQueries = useQueries({
    queries:
      options?.map((option, i) => {
        return {
          queryKey: [`${option.slug}-childQuery`, i],
          queryFn: () => getChildOptions(OPTIONS_TYPE.product, option.slug),
          enabled: !!options,
          staleTime: 5 * 60 * 1000,
        };
      }) ?? [],
  });

  useEffect(() => {
    if (isStory || !options || !optionQueries || !!categoryData) return;
    const allChildOptionsSuccess = optionQueries.every((o) => o?.data?.success);
    if (!allChildOptionsSuccess) return;
    else {
      const allCategoryData = options.map((option, index) => {
        return {
          title: {
            ...option,
          },
          options: optionQueries[index].data.options,
        };
      });
      setCategoryData(allCategoryData);
      setLoading(false);
    }
  }, [isStory, options, optionQueries, categoryData]);

  return (
    <StyledProductsMegaMenuContainer>
      {loading && (
        <LoadingContainer>
          <Spinner align="center" />
        </LoadingContainer>
      )}
      {!loading &&
        categoryData?.length > 0 &&
        categoryData.map((category) => {
          return (
            <MegaMenuList
              key={`megaMenuList-${category.title.slug}`}
              title={category.title}
              options={category.options}
              handleNavigation={NavContext.handleProductsMegaMenuNavigation}
            />
          );
        })}
      {!loading && (
        <CloseButtonWrapper>
          <CustomIconButton onClick={NavContext.handleMegaMenuClick} icon={<CloseIcon />} />
        </CloseButtonWrapper>
      )}
    </StyledProductsMegaMenuContainer>
  );
};

ProductsMegaMenu.propTypes = {};

export default ProductsMegaMenu;
