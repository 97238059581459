import { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CustomTableBody from 'components/Table/CustomTableBody/CustomTableBody';
import CustomTableHeader from 'components/Table/CustomTableHeader/CustomTableHeader';
import CustomTableRow from 'components/Table/CustomTableRow/CustomTableRow';
import CustomTableBodyCell from 'components/Table/CustomTableBodyCell/CustomTableBodyCell';
import ItemInfo from 'components/ItemInfo/ItemInfo';
import PillTag from 'components/PillTag/PillTag';
import TableBodyText from 'components/Typography/TableBodyText/TableBodyText';
import ThreeDotMenu from 'components/ThreeDotMenu/ThreeDotMenu';
import Gravatar from 'components/Gravatar/Gravatar';
import StatusPill from 'components/StatusPill/StatusPill';
import CustomTablePagination from 'components/Table/CustomTablePagination/CustomTablePagination';
import CustomTableWrapper from 'components/CustomTableWrapper/CustomTableWrapper';
import Spinner from 'components/Spinner/Spinner';
import NoFilteringMatch from 'components/Table/NoFilteringMatch/NoFilteringMatch';
import PreviewBriefModal from 'components/PreviewBriefModal/PreviewBriefModal';
import { PILL_STATUS_ICON, PILL_STATUS_MAP, BUYER_BRIEF_PILL_STATUS_TEXT } from 'options/pillStatusOptions';
import { BUYER_BRIEFS_LIST_HEADINGS } from 'options/tableOptions';
import { BRIEF_STATUS_BUYER, BRIEF_STATUS_BUYER_OPTIONS } from 'options/briefStatus';

import { getUniqueList } from 'utils/getUniqueList';
import { addFilters } from 'utils/addFilters';

import { PillsWrapperStyled } from 'components/Table/CustomTableRow/CustomTableRow.styles';
import { ProductDetailsWrapper } from 'components/DesignerProductList/DesignerProductList.styles';
import { TableStyled, VisibilityIconWrapper } from 'components/Table/Table.styles';
import { useQuery } from 'react-query';
import { OPTIONS_TYPE } from 'options/selectOptions';
import { getChildOptions } from 'api';

const BuyersBriefList = ({
  buyersBriefList,
  loadingStatus,
  totalCount,
  tableMethods,
  notFound,
  onNotFoundClick,
  onEdit,
  onView,
  onDelete,
  onCancelBrief,
  onDesignerClick,
}) => {
  const { order, orderBy, filter, setFilter, p, l, onPageChange, onRequestSort, onRowsPerPageChange } = tableMethods;
  const hasFilter = Object.keys(filter).length;
  const [previewBriefUUID, setPreviewBriefUUID] = useState();
  const {
    data: { options: categories },
  } = useQuery(['options', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    enabled: true,
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const filtersList = useMemo(
    () => ({
      categories: categories,
      statuses: [...Object.values(BRIEF_STATUS_BUYER_OPTIONS)],
    }),
    [categories]
  );

  const headingsOnClick = useMemo(
    () =>
      BUYER_BRIEFS_LIST_HEADINGS.map((menuItem) =>
        !menuItem.sortable
          ? {
              ...menuItem,
              menuOptions: addFilters(menuItem, filtersList, setFilter, filter, BUYER_BRIEF_PILL_STATUS_TEXT),
            }
          : menuItem
      ),
    [filter, filtersList, setFilter]
  );

  const handleViewNavigation = (brief_uuid, isDraft) => {
    if (isDraft) {
      onEdit(brief_uuid);
      return;
    }
    onView(brief_uuid);
    return;
  };

  const onPreviewClick = useCallback(async (event, brief_uuid) => {
    event.stopPropagation();
    setPreviewBriefUUID(brief_uuid);
  }, []);

  const showNotFoundMessage = notFound && !hasFilter;

  return (
    <Box>
      {loadingStatus ? (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      ) : (
        <CustomTableWrapper
          noResultsTitle={showNotFoundMessage ? 'No briefs found' : ''}
          noResultsDescription={showNotFoundMessage ? "You haven't added any briefs yet. Let's get started!" : ''}
          btnText={showNotFoundMessage ? 'Add Brief' : ''}
          onBtnClick={showNotFoundMessage ? onNotFoundClick : () => {}}
          icon={<CreateIcon />}
        >
          <TableStyled>
            <CustomTableHeader
              columnHeadings={headingsOnClick}
              onRequestSort={onRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <CustomTableBody>
              {!buyersBriefList.length && <NoFilteringMatch item="briefs" cols={BUYER_BRIEFS_LIST_HEADINGS.length} />}
              {buyersBriefList?.map((brief, index) => {
                const {
                  image,
                  name,
                  title,
                  categories,
                  designers,
                  additional_designers,
                  date_sent,
                  deadline,
                  status,
                  brief_uuid,
                  can_cancel,
                } = brief;

                const isDraft = status === BRIEF_STATUS_BUYER.DRAFT;
                const isActive = status === BRIEF_STATUS_BUYER.ACTIVE;
                const isCancelled = status === BRIEF_STATUS_BUYER.CANCELLED;

                const threeDotMenuActions = [
                  (isDraft || isActive) && { title: 'Edit', onClick: () => onEdit(brief_uuid) },
                  isDraft && !isCancelled && { title: 'Delete', onClick: () => onDelete(brief_uuid) },
                  !isDraft && !isCancelled && { title: 'View', onClick: () => onView(brief_uuid) },
                  can_cancel && !isCancelled && { title: 'Cancel Brief', onClick: () => onCancelBrief(brief_uuid) },
                ].filter(Boolean);

                return (
                  <CustomTableRow
                    key={`table_${name}_${index}`}
                    onClick={!isCancelled && (() => handleViewNavigation(brief_uuid, isDraft))}
                  >
                    <CustomTableBodyCell>
                      <ProductDetailsWrapper>
                        <ItemInfo avatar={image || {}} label={title} />
                      </ProductDetailsWrapper>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <PillsWrapperStyled>
                        {getUniqueList(categories, 'slug')?.map((category, i) => {
                          const { name, slug } = category;
                          return (
                            <Box key={`${category}_${i}`}>
                              <PillTag option={{ label: name, value: slug }} />
                            </Box>
                          );
                        })}
                      </PillsWrapperStyled>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <Gravatar
                          data={designers.map(({ portrait: { fileSrc, fileType }, user_uuid }) => ({
                            fileSrc,
                            fileType,
                            uuid: user_uuid,
                          }))}
                          onClick={onDesignerClick}
                          additional_designers={additional_designers}
                        />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <TableBodyText text={date_sent ? date_sent : '--'} />
                      </Box>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <TableBodyText text={deadline ? deadline : '--'} />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <StatusPill
                        label={BUYER_BRIEF_PILL_STATUS_TEXT[status]}
                        status={PILL_STATUS_MAP[status]}
                        icon={PILL_STATUS_ICON[status]}
                      />
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <VisibilityIconWrapper>
                        <VisibilityIcon onClick={(event) => onPreviewClick(event, brief_uuid)} />
                      </VisibilityIconWrapper>
                    </CustomTableBodyCell>
                    <CustomTableBodyCell>
                      <Box>
                        <ThreeDotMenu actions={threeDotMenuActions} variant="vertical" />
                      </Box>
                    </CustomTableBodyCell>
                  </CustomTableRow>
                );
              })}
            </CustomTableBody>
          </TableStyled>
          {!!buyersBriefList.length && (
            <CustomTablePagination
              count={totalCount}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              rowsPerPage={l}
              rowsPerPageOptions={[5, 10, 15]}
              page={p}
            />
          )}
        </CustomTableWrapper>
      )}
      {!!previewBriefUUID && (
        <PreviewBriefModal
          open={!!previewBriefUUID}
          handleClose={() => setPreviewBriefUUID('')}
          brief_uuid={previewBriefUUID}
          isStory={false}
          wide
          showTerms
        />
      )}
    </Box>
  );
};

BuyersBriefList.propTypes = {
  isStory: PropTypes.bool,
  mockData: PropTypes.array,
  mockTotal: PropTypes.number,
};

export default BuyersBriefList;
