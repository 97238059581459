import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router';

import CreateIcon from '@mui/icons-material/Create';
import BuyerDashboardWrapper from 'components/Wrappers/DashboardWrapper/BuyerDashboardWrapper/BuyerDashboardWrapper';
import HeaderAndMenu from 'components/HeaderAndMenu/HeaderAndMenu';
import BuyersBriefList from 'components/BuyersBriefList/BuyersBriefList';
import ModalDeleteItem from 'components/ModalDeleteItem/ModalDeleteItem';
import ModalCancelItem from 'components/ModalCancelItem/ModalCancelItem';

import { routes } from 'options/routes';

import { getErrorMessage } from 'utils/getErrorMessage';

import { MOCK_BRIEF_LIST_BUYERS } from 'mockData/mockBrief';

import { useTableFilter } from 'hooks/useTableFilter';

import { useMessageContext } from 'context/MessageContext';

import { cancelBuyerBrief, deleteBuyersBrief, getBuyersBriefList } from 'api';

export const BuyerBriefsDashboard = ({ isStory }) => {
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [selectedBriefToCancel, setSelectedBriefToCancel] = useState('');
  const [setMessage] = useMessageContext();
  const [selectedDeleteBrief, setSelectedDeleteBrief] = useState('');
  const { parsedFilter, ...tableMethods } = useTableFilter({ initialOrder: 'desc', initialOrderBy: 'date_sent' });

  const navigate = useNavigate();

  const { data, isLoading, refetch, isRefetching } = useQuery(
    ['buyerBriefList', parsedFilter],
    () => getBuyersBriefList(parsedFilter),
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const can_create = data?.can_create;

  const buyersBriefList = useMemo(
    () => (isStory ? MOCK_BRIEF_LIST_BUYERS : data?.briefs || []),
    [data?.briefs, isStory]
  );

  const totalCount = useMemo(
    () => (isStory ? MOCK_BRIEF_LIST_BUYERS.length : data?.total || 0),
    [data?.total, isStory]
  );

  const onEdit = useCallback(
    (brief_uuid) => {
      navigate(`${routes.briefsAdd}/${brief_uuid}`);
    },
    [navigate]
  );

  const deleteBrief = useCallback(async () => {
    setIsDeleteLoading(true);
    const res = await deleteBuyersBrief(selectedDeleteBrief);
    setIsDeleteLoading(false);
    if (!res.success) {
      return setMessage('There was an error deleting brief.');
    }
    setMessage('Successfully deleted brief.');
    refetch();
  }, [refetch, selectedDeleteBrief, setMessage]);

  const onDelete = useCallback((brief_uuid) => {
    setSelectedDeleteBrief(brief_uuid);
  }, []);

  const onView = useCallback(
    (brief_uuid) => {
      navigate(`${routes.briefsDashboard}/${brief_uuid}`);
    },
    [navigate]
  );

  const onDesignerClick = useCallback(
    (uuid) => {
      navigate(`${routes.designersIndex}/${uuid}`);
    },
    [navigate]
  );

  const handleCancelBrief = useCallback(async () => {
    try {
      const res = await cancelBuyerBrief(selectedBriefToCancel);

      if (!res.success) {
        throw new Error(getErrorMessage(res));
      }
      setMessage('You have successfully cancelled this brief.');
      refetch();
    } catch (err) {
      setMessage(err.toString());
    }
  }, [refetch, selectedBriefToCancel, setMessage]);

  const onCancelBrief = useCallback((brief_uuid) => {
    setSelectedBriefToCancel(brief_uuid);
  }, []);

  const loadingStatus = isLoading || isDeleteLoading || isRefetching;
  const isLoadingBriefs = isLoading || isRefetching;
  const notFound = !isLoadingBriefs && !buyersBriefList?.length;
  const onNotFoundClick = notFound ? () => navigate(routes.briefsAdd) : null;

  return (
    <BuyerDashboardWrapper activeTab={routes.briefsDashboard}>
      <HeaderAndMenu
        title="Briefs"
        menuOptions={
          can_create
            ? [{ buttonTitle: 'New Brief', icon: <CreateIcon />, onClick: () => navigate(routes.briefsAdd) }]
            : []
        }
      />
      <BuyersBriefList
        buyersBriefList={buyersBriefList}
        totalCount={totalCount}
        loadingStatus={loadingStatus}
        notFound={notFound}
        onNotFoundClick={onNotFoundClick}
        tableMethods={tableMethods}
        onView={onView}
        onEdit={onEdit}
        onDelete={onDelete}
        onCancelBrief={onCancelBrief}
        onDesignerClick={onDesignerClick}
      />
      {!!selectedDeleteBrief && (
        <ModalDeleteItem
          open={!!selectedDeleteBrief}
          handleClose={() => setSelectedDeleteBrief('')}
          onSubmit={() => deleteBrief()}
          callback={() => refetch()}
          variant="brief"
        />
      )}
      {!!selectedBriefToCancel && (
        <ModalCancelItem
          open={!!selectedBriefToCancel}
          handleClose={() => setSelectedBriefToCancel('')}
          handleSubmit={handleCancelBrief}
          title="Cancel Brief"
          description="Cancelling this Brief will cancel it for all designers who haven’t agreed terms yet. This action cannot be undone."
        />
      )}
    </BuyerDashboardWrapper>
  );
};

export default BuyerBriefsDashboard;
