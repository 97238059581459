import { Box } from '@mui/material';
import StatusPill from 'components/StatusPill/StatusPill';
import CaptionText from 'components/Typography/CaptionText/CaptionText';

import { ChatPillContainer } from './ChatPill.styles';

import { CHAT_PILL_ICONS, CHAT_PILL_STATUSES } from 'options/pillStatusOptions';

const getStatus = (body) => {
  if (body.match(/accepted|approved|completed|signed/gi)) {
    return 'accepted';
  }
  if (body.match(/submitted|sent/gi)) {
    return 'submitted';
  }
  if (body.match(/deleted|declined|rejected/gi)) {
    return 'declined';
  }
};

const ChatPill = ({ variant, body = '', created }) => {
  const status = getStatus(body);
  const pillStatus = CHAT_PILL_STATUSES[status];
  const icon = CHAT_PILL_ICONS[status];
  return (
    <ChatPillContainer variant={variant}>
      <StatusPill label={body} status={pillStatus} icon={icon} />
      <Box ml={2}>
        <CaptionText text={created} />
      </Box>
    </ChatPillContainer>
  );
};

export default ChatPill;
