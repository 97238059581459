import { styled, Box } from '@mui/material';

export const ImageOverlayStyled = styled(Box)(({ theme, show }) => ({
  backgroundColor: theme.palette.background.pinkOpaque,
  height: '100%',
  width: '100%',
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 0,
  left: 0,
  transition: 'opacity 300ms ease-in-out',
  opacity: show ? 1 : 0,
  boxSizing: 'border-box',
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer'
}));

export const TopWrapper = styled(Box)(({ theme }) => ({
  padding: `0 0 ${theme.spacing(1)} 0`,
  display: 'flex',
  justifyContent: 'flex-end',
}));

export const ImageButtonsStyled = styled(Box)(() => ({
  marginTop: 'auto',
}));

export const ThreeDotWrapper = styled(Box)(() => ({
  height: 30,
  width: 30,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'center',
  '& .MuiButtonBase-root': {
    padding: 0,
  },
}));
