import React from 'react';

import HelpDashboard from 'components/HelpDashboard/HelpDashboard';
import PageWrapper from 'components/Wrappers/PageWrapper/PageWrapper';
import PageContentWrapper from 'components/PageContentWrapper/PageContentWrapper';

const HelpDashboardLanding = ({ userType }) => {
  return (
    <PageWrapper userType={userType} showSecondaryFooter={false}>
      <PageContentWrapper>
        <HelpDashboard />
      </PageContentWrapper>
    </PageWrapper>
  );
};

HelpDashboardLanding.propTypes = {};

export default HelpDashboardLanding;
