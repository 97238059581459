import { styled } from '@mui/system';

export const SignUpUserOptionContainer = styled('div')(({ theme, colour }) => ({
  '& h6, & h1, & p, & button': {
    marginBottom: theme.spacing(3),
  },
  '& h6, & h1, & > *:nth-child(3)': {
    color: colour === 'white' ? theme.palette.common.white : '',
  },
}));

export const DividerContainer = styled('div')(({ theme }) => ({
  marginBottom: 30,
  [theme.breakpoints.down('md')]: {
    marginBottom: 10,
  },
}));
