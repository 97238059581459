import PropTypes from 'prop-types';

import CustomModal from 'components/CustomModal/CustomModal';
import BuyerTermsForm from 'components/BuyerTermsForm/BuyerTermsForm';

const ModalBuyerTermsForm = ({ open, handleClose, onSubmit, agreement_type, currency, price }) => {
  return (
    <CustomModal title="Terms and Conditions" open={open} handleClose={handleClose} variant="secondary">
      <BuyerTermsForm
        onSubmit={onSubmit}
        onCancel={handleClose}
        agreement_type={agreement_type}
        currency={currency}
        price={price}
      />
    </CustomModal>
  );
};

ModalBuyerTermsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  agreement_type: PropTypes.string,
};

export default ModalBuyerTermsForm;
