import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import CustomStandardLink from 'components/CustomStandardLink/CustomStandardLink';

const FooterLinksColumn = ({ options }) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" gap="3px">
      <Box mb={1}>
        <Typography variant="bodyWhiteBold" style={{ fontSize: 14 }}>
          {options.title}
        </Typography>
      </Box>
      {options.links.map((link) => (
        <CustomStandardLink
          text={link.linkText}
          onClick={() => {
            if (link.external) {
              window.location.href = link.linkPath;
              return;
            }
            navigate(link.linkPath);
          }}
          colour="white"
          linkType="secondary"
          fontSize="medium"
          key={link.linkText}
          {... link.external ? {href: link.linkPath} : {}}
        />
      ))}
    </Box>
  );
};

export default FooterLinksColumn;
