import { styled, Avatar } from '@mui/material';

export const CustomAvatarStyled = styled(Avatar)(({ name }) => ({
  marginRight: name ? 10 : null,
  width: 32,
  height: 32,
}));

export const CustomAvatarWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
