import React from 'react';
import PropTypes from 'prop-types';
import { FieldDescriptionStyled } from './FieldDescription.styles';

const FieldDescription = ({ text, inline }) => {
  return <FieldDescriptionStyled variant="body2"  inline={inline}>{text}</FieldDescriptionStyled>;
};

FieldDescription.propTypes = {
  text: PropTypes.string,
};

export default FieldDescription;
