const BuyerTerms = `
**These terms and conditions govern your use of the BuyDesign Platform, and your purchase and
licensing of product designs through it. By accepting these terms and conditions, you agree to enter a contract with BuyDesign Management Ltd of Flat 2, 9 Palace Gate, London, England, W8 5LS, (“BuyDesign) as set out below.**

## 1. DEFINITIONS

“ **Design** ” means a design for a product, logo, artwork, or pattern created by a Designer and submitted to us for inclusion on the Platform.

“ **Designer** ” means a person who has submitted Designs for inclusion on the Platform.

**"Intellectual Property Rights"** means all copyright, patents, database rights, registered and unregistered design rights, topography rights, trademarks and service marks and applications for any of the foregoing, together with all trade secrets, know-how, rights in confidential information and other intellectual and industrial property rights in all parts of the world.

**“Platform”** means the website operated and made available by BuyDesign through which Designs can
be offered for sale by Designers and purchased or licensed by Purchasers.

“ **Purchaser** ” means you.

We do not guarantee that your request to create an account with BuyDesign will be approved. We
review all applications carefully, and we may reject or approve any request at our sole discretion.

## 2. ACCESSING DESIGNS THROUGH THE PLATFORM

2.1. We will provide you with access to the Platform, through which you can browse a curated
selection of Designs from Designers with whom BuyDesign has chosen to collaborate, or brief
certain Designers to propose potential Designs to you.
2.2. If you publish a brief or open call on the Platform for a specific project, you undertake that such
brief or open call will be exclusive to BuyDesign, and that you will not seek to have that brief
fulfilled through any other channel.
2.3. We reserve the right to remove a Design from the Platform at any time at our sole discretion,
for reasons including sale of the Design to a third party, or notification of a claim of
infringement of third-party Intellectual Property Rights.
2.4. Certain Designs available on the Platform may also benefit from registered design protection.
We will endeavour to provide notification of that wherever applicable.
2.5. You acknowledge and agree that these terms and conditions only govern your use of the
Platform. Any agreement with a Designer regarding purchase or use of any of their Designs
shall be subject to a separate agreement between you and the Designer. We shall provide
functionality for you to upload to the Platform your preferred forms of agreement for your use
of Designs. You remain solely responsible for any such agreement(s) and acknowledge that
BuyDesign shall have no liability for any act or omission in relation to your agreement with any
Designer.
2.6. Any agreement you enter into with a Designer shall include a provision for any payments owed
to the Designer to be made through BuyDesign acting as their agent. You acknowledge and
agree that any agreement you enter into with a Designer in relation to any Design must be
concluded through the Platform, and that we shall be permitted to retain a copy for our
records. You accept that BuyDesign shall have no liability in relation to any issues arising with
the validity of the Design or use of the Design being subject to third party Intellectual Property
Rights. You agree not to involve BuyDesign in any disputes that may arise between you and any
Designer to whom you are introduced through the Platform.
2.7. In respect of any Designs which are purchased by you through the Platform on a one-off
payment basis, you shall pay BuyDesign a commission equivalent to of 15% of the one-off
amount payable to the Designer. You agree that you shall pay the commission to us at the
same time as the one-off amount payable to the Designer, and we shall remit the one-off
amount to the Designer.
2.8. In respect of any Designs which are licensed to you from a Designer through the Platform on an
ongoing royalty basis, we shall take payment from the Designer for our commission. You shall
provide us with a report on a periodic basis (being calendar quarterly unless otherwise agreed
between you and the Designer in the relevant contract) of royalties payable for the preceding
royalty period for any Designs you license from a Designer. We will invoice you the royalty
payable, and such invoice must be paid within 30 days. Upon receipt of full cleared payment
from you, we will notify the Designer of such receipt, and then pay the Designer the applicable
royalty (less our commission) within 30 days of receipt of their invoice.
2.9. If you brief or commission a Designer to create new designs or any variants of a Design or any
new design which have a substantially similar design aesthetic to, or could reasonably form part
of the same collection as, a Design, you agree to do so on the Platform, including the entering
into of any purchase or license agreement.
2.10. We may require payment for access to the Platform based on the number of users or
level of functionality. You agree that your subscription will automatically renew each month or
year (as applicable). You will be charged monthly or annually in advance to the same payment
method previously provided to us. If you wish to cancel or downgrade your subscription, you
can do so at any time by contacting us and we will endeavour to make the requested changes
as soon as possible. You must notify us in writing and/or update your account at least 2
business days prior to the next billing day. If you cancel your subscription less than 2 business
days prior to the next billing day, you will be charged the fee on the next billing day and your
cancellation of the subscription will be effective for the following month or year (as applicable).
2.11. All amounts payable to us are exclusive of VAT and other applicable taxes unless
otherwise specified, which you are required to pay in addition. We reserve the right to amend
our prices and amend or withdraw any special offers at any time without notice. All sums
received by BuyDesign for use of the Platform and by way of commission are non-cancellable
and non-refundable in any circumstances.

## 3. CONTACT WITH DESIGNERS

3.1. You acknowledge and agree that, without the prior written consent of BuyDesign, all
commercial transactions in relation to (a) the sale or license of any Designs that have been
uploaded to the Platform or (b) in relation to a request for product design services published on
the Platform by you or (c) any other commissioning of product design work by you from a
Designer that you have been introduced to through the Platform, must be conducted through
the Platform.
3.2. If a Designer contacts you directly as a result of a connection through the Platform to engage
you with product design work other than in relation to Designs that they have uploaded to the
Platform or product design briefs or open calls that you created on the Platform, you shall
ensure that such engagement is exclusively conducted through the Platform.
3.3. If you act in breach of this section 3, you acknowledge that BuyDesign may suffer financial and
other losses, and you hereby indemnify BuyDesign in relation to any such losses. We reserve
the right to immediately terminate your BuyDesign account in these circumstances
3.4. This section 3 shall survive termination or expiry of these terms and conditions.
3.5. This section 3 shall not apply to relationships between you and any Designer with whom you
have a relationship that pre-dates your relationship with us and which can be demonstrated by
way of documented evidence of such prior collaboration.

## 4. INTELLECTUAL PROPERTY RIGHTS

4.1. These terms and conditions do not constitute an assignment or license of any Intellectual
Property Rights in any Design.
4.2. You acknowledge that BuyDesign acts as an agent for the sale and purchase of Designs, and
BuyDesign does not own or license to you any Intellectual Property Rights in any Design at any
time. You are solely responsible for agreeing the terms of any purchase or license of any
Design with the Designer. Any and all claims arising from the ownership and use of any Design
subsequent to the purchase of that Product Design shall be directed solely to the relevant
Designer.
4.3. You acknowledge and agree that we may, at our sole discretion, share information regarding
the browsing activity of your users of the Platform with other users in relation to protection
and enforcement of Intellectual Property Rights and any contract entered into through the
Platform. This clause 4.3 does not authorize BuyDesign to share any part of any agreement
entered into through the Platform with anyone other than a party to such agreement. Such
agreements remain confidential to the parties and BuyDesign.

## 5. WARRANTIES

5.1. You warrant to us that you are legally capable of entering into binding contracts, and you have
full authority, power and capacity to agree to these terms and conditions on behalf of the
organization that you represent.
5.2. You warrant and undertake that you shall not upload to the Platform any content owned by
any third party in respect of which you do not have sufficient permissions to share such content
with us.
5.3. These terms and conditions set out the full extent of our obligations and liabilities in respect of
the Designs available on the Platform. To the maximum extent permitted by applicable law, all
conditions, warranties or other terms concerning the Designs which might otherwise be implied
into a contract with a business customer are expressly excluded.
5.4. Nothing in these terms and conditions will (a) limit or exclude the liability of a party for death
or personal injury resulting from negligence; (b) limit or exclude the liability of a party for fraud
or fraudulent misrepresentation by that party; (c) limit any liability of a party in any way that is
not permitted under applicable law; or (d) exclude any liability of a party that may not be
excluded under applicable law.
5.5. We will not be liable to you in respect of any damages, business losses, loss of or damage to
profits, income, revenue, use, production, anticipated savings, business, contracts, commercial
opportunities or goodwill, or otherwise in relation to any Design in any way.
5.6. We will not be liable to you in respect of any loss or corruption of any data, database or
software.
5.7. We will not be liable to you in respect of any special, indirect or consequential loss or damage.
5.8. Our liability to you shall be limited to the subscription fees and commission which you have
paid to us in the 12 months preceding the date of any liability arising.
5.9. You hereby indemnify us and undertake to keep us indemnified against all and any liabilities,
losses, damages, expenses and costs (including legal expenses and amounts paid in settlement
of any demand, action or claim) arising, directly or indirectly, out of a breach by you of any of
your obligations under these terms and conditions.

## 6. TERMINATION

6.1. These terms and conditions may be terminated immediately by either party for failure of the
other party to comply with any material term of these terms and conditions, unless the failure
to comply has been remedied within 30 days of notification of such failure.
6.2. We may terminate these terms and conditions if:
6.2.1. you cease to trade;
6.2.2. you become insolvent or unable to pay your debts within the meaning of the insolvency
legislation applicable to you;
6.2.3. a person (including the holder of a charge or other security interest) is appointed to
manage or take control of the whole or part of your business or assets, or notice of an
intention to appoint such a person is given or documents relating to such an appointment
are filed with any court;
6.2.4. the ability of your creditors to take any action to enforce their debts is suspended,
restricted or prevented, or some or all of your creditors accept, by agreement or pursuant
to a court order, an amount of less than the sums owing to them in satisfaction of those
sums; or
6.2.5. any process is instituted which could lead to you being dissolved and your assets being
distributed to your creditors, shareholders or other contributors.
6.3. Upon termination of these terms and conditions for any reason and unless specified otherwise
herein, all rights and licences granted pursuant to this terms and conditions shall cease, save
that BuyDesign shall continue to act as the Designer’s agent for receipt of any sums due under
these terms and conditions in relation to any agreement entered into with a Designer prior to
the date of termination.

## 7. OTHER PROVISIONS

7.1. We are entitled to amend or vary these terms and conditions from time to time at our sole
discretion. We will notify you of any change which materially affects your rights and
obligations.
7.2. We are entitled to assign or license all of the rights granted to us under these terms and
conditions to any third party. You shall not be entitled to assign or sublicense your rights under
these terms and conditions.
7.3. If any provision or part-provision of these terms and conditions is or becomes invalid, illegal or
unenforceable, it shall be deemed modified to the minimum extent necessary to make it valid,legal and enforceable. If such modification is not possible, the relevant provision or part-
provision shall be deemed deleted. Any modification to or deletion of a provision or part-
provision under this section shall not affect the validity and enforceability of the rest of these
terms and conditions.
7.4. These terms and conditions and the documents referred to in them constitute the entire
agreement between the parties and supersedes and extinguishes all previous agreements,
promises, assurances, warranties, representations and understandings between them, whether
written or oral, relating to its subject matter.
7.5. These terms and conditions shall be governed by the laws of England and the parties submit to
the exclusive jurisdiction of the courts of England in relation to any disputes arising under them.`;

export default BuyerTerms;
