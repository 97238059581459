import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';

import { Box } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChatIcon from '@mui/icons-material/Chat';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import DoneIcon from '@mui/icons-material/Done';
import Spinner from 'components/Spinner/Spinner';
import ChatBox from 'components/ChatBox/ChatBox';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import ModalBriefProposalComplete from 'components/ModalBriefProposalComplete/ModalBriefProposalComplete';
import ModalCongratulations from 'components/ModalCongratulations/ModalCongratulations';
import BuyerTabSectionsWrapper from 'components/Wrappers/TabSectionsWrapper/BuyerTabSectionsWrapper/BuyerTabSectionsWrapper';
import TopBarTitleStatus from 'components/TopBarTitleStatus/TopBarTitleStatus';
import ScrollableContainer from 'components/ScrollableContainer/ScrollableContainer';
import BodyText from 'components/Typography/BodyText/BodyText';
import ModalAcceptProposal from 'components/ModalAcceptProposal/ModalAcceptProposal';
import ModalConfirmAccept from 'components/ModalConfirmAccept/ModalConfirmAccept';
import ModalRejectBrief from 'components/ModalRejectBrief/ModalRejectBrief';

import { routes } from 'options/routes';
import { PROJECT_STATUS } from 'options/projectStatus';

import { parseRemoteProductProposalData } from 'utils/parseProductProposal';
import {
  getIsAgreementTabDisabled,
  getIsFilesTabDisabled,
  getShowCheckoutButton,
  getShowCompletePurchase,
} from '../BuyerPitchesSingle.utils';

import { options } from './BuyerPitchesSingleLanding.data';

import { useMessageContext } from 'context/MessageContext';
import { useAuthContext } from 'context/AuthContext';

import {
  deleteBuyersPitchFiles,
  postBuyersPitchFiles,
  downloadBuyersPitchFiles,
  getBuyersSinglePitch,
  postProductChatMessage,
  getProductChatMessages,
  getCheckoutPitch,
  completePitchPurchase,
  postBuyersSinglePitch,
} from 'api';


const BuyerPitchesSingleLanding = ({ userType }) => {
  const queryClient = useQueryClient();
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const { pitch_uuid } = useParams();
  const [setMessage] = useMessageContext();
  const navigate = useNavigate();

  const [completePurchase, openCompletePurchase] = useState(false);
  const [openCongratulations, setOpenCongratulations] = useState(false);
  const [openHaveAccepted, setOpenHaveAccepted] = useState(false);
  const { company_name } = useAuthContext();

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const [showMessages, setShowMessages] = useState(true);

  const { data, isLoading, isRefetching } = useQuery(
    ['buyerSinglePitch', pitch_uuid],
    () => {
      return getBuyersSinglePitch(pitch_uuid);
    },
    {
      enabled: true,
      staleTime: 2 * 60 * 1000,
    }
  );

  const loading = isLoading || isRefetching;
  const pitch = (data && data.pitch && parseRemoteProductProposalData(data?.pitch)) || {};
  const hasError = data?.data?.error_message;

  const { agreement_type, agreement_status, project_status, selected_agreement_type, designer_name, name } = pitch;

  const designerFirstName = designer_name && designer_name.split(' ')[0];

  const chat_uuid = pitch?.chat_uuid;
  const title = pitch?.name;

  const [selectedAgreementType, setSelectedAgreementType] = useState(agreement_type);


  useEffect(() => {
    if(agreement_type) {
      setSelectedAgreementType(agreement_type === 'both' ? 
      selected_agreement_type : agreement_type || selected_agreement_type)
    }
  },[agreement_type])

  const updatePitchCache = useCallback(
    (accept) => {
      queryClient.setQueryData(['buyerSinglePitch', pitch_uuid], (oldData) => ({
        pitch: {
          ...oldData.pitch,
          project_status: accept ? PROJECT_STATUS.ACCEPTED : PROJECT_STATUS.REJECTED,
          selected_agreement_type: accept ? selectedAgreementType : '',
        },
        success: true,
      }));
    },
    [pitch_uuid, queryClient, selectedAgreementType]
  );

  const handleSectionClick = useCallback(
    (path) => {
      navigate(`${routes.pitchesDashboard}/${pitch_uuid}${path}`);
    },
    [navigate, pitch_uuid]
  );

  const handleOnCompletePurchase = useCallback(async () => {
    const res = await completePitchPurchase(pitch_uuid);
    if (!res.success) {
      openCompletePurchase(false);
      return setMessage('There was a problem completing the purchase');
    }
    openCompletePurchase(false);
    setOpenCongratulations(true);
  }, [pitch_uuid, setMessage]);

  const handleOnCheckout = useCallback(async () => {
    setIsCheckoutLoading(true);
    const res = await getCheckoutPitch(pitch_uuid);
    setIsCheckoutLoading(false);
    if (!res.success || !res.url) {
      return setMessage(res?.data?.error_message || 'There was a problem checking out.');
    }

    //redirect to stripe
    window.location.href = res?.url;
  }, [pitch_uuid, setMessage]);

  const isFilesTabDisabled = getIsFilesTabDisabled(agreement_status);
  const isAgreementTabDisabled = getIsAgreementTabDisabled(project_status);
  const showCompletePurchase = getShowCompletePurchase(agreement_status, project_status);

  const showCheckoutButton = getShowCheckoutButton(agreement_status);
  const isProjectCompleted = project_status === PROJECT_STATUS.COMPLETED;

  const toggleMessages = useCallback(() => {
    setShowMessages((prev) => !prev);
  }, []);

  const handleCloseAcceptedModal = async () => {
    // refetch data or set the proposal to be accepted
    setOpenHaveAccepted(false);
  };

  const showButtons = !selected_agreement_type && project_status !== PROJECT_STATUS.REJECTED;
  
  const briefDesignerName = useMemo(() => {
    const designer = pitch?.designer;
    if (designer) {
      return designer.first_name + ' ' + designer.last_name;
    }
    return '';
  }, [pitch?.designer]);


  const onAccept = useCallback(() => {
    setShowAcceptModal(true);
  }, []);

  const onReject = useCallback(() => {
    setShowRejectModal(true);
  }, []);

  const navigateToTerms = () => {
    navigate(`${routes.pitchesDashboard}/${pitch_uuid}/agreement`);
    setOpenHaveAccepted(false);
  };

  const handleAcceptSubmit = useCallback(async () => {
    if (!selectedAgreementType) {
      return setMessage('You must select an agreement type');
    }
    const res = await postBuyersSinglePitch(pitch_uuid, {
      response: 'accepted',
      agreement_type: selectedAgreementType,
    });
    if (!res.success) {
      setMessage('There was an error accepting pitch');
    }
    setOpenHaveAccepted(true);
    updatePitchCache(true);
    setShowAcceptModal(false);
  }, [pitch_uuid, setMessage, selectedAgreementType]);

  const handleRejectSubmit = useCallback(
    // could refactor this later, bool is not needed here
    async (bool, message) => {
      const res = await postBuyersSinglePitch(pitch_uuid, {
        response: 'rejected',
        rejected_reason: message,
      });
      if (!res.success) {
        setMessage('There was an error rejecting the pitch.');
        return setShowRejectModal(false);
      }
      updatePitchCache(false);
      setMessage('You have sucessfully rejected the pitch.');
    },
    [pitch_uuid, setMessage, updatePitchCache]
  );

  return (
    <BuyerTabSectionsWrapper
      title={title}
      headerMenuOptions={[
        {
          buttonTitle: showMessages ? 'Hide Messages' : 'Show Messages',
          icon: showMessages ? <SpeakerNotesOffIcon fontSize="small" /> : <ChatIcon fontSize="small" />,
          onClick: toggleMessages,
        },
        {
          buttonTitle: 'Back',
          icon: <ArrowBackIcon fontSize="small" />,
          onClick: () => navigate(`${routes.pitchesDashboard}`),
          animate: true,
        },
        ...(showCompletePurchase
          ? [
              {
                buttonTitle: 'Complete Purchase',
                icon: <DoneIcon fontSize="small" />,
                onClick: () => openCompletePurchase(true),
                colour: 'black',
              },
            ]
          : []),
        ...(showCheckoutButton
          ? [
              {
                buttonTitle: 'Checkout',
                icon: isCheckoutLoading && <Spinner size="12px" />,
                onClick: () => handleOnCheckout(),
                colour: 'black',
              },
            ]
          : []),
      ]}
      collapse={showMessages}
      activeTab={routes.pitchesDashboard}
      breadcrumbs={
        <Breadcrumbs
          links={[{ label: 'Pitches', onClick: () => navigate(routes.pitchesDashboard) }]}
          currentPage={title}
        />
      }
      handleSectionClick={handleSectionClick}
      options={options.map((option) => {
        if (option.path === '/files') {
          return {
            ...option,
            disabled: isFilesTabDisabled,
          };
        }
        if (option.path === '/agreement') {
          return {
            ...option,
            disabled: isAgreementTabDisabled,
          };
        }
        return option;
      })}
      chatbox={
        <ChatBox
          chat_uuid={chat_uuid}
          getChatMessages={() => getProductChatMessages(chat_uuid)}
          postChatMessage={(chat_uuid, data) => postProductChatMessage(chat_uuid, data)}
          downloadFiles={(data) => downloadBuyersPitchFiles(pitch_uuid, data, true)}
          uploadFiles={(data) => postBuyersPitchFiles(pitch_uuid, data)}
          deleteFiles={(file_uuid) => deleteBuyersPitchFiles(pitch_uuid, file_uuid)}
          userType={userType}
          expand={showMessages}
          setExpand={toggleMessages}
          onAcceptClick={showButtons ? onAccept : null}
          onRejectClick={showButtons ? onReject : null}
          acceptBtnText={'Connect with designer'}
          rejectBtnText={'Decline Proposal'}
          isAcceptDisabled={!selectedAgreementType}
        />
      }
    >
      {loading && (
        <Box mt={2}>
          <Spinner align="center" />
        </Box>
      )}
      {!loading && (
        <>
          <TopBarTitleStatus name={briefDesignerName} project_status={project_status} />
          {!loading && isProjectCompleted && (
            <Box mt={2}>
              <BodyText text="This project has been completed." />
            </Box>
          )}
          <ScrollableContainer collapse={showMessages}>
            {hasError ? (
              <Box mt={2}>
                <BodyText text="Pitch no longer available." />
              </Box>
            ) : (
              <Outlet context={[pitch, setMessage, userType, onAccept, onReject, selectedAgreementType, setSelectedAgreementType]} />
            )}
          </ScrollableContainer>
        </>
      )}
        {showAcceptModal && (
        <ModalConfirmAccept
          open={showAcceptModal}
          name="pitch"
          handleClose={() => setShowAcceptModal(false)}
          handleSubmit={() => handleAcceptSubmit()}
          titleOverride="Connect with Designer"
          textOverride={
            "Please confirm you want to connect with this designer. After you've confirmed you can continue to develop the work together."
          }
        />
      )}

      {showRejectModal && (
        <ModalRejectBrief
          open={showRejectModal}
          handleClose={() => setShowRejectModal(false)}
          handleSubmit={handleRejectSubmit}
          variant="pitch"
          item_name={name}
          designer_name={designerFirstName}
          company_name={company_name}
        />
      )}
      {openHaveAccepted && (
        <ModalAcceptProposal
          open={openHaveAccepted}
          handleClose={() => handleCloseAcceptedModal()}
          navigateAction={navigateToTerms}
        />
      )}
      {completePurchase && (
        <ModalBriefProposalComplete
          open={completePurchase}
          handleClose={() => openCompletePurchase(false)}
          onSubmit={handleOnCompletePurchase}
          variant="pitch"
        />
      )}

      {openCongratulations && (
        <ModalCongratulations
          handleClose={() => setOpenCongratulations(false)}
          action={() => navigate(`${routes.pitchesDashboard}/${pitch_uuid}/review`)}
          text="You have purchased the product!"
          title="Project"
          open={openCongratulations}
        />
      )}
    </BuyerTabSectionsWrapper>
  );
};

BuyerPitchesSingleLanding.propTypes = {};

export default BuyerPitchesSingleLanding;
