import { useNavigate } from 'react-router-dom';

import { NavBarLogoStyled } from './NavBarLogo.styles';
import { wpBaseUrl } from '../../../../options/routes';

const NavBarLogo = () => {
  const navigate = useNavigate();

  return <NavBarLogoStyled component="a" onClick={() => window.location.href = wpBaseUrl} />;
};

export default NavBarLogo;
