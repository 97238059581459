export const mockProductImageUrl_1 =
  'https://images.unsplash.com/photo-1598300056393-4aac492f4344?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1434&q=80';

export const mockProductImageUrl_2 =
  'https://images.unsplash.com/photo-1652346080508-e2a6b7298450?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80';

export const mockProductImageUrl_3 =
  'https://images.unsplash.com/photo-1493030233192-040fe1b18799?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1480&q=80';

export const mockProductImageObject = { fileSrc: mockProductImageUrl_1, fileType: 'image' };

export const mockProductImageObject_2 = { fileSrc: mockProductImageUrl_2, fileType: 'image' };

export const mockProductImageObject_3 = { fileSrc: mockProductImageUrl_3, fileType: 'image' };

export const mockProductList = [mockProductImageObject, mockProductImageObject_2, mockProductImageObject_3];
