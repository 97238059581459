import React, { useState, useRef, useCallback } from 'react';
import { Box } from '@mui/material';

import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';

import { hideOpenCall, showOpenCall } from 'api';

//TODO: Update link for navigation
const ModalShowHideOpenCall = ({ open, handleClose, openCallUUID, visible }) => {
  const [loading, setLoading] = useState(false);
  const shouldRefetch = useRef(false);

  const onClose = () => {
    handleClose(shouldRefetch.current);
    shouldRefetch.current = false;
  };

  const text = visible
    ? 'Please confirm that you want to hide this Open Call from your profile?'
    : 'Please confirm that you want to show this Open Call on your profile?';

  const title = visible ? 'Hide this Open Call' : 'Show this Open Call';

  const handleShowOpenCall = useCallback(async () => {
    if (!openCallUUID) return;
    setLoading(true);
    shouldRefetch.current = true;
    const res = await showOpenCall(openCallUUID);
    onClose();
  }, [openCallUUID]);

  const handleHideOpenCall = useCallback(async () => {
    if (!openCallUUID) return;
    setLoading(true);
    shouldRefetch.current = true;
    const res = await hideOpenCall(openCallUUID);
    onClose();
  }, [openCallUUID]);

  return (
    <CustomModal title={title} open={open} handleClose={onClose}>
      <Box>
        <Box mb={2} mt={2}>
          <BodyText text={text} />
        </Box>
        {!loading ? (
          <Box mt={4} display={'flex'}>
            <Box mr={2} flex={1}>
              <CustomButton fullWidth={true} variant="outlined" onClick={onClose} text="Cancel" />
            </Box>
            <Box flex={1}>
              <CustomButton
                fullWidth={true}
                onClick={visible ? handleHideOpenCall : handleShowOpenCall}
                text="Confirm"
              />
            </Box>
          </Box>
        ) : (
          <Box>
            <Spinner align={'center'} />
          </Box>
        )}
      </Box>
    </CustomModal>
  );
};

export default ModalShowHideOpenCall;
