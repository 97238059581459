import { styled } from '@mui/system';
import { MAX_WIDTH } from 'styles/constants';

export const SectionWrapper = styled('div')(({ theme }) => ({
  flex: 1,
  background: `linear-gradient(to right, ${theme.palette.common.black} 0%, ${theme.palette.common.black} 50%, white 50%, white 100%)`,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    height: '100%',
    marginRight: 0,
    background: 'unset',
  },
}));

export const SectionInnerWrapper = styled('div')(({ theme }) => ({
  maxWidth: MAX_WIDTH,
  margin: '0 auto',
  display: 'flex',
  width: '90%',
  [theme.breakpoints.down('md')]: {
    width: '95%',
    flexDirection: 'column',
  },
}));

export const SectionContainer = styled('div')(({ theme }) => ({
  padding: '120px 90px',
  display: 'flex',
  alignItems: 'top',
  flex: 1,
  '&:first-of-type': {
    paddingLeft: '0px',
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  '&:nth-of-type(2)': {
    backgroundColor: theme.palette.common.white,
    paddingRight: '0px',
    flex: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '0px',
    },
  },
  [theme.breakpoints.down('md')]: {
    display: 'block',
    padding: '120px 20px',
    minHeight: '400px',
    flex: 'none',
  },
}));
