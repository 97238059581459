import Tab from '@mui/material/Tab';
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/system';

export const TabWrapperStyled = styled('div')(({ theme, variant }) => ({
  border: `1px solid ${theme.palette.common.black}`,
  borderRadius: theme.shape.borderRadius * 5,
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  backgroundColor: variant === 'standard' ? 'none' : theme.palette.primary.main,
  opacity: variant === 'active' ? 0.4 : 1,
  width: 'calc(16.666% - 8px)',
  marginRight: theme.spacing(1),
  boxSizing: 'border-box',
  '&:last-child': {
    width: 'calc(16.666%)',
    paddingRight: variant === 'completed' ? theme.spacing(2) : '',
    marginRight: 0,
  },
  [theme.breakpoints.down('lg')]: {
    width: 'calc(33% - 2px) !important',
    margin: `0 0 ${theme.spacing(0.5)} 0px`,
  },
  [theme.breakpoints.down('md')]: {
    width: 'calc(50% - 4px) !important',
    '&:nth-of-type(odd)': {
      margin: `0 2px ${theme.spacing(0.5)} 0`,
    },
    '&:nth-of-type(even)': {
      margin: `0 0 ${theme.spacing(0.5)} 2px`,
    },
  },
}));

export const TabStyled = styled(Tab)(({ theme, variant }) => ({
  color: variant === 'standard' ? theme.palette.text.primary : theme.palette.text.white,
  minHeight: 'unset',
  opacity: 1,
  padding: `${theme.spacing(0.5)} ${theme.spacing(4)}`,
  zIndex: 1,
  width: '100%',
  '&.Mui-selected': {
    color: theme.palette.text.white,
  },
}));

export const CheckIconStyled = styled(CheckIcon)(({ theme }) => ({
  position: 'absolute',
  top: '2px',
  right: '4px',
  color: theme.palette.text.white,
  zIndex: 0,
}));
