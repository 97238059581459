import isMatch from 'date-fns/isMatch';
import { parseStringToDate } from './parseDates';

const sortValues = {
  asc: 1,
  desc: -1,
};

export const sortByObjProp = (a, b, order, orderBy) => {
  if (!order || !orderBy) return 0;
  if (isMatch(a[orderBy], 'dd/MM/yyyy')) return sortByDate(a, b, order, orderBy);
  if (typeof a[orderBy] === 'number') return sortValues[order] * (a[orderBy] < b[orderBy] ? -1 : 1);
  return sortValues[order] * (a[orderBy]?.toLowerCase() < b[orderBy]?.toLowerCase() ? -1 : 1);
};

export const filterCategories = (categories, filter) => {
  if (!filter || !filter?.length) return true;

  return !!categories.find((c) => filter.includes(c?.slug));
};

export const sortByDate = (a, b, order = 'asc', orderBy) => {
  const stringA = a[orderBy];
  const stringB = b[orderBy];

  const [dayA, monthA, yearA] = stringA.split('/');
  const convertedA = `${monthA}/${dayA}/${yearA}`;
  const [dayB, monthB, yearB] = stringB.split('/');
  const convertedB = `${monthB}/${dayB}/${yearB}`;

  return order === 'desc'
    ? parseStringToDate(convertedB).getTime() - parseStringToDate(convertedA).getTime()
    : parseStringToDate(convertedA).getTime() - parseStringToDate(convertedB).getTime();
};
