import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';

import { StyledModalContentWrapper, StyledActionButtonsContainer } from 'components/CustomModal/CustomModal.styles';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';

import { yupObject, validation } from 'utils/validation';
import { capitalize } from 'utils/capitalize';

const validationSchema = yupObject({
  ...validation.deleteProduct,
});

const ModalDeleteItem = ({ open, handleClose, onSubmit, callback, variant = 'product' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const methods = useForm({
    defaultValues: {
      delete: '',
    },
    resolver,
  });

  useEffect(() => {
    if (!open) {
      methods.reset({ delete: '' });
    }
  }, [methods, open]);

  const handleOnSubmit = useCallback(async () => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
    callback();
    handleClose(true);
  }, [callback, handleClose, onSubmit]);

  return (
    <CustomModal title={`Delete ${capitalize(variant)}`} open={open} handleClose={() => handleClose(false)}>
      <StyledModalContentWrapper>
        {isLoading ? (
          <Box mt={2}>
            <Spinner align="center" />
          </Box>
        ) : (
          <Box mt={1}>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleOnSubmit)}>
                <Box mb={2}>
                  <BodyText
                    text={`Are you sure you want to delete this ${variant}? Once confirmed, this action cannot be undone.`}
                    colour="gray"
                  />
                </Box>
                <Box>
                  <BodyText text="Type 'delete' in the field below to confirm:" />
                </Box>
                <ControlledTextField placeholder="delete" name="delete" />
                <StyledActionButtonsContainer>
                  <CustomButton text="Cancel" onClick={() => handleClose(false)} variant="outlined" fullWidth />
                  <CustomButton text="Remove" buttonType="submit" fullWidth />
                </StyledActionButtonsContainer>
              </form>
            </FormProvider>
          </Box>
        )}
      </StyledModalContentWrapper>
    </CustomModal>
  );
};

ModalDeleteItem.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalDeleteItem;
