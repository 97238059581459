import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import NavBarLink from '../NavNarLink/NavBarLink';

import { IconButtonStyled, IconContainerStyled, MenuIconStyled, MenuStyled } from './NavBarMenu.styles';
import { NavBarBuyerContext } from 'components/NavBar/NavBarBuyer/NavBarBuyer';
import { Box } from '@mui/system';

const NavBarMenu = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const NavBuyerContext = useContext(NavBarBuyerContext);
  return (
    <div>
      <IconButtonStyled onClick={handleClick}>
        <MenuIconStyled />
      </IconButtonStyled>
      <MenuStyled anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options?.map((option) => {
          let thisIconAndFn = null;
          if (NavBuyerContext?.navBarLinksIconsAndFns) {
            thisIconAndFn = NavBuyerContext.navBarLinksIconsAndFns.find((item) => item.linkText === option.linkText);
          }
          return (
            <MenuItem key={option.linkText} onClick={handleClose}>
              <NavBarLink linkText={option.linkText} linkPath={option.linkPath} openNewTab={option.openNewTab} external={option.external}/>
              {thisIconAndFn && (
                <IconContainerStyled onClick={thisIconAndFn.onClick}>{thisIconAndFn.icon}</IconContainerStyled>
              )}
            </MenuItem>
          );
        })}
      </MenuStyled>
    </div>
  );
};

NavBarMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default NavBarMenu;
