import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import { Box } from '@mui/system';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from 'components/Spinner/Spinner';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import {
  CustomModalStyled,
  LoadingContainer,
  PDFBackBtnWrapper,
  PdfContainer,
  PdfModalCloseWrapper,
  PdfModalContentContainer,
  PDFNextBtnWrapper,
  PDFPageNumWrapper,
} from './PreviewReferenceFileModal.styles';

const PreviewReferenceFileModal = ({ loading, reference_file, open, handleClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber === numPages) return;
    setPageNumber((prev) => prev + 1);
  };

  const handleBackPage = () => {
    if (pageNumber === 1) return;
    setPageNumber((prev) => prev - 1);
  };

  const closePdfModal = () => {
    setPageNumber(1);
    handleClose();
  };

  const loadingComponent = (
    <LoadingContainer width="100%">
      <Spinner align={'center'} />
    </LoadingContainer>
  );

  if (!reference_file && loading)
    return (
      <CustomModalStyled open={open} onClose={handleClose}>
        <PdfModalContentContainer>{loadingComponent}</PdfModalContentContainer>
      </CustomModalStyled>
    );

  if (reference_file && !loading) {
    const fileSrc = reference_file.fileSrc ? atob(reference_file?.fileSrc) : '';

    return (
      <CustomModalStyled open={open} onClose={closePdfModal}>
        <PdfModalContentContainer>
          <>
            <PdfModalCloseWrapper onClick={closePdfModal}>
              <CustomIconButton icon={<CloseIcon />} onClick={() => {}} />
            </PdfModalCloseWrapper>
            {!!reference_file && !loading && (
              <PdfContainer>
                <Document loading={loadingComponent} file={fileSrc} onLoadSuccess={onDocumentLoadSuccess}>
                  <Box minHeight="100px">
                    <Page pageNumber={pageNumber} />
                  </Box>
                </Document>
                {numPages && (
                  <PDFPageNumWrapper>
                    <BodyText text={`  Page ${pageNumber} of ${numPages}`} />
                  </PDFPageNumWrapper>
                )}
                {numPages > 1 && (
                  <>
                    <PDFNextBtnWrapper>
                      <CustomIconButton onClick={handleNextPage} icon={<EastIcon />} />
                    </PDFNextBtnWrapper>
                    {pageNumber > 1 && (
                      <PDFBackBtnWrapper>
                        <CustomIconButton onClick={handleBackPage} icon={<WestIcon />} />
                      </PDFBackBtnWrapper>
                    )}
                  </>
                )}
              </PdfContainer>
            )}
          </>
        </PdfModalContentContainer>
      </CustomModalStyled>
    );
  }
};

export default PreviewReferenceFileModal;
