import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';

import { Box } from '@mui/material';
import SearchAndFilterOptions from 'components/SearchAndFilterOptions/SearchAndFilterOptions';
import BodyText from 'components/Typography/BodyText/BodyText';
import Spinner from 'components/Spinner/Spinner';
import OpenBriefsAccordionsContainer from 'components/OpenBriefsAccordionsContainer/OpenBriefsAccordionsContainer';

import { userTypes } from 'options/userTypes';
import { OPTIONS_TYPE } from 'options/selectOptions';

import { BUYER_OPEN_CALL_PILL_STATUS_TEXT } from 'options/pillStatusOptions';

import { addFilters } from 'utils/addFilters';
import { DateObjFromUKDateStr } from 'utils/parseDates';

import { OpenCallAccordionLoadingContainer } from 'pages/DesignerBuyerProfile/DesignerBuyerProfile.styles';

import { getBuyersOpenCalls, getChildOptions } from 'api';

import { useTableFilter } from 'hooks/useTableFilter';

const BuyerOpenCalls = ({ company_uuid, setCanCreate }) => {
  const { order, orderBy, filter, setFilter, onRequestSort } = useTableFilter({
    initialOrder: null,
    initialOrderBy: null,
  });

  const {
    data: { options: categories },
  } = useQuery(['categories', [OPTIONS_TYPE.product], 'top'], () => getChildOptions([OPTIONS_TYPE.product], 'top'), {
    staleTime: 5 * 60 * 1000,
    placeholderData: { options: [] },
  });

  const fetchOpenCalls = useQuery(['allBuyerOpenCalls', company_uuid], () => getBuyersOpenCalls(company_uuid), {
    enabled: !!company_uuid,
    staleTime: 5 * 60 * 1000,
    refetchOnMount: 'always',
  });

  const buyersOpenCalls = useMemo(() => fetchOpenCalls?.data?.open_calls || null, [fetchOpenCalls?.data?.open_calls]);

  useEffect(() => {
    if (fetchOpenCalls?.data?.can_create) {
      setCanCreate(true);
    }
  }, [setCanCreate, fetchOpenCalls?.data]);

  const filteredAndSorted = useMemo(() => {
    if (!buyersOpenCalls) return null;
    let mutatedArray = buyersOpenCalls;
    if (!!filter?.categories?.length > 0) {
      mutatedArray = mutatedArray.filter((item) =>
        item.filter_categories.some((i) => filter.categories.includes(i.slug))
      );
    }
    if (order && orderBy) {
      switch (orderBy) {
        case 'deadline':
          if (order === 'asc') {
            mutatedArray.sort(
              (a, b) => DateObjFromUKDateStr(b.deadline).getTime() - DateObjFromUKDateStr(a.deadline).getTime()
            );
          }
          if (order === 'desc') {
            mutatedArray.sort(
              (a, b) => DateObjFromUKDateStr(a.deadline).getTime() - DateObjFromUKDateStr(b.deadline).getTime()
            );
          }

          break;
        case 'name':
          if (order === 'asc') {
            mutatedArray.sort((a, b) => (a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 1));
          }
          if (order === 'desc') {
            mutatedArray.sort((a, b) => (b.title.toLowerCase() < a.title.toLowerCase() ? -1 : 1));
          }
          break;
        default:
          break;
      }
    }
    return mutatedArray;
  }, [order, orderBy, buyersOpenCalls, filter?.categories?.length]);

  const filtersList = useMemo(
    () => ({
      categories: categories ? [...categories] : [],
    }),
    [categories]
  );

  const menuFilterOptions = useMemo(() => {
    const FIND_BUYER_MENU = [
      {
        label: 'Name',
        id: 'name',
        actions: [],
        sortable: true,
      },
      {
        label: 'Deadline',
        id: 'deadline',
        actions: [],
        sortable: true,
      },
      {
        label: 'Categories',
        id: 'categories',
      },
    ];
    return FIND_BUYER_MENU.map((menuItem) => {
      if (menuItem.sortable)
        return {
          ...menuItem,
        };
      else
        return {
          ...menuItem,
          actions: addFilters(menuItem, filtersList, setFilter, filter, BUYER_OPEN_CALL_PILL_STATUS_TEXT),
        };
    });
  }, [filter, filtersList]);

  const noResultsMessage = () => {
    if (buyersOpenCalls?.length === 0) return "You haven't got any open calls";
    if (buyersOpenCalls?.length > 0 && filteredAndSorted.length === 0)
      return 'You have no open calls match this search criteria';
  };

  return (
    <Box mb={6}>
      <SearchAndFilterOptions
        onRequestSort={onRequestSort}
        orderBy={orderBy}
        order={order}
        title={`Open Calls (${buyersOpenCalls?.length || '...'})`}
        menuOptions={menuFilterOptions}
      />
      {fetchOpenCalls.isLoading && (
        <OpenCallAccordionLoadingContainer>
          <Spinner align={'center'} />
        </OpenCallAccordionLoadingContainer>
      )}
      {!fetchOpenCalls.isLoading && filteredAndSorted?.length > 0 && (
        <OpenBriefsAccordionsContainer role={userTypes.buyer} openBriefsData={filteredAndSorted} />
      )}
      {!fetchOpenCalls.isLoading && (!filteredAndSorted || filteredAndSorted?.length === 0) && (
        <Box style={{ minHeight: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <BodyText text={noResultsMessage()} />
        </Box>
      )}
    </Box>
  );
};

BuyerOpenCalls.propTypes = {
  company_uuid: PropTypes.string.isRequired,
};

export default BuyerOpenCalls;
