import React from 'react';
import PropTypes from 'prop-types';

import CustomTabGroup from 'components/CustomTabGroup/CustomTabGroup';

import { BUYER_TAB_GROUP_OPTIONS, BUYER_USER_TAB_GROUP_OPTIONS } from 'options/tabGroupOptions';

import { userTypes } from 'options/userTypes';

import { useAuthContext } from 'context/AuthContext';

const BuyerDashboardMenu = ({ activeTab }) => {
  const { role } = useAuthContext();
  const options = role === userTypes.buyerAdmin ? BUYER_TAB_GROUP_OPTIONS : BUYER_USER_TAB_GROUP_OPTIONS;
  return <CustomTabGroup activeTab={activeTab} options={options} />;
};

BuyerDashboardMenu.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default BuyerDashboardMenu;
