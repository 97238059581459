import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import FileUploadThumbnail from '../FileUploadThumbnail/FileUploadThumbnail';
import CustomFileUploadField from '../CustomFileUploadField/CustomFileUploadField';

import { ErrorMessage } from 'components/Inputs/TextFields/CustomTextField/CustomTextField.styles';

import { transformToBase64List } from 'utils/convertToBase64';
import { TilesContainerStyled } from './CustomFileUploadContainer.styles';

const CustomFileUploadContainer = ({
  variant,
  fileTypes,
  numberOfTiles,
  selectedFiles,
  handleChange,
  removeFile,
  setError,
  error,
  addFileText,
}) => {
  const selectedVariant = numberOfTiles === 1 ? 'single' : variant;

  const changeHandler = async (event) => {
    const eventFile = event.target.files;
    const sumOfPrevSelectionWithNew = selectedFiles.length + eventFile.length;

    // will this selection go over NUMBER_OF_TILES?
    if (sumOfPrevSelectionWithNew > numberOfTiles) {
      return setError(`You can only select up to ${numberOfTiles} files.`);
    }

    const base64Files = await transformToBase64List(eventFile);
    handleChange(base64Files);
  };

  const handleRemoveFile = (index) => {
    removeFile(index);
  };

  const tiles = useMemo(() => {
    if (selectedFiles.length === numberOfTiles) {
      return [...selectedFiles];
    }

    return [...selectedFiles, 'input'];
  }, [selectedFiles, numberOfTiles]);

  return (
    <div>
      <TilesContainerStyled numberOfTiles={numberOfTiles}>
        {tiles.map((fileDetails, index) => {
          if (fileDetails === 'input' || (fileDetails?.fileType === 'url' && fileDetails?.fileSrc === null)) {
            return (
              <CustomFileUploadField
                onChangeHandler={changeHandler}
                numberOfTiles={numberOfTiles}
                variant={selectedVariant}
                fileTypes={fileTypes}
                key={`input_${index}`}
                error={!!error}
                addFileText={addFileText}
              />
            );
          }
          return (
            <FileUploadThumbnail
              fileSrc={fileDetails && fileDetails.fileSrc}
              name={fileDetails && fileDetails.name}
              numberOfTiles={numberOfTiles}
              removeFile={() => handleRemoveFile(index)}
              index={index}
              key={`img_${index}`}
              fileType={fileDetails && fileDetails.fileType}
            />
          );
        })}
      </TilesContainerStyled>
      <Box height="20px">{error && <ErrorMessage>{error}</ErrorMessage>}</Box>
    </div>
  );
};

CustomFileUploadContainer.propTypes = {
  variant: PropTypes.oneOf(['single', 'multi']),
  fileTypes: PropTypes.oneOf(['images', 'files', 'all']),
  numberOfTiles: PropTypes.number,
  error: PropTypes.string,
};

export default React.memo(CustomFileUploadContainer);
