import { validation, yupObject } from 'utils/validation';

const {
  selectOptionsDesigner: { product_types },
  pictureFields: { portrait },
} = validation;

export const validationSchema = yupObject({
  portrait,
  ...validation.designerStudioFields,
  ...validation.designerGeneralFields,
  product_types,
  ...validation.socialMediaFields,
});
