import React from 'react';
import PropTypes from 'prop-types';

import NavBarLogo from '../components/NavBarLogo/NavBarLogo';
import NavBarLinkList from '../components/NavBarLinkList/NavBarLinkList';

import { NavBarStyled, NavBarWrapperStyled } from '../NavBar.styles';

const NavBarDesigner = () => {
  return (
    <NavBarStyled>
      <NavBarWrapperStyled>
        <NavBarLogo />
        <NavBarLinkList type="designer" />
      </NavBarWrapperStyled>
    </NavBarStyled>
  );
};

NavBarDesigner.propTypes = {
  count: PropTypes.number,
};

export default NavBarDesigner;
