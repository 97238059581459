import { useParams, useOutletContext } from 'react-router';

import ProposalTableList from 'components/ProposalTableList/ProposalTableList';

import { mockData } from 'components/ProposalTableList/ProposalTableList.data';

//TODO: Replace mockData with real data
export const DesignerBriefProposalList = () => {
  // outletContext will be [brief_uuid, setMessage] - only need setMessage
  const outletContext = useOutletContext();
  const { brief_uuid } = useParams();

  return (
    <ProposalTableList brief_uuid={brief_uuid} isStory={false} mockData={mockData} setMessage={outletContext[1]} />
  );
};

export default DesignerBriefProposalList;
