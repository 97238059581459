export const NEW_BRIEF_BUDGET_OPTIONS = [
  {
    label: '2,000',
    value: '2000',
  },
  {
    label: '4,000',
    value: '4000',
  },
  {
    label: '5,000',
    value: '5000',
  },
  {
    label: '10,000',
    value: '10000',
  },
  {
    label: '15,000',
    value: '15000',
  },
];
