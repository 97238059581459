import React from 'react';
import PropTypes from 'prop-types';

import { Box } from '@mui/material';
import CustomCheckboxStandard from '../CustomCheckboxStandard/CustomCheckboxStandard';
import CustomTermsText from 'components/Typography/CustomTermsText/CustomTermsText';
import CustomTermsLink from 'components/Typography/CustomTermsText/CustomTermsLink/CustomTermsLink';
import { ErrorMessage } from '../TextFields/CustomTextField/CustomTextField.styles';
import { StyledCustomCheckBoxWrapper } from './CustomCheckBoxTerms.styles';

const CustomCheckBoxTerms = ({ onlyTermsText, error, onChange, checked }) => {
  const text = (
    <>
      By selecting Continue, I agree to BuyDesign's{' '}
      <CustomTermsLink text="Terms of Services" to="/terms-of-services" />
      {/* <CustomTermsLink text="Buyer Agreement" to="/payments-terms-of-service" />,{' '} */}
      {/* <CustomTermsLink text="Privacy Policy" to="https://buydesign.com/privacy-policy" /> and{' '} */}
      {/* <CustomTermsLink text="Nondiscriminatory Policy." to="/nondiscriminatory-policy" /> */}
    </>
  );

  if (onlyTermsText) return <CustomTermsText variant="primary">{text}</CustomTermsText>;
  else
    return (
      <>
        <StyledCustomCheckBoxWrapper display="flex" alignItems="center">
          <Box mr={1} paddingTop="4px">
            <CustomCheckboxStandard onChange={onChange} checked={checked} showError={false} />
          </Box>
          <CustomTermsText variant="primary">{text}</CustomTermsText>
        </StyledCustomCheckBoxWrapper>

        {error && (
          <ErrorMessage variant="body1" colour="error.main">
            {error}
          </ErrorMessage>
        )}
      </>
    );
};

CustomCheckBoxTerms.propTypes = {
  onChange: PropTypes.func,
  check: PropTypes.bool,
  onlyTermsText: PropTypes.bool,
};

export default CustomCheckBoxTerms;
