import React, { useState } from 'react';
import parse from 'html-react-parser';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import { Box } from '@mui/system';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import BodyText from 'components/Typography/BodyText/BodyText';
import CustomIconButton from 'components/Buttons/CustomIconButton/CustomIconButton';
import CloseIcon from '@mui/icons-material/Close';
import Spinner from 'components/Spinner/Spinner';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

import {
  CustomModalStyled,
  LoadingContainer,
  PDFBackBtnWrapper,
  PdfContainer,
  PdfModalCloseWrapper,
  PdfModalContentContainer,
  PDFNextBtnWrapper,
  PDFPageNumWrapper,
  TitleAndBodyWrapper,
} from './PreviewTermsModal.styles';

const PreviewTermsModal = ({ loading, terms, open, handleClose }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const handleNextPage = () => {
    if (pageNumber === numPages) return;
    setPageNumber((prev) => prev + 1);
  };
  const handleBackPage = () => {
    if (pageNumber === 1) return;
    setPageNumber((prev) => prev - 1);
  };

  const closePdfModal = () => {
    setPageNumber(1);
    handleClose();
  };

  const loadingComponent = (
    <LoadingContainer width="100%">
      <Spinner align={'center'} />
    </LoadingContainer>
  );

  if (!terms && loading)
    return (
      <CustomModalStyled open={open} onClose={handleClose}>
        <PdfModalContentContainer>{loadingComponent}</PdfModalContentContainer>
      </CustomModalStyled>
    );

  if (terms && !loading) {
    const { title, file, body } = terms;
    let fileSrc = '';
    if (file) fileSrc = atob(file?.fileSrc);

    return (
      <CustomModalStyled open={open} onClose={closePdfModal}>
        <PdfModalContentContainer>
          <>
            <PdfModalCloseWrapper onClick={closePdfModal}>
              <CustomIconButton icon={<CloseIcon />} onClick={() => {}} />
            </PdfModalCloseWrapper>
            <TitleAndBodyWrapper>
              <Box mt={2} mb={4}>
                <CustomTitle variant="h4" text={'Agreement Title'} />
                <BodyText variant="h4" text={title} />
              </Box>
              {body && (
                <Box mt={2} mb={2}>
                  <CustomTitle variant="h4" text={'Agreement Body'} />
                  <BodyText variant="h4" text={parse(body)} />
                </Box>
              )}
            </TitleAndBodyWrapper>

            {!!file && !loading && (
              <PdfContainer>
                <Document loading={loadingComponent} file={fileSrc} onLoadSuccess={onDocumentLoadSuccess}>
                  <Box minHeight="100px">
                    <Page pageNumber={pageNumber} />
                  </Box>
                </Document>
                {numPages && (
                  <PDFPageNumWrapper>
                    <BodyText text={`  Page ${pageNumber} of ${numPages}`} />
                  </PDFPageNumWrapper>
                )}
                {numPages > 1 && (
                  <>
                    <PDFNextBtnWrapper>
                      <CustomIconButton onClick={handleNextPage} icon={<EastIcon />} />
                    </PDFNextBtnWrapper>
                    {pageNumber > 1 && (
                      <PDFBackBtnWrapper>
                        <CustomIconButton onClick={handleBackPage} icon={<WestIcon />} />
                      </PDFBackBtnWrapper>
                    )}
                  </>
                )}
              </PdfContainer>
            )}
          </>
        </PdfModalContentContainer>
      </CustomModalStyled>
    );
  }
};

PreviewTermsModal.propTypes = {};

export default PreviewTermsModal;
