import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import CustomTitle from 'components/Typography/CustomTitle/CustomTitle';
import theme from 'theme/theme';
import BodyText from 'components/Typography/BodyText/BodyText';
import { StyledBodyTextContainer } from './DesignersHeader.styles';

const DesignersHeader = (props) => {
  return (
    <>
      <Box pb={theme.spacing(1)} borderBottom={'1.4px solid'}>
        <CustomTitle variant="h2" text="Discover designers" />
      </Box>
      <StyledBodyTextContainer>
        <Box mb={2}>
          <BodyText text="We value originality and collaborate with a diverse international network of creatives." />
        </Box>

        <BodyText text="You can view our featured designers or discover designers by category, location or experience. 
Add your favourite designers to your studio list, and brief them directly or as a group. You can also invite designers personally, via email, to join you on BuyDesign." />
      </StyledBodyTextContainer>
    </>
  );
};

DesignersHeader.propTypes = {};

export default DesignersHeader;
