import { Box, styled } from '@mui/material';

export const ErrorContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const TilesContainerStyled = styled(Box, {
  shouldForwardProp: (props) => props !== 'numberOfTiles',
})(({ theme, numberOfTiles }) => ({
  display: 'flex',
  '& > div > div': {
    width: '150px',
  },
  gap: theme.spacing(1),

  // [theme.breakpoints.down('md')]: {
  //   gridTemplateColumns: numberOfTiles === 1 ? '1fr' : '1fr 1fr 1fr',
  // },
}));
