import { styled } from '@mui/material';
import Tab from '@mui/material/Tab';

export const StyledNavTab = styled(Tab, { shouldForwardProp: (prop) => prop !== 'active' })(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.common.white : theme.palette.common.black,
  color: active ? theme.palette.common.black : theme.palette.common.white,
  fontSize: 14,
  padding: '11px 24px',
  lineHeight: '125%',
  opacity: 1,
  transition: 'all 0.2s ease',
  boxShadow: active ? '0px 15px 15px rgba(24, 24, 24, 0.05)' : '',
  fontFamily: 'InterRegular',
  fontWeight: 600,
  '&:hover': {
    opacity: 0.9,
  },
  [theme.breakpoints.down('md')]: {
    padding: '11px 8px',
    fontSize: 10,
  },
}));
