import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { Box } from '@mui/material';
import CustomButton from 'components/Buttons/CustomButton/CustomButton';
import Spinner from 'components/Spinner/Spinner';
import CustomModal from 'components/CustomModal/CustomModal';
import BodyText from 'components/Typography/BodyText/BodyText';
import ControlledTextField from 'components/Inputs/TextFields/CustomTextField/ControlledTextField/ControlledTextField';
import ModalStaticMessage from 'components/ModalStaticMessage/ModalStaticMessage';
import ModalHelperText from 'components/ModalHelperText/ModalHelperText';
import ModalMessageHeader from 'components/ModalHelperText/ModalMessageHeader';

import { ActionButtonsContainerStyled } from './ModalInviteDesigner.styles';

import { useYupValidationResolver } from 'hooks/useYupValidationResolver';
import { validationSchema } from './ModalInvite.data';

import { capitalize } from 'utils/capitalize';

import { useAuthContext } from 'context/AuthContext';

import { inviteDesigner } from 'api';

//TODO: Update link for navigation
const ModalInviteDesigner = ({ open, handleClose, navigateAction, setFormError, setFormSuccess }) => {
  const [loading, setLoading] = useState(false);
  const resolver = useYupValidationResolver(validationSchema);

  const defaultValues = {
    name: '',
    email: '',
    message: '',
  };

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver,
  });

  const { reset, watch } = methods;
  const designer_name = watch('name');
  const { company_name, first_name } = useAuthContext();

  const onSubmit = async (data) => {
    setLoading(true);
    // Post to API
    const response = await inviteDesigner(data);
    if (response.success) {
      setFormSuccess(`${capitalize(data.name)} has been sent an invite.`);
      handleClose();
      setLoading(false);
      reset({ ...defaultValues });
      return;
    }
    if (!response.success || !response?.data?.success) {
      setFormError(response.data.error_message);
      reset({ ...defaultValues });
      setLoading(false);
      return;
    }
  };

  const onClose = () => {
    reset({ ...defaultValues });
    handleClose();
  };

  return (
    <CustomModal title="Invite Designer" open={open} handleClose={onClose}>
      {loading ? (
        <Box mt={1} mb={4}>
          <Spinner align={'center'} />
        </Box>
      ) : (
        <Box>
          <Box mb={2} mt={2}>
            <BodyText text="Invite a designer to join BuyDesign" />
          </Box>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box mt={1} mb={3}>
                <ControlledTextField name="name" label="Name" />
                <ControlledTextField name="email" label="Email Address" />
              </Box>
              <ModalMessageHeader />
              <Box textAlign="justify" mt={1} backgroundColor="background.staticMessage" py={1}>
                <ModalStaticMessage
                  variant="invite-designer"
                  designer_name={designer_name}
                  buyer_user_name={first_name}
                  company_name={company_name}
                />
              </Box>
              <ModalHelperText />
              <ActionButtonsContainerStyled>
                <CustomButton variant="outlined" text="Cancel" fullWidth onClick={onClose} />
                <CustomButton text="Send Invite" fullWidth buttonType="submit" />
              </ActionButtonsContainerStyled>
            </form>
          </FormProvider>
        </Box>
      )}
    </CustomModal>
  );
};

export default ModalInviteDesigner;
